import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Icon } from "@amzn/awsui-components-react";
// import { CalendarMajorMonotone } from '@shopify/polaris-icons';

// import "react-datepicker/dist/react-datepicker.css";
import "./AWSInputDateCustom.scss";
// import { Icon, Tooltip } from '@shopify/polaris';
import PropTypes from "prop-types";
import "./AWSinputDate.scss";
import moment from 'moment';
import _ from 'underscore';
import infoHelpIcon from '../../../images/awsfalconhelp.png';
import AWSError from '../../common/AWSError/AWSError';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as roleNames from '../../../constants/roleNames.const';
import * as tagNameConstant from '../../../constants/tagNameConstant';
import { userRoleCheckForEditableCell } from "../../../utils/common";

let todayDate = new Date();
let effectiveDateForTermination = null;

export default class AWSInputDate extends Component {

    state = {
        errorLabel: "",
        error: false,
        showError: false,

        month: todayDate.getMonth(),
        year: todayDate.getFullYear(),
        active: false,
        startDate: new Date(),
        startDateAgreement: '',
        terminationEffictiveDate: '',
        startDateAgreementFlag: false
    }

    componentDidMount() {
        let { value, questionValue } = this.props;
        todayDate = null;
        todayDate = new Date();
        if (value !== null) {
            this.props.onChangeFunc(this.props.value)
        }
        if (value === null || value === "Invalid date") {
            let defaultDisplayDate;
            if (questionValue && questionValue.tagName === tagNameConstant.commitTermEffectiveDateCalTag) {
                defaultDisplayDate = null;
            } else if (questionValue && questionValue.tagName === tagNameConstant.startDateAgreementCalTag) {
                // defaultDisplayDate = null;
            } else if (questionValue && questionValue.tagName === tagNameConstant.effectiveDetailCalTag) {
                defaultDisplayDate = moment(todayDate.setDate(todayDate.getDate() + 1)).endOf('month').format("MM/DD/YYYY")
            } else if (questionValue && questionValue.tagName === tagNameConstant.currentDiscountTermEndCalTag) {
                defaultDisplayDate = this.props.termEndDate;
            }
            else {
                defaultDisplayDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
            }
            this.props.onChangeFunc(defaultDisplayDate);
        }
    }

    componentDidUpdate(prevProps) {
        let { questionValue } = this.props;

        if (prevProps.date !== this.props.date) {
            if (prevProps.date !== "" && questionValue && questionValue.tagName === tagNameConstant.commitTermEffectiveDateCalTag) {
                this.props.onChangeFunc(null)
            }
            if (questionValue && questionValue.tagName === tagNameConstant.expectedCustomerCalTag) {
                this.popUpAlert(this.props.value)
            }
        }
    }

    validate(validationArray, val) {
        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            if (validationObject.type === "Required") {
                if ((_.isEmpty(val)) || (_.isNull(val)) || val === "Invalid date") {
                    this.setState({
                        error: true,
                        errorLabel: validationObject.message
                    })
                }
            }
        });
    }

    handleChange = (value, tagName) => {
        this.setState({
            error: false
        })
        effectiveDateForTermination = value;

        if (tagName === tagNameConstant.effectiveDetailCalTag) {
            value = moment(value).endOf('month').format("MM/DD/YYYY")
        } else if (tagName === tagNameConstant.currentDiscountTermEndCalTag) {
            value = moment(value).format("MM/DD/YYYY")
        } else {
            value = moment(value).format("MM/DD/YYYY");
        }

        this.props.onChangeFunc(value);
        this.popUpAlert(value);
    };

    handleStartDate = (value, dateType) => {
        this.setState({
            error: false
        })
        value = moment(value).format("MM/DD/YYYY");
        this.props.onChangeFunc(value);
        this.showWarning(value, dateType);
    }

    showWarning = (value, dateType) => {
        if (dateType === "startDateofAgreement") {
            let startDate = value.split('/')
            let startDateDay = startDate[1]

            if (parseInt(startDateDay) > 1) {
                this.setState({
                    startDateAgreementFlag: true
                })
            } else {
                this.setState({
                    startDateAgreementFlag: false
                })
            }

        }
    }

    handleChangeForActualDate = value => {
        value = moment(value).format("MM/DD/YYYY");
        this.props.onChangeFunc(value);
        this.popUpAlert(value)
        this.setState({
            error: false
        })
    }

    handleMonthChange = (month, year) => {
        this.setState({
            month,
            year
        });
    };

    getValue = (value, defaultDisplayDate, tagName) => {
        let { dealStatus } = this.props;

        if (value === null || value === "" || value === "Invalid date") {
            return defaultDisplayDate;
        } else if (value && tagName === tagNameConstant.currentDiscountTermEndCalTag && dealStatus === dealStatusConstant.Deal_Signed) {
            if (new Date(value) < new Date(defaultDisplayDate)) {
                this.props.onChangeFunc(defaultDisplayDate);
                return new Date(defaultDisplayDate);
            } else {
                return new Date(value);
            }
        } else {
            return new Date(value)
        }
    }

    handleCalendarIconClick = (id) => {
        document.getElementById(id).click();
    }

    editQuestionCss = (questionValue) => {
        return questionValue && questionValue.isQuestionEdit === true ? 'editable-color' : ''
    }

    /**
     * this function will verify and return boolean for disable state of field based on status, userRole and question tagnam
     */
    verifyDisablecondtionBasedOnstatus = () => {
        const { dealStatus, locationStateUser, userRole, dealInfo, questionValue } = this.props;
        const listOfQuestionBasedOnTagName = [tagNameConstant.expectedCustomerCalTag]
        if ((dealStatus === dealStatusConstant.Open_For_Review && userRoleCheckForEditableCell(locationStateUser, userRole, dealInfo, roleNames)) || userRole === roleNames.BILLING_TEAM) {
            if (questionValue && questionValue.tagName && listOfQuestionBasedOnTagName.includes(questionValue.tagName)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        let { dealStatus, userRole } = this.props;
        let tagName = questionValue && questionValue.tagName;
        if (this.verifyDisablecondtionBasedOnstatus()) {
            return false
        } else {
            if (canEditPPR === true) {
                return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
            } else if (canEditPPR === false) {
                if ((userRole === roleNames.SALES_REPRESENTATIVE || userRole === roleNames.BILLING_TEAM)
                    && dealStatus === dealStatusConstant.Deal_Signed && tagName === tagNameConstant.currentDiscountTermEndCalTag) {
                    return false;
                } else {
                    return true // disable true
                }
            }
        }
    }

    /**
    * this function will popup alert for additional approval requirement
    */
    popUpAlert = (value, dateType) => {
        // For triggering alert message when date selected above 28th of a month
        let { questionValue } = this.props;
        const { tagName = '' } = questionValue || {};
        let { date } = this.props;

        let discountTermEffectiveDate = date;
        let alertForSignatureDate = false;

        let discountTermEffectiveDateInDateFormat = new Date(discountTermEffectiveDate);
        let getMonthOfDiscountTermEffectiveDate = discountTermEffectiveDateInDateFormat.getMonth() + 1;
        let selectedDay = new Date(value).getDate();
        let selectedMonth = new Date(value).getMonth() + 1;
        let selectedYear = new Date(value).getFullYear()
        let checkForDateAlertSelectedMonth = selectedMonth;
        if (getMonthOfDiscountTermEffectiveDate === 1 && checkForDateAlertSelectedMonth === 12) {
            checkForDateAlertSelectedMonth = 0;
        }

        if (tagName === tagNameConstant.expectedCustomerCalTag) {

            if (discountTermEffectiveDateInDateFormat.getFullYear() < selectedYear) {
                alertForSignatureDate = true;
            } else {
                if (getMonthOfDiscountTermEffectiveDate !== 1) {
                    if ((selectedDay > 28) && ((getMonthOfDiscountTermEffectiveDate - 1) <= checkForDateAlertSelectedMonth) && discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear()) {
                        alertForSignatureDate = true;
                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    } else {
                        alertForSignatureDate = false;
                    }
                } else {

                    if ((selectedDay > 28) && (checkForDateAlertSelectedMonth === 0) && ((getMonthOfDiscountTermEffectiveDate - 1) <= checkForDateAlertSelectedMonth) && discountTermEffectiveDateInDateFormat.getFullYear() > new Date(value).getFullYear()) {
                        alertForSignatureDate = true;
                    } else if (checkForDateAlertSelectedMonth === 0 && (getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;

                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) === checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    }
                    else {
                        alertForSignatureDate = false;
                    }
                }
            }

            this.props.onChangeAdditionalApproval("Expected_Customer_Signature_Date", alertForSignatureDate)
        }
    }


    render() {
        let { label, value, triggeredNext, questionValue, date, canEditPPR } = this.props;
        const { tagName = '' } = questionValue || {};
        let discountTermEffectiveDate = date;
        let alertForSignatureDate = false;

        let discountTermEffectiveDateInDateFormat = new Date(discountTermEffectiveDate);
        let getMonthOfDiscountTermEffectiveDate = discountTermEffectiveDateInDateFormat.getMonth() + 1;
        let selectedDay = new Date(value).getDate();
        let selectedMonth = new Date(value).getMonth() + 1;

        if (tagName !== tagNameConstant.currentDiscountTermEndCalTag && tagName !== tagNameConstant.expectedCustomerCalTag) {
            // if (selectedMonth >= getMonthOfDiscountTermEffectiveDate) {
            //     if (discountTermEffectiveDateInDateFormat.getFullYear() <= new Date(value).getFullYear()) {
            //         value = null;
            //     }
            // }

            // if (discountTermEffectiveDateInDateFormat.getFullYear() < new Date(value).getFullYear()) {
            //     value = null;
            // }

            // if (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear()) {
            //     if (selectedMonth >= getMonthOfDiscountTermEffectiveDate) {
            //         value = null
            //     }
            // }
        }


        // For triggering alert message when date selected above 28th of a month
        let checkForDateAlertSelectedMonth = selectedMonth;
        if (getMonthOfDiscountTermEffectiveDate === 1 && checkForDateAlertSelectedMonth === 12) {
            checkForDateAlertSelectedMonth = 0;
        }

        if (tagName === tagNameConstant.expectedCustomerCalTag) {
            if (discountTermEffectiveDateInDateFormat.getFullYear() < new Date(value).getFullYear()) {
                alertForSignatureDate = true;
            } else {
                if (getMonthOfDiscountTermEffectiveDate !== 1) {
                    if ((selectedDay > 28) && ((getMonthOfDiscountTermEffectiveDate - 1) <= checkForDateAlertSelectedMonth) && discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear()) {
                        alertForSignatureDate = true;
                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    } else {
                        alertForSignatureDate = false;
                    }
                } else {

                    if ((selectedDay > 28) && (checkForDateAlertSelectedMonth === 0) && ((getMonthOfDiscountTermEffectiveDate - 1) <= checkForDateAlertSelectedMonth) && discountTermEffectiveDateInDateFormat.getFullYear() > new Date(value).getFullYear()) {
                        alertForSignatureDate = true;

                    } else if (checkForDateAlertSelectedMonth === 0 && (getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;

                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) < checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    } else if ((getMonthOfDiscountTermEffectiveDate - 1) === checkForDateAlertSelectedMonth && (discountTermEffectiveDateInDateFormat.getFullYear() === new Date(value).getFullYear())) {
                        alertForSignatureDate = true;
                    } else {
                        alertForSignatureDate = false;
                    }
                }

            }
        }



        // min and max date condtition
        let todayDate = new Date();
        let disableMaxDate = new Date(discountTermEffectiveDateInDateFormat.setDate(discountTermEffectiveDateInDateFormat.getDate() - 1))
        let disablebeforeDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
        let disablebeforeDateExpectedDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
        // let minDateForActualTerm = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);\
        let startDateAgreementbeforeDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
        let startDateForCommitTermEffectiveDate = new Date(discountTermEffectiveDateInDateFormat.getFullYear(), discountTermEffectiveDateInDateFormat.getMonth() + 1, 2);

        let effictiveMinDate = moment(todayDate.setDate(todayDate.getDate() + 1)).startOf('month');
        let effectiveDateForTermination = new Date(todayDate.setDate(todayDate.getDate() - 1));

        if (triggeredNext && (!this.state.error) && questionValue && questionValue.isVisible) {
            this.validate(this.props.validation, this.props.value)
        }
        return (
            <div className="date-wraper">
                {questionValue !== undefined ?
                    <div className={`full-width dark-black font-size14 label-txt ${this.editQuestionCss(questionValue)}`}><div className="float-left date-label-txt">{questionValue.isMandatory === true ? label : `${label} (Optional)`}</div></div>
                    : <div className={`full-width dark-black font-size14 label-txt ${this.editQuestionCss(questionValue)}`}>{label}</div>}
                {
                    tagName === tagNameConstant.terminationDateCalTag ?
                        <DatePicker
                            id={`datepicker_${questionValue.queId}`}
                            placeholderText="Select"
                            selected={this.getValue(value, disablebeforeDate)}
                            onChange={(value) => { this.handleChange(value, tagName) }}
                            minDate={disablebeforeDate}
                            maxDate={disableMaxDate}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                        /> :
                        tagName === tagNameConstant.commitTermEffectiveDateCalTag ?
                            <DatePicker
                                id={`datepicker_${questionValue.queId}`}
                                placeholderText="Select"
                                selected={this.getValue(this.props.value, null)}
                                // selected={this.state.startDateAgreement}
                                minDate={startDateForCommitTermEffectiveDate}
                                onChange={(value) => { this.handleChange(value, tagName) }}
                                // maxDate={disablebeforeDateExpectedDate}
                                disabled={(this.props.date === "" || this.props.date === null) || this.checkForDisable(questionValue, canEditPPR)}
                            />
                            :
                            tagName === tagNameConstant.startDateAgreementCalTag ?
                                <DatePicker
                                    id={`datepicker_${questionValue.queId}`}
                                    placeholder="select"
                                    selected={this.getValue(this.props.value, null)}
                                    // selected={this.state.startDateAgreement}
                                    onChange={(value, dateType) => { this.handleStartDate(value, 'startDateofAgreement') }}
                                    // minDate={startDateAgreementbeforeDate}
                                    showMonthYearPicker
                                    maxDate={disablebeforeDateExpectedDate}
                                    disabled={this.checkForDisable(questionValue, canEditPPR)}
                                /> :
                                tagName === tagNameConstant.effectiveDetailCalTag ?
                                    <DatePicker
                                        id={`datepicker_${questionValue.queId}`}
                                        placeholder="select"
                                        // selected={this.state.terminationEffictiveDate}
                                        selected={this.getValue(value, null)}
                                        onChange={(value) => { this.handleChange(value, tagName) }}
                                        minDate={new Date(effictiveMinDate)}
                                        maxDate={disableMaxDate}
                                        showMonthYearPicker
                                        disabled={this.checkForDisable(questionValue, canEditPPR)}
                                    /> :
                                    tagName === tagNameConstant.actualDateCalTag ?
                                        <DatePicker
                                            id={`datepicker_${questionValue.queId}`}
                                            placeholder="select"
                                            selected={this.getValue(value, disablebeforeDate)}
                                            onChange={(value) => { this.handleChangeForActualDate(value) }}

                                            // onChange={this.handleChangeForActualDate}
                                            // minDate={minDateForActualTerm}
                                            maxDate={effectiveDateForTermination}
                                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                                        /> :
                                        tagName === tagNameConstant.expectedCustomerCalTag ?
                                            <DatePicker
                                                id={questionValue !== 'undefined' ? `datepicker_${questionValue && questionValue.queId}` : `datepicker_${Math.random()}`}
                                                selected={this.getValue(value, new Date(), tagName)}
                                                onChange={(value) => { this.handleChange(value, tagName) }}
                                                minDate={disablebeforeDateExpectedDate}
                                                disabled={this.checkForDisable(questionValue, canEditPPR)}
                                            /> :
                                            tagName === tagNameConstant.discountTermEndDateCalTag ?
                                                <DatePicker
                                                    id={questionValue !== 'undefined' ? `datepicker_${questionValue && questionValue.queId}` : `datepicker_${Math.random()}`}
                                                    selected={this.getValue(
                                                        this.props.termEndDate ? this.props.termEndDate : value,
                                                        todayDate
                                                    )}
                                                    onChange={(value) => { this.handleChange(value) }}
                                                    // disabled={this.checkForDisable(questionValue, canEditPPR)}
                                                    disabled={true}
                                                /> :
                                                tagName === tagNameConstant.currentDiscountTermEndCalTag && this.props.dealStatus === dealStatusConstant.PPR_Created ?
                                                    <DatePicker
                                                        dateFormat="MM/dd/yyyy"
                                                        id={questionValue !== 'undefined' ? `datepicker_${questionValue && questionValue.queId}` : `datepicker_${Math.random()}`}
                                                        selected={this.getValue(value, this.props.date)}
                                                        onChange={(value) => { this.handleChange(value, tagName) }}
                                                        // minDate={new Date(this.props.date)}
                                                        disabled={true}
                                                    /> :
                                                    tagName === tagNameConstant.currentDiscountTermEndCalTag && this.props.dealStatus !== dealStatusConstant.PPR_Created ?
                                                        <DatePicker
                                                            dateFormat="MM/dd/yyyy"
                                                            id={questionValue !== 'undefined' ? `datepicker_${questionValue && questionValue.queId}` : `datepicker_${Math.random()}`}
                                                            selected={this.getValue(value, this.props.date, tagName)}
                                                            onChange={(value) => { this.handleChange(value, tagName) }}
                                                            minDate={new Date(this.props.date)}
                                                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                                                        /> :
                                                        <DatePicker
                                                            id={questionValue !== 'undefined' ? `datepicker_${questionValue && questionValue.queId}` : `datepicker_${Math.random()}`}
                                                            selected={this.getValue(value, todayDate)}
                                                            onChange={(value) => { this.handleChange(value) }}
                                                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                                                        />
                }

                {this.state.startDateAgreementFlag && <p className="font-size10 red mb-0">Please select 1st of the month</p>}
                <span className={(this.verifyDisablecondtionBasedOnstatus() || this.checkForDisable(questionValue, canEditPPR) === false) ? "calendar-icon" : "calendar-icon disable-calendar"} onClick={() => this.handleCalendarIconClick(`datepicker_${questionValue && questionValue.queId}`)}><Icon name="calendar"></Icon></span>
                {tagName === tagNameConstant.expectedCustomerCalTag && alertForSignatureDate && <p className="font-size10 red"> This deal will require additional approvals due to exception to the customer signature date policy</p>}
                {/* {tagName != tagNameConstant.discountTermEndDateCalTag || tagNameConstant.currentDiscountTermEndCalTag && alertForSignatureDate && <p className="font-size10 red"> This deal will require additional approvals due to exception to the customer signature date policy</p>} */}

                {(questionValue && (questionValue.isVisible === true)) && this.state.error && (
                    <AWSError
                        errorLabel={this.state.errorLabel}
                    />
                )}
            </div>
        );
    }
}

AWSInputDate.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeFunc: PropTypes.func
};
