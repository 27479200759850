import React from "react";
import {Link} from "react-router-dom";

export default {

	sortableColumnOptions : [
        {id: "pprId", comparator: (item1, item2) => {
            const firstField = item1.pprId;
            const secondField = item2.pprId;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "customerLegalName", comparator: (item1, item2) => {
            const firstField = item1.customerLegalName;
            const secondField = item2.customerLegalName;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "createdDate", comparator: (item1, item2) => {
            const firstField = item1.createdDate;
            const secondField = item2.createdDate;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "discountTermEffectiveDate", comparator: (item1, item2) => {
            const firstField = item1.discountTermEffectiveDate;
            const secondField = item2.discountTermEffectiveDate;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "dealType", comparator: (item1, item2) => {
            const firstField = item1.dealType;
            const secondField = item2.dealType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "dealStatus", comparator: (item1, item2) => {
            const firstField = item1.dealStatus;
            const secondField = item2.dealStatus;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "SLA", comparator: (item1, item2) => {
            const firstField = item1.SLA;
            const secondField = item2.SLA;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "Sales_Representative", comparator: (item1, item2) => {
            const firstField = item1.Sales_Representative;
            const secondField = item2.Sales_Representative;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "pprType", comparator: (item1, item2) => {
            const firstField = item1.pprType;
            const secondField = item2.pprType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "payerId", comparator: (item1, item2) => {
            const firstField = item1.payerId;
            const secondField = item2.payerId;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "Sales_Representative_Requestor", comparator: (item1, item2) => {
            const firstField = item1.Sales_Representative_Requestor;
            const secondField = item2.Sales_Representative_Requestor;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "Sales_Manager", comparator: (item1, item2) => {
            const firstField = item1.Sales_Manager;
            const secondField = item2.Sales_Manager;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "SCE_Owner", comparator: (item1, item2) => {
            const firstField = item1.SCE_Owner;
            const secondField = item2.SCE_Owner;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "PPO_Owner", comparator: (item1, item2) => {
            const firstField = item1.PPO_Owner;
            const secondField = item2.PPO_Owner;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "Private_Pricing_China", comparator: (item1, item2) => {
            const firstField = item1.Private_Pricing_China;
            const secondField = item2.Private_Pricing_China;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "Private_Pricing_Public_Sector", comparator: (item1, item2) => {
            const firstField = item1.Private_Pricing_Public_Sector;
            const secondField = item2.Private_Pricing_Public_Sector;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "businessUnit", comparator: (item1, item2) => {
            const firstField = item1.businessUnit;
            const secondField = item2.businessUnit;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},{id: "vertical", comparator: (item1, item2) => {
            const firstField = item1.vertical;
            const secondField = item2.vertical;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "expectedCustomerSignatureDate", comparator: (item1, item2) => {
            const firstField = item1.expectedCustomerSignatureDate;
            const secondField = item2.expectedCustomerSignatureDate;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},{id: "lastStatusUpdated", comparator: (item1, item2) => {
            const firstField = item1.lastStatusUpdated;
            const secondField = item2.lastStatusUpdated;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "geo", comparator: (item1, item2) => {
            const firstField = item1.geo;
            const secondField = item2.geo;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "minimumCommittedRevenue", comparator: (item1, item2) => {
            const firstField = item1.minimumCommittedRevenue;
            const secondField = item2.minimumCommittedRevenue;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "termLength", comparator: (item1, item2) => {
            const firstField = item1.termLength;
            const secondField = item2.termLength;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "CFRCType", comparator: (item1, item2) => {
            const firstField = item1.CFRCType;
            const secondField = item2.CFRCType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "commitType", comparator: (item1, item2) => {
            const firstField = item1.commitType;
            const secondField = item2.commitType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "expectedRevenue", comparator: (item1, item2) => {
            const firstField = item1.expectedRevenue;
            const secondField = item2.expectedRevenue;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "accountName", comparator: (item1, item2) => {
            const firstField = item1.accountName;
            const secondField = item2.accountName;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "channel", comparator: (item1, item2) => {
            const firstField = item1.channel;
            const secondField = item2.channel;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "references", comparator: (item1, item2) => {
            const firstField = item1.references;
            const secondField = item2.references;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "businessCustomerType", comparator: (item1, item2) => {
            const firstField = item1.businessCustomerType;
            const secondField = item2.businessCustomerType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }}
    ]
};