export const LOADING_MESSAGE = 'Adding Additional Term...';
export const SAVE_MESSAGE = 'Additional term Added Successfullly...';
export const FAIL_MESSAGE = 'Failed to Add Additional Term...';

export const UPDATING_MESSAGE = 'Update Addtional Term...';
export const UPDATE_MESSAGE = 'Additional Term Updated Successfullly...';
export const UPDATE_FAIL_MESSAGE = 'Failed to Update Additional term...';

export const DELETING_ADDLTERM_MESSAGE = 'Deleting Addtional Term...';
export const DELETE_MESSAGE = 'Additional Term Deleted Successfullly...';
export const DELETE_FAIL_MESSAGE = 'Failed to delete Additional Term...';