import React, { Component } from 'react';
import AWSinput from "../common/AWSInput/AWSinput";
// import { AppProvider, TextField, Tooltip, Icon } from '@shopify/polaris';
import AWSDropdown from "../common/AWSDropdown/AWSDropdown";
import Plusicon from "../../images/Pluss_Icon.svg";
import Minusicon from '../../images/minu_icon.svg';
import Col from "../common/AWSCol/AWSCol";
import infoHelpIcon from '../../images/awsfalconhelp.png';
import AWSAccountNameAC from '../common/AWSAccountNameAC/AWSAccountNameAC';
import AWSConfig from '../../../src/config/AWSconfig';
import axios from 'axios';
import AWSCheckBox from '../common/AWScheckbox/AWScheckbox';
import deleteIcon from '../../images/delete_grey.svg';
import resetIcon from '../../images/Reset.svg';
import { cloneDeep } from 'lodash';
import AWSError from "../common/AWSError/AWSError";
import { Popover, Icon } from "@amzn/awsui-components-react";
import * as tagNameConstant from '../../constants/tagNameConstant';
const config = new AWSConfig();

class DynamicTable extends Component {

    state = {
        payerIdWithDiiferentAccount: false,
        resetAll: false,
        selectedRowId: [],
        errInMOP: false,
        rowIdMOP: null,
        errorMessage: null,
        selectedAffiliateIdArray: [],
        duplicateAffiliatePayerIdFlag: false
    }

    /**
     * this function used add new row in table
     */
    handleOnAdd = (formgroup, answer, question, rowId, maxRows = 1) => {
        let cells = [];
        const newRandomId = Math.floor(Math.random() * Math.floor(1000));
        formgroup.map((cell, formIndex) => {
            let cellTypeObject = {
                type: cell.type,
                value: null,
                tableRowID: question.sF_Id + newRandomId,
                cellId: cell.sF_Id,
                questionId: question.sF_Id,
                options: cell.options,
                sequence: cell.sequence,
                prevValue: cell.prevValue,
                isQuestionEdit: cell.isQuestionEdit,
                tagName: cell.tagName
            }
            cells.push(cellTypeObject);
        })
        let newRowObject = {
            type: null,
            newRowId: question.sF_Id + newRandomId,
            cells,
            tableId: question.sF_Id,
            questionId: question.sF_Id,
            sequence: rowId + 2,
        };
        if (question.tableObj.rows.length <= maxRows) {
            question.tableObj.rows.push(newRowObject)
        } else {
            // message.info(`You cannot add more than ${maxRows}`)
        }
        this.setState({
            nothing: ""
        })
    }

    /**
     * this function is used to delete single row from table
     */
    handleOnDeleteTable = (formGroup, question, rowId) => {
        question.tableObj.rows.splice(rowId, 1)
        if (question.tagName === tagNameConstant.affiliate_payerid_details_table) {
            let { selectedAffiliateIdArray } = this.state;
            selectedAffiliateIdArray.splice(rowId - 1, 1)
            this.setState({
                selectedAffiliateIdArray
            }, () => {
                this.validateForDuplicateAffiliateId()
                this.props.handleAffiliatedPayerIdForSummary(selectedAffiliateIdArray)
            })
        } else {
            this.props.handleDeleteTableRow(rowId)
        }
    }

    /**
     * this function is used to delete multiple row from table
     */
    handleOnBulkDelete = (questionValue) => {
        let { selectedRowId } = this.state;
        const tableQuestionObject = cloneDeep(questionValue);
        let tableQuestionObjectRows = tableQuestionObject.tableObj.rows;
        tableQuestionObjectRows = tableQuestionObjectRows.filter(function (value, index) {
            return selectedRowId.indexOf(index) == -1;
        })

        if (questionValue.tagName === tagNameConstant.affiliate_payerid_details_table) {
            let { selectedAffiliateIdArray } = this.state;

            selectedAffiliateIdArray = selectedAffiliateIdArray.filter(function (value, index) {
                return selectedRowId.indexOf(index + 1) == -1;
            })
            this.setState({
                selectedAffiliateIdArray
            }, () => {
                this.validateForDuplicateAffiliateId()
                this.props.handleAffiliatedPayerIdForSummary(selectedAffiliateIdArray)
            })
        } else {
            this.props.handleBulkDeleteTableRow(selectedRowId)
        }

        questionValue.tableObj.rows = tableQuestionObjectRows
        this.setState({
            selectedRowId: [],
            resetAll: false
        })
    }


    getDataFromAccountNameOrAccountId = (val, type, rowId) => {
        const url = `${config.awsFalconSearchUrl}search`;
        const postData = {
            query: {
                match_phrase_prefix: {
                    [type]: val,
                },
            },
            payerId: true
        };
        axios({
            method: 'POST',
            url: url,
            data: postData
        }).then(resp => {
            this.setPayerIdWithDiiferentAccount(resp.data, rowId)
        }).catch((e) => {
            console.warn('Auto completed selection warning', e);
        });
    }

    handlePayerIdAccountValidation = async (value, rowId) => {
        this.setState({
            errInMOP: false,
            rowIdMOP: null
        })
        if (value && (value.length === 12)) {
            this.getDataFromAccountNameOrAccountId(value, "aws_account_id", rowId)
        }
    }

    setPayerIdWithDiiferentAccount = (data, rowID) => {
        const modeOfPayment = ["Invoice", "CreditCard"];

        if ((data && data.length > 0)) {

            if (!modeOfPayment.includes(data[0].payerid_mode_ofpayment)) {
                this.setState({
                    errInMOP: true,
                    rowIdMOP: rowID,
                    errorMessage: "PayerId With Differnt Mode of Payment"
                })
            } else {
                this.setState({
                    errInMOP: true,
                    rowIdMOP: rowID,
                    errorMessage: "Please select the suggested PayerId"
                })
            }

            this.setState({
                payerIdWithDiiferentAccount: true
            })
        } else {
            this.setState({
                payerIdWithDiiferentAccount: false
            })
        }
    }

    handleAllSelectedCheckBox = (rowID) => {
        let selectedRowIdArray = [...this.state.selectedRowId];
        if (selectedRowIdArray.includes(rowID)) {
            selectedRowIdArray = selectedRowIdArray.filter((selectedRowIdArray) => selectedRowIdArray !== rowID)
        } else {
            selectedRowIdArray.push(rowID)
        }
        this.setState({
            selectedRowId: selectedRowIdArray
        })
    }

    /**
     * this function will show selected checkbox on click of reset button
     */
    setResetButton = () => {
        let { questionValue, canEditPPR } = this.props;
        let selectallRowID = [];

        if (questionValue.quePermission && questionValue.quePermission.read === false && canEditPPR === true) {
            if (this.state.resetAll === false) {
                questionValue.tableObj && questionValue.tableObj.rows && questionValue.tableObj.rows.forEach((row, rowId) => {
                    if (row.type !== "HEADER") {
                        selectallRowID.push(rowId)
                    }
                });
                this.setState({
                    selectedRowId: selectallRowID
                })
            }

            this.setState({
                resetAll: !this.state.resetAll
            })
        }
    }

    handleChange = (val, questionValue, questionBlockIndex, rowId, cellIndex, tagName = null, cell) => {
        if (tagName === tagNameConstant.affiliate_payerid_details_table && cell.tagName
            && cell.tagName === tagNameConstant.affiliate_payerId_cell && val.length === 12) {
            let { selectedAffiliateIdArray } = this.state;
            selectedAffiliateIdArray[rowId - 1] = val;
            this.setState({
                selectedAffiliateIdArray
            }, () => {
                this.validateForDuplicateAffiliateId()
                this.props.handleAffiliatedPayerIdForSummary(selectedAffiliateIdArray)
            })
        }
        this.props.handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)
    }

    validateForDuplicateAffiliateId = () => {
        const { selectedAffiliateIdArray } = this.state;
        if (new Set(selectedAffiliateIdArray).size !== selectedAffiliateIdArray.length) {
            this.setState({
                duplicateAffiliatePayerIdFlag: true
            })
        } else {
            this.setState({
                duplicateAffiliatePayerIdFlag: false
            })
        }
    }

    /**
     * this function will change color of edited field 
     */
    editQuestionCss = (cell) => {
        return cell.isQuestionEdit === true ? 'editable-color' : ''
    }

    render() {
        let { questionValue, index, triggeredNext, questionBlockIndex, duplicateValidation, duplicateErrFlag, onSelectOption, handleChange, canEditPPR } = this.props;
        let { selectedRowId, duplicateAffiliatePayerIdFlag } = this.state;
        const { quePermission } = questionValue;

        return (
            <div key={`table${index}`} id={questionValue.sF_Id} style={{ display: !questionValue.isVisible ? 'none' : 'block' }} className="mt-5">
                <div className={`input-label font-size14 float-left `}>{questionValue.isMandatory === true ? questionValue.queLabel : `${questionValue.queLabel} (Optional)`}</div>
                
                <Popover content={questionValue && questionValue.helpText}
                    preferredPosition="above" size="small" position="top">
                    <div className="float-left circle-alert">
                        {/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon>
                    </div>
                </Popover>
                <Col span={24} className="mt-2">
                    {
                        questionValue.tableObj && questionValue.tableObj.rows && questionValue.tableObj.rows.map((row, rowId) => {
                            if (row.type === "HEADER") {
                                return row.cells.map((cell, cellId) => {
                                    return (
                                        <Col span={cellId === 0 ? 1 : 4} className="mr-3" key={`aws-celx-${cellId}`}>
                                            <span className={`float-left grey-txt ${this.editQuestionCss(cell)}`}>{cell.value}
                                                <span className="float-right table-info-icon">
                                                    {cell && cell.helpText && (
                                                        <div className="float-left circle-alert">
                                                            {/* <img src={infoHelpIcon} alt="help" /> */}
                                                            {/* <Icon name="status-info"></Icon> */}
                                                        </div>
                                                    )}
                                                </span>
                                            </span>
                                        </Col>
                                    )
                                })
                            }
                        })
                    }
                    {
                        <>

                            <Popover content="Select to delete"
                                preferredPosition="above" size="small" position="top">
                                <div className="float-left cursor-pointer grey-txt" onClick={() => { this.setResetButton() }}><img src={resetIcon} alt="reset" /></div>
                            </Popover>


                            <Popover content="Selected rows will be removed"
                                preferredPosition="above" size="small" position="top">
                                {this.state.resetAll && <div className="float-left cursor-pointer grey-txt pl-2" onClick={() => { this.handleOnBulkDelete(questionValue) }}>
                                    <img src={deleteIcon} alt="delete" /></div>
                                }
                            </Popover>
                        </>
                    }
                </Col>
                <Col span={24} className="mt-2 ">
                    {
                        questionValue.tableObj && questionValue.tableObj.rows && questionValue.tableObj.rows.map((row, rowId) => {
                            let lastRowElementID = questionValue.tableObj.rows.length - 1;
                            if (questionValue.tableObj && questionValue.tableObj.rows.length === 1) {
                                { this.handleOnAdd(questionValue.tableObj.rows[0].cells, questionValue.answer, questionValue, rowId) }
                            } else {
                                if (row.type !== "HEADER") {
                                    return (
                                        <div className="full-width mb-2" key={`tableRow_${rowId}`}>
                                            {
                                                row.cells.map((cell, cellIndex) => {
                                                    if (questionValue.queId === 'a0J3j00001JQIUVEA5' && cellIndex === 1) {
                                                        return (
                                                            <Col span={cellIndex === 0 ? 1 : 4}
                                                                className={cellIndex === 0 ? "mr-3 mt-0 pointer-events-none payerid-input" : "mr-3 mt-0 payerid-input"}
                                                                key={`aws-tabcell-text-${cellIndex}`}
                                                            >
                                                                <AWSAccountNameAC
                                                                    id={questionValue.sF_Id}
                                                                    canEditPPR={canEditPPR}
                                                                    type="aws_account_id"
                                                                    shouldShowLabel={false}
                                                                    value={cell.value}
                                                                    questionValue={questionValue}
                                                                    onChangeFunc={(val) => handleChange && handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)}
                                                                    triggeredNext={triggeredNext}
                                                                    onSelectOption={(val) => onSelectOption && onSelectOption(val, questionValue, questionBlockIndex, rowId, cellIndex)}
                                                                    validation={questionValue.tableObj.rows[0].cells[cellIndex].validation}
                                                                    duplicateValidation={duplicateValidation}
                                                                    duplicateErrFlag={duplicateErrFlag} />
                                                            </Col>
                                                        );
                                                    }
                                                    switch (cell.type) {
                                                        case "AUTOCOMPLETE":
                                                            let autopopulate = questionValue.tableObj.rows[rowId].cells[cellIndex].autopopulate
                                                            if (autopopulate === undefined) {
                                                                autopopulate = false
                                                            }

                                                            return (
                                                                <Col span={cellIndex === 0 ? 1 : 4}
                                                                    className={cellIndex === 0 ? `mr-3 mt-0 pointer-events-none payerid-input ${autopopulate === false ? ' orangeborder-input' : ''}` : `mr-3 mt-0 payerid-input${autopopulate === false ? ' orangeborder-input' : ''}`}
                                                                    key={`aws-tabcell-text-${cellIndex}`}
                                                                >
                                                                    <AWSAccountNameAC
                                                                        id={questionValue.sF_Id}
                                                                        canEditPPR={autopopulate === true ? false : canEditPPR}
                                                                        newPayerId={autopopulate === true ? false : true}
                                                                        type="aws_account_id"
                                                                        shouldShowLabel={false}
                                                                        showHelpTooltip={false}
                                                                        showOptional={false}
                                                                        value={cell.value}
                                                                        questionValue={questionValue}
                                                                        payerIdAccountValidation={(val) => { this.handlePayerIdAccountValidation(val, rowId) }}
                                                                        onChangeFunc={(val) => handleChange && handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)}
                                                                        triggeredNext={triggeredNext}
                                                                        onSelectOption={(val) => onSelectOption && onSelectOption(val, questionValue, questionBlockIndex, rowId, cellIndex)}
                                                                        validation={questionValue.tableObj.rows[0].cells[cellIndex].validation}
                                                                        duplicateValidation={duplicateValidation}
                                                                        duplicateErrFlag={duplicateErrFlag} />
                                                                    {
                                                                        (autopopulate === false && this.state.errInMOP === true && this.state.rowIdMOP === rowId && cell.value && cell.value.length === 12) && (
                                                                            <AWSError
                                                                                errorLabel={this.state.errorMessage}
                                                                            />
                                                                        )
                                                                    }
                                                                </Col>
                                                            );
                                                        case "DROPDOWN":
                                                            let autopopulateDropDown = questionValue.tableObj.rows[rowId].cells[cellIndex].autopopulate
                                                            if (autopopulateDropDown === undefined) {
                                                                autopopulateDropDown = false
                                                            }
                                                            return (
                                                                <Col span={4} className="mr-3 mt-0" key={`dropdown_${cellIndex}`}>
                                                                    <AWSDropdown
                                                                        id={cellIndex}
                                                                        canEditPPR={autopopulateDropDown === true ? false : canEditPPR}
                                                                        label=""
                                                                        showHelpTooltip={false}
                                                                        showOptional={false}
                                                                        options={questionValue.tableObj.rows[0].cells[cellIndex].options}
                                                                        value={cell.value}
                                                                        defaultValue={questionValue.tableObj.rows[0].cells[cellIndex].defaulValue}
                                                                        handleOnSelect={
                                                                            (val) => {
                                                                                this.props.handleOnSelect(val, questionValue, questionBlockIndex, rowId, cellIndex)
                                                                            }
                                                                        }
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.tableObj.rows[0].cells[cellIndex].validation}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </Col>
                                                            );
                                                            break;
                                                        case "LABEL":
                                                            let autopopulateLabel = questionValue.tableObj.rows[rowId].cells[cellIndex].autopopulate
                                                            if (autopopulateLabel === undefined) {
                                                                autopopulateLabel = false
                                                            }
                                                            return (
                                                                <Col
                                                                    span={cellIndex === 0 ? 1 : 4}
                                                                    className={cellIndex === 0 ? "mr-3 mt-0 pointer-events-none" : "mr-3 mt-0 "}
                                                                    key={`aws-tabcell-${cellIndex}`}>
                                                                    <AWSinput
                                                                        canEditPPR={autopopulateLabel === true ? false : canEditPPR}
                                                                        id={index}
                                                                        label=""
                                                                        placeholder={""}
                                                                        // value={"1"}
                                                                        showHelpTooltip={false}
                                                                        showOptional={false}
                                                                        value={cellIndex === 0 ? rowId.toString() : cell.value}
                                                                        onChangeFunc={
                                                                            (val) => {
                                                                                this.props.handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)
                                                                            }
                                                                        }
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.tableObj.rows[0].cells[cellIndex].validation}
                                                                        questionValue={questionValue}
                                                                        duplicateValidation={duplicateValidation}
                                                                        duplicateErrFlag={duplicateErrFlag}
                                                                    />
                                                                </Col>
                                                            )
                                                        case "TEXT":
                                                            let autopopulateText = questionValue.tableObj.rows[rowId].cells[cellIndex].autopopulate
                                                            if (autopopulateText === undefined) {
                                                                autopopulateText = false
                                                            }
                                                            return (
                                                                <Col span={cellIndex === 0 ? 1 : 4}
                                                                    className={cellIndex === 0 ? "mr-3 mt-0 pointer-events-none" : "mr-3 mt-0 "}
                                                                    key={`aws-tabcell-text-${cellIndex}`}
                                                                >
                                                                    <AWSinput
                                                                        id={index}
                                                                        canEditPPR={autopopulateText === true ? false : canEditPPR}
                                                                        label=""
                                                                        showHelpTooltip={false}
                                                                        showOptional={false}
                                                                        placeholder={""}
                                                                        value={cellIndex === 0 ? rowId.toString() : cell.value}
                                                                        onChangeFunc={(val) => {
                                                                            this.handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex, questionValue.tagName, cell)
                                                                        }
                                                                        }
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.tableObj.rows[0].cells[cellIndex].validation}
                                                                        questionValue={questionValue}
                                                                        duplicateValidation={duplicateValidation}
                                                                        duplicateErrFlag={duplicateErrFlag}
                                                                    />
                                                                    {
                                                                        (duplicateAffiliatePayerIdFlag === true && cell.tagName && cell.tagName === tagNameConstant.affiliate_payerId_cell && cell.value && cell.value.length === 12) && (
                                                                            <AWSError
                                                                                errorLabel="Identical Payer Id is not allowed"
                                                                            />
                                                                        )
                                                                    }
                                                                </Col>
                                                            )
                                                    }
                                                })
                                            }
                                            {this.state.resetAll ? '' : lastRowElementID > 1 ?
                                                <span
                                                    className="float-left minus-icon mr-3 mt-0"
                                                    onClick={
                                                        () => {
                                                            (quePermission.read === false && canEditPPR === true) ?
                                                                this.handleOnDeleteTable(questionValue.tableObj.rows[0].cells, questionValue, rowId) :
                                                                () => { }
                                                        }
                                                    }>
                                                    <img src={Minusicon} alt="minus" className="mt-1 pt-1 cursor-pointer" />
                                                </span>
                                                : ''}
                                            {this.state.resetAll ? <span className="float-left pt-2">
                                                <AWSCheckBox
                                                    canEditPPR={canEditPPR}
                                                    checked={selectedRowId.includes(rowId) ? true : false}
                                                    onChangeFunc={(val) => this.handleAllSelectedCheckBox(rowId)}
                                                /></span> : (lastRowElementID === rowId)
                                                && (questionValue.tableObj.maxRows !== rowId)
                                                && (
                                                    <img
                                                        src={Plusicon}
                                                        alt="plus"
                                                        key={`plusIcon_${rowId}`}
                                                        className="mt-1 pt-1 cursor-pointer"
                                                        onClick={() => {
                                                            (quePermission.read === false && canEditPPR === true) ?
                                                                this.handleOnAdd(questionValue.tableObj.rows[0].cells,
                                                                    questionValue.answer, questionValue, rowId, questionValue.tableObj.maxRows) :
                                                                () => { }
                                                        }} />
                                                )}
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </Col>
            </div>
        )
    }
}
export default DynamicTable;