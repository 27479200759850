import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import AWSTextArea from '../../common/AWStextarea/AWStextarea';
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSCheckBox from "../../common/AWScheckbox/AWSDynamicCheckBox";
import AWSDropdown from '../../common/AWSDropdown/AWSDropdown';
let options = [
    {
        optionValue: "01",
        optionKey: "01",
        defaultValue: null,
    },
    {
        optionValue: "02",
        optionKey: "02",
        defaultValue: null,
    },
    {
        optionValue: "03",
        optionKey: "03",
        defaultValue: null,
    }
]
class EditSection extends Component {

    state = {
        questionJson: {
            questionLabel: "",
            questionType: "",
            isVisible: "",
            isMandatory: "",
            row: "",
            column: "",
            createdBy: "henry",
            createdOn: "10/06/2019",
            titleName: "",
            isVisibleSection: "",
            help: "",
            queBlkSeq: '',
            value: ''
        }
    }

    componentDidMount() {
        let { titleName, isVisibleSection, sectionBlock } = this.props;
        this.setState({
            titleName: sectionBlock.queBlkHeader,
            isVisibleSection: sectionBlock.isVisible,
            help: sectionBlock.infoText,
            queBlkSeq:sectionBlock.queBlkSeq,
        })
    }

    handleOk = () => {
        let { titleName, isVisibleSection, help, queBlkSeq } = this.state;
        let { sectionBlock } = this.props;

        sectionBlock.queBlkHeader = titleName;
        sectionBlock.infoText = help;
        sectionBlock.isVisible = isVisibleSection;

        let sectionJson = {
            queBlock: [
                sectionBlock
            ]
        }
        this.props.handleOk(sectionJson)
    }
    /* Delete Section */
    deleteSection = () => {
        let { titleName, isVisibleSection, help } = this.state;
        let { sectionBlock } = this.props;

        sectionBlock.queBlkHeader = titleName;
        sectionBlock.infoText = help;
        sectionBlock.isVisible = isVisibleSection;

        let deleteSectionJson = {
            queBlock: [
                sectionBlock
            ]
        }
        //console.log(deleteSectionJson,"==sectionJson")
        this.props.handleDelete(deleteSectionJson)
    }

    handleChangeSectionModal = (sectionAttribute, value) => {
        this.setState({
            [sectionAttribute]: value
        })
    }

    render() {
        const { titleName, isVisibleSection, help,queBlkSeq } = this.state;
        return (
            <div className="edit-section-popup full-width popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">Edit Section
                        <span className="float-right cursor-pointer close-icon pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                            <Icon name="close"></Icon>
                        </span>
                    </p>
                </div>
                <div className="full-width body-wraper pt-5 mb-4 pb-4 pl-5 pr-0">
                    <div className="col-6 float-left pl-0">
                        <p className="dark-black mb-0">Section Name</p>
                        <div className="full-width">
                            <AWSInput
                                value={titleName}
                                onChangeFunc={(value) => { this.handleChangeSectionModal("titleName", value) }}
                            />
                        </div>
                    </div>
                    <div className="col-3 float-left">
                        <AWSDropdown
                            id="sequence"
                            label="Sequence"
                            options={options}
                            value={queBlkSeq}
                            handleOnSelect={(value) => { this.handleChangeSectionModal('queBlkSeq', value) }}
                        />
                    </div>
                    <div className="col-3 float-left pt-5 ml-0 pr-3">
                        <div className="full-width">
                            <AWSCheckBox
                                label={this.props.label}
                                value={isVisibleSection}
                                onChangeFunc={(value) => { this.handleChangeSectionModal("isVisibleSection", value) }}
                            />
                        </div>
                    </div>
                    <div className="full-width col-9 p-0 mr-0 mt-4 pr-4">
                        <AWSTextArea
                            value={help}
                            label={"Help"}
                            placeholder={""}
                            onChangeFunc={(value) => { this.handleChangeSectionModal("help", value) }}

                        />
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    {/* <span className="float-left pt-4 pl-4 mt-2">Created By <span className="dark-blue">Henry Mendez 10/06/2019</span></span> */}
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Delete Section"
                                    isIcon={false}
                                    onClick={() => this.deleteSection()}
                                />
                            </span>
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Update"
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditSection;