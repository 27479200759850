import React from 'react';
import _ from 'underscore';
import "../../common/AWSAdminTableIcon/AWSAdminTableIcon.scss"
export default class AWSTableDeleteIcon extends React.Component {

    render() {
            return (
                        <div className='delete-icon icons-styles' >
                            </div>
            );

    }
}

