import React, { Component } from 'react';
import docIcon from '../../images/awsreports.svg';
import terminationIcon from '../../images/awsterminate_white.svg';
import './DashboardSubmenu.scss';
import { Link } from "react-router-dom";
import * as roleNames from '../../constants/roleNames.const';
export default class DashboardSubmenu extends Component {
    state = {
        isTextVisble: false,
    }

    /**
     * This method make the toggle between the sub Menu's
    */
    toggleSubmenu = () => {
        this.setState({
            isTextVisble: !this.state.isTextVisble,
        })
    }

    render() {
        let userRole = this.props;
        return (
            <div className="dashboard-submenu" onClick={this.toggleSubmenu}>
                <ul className={this.state.isTextVisble ? "col-12 m-0 p-0" : "col-5 m-0 p-0"}>
                    {this.props.userRole !== roleNames.BILLING_TEAM && this.props.userRole !== roleNames.SOLUTION_PROVIDER && (
                        <li className={this.state.isTextVisble ? "border-btm pr-3 pl-3 pb-3 pt-3" : "border-none pr-3 pl-3 pb-3 pt-3"} >
                            <img src={docIcon} alt="file" />
                            <Link to="/Report"
                                to={
                                    { pathname: "/Report", state: this.props.locationData }
                                }
                            >
                                
                                {
                                    this.state.isTextVisble && <span className="font-size14 white ml-3">Reports</span>
                                }
                            </Link>
                        </li>
                    )}
                    {this.props.userRole === roleNames.SALES_REPRESENTATIVE && (<li className={this.state.isTextVisble ? "border-btm pr-3 pl-3 pb-3 pt-3" : "border-none pr-3 pl-3 pb-3 pt-3"} >
                        <img src={terminationIcon} alt="terminationIcon" className="termination-icon " />
                        <Link to="/Termination"
                            to={
                                { pathname: "/Termination", state: this.props.locationData }
                            }
                        >
                            {
                                this.state.isTextVisble && <span className="font-size14 white ml-3">Terminate PPR</span>
                            }
                        </Link>
                    </li>)}
                    
                </ul>
            </div>
        )
    }
}
