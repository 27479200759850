import React, { Component } from 'react';
import './AwsLoadingToast.scss'
class AWSLoadingToast extends Component {
    render() {
        return (
            <div>
                <div className="overlay-dashboard display-block"></div>
                <div  className={`snackbar show ${this.props.error === true ? 'error': ''}`}>{this.props.message}</div>
            </div>
            
        );
    }
}

export default AWSLoadingToast;
