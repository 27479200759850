// Env variables to pick the right envConfig
const local = 'localhost:3000';
const beta = 'beta.falcon.sales.aws.a2z.com';
const gamma = 'gamma.falcon.sales.aws.a2z.com';
const prod = 'falcon.sales.aws.a2z.com';
const hostName = window && window.location && window.location.hostname;
const envConfig =
    hostName === prod
        ? prod
        : hostName === gamma ?
        gamma: hostName === beta ? beta: local;

const domain =
    hostName === prod
        ? 'prod' : hostName === gamma ?
        'gamma': hostName === beta ? 'beta' :'local';

function getEnvConfig(getHost){
    let envConfigTest = [
        {env:"localhost:3000", envVar:"local"},
        {env:"beta.falcon.sales.aws.a2z.com", envVar:"beta"},
        {env:"gamma.falcon.sales.aws.a2z.com", envVar:"gamma"},
        {env:"falcon.sales.aws.a2z.com", envVar:"prod"}
    ];
    let newEnv = envConfigTest.filter((singleHost) => {
            if (getHost.includes(singleHost.env) || getHost ==='localhost:3000') {
               return singleHost.env
            } else{
                return singleHost.env === getHost
            }
    })[0];
    return newEnv;
}

export default {
    getEnvConfig,
    local,
    beta,
    gamma,
    prod,
    hostName,
    envConfig,
    domain
};
