import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import "../../Dashboard/AWSDashboard.scss";
import {Popover} from  "@amzn/awsui-components-react";
export default class AWSTableSLAIcon extends React.Component {
  render() {
    let { sla, lastModifiedDate, pprType, slaBreachmsg } = this.props;
    let date2_ms = new Date();
    let yesterday = date2_ms.setDate(date2_ms.getDate() - 1);
    let dayBeforeYesterday = date2_ms.setDate(date2_ms.getDate() - 2);
    let totalTime;
    if (
      sla && sla === "green"
    ) {
      return <div className="status-circle bg-green"></div>;
    } else if (
      sla && sla === "amber"
    ) {
      return <div className="text-center">
                  <Popover content={slaBreachmsg}>
                    <div className="status-circle bg-amber cursor-pointer"></div>
                  </Popover>
             </div>;
    } else if (sla && sla === "red") {
      if(pprType && pprType === 'Core'){
        let date1_ms = new Date(lastModifiedDate);
        let diffMs = yesterday - date1_ms;
        let hours = Math.floor(diffMs / 3.6e6);
        let minutes = Math.floor((diffMs % 3.6e6) / 6e4);
        //let seconds = Math.floor((diffMs % 6e4) / 1000);

        totalTime =
          "Red status surpassed by " +
          hours +
          " hours and " +
          minutes +
          " minutes. " + slaBreachmsg;
      }else{
        let date1_ms = new Date(lastModifiedDate);
        let diffMs = dayBeforeYesterday - date1_ms;
        let hours = Math.floor(diffMs / 3.6e6);
        let minutes = Math.floor((diffMs % 3.6e6) / 6e4);
       // let seconds = Math.floor((diffMs % 6e4) / 1000);

        totalTime =
          "Red status surpassed by " +
          hours +
          " hours and " +
          minutes +
          " minutes. " + slaBreachmsg;
      }
      return (
        <div className="text-center">
          <Popover content={totalTime}>
            <div className="status-circle bg-red cursor-pointer"></div>
          </Popover>
        </div>

      );
    } else {
      return "";
    }
  }
}

AWSTableSLAIcon.propTypes = {
  cell: PropTypes.any,
  label: PropTypes.string,
  onChangeFunc: PropTypes.func
};
