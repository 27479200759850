import React from "react";
import PropTypes from "prop-types";
import downloadIcon from '../../../images/dashborad_Download.svg';
import * as dashboardConstant from '../../../constants/dashboardConstants';
import "../../Dashboard/AWSDashboard.scss";
export default class AWSTableDownloadIcon extends React.Component {
  render() {
    let { dealStatus, userRole, type } = this.props;
    if (
      type === "finalContract" && dealStatus && dealStatus === dashboardConstant.DEAL_SIGNED
    ) {
      return (
          <div className="text-center cursor-pointer">
              <img src={downloadIcon} alt="download" />
              <span className="font-size14 white pl-3"></span>
          </div>
      );
    } else if (
      type === "executable" && dealStatus && (dealStatus === dashboardConstant.EXECUTABLE_RELEASED || dealStatus === dashboardConstant.DEAL_SIGNED) 
    ) {
      return (
          <div className="text-center cursor-pointer">
              <img src={downloadIcon} alt="download" />
              <span className="font-size14 white pl-3"></span>
          </div>
      );
    }else {
        return <></>;
      }
    }
}

AWSTableDownloadIcon.propTypes = {
  label: PropTypes.string,
  onChangeFunc: PropTypes.func
};
