import initialMetricsPublisher from './metrics.index';

// Count Metrics
const clickMetrics= (methodName)=>{
    const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(
        methodName,
    );
    actionMetricsPublisher.publishCounterMonitor(methodName, 1);
};

export {
    clickMetrics,
};


