export const TODAY = 'Today';
export const CURRENT_WEEK = 'Current Week';
export const CURRENT_MONTH = 'Current Month';
export const CURRENT_QUARTER = 'Current Quarter';
export const LAST_90_DAYS = "Last 90 Days";
export const CUSTOM_DATES = "Custom Dates";
export const DEAL_SIGNED = "Deal Signed";
export const EXECUTABLE_RELEASED = "Executable Released";
export const TERMINATION = "Termination";
export const OPEN_FOR_REVIEW = "Open for Review";
export const PENDING_APPROVAL_LABEL = "PENDING MY APPROVAL";
export const PENDING_REVIEW_LABEL = "PENDING MY REVIEW";
export const PPR_SUBMITTED_LABEL = "PPRs SUBMITTED";
export const SEARCH_RESULT_LABEL = "SEARCH RESULTS";
export const ALL_PPR_LABEL = "ALL PPRs";
export const LOADING_MESSAGE_FOR_PPR = "Please wait while we load the PPR lists";
export const CUSTOM_DATES_SELECTED = "Custom Dates";
export const GET_WIDGET_API_URL = "dashboard/getWidget";
export const ADVANCED_SEARCH_API_URL = "dashboardsearch";
export const PAYER_ID_LABEL = "Payer ID"
