import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Form from "./Dashboard/Form";
import DealStructure from './Dashboard/DealStructure'
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import React from 'react';
import AWSDashboard from './Dashboard/AWSDashboard';
import AWSLogin from './Login/AWSLogin';
import Termination from './Dashboard/Termination';
import AdminLandingPage from "./AdminModule/AdminLandingPage/AdminLandingPage";
import Auth from '../Auth/Auth';
import Callback from './Login/Callback';
import Report from '../components/Reports/Report';
import { CURRENT_PATH } from '../constants/routes.const';
// import AWSLoadingToast from "./common/AWSLoadingToast/AWSLoadingToast";

import axios from 'axios';
import AWSFlashBarContainer from './common/AWSFlashBar/AWSFlashBarContainer';
import PageNotFound from '../components/PageNotFound/PageNotFound';
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const history = createBrowserHistory();
const auth = new Auth();


const ProtectedRoute = ({ component: Component, ...rest }) => {

  let refreshToken = localStorage.getItem('cfart');
  // console.log("refreshToken = ", refreshToken);
  if (refreshToken) {
    auth.authLoginForRefreshToken()
  }
  return (
    <Route
      {...rest}
      render={props => {
        sessionStorage.setItem(CURRENT_PATH, props.location.pathname);
        if(auth.isAuthenticated()){
            if(props.location && props.location.state){
              return <Component {...props} /> 
            }else {
              auth.checkValidAuthentication();
              const userData = JSON.parse(localStorage.getItem("userDetails"));
              props.location.state = userData;
              if(userData && props.location.state){
                return <Component {...props} />
              }
            }
        } else  {
          localStorage.clear(); 
          return <Redirect to="/" />
        }
      }
      }
    />
  )
};

class App extends React.Component {

  state = {
    activateToast: false
  }

  handleAuthentication = (nextState, replace) => {
    let accesToken = localStorage.getItem("cfat");

    if (/code|error/.test(nextState.location.search)) {
      auth.handleAuthentication();
    }
  }

  componentDidMount() {

    axios.interceptors.response.use(null, err => {
      //console.log('err 123', err.response);
      // if (err.response === undefined) {        
      //   this.setState({
      //     activateToast: false
      //   },()=>{
      //     setTimeout(() => {
      //       history.push("/")
      //       this.setState({
      //         activateToast: false
      //       })
      //     }, 3500);
      //   })
      // }
  
      if (err.response.status === 401) {
        this.setState({
          activateToast: true
        },()=>{
          setTimeout(() => {
            this.setState({
              activateToast: false
            })
            localStorage.clear();
            history.push("/")
          }, 3500);
        })
      }
      return Promise.reject(err.response)  // It will return to respective file's catch block from where api call happened
      
      // else if (err.response.status >= 400) {
      //   console.log("Error Occured while performing the operation")
      // }
    });
  }

  render() {
    
    return (
      <div>
        <AWSFlashBarContainer />
        <Router >
          <Switch>
            <Route path="/login" exact component={AWSLogin} />
            {/* <Route path="/dashboard" exact component={AWSDashboard} /> */}
            <ProtectedRoute exact path="/dashboard" component={AWSDashboard} />
            <ProtectedRoute exact path="/create-ppr/:pprId?/:type?/:dealStatus?/:isSameCustomer?" component={Form} />
            <ProtectedRoute exact path="/Termination/:pprId?/:type?" component={Termination} />
            <ProtectedRoute exact path="/deal" component={DealStructure} />
            <ProtectedRoute exact path="/Report" component={Report} />
            <ProtectedRoute exact path="/admin" component={AdminLandingPage} />
            <Route
              path="/"
              exact
              render={props => {
                this.handleAuthentication(props);
                if (/access_token|id_token|code|error/.test(props.location.hash)) {
                  return <Callback {...props} />;
                } else {
                  return <AWSLogin {...props} />;
                }
              }}
            />
            {/**
             * Adding Default route for 404 Page (Not Found)
             * If it doesn't matches above routes
             * Sim: https://issues.amazon.com/issues/V520324801
             */}
            <Route component={PageNotFound}/>
          </Switch>
          {/* {this.state.activateToast && (
            <AWSLoadingToast
              message={"Your session has timed out. Redirecting to SSO ..."}
              duration={3500}
            />
          )} */}
        </Router>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};
export default hot(module)(App);
