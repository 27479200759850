import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import { getFormattedDate } from '../../../utils/dates'
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import DependentQuest from './DependentQuest';
import PermissionAndSeq from './PermissionAndSeq';
import './AddQuestion.scss';
import NavBar from '../NavBar/NavBar';
import Question from './Question';
import axios from 'axios';
import AWSConfig from '../../../config/AWSconfig';
import { getRateTableObj } from '../../../constants/qualifyingQuestionsConstants';

const config = new AWSConfig();

class EditQuestion extends Component {

    state = {
        selectedStage: 0,
        dataModel: [{ stageName: "Question" }, { stageName: "Permissions" }],
        menuIndex: 0,
        navMenuOption: "",
        validationListOnQueType: [],
        validationList: [],
        questionJson: undefined,
        options: []
    }

    componentDidMount() {
        let { dataColumn } = this.props;
        this.callValidationInputApi();
        this.callOptionsApi();
        this.setState({ questionJson: dataColumn })
    }

    /*
    @param {array selected validation}
    @return {add all validation to question json}
    */
    handleAddValidation = (validationArr) => {
        const { questionJson } = this.state;
        if (validationArr.length > 0) {
            questionJson.queBlock[0].questions[0].validation = [...validationArr];
        } else {
            if (questionJson && questionJson.queBlock[0] && questionJson.queBlock[0].questions[0])
                questionJson.queBlock[0].questions[0].validation = [];
        }
    }

    /*
    @param {string value}
    @return {set all validation which are for selected question type}
    */
    handleValidationListOption = (value) => {
        let { validationList } = this.state;
        let validationListOnQueType = validationList.filter(({ queType }) => queType === value)
        this.setState({
            validationListOnQueType
        })
    }

    /*
    @param {string value, integer index}
    @return {map options choosen for answer option to question json}
    */
    createOptions = (value, index) => {
        let { questionJson } = this.state;
        if (questionJson) {
            let optionArrayLength = questionJson.queBlock[0].questions[0].options.length
            if (index > (optionArrayLength - 1)) {
                this.createNewOptions(questionJson, value, index)
            } else {
                questionJson.queBlock[0].questions[0].options[index].optionKey = value;
                questionJson.queBlock[0].questions[0].options[index].optionValue = value;
                questionJson.queBlock[0].questions[0].options[index].optionOrder = index;
            }
        }
    }

    /*
    @param {array questionJson, string value, integer index} 
    @return {map value for answer by creating new option object}
    */

    createNewOptions = (questionJson, value, index) => {
        let newOptionObject = {
            sF_Id: null,
            questionId: null,
            optionValue: value,
            optionOrder: index,
            optionKey: value,
            IconSource: null,
            defaultValue: null,
            cellId: null
        }
        questionJson.queBlock[0].questions[0].options.push(newOptionObject);
    }

    /*
    @param {string value, integer index}
    @return {map checkbox values to questionJson}
    */

    setDefaultValueForAnswer = (value, index) => {
        const { questionJson } = this.state;
        const { defaultAnswer, options } = questionJson.queBlock[0].questions[0];
        for (let i = 0; i < defaultAnswer.length; i++) {
            defaultAnswer[i] = false;
        }

        options.map((opt) => {
            if (opt.optionOrder === index)
                questionJson.queBlock[0].questions[0].defaultValue = opt.optionValue
        })
        defaultAnswer[index] = value;
    }

    /*
    @param {string value, string type, integer index}
    @return {map values of different fields to questio json}
    */

    handleQuestionChange = (value, type, index) => {
        let { questionJson } = this.state;

        if (questionJson) {
            switch (type) {
                case 'questionType':
                    this.handleValidationListOption(value)
                    questionJson.queBlock[0].questions[0].queType = value
                    break;
                case 'isVisible':
                    questionJson.queBlock[0].questions[0].isVisible = value
                    break;
                case 'isMandatory':
                    questionJson.queBlock[0].questions[0].isMandatory = value
                    break;

                case 'queLabel':
                    questionJson.queBlock[0].questions[0].queLabel = value
                    break;

                case 'options':
                    this.createOptions(value, index)
                    break;
                case 'isDefault':
                    this.setDefaultValueForAnswer(value, index);
                    break;
                case 'helpText':
                    questionJson.queBlock[0].questions[0].helpText = value;
                    break;

            }
            this.setState({
                questionJson
            })
        }
    }
    /*
    @param none
    @return {get api call for validation for all question}
    */

    callValidationInputApi = () => {
        let { questionJson } = this.state;
        let { dataColumn } = this.props;
        let validationListApi = `${config.amazonFalconbaseUrl}validation`;
        axios({
            method: "GET",
            url: validationListApi
        })
            .then(resp => {
                let compareQueType = dataColumn.queBlock[0].questions[0].queType;
                let validationListOnQueType = resp.data.filter(({ queType }) => queType === compareQueType)

                this.setState({
                    validationList: resp.data,
                    validationListOnQueType
                })
            }).catch(err => console.log(err));
    }

    /*
   @param none
   @return {get api call for question}
   */
    callOptionsApi = () => {
        const optionsApi = `${config.amazonFalconbaseUrl}options`
        axios({
            method: 'GET',
            url: optionsApi
        }).then(res => {
            const options = res.data;
            this.setState({ options });
        }).catch(err => console.log(err));
    }

    /*
    @param none
    @return {handle the modal/pop up close}
    */
    handleOk = () => {
        const questionJson = this.state.questionJson;
        const { queBlock } = questionJson;
        const { questions } = queBlock[0];
        const { queType } = questions[0];
        const { questionId, option } = (questions[0].dependentOn[0] === undefined)
            ? { "questionId": null, "option": null }
            : queBlock[0].questions[0].dependentOn[0];
        if (queType === 'RATETABLE') {
            this.setState({
                questionJson: {
                    ...questionJson,
                    queBlock: [{
                        ...queBlock[0],
                        questions: [{
                            ...questions[0],
                            tableObj: getRateTableObj(questions[0].options)
                        }]
                    }]
                }
            });
        }
        if (questionId === null && option === 'Select') {
            this.state.questionJson.queBlock[0].questions[0].dependentOn = [];
        }
        this.props.handleOk(this.state.questionJson)
    }
    /*
    @param {integer index}
    @return {map removed options and checked box value to questionJson}
    */

    removeOptions = (index) => {
        const { questionJson } = this.state;
        if (questionJson) {
            const { options, defaultAnswer } = questionJson && questionJson.queBlock[0].questions[0];
            if (index === undefined) {
                const newOptions = [];
                questionJson.queBlock[0].questions[0].options = newOptions;
            } else {
                const newOptions = options.slice(0, index).concat(options.slice(index + 1, options.length));
                const newDefaultAnswer = defaultAnswer.slice(0, index).concat(defaultAnswer.slice(index + 1, defaultAnswer.length));
                questionJson.queBlock[0].questions[0].options = newOptions;
                questionJson.queBlock[0].questions[0].defaultAnswer = newDefaultAnswer;
            }
            return;
        }
    }

    /*
    @param {integer index of menu}
    @return {map to to particular tab viz question, permission and...}
    */
    handleSelectTab = menuIndex => this.setState({ selectedStage: menuIndex })

    /*
     @param {string value, string type, number questionID, array depOn }
     @return {map values to questionJson dependentOn and parentDepOn attribute}
    */
    setDependentOn = (value, type, questionID) => {
        const { questionJson } = this.state;
        if (questionJson.queBlock[0].questions[0].dependentOn.length > 0) {
            switch (type) {
                case 'event':
                    questionJson.queBlock[0].questions[0].dependentOn[0].event = value;
                    break;
                case 'alertMessage':
                    questionJson.queBlock[0].questions[0].dependentOn[0].alertMessage = value;
                    break;
                case 'option':
                    questionJson.queBlock[0].questions[0].dependentOn[0].option = value;
                    break;
            }
            if (questionID !== null)
                questionJson.queBlock[0].questions[0].dependentOn[0].questionId = questionID;
            this.setState({ questionJson })
        }
    }

    /**
     * This function updates permission for different user type
     * @param {*} value text
     * @param {*} userRole text
     */

    handlePermission = (value, userRole) => {
        let _userRole = userRole.split(' ').join('_');

        const { questionJson } = this.state
        const { permissionSeq } = questionJson.queBlock[0].questions[0];

        switch (_userRole) {
            case 'Sales_Manager':
                this.setPermissionsHandler(permissionSeq, value[0], 'Sales_Manager');
                break;
            case 'SCE_Owner':
                this.setPermissionsHandler(permissionSeq, value[0], 'SCE_Owner');
                break;
            case 'PPO_Owner':
                this.setPermissionsHandler(permissionSeq, value[0], 'PPO_Owner');
                break;
            case 'Sales_Representative':
                this.setPermissionsHandler(permissionSeq, value[0], 'Sales_Representative');
                break;
            default:

        }
        this.setState({ questionJson });
    }

    setPermissionsHandler = (permission, val, userRole) => {
        let permissionKey = Object.keys(permission[0][userRole]);
        for (let i = 0; i < permissionKey.length; i++) {
            if (permissionKey[i] === val) {
                permission[0][userRole][`${permissionKey[i]}`] = true;
            } else {
                permission[0][userRole][`${permissionKey[i]}`] = false;
            }
        }
        return;
    }

    /**
    * This method updates the permission state for diff user roles
    * @param {*} rowIndex rowIndex
    */
    handleSequencing = (rowIndex, colIndex, type) => {
        const { questionJson } = this.state;
        let rowNum = questionJson.queBlock[0].questions[0].rowNum;

        if (type === "removeSequence" && rowNum === rowIndex) {
            questionJson.queBlock[0].questions[0].rowNum = null;
            questionJson.queBlock[0].questions[0].columnNum = null;
        } else {
            questionJson.queBlock[0].questions[0].rowNum = rowIndex;
            questionJson.queBlock[0].questions[0].columnNum = colIndex;
        }

        this.setState({ questionJson });
    }

    render() {
        let { validationListOnQueType, questionJson, selectedStage } = this.state;
        const { location: { state: { firstName, lastName } } } = this.props;

        return (
            <div className="addquestion-ppr popup full-width">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-4 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold close-icon">Edit Question<span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                        <Icon name="close"></Icon>
                    </span></p>
                </div>
                <div className="col-12 float-right p-0">
                    <div className='full-width border-bottom'>
                        <div className="col-12 pl-5 pt-5 float-left">
                            <NavBar
                                selectedStage={selectedStage}
                                handleSelectTab={(menuIndex) => { this.handleSelectTab(menuIndex) }}
                                dataModel={this.state.dataModel}
                                navMenuOption={this.state.navMenuOption}
                            />
                        </div>
                    </div>
                </div>
                {selectedStage === 0 &&
                    <Question
                        editQuestion={true}
                        options={this.state.options}
                        questionJson={questionJson}
                        validationListOnQueType={validationListOnQueType}
                        removeOptions={this.removeOptions}
                        handleQuestionChange={(value, type, index) => { this.handleQuestionChange(value, type, index) }}
                        handleAddValidation={(val) => this.handleAddValidation(val)}
                    />
                }
                {selectedStage === 1 &&
                    <PermissionAndSeq
                        questionJson={questionJson}
                        selectedStage={this.props.selectedStage}
                        sectionIndex={this.props.sectionIndex}
                        updateQuestion={true}
                        oldDataModel={this.props.dataModel}
                        handlePermission={(value, userRole) => { this.handlePermission(value, userRole) }}
                        handleSequencing={(rowIndex, colIndex, type) => { this.handleSequencing(rowIndex, colIndex, type) }}
                    />}
                {/* {selectedStage === 2 &&
                    <DependentQuest
                        editQuestion={true}
                        questionJson={questionJson}
                        setDependentOn={(value, type, questionId) => this.setDependentOn(value, type, questionId)}
                    />
                } */}
                <div className="footer-wraper full-width pr-4">
                    <span className="float-left pt-4 pl-4">Created By <span className="dark-blue">{firstName && `${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-0 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={this.props.type === "editQuestionPPR" ? "Update" : "Add"}
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditQuestion;