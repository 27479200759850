import React, { Component } from "react";
import AWSbutton from "../common/AWSbutton/AWSbutton";
import AdvancedSearch from './AdvancedSearch';
import AWSDashboardCard from "../common/AWSDashboardCard/AWSDashboardCard";
import { Link } from "react-router-dom";
import AWSDashboardheader from "../common/AWSDashboardheader/AWSDashboardheader";
import AWSDashboardData from "./AWSDashboardData";
import "./AWSDashboard.scss";
import axios from "axios";
import DashboardSubmenu from "../Dashboard/DashboardSubmenu";
import Col from "../common/AWSCol/AWSCol";
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
// import Popover from "./TablePopOver.js";
import DatePicker from "react-datepicker";
import "../common/AWSInputDate/AWSinputDate.scss";
import _ from "underscore";
import AWSModal from "../common/AWSModal/AWSModal";
import "../common/AWSTableFilter/AWSTableFilter.scss";
import { getTokenBasedOnRole } from '../../utils/common';
// import "tabulator-tables/dist/css/tabulator.min.css";
import AWSConfig from "../../config/AWSconfig";
import AWSDropdown from "../common/AWSDropdown/AWSDropdown";
import { emailNotification } from "../../services/endPoints";
import { isValidFile, base64toBlob } from '../../utils/common';
import PropTypes from "prop-types";
import infoHelpIcon from '../../images/awsfalconhelp.png';
import searchWhite from '../../images/search_white.svg';
// import searchWhite from '../../images/Advance_search01.svg';
import { clickMetrics } from "../../metrics/metrics.common";
import * as roleNames from '../../constants/roleNames.const';
global.moment = require("moment");
import Video from "../../images/Play.png";
import { Popover, Icon, Modal } from "@amzn/awsui-components-react";
import ErrorPage from '../PageNotFound/DashboardServerError';
import VideoIcon from '../common/Icon/VideoIcon/VideoIcon';
import * as dashboardConstant from '../../constants/dashboardConstants';
import Auth from "../../Auth/Auth";
import AWScheckbox from "../common/AWScheckbox/AWScheckbox";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../../utils/sessionStorageUtils';
import { createStateForDashboardPage } from '../../utils/advancedSearch.util';
import STORAGE_NAMES from '../../constants/storageNames.const';
import { isSalesManager, isSceOwner, isPublicSector, isOnBoardingTeam, isSPUser, isPPOOrChina } from '../../utils/userCheck.util';

const config = new AWSConfig();
const auth = new Auth();
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
let baseUrl;
let baseUrlForUser;
let currentDate = moment();
let DateRanges = [
    {
        optionValue: dashboardConstant.TODAY,
        optionKey: dashboardConstant.TODAY,
        defaultValue: null
    },
    {
        optionValue: dashboardConstant.CURRENT_WEEK,
        optionKey: dashboardConstant.CURRENT_WEEK,
        defaultValue: null
    },
    {
        optionValue: dashboardConstant.CURRENT_MONTH,
        optionKey: dashboardConstant.CURRENT_MONTH,
        defaultValue: null
    },
    {
        optionValue: dashboardConstant.CURRENT_QUARTER,
        optionKey: dashboardConstant.CURRENT_QUARTER,
        defaultValue: null
    },
    {
        optionValue: dashboardConstant.LAST_90_DAYS,
        optionKey: dashboardConstant.LAST_90_DAYS,
        defaultValue: null
    },
    {
        optionValue: dashboardConstant.CUSTOM_DATES,
        optionKey: dashboardConstant.CUSTOM_DATES,
        defaultValue: null
    }
];
const dayStart = new Date(currentDate.clone().startOf("day").utc().format());

export default class AWSDashboard extends Component {
    state = {
        displayErrorPage: false,
        pprcreatedList: [],
        submittedList: [],
        dashboardData: [],
        pendingSMList: [],
        pendingExecList: [],
        pendingCusTermsList: [],
        openForReviewList: [],
        pendingLegalList: [],
        loadAssignedUserData: {},
        pprCreatedCount: "",
        pendingSMApprovalCount: "",
        pendingSalesManagerApprovalCount: "",
        pendingServiceApprovalCount: "",
        pendingExecutableCount: "",
        fullyExecutedCount: "",
        SalesManagerApprovalCount: "",
        pendingIGTApprovalCount: "",
        pendingMyReviewCount: "",
        selected: true,
        loadingError: false,
        activateToast: false,
        loadingMessageDuration: 1000,
        loadingMessage: "",
        activeTab: "",
        firstname: "",
        visible: false,
        lastname: "",
        userrole: "",
        userAlias: "",
        allPPRtab: false,
        submittedPPRtab: true,
        salesManagertab: false,
        sceOwnertab: false,
        customerTermstab: false,
        openForReviewtab: false,
        pendingLegaltab: false,
        selectedRoleUser: "",
        SelectedDate: dashboardConstant.LAST_90_DAYS,
        locationData: this.props.location && this.props.location.state,
        startDate: new Date(moment(dayStart).utc().subtract(90, 'days').format()),
        endDate: new Date(),
        maxDate: new Date(),
        submittedPPRLabel: "PPRs SUBMITTED",
        pprsubmitted: [],
        popoverVisible: false,
        clonepopup: false,
        deletePPRpopup: false,
        inActivepopup: false,
        openReviewPopup: false,
        tablepopOver: [],
        AssignDealUsers: [],
        typeOfPPR: "",
        pprID: "",
        dealType: "",
        userRoleAlias: "",
        inAcitveReq: "",
        nextLastEvaluatedKey: [],
        advancedSearchPopup: false,
        videoPopup: false,
        dealStatus: '',
        allPPRTabLabel: "ALL PPRs",
        allPPRIncludeDealSigned: false
    };

    constructor(props) {
        super(props);
        this.advancedSearchRef = React.createRef();
        baseUrl = `${config.amazonFalconbaseUrl}`;
        baseUrlForUser = `${config.userManagementbaseUrl}`;
    }

    /**
     * This lifecycle method will get the user details from login and set the state
     * Also it will call the Dashboard records API to get the records
     */
    componentDidMount() {
        this.setState({
            locationData: this.props.location && this.props.location.state
        })
        if (this.props.location.state && this.props.location.state.firstName) {
            this.setState(
                {
                    firstname:
                        this.props.location.state && this.props.location.state.firstName,
                    lastname:
                        this.props.location.state && this.props.location.state.lastName,
                    userrole:
                        this.props.location.state && this.props.location.state.userRole,
                    userAlias: this.props.location.state && this.props.location.state.alias,
                    locationData: this.props.location && this.props.location.state,
                    displayErrorPage: false
                },
                () => {
                    this.getDashboardData();
                    this.intervalId = setInterval(() => this.getTilesRefresh(), 60000);
                }
            );
        } else {
            localStorage.clear();
            this.props.history.push("/");
        }
    }

    /**
     * this function will get the dashboard records based on date range, user and role
     */
    getDashboardData = async () => {
        try {
            const { SEARCH_RESULT_LABEL, ALL_PPR_LABEL, PENDING_APPROVAL_LABEL, PENDING_REVIEW_LABEL, 
                PPR_SUBMITTED_LABEL, LOADING_MESSAGE_FOR_PPR, CUSTOM_DATES_SELECTED, GET_WIDGET_API_URL, ADVANCED_SEARCH_API_URL } = dashboardConstant;
            const advancedSearchParams = getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS);
            const advancedSearchEnable = advancedSearchParams !== null;
            const currentUserRole = this.state.userrole;
            this.setState({
                displayErrorPage: false,
                loadingError: false,
                activateToast: true,
                loadingMessage: LOADING_MESSAGE_FOR_PPR
            });
    
            let endDate = this.state.endDate;
            if (this.state.SelectedDate === CUSTOM_DATES_SELECTED) {
                endDate = moment(this.state.endDate).clone().startOf("day").utc().add(24, 'hours').format();
            }
            let ReqData = {
                AWSAlias: this.state.userAlias,
                userRole: currentUserRole,
                startDate: this.state.startDate,
                endDate: endDate
            };
            let dashBoardUrl = baseUrl + GET_WIDGET_API_URL;
            const advancedSearchListAPI = `${config.awsFalconSearchUrl}${ADVANCED_SEARCH_API_URL}`;
            const dashboardAPIParam = { method: "POST", data: ReqData, url: dashBoardUrl };
            const advancedSearchAPIParam = { method: "POST", url: advancedSearchListAPI, data: advancedSearchParams };
            const responses = await Promise.all([axios(dashboardAPIParam), (advancedSearchEnable) ? axios(advancedSearchAPIParam) : null ]).catch(err => console.log(err));
            const dashboardAPIResponse = responses[0];
            const advancedSearchData = (advancedSearchEnable) ? responses[1] : [];
            /**
             * Based on the user role 
             * we used to set allPPRActiveTab and submittedPPrActive flags for different views
             */
            const isSPUserOrBillingTeam = isOnBoardingTeam(currentUserRole) || isSPUser(currentUserRole);
            const restRolesForLabel = isPPOOrChina(currentUserRole) || isSceOwner(currentUserRole) || isPublicSector(currentUserRole);
            const allPPRActive = isSPUserOrBillingTeam;
            const submittedPPRActive = !isSPUserOrBillingTeam;
            /**
             * We set different labels based on Sales Manager and Other users.
             */
            const pprSubmittedLabel = isSalesManager(currentUserRole) ? PENDING_APPROVAL_LABEL : (restRolesForLabel) ? PENDING_REVIEW_LABEL : PPR_SUBMITTED_LABEL;
            const allPPRTabLabel = (advancedSearchEnable) ? SEARCH_RESULT_LABEL : ALL_PPR_LABEL;
            const allPPRtab = (advancedSearchEnable) ? true : allPPRActive;
            const submittedPPRtab = (advancedSearchEnable) ? false : submittedPPRActive;
            const dashboardData =  dashboardAPIResponse.data;
            const pprList = (advancedSearchEnable) ? advancedSearchData.data : dashboardData.tableData.allPPR;
            this.setState(createStateForDashboardPage({ dashboardData, pprList, pprSubmittedLabel, submittedPPRtab, allPPRtab, allPPRTabLabel }));
            this.handleOnTabClick(null, getSessionStorage(STORAGE_NAMES.CURRENT_TAB))
        } catch (error) {
            this.setState({ displayErrorPage: true });
        }
    };

    getTilesRefresh = () => {
        let { locationData } = this.state;
        let endDate = this.state.endDate;
        if (this.state.SelectedDate === "Custom Dates") {
            endDate = moment(this.state.endDate).clone().startOf("day").utc().add(24, 'hours').format();
        }
        let ReqData = {
            AWSAlias: this.state.userAlias,
            userRole: this.state.userrole,
            startDate: this.state.startDate,
            endDate: endDate
        };
        let refreshUrl = baseUrl + "dashboard/updateTiles";
        axios({
            method: "POST",
            data: ReqData,
            url: refreshUrl
        }).then(resp => {
            this.setState({
                dashboardData: resp.data && resp.data.tileData
            });
        }).catch(() => {
            clearInterval(this.intervalId);
            auth.getParamsDetailForToken();
        });
    }

    /**
     * This function will set the state value for dropdown select
     * @param {*} value value of the particular state to be set
     */
    handleOnSelect = value => {
        this.setState({
            selectedRoleUser: value
        });
    };

    /**
     * This lifecycle method is used to hide the popover on component unmount
     */
    componentWillUnmount() {
        if (this.state.popoverVisible === true) {
            document.removeEventListener("mousedown", this.hidePopover, false);
        }
        clearInterval(this.intervalId);
    }

    /**
     * this function will save the addtional fields
     */
    callApiToUpdateAdditionalColumnStatus = () => {
        let columnUpdateUrl = baseUrl + "dashboard/saveColumns";
        let updatedColumnData = {
            AWSAlias: this.state.userAlias,
            userRole: this.state.userrole,
            columns: this.state.tablepopOver
        };
        // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

        axios({
            method: "POST",
            data: updatedColumnData,
            url: columnUpdateUrl
        }).then(() => {
        }).catch(err => console.log(err));
    };


    /**
     * This function will be called whenever the tiles getting clicked
     * @param {*} dealLabel label of the particular tile
     * @param {*} dealStatus status of the particular tile
     */
    OnDashboradTilesclick = (dealLabel, dealStatus) => {
        if (dealLabel === "PENDING CONTRACT UPLOAD") {
            this.setState({
                allPPRtab: true,
                submittedPPRtab: false
            })
        } else if (dealLabel === "FULLY EXECUTED") {
            this.setState({
                submittedPPRtab: true,
                allPPRtab: false
            })
        }
        if (this.state.userrole !== roleNames.BILLING_TEAM) {
            this.setState({
                loadingError: false,
                activateToast: true,
                loadingMessage: "Please wait while loading the PPR list"
            });

            let endDate = this.state.endDate;
            if (this.state.SelectedDate === "Custom Dates") {
                endDate = moment(this.state.endDate).clone().startOf("day").utc().add(24, 'hours').format();
            }

            let ReqData = {
                AWSAlias: this.state.userAlias,
                userRole: this.state.userrole,
                label: dealLabel,
                dealStatus: dealStatus,
                startDate: this.state.startDate,
                endDate: endDate
            };
            let dashobardUrl = baseUrl + "dashboard/getTileData";
            // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

            axios({
                method: "POST",
                data: ReqData,
                url: dashobardUrl
            }).then(resp => {
                this.setState({
                    submittedList: resp.data && resp.data.pprList,
                    submittedPPRLabel: resp.data.label,
                    submittedPPRtab: true,
                    allPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: false,
                    activateToast: false,
                    openForReviewtab: false,
                    pendingLegaltab: false,
                    allPPRTabLabel: "ALL PPRs"
                });
            }).catch(err => console.log(err));
        }

    };

    /**
     * This function will be called whenever the All PPR Pagination
     * @param {*} dealLabel label of the particular tile
     * @param {*} dealStatus status of the particular tile
     */
    OnAllPPRsclick = (dealLabel, type) => {
        this.setState({
            loadingError: false,
            activateToast: true,
            loadingMessage: "Please wait while loading the PPR list"
        });

        let endDate = this.state.endDate;
        if (this.state.SelectedDate === "Custom Dates") {
            endDate = moment(this.state.endDate).clone().startOf("day").utc().add(24, 'hours').format();
        }

        let ReqData = {
            AWSAlias: this.state.userAlias,
            userRole: this.state.userrole,
            label: dealLabel,
            startDate: this.state.startDate,
            endDate: endDate,
            includeDealSigned: this.state.allPPRIncludeDealSigned
        };

        if (this.state.nextLastEvaluatedKey.length > 0 && type === "Next") {
            ReqData.LastEvaluatedKey = this.state.nextLastEvaluatedKey[
                this.state.nextLastEvaluatedKey.length - 1
            ];
        } else if (
            this.state.nextLastEvaluatedKey.length > 0 &&
            type === "Previous"
        ) {
            let tempArr = this.state.nextLastEvaluatedKey;
            ReqData.LastEvaluatedKey = tempArr[tempArr.length - 3];
            tempArr.splice(-2, 2);

            this.setState({
                nextLastEvaluatedKey: tempArr
            });
        } else {
            ReqData.LastEvaluatedKey = this.state.nextLastEvaluatedKey[0];
        }
        let dashobardUrl = baseUrl + "dashboard/getTileData";
        // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
        axios({
            method: "POST",
            data: ReqData,
            url: dashobardUrl
        }).then(resp => {
            if (resp.data && resp.data.LastEvaluatedKey) {
                this.state.nextLastEvaluatedKey.push(resp.data.LastEvaluatedKey);
            }
            this.setState({
                pprcreatedList: resp.data && resp.data.pprList,
                submittedPPRtab: false,
                allPPRtab: true,
                salesManagertab: false,
                sceOwnertab: false,
                customerTermstab: false,
                activateToast: false,
                openForReviewtab: false,
                pendingLegaltab: false,
                allPPRTabLabel: "ALL PPRs"
            });
        }).catch(err => console.log(err));
    };

    /**
     * This function will show or hide the modal window for assign / clone deals
     * @param {*} type type of action to decide the case
     */
    handleOkOrCancelOfModal = type => {
        switch (type) {
            case "assignDeals":
                this.setState({ visible: false });
                break;
            case "clonePopup":
                this.setState({ clonepopup: false });
                break;
            case "inActivepopup":
                this.setState({ inActivepopup: false });
                break;
            case "openReviewPopup":
                this.setState({ openReviewPopup: false });
                break;
            case "deletePPRPopup":
                this.setState({ deletePPRPopup: false });
                break;
        }
    };

    /**
     * This function will show or hide the modal window for assign / clone deals
     * @param {*} type type of action to decide the case
     */
    handleAssignRole = () => {
        if (typeof this.state.selectedRoleUser == typeof "string") {
            this.setState({
                loadingError: false,
                activateToast: true,
                loadingMessage: "Please wait while Assigning the PPR and load Dashobard"
            },
                () => {
                    let ReqData = {
                        AWSAlias: this.state.userAlias,
                        createdById: this.state.loadAssignedUserData.createdById,
                        userRole: this.state.userrole,
                        pprId: this.state.loadAssignedUserData.pprId,
                        assignedTo: this.state.selectedRoleUser,
                        accountName: this.state.loadAssignedUserData.accountName,
                        Sales_Representative: this.state.loadAssignedUserData
                            .Sales_Representative,
                        Sales_Manager: this.state.loadAssignedUserData.Sales_Manager
                    };
                    let dashobardUrl = baseUrl + "cfppr/assign";
                    // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

                    axios({
                        method: "POST",
                        data: ReqData,
                        url: dashobardUrl
                    })
                        .then(resp => {
                            if (resp.data.statusCode === 400) {
                                this.setState(
                                    {
                                        loadingError: false,
                                        activateToast: true,
                                        loadingMessage: resp.data.message
                                    },
                                    () => {
                                        setTimeout(() => {
                                            this.setState({
                                                visible: false,
                                                activateToast: false
                                            });
                                        }, 3000);
                                    }
                                );
                            } else {
                                this.setState({
                                    visible: false,
                                    activateToast: false
                                });
                                // send email after ppr is assigned

                                const pprId = this.state.loadAssignedUserData.pprId ? Number(this.state.loadAssignedUserData.pprId.split('-')[1]) : '';
                                const data = {
                                    ...ReqData,
                                    pprId: pprId
                                };
                                this.sendEmailUpdatesOnAssign(data);
                                this.getDashboardData();
                            }
                        })
                        .catch(error => {
                            this.setState({
                                loadingMessage: "Server error",
                                loadingError: true
                            });
                        });
                });
        } else {
            this.setState({
                loadingMessage: "Please select a user",
                loadingError: false,
                activateToast: true
            },
                () => {
                    setTimeout(() => {
                        this.setState({

                            activateToast: false
                        });
                    }, 3000);
                }
            );
        }
    };

    sendEmailUpdatesOnAssign = (data) => {
        // axios call to send notification
        const emailUrl = "email/sendMessage"
        emailNotification(emailUrl, data).catch(err => console.log(err));
    }

    /**
     * This function is used to navigate the user to the create PPR page
     */
    handleButtonClick = () => {
        this.props.history.push({
            pathname: "/createppr"
        });
    };

    /**
     * this function will update table column visible status
     * @param {*} value value of the additional fileds in the column
     * @param {*} name name of the particular field
     */
    updateExtaFieldsData = (value, name) => {
        this.setState(prevState => ({
            tablepopOver: prevState.tablepopOver.map(el =>
                el.optionValue === name ? { ...el, status: value } : el
            )
        }));
    };

    /**
     * this function will be called when the dashboard record is getting clicked
     * @param {*} type type of the ppr to view or delete
     * @param {*} pprid id of the ppr record
     * @param {*} dealtype deal type of the particular deal view / termination
     * @param {*} userRoleAlias user role of the logged in user
     */
    onView = (type, pprId, dealtype, userRoleAlias) => {
        let { locationData } = this.state;
        this.setState({
            popoverVisible: false
        });
        let pathNameForPPr = "";
        if (dealtype === "Termination") {
            if (locationData) {
                locationData.userRoleAlias = userRoleAlias;
                pathNameForPPr = "/Termination/" + pprId + "/" + type;
            } else {
                pathNameForPPr = "/";
            }
            let location = {
                pathname: pathNameForPPr,
                state: locationData
            };
            this.props.history.push(location);
        } else {
            if (locationData) {
                locationData.userRoleAlias = userRoleAlias;
                pathNameForPPr = "/create-ppr/" + pprId + "/" + type;
            } else {
                pathNameForPPr = "/";
            }
            let location = {
                pathname: pathNameForPPr,
                state: locationData
            };
            this.props.history.push(location);
        }
    };

    /**
     * this function will be called clone is getting called
     * @param {*} isSameCustomer check the customer of the particular ppr
     */
    onClone = isSameCustomer => {
        const {
            typeOfPPR,
            pprID,
            dealType,
            userRoleAlias,
            locationData,
            dealStatus
        } = this.state;
        let pathNameForPPr = "";
        switch (isSameCustomer) {
            case "yes":
                this.setState({
                    popoverVisible: false
                });
                if (dealType === "Termination") {
                    // this.props.history.push("/Termination/" + pprID + "/" + typeOfPPR);
                } else {
                    if (locationData) {
                        locationData.userRoleAlias = userRoleAlias;
                        pathNameForPPr = `/create-ppr/${pprID}/${typeOfPPR}/${dealStatus}/${isSameCustomer}`;
                    } else {
                        pathNameForPPr = "/";
                    }
                    let location = {
                        pathname: pathNameForPPr,
                        state: locationData
                    };
                    this.props.history.push(location);
                }
                break;
            case "no":
                this.setState({
                    popoverVisible: false
                });
                if (dealType === "Termination") {
                    this.props.history.push("/Termination/" + pprID + "/" + typeOfPPR);
                } else {
                    if (locationData) {
                        locationData.userRoleAlias = userRoleAlias;
                        pathNameForPPr = `/create-ppr/${pprID}/${typeOfPPR}/${dealStatus}/${isSameCustomer}`;
                    } else {
                        pathNameForPPr = "/";
                    }
                    let location = {
                        pathname: pathNameForPPr,
                        state: locationData
                    };
                    this.props.history.push(location);
                }
                break;
        }
    };

    /**
     * this function will be called when clicking the delete
     * @param {*} event click event
     * @param {*} id id of the particular ppr
     */
    onDelete = () => {
        const {
            pprID
        } = this.state;
        document.removeEventListener("mousedown", this.hidePopover, false);
        this.setState({
            loadingError: false,
            activateToast: true,
            deletePPRpopup: false,
            loadingMessage: "Deleting ..."
        });
        event.stopPropagation();

        let DeleteData = {
            AWSAlias: this.state.userAlias,
            userRole: this.state.userrole,
            pprName: pprID
        };

        // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

        axios({
            method: "DELETE",
            url: baseUrl + "cfppr/ppr",
            data: DeleteData
        })
            .then(resp => {
                this.setState(
                    {
                        activateToast: false
                    },
                    () => {
                        this.getDashboardData();
                    }
                );
            })
            .catch(() => {
                this.setState(
                    {
                        loadingError: true,
                        activateToast: true,
                        loadingMessage: "Failed to delete"
                    },
                    () => {
                        this.setState({
                            activateToast: false
                        });
                    }
                );
            });
    };

    /**
     * this function will be called when clicking the delete
     * @param {*} event click event
     * @param {*} id id of the particular ppr
     */
    onDownload = (event, id, type) => {
        const idVal = id.split('-');
        this.setState({
            loadingError: false,
            activateToast: true,
            loadingMessage: "Attachment Downloading ..."
        });
        const attachementListAPI = (type && type === 'executable') ?
            `${baseUrl}AttachmentList/execattch?pprId=` + idVal[idVal.length - 1] :
            `${baseUrl}AttachmentList/releasedattach?pprId=` + idVal[idVal.length - 1];

        axios({
            method: 'GET',
            url: attachementListAPI,
        }).then(resp => {
            const fileExt = resp.data.fileName.split('.');
            const contentType = `application/${fileExt[fileExt.length - 1]}`;
            var blob = base64toBlob(resp.data.base64String, contentType);
            var url = window.URL.createObjectURL(blob);
            const fileNameWithsubString = resp.data && resp.data.fileName;
            const fileName = (type === 'executable') ? fileNameWithsubString.replace('exec_', '') : fileNameWithsubString.replace('released_', '');

            this.downloadFile(url, fileName);
            this.setState({
                activateToast: false
            });
        }).catch(err => console.log(err));

    };

    downloadFile = function (sUrl, fileName) {

        //If in Chrome or Safari or Firefox - download via virtual link click
        //Creating new link node.
        var link = document.createElement('a');
        link.href = sUrl;

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            // var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
            link.download = fileName;
        }

        //Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
        // Force file download (whether supported by server).
        var query = '?download';

        window.open(sUrl + query);
    }

    /**
     * this function will be called to assign the ppr to other user roles
     * @param {*} pprId id of the particular ppr
     * @param {*} dealtype type of the deal created
     * @param {*} userRoleAlias role of the logged in user
     */
    onAssign = (pprId, dealtype, accountName, userRoleAlias, salesRep, SalesManager) => {
        let msg =
            this.state.userrole === roleNames.SALES_REPRESENTATIVE
                ? roleNames.SALES_REPRESENTATIVE_LOADING
                : this.state.userrole === roleNames.SALES_MANAGER
                    ? roleNames.SALES_MANAGER_LOADING
                    : this.state.userrole === roleNames.SCE_OWNER
                        ? roleNames.SCE_OWNER_LOADING
                        : this.state.userrole === roleNames.IGT_OWNER ?
                            roleNames.IGT_OWNER_LOADING :
                            roleNames.PRIVATE_PRICING_LOADING;
        this.setState({
            loadingError: false,
            activateToast: true,
            loadingMessage: msg,
            AssignDealUsers: []
        });
        let ReqData = {
            userRole: this.state.userrole
        };
        let loadAssignedData = {
            pprId: pprId,
            dealtype: dealtype,
            accountName: accountName,
            createdById: userRoleAlias,
            Sales_Representative: salesRep,
            Sales_Manager: SalesManager
        };
        let IGTUserUrl = baseUrlForUser + "users/getuserRoles";
        // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

        axios({
            method: "POST",
            data: ReqData,
            url: IGTUserUrl
        }).then(resp => {
            _.each(resp.data.Data, Values => {
                let Users = {
                    optionKey: Values.AWSAlias,
                    optionValue: Values.FirstName + " " + Values.LastName + " (" + Values.AWSAlias + ")"
                };
                this.state.AssignDealUsers.push(Users);
            });
            this.setState({
                activateToast: false,
                visible: true,
                loadAssignedUserData: loadAssignedData
            });
        }).catch(err => console.log(err));
    };

    /**
     * this function will be used to set the start date value
     * @param {*} value value of the start date selected
     */
    handleStartDateChange = value => {
        if (value) {
            this.setState(
                {
                    startDate: value
                },
                () => {
                    this.getDashboardData();
                }
            );
        }
    };

    /**
     * this function will be used to set the end date value
     * @param {*} value value of the end date selected
     */
    handleEndDateChange = value => {
        if (value) {
            this.setState(
                {
                    endDate: value
                },
                () => {
                    this.getDashboardData();
                }
            );
        }
    };

    /**
     * this function will be called to get the date Range based on drop down date filter
     * @param {*} value value of the drop down selected
     */
    handleSelectedDateRange = value => {
        let startDate, endDate, startOfDate;
        let currentDate = moment();
        var dayStart = currentDate.clone().startOf("day").utc().format();
        startOfDate = new Date(dayStart);
        if (this.state.SelectedDate !== value) {
            switch (value) {
                case dashboardConstant.TODAY:
                    startDate = new Date(dayStart);
                    endDate = new Date(moment(startDate).utc().add(24, 'hours').format());
                    break;
                case dashboardConstant.CURRENT_WEEK:
                    var weekStart = currentDate.clone().startOf("isoWeek").utc().format();
                    startDate = new Date(weekStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case dashboardConstant.CURRENT_MONTH:
                    var monthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(monthStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case dashboardConstant.CURRENT_QUARTER:
                    var quarterStart = currentDate.clone().startOf("quarter").format();
                    startDate = new Date(quarterStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case dashboardConstant.LAST_90_DAYS:
                    startDate = new Date(moment(startOfDate).utc().subtract(90, 'days').format());
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case dashboardConstant.CUSTOM_DATES:
                    var monthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(monthStart);
                    endDate = new Date(moment().utc().format());
                    this.setState({
                        startDate: startDate,
                        endDate: endDate,
                        SelectedDate: value
                    });
                    break;
                default:
            }
            if (value && value !== dashboardConstant.CUSTOM_DATES) {
                this.setState(
                    {
                        startDate: startDate,
                        endDate: endDate,
                        SelectedDate: value
                    },
                    () => {
                        this.getDashboardData();
                    }
                );
            }
        }
    };

    /** show popup for clone
     * @param {*} type value of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealtype type of the deal created
     * @param {*} userRoleAlias role of the logged in user
     */
    showClonePopup = (type, pprId, dealtype, userRoleAlias, dealStatus) => {
        this.setState({
            clonepopup: true,
            typeOfPPR: type,
            pprID: pprId,
            dealType: dealtype,
            dealStatus: dealStatus,
            userRoleAlias
        });

    };

    /*show popup to delete ppR*/
    showDeletePPRPopup = (pprId) => {
        this.setState({
            deletePPRpopup: true,
            pprID: pprId
        });
    };

    /** show popup for clone
     * @param {*} type value of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealstatus status of the deal created
     * @param {*} createdById created by date of deal
     */
    showInAcitvePopup = (event, pprId, dealstatus, createdById, pprType, accountName, comments) => {
        let ReqData = {
            AWSAlias: createdById,
            userRole: this.state.userrole,
            dealStatus: dealstatus,
            createdById: createdById,
            pprId: pprId,
            pprType: pprType,
            accountName: accountName,
            comments: comments,
            loginAlias: this.state.userAlias,
        };
        this.setState({
            inActivepopup: true,
            inAcitveReq: ReqData
        });
    };

    /**
     * this function will be called when clicking the InActive ot Deal Lost
     * @param {*} type id of the particular ppr
     */
    onInActive = (type, comments) => {
        this.setState({
            loadingError: false,
            activateToast: true,
            inActivepopup: false,
            loadingMessage: "Please wait while updating the ppr..."
        });
        event.stopPropagation();
        let ReqData = this.state.inAcitveReq;
        ReqData.action = type;
        ReqData.comments = comments;
        // axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

        axios({
            method: "POST",
            url: baseUrl + "cfppr/inactive",
            data: ReqData
        })
            .then(resp => {
                this.setState(
                    {
                        activateToast: false,
                        inActivepopup: false
                    },
                    () => {
                        if (this.props.location.state && this.props.location.state.userRole && type === 'lost') {
                            let userRole = this.props.location.state.userRole;
                            let metricsAction = 'DealIntakeForm-' + userRole.replace(/\s+/g, '-') + '-Deal-Lost-Clicked';
                            clickMetrics(metricsAction);
                        }
                        this.sendEmailUpdates(type);
                        this.getDashboardData();
                    }
                );
            }).catch(error => {

                const { data } = error;

                if (data && data.statusCode && data.statusCode === 400) {
                    this.setState({
                        activateToast: true,
                        loadingMessage: data.errorMessage,
                        loadingError: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                activateToast: false
                            })
                        }, 3000)
                    });
                } else {
                    this.setState(
                        {
                            loadingError: true,
                            activateToast: true,
                            loadingMessage: "Failed to update"
                        },
                        () => {
                            this.setState({
                                activateToast: false
                            });
                        }
                    );
                }
            });
    };

    sendEmailUpdates = (type) => {
        let pprID = Number(this.state.inAcitveReq.pprId.split('-')[1]);
        let dealStatus = (type === 'lost') ? "Deal Lost" : "Inactive";
        let emailForm = {
            "dealStatus": dealStatus,
            "createdById": this.state.inAcitveReq.createdById,
            "type": this.state.inAcitveReq.pprType,
            "userRole": this.props.location.state && this.props.location.state.userRole,
            "accountManager": "",
            "accountName": this.state.inAcitveReq.accountName,
            "ownerId": "",
            "AWSAlias": this.props.location.state && this.props.location.state.alias,
            "pprId": pprID,
            "isMatChange": "",
            "dashboard": true,
            "RFIflag": "",
            "isMaterialChange": ""
        }
        // axios call to send notification
        let emailUrl = "email/sendMessage"
        emailNotification(emailUrl, emailForm)
    }

    /** show popup for open for review
     * @param {*} type type of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealstatus status of the ppr created
     * @param {*} createdById created by date of deal
     */
    showOpenReviewPopup = (event, pprId, dealstatus, createdById, pprType, accountName, comments) => {
        const reqData = {
            AWSAlias: createdById,
            userRole: roleNames.SALES_REPRESENTATIVE,
            dealStatus: dealstatus,
            createdById: createdById,
            pprId: pprId,
            pprType: pprType,
            accountName: accountName,
            comments: comments,
            loginAlias: this.state.userAlias,
            action: dashboardConstant.OPEN_FOR_REVIEW,
        };
        this.setState({
            openReviewPopup: true,
            openReviewReq: reqData
        });
    };

    /**
     * this function will be called when clicking open for review action
     * @param {*} type id of the particular ppr
     */
    onOpenForReview = (comments) => {
        this.setState({
            loadingError: false,
            activateToast: true,
            openReviewPopup: false,
            loadingMessage: "Please wait while updating the ppr..."
        });
        event.stopPropagation();
        let ReqData = this.state.openReviewReq;
        ReqData.comments = comments;

        axios({
            method: "POST",
            url: baseUrl + "cfppr/inactive",
            data: ReqData
        })
            .then(resp => {
                this.setState(
                    {
                        activateToast: false,
                        openReviewPopup: false
                    },
                    () => {
                        this.sendEmailUpdatesforOpenforReview();
                        this.getDashboardData();
                    }
                );
            }).catch(error => {
                const { data } = error;
                if (data && data.statusCode && data.statusCode === 400) {
                    this.setState({
                        activateToast: true,
                        loadingMessage: data.errorMessage,
                        loadingError: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                activateToast: false
                            })
                        }, 5000)
                    });
                } else {
                    this.setState(
                        {
                            loadingError: true,
                            activateToast: true,
                            loadingMessage: "Failed to update"
                        },
                        () => {
                            this.setState({
                                activateToast: false
                            });
                        }
                    );
                }
            });
    };

    sendEmailUpdatesforOpenforReview = () => {
        const pprID = Number(this.state.openReviewReq.pprId.split('-')[1]);
        const dealStatus = dashboardConstant.OPEN_FOR_REVIEW;
        const emailForm = {
            "dealStatus": dealStatus,
            "createdById": this.state.openReviewReq.createdById,
            "type": this.state.openReviewReq.pprType,
            "userRole": this.props.location.state && this.props.location.state.userRole,
            "accountName": this.state.openReviewReq.accountName,
            "AWSAlias": this.props.location.state && this.props.location.state.alias,
            "pprId": pprID
        }
        // axios call to send notification
        const emailUrl = "email/sendMessage"
        emailNotification(emailUrl, emailForm)
    }

    /**
     * this function will be called when the partiular tab is clicked on show the values
     * @param {*} value value of the tab selected
     */
    handleOnTabClick = (value, name) => {
        setSessionStorage(STORAGE_NAMES.CURRENT_TAB, name);
        switch (name) {
            case "ALL PPR":
                this.setState({
                    allPPRtab: true,
                    submittedPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: false,
                    pendingLegaltab: false,
                    openForReviewtab: false
                });
                break;
            case "PPR Submitted":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: true,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: false,
                    pendingLegaltab: false,
                    openForReviewtab: false
                });
                break;
            case "Pending Sales Manager Approval":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: false,
                    salesManagertab: true,
                    sceOwnertab: false,
                    customerTermstab: false,
                    pendingLegaltab: false,
                    openForReviewtab: false
                });
                break;
            case "Pending Customer Terms Aligned":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: true,
                    pendingLegaltab: false,
                    openForReviewtab: false
                });
                break;
            case "Open For Review":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: false,
                    pendingLegaltab: false,
                    openForReviewtab: true
                });
                break;
            case "Pending Executable":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: true,
                    customerTermstab: false,
                    pendingLegaltab: false,
                    openForReviewtab: false
                });
                break;
            case "Pending Legal Drafting":
                this.setState({
                    allPPRtab: false,
                    submittedPPRtab: false,
                    salesManagertab: false,
                    sceOwnertab: false,
                    customerTermstab: false,
                    pendingLegaltab: true,
                    openForReviewtab: false
                });
                break;
            default:
        }
        if (this.state.allPPRTabLabel === "ALL PPRs" && name === "ALL PPR" && this.state.userrole !== roleNames.BILLING_TEAM && this.state.userrole !== roleNames.SOLUTION_PROVIDER) {
            this.OnAllPPRsclick(name);
        }
    };

    /**
     * this function will be called to get the date values to be displayed in dashboard
     * @param {*} value value of the date passed
     * @param {*} defaultDisplayDate default date to be displayed
     */
    // getValue = (value, defaultDisplayDate) => {
    //   if (value === null || value === "Invalid date") {
    //     return defaultDisplayDate;
    //   } else {
    //     return new Date(value);
    //   }
    // };

    handleDateChangeRaw = e => {
        e.preventDefault();
    };

    /**
     * this function will display table data based on searrch
     */
    setTableDataBasedonAdvanceSearch = (searchedData) => {
        let { allPPRtab, submittedPPRtab, salesManagertab } = this.state;
        this.setState({
            pprcreatedList: searchedData,
            allPPRTabLabel: dashboardConstant.SEARCH_RESULT_LABEL,
            submittedPPRtab: false,
            allPPRtab: true,
            salesManagertab: false,
            sceOwnertab: false,
            customerTermstab: false,
            pendingLegaltab: false,
            openForReviewtab: false
        })
        this.hideAdvanceSearch()
    }

    /*show advacnced search popup*/
    showAdvancedSearchPopup = () => {
        this.setState({
            advancedSearchPopup: true
        })
    }
    hideAdvanceSearch = () => {
        this.setState({
            advancedSearchPopup: false
        })
    }

    showPlayer = () => {
        window.open("https://broadcast.amazon.com/videos/192216", "_blank")
    }
    navigateToSetting = () => {
        window.open("https://broadcast.amazon.com/videos/192226", "_blank")
    }
    hidevideoPopup = () => {
        this.setState({
            videoPopup: false
        })
    }

    onContentSelectionChange(e) {
        if (e.detail.contentSelection) {
            let selecionColums = e.detail.contentSelection;
            if (selecionColums && selecionColums.length > 0) {
                let DefaultSelection = this.state.dashboardColumnOptions.map(el => {
                    return Object.assign({}, el, { visible: false })
                });
                selecionColums.forEach(element => {
                    DefaultSelection = DefaultSelection.map(el => {
                        if (el.id === element)
                            return Object.assign({}, el, { visible: true })
                        return el
                    });
                });
                this.setState({
                    dashboardColumnOptions: DefaultSelection
                }, () => {
                    let columnUpdateUrl = baseUrl + "dashboard/saveColumns";
                    let updatedColumnData = {
                        AWSAlias: this.state.userAlias,
                        userRole: this.state.userrole,
                        columns: this.state.dashboardColumnOptions
                    };
                    axios({
                        method: "POST",
                        data: updatedColumnData,
                        url: columnUpdateUrl
                    }).then(() => {
                    }).catch(err => console.log(err));
                })
            }
        }
    }

    updateUserRole = (secondaryRole, activeRole) => {
        const isReturn = secondaryRole === roleNames.ADMIN ? false : true;
        const tokenResult = getTokenBasedOnRole(secondaryRole, activeRole, this.props.location.state, this.props.history, isReturn);
        if (isReturn) {
            tokenResult.then((res) => {
                this.setState(
                    {
                        firstname:
                            this.props.location.state && this.props.location.state.firstName,
                        lastname:
                            this.props.location.state && this.props.location.state.lastName,
                        userrole:
                            this.props.location.state && this.props.location.state.userRole,
                        userAlias: this.props.location.state && this.props.location.state.alias,
                        locationData: this.props.location && this.props.location.state
                    },
                    () => {
                        this.getDashboardData();
                    }
                );
            });
        }
    }

    updateSPUserRole = (solutionProviderRole, activeRole) => {
        const isReturn = true;
        const solutionProvider = true;
        const tokenResult = getTokenBasedOnRole(solutionProviderRole, activeRole, this.props.location.state, this.props.history, isReturn, solutionProvider)
        tokenResult.then((res) => {
            const userRole = this.props.location.state && this.props.location.state.userRole;
            const locationState = this.props.location && this.props.location.state;
            this.setState(
                {
                    userrole: userRole,
                    locationData: locationState
                },
                () => {
                    this.getDashboardData();
                }
            );
        });
    }

    updatePPOOrChinaUserRole = (activeRole) => {
        const secondaryRole = activeRole === roleNames.IGT_OWNER ? roleNames.PRIVATE_PRICING_CHINA : roleNames.IGT_OWNER;
        const isReturn = true;
        const tokenResult = getTokenBasedOnRole(secondaryRole, activeRole, this.props.location.state, this.props.history, isReturn);
        if (tokenResult) {
            tokenResult.then((res) => {
                this.setState(
                    {
                        firstname:
                            this.props.location.state && this.props.location.state.firstName,
                        lastname:
                            this.props.location.state && this.props.location.state.lastName,
                        userrole:
                            this.props.location.state && this.props.location.state.userRole,
                        userAlias: this.props.location.state && this.props.location.state.alias,
                        locationData: this.props.location && this.props.location.state
                    },
                    () => {
                        this.getDashboardData();
                    }
                );
            });
        }
    };

    updateIncludeDealSignedCheck = (value) => {
        this.setState({
            allPPRIncludeDealSigned: value },() => {
                this.OnAllPPRsclick("ALL PPR");
            }) 
    }

    getAdjustedMargin = (className, allPPR) => {
        if(allPPR && getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) === null) {
           className += " margin-allppr";
        }
        return className;
    }
    clearFilters = () => {
        this.hideAdvanceSearch();
        if(getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) === null) {
            this.getDashboardData();
        }
    };

    render() {
        let { locationData, displayErrorPage } = this.state;

        if (displayErrorPage === true) {
            return (
                <div className="aws-dash">
                    <AWSDashboardheader
                        firstName={this.state.firstname}
                        lastName={this.state.lastname}
                        locationData={locationData}
                        location={this.props.location.state && this.props.location.state.userRole}
                        getDashboardData={() => {
                            removeSessionStorage(STORAGE_NAMES.SEARCH_PARAMS);
                            this.getDashboardData();
                            if(this.state.allPPRTabLabel === dashboardConstant.SEARCH_RESULT_LABEL) {
                                this.advancedSearchRef.current.clearState();
                            }
                        }}
                        updateUserRole={(secondaryRole, activeRole) => {
                            this.updateUserRole(secondaryRole, activeRole);
                        }}
                        updateSPUserRole={(secondaryRole, activeRole) => {
                            this.updateSPUserRole(secondaryRole, activeRole);
                        }}
                        updatePPOOrChinaUserRole={(activeRole) => {
                            this.updatePPOOrChinaUserRole(activeRole);
                        }}
                    />
                    <div className="errorPagePadding">
                        <ErrorPage
                            onRefreshClick={() => this.getDashboardData(null, "refreshOnId")}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="aws-dash">
                    <div className="nav-container">                    
                        <AWSDashboardheader
                            firstName={this.state.firstname}
                            lastName={this.state.lastname}
                            locationData={locationData}
                            location={this.props.location.state && this.props.location.state.userRole}
                            getDashboardData={() => {
                                removeSessionStorage(STORAGE_NAMES.SEARCH_PARAMS);
                                this.getDashboardData();
                                if(this.state.allPPRTabLabel === dashboardConstant.SEARCH_RESULT_LABEL) {
                                    this.advancedSearchRef.current.clearState();
                                }
                            }}
                            updateUserRole={(secondaryRole, activeRole) => {
                                this.updateUserRole(secondaryRole, activeRole);
                            }}
                            updateSPUserRole={(secondaryRole, activeRole) => {
                                this.updateSPUserRole(secondaryRole, activeRole);
                            }}
                            updatePPOOrChinaUserRole={(activeRole) => {
                                this.updatePPOOrChinaUserRole(activeRole);
                            }}
                        />
                        <DashboardSubmenu
                            locationData={this.state.locationData}
                            userRole={this.state.userrole}
                        />
                    </div>
                    <div
                        className={this.props.location.state && (this.props.location.state.userRole === roleNames.BILLING_TEAM || this.props.location.state.userRole === roleNames.SOLUTION_PROVIDER) ? "main-container moz-scroll bg-gradient pr-4 padding-left2p scroll-width5" :
                            "main-container moz-scroll bg-gradient pr-4 padding-left5p scroll-width5"}
                    >
                        <div className="pt-0 pb-2 top-wraper float-left col-12 pl-0 pr-0">
                            <div className="col-12 float-left p-0">
                                {this.state.SelectedDate === "Custom Dates" && (
                                    <div className="col-4 float-right timeframe-datepicker p-0 pb-2 pt-2">
                                        <span className="float-left date-wraper col-6 start-date">
                                            <DatePicker
                                                id={`datepicker_startDate`}
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDateChange}
                                                maxDate={this.state.endDate}
                                                onSelect={this.handleSelectStartDate}
                                                onChangeRaw={this.handleDateChangeRaw}
                                            />
                                        </span>

                                        <span className="float-left totxt">To</span>
                                        <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                            <DatePicker
                                                id={`datepicker_endtDate`}
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDateChange}
                                                minDate={this.state.startDate}
                                                maxDate={this.state.maxDate}
                                                onSelect={this.handleSelectEndDate}
                                                onChangeRaw={this.handleDateChangeRaw}
                                            />
                                        </span>
                                    </div>
                                )}

                                <div className="width-auto float-right pr-0 dashboard-dropdown">
                                    {this.state.userrole === roleNames.SALES_REPRESENTATIVE && <span className="dropdown-youtube">

                                        <div className="float-left mr-0 mt-0 pt-1 youtube-icon" onClick={() => { this.showPlayer() }}>
                                            {/* <img src={Video} alt="home" className="video-icon cursor-pointer"
                        onClick={() => { this.showPlayer() }} /> */}
                                            {/* <Icon name="caret-right-filled" ></Icon> */}
                                            <VideoIcon />
                                        </div>
                                        <span className="dropdown-youtube-tooltip tooltip-style">Dashboard Tiles</span>
                                    </span>}

                                    <Popover
                                        content={
                                            this.state.userrole === roleNames.BILLING_TEAM
                                                ? "This date range filter applies only to the Fully Executed tile"
                                                : this.state.userrole === roleNames.SALES_MANAGER ?
                                                    "This date range filter applies only to the Approved and Fully Executed tiles" :
                                                    this.state.userrole === roleNames.SALES_REPRESENTATIVE
                                                        ? "This date range filter applies only to the PPR Created, PPRs Submitted and Fully Executed tiles" :
                                                        this.state.userrole === roleNames.IGT_OWNER || roleNames.SCE_OWNER || roleNames.PRIVATE_PRICING_PUBLIC_SECTOR || roleNames.PRIVATE_PRICING_CHINA
                                                            ? "This date range filter applies only to the Approved By Sales Manager, Executable Released and Fully Executed tiles"
                                                            : "This date range filter applies only to the PPR Created, PPR Submitted and Fully Executed tiles"
                                        }
                                    >
                                        <span className="float-right circle-alert pt-1">
                                            {/* {/* <img src={infoHelpIcon} alt="help" /> */}
                                            <Icon name="status-info"></Icon>
                                        </span>
                                    </Popover>
                                    <span className="float-left">
                                        <AWSDropdown
                                            id="additionalTerm"
                                            options={DateRanges}
                                            value={this.state.SelectedDate}
                                            handleOnSelect={value =>
                                                this.handleSelectedDateRange(value)
                                            }
                                        />
                                    </span>


                                </div>
                            </div>

                            <Col
                                span={
                                    this.state.userrole !== roleNames.SALES_REPRESENTATIVE &&
                                        this.state.userrole !== roleNames.PRIVATE_PRICING_CHINA &&
                                        this.state.userrole !== roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
                                        ? (this.state.userrole === roleNames.BILLING_TEAM || this.state.userrole === roleNames.SOLUTION_PROVIDER)
                                            ? "8"
                                            : "20"
                                        : "24"
                                }
                                className="float-right"
                            >
                                {this.state.dashboardData &&
                                    this.state.dashboardData.map((tableData, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={e =>
                                                    this.OnDashboradTilesclick(
                                                        tableData.label,
                                                        tableData.dealStatus,
                                                        tableData.ownerType,
                                                        e
                                                    )
                                                }
                                                className="card-wraper"
                                            >
                                                {/* <Tooltip content={tableData.helpText}> */}
                                                <AWSDashboardCard
                                                    cardText={tableData.label}
                                                    bgColor={tableData.color}
                                                    count={tableData.count}
                                                    userRole={this.state.userrole}
                                                    helpText={tableData.helpText}
                                                />
                                                {/* </Tooltip> */}
                                            </div>
                                        );
                                    })}
                            </Col>
                        </div>
                        {/* End of dashboard cards */}


                        <div
                            className={`pt-1 pb-4 dark-wraper float-left full-width ${this.state.userrole !== roleNames.SALES_REPRESENTATIVE ? "mt-0" : ""
                                }`}
                        >
                            <Col span={24}>
                                <div className="col-12 p-0 float-left tabs-contanier">
                                    <Col span={this.state.userrole !== roleNames.SCE_OWNER &&
                                        this.state.userrole !== roleNames.PRIVATE_PRICING_CHINA &&
                                        this.state.userrole !== roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
                                        ? "18"
                                        : "24"}>
                                        <ul className="col-12 float-left p-0 pt-2 mt-1 pb-3">
                                            <li
                                                className={
                                                    this.state.allPPRtab === true
                                                        ? "font-size14 white mr-4 active-tab pb-1"
                                                        : "font-size14 white mr-4 pb-1"
                                                }
                                                onClick={() => {
                                                    this.handleOnTabClick(true, "ALL PPR");
                                                }}
                                            >
                                                {this.state.userrole === roleNames.BILLING_TEAM
                                                    ? "PENDING CONTRACT UPLOAD"
                                                    : this.state.userrole === roleNames.SOLUTION_PROVIDER ? "PENDING SP APPROVAL" : `${this.state.allPPRTabLabel}`}
                                                {(this.state.userrole === roleNames.BILLING_TEAM || this.state.userrole === roleNames.SOLUTION_PROVIDER) && this.state.pprcreatedList && this.state.pprcreatedList.length > 0 ? "(" + this.state.pprcreatedList.length + ")" : ""}
                                            </li>
                                            <li
                                                className={
                                                    this.state.submittedPPRtab === true
                                                        ? "font-size14 white mr-4 active-tab pb-1"
                                                        : "font-size14 white mr-4 pb-1"
                                                }
                                                onClick={() => {
                                                    this.handleOnTabClick(true, "PPR Submitted");
                                                }}
                                            >
                                                {this.state.userrole === roleNames.BILLING_TEAM && this.state.submittedPPRLabel === 'PPRs SUBMITTED'
                                                    ? "FULLY EXECUTED" : this.state.userrole === roleNames.SOLUTION_PROVIDER && this.state.submittedPPRLabel === 'PPRs SUBMITTED'
                                                        ? "SP APPROVED" :
                                                        this.state.submittedPPRLabel}
                                                {this.state.submittedList && this.state.submittedList.length > 0 ? "(" + this.state.submittedList.length + ")" : "(0)"}
                                            </li>
                                            {(this.state.userrole !== roleNames.IGT_OWNER &&
                                                this.state.userrole !== roleNames.BILLING_TEAM &&
                                                this.state.userrole !== roleNames.SOLUTION_PROVIDER) && (
                                                    <li
                                                        className={
                                                            this.state.salesManagertab === true
                                                                ? "font-size14 white mr-4 active-tab pb-1"
                                                                : "font-size14 white mr-4 pb-1"
                                                        }
                                                        onClick={() => {
                                                            this.handleOnTabClick(
                                                                true,
                                                                "Pending Sales Manager Approval"
                                                            );
                                                        }}
                                                    >
                                                        {this.state.userrole === roleNames.SALES_MANAGER ? "PENDING ALL SALES MANAGER APPROVAL" : this.state.userrole === roleNames.SALES_REPRESENTATIVE ? "AWAITING SALES INPUT" : "PENDING SERVICE TEAM APPROVAL"}
                                                        {this.state.pendingSMList && this.state.pendingSMList.length > 0 ? "(" + this.state.pendingSMList.length + ")" : "(0)"}
                                                    </li>
                                                )}
                                            {(this.state.userrole === roleNames.SALES_REPRESENTATIVE ||
                                                this.state.userrole === roleNames.SCE_OWNER ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_CHINA ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR) && (
                                                    <li
                                                        className={
                                                            this.state.customerTermstab === true
                                                                ? "font-size14 white mr-4 active-tab pb-1"
                                                                : "font-size14 white mr-4 pb-1"
                                                        }
                                                        onClick={() => {
                                                            this.handleOnTabClick(
                                                                true,
                                                                "Pending Customer Terms Aligned"
                                                            );
                                                        }}
                                                    >
                                                        {"PENDING CUSTOMER TERMS ALIGNED"}
                                                        {this.state.pendingCusTermsList && this.state.pendingCusTermsList.length > 0 ? "(" + this.state.pendingCusTermsList.length + ")" : "(0)"}
                                                    </li>
                                                )}
                                            {(this.state.userrole === roleNames.SALES_REPRESENTATIVE ||
                                                this.state.userrole === roleNames.SCE_OWNER ||
                                                this.state.userrole === roleNames.IGT_OWNER ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_CHINA ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR) && (
                                                    <li
                                                        className={
                                                            this.state.openForReviewtab === true
                                                                ? "font-size14 white mr-4 active-tab pb-1"
                                                                : "font-size14 white mr-4 pb-1"
                                                        }
                                                        onClick={() => {
                                                            this.handleOnTabClick(
                                                                true,
                                                                "Open For Review"
                                                            );
                                                        }}
                                                    >
                                                        {"OPEN FOR REVIEW"}
                                                        {this.state.openForReviewList && this.state.openForReviewList.length > 0 ? "(" + this.state.openForReviewList.length + ")" : "(0)"}
                                                    </li>
                                                )}
                                            {(this.state.userrole === roleNames.SCE_OWNER ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_CHINA ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR) && (
                                                    <li
                                                        className={
                                                            this.state.sceOwnertab === true
                                                                ? "font-size14 white mr-4 active-tab pb-2"
                                                                : "font-size14 white mr-4 pb-3"
                                                        }
                                                        onClick={() => {
                                                            this.handleOnTabClick(
                                                                true,
                                                                "Pending Executable"
                                                            );
                                                        }}
                                                    >
                                                        {"PENDING EXECUTABLE"}
                                                        {this.state.pendingExecList && this.state.pendingExecList.length > 0 ? "(" + this.state.pendingExecList.length + ")" : "(0)"}
                                                    </li>
                                                )}
                                            {(
                                                this.state.userrole === roleNames.IGT_OWNER ||
                                                this.state.userrole === roleNames.SCE_OWNER ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_CHINA ||
                                                this.state.userrole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR) && (
                                                    <li
                                                        className={
                                                            this.state.pendingLegaltab === true
                                                                ? "font-size14 white mr-4 active-tab pb-2"
                                                                : "font-size14 white mr-4 pb-3"
                                                        }
                                                        onClick={() => {
                                                            this.handleOnTabClick(
                                                                true,
                                                                "Pending Legal Drafting"
                                                            );
                                                        }}
                                                    >
                                                        {"PENDING LEGAL DRAFTING"}
                                                        {this.state.pendingLegalList && this.state.pendingLegalList.length > 0 ? "(" + this.state.pendingLegalList.length + ")" : "(0)"}
                                                    </li>
                                                )}
                                        </ul>
                                    </Col>
                                    {this.state.userrole === roleNames.SALES_REPRESENTATIVE && (
                                        <div className="pt-1 pb-0 float-right">
                                            <Col span={24} className="big-button">
                                                <Link
                                                    to={{ pathname: "/create-ppr", state: locationData }}
                                                    state={locationData}
                                                >
                                                    <span className="float-left mt-2">
                                                        <AWSbutton
                                                            btnType="fill"
                                                            label="Create PPR"
                                                            isIcon={true}
                                                        />
                                                    </span>
                                                </Link>
                                            </Col>
                                        </div>
                                    )}

                                    {/* End of button */}

                                    {(this.state.allPPRtab === true && getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) === null)&& (
                                        <div className="checkbox-primary col-12 p-0 float-left">
                                            <AWScheckbox
                                                label={"Include Deal Signed"}
                                                checked={this.state.allPPRIncludeDealSigned}
                                                onChangeFunc={(value) => { 
                                                    this.updateIncludeDealSignedCheck(value);
                                                }}>
                                            </AWScheckbox>
                                        </div>      
                                    )}

                                    {this.props.location.state && this.props.location.state.userRole && this.state.userrole !== roleNames.SOLUTION_PROVIDER && (
                                        <div className="advance-search-icon">
                                            <Col span={6} className={
                                                this.props.location.state && this.props.location.state.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ? "float-right width-auto pagination-style sceprivate-publicowner" :
                                                    this.props.location.state && this.props.location.state.userRole === roleNames.PRIVATE_PRICING_CHINA ? "float-right width-auto pagination-style sceprivate-publicowner" :
                                                        this.props.location.state && this.props.location.state.userRole === roleNames.SALES_REPRESENTATIVE ? "pagination-style float-right width-auto" :
                                                            this.props.location.state && this.props.location.state.userRole === roleNames.SCE_OWNER ? "float-right width-auto pagination-style sceprivate-publicowner" :


                                                                "float-right width-auto pagination-style otheruser-pagination-style"
                                            }>
                                                <div className="search-icon-wraper float-left pt-3 cursor-pointer">
                                                    <span className={ this.getAdjustedMargin("search-icon float-left", this.state.allPPRtab) } 
                                                        onClick={() => { this.showAdvancedSearchPopup() }}>
                                                    <img src={searchWhite} alt="search" />
                                                    </span>
                                                </div>
                                            </Col>
                                            <span className="advance-tooltip tooltip-style">Advanced Search</span>
                                        </div>


                                    )}
                                    {this.props.location.state && this.props.location.state.userRole === roleNames.SALES_REPRESENTATIVE && <div className="pagination-wraper">
                                        <div className={ this.getAdjustedMargin("pagination-youtube youtube-icon", this.state.allPPRtab) } 
                                            onClick={() => window.open("https://broadcast.amazon.com/videos/192219", "_blank")}>
                                            {/* <Icon name="caret-right-filled" ></Icon> */}
                                            
                                            <VideoIcon />
                                            {/* <img src={Video} alt="home" className="video-icon cursor-pointer"
                        onClick={() => window.open("https://broadcast.amazon.com/videos/192219", "_blank")} /> */}
                                        </div>
                                        <span className="pagination-tooltip tooltip-style">Dashboard Tables - Search and Sort Action</span>
                                    </div>}
                                    {this.props.location.state && this.props.location.state.userRole === roleNames.SALES_REPRESENTATIVE && <div className="setting-wraper">
                                        <div className={ this.getAdjustedMargin("float-left mr-0 setting-youtube youtube-icon", this.state.allPPRtab) } 
                                            onClick={() => window.open("https://broadcast.amazon.com/videos/192226", "_blank")}>
                                            {/* <img src={Video} alt="home" className="video-icon cursor-pointer"
                        onClick={() => window.open("https://broadcast.amazon.com/videos/192226", "_blank")} /> */}
                                            {/* <VideoIcon/> */}
                                            <VideoIcon />
                                        </div>
                                        <span className="setting-tooltip tooltip-style">Dashboard Tables - Adding and Deleting Columns </span>
                                    </div>}
                                    <AWSDashboardData
                                        location={this.props.location}
                                        allPPRtab={this.state.allPPRtab}
                                        pprcreatedList={this.state.pprcreatedList}
                                        submittedPPRtab={this.state.submittedPPRtab}
                                        salesManagertab={this.state.salesManagertab}
                                        sceOwnertab={this.state.sceOwnertab}
                                        customerTermstab={this.state.customerTermstab}
                                        submittedList={this.state.submittedList}
                                        pendingSMList={this.state.pendingSMList}
                                        pendingExecList={this.state.pendingExecList}
                                        openForReviewtab={this.state.openForReviewtab}
                                        openForReviewList={this.state.openForReviewList}
                                        pendingLegaltab={this.state.pendingLegaltab}
                                        pendingLegalList={this.state.pendingLegalList}
                                        pendingCusTermsList={this.state.pendingCusTermsList}
                                        dashboardColumnOptions={this.state.dashboardColumnOptions}
                                        getDashboardData={() => this.getDashboardData()}
                                        onContentSelectionChange={(e) => this.onContentSelectionChange(e)}
                                        onView={(type, pprId, dealtype, userRoleAlias) =>
                                            this.onView(type, pprId, dealtype, userRoleAlias)
                                        }
                                        userRole={this.props.location.state && this.props.location.state.userRole}
                                        showInAcitvePopup={(event, pprId, dealstatus, createdById, pprType, accountName, comments) =>
                                            this.showInAcitvePopup(event, pprId, dealstatus, createdById, pprType, accountName, comments)
                                        }
                                        showOpenReviewPopup={(event, pprId, dealstatus, createdById, pprType, accountName, comments) =>
                                            this.showOpenReviewPopup(event, pprId, dealstatus, createdById, pprType, accountName, comments)
                                        }
                                        onAssign={(pprId, dealtype, accountName, userRoleAlias, salesRep, SalesManager) =>
                                            this.onAssign(pprId, dealtype, accountName, userRoleAlias, salesRep, SalesManager)
                                        }
                                        onView={(type, pprId, dealtype, userRoleAlias) =>
                                            this.onView(type, pprId, dealtype, userRoleAlias)
                                        }
                                        showClonePopup={(type, pprId, dealtype, dealstatus, userRoleAlias) =>
                                            this.showClonePopup(type, pprId, dealtype, dealstatus, userRoleAlias)
                                        }
                                        showDeletePPRPopup={(pprId) =>
                                            this.showDeletePPRPopup(pprId)
                                        }
                                        onDownload={(event, id, type) => this.onDownload(event, id, type)}
                                        onInActive={type => this.onInActive(type)}
                                    ></AWSDashboardData>
                                    {/* <AWSDashboardTable
                      location={this.props.location}
                      allPPRtab={this.state.allPPRtab}
                      submittedPPRtab={this.state.submittedPPRtab}
                      salesManagertab={this.state.salesManagertab}
                      sceOwnertab={this.state.sceOwnertab}
                      pprcreatedList={this.state.pprcreatedList}
                      submittedList={this.state.submittedList}
                      loadingError={this.state.loadingError}
                      pendingSMList={this.state.pendingSMList}
                      pendingExecList={this.state.pendingExecList}
                      getDashboardData={() => this.getDashboardData()}
                      userrole={this.state.userrole}
                      ref="allTable"
                      hideShowAdditionalColumn={this.hideShowAdditionalColumn()}
                      showInAcitvePopup={(
                        event,
                        pprId,
                        dealstatus,
                        createdById,
                        comments
                      ) =>
                        this.showInAcitvePopup(
                          event,
                          pprId,
                          dealstatus,
                          createdById,
                          comments
                        )
                      }
                      onAssign={(
                        pprId,
                        dealtype,
                        userRoleAlias,
                        salesRep,
                        SalesManager
                      ) =>
                        this.onAssign(
                          pprId,
                          dealtype,
                          userRoleAlias,
                          salesRep,
                          SalesManager
                        )
                      }
                      onView={(type, pprId, dealtype, userRoleAlias) =>
                        this.onView(type, pprId, dealtype, userRoleAlias)
                      }
                      showClonePopup={(type, pprId, dealtype, dealstatus, userRoleAlias) =>
                        this.showClonePopup(type, pprId, dealtype, dealstatus, userRoleAlias)
                      }
                      showDeletePPRPopup={(pprId) =>
                        this.showDeletePPRPopup(pprId)
                      }
                      onDownload={(event, id) => this.onDownload(event, id)}
                      onInActive={type => this.onInActive(type)}
                    ></AWSDashboardTable> */}
                                </div>
                                {this.state.popoverVisible && (
                                    <div
                                        className="table-popover"
                                        ref={node => (this.node = node)}
                                    >
                                        <Popover
                                            Options={this.state.tablepopOver}
                                            updateExtaFieldsData={(value, name) =>
                                                this.updateExtaFieldsData(value, name)
                                            }
                                            AddAdditionalColumns={() =>
                                                this.addAdditionalColumstoTable()
                                            }
                                        />
                                    </div>
                                )}
                                {this.state.activateToast && (
                                    <AWSLoadingToast
                                        message={this.state.loadingMessage}
                                        error={this.state.loadingError}
                                        duration={this.state.loadingMessageDuration}
                                    />
                                )}
                            </Col>
                        </div>
                        {/*End of table*/}
                    </div>
                    <AWSModal
                        type="assignDeals"
                        userRole={this.state.userrole}
                        AssignDealUsers={this.state.AssignDealUsers}
                        visible={this.state.visible}
                        data={this.state.selectedRoleUser}
                        handleOk={() => {
                            this.handleAssignRole();
                        }}
                        handleCancel={() => {
                            this.handleOkOrCancelOfModal("assignDeals");
                        }}
                        handleOnSelect={(name, value) => {
                            this.handleOnSelect(name, value);
                        }}
                        alertMessage={this.state.alertMessage}
                    />
                    <div className="clone-popup-wraper">
                        <AWSModal
                            type="clonePopup"
                            visible={this.state.clonepopup}
                            handleOk={() => {
                                this.onClone("yes");
                            }}
                            handleCancel={() => {
                                this.onClone("no");
                            }}
                            handleClose={() =>
                                this.setState({ ...this.state, clonepopup: false })
                            }
                        />
                    </div>
                    <div className="clone-popup-wraper">
                        <AWSModal
                            type="deletePPRPopup"
                            visible={this.state.deletePPRpopup}
                            handleOk={() => {
                                this.onDelete("yes");
                            }}
                            handleCancel={() => {
                                this.setState({ ...this.state, deletePPRpopup: false })
                            }}
                            handleClose={() =>
                                this.setState({ ...this.state, deletePPRpopup: false })
                            }
                        />
                    </div>
                    <div className="clone-popup-wraper">
                        <AWSModal
                            type="inActivePopup"
                            visible={this.state.inActivepopup}
                            handleOk={(status, comments) => {
                                this.onInActive(status, comments);
                            }}
                            handleCancel={(comments) => {
                                this.setState({ ...this.state, inActivepopup: false });
                            }}
                            handleClose={() =>
                                this.setState({ ...this.state, inActivepopup: false })
                            }
                        />
                    </div>
                    <div className="clone-popup-wraper" >
                        <div className="modal-wraper">
                            <Modal
                                visible={this.state.advancedSearchPopup}
                                onDismiss={() => this.hideAdvanceSearch()}>
                                <div className="advanced-search">
                                    <AdvancedSearch
                                        type="advancedSearch"
                                        userRole={this.state.userrole}
                                        visible={this.state.advancedSearchPopup}
                                        handleSearch={(searcedTableData) => this.setTableDataBasedonAdvanceSearch(searcedTableData)}
                                        clearFilters={() => this.clearFilters()}
                                        handleClose={() => this.hideAdvanceSearch()}
                                        ref={this.advancedSearchRef}
                                    />
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="clone-popup-wraper">
                        <AWSModal
                            type="openReviewPopup"
                            visible={this.state.openReviewPopup}
                            handleOk={(comments) => {
                                this.onOpenForReview(comments);
                            }}
                            handleCancel={() => {
                                this.setState({ ...this.state, openReviewPopup: false });
                            }}
                            handleClose={() =>
                                this.setState({ ...this.state, openReviewPopup: false })
                            }
                        />
                    </div>
                </div>
            );
        }
    }
}

AWSDashboard.propTypes = {
    location: PropTypes.any,
    history: PropTypes.any,
    getDashboardData: PropTypes.func,
    firstName: PropTypes.any,
    lastName: PropTypes.any
};

