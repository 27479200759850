
export default {

	sortableColumnOptions : [
        {id: "queLabel", comparator: (item1, item2) => {
            const firstField = item1.queLabel;
            const secondField = item2.queLabel;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "queType", comparator: (item1, item2) => {
            const firstField = item1.queType;
            const secondField = item2.queType;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "rowNum", comparator: (item1, item2) => {
            const firstField = item1.rowNum;
            const secondField = item2.rowNum;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }},
        {id: "columnNum", comparator: (item1, item2) => {
            const firstField = item1.columnNum;
            const secondField = item2.columnNum;
            return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
        }}
    ]
};