import React, { Component } from 'react';
import PropTypes from "prop-types";
import _ from 'underscore';
import { Checkbox } from "@amzn/awsui-components-react";
import AWSError from '../AWSError/AWSError';

export default class AWSDynamicCheckBox extends Component {

    state = {
        errorLabel: "",
        error: false
    }

    componentDidMount() {
        if (this.props.defaultValue && this.props.value !== false) {
            this.props.onChangeFunc(true)
        }
        if (this.props.value === "true") {
            this.props.onChangeFunc(true);
        }
        if (this.props.value === "false") {
            this.props.onChangeFunc(false);
        }
    }

    validate(validationArray, val) {
        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            if (validationObject.type === "Required") {
                if (val === false || val === null) {
                    this.setState({
                        error: true,
                        errorLabel: validationObject.message
                    })
                }
            }

        });
    }


    onChange = (val) => {
        this.setState({
            error: false,
            errorLabel: ""
        })
        if (this.props.questionValue !== undefined) {
            const { canEditPPR } = this.props;

            const { quePermission } = this.props.questionValue;
            if (canEditPPR === true) {
                if (quePermission && quePermission.read === false) {
                    this.setState({
                        error: false,
                        errorLabel: ""
                    })
                    this.props.onChangeFunc(val)
                }
            }
        } else {
            this.setState({
                error: false,
                errorLabel: ""
            })
            this.props.onChangeFunc(val);
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (canEditPPR === true) {
            return questionValue && questionValue.quePermission && questionValue.quePermission.read ? questionValue.quePermission.read : false
        } else if (canEditPPR === false) {
            return true // disable true
        } else {
            return false
        }
    }

    render() {
        const { label, value, triggeredNext, questionValue, canEditPPR } = this.props;
        if (triggeredNext && (!this.state.error) && questionValue && questionValue.isVisible) {

            this.validate(this.props.validation, value)
        }

        return (
            <div className="checkbox-wraper">
                <Checkbox
                    onChange={(e) => this.onChange(e.detail.checked)}
                    label={label} id={label} checked={value || false}
                    disabled={this.checkForDisable(questionValue, canEditPPR)}
                ></Checkbox>
                {(value === false || value === null) && this.state.error && (
                    <AWSError
                        errorLabel={this.state.errorLabel}
                    />
                )}
            </div>
        );
    }
}

AWSDynamicCheckBox.propTypes = {
    label: PropTypes.string,
    onChangeFunc: PropTypes.func
};
