import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "./AWSInputDateCustom.scss";
import { Icon } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import "./AWSinputDate.scss";
import moment from 'moment';
import _ from 'underscore';
import infoHelpIcon from '../../../images/awsfalconhelp.png';

let todayDate = new Date();
let effectiveDateForTermination = null;

export default class AWSInputDateCommon extends Component {

    state = {
        errorLabel: "",
        error: false,
        showError: false,

        month: todayDate.getMonth(),
        year: todayDate.getFullYear(),
        active: false,
        startDate: new Date(),
    }

    handleChange = value => {
        value = moment(value).format("MM/DD/YYYY");
        this.props.onChangeFunc(value);
    };

    getValue = (value, defaultDisplayDate) => {
        if (value === null || value === "Invalid date") {
            return defaultDisplayDate
        } else {
            return new Date(value)
        }
    }

    handleCalendarIconClick = (id) => {
        document.getElementById(id).click();
    }

    render() {
        let { label, value } = this.props;

        return (
            <div>
                <div className={`full-width dark-black font-size14 label-txt`}>{label}</div>
                <div className="date-wraper">
                    <DatePicker
                        id={`datepicker_AdvanceSearch`}
                        placeholderText="Select"
                        selected={this.getValue(value)}
                        onChange={(value) => { this.handleChange(value) }}
                        dateFormat="MM/01/yyyy"
                        showMonthYearPicker
                    />
                    <span className="calendar-icon mr-4" onClick={() => this.handleCalendarIconClick(`datepicker_AdvanceSearch`)}>
                        <Icon name="calendar" />
                    </span>
                </div>
            </div>
        );
    }
}

AWSInputDateCommon.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeFunc: PropTypes.func
};
