
import { SUCCESS_PREDICTION, FAILURE_PREDICTION, REQUEST_PREDICTION } from '../actions/actions';

const initialState = {loading: false, error: false, suggests: []};

export default function predictions(state, action) {
  const {type, payload} = action || {};

  switch(type) {
    case REQUEST_PREDICTION:
      return { ...state, loading: true };
    case SUCCESS_PREDICTION: 
      return { ...state, error: false, loading: false, suggests: payload.data };
    case FAILURE_PREDICTION:
      return { ...state, error: true, loading: false};
    default:
      return initialState;
  }
}
