import React, { Component } from "react";
import { Icon } from "@amzn/awsui-components-react";
import "./AWSError.scss";
class AWSError extends Component {
  render() {
    return (
      <div className="has-error font-size10 red">
        <span className="error-icon mr-0 float-left">
          <Icon name="status-warning"></Icon>
        </span>
        {`${this.props.errorLabel} `}
      </div>
    );
  }
}

export default AWSError;
