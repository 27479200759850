export const deal = [
  {
    optionKey: 1,
    optionValue: "Yes"
  },
  {
    optionKey: 2,
    optionValue: "No"
  },
];
export const Terms = [
  {
    optionKey: 1,
    optionValue: "Yes"
  },
  {
    optionKey: 2,
    optionValue: "No"
  },
];
export const infoText = {
  "typeofcommit": "Please choose the type of CloudFront Rate Card",
  "Type_of_CFRC": "Please choose the type of Commit",
  "Type_of_CommitType": "Please choose the type of Customer Commit",
  "average_obj_size": "Choose the Average Object Size from the slider or enter a numeric value in the text box",
  "terms_month": "Choose the Term from the slider or enter a value between 1 and 99 in the text box",
  "volume_commit": "Choose one of the options TB or PB for the Volume Commit",
  "data_transfet_commit": "Choose one of the options All or By Geo",
  "request_fees": "Choose one of the options for the type of Request Fees",
  "Global_Limits": "Choose the Global Limit from the slider or enter a value between 0 and 100 in the text box",
  "Total_Expected_Revenue": "Enter the Total Expected Revenue in $",
  "approval_price": "Please respond with Yes or No",
  "Please_explain": "Please explain why",
  "saveEnteredRates": "Checking this box will preserve the entered rates when modifying the values on the pricing section (e.g. Term Length, Volume Commit, AOS)."
}
export const transferTBAll = [{ mark: 10 }, { mark: 150 }, { mark: 500 }, { mark: 1024 }, { mark: 2048 }];
export const transferTBbyGeo = [{ mark: 0 }, { mark: 150 }, { mark: 500 }, { mark: 1024 }, { mark: 2048 }];
export const transferPBAll = [{ mark: 0 }, { mark: 0.5 }, { mark: 1 }, { mark: 2 }];
export const transferPBbyGeo = [{ mark: 0 }, { mark: 0.5 }, { mark: 1 }, { mark: 2 }];
export const outside = [
  {
    optionKey: 1,
    optionValue: "0"
  },
  {
    optionKey: 2,
    optionValue: "25"
  },
  {
    optionKey: 3,
    optionValue: "50"
  },
  {
    optionKey: 4,
    optionValue: "75"
  },
  {
    optionKey: 5,
    optionValue: "100"
  }
];
export const terms = [{ mark: 1 }, { mark: 12 }, { mark: 24 }, { mark: 36 }, { mark: 48 }];
export const aos = [{ mark: 1 }, { mark: 15 }, { mark: 35 }, { mark: 100 }];
export const COMBINEDTRAFFIC = [{ mark: 0 }, { mark: 25 }, { mark: 50 }, { mark: 75 }, { mark: 100 }];
export const CFRC = [
  {
    optionKey: "Monthly",
    optionValue: "Monthly"
  },
  {
    optionKey: "Bucket",
    optionValue: "Bucket"
  },
  {
    optionKey: "Annual",
    optionValue: "Annual"
  }
];
export const CommitType = [
  {
    optionKey: 1,
    optionValue: "Yes"
  },
  {
    optionKey: 2,
    optionValue: "No"
  }
];
export const RegionalTrafficType = [
  {
    optionKey: 1,
    optionValue: "Yes"
  },
  {
    optionKey: 2,
    optionValue: "No"
  }
];
export const regionalMixforAP = [
  {
    optionKey: 1,
    optionValue: "Yes"
  },
  {
    optionKey: 2,
    optionValue: "No"
  }
];
export const volume = [
  {
    optionKey: "TB",
    optionValue: "TB"
  },
  {
    optionKey: "PB",
    optionValue: "PB"
  }
];
export const regions = [
  {
    optionKey: "All",
    optionValue: "All"
  },
  {
    optionKey: "By Geo",
    optionValue: "By Geo"
  }
];
export const commitfee = [
  {
    optionKey: "Waive Request Fees",
    optionValue: "Waive Request Fees"
  },
  {
    optionKey: "Apply Private Rates",
    optionValue: "Apply Private Rates"
  },
  {
    optionKey: "Apply Public Rates",
    optionValue: "Apply Public Rates"
  }
];
export const apiParams = ['Volume Commit', 'Commitment Fee', 'Aos_Selected', 'requested_commit', 'volumeUnit', 'chooseByGeo', 'commitment_fee', 'TermLength', 'TypeOfCFRL', 'geo', 'request_fees'];
export const allData = ["ALL", "EU", "AP", "JP", "SA", "AU", "IN", "ZA", "ME", "NA"];
export const CommitFeeVal = "Minimum AOS less than 35kb for Commitment Fee requires additional approvals";
export const VolumeCommitVal = "Minimum AOS less than 15kb for Volume Commit requires additional approvals";
export const VolumeandCommitVal = "Minimum AOS of less than 15 kb when both Volume and Commitment Fee are selected requires additional approvals";
export const cloudFrontShieldAdvancedAOSVal = 'The minimum average object size eligible for waivers is 35kB for CloudFront + Shield Advanced Combined Rate requests. We can proceed with the request so long as the customer is aligned with paying the penalty rate for AOS falling below the 35kB mark. If the customer REQUIRES request fees be waived for an AOS size lower than 35kB – the customer would no longer qualify for the combined rate card – and thus two service-specific commitments and PPRs would be required if the customer wanted to still proceed with both CF and SA with a WAIVED request fee. In the Deal Summary page, the minimum AOS that will be displayed for drafting purposes will be 35kB.';
export const regionalMixVal = "Total of Regional Usage Mix (in %) should be equal to 100%";
export const regionalMixTraffic = "Total of Regional Traffic Limits (in %) should be equal to 100%";
export const termLengthMinError = "Term length of less than 12 months requires additional approvals";
export const termLengthMaxError = "Term length of more than 36 months requires additional approvals";
export const TermCommitfor12 = "Commitment Fee less than $10,000 requires additional approvals";
export const TermCommitfor24 = "Commitment Fee less than $15,000 requires additional approvals";
export const inputBlurParams = ['Aos_Selected', 'requested_commit', 'commitment_fee', 'TermLength', 'request_fees'];

export const tableHeaderAll = ["NA", "EU/IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME", "ALL"];
export const tableRequestHeaders = ["Type", "NA", "EU/IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME", "ALL"];
export const tableHeaderTier = [">=TB", "<=TB", "NA", "EU/IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME", "ALL"];
export const getTableHeaderTier = (unit) => {
  return [`>=${unit}`, `<=${unit}`, "NA", "EU/IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME", "ALL"]
};
export const regionalTrafficLimitHeaders = ["Outside of NA+EU/IL", "SA+AU+IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME"];
export const regionalUsageMixHeaders = ["NA", "EU/IL", "AP", "JP", "SA", "AU", "IN", "ZA", "ME"];
export const tenpercent = [
  {
    key: '1',
    monthlycommit: '10+TB',
    global: '$ 0.0280',
  },
  {
    key: '2',
    monthlycommit: '20+TB',
    global: '$ 0.0240',
  },
  {
    key: '3',
    monthlycommit: '150+TB',
    global: '$ 0.0200',
  },
  {
    key: '4',
    monthlycommit: '500+TB',
    global: '$ 0.0160',
  },
  {
    key: '5',
    monthlycommit: '1024+TB',
    global: '$ 0.0120',
  },
  {
    key: '6',
    monthlycommit: '2048+TB',
    global: '$ 0.0100',
  },
];
export const tenpercenttwenty = [
  {
    key: '1',
    monthlycommit: '10+TB',
    global: '$ 0.0252',
  },
  {
    key: '2',
    monthlycommit: '20+TB',
    global: '$ 0.0216',
  },
  {
    key: '3',
    monthlycommit: '150+TB',
    global: '$ 0.0180',
  },
  {
    key: '4',
    monthlycommit: '500+TB',
    global: '$ 0.0144',
  },
  {
    key: '5',
    monthlycommit: '1024+TB',
    global: '$ 0.0108',
  },
  {
    key: '6',
    monthlycommit: '2048+TB',
    global: '$ 0.0090',
  },
];
export const columns = [
  {
    title: 'Monthly Commit',
    dataIndex: 'monthlycommit',
    key: 'monthlycommit',
  },
  {
    title: 'Global',
    dataIndex: 'global',
    key: 'global',
  },
];

export const CorePlusPlusoptions = [
  {
    optionValue: 'CF012 - Less than 10K minimum annual commit',
    optionKey: 'CF012 - Less than 10K minimum annual commit',
    defaultValue: null
  }, {
    optionValue: 'CF004 - AOS at 30KB on 35KB',
    optionKey: 'CF004 - AOS at 30KB on 35KB',
    defaultValue: null
  },
  {
    optionValue: 'CF018 - CloudFront commitment reduction if SLA is not met',
    optionKey: 'CF018 - CloudFront commitment reduction if SLA is not met',
    defaultValue: null
  },
  {
    optionValue: 'CF016 - Proxy requests in GET request calculation',
    optionKey: 'CF016 - Proxy requests in GET request calculation',
    defaultValue: null
  },
  {
    optionValue: 'CF015 - Proxy requests pricing',
    optionKey: 'CF015 - Proxy requests pricing',
    defaultValue: null
  },
  {
    optionValue: 'CF013 - Custom SSL Certificate pricing',
    optionKey: 'CF013 - Custom SSL Certificate pricing',
    defaultValue: null
  },
  {
    optionValue: 'CF014 - Custom SSL dedicated IP pricing',
    optionKey: 'CF014 - Custom SSL dedicated IP pricing',
    defaultValue: null
  },
  {
    optionValue: 'Custom ABT',
    optionKey: ' Custom ABT',
    defaultValue: null
  }
];

export const tierObject = {
  "cells": [
    {
      "value": "", "tierKey": ">TB", "editable": false
    },
    {
      "value": "", "tierKey": "<TB", "editable": true
    },
    {
      "value": "", "tierKey": "NA", "editable": true
    },
    {
      "value": "", "tierKey": "EU", "editable": true
    },
    {
      "value": "", "tierKey": "AP", "editable": true
    },
    {
      "value": "", "tierKey": "JP", "editable": true
    },
    {
      "value": "", "tierKey": "SA", "editable": true
    },
    {
      "value": "", "tierKey": "AU", "editable": true
    },
    {
      "value": "", "tierKey": "IN", "editable": true
    },
    {
      "value": "", "tierKey": "ZA", "editable": true
    },
    {
      "value": "", "tierKey": "ME", "editable": true
    },
    {
      "value": "", "tierKey": "ALL", "editable": true
    }
  ]
}
export const commitHelpText = [{
  'Typeofcloudfront': "Please choose from the list the Core++ ABTs against each Request and Justification. If there is no match, please choose Custom ABT",
  'Typeofcommit': "Please choose the Type of Commit",
  'AOS': "Choose the Average Object Size from the slider. AOS doesn’t have to be between 0 and 99. It can be more than 100",
  'TermlengthMonths': "Choose the Term from the slider or enter a value between 0 and 99 in the text box",
  'Datatransfercommitregions': "Choose one of the options All or By Geo",
  'Allregionsvolumecommit': "Please input the Data Transfer Commit",
  'Volumecommit': "Choose one of the options TB or PB for the Volume Commit",
  'data-Transfer': "The Data Transfer Price (per GB) is populated here from the rate card. Edit manually if you are providing a different rate",
  'Requestfees': "Choose one of the options for the type of Request Fees",
  'Totalexpectedrevenue': "Please enter the Total Expected Value in $",
  'ifapproved': "Please respond with Yes or No",
  'pleaseexplain': "Please explain why",
  'Additionalpricing': "Please list if there are any additional terms",
  'CommitFeePrivateRates': "Request rates are in $/10k requests"
}];
export const multiplePegabyte = 1024;
export const inputFieldsforAlert = ['commitment_fee', 'PrivateRates', 'Aos_Selected', 'TermLength', 'requested_commit', 'dataTransferInput', 'total_commitment_fee', 'Aos_Selected_Nocommit', 'TermLength_Nocommit', 'total_commitment_fee_Nocommit', 'dataTransferPricenoCommit', 'combinedTraffic', 'Actual_Aos_Selected', 'regionalTraficMix', 'regionalTrafficLimit', 'regionalMix'];
export const dealDetailsSection = ['winDeal', 'explainWindeal', 'projected_storage', 'sub_region_mix', 'pricingTerms', 'FORMGROUP', 'FormGroupCorePlus', 'FormGroupCorePlus', 'commitOrTerm', 'commitTermText', 'existCommitTermText', 'additionalTerm'];
export const textAreaQuestions = ['regionalTraffic', 'past_price', 'floor_price', 'past_price_noCommit', 'floor_price_noCommit'];