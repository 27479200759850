export const SALES_REPRESENTATIVE = 'Sales Representative';
export const SALES_MANAGER = 'Sales Manager';
export const SCE_OWNER = 'SCE Owner';
export const IGT_OWNER = 'PPO Owner';
export const PRIVATE_PRICING_CHINA = 'Private Pricing China';
export const PRIVATE_PRICING_GLOBAL = 'Private Pricing Global';
export const PRIVATE_PRICING_PUBLIC_SECTOR = 'Private Pricing Public Sector';
export const BILLING_TEAM = 'Onboarding Team';
export const SALES_REPRESENTATIVE_LOADING = 'Loading the Sales Representatives';
export const SALES_MANAGER_LOADING = "Loading the Sales Managers";
export const SCE_OWNER_LOADING = "Loading the SCE Users";
export const IGT_OWNER_LOADING = "Loading the PPO Users";
export const PRIVATE_PRICING_LOADING = "Loading the Users";
export const PRIVATE_PRICING_USERS = ["SCE Owner","PPO Owner","Private Pricing China","Private Pricing Public Sector"];
export const REQUESTOR = 'Requestor';
export const SOLUTION_PROVIDER = 'Solution Provider';
export const ACTIVEROLE = 'activeRole';
export const SECONDARYROLE = 'secondaryRole';
export const ORIGINALSECONDARYROLE = 'originalSecondaryRole';
export const SOLUTIONPROVIDERROLE = 'solutionProvider';
export const ADMIN = 'Admin';
export const SHORT_ROLE_NAME = {
    [SALES_REPRESENTATIVE]: 'SR',
    [SALES_MANAGER]: 'SM',
    [SCE_OWNER]: 'SCE Owner',
    [IGT_OWNER]: 'PPO Owner',
    [PRIVATE_PRICING_CHINA]: 'PP China',
    [PRIVATE_PRICING_GLOBAL]: 'PP Global',
    [PRIVATE_PRICING_PUBLIC_SECTOR]: 'PP Public Sector',
    [BILLING_TEAM]: 'Onboarding Team',
    [REQUESTOR]: 'Requestor',
    [SOLUTION_PROVIDER]: 'SP',
    [ADMIN]: 'Admin'
};
export const IS_SOLUTION_PROVIDER = "isSPUser";