import React, { Component } from "react";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { base64toBlob, isAfterOrgChange } from '../../utils/common';
import { discountEffectiveDateListForMultiSelect } from '../../utils/disscountEffDateList';
import './MonthlyActivity.scss';
import AWSbutton from '../common/AWSbutton/AWSbutton';
import AWSDropdown from '../common/AWSDropdown/AWSDropdown';
import AWSAccountNameAC from "../common/AWSAccountNameAC/AWSAccountNameAC";
import AWSconfig from "../../config/AWSconfig";
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import dealStatusJson from '../../json/dealStatusForMultiSelect.json';
import channeJson from '../../json/channelForMultiSelect.json';
import dealTypeJson from '../../json/dealtypeForMultiSelect.json';
import geoJson from '../../json/geoForReportMultiSelect.json';
import orgChangeGeoJson from '../../json/orgChangeGeoForReportingMultiselect.json';
import subGeoJson from '../../json/subGeoForReportMultiSelect.json';
import buJson from '../../json/buForMultiSelect.json';
import dateRangeJson from '../../json/dateRange.json';
import commitTypeJson from '../../json/commitTypeForMultiSelect.json';
import cfrcTypeJson from '../../json/cfrcTypeForMultiSelect.json';
import businessCustomerTypeJson from '../../json/businessCustomerTypeMultiSelect.json'
import pprtypeJson from '../../json/pprType.json';
import * as roleNames from '../../constants/roleNames.const';
import dealOpenCloseJson from '../../json/dealOpenClose.json';
import { Multiselect } from "@amzn/awsui-components-react";
import './SalesReport.scss';
import orgChangeBusinessUnitOptionsMultiSelect from '../../json/orgChangeBusinessUnitOptionsMultiSelect.json';

const config = new AWSconfig();
// const currentDate = moment();
const salesReportUrl = `${config.awsFalconSearchUrl}exportppr`;
const userRoleUrl = `${config.userManagementbaseUrl}users/getuserRoles`;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
axios.defaults.headers.common["Content-Type"] = 'application/json'


class ExportPPR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDate: '',
            salesManagerApprovalDte: '',
            fullyExwcutedDate: '',
            submittedStartDate: null,
            submittedEndDate: null,
            smStartDate: null,
            smEndDate: null,
            fullyExecutedStartDate: null,
            fullyExecutedEndDate: null,
            channel: [],
            dealType: [],
            dealStatus: [],
            geo: [],
            disTermDate: [],
            bu: [],
            commitType: [],
            cfrcType: [],
            dealStatusOptions: null,
            loadingMessage: '',
            loadingMessageDuration: '',
            activateToast: false,
            subgeoOptions: [],
            pprType: '',
            custLegalName: '',
            questionValue: { isVisible: true, isMandatory: true, queLabel: 'Customer Legal Name', sF_Id: 'cust_leg_nam', tagName: '' },
            salesRpeRequestor: [],
            salesManager: [],
            salesRepusers: [],
            salesManagerUsers: [],
            maxDate: new Date(),
            salesRepRequestorSelectedOptions: [],
            salesManagerSelectedOptions: [],
            discountTermSelectedOptions: [],
            geoSelectedOptions: [],
            subGeo: [],
            subGeoSelectedOptions: [],
            cfrcSelectedOptions: [],
            commitTypeSelectedOptions: [],
            buSelectedOptions: [],
            channelSelectedOptions: [],
            dealStatusSelectedOptions: [],
            dealtypeSelectedOptions: [],
            businessCustomerTypeOptions: []
        }
        this.baseState = this.state;
    }

    componentDidMount() {
        const { locationState } = this.props;
        const { userRole = '' } = locationState || {};
        const salesRepuserRole = roleNames.SALES_REPRESENTATIVE;
        const salesManagerRole = roleNames.SALES_MANAGER;
        if (userRole === roleNames.SALES_REPRESENTATIVE)
            this.getAllUsers(userRole);
        else this.getAllUsers(salesRepuserRole, salesManagerRole);
    }

    resetFilter = () => {
        this.setState(this.baseState);
    }

    showsalesReportToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    getAllUsers = (userRoleRep, userRoleManger) => {

        const { salesRepusers, salesManagerUsers } = this.state || {};
        const reqBodyJson = { "userRole": userRoleRep }
        if (userRoleManger === undefined) {
            axios({
                method: 'POST',
                url: userRoleUrl,
                data: reqBodyJson
            }).then(resp => {

                resp.data.Data.map((Items, index) => {
                    let option = { "id": Items.AWSAlias, "label": `${Items.FirstName} ${Items.LastName} (${Items.AWSAlias})` }
                    salesRepusers.push(option);
                })
                this.setState({ salesRepusers });
            }).catch((err) => {
                if (err && err.response) {
                    this.setState(prevState => {
                        return {
                            salesRepusers: prevState.salesRepusers
                        }
                    })
                }
            });
        } else {
            const reqBodyJson1 = { "userRole": userRoleRep }
            const reqBodyJson2 = { "userRole": userRoleManger }
            axios.all([axios({
                method: 'POST',
                url: userRoleUrl,
                data: reqBodyJson1 }).catch(err => console.log(err)), axios({method: "POST", url: userRoleUrl,
                data: reqBodyJson2
            })]).then((responseArr) => {
                responseArr[0].data.Data.map((salesreps, index) => {
                    let option = { "id": salesreps.AWSAlias, "label": `${salesreps.FirstName} ${salesreps.LastName} (${salesreps.AWSAlias})` }
                    salesRepusers.push(option);

                })
                responseArr[1].data.Data.map((salesmr, index) => {
                    let option = { "id": salesmr.AWSAlias, "label": `${salesmr.FirstName} ${salesmr.LastName} (${salesmr.AWSAlias})` }
                    salesManagerUsers.push(option);
                })
                this.setState({ salesRepusers, salesManagerUsers });
            }).catch(err => console.log(err));

        }
    }

    handleSelectedDateRange = (value, id) => {
        if (id === 'ppr_sub_date' && this.state.filterDate !== value) {
            const result = this.setDateFor(value);
            this.setState({
                submittedStartDate: result['startDate'],
                submittedEndDate: result['endDate'],
                filterDate: value
            });
        } else if (id === 'sales_app_date' && this.state.salesManagerApprovalDte !== value) {
            const result = this.setDateFor(value);
            this.setState({
                smStartDate: result['startDate'],
                smEndDate: result['endDate'],
                salesManagerApprovalDte: value
            })
        } else if (id === 'fully_exec_date' && this.state.fullyExwcutedDate !== value) {
            const result = this.setDateFor(value);
            this.setState({
                fullyExecutedStartDate: result['startDate'],
                fullyExecutedEndDate: result['endDate'],
                fullyExwcutedDate: value
            })
        } else {
            this.setState(prevState => {
                return {
                    submittedStartDate: prevState.submittedStartDate,
                    submittedEndDate: prevState.submittedEndDate,
                    filterDate: prevState.filterDate,
                    smStartDate: prevState.smStartDate,
                    smEndDate: prevState.smEndDate,
                    salesManagerApprovalDte: prevState.salesManagerApprovalDte,
                    fullyExecutedStartDate: prevState.fullyExecutedStartDate,
                    fullyExecutedEndDate: prevState.fullyExecutedEndDate,
                    fullyExwcutedDate: prevState.fullyExwcutedDate
                }
            })
        }
    }

    setDateFor = (value) => {
        let startDate, endDate, startOfDate;
        let currentDate = moment();
        let dayStart = currentDate.clone().startOf("day").utc().format();
        startOfDate = new Date(dayStart);
        switch (value) {
            case "Today":
                startDate = new Date(dayStart);
                endDate = new Date(moment(startDate).utc().add(24, 'hours').format());
                break;
            case "Current Week":
                var weekStart = currentDate.clone().startOf("isoWeek").utc().format();
                startDate = new Date(weekStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Current Month":
                var monthStart = currentDate.clone().startOf("month").utc().format();
                startDate = new Date(monthStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Current Quarter":
                var quarterStart = currentDate.clone().startOf("quarter").format();
                startDate = new Date(quarterStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Custom Dates":
                var monthStart = currentDate.clone().startOf("month").utc().format();
                startDate = new Date(monthStart);
                endDate = new Date(moment().utc().format());
                break;
            default:
        }

        return { startDate, endDate };
    }

    handleChange = (val, id) => {
        const valType = typeof (val);
        switch (id) {
            case "deal_type":
                this.setState({ dealType: val.selectedIds, dealtypeSelectedOptions: val.selectedOptions })

                break;
            case 'deal_status':
                this.setState({ dealStatus: val.selectedIds, dealStatusSelectedOptions: val.selectedOptions })
                break;
            case "channel":
                this.setState({ channel: val.selectedIds, channelSelectedOptions: val.selectedOptions })
                break;
            case "bu":
                this.setState({ bu: val.selectedIds, buSelectedOptions: val.selectedOptions })
                break;
            case "commit_type":
                this.setState({ commitType: val.selectedIds, commitTypeSelectedOptions: val.selectedOptions })
                break;
            case "cfrc_type":
                this.setState({ cfrcType: val.selectedIds, cfrcSelectedOptions: val.selectedOptions })
                break;

            case 'geo':
                if (val.selectedIds.length === 1 && val.selectedIds.includes("GLBL")) {
                    this.setState({ subGeo: [], subGeoSelectedOptions: [], geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                } else {
                    this.setState({ geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                }
                break;

            case 'subGeo':
                this.setState({ subGeo: val.selectedIds, subGeoSelectedOptions: val.selectedOptions })
                break;

            case "ppr_type":
                if (valType === "object")
                    this.setState({ pprType: '' });
                else if (val === "Select")
                    this.setState({ pprType: '' });
                else this.setState({ pprType: val });
                break;
            case 'disTerm':
                this.setState({ disTermDate: val.selectedIds, discountTermSelectedOptions: val.selectedOptions })
                break;
            case "deal_stage":
                if (valType === "object")
                    this.setState({ dealStage: '' });
                else if (val === "Select")
                    this.setState({ dealStage: '' });
                else
                    this.setState({ dealStage: val });
                break;
            case 'salesRep':
                this.setState({ salesRpeRequestor: val.selectedIds, salesRepRequestorSelectedOptions: val.selectedOptions })
                break;
            case 'salesManager':
                this.setState({ salesManager: val.selectedIds, salesManagerSelectedOptions: val.selectedOptions })
                break;
            case "start_pprsubmit_date":
                this.setState({ submittedStartDate: val });
                break;
            case "end_pprsubmit_date":
                this.setState({ submittedEndDate: val });
                break;
            case "sm_start_date":
                this.setState({ smStartDate: val });
                break;
            case "sm_end_date":
                this.setState({ smEndDate: val });
                break;
            case "exec_start_date":
                this.setState({ fullyExecutedStartDate: val });
                break;
            case "exec_end_date":
                this.setState({ fullyExecutedEndDate: val });
                break;
            case "cust_leg_name":
                this.setState({ custLegalName: val });
                break;
            case "business_customer_type":
                this.setState({ businessCustomerType: val.selectedIds, businessCustomerTypeOptions: val.selectedOptions })
                break;
        }
    }

    onDropdownSelection = (val) => {
        const url = `${config.awsFalconSearchUrl}custlegalsearch`;
        const postData = {
            query: {
                match_phrase_prefix: {
                    "Customer Legal Name": val,
                },
            },
            _source: ['Customer Legal Name']
        };
        axios({
            method: 'POST',
            url: url,
            data: postData
        }).then(resp => {
            const Customer_Legal_Name = resp.data.filter(res => res[`Customer Legal Name`] === val)[0][`Customer Legal Name`];
            this.setState({ custLegalName: Customer_Legal_Name })
        }).catch((e) => {
            if (e && e.response) {
                this.setState(prevState => {
                    return {
                        custLegalName: prevState.custLegalName
                    }
                })
            }
            // console.warn('Auto completed selection warning', e);
        });
    }

    generateExportReport = () => {
        let reqBodyJson;
        const {
            dealStatus,
            submittedStartDate,
            submittedEndDate,
            smStartDate,
            smEndDate,
            fullyExecutedStartDate,
            fullyExecutedEndDate,
            channel,
            dealType,
            geo,
            subGeo,
            disTermDate,
            bu,
            commitType,
            cfrcType,
            pprType,
            custLegalName,
            salesRpeRequestor,
            salesManager,
            dealStage,
            filterDate,
            businessCustomerType
        } = this.state || {};

        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};
        let _submittedEndDate = submittedEndDate, _smEndDate = smEndDate, _fullyExecutedEndDate = fullyExecutedEndDate;
        if (filterDate === 'Custom Dates') {
            if (submittedEndDate !== undefined)
                _submittedEndDate = moment(submittedEndDate).clone().startOf("day").utc().add(24, 'hours').format();
            else if (smEndDate !== undefined)
                _smEndDate = moment(smEndDate).clone().startOf("day").utc().add(24, 'hours').format();
            else if (fullyExecutedEndDate !== undefined)
                _fullyExecutedEndDate = moment(fullyExecutedEndDate).clone().startOf("day").utc().add(24, 'hours').format();
        }
        if (userRole === roleNames.SALES_REPRESENTATIVE || userRole === roleNames.SALES_MANAGER)
            reqBodyJson = {
                "dealStatus": dealStatus,
                "submittedStartDate": submittedStartDate,
                "submittedEndDate": _submittedEndDate,
                "smStartDate": smStartDate,
                "smEndDate": _smEndDate,
                "fullyExecutedStartDate": fullyExecutedStartDate,
                "fullyExecutedEndDate": _fullyExecutedEndDate,
                "channel": channel,
                "dealType": dealType,
                "geo": geo,
                "subgeo": geo.length === 1 && geo.includes("GLBL") ? subGeo : [],
                "disTerm": disTermDate,
                "bu": bu,
                "custLegalName": custLegalName,
                "requestor": salesRpeRequestor,
                "salesManager": salesManager,
                "commitType": commitType,
                "AWSAlias": alias,
                "userRole": userRole,
                "dealReady": dealStage,
                "businessCustomerType": businessCustomerType
            }
        else {
            reqBodyJson = {
                "dealStatus": dealStatus,
                "submittedStartDate": submittedStartDate,
                "submittedEndDate": _submittedEndDate,
                "smStartDate": smStartDate,
                "smEndDate": _smEndDate,
                "fullyExecutedStartDate": fullyExecutedStartDate,
                "fullyExecutedEndDate": _fullyExecutedEndDate,
                "channel": channel,
                "dealType": dealType,
                "geo": geo,
                "subgeo": geo.length === 1 && geo.includes("GLBL") ? subGeo : [],
                "disTerm": disTermDate,
                "bu": bu,
                "custLegalName": custLegalName,
                "requestor": salesRpeRequestor,
                "salesManager": salesManager,
                "pprType": pprType,
                "commitType": commitType,
                "cfrcType": cfrcType,
                "AWSAlias": alias,
                "userRole": userRole,
                "dealReady": dealStage,
                "businessCustomerType": businessCustomerType
            }
        }
        this.showsalesReportToast('Please wait while Export Report is generating...', true);
        axios({
            method: 'POST',
            url: salesReportUrl,
            data: reqBodyJson
        })
        .then(response => {
            this.showsalesReportToast('Preparing the CSV FILE to be downloaded....', true);
            const { data } = response;
            if(data && data.presignedURL) {
                const { presignedURL } = data;
                    this.downloadCSVFile(presignedURL, "PPR");
            } else {
                this.showsalesReportToast('There is no data available with these search filter...', true);
            }
            }).catch((err) => {
                const { response: { data: { message }, status } } = err;
                if (status === 401)
                    this.showsalesReportToast(message, true);
                else if (status >= 400)
                    this.showsalesReportToast('Error Occured while performing the operation', true);

            });
    }

    downloadCSVFile = (sUrl, fileName) => {
        //If in Chrome or Safari or Firefox - download via virtual link click
        //Creating new link node.
        var link = document.createElement('a');
        link.href = sUrl;

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            // var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);

            link.download = `${fileName}_ExportReport.CSV`;
        }

        //Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
        // Force file download (whether supported by server).
        var query = '?download';

        window.open(sUrl + query);
    }

    render() {
        const { locationState } = this.props;
        const { userRole = '' } = locationState || {};
        const {
            filterDate,
            salesManagerApprovalDte,
            fullyExwcutedDate,
            fullyExecutedStartDate,
            fullyExecutedEndDate,
            loadingMessage,
            channel,
            submittedStartDate,
            submittedEndDate,
            smStartDate,
            smEndDate,
            disTermDate,
            dealStatus,
            geo,
            bu,
            commitType,
            cfrcType,
            subgeoOptions,
            pprType,
            dealType,
            custLegalName,
            questionValue,
            salesRepusers = [],
            salesManagerUsers = [],
            salesRpeRequestor,
            salesManager,
            dealStage,
            maxDate,
            discountTermSelectedOptions,
            salesRepRequestorSelectedOptions,
            salesManagerSelectedOptions,
            geoSelectedOptions,
            subGeoSelectedOptions,
            cfrcSelectedOptions,
            commitTypeSelectedOptions,
            buSelectedOptions,
            channelSelectedOptions,
            dealStatusSelectedOptions,
            dealtypeSelectedOptions,
            businessCustomerTypeOptions
        } = this.state || {};
        return (
            <div className="mothlyactivity-wraper full-width export-ppr-report">
                <div className="col-9 float-left">
                    <h1 className="font-size18 amber-bold">Export PPR</h1>
                    {/* <p className="amber-bold pt-4 mb-2">Reports</p> */}
                    {this.state.filterDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={submittedStartDate}
                                    onChange={(value) => this.handleChange(value, "start_pprsubmit_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={submittedEndDate}
                                    onChange={(value) => this.handleChange(value, "end_pprsubmit_date")}
                                    minDate={submittedStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.salesManagerApprovalDte === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={smStartDate}
                                    onChange={(value) => this.handleChange(value, "sm_start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={smEndDate}
                                    onChange={(value) => this.handleChange(value, "sm_end_date")}
                                    minDate={smStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.fullyExwcutedDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={fullyExecutedStartDate}
                                    onChange={(value) => this.handleChange(value, "exec_start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={fullyExecutedEndDate}
                                    onChange={(value) => this.handleChange(value, "exec_end_date")}
                                    minDate={fullyExecutedStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    <div className="generate-btn col-12 float-left p-0">
                        <div className="col-8 float-left p-0">
                            <AWSbutton
                                btnType="fill"
                                label="Generate"
                                isIcon={false}
                                onClick={this.generateExportReport}
                            />
                        </div>
                    </div>
                    <div className="full-width pt-5">
                        <p className="dark-black font-size16">Select the filter to get details of report</p>
                    </div>
                </div>
                <div className="col-3 float-left pr-0">
                    <p className="font-size14 amber-bold">Filters</p>
                    <ul className="full-width m-0 p-0 filter-wraper pr-2">
                        <li>
                            <AWSDropdown
                                id="deal_stage"
                                label="Open/Closed"
                                options={dealOpenCloseJson}
                                value={dealStage}
                                handleOnSelect={(value) => this.handleChange(value, "deal_stage")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="date_filter"
                                defaultValue="Select"
                                label="PPR Submitted Date"
                                options={dateRangeJson}
                                value={filterDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "ppr_sub_date")}
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="sales_date"
                                label="Sales Manager Approval Date"
                                options={dateRangeJson}
                                value={salesManagerApprovalDte}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "sales_app_date")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="fully_exec_date"
                                label="Fully Executed Date"
                                options={dateRangeJson}
                                value={fullyExwcutedDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "fully_exec_date")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSAccountNameAC
                                id="cust_legal_name"
                                type="Customer Legal Name"
                                questionValue={questionValue}
                                value={custLegalName}
                                showHelpTooltip={true}
                                onChangeFunc={(value) => this.handleChange(value, "cust_leg_name")}
                                onSelectOption={(val) => this.onDropdownSelection(val)}
                                payerIdAccountValidation={() => { }}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Direct/Reseller/Solution Provider
                            </span>
                            <Multiselect
                                id="channel"
                                options={channeJson}
                                selectedOptions={channelSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'channel')}
                            />
                        </li>
                        {userRole === roleNames.SCE_OWNER || userRole === roleNames.IGT_OWNER || userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
                            || userRole === roleNames.PRIVATE_PRICING_CHINA ?
                            <li>
                                <AWSDropdown
                                    id="ppr_type"
                                    defaultValue="Select"
                                    label="PPR Type"
                                    options={pprtypeJson}
                                    value={pprType}
                                    handleOnSelect={(value) => this.handleChange(value, 'ppr_type')}
                                    filterDD="filter"
                                />
                            </li> : null
                        }
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Deal Type
                            </span>
                            <Multiselect
                                id="deal_type"
                                options={dealTypeJson}
                                selectedOptions={dealtypeSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'deal_type')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Deal Status
                            </span>
                            <Multiselect
                                id="deal_status"
                                options={dealStatusJson}
                                selectedOptions={dealStatusSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'deal_status')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Geo
                            </span>
                            <Multiselect
                                id="geo"
                                options={isAfterOrgChange() ? orgChangeGeoJson : geoJson}
                                selectedOptions={geoSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'geo')}
                            />
                        </li>

                        {
                            geo.length === 1 && geo.includes("GLBL") && (
                                <li>
                                    <span className='input-label float-left mb-2 label-txt'>
                                        Sub-Geo
                            </span>
                                    <Multiselect
                                        id="subGeo"
                                        options={subGeoJson}
                                        selectedOptions={subGeoSelectedOptions}
                                        placeholder="Select"
                                        checkboxes={true}
                                        selectedLabel="Selected"
                                        onChange={(e) => this.handleChange(e.detail, 'subGeo')}
                                    />
                                </li>
                            )
                        }
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Discount Term Effective Date
                            </span>
                            <Multiselect
                                id="disTerm"
                                label="Select"
                                options={discountEffectiveDateListForMultiSelect()}
                                selectedOptions={discountTermSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'disTerm')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                BU
                                    </span>
                            <Multiselect
                                id="bu"
                                label="Select"
                                options={isAfterOrgChange() ? orgChangeBusinessUnitOptionsMultiSelect : buJson}
                                selectedOptions={buSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'bu')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Commit Type
                                    </span>
                            <Multiselect
                                id="commit_type"
                                label="Select"
                                options={commitTypeJson || []}
                                selectedOptions={commitTypeSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'commit_type')}
                            />
                        </li>
                        {(isAfterOrgChange() && <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Customer Type
                                    </span>
                            <Multiselect
                                id="business_customer_type"
                                label="Select"
                                options={businessCustomerTypeJson || []}
                                selectedOptions={businessCustomerTypeOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'business_customer_type')}
                            />
                        </li>)}
                        {roleNames.PRIVATE_PRICING_USERS.includes(userRole) ?
                            <li>
                                <span className='input-label float-left mb-2 label-txt'>
                                    CFRC Type
                                    </span>
                                <Multiselect
                                    id="cfrc_type"
                                    label="Select"
                                    options={cfrcTypeJson || []}
                                    selectedOptions={cfrcSelectedOptions}
                                    placeholder="Select"
                                    checkboxes={true}
                                    selectedLabel="Selected"
                                    onChange={(e) => this.handleChange(e.detail, 'cfrc_type')}
                                />
                            </li> : null}
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Sales Rep (Requestor)
                                    </span>
                            <Multiselect
                                id="sales_rep"
                                label="Select"
                                options={salesRepusers || []}
                                selectedOptions={salesRepRequestorSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'salesRep')}
                            />
                        </li>
                        {roleNames.PRIVATE_PRICING_USERS.includes(userRole) || userRole === roleNames.SALES_MANAGER ?
                            <li>
                                <span className='input-label float-left mb-2 label-txt'>
                                    Sales Manager
                                    </span>
                                <Multiselect
                                    id="sm"
                                    label="Select"
                                    options={salesManagerUsers || []}
                                    selectedOptions={salesManagerSelectedOptions}
                                    placeholder="Select"
                                    checkboxes={true}
                                    selectedLabel="Selected"
                                    onChange={(e) => this.handleChange(e.detail, 'salesManager')}
                                />
                            </li> : null
                        }
                    </ul>
                    <div className="pt-4 float-left">
                        <AWSbutton
                            btnType="fill"
                            label="Reset Filter"
                            isIcon={false}
                            onClick={this.resetFilter}
                        />
                    </div>
                    {this.state.activateToast && (
                        <AWSLoadingToast
                            message={loadingMessage}
                        />
                    )}
                </div>
            </div>
        )
    }
}

ExportPPR.propTypes = {
    locationState: PropTypes.object
}
export default ExportPPR;