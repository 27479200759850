import React, { Component } from 'react';
import LeftNavCollaboarion from './LeftNavCollaboration';
import LeftnavigationSummary from './LeftNavigationSummary';
import LeftNavAttachement from './LeftNavAttachment';
// import LeftNavTermiantionSummary from './LeftNavTermiantionSummary';
import LeftNavTerminationSummary from './LeftNavTermiantionSummary'
import LeftNavTerminationAttachment from './LeftNavTerminationAttachment';
import LeftNavTerminationCommuntication from './LeftNavTerminationCommuntication';
import './LeftNavTab.scss';
import youtubeIcon from '../../../images/Play.png';
import * as roleNames from '../../../constants/roleNames.const';
import { Icon } from "@amzn/awsui-components-react";
import VideoIcon from '../../common/Icon/VideoIcon/VideoIcon';
class LeftNavTab extends Component {
    state = {
        showCollaborationFlag: false,
        showAttachmentFlag: false,
        showDealSummaryFlag: true,
        showTerminationAttachmentFlag: false
    }
    /*load summarypage */
    loadSummaryPage = () => {
        this.setState({
            showDealSummaryFlag: true,
            showAttachmentFlag: false,
            showCollaborationFlag: false,
            showTerminationAttachmentFlag: false,
            showTerminationCommunication: false

        })
    }

    /* show collaboration data */
    loadCollaboation = () => {
        this.setState({
            showCollaborationFlag: true,
            showDealSummaryFlag: false,
            showAttachmentFlag: false,
        })
    }

    /*load atachments*/
    loadAttachment = () => {
        this.setState({
            showAttachmentFlag: true,
            showCollaborationFlag: false,
            showDealSummaryFlag: false
        })
    }

    // load termination attachments
    loadTerminationAttachment = () => {
        this.setState({
            showTerminationAttachmentFlag: true,
            showDealSummaryFlag: false,
            showTerminationCommunication: false
        })
    }
    // load communication tab
    loadTerminationCommunication = () => {
        this.setState({
            showTerminationAttachmentFlag: false,
            showDealSummaryFlag: false,
            showTerminationCommunication: true
        })
    }
    navigatedealSummary = () => {
        window.open("https://broadcast.amazon.com/videos/192265", '_blank')
    }
    navigatecommunicateSummary = () => {
        window.open("https://broadcast.amazon.com/videos/192399", '_blank')
    }
    navigateAttachmentSummary = () => {
        window.open("https://broadcast.amazon.com/videos/192402", '_blank')
    }
    render() {
        let { terminationPage, terminationSummaryData, dealAction, neglectCommitAndPricing } = this.props;
        return (
            <div className="leftnav-tabs full-width tabs-padding-top pl-2">
                <ul className="col-12 float-left p-0 tabs-contanier pb-0 pl-0">
                    <div className="col-12 float-left tabs-wraper pl-0">
                        <div className="tabs pl-0" onClick={() => { this.loadSummaryPage() }}><span className={this.state.showDealSummaryFlag ? "font-size16 white float-left p-0 pb-2 active-tab" : "font-size16 white float-left p-0 pb-2"}>Deal Summary
                            {(this.props.userRole === roleNames.SALES_REPRESENTATIVE && terminationPage === false) && (<span className="youtube-icon leftnav-summary-youtube dealsummary-video" onClick={() => { this.navigatedealSummary() }}>
                                {/* <img src={youtubeIcon} alt="youtube" /> */}
                                <VideoIcon />
                                <span className="leftnav-summary-tooltip">Deal Summary - Review and Submit PPR</span>
                            </span>)
                            }
                        </span></div>
                        {terminationPage === false && (<div className="tabs" onClick={() => { this.loadCollaboation() }}><span className={this.state.showCollaborationFlag ? "font-size16 white float-left p-0 active-tab pb-2" : "font-size16 white float-left p-0 pb-2"}>Comments History
                            {this.props.userRole === roleNames.SALES_REPRESENTATIVE && <span className="youtube-icon leftnav-summary-youtube" onClick={() => { this.navigatecommunicateSummary() }}>
                                {/* <img src={youtubeIcon} alt="youtube" /> */}
                                <VideoIcon />
                                <span className="leftnav-summary-tooltip comments-history-tooltip">Deal Summary - Comments History</span></span>}</span></div>)}
                        {terminationPage === true && (<div className="tabs" onClick={() => { this.loadTerminationCommunication() }}><span className={this.state.showTerminationCommunication ? "font-size16 white float-left p-0 active-tab pb-2" : "font-size16 white float-left p-0 pb-2"}>Comments History</span></div>)}
                        {terminationPage === false && (<div className="tabs" onClick={() => { this.loadAttachment() }}><span className={this.state.showAttachmentFlag ? "font-size16 white float-left p-0 active-tab pb-2" : "font-size16 white float-left p-0 pb-2"}>Attachments
                            {this.props.userRole === roleNames.SALES_REPRESENTATIVE && <span className="youtube-icon leftnav-summary-youtube" onClick={() => { this.navigateAttachmentSummary() }}>
                                {/* <img src={youtubeIcon} alt="youtube" /> */}
                                <VideoIcon />
                                <span className="leftnav-summary-tooltip attachments-tooltip">Deal Summary - Attachments</span></span>}</span></div>)}
                        {terminationPage === true && (<div className="tabs" onClick={() => { this.loadTerminationAttachment() }}><span className={this.state.showTerminationAttachmentFlag ? "font-size16 white float-left p-0 active-tab pb-2" : "font-size16 white float-left p-0 pb-2"}>Attachments</span></div>)}
                    </div>
                    <div className="full-width border-btm-tabsummary short-width-line"></div>

                </ul>

                {
                    this.state.showDealSummaryFlag && (terminationPage === true) && <div className="full-width pt-4">
                        <LeftNavTerminationSummary
                            userRole={this.props.userRole}
                            ownerType={this.props.ownerType}
                            disableSubmitButton={this.props.disableSubmitButton}
                            summarCommentArrayObject={this.props.summarCommentArrayObject}
                            salesRepName={this.props.salesRepName}
                            terminationSummaryData={terminationSummaryData}
                            dealAction={dealAction}
                            summaryComment={this.props.summaryComment}
                            triggerCommentValidation={this.props.triggerCommentValidation}
                            handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
                            handleSubmit={this.props.handleSubmit}
                            handleOnClick={this.props.handleOnClick}
                            pprId={this.props.pprId}
                            showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                        />
                    </div>
                }
                {
                    this.state.showTerminationAttachmentFlag && (terminationPage === true) && <div className="full-width pt-0">
                        <LeftNavTerminationAttachment
                            userRole={this.props.userRole}
                            pprId={this.props.pprId}
                            dealStatus={this.props.dealStatus}
                            locationStateUser={this.props.locationStateUser}
                            showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                            handleOnClick={this.props.handleOnClick}
                        />
                    </div>
                }
                {
                    this.state.showTerminationCommunication && (terminationPage === true) && <div className="full-width">
                        <LeftNavTerminationCommuntication
                            handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
                            summarCommentArrayObject={this.props.summarCommentArrayObject}
                            summaryComment={this.props.summaryComment}
                            summaryCommentTimeStamp={this.props.summaryCommentTimeStamp}
                            handleSubmit={this.props.handleSubmit}
                            dealAction={this.props.dealAction}
                            handleOnClick={this.props.handleOnClick}
                            locationStateUser={this.props.locationStateUser}
                            commentObjectArrayFromCollabsTab={this.props.commentObjectArrayFromCollabsTab}
                            commentArrayFromCollabTabs={this.props.commentArrayFromCollabTabs}
                            pprId={this.props.pprId}
                            dealStatus={this.props.dealStatus}
                            createdById={this.props.createdById}
                            showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                            userRole={this.props.userRole}
                        />
                    </div>
                }
                {
                    this.state.showDealSummaryFlag && (terminationPage === false) && <div className="full-width pt-0">
                        <LeftnavigationSummary
                            pprType={this.props.pprType}
                            neglectCommitAndPricing={neglectCommitAndPricing}
                            dealIdentifier={this.props.dealIdentifier}
                            tempArray={this.props.tempArray}
                            customerLegalName={this.props.customerLegalName}
                            triggerCommentValidation={this.props.triggerCommentValidation}
                            pprId={this.props.pprId}
                            summarCommentArrayObject={this.props.summarCommentArrayObject}
                            salesRepName={this.props.salesRepName}
                            locationStateUser={this.props.locationStateUser}
                            dealStatus={this.props.dealStatus}
                            userRole={this.props.userRole}
                            ownerType={this.props.ownerType}
                            dealAction={this.props.dealAction}
                            summaryData={this.props.summaryData}
                            summaryTable={this.props.summaryTable}
                            leftNavTierPricing={this.props.leftNavTierPricing}
                            leftNavTierPricingTable={this.props.leftNavTierPricingTable}
                            regionalLimitsTable={this.props.regionalLimitsTable}
                            regionalTrafficLimitTable={this.props.regionalTrafficLimitTable}
                            requestFeesTable={this.props.requestFeesTable}
                            rateCardData={this.props.rateCardData}
                            pprData={this.props.pprData}
                            summaryComment={this.props.summaryComment}
                            summaryCommentTimeStamp={this.props.summaryCommentTimeStamp}
                            disableSubmitButton={this.props.disableSubmitButton}
                            handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
                            handleSubmit={this.props.handleSubmit}
                            dealAction={this.props.dealAction}
                            userRole={this.props.userRole}
                            additionalPricingTerms={this.props.additionalPricingTerms}
                            pprId={this.props.pprId}
                            showAdiDealTermInSummary={this.props.showAdiDealTermInSummary}
                            adiDealTermFormGroup={this.props.adiDealTermFormGroup}
                            enableCommentBox={this.props.enableCommentBox}
                            showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                            combinedTraffic={this.props.combinedTraffic}
                            createdById={this.props.createdById}
                            shouldShowPTS={this.props.shouldShowPTS}
                            selectedDealType={this.props.selectedDealType}
                            handleOnClick={this.props.handleOnClick}
                            affiliatedIdArray={this.props.affiliatedIdArray}
                            qualifyingQuestionIds={this.props.qualifyingQuestionIds}
                            originShieldQuestionIds={this.props.originShieldQuestionIds}
                            dealInfo={this.props.dealInfo}
                        />
                    </div>
                }
                {this.state.showCollaborationFlag &&
                    <div className="full-width">
                        <LeftNavCollaboarion
                            handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
                            summarCommentArrayObject={this.props.summarCommentArrayObject}
                            summaryComment={this.props.summaryComment}
                            summaryCommentTimeStamp={this.props.summaryCommentTimeStamp}
                            handleSubmit={this.props.handleSubmit}
                            dealAction={this.props.dealAction}
                            handleOnClick={this.props.handleOnClick}
                            locationStateUser={this.props.locationStateUser}
                            commentObjectArrayFromCollabsTab={this.props.commentObjectArrayFromCollabsTab}
                            commentArrayFromCollabTabs={this.props.commentArrayFromCollabTabs}
                            pprId={this.props.pprId}
                            dealStatus={this.props.dealStatus}
                            createdById={this.props.createdById}
                            showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                            dealInfo={this.props.dealInfo}
                            accountManager={this.props.accountManager}
                            accountName={this.props.accountName}
                            userRole={this.props.userRole}
                        />
                    </div>
                }


                {this.state.showAttachmentFlag && <div className="full-width">
                    {/* <LeftNavTermiantionSummary/> */}
                    <LeftNavAttachement
                        canEditPPR={this.props.canEditPPR}
                        pprId={this.props.pprId}
                        dealStatus={this.props.dealStatus}
                        locationStateUser={this.props.locationStateUser}
                        showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                        handleOnClick={this.props.handleOnClick}
                    /></div>}
            </div>
        )
    }
}
export default LeftNavTab;
