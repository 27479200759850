import React from 'react';
import PropTypes from "prop-types";
import _ from 'underscore';
import '../../Dashboard/AWSDashboard.scss'
export default class AWSTableDeleteIcon extends React.Component {

    render() {
        let { dealStatus, createdById, userAlias } = this.props;
        if (dealStatus && dealStatus === 'PPR Created' && createdById === userAlias) {
            return (
                <div className='text-center'>
                    {/* <Tooltip content="Delete"> */}
                        <span className='delete-icon icons-styles cursor-pointer' />
                    {/* </Tooltip> */}
                </div>
            );
        } else {
            return (
                <div className='text-center'>
                    <span className='delete-icon-grey icons-styles pointer-events-none' />
                </div>
            )
        }

    }
}

AWSTableDeleteIcon.propTypes = {
    label: PropTypes.string,
    onChangeFunc: PropTypes.func
};
