import React from "react";
import AWSUserManagement from "../AWSUsers/AWSUserManagement";
import AWSRoleManagement from "../AWSUsers/AWSRoleManagement";
import PropTypes from "prop-types";

class AWSUsers extends React.Component {

  /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
    */
  showUserManagementLoadingToast = (message, toast) => {
    this.props.showUserManagementLoadingToast(message, toast);
  }

  render() {
    const { selectedStage } = this.props;
    const { location } = this.props;
    return (
      <div>
        {
          selectedStage === 1 ?
            <AWSRoleManagement
              showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
            /> :
            <AWSUserManagement
              location={location}
              showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
            />
        }
      </div>
    );
  }
}

export default AWSUsers;

AWSUsers.propTypes = {
  selectedStage: PropTypes.any,
  showUserManagementLoadingToast: PropTypes.func,
};
