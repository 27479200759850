import React, { Component } from 'react';


class LeftNavigationCommon extends Component {

    handleOnClick = (index) => {
        this.props.handleLefNavClick(index)
    }

    terminationPageLeftNav = (navMenu) => {
        return navMenu.map((menu, index) => {            
            return (
                <li id={`menu_${menu.sF_Id}`} key={`${menu.stageName}_${index}`}  className="pl-4 pt-3">
                    <p className="nav-txt active-nav mb-1 pl-0 full-width text-left float-left">{menu.stageName}</p>
                    <p className="font-size14 white mt-2 pl-0 float-left">{menu.queBlock[0].infoText}</p>
                </li>
            )
        }
        )
    }

    commonLeftNav = (navMenu) => {
        return navMenu && navMenu.map((menu, index) => {
            return (
                <li id={`menu_${index}`} key={`${menu}_${index}`} onClick={() => this.handleOnClick(index)} >
                    <p className={`nav-txt mb-1 pl-5 full-width text-left ${this.props.leftNavIndex === index ? 'active-nav': 'white'}`}>{menu}</p>
                </li>
            )
        }
        )
    }

    render() {
        let navMenu = this.props.navMenu;

        return (
            <div className="customer-info-padding full-width scroll-width0">
                <ul
                    className={`pr-3 nav-container pt-4 pl-4 pb-3 mb-3 ${this.props.isExpand ? 'border-nav-wrapper-expand' : ''}`}
                >
                    {this.props.heading && (<h4 className="orange font-size18 text-right pb-5">{this.props.heading}</h4>)}
                    {this.props.terminationPage === true ? this.terminationPageLeftNav(navMenu) : this.commonLeftNav(navMenu)}

                </ul>
            </div>
        );
    }
}


export default LeftNavigationCommon;