import React, { Component } from "react";
import './Report.scss';
import AWSheader from '../common/AWSheader/AWSheader';
import MonthlyActivity from './MonthlyActivity';
import Leftnavigation from '../common/LeftNav/Leftnavigation';
import SalesReport from './SalesReport';
import PPRCount from './PPRCount';
import ExportPPR from './ExportPPR';
import WorkLoadSCE from './WorkLoadSCE';
import ErrorPage from '../PageNotFound/ServerError';
import * as roleNames from '../../constants/roleNames.const';
import PropTypes from "prop-types";

class Report extends Component {

    constructor(props) {
        super(props)
        this.state = {
            salesRepDataModel: ['Sales', 'Export'],
            smDataModel: ['Sales', 'Export'],
            sceDataModel: ['PPR Count & Commit', 'Workload-SCE', 'Monthly Activity Metrics', 'Export'],
            igtDataModel: ['PPR Count & Commit', 'Workload-PPO', 'Monthly Activity Metrics', 'Export'],
            publicSectorDataModel: ['PPR Count & Commit', 'Workload-Private Pricing Public Sector', 'Monthly Activity Metrics', 'Export'],
            chinaUserDataModel: ['PPR Count & Commit', 'Workload-Private Pricing China', 'Monthly Activity Metrics', 'Export'],
            stage: 0,
            leftNavIndex: 0,
            EagleWatchlist: "Dropdown",
            rateCardButtonOption: "Count",
            displayErrPage: false,
            statusCode: ''
        }
    }

    handleLefNavClick = index => {
        this.setState({ leftNavIndex: index, displayErrPage: false })
    }


    renderLeftNavigation = () => {

        const { location: { state: { userRole } } = {} } = this.props || {};

        let navMenu = this.state.dataModel;
        if (userRole === roleNames.SALES_REPRESENTATIVE)
            navMenu = this.state.salesRepDataModel;
        else if (userRole === roleNames.SALES_MANAGER)
            navMenu = this.state.smDataModel;
        else if (userRole === roleNames.SCE_OWNER)
            navMenu = this.state.sceDataModel;
        else if (userRole === roleNames.IGT_OWNER)
            navMenu = this.state.igtDataModel;
        else if (userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR)
            navMenu = this.state.publicSectorDataModel;
        else if (userRole === roleNames.PRIVATE_PRICING_CHINA)
            navMenu = this.state.chinaUserDataModel;


        let salesRepName = null;
        if (this.props.location.state && this.props.location.state.userRole) {
            salesRepName = this.props.location.state.firstName + ' ' + this.props.location.state.lastName;

            return (
                <Leftnavigation
                    heading="Reports"
                    navMenu={navMenu}
                    isForm={false}
                    stage={this.state.stage}
                    leftNavIndex={this.state.leftNavIndex}
                    salesRepName={salesRepName}
                    userRole={userRole}
                    isExpand={this.state.isExpand}
                    terminationPage={false}
                    disableSubmitButton={false}
                    showSummary={false}
                    handleOnClick={() => this.handleExpand()}
                    handleSubmit={() => { }}
                    handleLefNavClick={index => this.handleLefNavClick(index)}
                />
            )
        } else {
            this.props.history.push("/");
        }
    }

    handleWatchlistChange = (value) => {
        this.setState({
            EagleWatchlist: value
        })
    }

    /**
    * This function will call dashboard api
    */
    handleNavigatetoDashboard = (locationState) => {

        let location = {
            pathname: "/dashboard",
            state: locationState
        }
        this.props.history.push(location);
    }

    setErrorPage = (statusCode) => {
        const { displayErrPage } = this.state;
        this.setState({ displayErrPage: !displayErrPage, statusCode });
    }

    renderReportPage = () => {
        const locationState = this.props.location && this.props.location.state;
        const { userRole = '' } = locationState || {};
        const { leftNavIndex, statusCode } = this.state;
        let leftMenu;
        switch (userRole) {
            case roleNames.SALES_REPRESENTATIVE:
                if (leftNavIndex === 0) {
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <SalesReport id="salesreport" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                }
                else if (leftNavIndex === 1)
                    leftMenu = <ExportPPR locationState={locationState}/>
                break;
            case roleNames.SALES_MANAGER:
                if (leftNavIndex === 0)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <SalesReport id="salesreport" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 1)
                    leftMenu = <ExportPPR locationState={locationState} />
                break;
            case roleNames.SCE_OWNER:
                if (leftNavIndex === 0)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> :<PPRCount id="pprcount" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)}/>
                else if (leftNavIndex === 1)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <WorkLoadSCE id="workload" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 2)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <MonthlyActivity id="monthlyacitvity" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 3)
                    leftMenu = <ExportPPR id="exportppr" locationState={locationState} />
                break;
            case roleNames.IGT_OWNER:
                if (leftNavIndex === 0)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> :<PPRCount id="pprcount" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)}/>
                else if (leftNavIndex === 1)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <WorkLoadSCE id="workload" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 2)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <MonthlyActivity id="monthlyacitvity" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 3)
                    leftMenu = <ExportPPR id="exportppr" locationState={locationState} />
                break;
            case roleNames.PRIVATE_PRICING_PUBLIC_SECTOR:
                if (leftNavIndex === 0)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> :<PPRCount id="pprcount" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)}/>
                else if (leftNavIndex === 1)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <WorkLoadSCE id="workload" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 2)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <MonthlyActivity id="monthlyacitvity" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 3)
                    leftMenu = <ExportPPR id="exportppr" locationState={locationState} />
                break;
            case roleNames.PRIVATE_PRICING_CHINA:
                if (leftNavIndex === 0)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> :<PPRCount id="pprcount" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)}/>
                else if (leftNavIndex === 1)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <WorkLoadSCE id="workload" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 2)
                    leftMenu = this.state.displayErrPage ? <ErrorPage id="erropage" onRefreshClick={() => this.setErrorPage()} statusCode={statusCode} onDashboardClick={() => this.handleNavigatetoDashboard(locationState)} /> : <MonthlyActivity id="monthlyacitvity" locationState={locationState} handleDisplayErr={(statusCode) => this.setErrorPage(statusCode)} />
                else if (leftNavIndex === 3)
                    leftMenu = <ExportPPR id="exportppr" locationState={locationState} />
                break;
        }
        return leftMenu;
    }

    render() {
        const locationState = this.props.location && this.props.location.state;
        return (
            <div className="full-width core-tab">
                <div className="full-width">
                    <AWSheader
                        locationState={locationState}
                        firstName={this.props.location.state.firstName}
                        lastName={this.props.location.state.lastName}
                        handlePPRCreatedForAutoSave={(locationState) => this.handleNavigatetoDashboard(locationState)}
                    />
                </div>

                <div className="full-width main-container pt-5">
                    <div className="col-4 float-left p-0 report-leftnav">
                        {this.renderLeftNavigation()}
                    </div>
                    <div className="col-9 float-left right-container pb-3">
                        {this.renderReportPage()}
                    </div>
                </div>
            </div>

        )
    }
}
export default Report;
Report.propTypes = {
    location: PropTypes.any,
    history: PropTypes.func
};