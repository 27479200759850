import React from 'react';
import _ from 'underscore';
import "../../common/AWSAdminTableIcon/AWSAdminTableIcon.scss"
export default class AWSAdminTableEditIcon extends React.Component {

    render() {
        return (
            <div className='edit-icon icons-styles' >
            </div>
        );

    }
}

