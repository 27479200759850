import React, { Component } from 'react';
import AWSCollapsible from "../../common/AWSCollapsible/AWSCollapsible";
import PPRCollapseData from "../PPRCollapasableData/PPRCollapasableData";
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import axios from 'axios';

class PPRManagement extends Component {

    state = {
        sectionIndex: 0,
        activateToast: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataModel !== this.props.dataModel || prevProps.selectedStage !== this.props.selectedStage) {
            this.setState({ sectionIndex: 0 })
        }
    }

    /**
     * function to update section
     */
    onChangeSection = (sectionIndex, stage) => {
        this.setState({ sectionIndex: sectionIndex });
    }

    /**
     * This function will load toaster as a loader while api calls, it will have message and state of loader as params 
     * @params message, activeState
     */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        })
    }

    callApiToEditSection = (data) => {

        // let editSection = baseUrlForPageAndSectionCrud + "section";
        let editSection = "section";

        this.showUserManagementLoadingToast("Updating Section .....", true);

        axios({
            method: "PUT",
            url: editSection,
            data: data
        })
            .then(resp => {
                this.showUserManagementLoadingToast("Section Updated successfully ...", false);
            })
            .catch(error => {
                this.showUserManagementLoadingToast("Section failed To Update ...", false);
            })
    }

    render() {
        return (
            <div>
                {this.state.activateToast && (
                    <AWSLoadingToast
                        message={this.state.loadingMessage}
                        error={this.state.loadingError}
                        duration={this.state.loadingMessageDuration}
                    />
                )}
                <AWSCollapsible
                    dataModel={this.props.dataModel}
                    stage={this.props.selectedStage}
                    leftNavIndex={this.props.leftNavIndex}
                    activeCollapse={this.props.activeCollapse}
                    collapaseStateName={this.props.dataModel && this.props.dataModel[this.props.selectedStage || 0]}
                    onChangeSection={(section, stage) => this.onChangeSection(section, stage)}
                >

                    <PPRCollapseData
                        dataModel={this.props.dataModel}
                        valueChange={this.props.valueChange}
                        questionBlock={this.props.dataModel[this.props.selectedStage]}
                        selectedStage={this.props.selectedStage}
                        sectionIndex={this.state.sectionIndex}
                        handleSectionChange={(type, value) => this.props.handleSectionChange(type, value, this.state.sectionIndex)}
                        location={this.props.location}      //passing the userinfo binded in location prop
                    />
                </AWSCollapsible>
            </div>
        );
    }
}

export default PPRManagement;