import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWSinput.scss";
import _ from 'underscore';
import AWSError from '../AWSError/AWSError';
import infoHelpIcon from '../../../images/awsfalconhelp.png';
const TermLengthLabel = "Term Length (Months)";
import {Input,Popover,Icon} from  "@amzn/awsui-components-react";
import * as tagNameConstant from '../../../constants/tagNameConstant';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as roleNames from '../../../constants/roleNames.const';
import { userRoleCheckForEditableCell } from "../../../utils/common";

export default class AWSinput extends Component {

    state = {
        errorLabel: "",
        error: false,
        valueChanged: true,
        text: null
    }

    componentDidMount() {
        
        if (this.props.label === TermLengthLabel) {

            if (this.props.termLength) {
                this.props.onChangeFunc(Number(this.props.termLength.toString()));
                this.validate(this.props.validation, this.props.termLength, 'Range');
            } else {
                if (this.props.value) {
                    this.props.onChangeFunc(this.props.value);
                }
            }
        } else {
            if (this.props.value) {
                this.props.onChangeFunc(this.props.value);
            }
        }

        this.setState({
            text: this.props.value
        })
        
    }

    componentDidUpdate = (prevProps) => {
        const { questionValue, value, duplicateValidation } = this.props;
        let {valueChanged} = this.state;

        if (prevProps.value !== this.props.value) {
            this.setState({
                valueChanged: !valueChanged,
                text: this.props.value
            })
            if (this.props.value) {
                this.props.onChangeFunc(this.props.value);
            }
        }

        if ((questionValue && questionValue.tagName === tagNameConstant.payerIdDetailsTag) && this.props.value !== prevProps.value) {
            this.validate(this.props.validation, value, 'Regex')
        }


        if (this.props.value !== prevProps.value) {
            this.validate(this.props.validation, value, 'Required')
            this.validate(this.props.validation, value, 'Regex')
        }

    }

    checkRegexOrRangeAndSetErrorMessage = (validationObject, val) => {
        let regex = new RegExp(validationObject.criteria);
        if ((!_.isEmpty(val)) && (!_.isNull(val))) {
            if (!regex.test((val))) {
                this.setState({
                    error: true,
                    errorLabel: validationObject.message
                })
            } else {
                this.setState({
                    error: false
                })
            }
        }
    }

    validate(validationArray, val, validationType) {
        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            let flag = false;

            if (validationType === validationObject.type && validationType === "Regex") {
                if (this.props.label === TermLengthLabel) {
                    val = Number(val).toString();
                }
                this.checkRegexOrRangeAndSetErrorMessage(validationObject, val)
                flag = true;
                return false;
            }
            else if (validationType === validationObject.type && validationType === "Range") {
                this.checkRegexOrRangeAndSetErrorMessage(validationObject, val)
                flag = true;
                return false;

            }
            else if (validationType === validationObject.type && validationType == 'Required') {
                if ((_.isNull(val)) || (_.isEmpty(val)) || (_.isEmpty(val && val.trim()))) {
                    this.setState({
                        error: true,
                        errorLabel: validationObject.message
                    })
                } else {
                    if (this.state.error === true) {
                        this.setState({
                            error: false
                        })
                    }
                }
                flag = true;
                return false;

            }
            if (flag) return false;
        });
    }

    handleChange = (val, validationArray) => {
        this.setState({
            error: false,
            text: val
        })
        this.validate(validationArray, val, 'Regex');
    }

    onBlur(){
        this.props.onChangeFunc(this.state.text);
    }

    removeError = () => {
        this.setState({
            error: false
        })
    }

    editQuestionCss = (questionValue) => {
        return questionValue && questionValue.isQuestionEdit === true ? 'editable-color' : ''
    }

    /**
     * this function will verify and return boolean for disable state of field based on status, userRole and question tagnam
     */
    verifyDisablecondtionBasedOnstatus = () => {
        const { dealStatus, questionValue, userRole, locationStateUser, dealInfo } = this.props;
        const listOfQuestionBasedOnTagName = [tagNameConstant.customerLegalNameTag]
        if ((dealStatus === dealStatusConstant.Open_For_Review && userRoleCheckForEditableCell(locationStateUser, userRole, dealInfo, roleNames)) || userRole === roleNames.BILLING_TEAM) {
            if (questionValue && questionValue.tagName && listOfQuestionBasedOnTagName.includes(questionValue.tagName)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (this.verifyDisablecondtionBasedOnstatus()) {
            return false
        } else {
            if (canEditPPR === true) {
                return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
            } else if (canEditPPR === false) {
                return true // disable true
            } else {
                return false
            }
        }
    }

    render() {
        let { label, placeholder, triggeredNext, questionValue, duplicateValidation, duplicateErrFlag, canEditPPR, showHelpTooltip = true, showOptional = true, isQuesEdit, newPayerId } = this.props;
        const tagName = questionValue && questionValue.tagName;
        let value = this.state.text

        if (this.state.error === true && questionValue.isVisible === false) {
            this.removeError();
        }

        if (triggeredNext === true && (!this.state.error) && questionValue && questionValue.isVisible) {
            this.validate(this.props.validation, value, 'Required')
        }

        // if (label === TermLengthLabel) {
        //     value = this.props.termLength || value;
        // }
        return (
            <div className="AWS-input-div">
                    {questionValue !== undefined ?
                        <div className="full-width">
                            <span className={this.props.labelType === "TEXTUP" ? `${this.props.className} ${this.editQuestionCss(questionValue, isQuesEdit)}` : `${label ? 'input-label ' : ''} float-left ${this.editQuestionCss(questionValue)}`}>
                                {/* {label} */}
                                {questionValue.isMandatory === true || showOptional === false ? label : `${label} (Optional)`}
                            </span>
                            {showHelpTooltip && questionValue && questionValue.helpText && (
                                 <Popover content={questionValue && questionValue.helpText}
                                preferredPosition="above" size="small" position="top">
 
                                <div className="float-left circle-alert">
                                    {/* <img src={infoHelpIcon} alt="help" /> */}
                                    <Icon name="status-info"></Icon>
                                </div>
                            </Popover>
                            )}

                        </div>
                        : <div className={this.props.labelType === "TEXTUP" ? `${this.props.className}` : "input-label"}>
                            <div className="float-left">{label}</div>
                        </div>
                    }
                    <div className={((value && value.length === 12) && newPayerId === true) ? "full-width p-0 orangeborder-input":"full-width p-0"}>
                        <Input
                            id={`${label} + ${questionValue && questionValue.queId}`}
                            placeholder={placeholder}
                            value={value}
                            onBlur={this.onBlur.bind(this)}
                            error={value === "" ? true : false}
                            type={label === TermLengthLabel ? "number" : "text"}
                            onInput={(e) => this.handleChange(e.detail.value, this.props.validation)}
                            // disabled={questionValue && questionValue.quePermission ? questionValue.quePermission.read : false}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                            class={`${newPayerId === true ? "orangeborder-input": ""}`}
                        />

                        {/* <input
                            id={`${label} + ${questionValue && questionValue.queId}`}
                            placeholder={placeholder}
                            value={value}
                            error={value === "" ? true : false}
                            type={label === TermLengthLabel ? "number" : "text"}
                            onChange={(e) => this.handleChange(e.target.value, this.props.validation)}
                            // disabled={questionValue && questionValue.quePermission ? questionValue.quePermission.read : false}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                            class={`${newPayerId === true ? "awsui-input orangeborder-input": "awsui-input "}`}
                        /> */}
                        {/* <input 
                            value={value}  
                            type={label === TermLengthLabel ? "number" : "text"} 
                            onChange={(e) => this.handleChange(e.detail.value, this.props.validation)}
                        /> */}
                    </div>

                    {this.props.percentageSign === true && (<div className="percentage-sign">%</div>)}
                    {/* {this.state.error === false && this.props.termEndDate !== "" && label === TermLengthLabel && (<span className="font-size12 grey">Discount term ends on date {this.props.termEndDate}</span>)} */}
                    {tagName === tagNameConstant.termLengthTag && value && (value > 36 || value < 12) && <p className="font-size10 red">This deal might require additional approvals</p>}
                    {this.state.error && (
                        <AWSError
                            errorLabel={this.state.errorLabel}
                        />
                    )}
            </div>
        );
    }
}

AWSinput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChangeFunc: PropTypes.func
};
