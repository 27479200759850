import React from 'react';
import './watchilst.scss';
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSbutton from '../../common/AWSbutton/AWSbutton';
import PropTypes from "prop-types";
class CreateNewWatchlist extends React.Component {
    state = {
        titleName: null,
        accountlist: null
    }

    handleChangeSectionTitle = (value) => {
        this.setState({
            titleName: value
        })
    }
    handleChangeSectionaccountList = (value) => {
        this.setState({
            accountlist: value
        })
    }
    render() {
        return (
            <div className="full-width watchlist-popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        New Watchlist
                    <span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                        </span>
                    </p>
                </div>
                <div className="col-12 float-right p-3 pt-4 pb-5">
                    <div className="col-6 float-left mr-5">
                        <div className="col-11 float-left p-0">
                            <p className="amber-bold mb-1">Watchlist Name</p>
                            <div className="full-width input-wraper">
                                <AWSInput
                                    value={this.state.titleName}
                                    onChangeFunc={(value) => { this.handleChangeSectionTitle(value) }}
                                />
                            </div>

                        </div>
                        <div className="col-10 float-left pl-0 pt-4">
                            <p className="amber-bold mb-1">Add Account to list</p>
                        </div>
                        <div className="col-10 float-left pl-0 pr-3"><AWSInput
                            value={this.state.accountlist}
                            onChangeFunc={(value) => { this.handleChangeSectionaccountList(value) }}
                        /></div>

                    </div>
                    <div className="col-5 float-left">
                        <p className="amber-bold ">Account list</p>
                        <ul className="p-0 accountname-list">
                            <li className="list-wraper full-width">
                                <span className="float-left">Cutomer Legal Name</span>
                                <span className="float-right">
                                </span>
                            </li>
                            <li className="list-wraper full-width">
                                <span className="float-left">Cutomer Legal Name</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-0 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Delete Watchlist"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CreateNewWatchlist;
CreateNewWatchlist.propTypes = {

    handleCancel: PropTypes.func,

};