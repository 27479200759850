import React, { Component } from "react";
import { Modal, Icon } from "@amzn/awsui-components-react";
import { Link } from "react-router-dom";
// import { RiskMinor, MobileCancelMajorMonotone } from "@shopify/polaris-icons";
import AWSbutton from "../AWSbutton/AWSbutton";
import Col from "../AWSCol/AWSCol";
import "./AWSModal.scss";
import Row from "../AWSRow/AWSRow";
import AWSDropDown from "../AWSDropdown/AWSDropdown";
import AWSTextArea from "../AWStextarea/AWStextarea";
import AWSInput from "../AWSInput/AWSinput";
import { Table } from "@amzn/awsui-components-react";
// import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
// import { ReactTabulator } from "react-tabulator";
import AdvancedSearch from "../../Dashboard/AdvancedSearch";
import videoIcon from "../../../images/Play.png";

import closeIcon from "../../../images/close_light_grey_icon.svg";

const columns = [
  {
    header: "Monthly Commit",
    id: "commit",
    cell: (item) => <div>{item.commit}</div>,
    minWidth: "160px",
  },
  {
    header: "Global",
    id: "price",
    cell: (item) => <div>{item.price}</div>,
    minWidth: "60px",
  },
];

let validation = [
  {
    message: "This is a mandatory field",
    type: "Required",
  },
];

let questionValue = {
  isVisible: true,
  isMandatory: true,
  dealStatus: "",
};

class AWSModal extends Component {
  state = {
    comments: null,
    dealLostEnabled: false,
    openReviewEnabled: false,
  };

  componentDidMount() {
    this.setState({
      dealLostEnabled: false,
      openReviewEnabled: false,
    });
  }

  handleChangeSectionTitle = (value) => {
    this.setState({
      comments: value,
    });
  };

  handleOnSelect = (value) => {
    this.props.handleOnSelect(value);
  };

  handleInactiveDeal = (status) => {
    this.setState({
      dealStatus: status,
      dealLostEnabled: true,
    });
  };

  handleInactiveSubmit = (status, comments) => {
    this.setState(
      {
        dealLostEnabled: false,
        comments: "",
      },
      () => {
        this.props.handleOk(status, comments);
      }
    );
  };

  handleDealStatusClose = () => {
    this.setState(
      {
        dealStatus: "",
        dealLostEnabled: false,
        comments: "",
      },
      () => {
        this.props.handleCancel();
      }
    );
  };

  handleInActiveClose = () => {
    this.setState(
      {
        dealStatus: "",
        dealLostEnabled: false,
        comments: "",
      },
      () => {
        this.props.handleClose();
      }
    );
  };

  handleOpenForReview = () => {
    this.setState({
      openReviewEnabled: true,
    });
  };

  handleOpenReviewClose = () => {
    this.setState(
      {
        openReviewEnabled: false,
        comments: "",
      },
      () => {
        this.props.handleCancel();
      }
    );
  };

  handleOpenReviewSubmit = (comments) => {
    this.setState(
      {
        openReviewEnabled: false,
        comments: "",
      },
      () => {
        this.props.handleOk(comments);
      }
    );
  };

  renderBasicModal = () => {
    return (
      <div className="alert-popup">
        <div className="full-width popup-bg">
          <p className="full-width float-left mb-0 header-txt">
            <span className="float-left warning title-txt mr-3">
              <Icon name="status-warning"></Icon>
            </span>
            <span className="text-left float-left title-txt red font-size16">
              Alert
            </span>
            <span
              className="float-right cursor-pointer close-icon dark-black"
              onClick={() => this.props.handleCancel()}
            >
              <Icon name="close"></Icon>
            </span>
          </p>
          <p className="font-size14 dark-color mb-0 full-width content p-4 text-center">
            {this.props.alertMessage}
          </p>
        </div>
      </div>
    );
  };

  renderSecondaryModal = () => {
    return (
      <div className="delete-popup-wraper">
        <div className="delete-popup p-4 float-left full-width popup-bg">
          <p className="font-size14 dark-color mb-0 full-width content">
            {this.props.titleMessage}
          </p>
          <div className="btn-wraper full-width">
            <div className="mb-3 mt-4 float-right col-6 p-0">
              <div className="float-left full-width">
                <span className="float-left">
                  <AWSbutton
                    btnType="btns unfill-button"
                    label="Cancel"
                    isIcon={false}
                    onClick={() => this.props.handleCancel()}
                  />
                </span>

                <span className="float-right">
                  <AWSbutton
                    btnType="fill"
                    label="Yes"
                    isIcon={false}
                    onClick={() => this.props.handleOk()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  rederTermintionModal = () => {
    return (
      <div className="termination-popup">
        <div className="full-width popup-bg">
          <div className="content-wraper p-4 full-width">
            <h4 className="orange font-size24 amber-bold">Termination</h4>
            <p className="font-size14 white">
              Your deal termination request has been submitted.
            </p>
          </div>
          <div className="button-wraper full-width">
            <div className="pb-4 mt-4 col-6 float-right mr-3">
              <Col span={8} className="float-right">
                <Link to="/dashboard">
                  <AWSbutton btnType="fill" label="Dashboard" isIcon={false} />
                </Link>
              </Col>
              <Col span={9} className="float-right">
                <AWSbutton
                  btnType="btns unfill-button"
                  label="Cancel"
                  isIcon={false}
                  onClick={() => this.props.handleCancel()}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  };

  rederAssignDealsModal = () => {
    return (
      <div className="delete-popup-wraper dasboard-dealpopup">
        <div className="delete-popup p-0 float-left full-width popup-bg p-4">
          <p className="font-size14 dark-color mb-0 width-auto float-left amber-bold">
            Assign Deal
          </p>
          <span
            className="float-right cursor-pointer close-icon dark-black"
            onClick={() => this.props.handleCancel()}
          >
            <Icon name="close"></Icon>
          </span>
          <div className="btn-wraper full-width mt-4 pt-2">
            <div className=" full-width p-0">
              <div className="full-width">
                <AWSDropDown
                  label={"Assigned To"}
                  placeholder="Assigned To"
                  options={this.props.AssignDealUsers}
                  value={this.props.data}
                  questionValue={questionValue}
                  handleOnSelect={(value) => {
                    this.handleOnSelect(value);
                  }}
                />
              </div>
            </div>
            <div className="mb-0 mt-4 float-right col-6 p-0 pt-2">
              <div className="float-left full-width">
                <span className="float-left">
                  <AWSbutton
                    btnType="btns unfill-button"
                    label="Cancel"
                    isIcon={false}
                    onClick={() => this.props.handleCancel()}
                  />
                </span>

                <span className="float-right">
                  <AWSbutton
                    btnType="fill"
                    label="Assign"
                    isIcon={false}
                    onClick={() => this.props.handleOk()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  rederGlobalLimitsModal = () => {
    let { data } = this.props;
    return (
      <div className="global-limit-wraper p-2 scroll-width5">
        <div className="modal-container global-limit-popup full-width popup-bg p-4 scroll-width5 moz-scroll">
          <div className="modal-wraper">
            <p className="font-size16 dark-black amber-bold col-8 float-left p-0">
              The below rates are available for the global limits
            </p>
            <span
              className="float-right cursor-pointer close-icon dark-black float-left"
              onClick={() => this.props.handleCancel()}
            >
              <Icon name="close"></Icon>
            </span>
            <Row>
              <Col span={12} className="pr-2">
                <p className="font-size16 dark-black">
                  10% LIMIT- 35KB+AOS - <br /> Minimum 12 Month Commit*
                </p>
                {data.limit10Term12 && (
                  <Table
                    loadingText="Loading records"
                    items={data.limit10Term12}
                    columnDefinitions={columns}
                  />
                )}
                <p className="font-size14 grey mt-2">
                  {"*Request fees waived for request >=35kb"}
                </p>
              </Col>
              <Col span={12} className="pl-2">
                <p className="font-size16 dark-black">
                  10% LIMIT- 35KB+AOS - <br /> Minimum 24 Month Commit*
                </p>
                {data.limit10Term24 && (
                  <Table
                    loadingText="Loading records"
                    items={data.limit10Term24}
                    columnDefinitions={columns}
                  />
                )}
                <p className="font-size14 grey mt-2">
                  {"*Request fees waived for request >=35kb"}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={12} className="pr-2">
                <p className="font-size16 dark-black">
                  20% LIMIT- 35KB+AOS - <br /> Minimum 12 Month Commit*
                </p>
                {data.limit20Term12 && (
                  <Table
                    loadingText="Loading records"
                    items={data.limit20Term12}
                    columnDefinitions={columns}
                  />
                )}
                <p className="font-size14 grey mt-2">
                  {"*Request fees waived for request >=35kb"}
                </p>
              </Col>
              <Col span={12} className="pl-2">
                <p className="font-size16 dark-black">
                  20% LIMIT- 35KB+AOS - <br />
                  Minimum 24 Month Commit*
                </p>
                {data.limit20Term24 && (
                  <Table
                    loadingText="Loading records"
                    items={data.limit20Term24}
                    columnDefinitions={columns}
                  />
                )}
                <p className="font-size14 grey mt-2">
                  {"*Request fees waived for request >=35kb"}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  renderDeleteModal = () => {
    return (
      <div className="clone-popup">
        <div className="modal-container full-width popup-bg bg-grey p-4">
          <div className="modal-wraper">
            <p className="font-size14">{this.props.titleMessage}</p>
            <div className="mb-0 mt-3 float-right col-9 p-0">
              <div className="float-left full-width">
                <span className="float-left">
                  <AWSbutton
                    btnType="btns unfill-button"
                    label="No"
                    isIcon={false}
                    onClick={() => this.props.handleCancel()}
                  />
                </span>

                <span className="float-right">
                  <AWSbutton
                    btnType="fill"
                    label="Yes"
                    isIcon={false}
                    onClick={() => this.props.handleOk()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showClonePPR_Player = () => {
    window.open("https://broadcast.amazon.com/videos/192231", "_blank");
  };

  /*clone popup */
  renderCloneModal = () => {
    return (
      <div className="clone-popup">
        <div className="modal-container full-width popup-bg bg-grey p-4">
          <div className="modal-wraper">
            <span
              className="float-right cursor-pointer close-icon dark-black float-left"
              onClick={() => this.props.handleClose()}
            >
              <Icon name="close"></Icon>
            </span>
            <div className="pt-2 float-left">
              <p className="font-size14 mb-2">
                Please confirm if the version is for the same customer?
                <span className="youtube-wraper">
                  <span
                    className="popup-video-icon youtube-icon"
                    onClick={() => {
                      this.showClonePPR_Player();
                    }}
                  >
                    <Icon name="caret-right-filled"></Icon>
                    {/* <img src={videoIcon} alt="home" className="pl-2 video-icon cursor-pointer"
                                            onClick={() => { this.showClonePPR_Player() }} /> */}
                  </span>
                  <span className="popup-tooltip-style">Versioning Action</span>
                </span>
              </p>
              <div className="mb-0 mt-3 float-right col-9 p-0">
                <div className="float-left full-width">
                  <span className="float-left">
                    <AWSbutton
                      btnType="btns unfill-button"
                      label="No"
                      isIcon={false}
                      onClick={() => this.props.handleCancel()}
                    />
                  </span>

                  <span className="float-right">
                    <AWSbutton
                      btnType="fill"
                      label="Yes"
                      isIcon={false}
                      onClick={() => this.props.handleOk()}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showDeletePPR_Player = () => {
    window.open("https://broadcast.amazon.com/videos/192233", "_blank");
  };

  /*delete PPR popup */
  renderDeletePPRModal = () => {
    return (
      <div className="clone-popup delete-ppr-popup">
        <div className="modal-container full-width popup-bg bg-grey p-4">
          <div className="modal-wraper">
            <span
              className="float-right cursor-pointer close-icon dark-black float-left"
              onClick={() => this.props.handleClose()}
            >
              <Icon name="close"></Icon>
            </span>
            <div className="mt-4 float-left">
              <p className="font-size14 mb-3">
                Are you sure you want to delete this PPR?
                <span className="youtube-wraper">
                  <span
                    className="popup-video-icon youtube-icon"
                    onClick={() => {
                      this.showDeletePPR_Player();
                    }}
                  >
                    <Icon name="caret-right-filled"></Icon>
                    {/* <img src={videoIcon} alt="home" className="pl-2 video-icon cursor-pointer"
                                            onClick={() => { this.showDeletePPR_Player() }} /> */}
                  </span>
                  <span className="popup-tooltip-style delete-ppr">
                    Delete PPR Action{" "}
                  </span>
                </span>
              </p>
              <div className="mb-0 mt-3 float-right col-8 p-0">
                <div className="float-left full-width">
                  <span className="float-left">
                    <AWSbutton
                      btnType="btns unfill-button"
                      label="No"
                      isIcon={false}
                      onClick={() => this.props.handleCancel()}
                    />
                  </span>

                  <span className="float-right">
                    <AWSbutton
                      btnType="fill"
                      label="Yes"
                      isIcon={false}
                      onClick={() => this.props.handleOk()}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderConfirmationPopUp = () => {
    const nbsp = <span>&nbsp;</span>;
    const textArray = this.props.text ? this.props.text.split("\n") : [];
    return (
      <div className="clone-popup">
        <div className="modal-container full-width popup-bg bg-grey">
          <div className="modal-wraper">
            <span
              className="float-right cursor-pointer close-icon dark-black float-left pt-3 pr-3"
              onClick={() => this.props.handleCancel()}
            >
              <Icon name="close"></Icon>
            </span>
            {this.props.header ? (
              <div className="p-4 float-left">
                <h2>{this.props.header}</h2>
              </div>
            ) : null}
            <div className="p-4 float-left">
              <p className="font-size14" date-testid="modalMsg">
                {textArray.map((text) => {
                  return <div>{text.length > 0 ? text : nbsp}</div>;
                })}
              </p>
              <div className="mb-0 mt-3 float-right col-9 p-0">
                <div className="float-left full-width">
                  {!this.props.hideCancel && (
                    <span className="float-left">
                      <AWSbutton
                        btnType="btns unfill-button"
                        label={
                          this.props.cancelLabel ? this.props.cancelLabel : "No"
                        }
                        isIcon={false}
                        onClick={() => this.props.handleCancel()}
                        data-testid="cancelBtn"
                      />
                    </span>
                  )}

                  <span className="float-right">
                    <AWSbutton
                      btnType="fill"
                      label={
                        this.props.confirmLabel
                          ? this.props.confirmLabel
                          : "Yes"
                      }
                      isIcon={false}
                      onClick={() => this.props.handleOk()}
                      data-testid="confirmBtn"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showDealActiveLostPlayer = () => {
    window.open("https://broadcast.amazon.com/videos/192233", "_blank");
  };

  /*inActive popup */
  renderInActiveModal = () => {
    return (
      <div className="clone-popup">
        {this.state.dealLostEnabled === false ? (
          <div className="modal-container full-width popup-bg bg-grey p-4">
            <div className="modal-wraper">
              <span
                className="float-right cursor-pointer close-icon dark-black float-left pt-0"
                onClick={() => this.handleInActiveClose()}
              >
                <Icon name="close"></Icon>
              </span>
              <div className="pt-2 float-left">
                <p className="font-size14 mb-4">
                  Please select whether the deal is ‘Inactive’ or ‘Lost’:
                  <span className="youtube-wraper">
                    <span
                      className="popup-video-icon youtube-icon"
                      onClick={() => {
                        this.showDealActiveLostPlayer();
                      }}
                    >
                      <Icon name="caret-right-filled"></Icon>
                      {/* <img src={videoIcon} alt="home" className="pl-2 video-icon cursor-pointer"
                                                onClick={() => { this.showDealActiveLostPlayer() }} /> */}
                    </span>
                    <span className="popup-tooltip-style">
                      Inactive/Deal Lost Action{" "}
                    </span>
                  </span>
                </p>

                <AWSTextArea
                  placeholder="Enter Justification"
                  value={this.state.comments || ""}
                  onChangeFunc={(value) => {
                    this.handleChangeSectionTitle(value);
                  }}
                />
                <div className="mb-0 mt-4 float-right col-9 p-0">
                  <div className="float-left full-width">
                    <span className="float-left">
                      <AWSbutton
                        btnType="fill"
                        label="Inactive"
                        isIcon={false}
                        disabled={
                          this.state.comments === null ||
                          this.state.comments === ""
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.handleInactiveDeal(
                            "inactive",
                            this.state.comments
                          )
                        }
                      />
                    </span>

                    <span className="float-right">
                      <AWSbutton
                        btnType="fill"
                        label="Deal Lost"
                        isIcon={false}
                        disabled={
                          this.state.comments === null ||
                          this.state.comments === ""
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.handleInactiveDeal("lost", this.state.comments)
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-container full-width popup-bg bg-grey">
            <div className="modal-wraper">
              <span
                className="float-right cursor-pointer close-icon dark-black float-left pt-3 pr-3"
                onClick={() => this.handleDealStatusClose()}
              >
                {/* <Icon source={MobileCancelMajorMonotone} /> */}
              </span>
              <div className="p-4 float-left">
                <p className="font-size14">
                  Are you sure you want to change the deal Status?
                </p>
                <div className="mb-0 mt-3 float-right col-9 p-0">
                  <div className="float-left full-width">
                    <span className="float-left">
                      <AWSbutton
                        btnType="btns unfill-button"
                        label="No"
                        isIcon={false}
                        onClick={() => this.handleDealStatusClose()}
                      />
                    </span>

                    <span className="float-right">
                      <AWSbutton
                        btnType="fill"
                        label="Yes"
                        isIcon={false}
                        onClick={() =>
                          this.handleInactiveSubmit(
                            this.state.dealStatus,
                            this.state.comments
                          )
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderAdvancedSearch = () => {
    return (
      <div className="advanced-search">
        <AdvancedSearch
          userRole={this.props.userRole}
          handleOk={(tableData) => this.props.handleOk(tableData)}
          handleCancel={() => this.props.handleCancel()}
        />
      </div>
    );
  };

  /*Open for Review popup */
  renderOpenReviewModal = () => {
    return (
      <div className="clone-popup">
        {this.state.openReviewEnabled === false ? (
          <div className="modal-container full-width popup-bg bg-grey p-4">
            <div className="modal-wraper">
              <span
                className="float-right cursor-pointer close-icon dark-black float-left pt-0"
                onClick={() => this.handleOpenReviewClose()}
              >
                <Icon name="close"></Icon>
              </span>
              <div className="pt-2 float-left">
                <p className="font-size14 mb-4">
                  Please enter comments for opening the deal for review :
                </p>

                <AWSTextArea
                  placeholder="Enter Justification"
                  value={this.state.comments || ""}
                  onChangeFunc={(value) => {
                    this.handleChangeSectionTitle(value);
                  }}
                />
                <div className="mb-0 mt-4 float-right col-9 p-0">
                  <div className="float-left full-width">
                    <span className="float-right">
                      <AWSbutton
                        btnType="fill"
                        label="Open For Review"
                        isIcon={false}
                        disabled={
                          this.state.comments === null ||
                          this.state.comments === ""
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.handleOpenForReview(this.state.comments)
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-container full-width popup-bg bg-grey">
            <div className="modal-wraper">
              <span
                className="float-right cursor-pointer close-icon dark-black float-left pt-3 pr-3"
                onClick={() => this.handleOpenReviewClose()}
              ></span>
              <div className="p-4 float-left">
                <p className="font-size14">
                  Are you sure you want to open the deal for review?
                </p>
                <div className="mb-0 mt-3 float-right col-9 p-0">
                  <div className="float-left full-width">
                    <span className="float-left">
                      <AWSbutton
                        btnType="btns unfill-button"
                        label="No"
                        isIcon={false}
                        onClick={() => this.handleOpenReviewClose()}
                      />
                    </span>

                    <span className="float-right">
                      <AWSbutton
                        btnType="fill"
                        label="Yes"
                        isIcon={false}
                        onClick={() =>
                          this.handleOpenReviewSubmit(this.state.comments)
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderPrivatePricingEditModal = () => {
    return (
      <div className="delete-popup-wraper">
        <div className="delete-popup p-4 float-left full-width popup-bg">
          <p className="font-size14 dark-color mb-0 full-width content">
            {this.props.titleMessage}
          </p>
          <div className="btn-wraper full-width">
            <div className="mb-3 mt-4 float-right col-7 p-0">
              <div className="float-left full-width">
                <span className="float-left">
                  <AWSbutton
                    btnType="btns unfill-button"
                    label="Revert Changes"
                    isIcon={false}
                    onClick={() => this.props.handleCancel()}
                  />
                </span>

                <span className="float-right">
                  <AWSbutton
                    btnType="fill"
                    label="Yes"
                    isIcon={false}
                    onClick={() => this.props.handleOk()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUnLinkedIdType = () => {
    return (
      <div className="delete-popup-wraper">
        <div className="delete-popup p-4 float-left full-width popup-bg">
          <p className="font-size14 dark-color mb-0 full-width content">
            {this.props.titleMessage}
          </p>
          <div className="btn-wraper full-width">
            <div className="mb-3 mt-4 float-right col-6 p-0">
              <div className="float-left full-width">
                <span className="float-right">
                  <AWSbutton
                    btnType="fill"
                    label="Ok"
                    isIcon={false}
                    onClick={() => this.props.handleCancel()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // renderPlayer = () => {

  //     return (
  //         <div className="full-width player-popup">
  //             {/* <div className="full-width">
  //                 <span
  //                     className="float-right cursor-pointer close-icon dark-black float-left"
  //                     onClick={() => this.props.handleCancel()}
  //                 >
  //                     <Icon name="close"></Icon>
  //                 </span>
  //             </div> */}
  //             {/* <ReactPlayer url="https://vimeo.com/291715535" width="90vh"/> */}
  //             <ReactPlayer url='https://broadcast.amazon.com/videos/192216' />
  //             <div>

  //                 {/* <iframe src="https://broadcast.amazon.com/embed/192216" width="682" height="384"></iframe> */}
  //             </div>

  //             {/* <iframe width="600" height="400" src="https://broadcast.amazon.com/videos/192216" frameborder="0" ></iframe> */}

  //         </div>
  //     )
  // }

  renderModal = () => {
    switch (this.props.type) {
      case "basic":
        return this.renderBasicModal();

      case "Secondary":
        return this.renderSecondaryModal();

      case "Termination":
        return this.rederTermintionModal();

      case "globalLimits":
        return this.rederGlobalLimitsModal();

      case "assignDeals":
        return this.rederAssignDealsModal();

      case "clonePopup":
        return this.renderCloneModal();

      case "inActivePopup":
        return this.renderInActiveModal();

      case "deletePopup":
        return this.renderDeleteModal();

      case "deletePPRPopup":
        return this.renderDeletePPRModal();

      case "renderConfirmationPopUp":
        return this.renderConfirmationPopUp();

      case "advancedSearch":
        return this.renderAdvancedSearch();

      case "unLinkedIdType":
        return this.renderUnLinkedIdType();

      case "openReviewPopup":
        return this.renderOpenReviewModal();

      case "privatePricingEdit":
        return this.renderPrivatePricingEditModal();

      default:
        return;
    }
  };

  render() {
    return (
      <div className="modal-wraper">
        <Modal
          visible={this.props.visible}
          onDismiss={() => this.props.handleCancel()}
          size={this.props.size}
        >
          {this.renderModal()}
        </Modal>

        {/* <Modal open={this.props.visible}>{this.renderModal()}</Modal> */}
      </div>
    );
  }
}

export default AWSModal;
