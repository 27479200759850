import React from "react";
import PropTypes from "prop-types";
import "../../Dashboard/AWSDashboard.scss";
import * as roleNames from "../../../constants/roleNames.const";
export default class AWSTableCloneIcon extends React.Component {
    showCloneIcon = (dealStatus) => {
        const cloneIconStatus = [
            "PPR Created",
            "Inactive",
            "Deal Signed",
            "Deal Lost",
        ];
        return cloneIconStatus.includes(dealStatus);
    };

    render() {
        let { dealStatus,dealType, userRole, createdById, userAlias } = this.props;
        let displayCloneIcon = false;
        if (dealStatus) {
            displayCloneIcon = this.showCloneIcon(dealStatus);
        }

        if (dealStatus && dealType !== "Termination" && displayCloneIcon && userRole === roleNames.SALES_REPRESENTATIVE && createdById === userAlias){
            return (
                <div className="text-center">
                    {/* <Tooltip content="Version"> */}
                        <span className="mr-0 clone-icon icons-styles cursor-pointer"></span>
                    {/* </Tooltip> */}
                </div>
            );
        } else {
            return (
                <div className="text-center">
                        <span className="mr-0 clone-grey-icon icons-styles pointer-events-none"></span>
                </div>
            );
        }
    }
}

AWSTableCloneIcon.propTypes = {
    label: PropTypes.string,
    onChangeFunc: PropTypes.func,
};
