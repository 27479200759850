export const RateCardLabels = {
    RateCard1: 'Rate Card 1',
    RateCard2: 'Rate Card 2',
    CfsaSmbRateCard: 'CF + SA SMB Rate Card',
    CfsaEnterpriseRateCard: 'CF + SA Enterprise Rate Card'
}

export const RateCardKeys = {
    RateCard1: 'ratecard1',
    RateCard2: 'ratecard2',
    CfsaSmbRateCard: 'cfsa_smb',
    CfsaEnterpriseRateCard: 'cfsa_enterprise'
}
