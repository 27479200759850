import React, { Component, PureComponent } from "react";
import './PageNotFound.scss';
import clousdIllustrate from '../../images/cloud_illustration.svg';
export default class DashboardServerError extends PureComponent {
    render(){
        return(
            <div className="col-12 text-center page-notfound pt-5">
                <h1 className="amber-bold pb-4 orange font-size30 pt-5">500</h1>
                <p className="font-size16 pb-5 dark-black pt-3">Something went wrong</p>
                <img src={clousdIllustrate} alt="cloud"/>
                {/* <p className="font-size18">The page which you are looking for is not available</p> */}
                <p className="link-txt cursor-pointer font-size16 pt-5" onClick={()=>this.props.onRefreshClick()}>Please Click To Refresh </p>
            </div>
        )
    }
}
