import React, { Component } from 'react';
import { Checkbox } from  "@amzn/awsui-components-react";
import PropTypes from "prop-types";

export default class AWScheckbox extends Component {
    onChangeFunc = (value) => {
        this.props.onChangeFunc(value);
    }

    checkForDisable = (canEditPPR, disabled) => {
        if (canEditPPR === undefined && !disabled) {
            return false;
        }
        return canEditPPR === false || disabled;
    }

    render() {
        const { label, onChangeFunc, checked, canEditPPR, disabled = false } = this.props;
        return (
            <div className="checkbox-div">
                <Checkbox 
                    id={label}
                    label={label}
                    checked={checked}
                    disabled={this.checkForDisable(canEditPPR, disabled)}
                    onChange={(e) => onChangeFunc(e.detail.checked)}
                ></Checkbox>
            </div>
        );
    }
}
AWScheckbox.propTypes = {
    canEditPPR: PropTypes.bool,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChangeFunc: PropTypes.func,
    disabled: PropTypes.bool
};


