import React from "react";
import AWSAdminTable from "../AWSAdminTable/AWSAdminTable";
import axios from 'axios';
import './AWSMngNotification.scss';
import AWSConfig from '../../../config/AWSconfig';
import AWSModal from '../AWSModal/AWSModal';
import PropTypes from "prop-types";
import AWSAdminTableEditIcon from "../../common/AWSAdminTableIcon/AWSAdminTableEditIcon";
import * as NotificationConstants from "../AWSAdminTable/AdminTableConstants/NotificationConstants";

const config = new AWSConfig();

class AWSEmailNotification extends React.Component {

  state = {
    tableData: [],
    visible: false,
    modalType: "",
    deleteObj: "",
    roleOptions: [],
    emailData: {},
    userData:
    {
      "subject": "",
      "userRole": "",
      "dealStatus": "",
      "body": "",
      "type": ""
    },
    userColumns: [
      { header: "Subject", id: "subject", cell: item => <div>{item.subject}</div>, headerFilter: "input", headerFilterPlaceholder: " ", tooltip: true },
      { header: "User Role", id: "userRole", cell: item => <div>{item.userRole}</div>, headerFilter: "input", headerFilterPlaceholder: " " },
      { header: "Deal Status", id: "dealStatus", cell: item => <div>{item.dealStatus}</div>, headerFilter: "input", headerFilterPlaceholder: " " },
      { header: "Body", id: "body", headerFilter: "input", cell: item => <div>{item.body}</div>, tooltip: true },
      { header: "Type", id: "type", width: 100, align: "center", headerFilter: "input", cell: item => <div>{item.type}</div>, },
      {
        header: "",
        id: "action",
        minWidth: '80px',
        cell: item => <div className="float-left col-12">
          <div className="col-6 float-left" onClick={(e) => this.onEdit(e, item)}>
            <AWSAdminTableEditIcon />
          </div>
        </div>,
      }
    ]
  };

  /**
  * This lifecycle method is calling API to get user management details
  */
  componentDidMount() {
    this.showUserManagementLoadingToast("Loading Email Notification...", true);
    let UsersApi = `${config.userManagementbaseUrl}users/ses`;
    axios({
      method: "GET",
      url: UsersApi
    })
      .then(resp => {
        this.setState({
          tableData: resp?.data?.Data
        });
        this.showUserManagementLoadingToast("Loading Email Notification", false);
      }).catch(err => console.log(err));
  }

  /**
   *  This function is called to show toast message based on the selection
   * @param {*} message which is a message to display 
   * @param {*} toast which is a boolean to show hide the message
  */
  showUserManagementLoadingToast = (message, toast) => {
    this.props.showUserManagementLoadingToast(message, toast);
  }


  /**
  * This function is used to set the local state for each varaible based on selection
  * @param {*} value value of the particular varaible to set 
  * @param {*} name name of the particular varaible to set 
  */
  handleOnSelect = (name, value) => {
    this.setState(prevState => ({
      userData: {
        ...prevState.userData,
        [name]: value
      }
    }));
  }

  /**
* This function is used to set the local state for each varaible
* @param {*} modalType type of the modal to load  
* @param {*} data value of all the role data 
*/
  showModal = (modalType) => {
    this.setState({
      visible: true,
      modalType: modalType,
    })
  };

  /**
 * This function is used to handle the button selection in the modal
 */
  handleOkOrCancelOfModal = () => {
    this.setState({
      visible: false
    });
  };

  onEdit = (e, cell) => {
    // const { _cell: { row: { data } } } = cell;
    console.log('data', cell);
    this.setState({ emailData: cell });
    this.showModal("editNotification");
  }

  render() {
    const { emailData, userColumns, visible } = this.state;

    return (
      <div className="user-management full-width">
        <div className="full-width user-managenent-table user-infor-table notification-management">
          <AWSAdminTable
            sortableColumnOptions={NotificationConstants.email}
            columns={userColumns}
            tableData={this.state.tableData}
            showFilter={true}
          />
        </div>
        <div className="full-width">
          {visible &&
            <AWSModal
              type={this.state.modalType}
              visible={this.state.visible}
              emailData={emailData}
              handleOnSelect={(name, value) => { this.handleOnSelect(name, value) }}
              handleOk={(type) => { this.handleOkOrCancelOfModal(type) }}
              handleCancel={() => { this.handleOkOrCancelOfModal() }}
            />
          }
        </div>
      </div>
    );
  }
}

export default AWSEmailNotification;

AWSEmailNotification.propTypes = {
  showUserManagementLoadingToast: PropTypes.func,
  handleOnSelect: PropTypes.func,
  onChangeFunc: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func
};