import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autosuggest, Popover, Icon } from '@amzn/awsui-components-react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import './AWSAccountNameAC.scss';
import AWSError from '../AWSError/AWSError';
import infoHelpIcon from '../../../images/awsfalconhelp.png';
import { requestPrediction } from '../../../actions/actions';
import * as tagNameConstant from '../../../constants/tagNameConstant';


const AWSAccountNameAC = ({
  questionValue,
  value,
  triggeredNext,
  duplicateValidation,
  shouldShowLabel = true,
  questionBlockIndex,
  termLength,
  onChangeFunc,
  onSelectOption,
  type = '',
  validation,
  duplicateErrFlag,
  showHelpTooltip,
  canEditPPR,
  payerIdAccountValidation,
  newPayerId
}) => {

  const TermLengthLabel = "Term Length (Months)";

  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");
  const [valid, setValidation] = useState(false);
  const [status, setStatus] = useState('loading');

  const handleSelectOption = event => {
    const { detail: { value } } = event;
    onSelectOption(value, questionValue, questionBlockIndex);
  };

  const dataList = useSelector(state => {
    return state.predictions.suggests;
  }) || [];

  const datalist = dataList && JSON.parse(JSON.stringify(dataList));
  const options = datalist && datalist.map(item => {
    return { value: item[type], label: item[type] };
  });

  useEffect(() => {
    onChangeFunc(value, questionValue, questionBlockIndex);
    if (questionValue && questionValue.tagName === tagNameConstant.payerIdDetailsTag)
       validate(validation, value, 'Regex')
  }, [value]);

  const checkRegexOrRangeAndSetErrorMessage = (validationObject, val) => {
    let regex = new RegExp(validationObject.criteria);
    if ((!_.isEmpty(val)) && (!_.isNull(val))) {
      if (!regex.test((val))) {
        setError(true);
        setErrorLabel(validationObject.message)
      } else {
        setError(false);
      }
    }
  }

  const validate = (validationArray, val, validationType) => {

    _.each(validationArray, (validationObject, validationIndex) => {
      //test value against validation rule
      let flag = false;

      if (validationType === validationObject.type && validationType === "Regex") {
        if (questionValue.queLabel === TermLengthLabel) {
          val = Number(val).toString();
        }
        checkRegexOrRangeAndSetErrorMessage(validationObject, val)
        flag = true;
        return false;
      }
      else if (validationType === validationObject.type && validationType === "Range") {
        checkRegexOrRangeAndSetErrorMessage(validationObject, val)
        flag = true;
        return false;

      }
      else if (validationType === validationObject.type && validationType == 'Required') {

        if ((_.isNull(val)) || (_.isEmpty(val)) || (_.isEmpty(val && val.trim()))) {
          setError(true);
          setErrorLabel(validationObject.message);
        } else {
          if (error === true)
            setError(false);
        }
        flag = true;
        return false;

      }
      else if (validationType === validationObject.type && validationType === "Duplicate") {
        setError(true);
        setErrorLabel(validationObject.message);
        flag = true;
        return false;
      }
      if (flag) return false;
    });
  }

  const loading = useSelector(state => {
    return state.predictions.loading;
  });

  const loadFirstPage = event => {
    const { detail: { value } } = event;
    setError(false);
    validate(validation, value, "Regex")
    dispatch(requestPrediction({ value, type }));
    onChangeFunc(value, questionValue, questionBlockIndex);
  };

  const checkForDisable = (questionValue, canEditPPR) => {
    if (canEditPPR === true) {
      return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
    } else if (canEditPPR === false) {
      return true // disable true
    } else {
      return false
    }
  }



  if (triggeredNext === true && !error && questionValue && questionValue.isVisible) {
    duplicateValidation === false ? validate(validation, value, 'Required') : validate(validation, value, 'Duplicate')
  }

  if (error === true && duplicateErrFlag === true) {
    setError(false);
  }


  return (
    <div className="autosuggest-container">
      {shouldShowLabel ?
        <span className='input-label float-left mb-2 label-txt'>
          {questionValue.isMandatory ? questionValue.queLabel : `${questionValue.queLabel} (Optional)`}
        </span> : null
      }
      {showHelpTooltip && questionValue && questionValue.helpText && (
        <Popover content={questionValue && questionValue.helpText}
          size="small" position="top">
          <div className="float-left circle-alert">
            {/* {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
          </div>
        </Popover>
      )}
      <Autosuggest
        value={value}
        disabled={checkForDisable(questionValue, canEditPPR)}
        placeholder={type === "aws_account_id" ? "" : type === "Customer Legal Name" ? "Enter a Customer Legal Name" : "Enter an Account Name"}
        // label="Account Name"
        loadingText="Loading Account Name"
        errorText="Error fetching results."
        recoveryText="Retry"
        finishedText={
          value ? `End of "${value}" results` : 'End of all results'
        }
        empty={type === "sfdc_account_name" ? "No Account Names found" : type === "Customer Legal Name" ? "No Customer Legal Name found" : 'No AWS Payer Id found'}
        stickyFooter={false}
        // statusType={status}
        options={options}
        filteringType="manual"
        // onInput={handleChange}
        onDelayedInput={loadFirstPage}
        questionValue={questionValue}
        loading={questionValue.tagName === tagNameConstant.payerIdDetailsTag ? false : loading}
        onChange={handleSelectOption}
      />
      {error && (<AWSError errorLabel={errorLabel} />)}
    </div>
  );

};

AWSAccountNameAC.propTypes = {
  questionValue: PropTypes.object,
  onSelect: PropTypes.func,
  onChangeFunc: PropTypes.func,
};

export default AWSAccountNameAC;
