import React, { Component } from 'react';
import "./AWSCollapsible.scss";
import { ExpandableSection } from "@amzn/awsui-components-react";

// import { AppProvider, Icon, Collapsible } from '@shopify/polaris';
// import { ChevronDownMinor } from '@shopify/polaris-icons';
// import { ChevronUpMinor } from '@shopify/polaris-icons';
// import AWSModal from '../AWSModal/AWSModal';
class AWSCollapsible extends Component {

    state = {
        active: false,
        activeIndex: 0,
    }

    componentDidUpdate(prevProps) {
        if (this.props.stage !== prevProps.stage) {
            this.setState({
                activeIndex: null
            })
        }
        if (this.props.leftNavIndex !== prevProps.leftNavIndex) {
            this.setState({
                activeIndex: null
            })
        }
    }

    handleToggle = (sectionIndex, stage) => {
        if (this.state.activeIndex === sectionIndex) {
            this.setState({
                active: !this.state.active,
                activeIndex: null
            })
        } else {
            this.setState({
                active: !this.state.active,
                activeIndex: sectionIndex
            })
        }
        this.props.onChangeSection(sectionIndex, stage)
    }

    render() {

        return (
            <div className="full-width collapase-wraper">
                {
                    this.props.collapaseStateName && this.props.collapaseStateName.queBlock && this.props.collapaseStateName.queBlock.map((questionBlock, sectionIndex) => {

                        return (
                            <div key={sectionIndex} className="full-width">
                                {/* <div
                                    onClick={() => { this.handleToggle(sectionIndex, this.props.stage) }}
                                    ariaExpanded={this.state.active}
                                    ariaControls={"basic-collapsible" + sectionIndex}
                                    className="collapse-tab amber-bold pl-3 pt-3 pb-3 mb-3"
                                    key={sectionIndex}
                                >
                                    {questionBlock.queBlkHeader === null ? "New Section" : questionBlock.queBlkHeader}
                                    <span className="down-icon float-right pr-3">
                                        {/* {this.state.activeIndex === sectionIndex ? <Icon source={ChevronUpMinor} /> : <Icon source={ChevronDownMinor} />} */}
                                {/* </span>
                                </div> */}
                                <ExpandableSection
                                    variant="container"
                                    expanded={this.state.activeIndex === sectionIndex ? true : false}
                                    id={"basic-collapsible" + sectionIndex}
                                    header={questionBlock.queBlkHeader === null ? "New Section" : questionBlock.queBlkHeader}
                                    onChange={() => { this.handleToggle(sectionIndex, this.props.stage) }}
                                >
                                    {this.props.children}
                                </ExpandableSection>
                            </div>
                        )
                    }
                    )
                }


            </div>
        );
    }
}

export default AWSCollapsible;