// Getter
export const getSessionStorage = key => JSON.parse(sessionStorage.getItem(key));

// Setter
export const setSessionStorage = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

// Remove all saved data from sessionStorage
export const clearSessionStorage = () => sessionStorage.clear();

// Remove particular key from the sessionStorage
export const removeSessionStorage = key => sessionStorage.removeItem(key);