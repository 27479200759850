import React, { Component, Fragment } from 'react';
import axios from 'axios';
import AWSConfig from '../../../config/AWSconfig';
import AWSbutton from '../AWSbutton/AWSbutton';
import './LeftNavAttachment.scss';
import downloadIcon from '../../../images/Attachments_download.svg';
import deleteIcon from '../../../images/Attachments_Delete.svg';
import { isValidFile, base64toBlob } from '../../../utils/common';
import closeIcon from '../../../images/close_light_grey_icon.svg';
import * as roleNames from '../../../constants/roleNames.const';
const config = new AWSConfig();
let baseUrl;

class LeftNavTerminationAttachment extends Component {
  state = {
    attachMentList: [],
    fileKeyID: [],
  };

  supportingDocRef = React.createRef();
  uploadRef = React.createRef();
  execRef = React.createRef();

  componentDidMount() {
    baseUrl = config.amazonFalconbaseUrl;
    this.loadAttachmentList();
  }

  showDealStructureLoadingToast = (message, toast) => {
    this.props.showDealStructureLoadingToast(message, toast);
  };

  /*load attachment list*/
  loadAttachmentList = () => {
    this.showDealStructureLoadingToast('Loading Attachment List...', true);
    let attachementListAPI = `${config.amazonFalconbaseUrl}AttachmentList/listofattch?pprId=${this.props.pprId}`;
    axios({
      method: 'GET',
      url: attachementListAPI,
    }).then(resp => {
      this.setState({
        attachMentList: resp.data,
      });
      this.showDealStructureLoadingToast('Loading Attachment List', false);
    }).catch(err => console.log(err));
  };

  handleUpload = async (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    const file = event?.target?.files[0];
    const fileName = `${event?.target?.id}_${file?.name}`;
    if (event?.target) {
      event.target.value = null;
    }
    

    if (!isValidFile(file?.name)) {
      this.showDealStructureLoadingToast(`Invalid File`, true);
      setTimeout(() => {
        this.showDealStructureLoadingToast(`Invalid File`, false);
      }, 1000)
      return;
    }

    this.showDealStructureLoadingToast(`Attaching ${file?.name} ...`, true);
    const base64 = await this.toBase64(file).catch(err => console.log(err));

    axios({
      method: 'POST',
      url: `${baseUrl}cfppr/attach`,
      data: {
        base64String: base64.split(',')[1],
        fileName: fileName,
        pprId: this.props.pprId,
      },
    })
      .then(() => {
        this.loadAttachmentList();
      })
      .catch(() => {
        this.showDealStructureLoadingToast(`Failed to attach ${file?.name}`, true);
        setTimeout(() => {
          this.showDealStructureLoadingToast(`Failed to attach`, false);
        }, 5000)
      });
  };

  toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  /*download attachment API*/
  downloadAttachemnt = (attachment, type) => {
    this.showDealStructureLoadingToast('Attachment Downloading ...', true);
    // let attachementListAPI = `${config.amazonFalconbaseUrl}AttachmentList/attatchment?keyId=${attachment.Key}`;
    let attachementListAPI = `${config.amazonFalconbaseUrl}AttachmentList/attatchment`;

    axios({
      method: 'POST',
      url: attachementListAPI,
      data:{
        keyId:attachment.Key
      }
    }).then(resp => {
      const fileExt = attachment.Key.split('.');
      const contentType = `application/${fileExt[fileExt.length - 1]}`;
      const fileNameWithsubString = resp.data && resp.data.fileName;
      let fileName = fileNameWithsubString;
      if (type === "summary") {
        fileName = fileNameWithsubString.replace('summary_', '');
      } else if (type === "release"){
        fileName = fileNameWithsubString.replace('released_', '');
      } else if (type === "exec") {
        fileName = fileNameWithsubString.replace('exec_', '');
      }
      var blob = base64toBlob(resp.data.base64String, contentType);
      var url = window.URL.createObjectURL(blob);
      // window.open(url,'_self');

      this.downloadFile(url,fileName);
    }).catch(err => this.console.log(err));
    this.showDealStructureLoadingToast('Attachment Downloading...', false);
  };

  downloadFile = function (sUrl, fileName) {

    //If in Chrome or Safari or Firefox - download via virtual link click
    //Creating new link node.
    var link = document.createElement('a');
    link.href = sUrl;

    if (link.download !== undefined) {
      //Set HTML5 download attribute. This will prevent file from opening if supported.
      // var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
      link.download = fileName;
    }

    //Dispatching click event.
    if (document.createEvent) {
      var e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
      return true;
    }
    // Force file download (whether supported by server).
    var query = '?download';

    window.open(sUrl + query);
  }

  listItem = (data, index, type) => {
    const showDelete = !!type;

    return (
      <li key-={`${type}${index}`} className="font-size12 mb-1 card p-2 list-item">
        <div className="font-size12 attachedfile-name float-left">
          <span className="file-name">{type === 'summary' ? data.attachmentName.replace('summary_', '') : data.attachmentName}</span>
        </div>
        <div className="download-replace">
          <span className="cursor-pointer" onClick={() => this.downloadAttachemnt(data,type)} target="_blank"><img src={downloadIcon} alt="download" /></span>
          {showDelete && <button type="button" className="close float-right pl-3" aria-label="Close" onClick={(e) => this.deleteFile(e, data, type)}>
            <span aria-hidden="true" className="float-right"><img src={deleteIcon} alt="delete" /></span>
          </button>}
        </div>
      </li>
    );
  };

  deleteFile = (event, data, type) => {
    event?.stopPropagation();
    event?.preventDefault();

    this.showDealStructureLoadingToast('Deleting Attachment...', true);

    axios({
      method: 'Delete',
      url: `${baseUrl}cfppr/attach`,
      data: {
        fileName: type === 'exec' ? `exec_${data.attachmentName}` : type === 'release' ? `released_${data.attachmentName}` : data.attachmentName,
        pprId: this.props.pprId,
      },
    })
      .then(() => {
        this.loadAttachmentList();
      })
      .catch(() => {
        this.showDealStructureLoadingToast(`Failed to delete`, true);
        setTimeout(() => {
          this.showDealStructureLoadingToast(`Failed to delete`, false);
        }, 5000)
      });
  };

  fileUploadButton = (id, title, ref) => {
    return (<div className="float-right">
      <input
        ref={ref}
        id={id}
        type="file"
        style={{ display: 'none' }}
        onChange={this.handleUpload}
      />
      <span className="attach-txt cursor-pointer font-size12" onClick={() => {
        ref.current.click();
      }}>Attach</span>
      {/* <AWSbutton
        btnType="btns unfill-button bg-transparent"
        label={title}
        isIcon={false}
        
      /> */}
    </div>);
  };

  closeSummary = () => {
    this.props.handleOnClick()
  }

  render() {
    const statusFlow = ['Customer Terms Aligned', 'Sales Manager Approved', 'Executable Released',
      'Deal signed', 'Deal Lost', 'Pending Legal Drafting', 'Service Team Approved'];
    const { locationStateUser, dealStatus } = this.props;
    const isSR = this.props.userRole === roleNames.SALES_REPRESENTATIVE
    const isReleasedApproved = !isSR && statusFlow.includes(dealStatus);
    const isFullyApproved = this.props.userRole === roleNames.BILLING_TEAM && statusFlow.splice(2, 5).includes(dealStatus);
    const { attachments, executable, fullyExecuted } = this.state.attachMentList || {};

    return (
      <div className="full-width attach-wraper p-4 summary-scroll scroll-width10 moz-scroll">
        <span className="close-summary light-white cursor-pointer float-right ml-4" onClick={() => { this.closeSummary() }}>Close <img src={closeIcon} alt="copy" className="ml-2" /></span>
        <div className="col-3 pt-1 float-left pl-0 files-lists">
          <div className="white pb-4 font-size12">PPR Intake Documents</div>
          <div className="col-12 float-left pl-0">
            <ul className="p-0">
              {attachments &&
                attachments.filter((item) => !item.attachmentName.startsWith('summary_')).map((attachment, index) => {
                  return this.listItem(attachment, index);
                })}
            </ul>
          </div>

        </div>

        <div className="col-3 float-left files-lists">
          <div className="col-12 float-left pl-0 pr-0">
            <div className="white pb-4 font-size12 width-auto float-left pr-3">Supporting Documents</div>
            <div className="width-auto pr-0 float-left">
              {this.fileUploadButton('summary', 'Attach File', this.supportingDocRef)}
            </div>
            <ul className="p-0">
              {attachments &&
                attachments.filter((item) => item.attachmentName.startsWith('summary_')).map((attachment, index) => {
                  return this.listItem(attachment, index, 'summary');
                })}
            </ul>
          </div>
        </div>



        <div className="col-3 float-left files-lists">
          <div className="col-12 float-left pl-0">
            <div className="white pb-4 font-size12 width-auto  float-left pr-3">Executable</div>
            <div className="width-auto pr-0 float-left">
              {isReleasedApproved && this.fileUploadButton('exec', 'Attach File', this.uploadRef)}
            </div>
            <ul className="p-0">
              {executable && executable.map((attachMentList, index) => {
                return this.listItem(attachMentList, index, 'exec');
              })}
            </ul>
          </div>

        </div>

        <div className="col-3 float-left files-lists">
          <div className="col-12 float-left pl-0 ">
            <div className="white pb-4 font-size12 width-auto  float-left pr-3">Fully Executed</div>
            <div className="width-auto pr-0 float-left">{isFullyApproved && this.fileUploadButton('released', 'Attach File', this.execRef)}</div>
            <ul className="p-0">
              {fullyExecuted && fullyExecuted.map((attachMentList, index) => {
                return this.listItem(attachMentList, index, 'release');
              })}
            </ul>
          </div>

        </div>
        {/* <div className="col-lg-6">
          
          
          
        </div> */}

        {/* {isReleasedApproved && ( */}
        {/* <div className="mr-0 mb-4 float-right full-width p-0">


        </div> */}
        {/* )} */}
        {/* {isFullyApproved &&  */}
        {/* <div className="mr-0 mb-4 float-right full-width p-0">

        </div> */}
        {/* } */}
      </div>
    );
  }
}

export default LeftNavTerminationAttachment;