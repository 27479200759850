
export const getToken = async (pathname,history,firstname,lastname,userrole,alias, secondaryRole) => {
    if (history) {
        let location = {
            pathname: pathname,
            state: {
                firstName: firstname,
                lastName: lastname,
                userRole: userrole,
                alias: alias,
                secondaryRole
            }
        }
        history.push(location)
        return location;
    }
}


