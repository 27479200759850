const map = {
    "LOCAL": "localhost",
    "ALPHA": "alpha.falcon.sales.aws.a2z.com",
    "DEV": "dev.falcon.sales.aws.a2z.com",
    "BETA": "beta.falcon.sales.aws.a2z.com",
    "GAMMA": "gamma.falcon.sales.aws.a2z.com",
    "PROD": "falcon.sales.aws.a2z.com",
    "awsCloudFront": "d1p1jm1s3amyr3.cloudfront.net",
    "devCloudFront": "d3ho5h60ixc6qh.cloudfront.net"
}

export default class AWSconfig {
    constructor() {
        let host = window.location.hostname;
    
        switch (host) {
            case map.LOCAL:
                this.amazonFalconbaseUrl = "https://apg." + map.BETA + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.BETA + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.BETA + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.BETA + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.BETA + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.BETA + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.BETA + "/auth/";
                this.ptsDownload = "https://apg." + map.BETA + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.BETA + "/auth/Report/";
                this.commentUrl = "https://apg." + map.BETA + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.BETA;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19187.js";
                break;
            case map.ALPHA:
                this.amazonFalconbaseUrl = "https://apg." + map.ALPHA + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.ALPHA + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.ALPHA + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.ALPHA + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.ALPHA + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.ALPHA + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.ALPHA + "/auth/";
                this.ptsDownload = "https://apg." + map.ALPHA + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.ALPHA + "/auth/Report/";
                this.commentUrl = "https://apg." + map.ALPHA + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.ALPHA;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19186.js";
                break;
            case map.DEV:
                this.amazonFalconbaseUrl = "https://apg." + map.DEV + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.DEV + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.DEV + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.DEV + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.DEV + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.DEV + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.DEV + "/auth/";
                this.ptsDownload = "https://apg." + map.DEV + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.DEV + "/auth/Report/";
                this.commentUrl = "https://apg." + map.DEV + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.DEV;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19187.js";
                break;
            case map.devCloudFront:
                this.amazonFalconbaseUrl = "https://apg." + map.DEV + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.DEV + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.DEV + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.DEV + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.DEV + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.DEV + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.DEV + "/auth/";
                this.ptsDownload = "https://apg." + map.DEV + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.DEV + "/auth/Report/";
                this.commentUrl = "https://apg." + map.DEV + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.DEV;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19187.js";
                break;
            case map.BETA:
                this.amazonFalconbaseUrl = "https://apg." + map.BETA + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.BETA + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.BETA + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.BETA + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.BETA + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.BETA + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.BETA + "/auth/";
                this.ptsDownload = "https://apg." + map.BETA + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.BETA + "/auth/Report/";
                this.commentUrl = "https://apg." + map.BETA + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.BETA;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19187.js";
                break;
            case map.GAMMA:
                this.amazonFalconbaseUrl = "https://apg." + map.GAMMA + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.GAMMA + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.GAMMA + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.GAMMA + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.GAMMA + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.GAMMA + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.GAMMA + "/auth/";
                this.ptsDownload = "https://apg." + map.GAMMA + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.GAMMA + "/auth/Report/";
                this.commentUrl = "https://apg." + map.GAMMA + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.GAMMA;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19188.js";
                break;
            case map.PROD:
                this.amazonFalconbaseUrl = "https://apg." + map.PROD + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.PROD + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.PROD + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.PROD + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.PROD + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.PROD + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.PROD + "/auth/";
                this.ptsDownload = "https://apg." + map.PROD + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.PROD + "/auth/Report/";
                this.commentUrl = "https://apg." + map.PROD + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.PROD;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19189.js";
                break;
            default:
                this.amazonFalconbaseUrl = "https://apg." + map.BETA + "/auth/awsfalcon/";
                this.pricingIntakeUrl = "https://apg." + map.BETA + "/auth/pricingintakeratecard/";
                this.adminrateCardbaseUrl = "https://apg." + map.BETA + "/auth/adminratecard/";
                this.userManagementbaseUrl = "https://apg." + map.BETA + "/auth/usermgt/";
                this.awsFalconSearchUrl = "https://apg." + map.BETA + "/auth/awsfalconesapi/";
                this.awsFalconSearchMgtUrl = "https://apg." + map.BETA + "/auth/awsfalconesadmin/";
                this.awsFalconAuthUrl = "https://apg." + map.BETA + "/auth/";
                this.ptsDownload = "https://apg." + map.BETA + "/auth/awsfalcon/ptsdownload";
                this.reportUrl = "https://apg." + map.BETA + "/auth/Report/";
                this.commentUrl = "https://apg." + map.BETA + "/auth/awsfalcon/cfppr/comment";
                this.ticketUrl = "https://t.corp.amazon.com/create/templates/1764d51e-7a31-404b-805b-e0c74414355a";
                this.logTicketUrl= "https://t.corp.amazon.com/create/templates/0bfd142b-0171-48cc-9204-e74fbd7028a1";
                this.baseURl = "https://" + map.BETA;
                this.isItDownUrl = "https://is-it-down.amazon.com/awsfalconui/components/19187.js";
                break;
        }
    }
}
