import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
// import { RiskMinor } from '@shopify/polaris-icons';
import './AWSButtonRadio.scss'
import _ from 'underscore';
import Col from '../AWSCol/AWSCol';

export default class AWSButtonRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errorLabel: "",
            error: false
        }
    }

    componentDidMount() {
        if (this.props.value !== null) {
            this.props.handleOnSelect(this.props.value);
        } else {
            this.props.handleOnSelect(this.props.defaultValue);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.props.handleOnSelect(this.props.value)
        }
    }

    validate(validationArray, val) {
        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            if (validationObject.type === "Required") {
                if ((_.isEmpty(val)) || (_.isNull(val))) {
                    this.setState({
                        error: true,
                        errorLabel: validationObject.message
                    })
                }
            }
        });
    }

    toggleTick(value) {
        const { quePermission, canEditPPR } = this.props;
        
        if (canEditPPR === true) {
            if (quePermission && quePermission.read === false) {
                this.setState({
                    value,
                    error: false
                });
                this.props.handleOnSelect(value);
            } else if (quePermission === undefined) {
                this.setState({
                    value,
                    error: false
                });
                this.props.handleOnSelect(value);
            }
        }
    }

    checkForDisable = () => {
        const { quePermission, canEditPPR } = this.props;
        if (canEditPPR === true) {
            return quePermission && quePermission.read ? quePermission.read : false
        }

        return !canEditPPR;
    }

    render() {
        if (this.props.triggeredNext && (!this.state.error)) {
            this.validate(this.props.validation, this.props.value)
        }
        return (
            <div className={`tabs-button-wraper ${this.props.aosWidthAdminModule === true ? 'aosButtonWidth' : ''}`}>
                <div>
                    <ul className={`p-0 ${this.checkForDisable() || this.props.isDisabledByDependency ? 'disable-state':''}`}>
                        {
                            this.props.options &&
                            this.props.options.map(({ optionKey, optionValue, optionLabel }) => {
                                return (
                                    <li className={this.props.value === optionKey ? "active" : ""}
                                        onClick={() => this.toggleTick(optionValue)} key={optionValue}>
                                        {optionLabel ?  optionLabel : optionValue}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <Col span={24}>
                    <span className="error-wraper">
                        {this.state.error && (<error className="has-error font-size10 red">
                            <span className="error-icon mr-0">
                                <Icon name="status-warning"></Icon>
                            </span>
                            {`${this.state.errorLabel} ${this.props.label}`}</error>)}
                    </span>
                </Col>
            </div>
        )
    }

}
