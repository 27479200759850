import React, { Component } from "react";
import "./LeftNavTermiantionSummary.scss";
import "./LeftNavigationSummary.scss";
import AWSTextArea from "../AWStextarea/AWStextarea";
import AWSbutton from "../AWSbutton/AWSbutton";
import LeftNavTerminationDealSummary from './LeftNavTerminationDealSummary';
let validationArray = [
  {
    cellId: null,
    message: "This is a mandatory field",
    type: "Required",
    criteria: null
  }
];

let validatingQuestion = {
  isVisible: true
};

class LeftNavTermiantionSummary extends Component {
  /**
   * this function will set value of comments
   */
  handleChange = value => {
    this.props.handleSummaryComment(value);
  };

  render() {
    let { terminationPage, terminationSummaryData, dealAction } = this.props;

    return (
      <div className="full-width termination-leftnav-summary">
        <LeftNavTerminationDealSummary
          userRole={this.props.userRole}
          ownerType={this.props.ownerType}
          disableSubmitButton={this.props.disableSubmitButton}
          summarCommentArrayObject={this.props.summarCommentArrayObject}
          salesRepName={this.props.salesRepName}
          terminationSummaryData={terminationSummaryData}
          dealAction={dealAction}
          summaryComment={this.props.summaryComment}
          triggerCommentValidation={this.props.triggerCommentValidation}
          handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
          handleSubmit={this.props.handleSubmit}
          handleOnClick={this.props.handleOnClick}
          pprId={this.props.pprId}
          showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
        />
      </div>
    );
  }
}
export default LeftNavTermiantionSummary;
