import React, { useCallback, useState, useEffect } from 'react';
// import { ChoiceList } from '@shopify/polaris';
import './AWSRadioButton.scss';
import PropTypes from "prop-types";
import { RadioGroup } from "@amzn/awsui-components-react";

export default function AWSRadioButton({ onChangeFunc, selectedPermission }) {

    const [selected, setSelected] = useState('');

    useEffect(() => {
        setSelected(selectedPermission);
    }, []);

    const handleChange = useCallback((event) => {
        console.log('detail', event.detail.value);

        setSelected(event.detail.value);
        onChangeFunc(event.detail.value)
    }, []);

    const choices = [
        {
            "value": "read",
            "label": ""
        },
        {
            "value": "write",
            "label": ""
        },
        {
            "value": "hidden",
            "label": ""
        }
    ]
    return (
        <div className="full-width radiobtn-wraper">
            <RadioGroup value={selected} items={choices} onChange={handleChange}></RadioGroup>

            {/* <ChoiceList
                choices={[
                    { value: 'read' },
                    { value: 'write' },
                    { value: 'hidden' },
                ]}
                selected={selected}
                onChange={handleChange}
            /> */}
        </div>
    )
}

AWSRadioButton.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChangeFunc: PropTypes.func
}