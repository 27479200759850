import React, { Component } from 'react';
import './AWSHtmlTable.scss'



class AWSHtmlTable extends Component {

    render() {
        let { columns, rowValues, tierType } = this.props;
        return (
            <table className="copy-table data-transfer" id='simpleTable' border='1' bordercolor='#293647'>
                <thead>
                <tr>
                    {columns.map((value, key) => {
                        return (
                            <th key={value} className={`mr-2 float-left ${tierType === 'tiered' ? "width7p3 table-border" : tierType === 'regionaltype' ? "widthreggioanl width9p3 table-border" : tierType === 'widthregional' ? "widthregionSummary width9p3 table-border" : "width9p3 table-border"}`}>
                                {value}
                            </th>
                      )
                    })}
                </tr>
                </thead>
                <tbody>
                    {rowValues && rowValues.map((row, rowIndex) => {
                        return(                       
                            <tr key={rowIndex}>                    
                                {row.cells && row.cells.map((cell, cellIndex) => {                                        
                                    return (
                                        <td className={`mr-2 mt-0 float-left 
                                            ${tierType === 'tiered' ? "width7p3 tiering-input input-style" : "width9p3 without-tiering-input input-style"}
                                            ${cell.editable === false ? "pointer-events-none" : ""}
                                            ${tierType === "summaryPage" ? "pointer-events-none" : ""}
                                            ${tierType === "widthregional" ? "pointer-events-none" :""}
                                            ${cell.isEdited && cell.isEdited === true && cell.value !== '' && tierType !== "summaryPage" ? 'orangeborder-input' : ''}`}
                                            key={cellIndex}>
                                                {cell.value.toString()}
                                        </td> 
                                    )
                                })}                        
                            </tr>
                        )  
                    })}
                </tbody>
            </table>
        );
    }
}

export default AWSHtmlTable;