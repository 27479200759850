import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { discountEffectiveDateListForMultiSelect } from '../../utils/disscountEffDateList';
import { copyToClipFirefox, copyContent } from '../../utils/copy';
import DatePicker from "react-datepicker";
import { base64toBlob, isAfterOrgChange } from '../../utils/common';
import './MonthlyActivity.scss';
import AWSbutton from '../common/AWSbutton/AWSbutton';
import AWSDropdown from '../common/AWSDropdown/AWSDropdown';
import AWSInput from '../common/AWSInput/AWSinput';
import './SalesReport.scss';
import SalesReportJson from '../../json/salesreport.json';
import dateRangeJson from '../../json/dateRange.json';
import downloadIcon from '../../images/download_grey.svg';
import AWSconfig from "../../config/AWSconfig";
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import AWSAccountNameAC from "../common/AWSAccountNameAC/AWSAccountNameAC";
import CopyBlackIcon from "../../images/Copyblack.svg";
import * as roleNames from '../../constants/roleNames.const';
import dealOpenCloseJson from '../../json/dealOpenClose.json';
import PropTypes from "prop-types";
import { Multiselect } from "@amzn/awsui-components-react";
import geoJson from '../../json/geoForReportMultiSelect.json';
import orgChangeGeoJson from '../../json/orgChangeGeoForReportingMultiselect.json';
import subGeoJson from '../../json/subGeoForReportMultiSelect.json';
import businessCustomerTypeJson from '../../json/businessCustomerTypeMultiSelect.json'

const config = new AWSconfig();
const isFirefox = typeof InstallTrigger !== 'undefined';
const salesReportUrl = `${config.reportUrl}salesReport`;
const userRoleUrl = `${config.userManagementbaseUrl}users/getuserRoles`;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
axios.defaults.headers.common["Content-Type"] = 'application/json'

class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDate: '',
            startDate: null,
            endDate: null,
            type: '',
            salesRpeRequestor: [],
            disTermDate: [],
            custLegalName: '',
            salesManager: [],
            dealStatusOptions: null,
            salesReportData: [],
            users: [],
            loadingMessage: '',
            loadingMessageDuration: '',
            activateToast: false,
            dealStatusFlag: false,
            dealTypeFlag: false,
            geoTypeFlag: false,
            channelFlag: false,
            termLength: '',
            triggeredNext: false,
            validation: [{ message: 'Please Enter Valid Character', type: 'Regex', criteria: '^[0-9]*$' }],
            questionValue: { isVisible: true, isMandatory: true, queLabel: 'Customer Legal Name', sF_Id: 'cust_leg_nam', tagName: '' },
            dealStage: '',
            maxDate: new Date(),
            displayErrPage: false,
            salesRepRequestorSelectedOptions: [],
            salesManagerSelectedOptions: [],
            discountTermSelectedOptions: [],
            geoSelectedOptions: [],
            geo: [],
            subGeo: [],
            subGeoSelectedOptions: [],
            businessCustomerTypeOptions: []
        }

        this.baseState = this.state;
    }
    copyRef = React.createRef();
    componentDidMount() {
        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};
        if (userRole === roleNames.SALES_REPRESENTATIVE) {
            this.setState({ salesRpeRequestor: [alias] });
            this.baseState.salesRpeRequestor = [alias]
        } else {
            this.setState({ salesManager: [alias] });
            this.baseState.salesManager = [alias]
        }
        this.generateSalesReport('dealType');
        this.getAllUsers();
    }

    getAllUsers = () => {
        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};
        const { users } = this.state || {};
        const reqBodyJson = { "userRole": userRole }

        axios({
            method: 'POST',
            url: userRoleUrl,
            data: reqBodyJson
        }).then(resp => {
            resp.data.Data.map((Items, index) => {
                let option = { "id": Items.AWSAlias, "label": `${Items.FirstName} ${Items.LastName} (${Items.AWSAlias})` }

                users.push(option);
                if (userRole === roleNames.SALES_REPRESENTATIVE) {
                    if (alias === Items.AWSAlias) {
                        this.setState({ salesRepRequestorSelectedOptions: [option] });
                        this.baseState.salesRepRequestorSelectedOptions = [option]
                    }


                } else {
                    if (alias === Items.AWSAlias) {
                        this.setState({ salesManagerSelectedOptions: [option] });
                        this.baseState.salesManagerSelectedOptions = [option]
                    }

                }
            })

            this.setState({ users });
        }).catch((err) => {
            if (err && err.response) {
                this.setState(prevState => {
                    return {
                        users: prevState.users
                    }
                })
            }
        });
    }

    showsalesReportToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }
    handleSelectedDateRange = value => {
        let startDate, endDate, startOfDate;
        let currentDate = moment();
        let dayStart = currentDate.clone().startOf("day").utc().format();
        startOfDate = new Date(dayStart);
        if (this.state.filterDate !== value) {
            switch (value) {
                case "Today":
                    startDate = new Date(dayStart);
                    endDate = new Date(moment(startDate).utc().add(24, 'hours').format());
                    break;
                case "Current Week":
                    var weekStart = currentDate.clone().startOf("isoWeek").utc().format();
                    startDate = new Date(weekStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Current Month":
                    var monthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(monthStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Current Quarter":
                    var quarterStart = currentDate.clone().startOf("quarter").format();
                    startDate = new Date(quarterStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Custom Dates":
                    var custommonthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(custommonthStart);
                    endDate = new Date(moment().utc().format());
                    this.setState({
                        startDate: startDate,
                        endDate: endDate,
                        filterDate: value
                    });
                    break;
                default:
            }
            if (value && value !== "Custom Dates") {
                this.setState({
                    startDate: startDate,
                    endDate: endDate,
                    filterDate: value
                })
            }
        }
    };

    handleChange = (val, id) => {
        const valType = typeof (val);
        switch (id) {
            case 'termLength':
                if (valType === "object")
                    this.setState({ termLength: '' });
                else this.setState({ termLength: val })
                break;
            case 'salesRep':
                this.setState({ salesRpeRequestor: val.selectedIds, salesRepRequestorSelectedOptions: val.selectedOptions })
                break;
            case 'salesManager':

                this.setState({ salesManager: val.selectedIds, salesManagerSelectedOptions: val.selectedOptions })

                break;
            case 'disTerm':
                this.setState({ disTermDate: val.selectedIds, discountTermSelectedOptions: val.selectedOptions })
                break;

            case 'geo':
                if (val.selectedIds.length === 1 && val.selectedIds.includes("GLBL")) {
                    this.setState({ subGeo: [], subGeoSelectedOptions: [], geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                } else {
                    this.setState({ geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                }
                break;

            case 'subGeo':
                this.setState({ subGeo: val.selectedIds, subGeoSelectedOptions: val.selectedOptions })
                break;

            case 'cust_leg_name':
                if (valType === "object")
                    this.setState({ custLegalName: '' });
                else this.setState({ custLegalName: val })
                break;
            case "start_date":
                this.setState({ startDate: val })
                break;
            case "end_date":
                this.setState({ endDate: val })
                break;
            case "business_customer_type":
                this.setState({ businessCustomerType: val.selectedIds, businessCustomerTypeOptions: val.selectedOptions })
                break;
            case "deal_stage":
                if (valType === "object")
                    this.setState({ dealStage: '' });
                else if (val === "Select")
                    this.setState({ dealStage: '' });
                else this.setState({ dealStage: val })
                break;
            default:
        }
    }

    onDropdownSelection = (val) => {
        const url = `${config.awsFalconSearchUrl}custlegalsearch`;
        const postData = {
            query: {
                match_phrase_prefix: {
                    "Customer Legal Name": val,
                },
            },
            _source: ['Customer Legal Name']
        };
        axios({
            method: 'POST',
            url: url,
            data: postData
        }).then(resp => {
            const Customer_Legal_Name = resp.data.filter(res => res[`Customer Legal Name`] === val)[0][`Customer Legal Name`];
            this.setState({ custLegalName: Customer_Legal_Name });
        }).catch(() => {
        });
    }

    generateSalesReport = (type) => {
        const { startDate = "", endDate = "", termLength, salesRpeRequestor, disTermDate, custLegalName, salesManager, dealStage, filterDate, geo, subGeo, businessCustomerType } = this.state || {};
        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};
        let disTermDte;
        if (disTermDate === undefined)
            disTermDte = '';
        else disTermDte = disTermDate;
        let _endDate = endDate;
        if (filterDate === 'Custom Dates') {
            _endDate = moment(endDate).clone().startOf("day").utc().add(24, 'hours').format();
        }
        const reqBodyJson = {
            "submittedStartDate": startDate,
            "submittedEndDate": _endDate,
            "type": type,
            "termLength": termLength,
            "geo": geo,
            "sub_geo": geo.length === 1 && geo.includes("GLBL") ? subGeo : [],
            "requestor": salesRpeRequestor,
            "custLegalName": custLegalName,
            "disTermDate": disTermDte,
            "salesManager": salesManager,
            "AWSAlias": alias,
            "userRole": userRole,
            "dealReady": dealStage,
            "businessCustomerType": businessCustomerType
        }
        this.showsalesReportToast('Please wait while Sales Report is loading...', true);
        axios({
            method: 'POST',
            url: salesReportUrl,
            data: reqBodyJson
        })
            .then(resp => {
                if (resp.data.statusCode >= 400) {
                    this.props.handleDisplayErr(resp.data.statusCode);
                }
                else {
                    if (type === 'dealType')
                        this.setState({ salesReportData: [...resp.data], dealTypeFlag: true, dealStatusFlag: false, channelFlag: false, geoTypeFlag: false, type: type });
                    else if (type === 'dealStatus')
                        this.setState({ salesReportData: [...resp.data], dealTypeFlag: false, dealStatusFlag: true, channelFlag: false, geoTypeFlag: false, type: type });
                    else if (type === 'channel')
                        this.setState({ salesReportData: [...resp.data], dealTypeFlag: false, dealStatusFlag: false, channelFlag: true, geoTypeFlag: false, type: type });
                    else if (type === 'geo')
                        this.setState({ salesReportData: [...resp.data], dealTypeFlag: false, dealStatusFlag: false, channelFlag: false, geoTypeFlag: true, type: type });
                    this.showsalesReportToast('Please wait while Sales Report is loading...', false);
                }
            }).catch((err) => {
                if (err.status) {
                    this.props.handleDisplayErr(err.status);
                }
                else if (err.response) {
                    const { response: { data: { message }, status } } = err;
                    if (status === 401)
                        this.showsalesReportToast(message, true);
                    else if (status >= 400)
                        this.props.handleDisplayErr(status);
                }
            });
    }

    resetFilter = () => {
        this.setState(this.baseState, () => {
            this.generateSalesReport('dealType')
        });
    }

    downloadCSV = () => {
        this.showsalesReportToast('Preparing the CSV FILE to be dowloaded....', true);
        const { salesReportData } = this.state;
        let downloadCSVUrl = `${config.reportUrl}exportsalesreport`;
        axios({
            method: "POST",
            url: downloadCSVUrl,
            data: salesReportData
        }).then(resp => {
            const contentType = "text/csv;charset=utf-8";
            this.showsalesReportToast('Preparing the CSV FILE to be dowloaded....', false);

            let blob = base64toBlob(resp.data, contentType);
            let url = window.URL.createObjectURL(blob);
            this.downloadCSVFile(url);
        }).catch(err => console.log(err));
    }

    downloadCSVFile = (sUrl) => {
        //If in Chrome or Safari or Firefox - download via virtual link click
        //Creating new link node.
        var link = document.createElement('a');
        link.href = sUrl;

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
            link.download = `${fileName}_SalesReport.CSV`;
        }

        //Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
        // Force file download (whether supported by server).
        var query = '?download';

        window.open(sUrl + query);
    }

    renderTable = () => {
        const { salesReportData = [...SalesReportJson] } = this.state || {};

        return (
            <>
                <div ref={this.copyRef}>
                    <div className="full-width sales-report mt-4" style={{ display: this.state.dealTypeFlag === true ? 'block' : 'none' }}>
                        <table className="p-0 m-0" id="dealTypeTable" border='1' borderColor="#cecece">
                            <thead>
                                <tr>
                                    <th>Deal Type</th>
                                    <th>PPR Count</th>
                                    <th>Minimum Committed Revenue ($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dealTypeFlag && salesReportData && salesReportData.map((salesreport, index) => {
                                    let dealtype = Object.keys(salesreport.dealType);
                                    return dealtype && dealtype.map((dealTypeVal, dealTypeValIndex) => {
                                        return (
                                            <tr key={dealTypeValIndex}>
                                                <td>{dealTypeVal}</td>
                                                <td>{salesreport.dealType[dealTypeVal].pprCount}</td>
                                                <td>{salesreport.dealType[dealTypeVal].minimumComittedRevenue}</td>
                                            </tr>
                                        )
                                    })
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="full-width sales-report mt-4" style={{ display: this.state.channelFlag === true ? 'block' : 'none' }}>
                        <table className="p-0 m-0" id="channelTable" border='1' borderColor="#cecece">
                            <thead>
                                <tr>
                                    <th>Direct/Reseller/Solution Provider</th>
                                    <th>PPR Count*</th>
                                    <th>Minimum Committed Revenue ($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.channelFlag && salesReportData && salesReportData.map((salesreport, index) => {
                                    let channel = Object.keys(salesreport.channel);
                                    return channel && channel.map((channelVal, channelIndex) => {
                                        return (
                                            <tr key={channelIndex}>
                                                <td>{channelVal}</td>
                                                <td>{salesreport.channel[channelVal].pprCount}</td>
                                                <td>{salesreport.channel[channelVal].minimumComittedRevenue}</td>
                                            </tr>
                                        )
                                    })
                                })}
                            </tbody>
                        </table>
                        <p className="dark-black font-size12">*Excludes Termination PPRs</p>
                    </div>
                    <div className="full-width sales-report mt-4" style={{ display: this.state.dealStatusFlag === true ? 'block' : 'none' }}>
                        <table className="p-0 m-0" id="dealStatusTable" border='1' borderColor="#cecece">
                            <thead>
                                <tr>
                                    <th>Deal Status</th>
                                    <th>PPR Count</th>
                                    <th>Minimum Committed Revenue ($)</th>
                                </tr>

                            </thead>
                            <tbody>
                                {this.state.dealStatusFlag && salesReportData && salesReportData.map((salesreport, index) => {
                                    let dealStatuses = Object.keys(salesreport.dealStatus);
                                    return dealStatuses && dealStatuses.map((dealSttusVal, dealSttausIndex) => {
                                        return (
                                            <tr key={dealSttausIndex}>
                                                <td>{dealSttusVal}</td>
                                                <td>{salesreport.dealStatus[dealSttusVal].pprCount}</td>
                                                <td>{salesreport.dealStatus[dealSttusVal].minimumComittedRevenue}</td>
                                            </tr>
                                        )
                                    })
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="full-width sales-report mt-4" style={{ display: this.state.geoTypeFlag === true ? 'block' : 'none' }}>
                        <table className="p-0 m-0" id="GeoTable" border='1' borderColor="#cecece">
                            <thead>
                                <tr>
                                    <th>Geo</th>
                                    <th>PPR Count*</th>
                                    <th>Minimum Committed Revenue ($)</th>
                                </tr>

                            </thead>
                            <tbody>
                                {this.state.geoTypeFlag && salesReportData && salesReportData.map((salesreport, index) => {
                                    let geos = Object.keys(salesreport.GEO);
                                    return geos && geos.map((geoVal, geoIndex) => {
                                        return (
                                            <tr key={geoIndex}>
                                                <td>{geoVal}</td>
                                                <td>{salesreport.GEO[geoVal].pprCount}</td>
                                                <td>{salesreport.GEO[geoVal].minimumComittedRevenue}</td>
                                            </tr>
                                        )
                                    })
                                })}
                            </tbody>
                        </table>
                        <p className="dark-black font-size12">*Excludes Termination PPRs</p>
                    </div>
                </div>
            </>
        )
    }

    renderTableOnStatus = (tableStatus) => {
        if (tableStatus === 'deal_status') {
            this.generateSalesReport('dealStatus');
        }
        else if (tableStatus === 'deal_Type') {
            this.generateSalesReport('dealType');

        } else if (tableStatus === 'channel') {
            this.generateSalesReport('channel');

        } else if (tableStatus === 'geo') {
            this.generateSalesReport('geo');
        }
    }


    loadToastForCopy = () => {
        this.showsalesReportToast('Copied....', true);
        setTimeout(() => {
            this.showsalesReportToast('Copied....', false);
        }, 1500)
    }

    callToCopy = () => {
        this.loadToastForCopy();
        isFirefox ? copyToClipFirefox(this.copyRef) : copyContent(this.copyRef);
    }

    render() {
        const { locationState } = this.props;
        const { userRole = '' } = locationState || {};
        const {
            filterDate,
            loadingMessage,
            termLength,
            validation,
            triggeredNext,
            questionValue,
            startDate,
            endDate,
            type,
            salesRpeRequestor,
            salesManager,
            disTermDate,
            custLegalName,
            users,
            dealStage,
            maxDate,
            salesRepRequestorSelectedOptions,
            salesManagerSelectedOptions,
            discountTermSelectedOptions,
            geoSelectedOptions,
            geo,
            subGeoSelectedOptions,
            businessCustomerTypeOptions
        } = this.state || {};


        return (
            <div className="full-width salesreport-wraper mothlyactivity-wraper">
                <div className="col-9 float-left">
                    {userRole === roleNames.SALES_REPRESENTATIVE ?
                        <h1 className="font-size18 amber-bold">Sales Rep Reports</h1>
                        : userRole === roleNames.SALES_MANAGER ?
                            <h1 className="font-size18 amber-bold">Sales Manager Reports</h1>
                            : null}
                    {this.state.filterDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date" id="start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={startDate}
                                    onChange={(value) => this.handleChange(value, "start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={endDate}
                                    onChange={(value) => this.handleChange(value, "end_date")}
                                    minDate={startDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    <div className="col-12 float-left p-0">
                        <ul className="col-12 float-left p-0 m-0 salesbtn-group">
                            <li className="float-left pr-3">
                                <AWSbutton
                                    btnType={this.state.dealTypeFlag === true ? "fill" : "fill unfill"}
                                    label="Deal Type"
                                    isIcon={false}
                                    onClick={() => this.renderTableOnStatus('deal_Type')}
                                />
                            </li>
                            <li className="float-left pr-3">
                                <AWSbutton
                                    btnType={this.state.dealStatusFlag === true ? "fill" : "fill unfill"}
                                    label="Deal Status"
                                    isIcon={false}
                                    onClick={() => this.renderTableOnStatus('deal_status')}
                                />
                            </li>

                            <li className="float-left pr-3">
                                <AWSbutton
                                    btnType={this.state.geoTypeFlag === true ? "fill" : "fill unfill"}
                                    label="Geo"
                                    isIcon={false}
                                    onClick={() => this.renderTableOnStatus('geo')}
                                />
                            </li>
                            <li className="float-left">
                                <AWSbutton
                                    btnType={this.state.channelFlag === true ? "fill" : "fill unfill"}
                                    label="Direct/Reseller/Solution Provider"
                                    isIcon={false}
                                    onClick={() => this.renderTableOnStatus('channel')}
                                />
                            </li>
                        </ul>

                        <div>
                            <div className="print-download float-right pt-2">
                                <p className="mb-0"><div className="float-right font-size12  cursor-pointer btn-copy noselect generate-ptsicon"
                                    id="copyContent"
                                    onClick={() => this.callToCopy()}
                                >
                                    Copy
                                            <img src={CopyBlackIcon} alt="copy" className="ml-2" />
                                </div>
                                </p>
                                <p className="float-left width-auto ml-4 cursor-pointer mb-0" id="downloadcsv" onClick={() => { this.downloadCSV() }}><span className="font-size12 float-left pr-2">Download</span><img src={downloadIcon} alt="download" /></p>
                            </div>
                        </div>
                    </div>
                    {this.renderTable()}
                </div>
                <div className="col-3 float-left pr-0">
                    <p className="font-size14 amber-bold">Filters</p>
                    <ul className="full-width m-0 p-0 filter-wraper pr-2">
                        <li>
                            <AWSDropdown
                                id="deal_stage"
                                label="Open/Closed"
                                options={dealOpenCloseJson}
                                value={dealStage}
                                handleOnSelect={(value) => this.handleChange(value, "deal_stage")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="date_filter"
                                label="PPR Submitted Date"
                                options={dateRangeJson}
                                value={filterDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value)}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Discount Term Effective Date
                            </span>
                            <Multiselect
                                id="disTerm"
                                label="Select"
                                options={discountEffectiveDateListForMultiSelect()}
                                selectedOptions={discountTermSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'disTerm')}
                            />
                        </li>
                        <li>
                            <AWSInput
                                id="termlengthInput"
                                placeholder=''
                                label="Term Length (Months)"
                                value={termLength}
                                validation={validation}
                                triggeredNext={triggeredNext}
                                questionValue={questionValue}
                                showOptional={false}
                                termEndDate=''
                                onChangeFunc={(value) => this.handleChange(value, "termLength")}
                            />
                        </li>

                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Geo
                            </span>
                            <Multiselect
                                id="geo"
                                options={isAfterOrgChange() ? orgChangeGeoJson : geoJson}
                                selectedOptions={geoSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'geo')}
                            />
                        </li>
                        {(isAfterOrgChange() && <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Customer Type
                                    </span>
                            <Multiselect
                                id="business_customer_type"
                                label="Select"
                                options={businessCustomerTypeJson || []}
                                selectedOptions={businessCustomerTypeOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'business_customer_type')}
                            />
                        </li>)}
                        {
                            geo.length === 1 && geo.includes("GLBL") && (
                                <li>
                                    <span className='input-label float-left mb-2 label-txt'>
                                        Sub-Geo
                            </span>
                                    <Multiselect
                                        id="subGeo"
                                        options={subGeoJson}
                                        selectedOptions={subGeoSelectedOptions}
                                        placeholder="Select"
                                        checkboxes={true}
                                        selectedLabel="Selected"
                                        onChange={(e) => this.handleChange(e.detail, 'subGeo')}
                                    />
                                </li>
                            )
                        }

                        <li>
                            <AWSAccountNameAC
                                id="cust_legal_name"
                                type="Customer Legal Name"
                                questionValue={questionValue}
                                value={custLegalName}
                                showHelpTooltip={true}
                                onChangeFunc={(value) => this.handleChange(value, "cust_leg_name")}
                                onSelectOption={(val) => this.onDropdownSelection(val)}
                                payerIdAccountValidation={() => { }}
                            />
                        </li>
                        <li>
                            {userRole === roleNames.SALES_REPRESENTATIVE ?

                                <>
                                    <span className='input-label float-left mb-2 label-txt'>
                                        Sales Rep (Requestor)
                                    </span>
                                    <Multiselect
                                        id="salesRepSelect"
                                        label="Select"
                                        options={users || []}
                                        selectedOptions={salesRepRequestorSelectedOptions}
                                        placeholder="Select"
                                        checkboxes={true}
                                        selectedLabel="Selected"
                                        onChange={(e) => this.handleChange(e.detail, 'salesRep')}
                                    />
                                </>
                                : userRole === roleNames.SALES_MANAGER ?

                                    <>
                                        <span className='input-label float-left mb-2 label-txt'>
                                            Sales Manager
                                    </span>
                                        <Multiselect
                                            id="salesManagerSelect"
                                            label="Select"
                                            options={users || []}
                                            selectedOptions={salesManagerSelectedOptions}
                                            placeholder="Select"
                                            checkboxes={true}
                                            selectedLabel="Selected"
                                            onChange={(e) => this.handleChange(e.detail, 'salesManager')}
                                        />
                                    </>
                                    : null}
                        </li>

                    </ul>
                    <div className="pt-4 float-left apply-report">
                        <AWSbutton
                            btnType="fill"
                            label="Apply Filter"
                            isIcon={false}
                            onClick={() => this.generateSalesReport(type)}
                        />
                    </div>
                    <div className="pt-4 float-right reset-filter">
                        <AWSbutton
                            btnType="fill"
                            label="Reset Filter"
                            isIcon={false}
                            onClick={() => this.resetFilter()}
                        />
                    </div>
                    {this.state.activateToast && (
                        <AWSLoadingToast
                            message={loadingMessage}
                        />
                    )}

                </div>
            </div>
        )
    }
}
export default SalesReport;
SalesReport.propTypes = {
    locationState: PropTypes.any,
    handleDisplayErr: PropTypes.func,
};