import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWSAdminTableInput.scss";
import _ from 'underscore';
// import AWSError from '../AWSError/AWSError';
import { Input } from "@amzn/awsui-components-react";

export default class AWSAdminTableInput extends Component {

    state = {
        errorLabel: "",
        error: false,
        disabled: true
    }
    
    componentDidUpdate(prevProps){
        let {editableCellTable} = this.props;
        let {disabled} = this.state;
        if (prevProps.editableCellTable !== editableCellTable) {
            if (editableCellTable === true) {
                console.log("editableCellTable equals true");
            } else {
                this.setState({
                    disabled: true
                })
            }
        }
        
    }
    
    handleChange = (val) => {
        this.setState({
            error: false
        })
        this.props.onChangeFunc(val);
    }

    handleBlur = (val) => {
        if(this.props.onBlurFunc){
            this.props.onBlurFunc(val);
        }
    }


    onClickOfInput = () => {
        this.setState({
            disabled: false
        },()=>{
            this.props.makeCellEditableTrue()
        })
    }

    render() {
        let {  value} = this.props;
        let { disabled } = this.state;
    
        return (
            <div className="AWS-input-div">

                <div className={"full-width p-0"} onClick={() => this.onClickOfInput()}>
                    {
                        disabled === true ? <label>{value}</label> : <Input
                            value={value}
                            error={value === "" ? true : false}
                            type="number"
                            onInput={(e) => this.handleChange(e.detail.value)}
                            onBlur={(e) => this.handleBlur(value)}
                            disabled={disabled}
                        />
                    }
                </div>

                {/* {this.state.error && (
                        <AWSError
                            errorLabel={this.state.errorLabel}
                        />
                    )} */}
            </div>
        );
    }
}

AWSAdminTableInput.propTypes = {
    value: PropTypes.any,
    onChangeFunc: PropTypes.func
};
