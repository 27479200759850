import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.scss';
export default class PageNotFound extends Component {
  render() {
    return (
      <div className="col-12 text-center page-notfound">
        <h1 className="amber-bold pb-4">404</h1>
        <p className="font-size18 pb-4">Page not found</p>
        <p className="font-size18 pb-4">The page which you are looking for is not available</p>
        <Link to="/" className="link-txt cursor-pointer font-size18">
        Go back to homepage
        </Link>
      </div>
    );
  }
}
