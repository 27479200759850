import React, { Component } from 'react';
import RateCardManagement from "./RateCardManagement";
import AWSCollapsible from "../../common/AWSCollapsible/AWSCollapsible";
import PropTypes from "prop-types";


class RateCardManagementWrapper extends Component {

    state = {
        rateCardButtonOption: "Rate Card 1",
        sectionIndex: 0,
    }

    /**
     * this function to update section based on selection
     * @param {*} sectionIndex the Index value of the particular index selected
     * @param {*} stage stage value of the section
     */
    onChangeSection = (sectionIndex) => {
        this.setState({
            sectionIndex
        })
    }

    /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
     */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    /**
     * This function used to handle rate card button navigation
     * @param {*} value the value of the Particular rate card section 
     */
    handleRateCardButtonMenu = (value) => {
        this.setState({
            rateCardButtonOption: value
        })
    }

    render() {
        return (
            <>
                <AWSCollapsible
                    dataModel={this.props.dataModel}
                    stage={this.props.selectedStage}
                    leftNavIndex={this.props.leftNavIndex}
                    activeCollapse={this.props.activeCollapse}
                    collapaseStateName={this.props.dataModel && this.props.dataModel[this.props.selectedStage || 0]}
                    onChangeSection={(section, stage) => this.onChangeSection(section, stage)}
                >

                    <div className="full-width p-0">
                        <RateCardManagement
                            selectedStage={this.props.selectedStage}
                            sectionIndex={this.state.sectionIndex}
                            rateCardButtonOption={this.state.rateCardButtonOption}
                            showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                        />
                    </div>
                </AWSCollapsible>
            </>
        );
    }
}

export default RateCardManagementWrapper;

RateCardManagementWrapper.propTypes = {
    showUserManagementLoadingToast: PropTypes.func,
    selectedStage : PropTypes.any,
    dataModel : PropTypes.any,
    leftNavIndex : PropTypes.any,
    activeCollapse : PropTypes.any
};
