import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import './AWSUserManagement.scss';
import PropTypes from "prop-types";

class DeleteUser extends Component {

    /**
       *  This function is called to show toast message based on the selection
       * @param {*} type type of event to handle

      */
    handleOk = (type) => {
        this.props.handleOk(type)
    }

    render() {
        const { type } = this.props;
        return (
            <div className="full-width delete-userpopup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-3 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        <span className="float-left warning-icon mr-1">
                        <Icon  name="status-warning"></Icon>
                            </span>Confirmation Message
                        <span className="float-right cursor-pointer pr-2 pt-1 close-icon" onClick={() => this.props.handleCancel()}>
                        <Icon name="close"></Icon>
                        </span>
                    </p>
                </div>
                <div className="full-width body-wraper pt-4 mb-0 pl-0 pr-0 pb-0">
                    <div className="full-width pt-3 text-center">
                        <p>{type === 'deleteUser' ? 'Are you sure you want to delete the user?': 'Are you sure you want to delete the role?'}</p>
                    </div>
                    <div className="full-width mt-5">
                    
                    </div>
                <div className="footer-wraper full-width pr-4">
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={'Yes'}
                                    isIcon={false}
                                    onClick={() => this.handleOk(type)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>    
            </div>
        );  
    }
}

export default DeleteUser;

DeleteUser.propTypes = {
    type : PropTypes.any,
    handleCancel : PropTypes.func,
    handleOk : PropTypes.func
  };