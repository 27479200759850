export const PPR_Created = 'PPR Created';
export const Awaiting_Sales_Input = 'Awaiting Sales Input';
export const Awaiting_Sales_Input_SM = 'Awaiting Sales Input (SM)';
export const PPR_Submitted = 'PPR Submitted';
export const Deal_Signed = "Deal Signed";
export const Deal_Lost = "Deal Lost";
export const Executable_Released = "Executable Released";
export const Open_For_Review = "Open for Review";
export const Sales_rep = "Sales Representative";
export const User_role = "Sales Manager";
export const Sales_Manager_Approved = "Sales Manager Approved";
export const Pending_Legal_Drafting = "Pending Legal Drafting";
export const Service_Team_Approved = "Service Team Approved";
export const Customer_Terms_Aligned = "Customer Terms Aligned";
