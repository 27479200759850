import React from "react";
import { Icon } from "@amzn/awsui-components-react";
import Plusicon from "../../../images/Pluss_Icon.svg";
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import AWSDropdown from '../../common/AWSDropdown/AWSDropdown';
class AddNewDealStatus extends React.Component {
    state = {
        value: '',
        inputValue: "test"
    }
    // onInputChangeFunc = (value) =>{
    //     this.setState({
    //         inputValue : value
    //     })
    // }
    render() {
        let options = [
            {
                optionValue: "AUTOCOMPLETE",
                optionKey: "AUTOCOMPLETE",
                defaultValue: null,
            },
            {
                optionValue: "DROPDOWN",
                optionKey: "DROPDOWN",
                defaultValue: null,
            },
            {
                optionValue: "TEXT",
                optionKey: "TEXT",
                defaultValue: null,
            }
        ]

        return (
            <div className="full-width dealstatus-wraper">
                {/* header wraper starts*/}
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-4 font-size16 dark-black mb-0 amber-bold border-bottom">Add New Deal Status
                    <span className="float-right cursor-pointer pr-2 pt-1 close-icon" onClick={() => this.props.handleCancel()}>
                            <Icon name="close"></Icon>
                        </span>
                    </p>
                </div>
                {/* body wraper starts*/}
                <div className="full-width body-wraper border-bottom pl-5 pr-4 pt-5 pb-5">
                    <div className="col-6 float-left p-0">
                        <div className="col-6 float-left pl-0">
                            <AWSDropdown
                                defaultValue="PPR Created"
                                label="Preceding Status"
                                options={options}
                                value={this.state.value}
                                handleOnSelect={(value) => { console.log("data") }}
                            />
                        </div>
                        <div className="col-6 float-left pl-0">
                            <AWSDropdown
                                defaultValue="PPR Created"
                                label="Succeeding Status"
                                options={options}
                                value={this.state.value}
                                handleOnSelect={(value) => { console.log("data") }}
                            />
                        </div>
                    </div>
                    {/* stage wraper starts*/}
                    <div className="full-width pt-5">
                        <div className="col-3 float-left pl-0">
                            <p className="font-size14 mb-2">Stage</p>
                            <AWSInput
                                value={this.state.value}
                                placeholder={"Deal Qualification"}
                                onChangeFunc={(value) => { this.onInputChangeFunc(value) }}
                            />
                        </div>
                        <div className="col-3 float-left pl-0">
                            <p className="font-size14 mb-2">Status</p>
                            <AWSInput
                                value={this.state.value}
                                placeholder={"PPR Submitted"}
                                onChangeFunc={(value) => { console.log("data") }}
                            />
                        </div>
                        <div className="col-2 float-left pl-0 pr-0">
                            <AWSDropdown
                                defaultValue="Sales Rep"
                                label="Check In"
                                options={options}
                                value={this.state.value}
                                handleOnSelect={(value) => { console.log("data") }}
                            />
                        </div>
                    </div>
                    {/* action label wraper starts*/}
                    <div className="col-9 float-right p-0">
                        <div className="full-width pt-4">
                            <div className="col-4 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="Approve"
                                    label="Action Label 1"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                            <div className="col-3 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="PPO"
                                    label="Check out"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                            <div className="col-4 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="PPR Approved"
                                    label="Notification Message"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                        </div>
                        <div className="full-width pt-3">
                            <div className="col-4 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="Reject"
                                    label="Action Label 2"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                            <div className="col-3 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="Sales Rep"
                                    label="Check out"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                            <div className="col-4 float-left pl-0">
                                <AWSDropdown
                                    defaultValue="Reject"
                                    label="Notification Message"
                                    options={options}
                                    value={this.state.value}
                                    handleOnSelect={(value) => { console.log("data") }}
                                />
                            </div>
                            <span className="plus-icon pt-5 float-left cursor-pointer"><img src={Plusicon} alt="plusIcon" /></span>
                        </div>
                    </div>
                </div>

                {/* foot wraper starts*/}
                <div className="footer-wraper full-width pr-4">
                    {/* <span className="float-left pt-4 pl-4 mt-2">Created By <span className="dark-blue">Henry Mendez 10/06/2019</span></span> */}
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => { this.props.handleCancel() }}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Add"
                                    isIcon={false}
                                    onClick={() => { console.log("cancel") }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddNewDealStatus;