import React, { Component } from 'react';
// import { AppProvider, Navigation, Icon } from '@shopify/polaris';
// import { PlusMinor, EditMinor } from '@shopify/polaris-icons';
import './NavMenu.scss';
import { ButtonDropdown } from "@amzn/awsui-components-react";

class NavMenu extends Component {


    handleTabMenuSelection = (navMenuOption) => {
        this.props.onMenuClick(navMenuOption)
    }


    render() {
        return (
            <div className="submenu-wraper" >
                {
                    this.props.leftIndex === 0 &&


                    <ButtonDropdown items={[
                        {
                            id: 'editMenu',
                            text: 'Edit Page Header',
                        },
                        {
                            id: 'addSection',
                            text: 'Add Section',
                        },
                        // {
                        //     text: 'Add New page',
                        //     id: 'addPage'
                        // },
                    ]}
                        onItemClick={(e) => { this.handleTabMenuSelection(e.detail.id) }}

                    >
                    </ButtonDropdown>


                }
                {/* {
                    this.props.leftIndex === 2 &&
                    <ButtonDropdown items={[
                        {
                            text: 'New Rate card',
                            id: ''
                        },
                        {
                            text: 'Configurations',
                            id: 'rateCardConfig'
                        }
                    ]}
                        onItemClick={(e) => { this.handleTabMenuSelection(e.detail.id) }}
                    >
                    </ButtonDropdown>
                } */}

            </div>

        );
    }
}

export default NavMenu;