import React from 'react';
import './AWSDropdown.scss';
import PropTypes from "prop-types";
import _ from 'underscore';
import { Select, Popover,Icon } from "@amzn/awsui-components-react";
import AWSError from '../AWSError/AWSError';
import infoHelpIcon from '../../../images/awsfalconhelp.png';
import * as tagNameConstant from '../../../constants/tagNameConstant';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as roleNames from '../../../constants/roleNames.const';
import { userRoleCheckForEditableCell } from "../../../utils/common";

export default class AWSDropdown extends React.Component {

    state = {
        errorLabel: "",
        error: false,
        value: "",
        warningLabel: false,
        selectedOption: ''
    }

    componentDidMount() {
        if (this.props.label === "Term Length") {
            if (this.props.value !== null) {
                this.props.handleOnSelect(this.props.value)
            }
        }
        if (this.props.defaultValue !== null && this.props.value === null) {
            this.props.handleOnSelect(this.props.defaultValue);
        }

        if (this.props.value) {
            if (this.props.questionValue && this.props.questionValue.tagName === tagNameConstant.discountTermDateTag) {
                let e = {
                    "detail" : {
                        "selectedOption": this.props.value
                    }
                }
                this.onChange(e)
            }
            this.props.handleOnSelect(this.props.value)
        }
        this.checkForAlertMessage()
    }

    checkForAlertMessage = () => {
        if (this.props.questionValue && this.props.questionValue.tagName === tagNameConstant.discountTermDateTag) {
            var tempDate = new Date();
            let {value} = this.props;
            let currentmonth = tempDate.getMonth() + 1;
            let currentdate = tempDate.getDate();
            let currentDay = tempDate.getDay();
            let dropValues = this.props.options.filter((valop) => valop.optionKey === value)[0];

            if (dropValues) {
                let currentValue = dropValues.optionValue
                let currentDatevalue = currentValue.split('/');
                let monthValue = parseInt(currentDatevalue[0]);

                // for saturday sunday
                if (currentDay > 5) {
                    if (currentdate === 20) {
                        if (monthValue === (currentmonth + 1)) {
                            this.setState({
                                warningLabel: true
                            })
                        }
                        else {
                            this.setState({
                                warningLabel: false
                            })
                        }
                    }
                } else {
                    if (currentdate > 20) {
                        if (monthValue === (currentmonth + 1)) {
                            this.setState({
                                warningLabel: true
                            })
                        }
                        else {
                            this.setState({
                                warningLabel: false
                            })
                        }
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            // this.validate(this.props.validation, this.props.value)

            const {  questionValue } = this.props;
            const { tagName = '' } = questionValue || {};
    
            if ((tagName === tagNameConstant.payerIdDetailsTag) ) {
                this.validate(this.props.validation, this.props.value)
            } else {
                if (this.props.value !== null && this.props.value !== undefined) {
                    this.setState({
                        error: false
                    })
                }
            }

            this.props.handleOnSelect(this.props.value)
        }
    }

    validate(validationArray, val) {

        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            if (validationObject.type === "Required") {
                if ((_.isEmpty(val)) || (_.isNull(val)) || !(val && val.length)) {
                    this.setState({
                        error: true,
                        errorLabel: validationObject.message
                    })
                } else {
                    if (this.state.error === true) {
                        this.setState({
                            error: false
                        })
                    }
                }
            }
        });
    }

    onChange = (e, optionValue) => {
        // console.log("e = ", e);
        
        const { detail: { selectedOption } } = e;
        const { value } = selectedOption;

        const { options, questionValue } = this.props;
        const { tagName = '' } = questionValue || {};

        // if (this.props.label === "Discount Term Effective Date") {
        if (tagName === tagNameConstant.discountTermDateTag) {
            var tempDate = new Date();

            let currentmonth = tempDate.getMonth() + 1;
            let currentdate = tempDate.getDate();
            let currentDay = tempDate.getDay();
            let dropValues = this.props.options.filter((valop) => valop.optionKey === value)[0];

            if (dropValues) {
                let currentValue = dropValues.optionValue
                let currentDatevalue = currentValue.split('/');
                let monthValue = parseInt(currentDatevalue[0]);

                // for saturday sunday
                if (currentDay > 5) {
                    if (currentdate === 20) {
                        if (monthValue === (currentmonth + 1)) {
                            this.setState({
                                warningLabel: true
                            })
                            this.props.onChangeAdditionalApproval("dis_term_date", true)
                        }
                        else {
                            this.setState({
                                warningLabel: false
                            })
                            this.props.onChangeAdditionalApproval("dis_term_date", false)
                        }
                    }
                } else {
                    if (currentdate > 20) {
                        if (monthValue === (currentmonth + 1)) {
                            this.setState({
                                warningLabel: true
                            })
                            this.props.onChangeAdditionalApproval("dis_term_date", true)
                        }
                        else {
                            this.setState({
                                warningLabel: false
                            })
                            this.props.onChangeAdditionalApproval("dis_term_date", false)
                        }
                    }
                }
            }

            this.props.handleOnSelect(value, optionValue);
            this.setState({
                error: false,
                selectedOption: selectedOption
            })
        } else {
            this.props.handleOnSelect(value);
            this.setState({
                error: false,
                selectedOption: selectedOption
            })
        }
    }

    getValue(options, value) {
        if (this.props.value || this.props.defaultValue) {
            const selectedOption = options && options.filter(({ optionKey, optionValue }) => value === optionKey)
            return selectedOption[0];
        } else {
            return '';
        }
    }

    editQuestionCss = (questionValue) => {
        return questionValue.isQuestionEdit === true ? 'editable-color' : ''
    }

    /**
     * this function will verify and return boolean for disable state of field based on status, userRole and question tagnam
     */
    verifyDisablecondtionBasedOnstatus = () => {
        const { dealStatus, userRole, questionValue, locationStateUser, dealInfo } = this.props;        
        if ((dealStatus === dealStatusConstant.Open_For_Review && userRoleCheckForEditableCell(locationStateUser, userRole, dealInfo, roleNames)) || userRole === roleNames.BILLING_TEAM) {
            if (questionValue && questionValue.tagName && questionValue.tagName === tagNameConstant.discountTermDateTag) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (this.verifyDisablecondtionBasedOnstatus()) {
            return false
        } else {
            if (canEditPPR === true) {
                return this.props.disableDropdown || (questionValue && questionValue.quePermission ? questionValue.quePermission.read : false)
            } else if (canEditPPR === false) {
                return true // disable true
            } else {
                return false
            }
        }
    }

    render() {
        const { selectedOption } = this.state;
        const { label, options, value, questionValue, canEditPPR, showHelpTooltip = true, showOptional = true, filterDD, defaultValue } = this.props;
        const { tagName = '' } = questionValue || {};

        if (this.props.triggeredNext && (!this.state.error) && questionValue && questionValue.isVisible) {
            this.validate(this.props.validation, this.props.value)
        }

        options && options.map((option) => {
            option.label = option.optionValue;
            option.value = option.optionKey;
        })
        

        return (
            <>
                <div className={label === "" ? "block drop-wraper" : "inline drop-wraper"}>
                    {questionValue !== undefined ?

                        <label className={label === "" ? `disblock block ${this.editQuestionCss(questionValue)}` : `${label ? 'input-label':''} disinline inline col-12 p-0 mb-0 ${this.editQuestionCss(questionValue)}`}>
                            <div className="float-left mb-0 label-txt">{questionValue.isMandatory === true || showOptional === false ? label : `${label} (Optional)`}</div>
                            {label === "" ? "" :
                                showHelpTooltip && questionValue && questionValue.helpText && (
                                    <span className="float-left">
                                        <Popover content={questionValue && questionValue.helpText}
                                            preferredPosition="above" size="small" position="top">

                                        </Popover>
                                        <span className="float-left circle-alert cursor-poniter table-info-icon">
                                            {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                        </span>
                                    </span>
                                )
                            }
                        </label>
                        : <label className={label === "" ? "disblock block" : "disinline inline mb-2"}>{label}</label>
                    }
                    {filterDD && filterDD === 'filter' ?
                        <Select
                            options={options}
                            onChange={(e) => this.onChange(e)}
                            // onFocus={(e) => this.onChange(value ? value : e)}
                            // value={this.getValue(options, value)}
                            selectedOption={value && value.length > 0 ? this.getValue(options, value) : this.getValue(options, defaultValue)}
                            // disabled={this.props.disableDropdown || (questionValue && questionValue.quePermission ? questionValue.quePermission.read : false)}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                            name={this.props.name}
                            selectedLabel="Selected"
                        /> : <Select
                            options={options}
                            placeholder="Select"
                            onChange={(e) => this.onChange(e)}
                            // onFocus={(e) => this.onChange(value ? value : e)}
                            selectedOption={value && value.length > 0 ? this.getValue(options, value) : this.getValue(options, defaultValue)}
                            // value={this.getValue(options, value)}
                            // disabled={this.props.disableDropdown || (questionValue && questionValue.quePermission ? questionValue.quePermission.read : false)}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                            name={this.props.name}
                        />
                    }



                    {this.state.warningLabel === true && tagName === tagNameConstant.discountTermDateTag && (
                        <p className="font-size10 red">Warning: Additional approvals are required for this deal. It might take more time to review this deal than necessary</p>
                    )}
                    {this.props.termEndDate !== "" && label === "Term Length" && (<span className="font-size12 grey">Discount term ends on date {this.props.termEndDate}</span>)}
                    {(questionValue && questionValue.isVisible === true) && this.state.error && (
                        <AWSError
                            errorLabel={this.state.errorLabel}
                        />)}
                </div >
            </>
        );
    }
}

AWSDropdown.propTypes = {
    label: PropTypes.string,
    onChangeFunc: PropTypes.func
};
