export const showVolumeCommitAlert = (
  isCloudFrontShieldAdvanced,
  geo,
  volumeCommitError,
  geoVolumeCommitErrors
) => {
  return (
    isCloudFrontShieldAdvanced &&
    ((geo === "All" && volumeCommitError) ||
      (geo === "By Geo" &&
        Object.values(geoVolumeCommitErrors).reduce((prev, current) => {
          return prev || current !== undefined;
        }, false)))
  );
};
