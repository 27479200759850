import React from "react";
import axios from 'axios';
// import { Icon } from '@shopify/polaris';
import _ from 'underscore';
// import AWSTable from "../AWSTable/AWSTable";
// import { MobilePlusMajorMonotone } from '@shopify/polaris-icons';
import './AWSUserManagement.scss';
import AWSModal from '../AWSModal/AWSModal';
import AWSConfig from '../../../config/AWSconfig';
import PropTypes from "prop-types";
import AWSAdminTable from "../AWSAdminTable/AWSAdminTable";
import AWSAdminTableEditIcon from "../../common/AWSAdminTableIcon/AWSAdminTableEditIcon";
import AWSAdminTableDeleteIcon from "../../common/AWSAdminTableIcon/AWSAdminTableDeleteIcon";
import  * as UserManagementConstants from "../AWSAdminTable/AdminTableConstants/UserManagementConstants";

const config = new AWSConfig();

class AWSRoleManagement extends React.Component {

  state = {
    tableData: [],
    visible: false,
    showTablePopup: false,
    modalType: "",
    deleteObj: "",
    roleOptions: [],
    roleData:
    {
      "roleName": "",
      "parentRole": "",
      "type": "",
      "roleSortId": ""
    }
  };

  /**
  * This lifecycle method is calling API to get role management details
  */
  componentDidMount() {
    this.showUserManagementLoadingToast("Loading Role List...", true);
    let UsersApi = `${config.userManagementbaseUrl}users/Roles`;
    axios({
      method: "GET",
      url: UsersApi
    })
      .then(resp => {
        this.setState({
          tableData: resp.data.Data.Items
        });
        _.each(resp.data.Data.Items, (Values) => {
          let roleOptions = { "optionKey": Values.roleName, "optionValue": Values.roleName };
          this.state.roleOptions.push(roleOptions);
        });
        this.showUserManagementLoadingToast("Loading Role List", false);
      }).catch(err => console.log(err));
  }

  /**
   * This function is used to set the local state for each varaible
   * @param {*} value value of the particular varaible to set 
   * @param {*} name name of the particular varaible to set 
   */
  onChangeRoleData = (name, value) => {
    this.setState(prevState => ({
      roleData: {
        ...prevState.roleData,
        [name]: value
      }
    }));
  }

  /**
   * This function is used to set the local state for each varaible
   * @param {*} modalType type of the modal to load  
   * @param {*} data value of all the role data 
   */
  showModal = (modalType, data) => {
    if (modalType !== 'deleteRole') {
      this.setState({
        visible: true,
        modalType: modalType,
        roleData: data
      });
    } else {
      this.setState({
        visible: true,
        modalType: modalType,
        deleteObj: data
      });
    }
  };

  /**
 * This function is used to handle the button selection in the modal
 */
  handleOkOrCancelOfModal = () => {
    this.setState({
      visible: false,
      roleData:
      {
        "roleName": "",
        "parentRole": "",
        "type": "",
        "roleSortId": ""
      }
    });
  };

  /**
   * This function is used to show the delete popup in modal     
   * @param {*} cell cell value to be deleted
   * */
  onDelete = (cell) => {
    this.showModal("deleteRole", cell);
  }

  /**
 * This function is used to show the dropdown in modal     
 * @param {*} cell cell value 
 * */
  showDropDown = (cell) => this.setState({ showTablePopup: true });

  /**
 * This function is used to edit role values in the modal
 * @param {*} cell cell value 
 * */
  onEdit = (cell) => {
    this.showModal("updateRole", cell);
  }

  /**
   *  This function is called to show toast message based on the selection
   * @param {*} message which is a message to display 
   * @param {*} toast which is a boolean to show hide the message
  */
  showUserManagementLoadingToast = (message, toast) => {
    this.props.showUserManagementLoadingToast(message, toast);
  }

  /**
  * This function is used to set the local state for each varaible based on selection
  * @param {*} value value of the particular varaible to set 
  * @param {*} name name of the particular varaible to set 
  */
  handleOnSelect = (name, value) => {
    this.setState(prevState => ({
      roleData: {
        ...prevState.roleData,
        [name]: value
      }
    }));
  }

  /**
  * This function is used to call when the role is added or edited
  * @param {*} type type of action to be taken when the function called
  * @param {*} name name of the particular varaible to set 
  */
  handleSubmit = (type) => {
    const addRoleUrl = `${config.userManagementbaseUrl}users/addRoles`;
    const updateRoleUrl = `${config.userManagementbaseUrl}users/updateRoles`;
    if (type !== 'deleteRole') {
      let roleData = this.state.roleData;
      if (roleData.roleName.trim() !== "" && roleData.parentRole.trim() !== "") {
        let message = (type === 'newRole') ? 'Adding New Role...' : 'Updating Role...';
        this.showUserManagementLoadingToast(message, true);
        let RolesApi = (type === 'newRole') ? addRoleUrl : updateRoleUrl;
        axios({
          method: "POST",
          data: this.state.roleData,
          url: RolesApi
        })
          .then(resp => {
            this.setState({
              tableData: resp.data.Data.Items
            });
            this.setState(prevState => ({
              roleData: {
                ...prevState.roleData,
                "roleName": "",
                "parentRole": "",
                "type": "",
                "roleSortId": ""
              }
            }));
            this.setState({
              visible: false
            });
            this.showUserManagementLoadingToast("Adding New Role", false);
          }).catch(err => console.log(err));
      }
    } else {
      let cell = this.state.deleteObj;
      this.showUserManagementLoadingToast("Deleting Role...", true);
      let reqdata = {
        "roleName": cell.roleName,
        "parentRole": cell.parentRole,
        "roleSortId": cell.roleSortId,
        "type": cell.type
      }
      let DeleteApi = `${config.userManagementbaseUrl}users/deleteRoles`;
      axios({
        method: "POST",
        url: DeleteApi,
        data: reqdata
      }).then(resp => {
        let removeIndex = this.state.tableData.map(function (item) { return item.roleSortId; }).indexOf(cell.roleSortId);
        this.state.tableData.splice(removeIndex, 1);
        this.showUserManagementLoadingToast("Role deleted", false);
      }).catch(err => console.log(err));
      this.setState({
        visible: false
      });
    }
  };

  render() {
    let roleColumns = [
      { header: "Role Name", id: "roleName", sorter: "string", width: 400, cell: item => <div>{item.roleName}</div> },
      { header: "Parent Role", id: "parentRole", sorter: "string", cell: item => <div>{item.parentRole}</div> },
      {
        header: "Actions",
        id: "action",
        cell: item => <div className="float-left col-12 p-0">
          <div className="col-6 float-left p-0" onClick={(e) => this.onEdit(
            item
          )}>
            <AWSAdminTableEditIcon />
          </div>
          <div className="col-6 float-left p-0" onClick={(e, index) => this.onDelete(
            item
          )}>
            <AWSAdminTableDeleteIcon />
          </div>
        </div>,
        minWidth: '90px'
      }
      // {
      //   header: "Action", id: "cheese", align: "center", width: 100, columns: [
      //     {
      //       header: "", align: "right",
      //       width: 30,
      //       formatter: (cell) => {
      //         return `<div class='text-center'><span class='mr-3 edit-icon icons-styles'></div>`
      //       },
      //       cellClick: (e, cell) => {
      //         this.onEdit(cell._cell.row.data)
      //       }
      //     },
      //     {
      //       header: "", id: "", align: "", width: 20,
      //       formatter: (cell) => {
      //         return `<div class='text-center'><span class='delete-icon icons-styles'></div>`
      //       },
      //       cellClick: (e, cell) => {
      //         this.onDelete(cell._cell.row.data)
      //       }
      //     }
      //   ],
      // },
    ];

    return (
      <div className="user-management full-width">
        <div className="full-width">
          <ul className="p-0 float-right sub-controls">
            {/* <li className="pr-4" onClick={() => this.showModal("newRole", this.state.roleData)}><span className="mr-2 float-left"><Icon source={MobilePlusMajorMonotone} /></span>New Role</li> */}
          </ul>
        </div>
        <div className="full-width user-managenent-table role-table">
          <AWSAdminTable
            columns={roleColumns}
            tableData={this.state.tableData}
            sortableColumnOptions={UserManagementConstants.roleColumns}
          />
        </div>
        <div className="full-width">
          <AWSModal
                type={this.state.modalType}
                visible={this.state.visible}
                Roleoptions={this.state.roleOptions}
                roleData={this.state.roleData}
                deleteObj={this.state.deleteObj}
                onChangeFunc={(name, value) => { this.onChangeRoleData(name, value) }}
                handleOnSelect={(name, value) => { this.handleOnSelect(name, value) }}
                handleOk={(type) => { this.handleSubmit(type) }}
                handleCancel={() => { this.handleOkOrCancelOfModal() }}
            />
        </div>
      </div>
    );
  }
}

export default AWSRoleManagement;

AWSRoleManagement.propTypes = {
  showUserManagementLoadingToast: PropTypes.func,
  handleOnSelect: PropTypes.func,
  onChangeFunc: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  columns: PropTypes.any,
};
