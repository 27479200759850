import React, { Component } from 'react';
// import { Icon } from '@shopify/polaris';
// import { MobileCancelMajorMonotone } from '@shopify/polaris-icons';
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSDropDown from '../../common/AWSDropdown/AWSDropdown';
import PropTypes from "prop-types";

let validation = [{
    message: "This is a mandatory field",
    type: "Required"
}];

let questionValue = {
    isVisible: true,
    isMandatory: true
};

class AddNewRole extends Component {

    state ={
        triggeredNext: false,
        isVisible: true
    }

    /**
     * This function is used to set the local state for each varaible based on selection
     * @param {*} value value of the particular varaible to set 
     * @param {*} name name of the particular varaible to set 
     */
    handleOnSelect = (value, name) => {
        this.props.handleOnSelect(name, value);
    }

    /**
     * This function is used to set the local state for each varaible
     * @param {*} value value of the particular varaible to set 
     * @param {*} name name of the particular varaible to set 
     */
    onChangeFunc = (value, name) => {
        this.props.onChangeFunc(name, value);
    }

    /**
     * This function is called on click of the configuration changes and check for validation errors
     */
    handleOk = (type) => {
        this.setState({
            triggeredNext: true
        }, () => {
            this.setState({
                triggeredNext: false
            })
            setTimeout(() => {
                this.props.handleOk(type)
            }, 100)
        })
    }

    render() {
        const { roleData,type } = this.props;
        return (
            <div className="addrole popup full-width">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                    {
                            type === 'newRole' ? "Add New Role" : "Update Role"
                        }
                        <span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                            {/* <Icon source={MobileCancelMajorMonotone} /> */}
                            </span></p>
                </div>
                <div className="full-width body-wraper pt-4 mb-5 pl-5 pr-5 pb-4">
                    <div className="full-width pt-3">
                        <div className="col-5 float-left p-0 mr-5">
                            <p className="dark-black">Role Name</p>
                            <div className="full-width">
                                <AWSInput
                                    placeholder="Senior Admin Manager"
                                    value={roleData.roleName}
                                    onChangeFunc={(value) => { this.onChangeFunc(value, "roleName") }}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                />
                            </div>
                        </div>
                        <div className="col-5 float-left p-0">
                            <p className="dark-black">Parent Role</p>
                            <div className="full-width">
                            <AWSDropDown
                                placeholder="Parent Role"
                                options={this.props.Roleoptions}
                                value={roleData.parentRole}
                                validation={validation}
                                triggeredNext={this.state.triggeredNext}
                                questionValue={questionValue}
                                handleOnSelect={
                                    (value) => {
                                        this.handleOnSelect(value, "parentRole")
                                    }
                                }
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={
                                        type === 'newRole' ? "Add" : "Update"
                                    }
                                    isIcon={false}
                                    onClick={() => this.handleOk(type)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>    
        );  
    }
}

export default AddNewRole;

AddNewRole.propTypes = {
    showUserManagementLoadingToast: PropTypes.func,
    handleOnSelect : PropTypes.func,
    onChangeFunc : PropTypes.func,
    handleCancel : PropTypes.func,
    handleOk : PropTypes.func,
    Roleoptions : PropTypes.any,
    roleData : PropTypes.any,
    type : PropTypes.any,
};