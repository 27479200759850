import { base64toBlob } from './common';
import axios from 'axios';
import AWSconfig from './../config/AWSconfig';

const config = new AWSconfig();
const salesReportUrl = `${config.reportUrl}salesReport`;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
axios.defaults.headers.common["Content-Type"] = 'application/json'

export const downloadCSV = (responseData, queryParam) => {
    // this.showsalesReportToast('Preparing the CSV FILE to be dowloaded....', true);    
    let downloadCSVUrl = `${config.reportUrl}${queryParam}`;
    axios({
        method: "POST",
        url: downloadCSVUrl,
        data: responseData
    }).then(resp => {
        console.log('respbase64', resp);

        const contentType = "text/csv;charset=utf-8";
        // console.log('resp', resp.data);
        // this.showsalesReportToast('Preparing the CSV FILE to be dowloaded....', false);

        if (resp) {
            let blob = base64toBlob(resp.data, contentType);
            let url = window.URL.createObjectURL(blob);
            downloadCSVFile(url);
        }
    }).catch((err) => {console.log(err)})
}

 const downloadCSVFile = (sUrl) => {
    // console.log('sUrl', sUrl);
    //If in Chrome or Safari or Firefox - download via virtual link click
    //Creating new link node.
    var link = document.createElement('a');
    link.href = sUrl;

    if (link.download !== undefined) {
        //Set HTML5 download attribute. This will prevent file from opening if supported.
        var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
        // console.log('FILENAME', fileName);

        link.download = `${fileName}_SalesReport.CSV`;
    }

    //Dispatching click event.
    if (document.createEvent) {
        var e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
    }
    // Force file download (whether supported by server).
    var query = '?download';

    window.open(sUrl + query);
    
}