import React from "react";
import axios from 'axios';
import downloadIcon from '../../../images/dashoboard_Upload.svg';
import ReplaceIcon from '../../../images/Replace_white.svg';
import "../../Dashboard/AWSDashboard.scss";
import { isValidFile, base64toBlob } from '../../../utils/common';
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import * as roleNames from '../../../constants/roleNames.const';
import AWSConfig from '../../../config/AWSconfig';
import { emailNotification } from "../../../services/endPoints";
import AWSModal from "../../common/AWSModal/AWSModal";

const config = new AWSConfig();
let baseUrl;

export default class AWSTableUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      attachmentsList: {},
      activateToast: false,
      loadingMessage: '',
      attachmentName: '',
      showPopUpVisible: false,
      uploadFileFlag: false,
      file: null,
      fileName: ''
    }
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    baseUrl = config.amazonFalconbaseUrl;
  }  

  showFileUploadToast = (message, activeState) => {
    this.setState({
      activateToast: activeState,
      loadingMessage: message
    }, () => {
      setTimeout(() => {
        this.setState({
          activateToast: false,
        })
      }, 5000)
    });
  }

  convertPPRId = () => {
    const { pprId } = this.props;
    let pprID = Number(pprId.split('-')[1]);
    return pprId;
  }

  actualFileUpload = async () => {
    const { file, fileName } = this.state;
    const { pprId } = this.props;
    let pprID = Number(pprId.split('-')[1]);
    this.showFileUploadToast(`Attaching ${file.name} ...`, true);
    const base64 = await this.toBase64(file);

    axios({
      method: 'POST',
      url: `${baseUrl}cfppr/attach`,
      data: {
        base64String: base64.split(',')[1],
        fileName: fileName,
        pprId: pprID,
      },
    })
      .then((resp) => {
        const { status, data: { fileName } } = resp;
        if (status === 200) {
          this.showFileUploadToast(`${file.name} has been successfully uploaded`, true);
          this.setState({ attachmentName: fileName }, () => {
            this.callDealFullyExecuted();
          });
        }
      })
      .catch((err) => {
        this.showFileUploadToast(`Failed to attach ${file.name}`, true);
      });
  }

  actualFileReplace = async () => {
    const { file, fileName } = this.state;
    const { pprId } = this.props;
    let pprID = Number(pprId.split('-')[1]);
    this.showFileUploadToast(`Replacing existing contract with ${file.name} ...`, true);
    const base64 = await this.toBase64(file);
    let previousFileName = "";
    let attachementListAPI = `${baseUrl}AttachmentList/releasedattach?pprId=` + pprID;

    axios({
      method: 'GET',
      url: attachementListAPI,
    }).then(resp => {
      previousFileName = resp?.data?.fileName;
      if (previousFileName !== '') {
        axios({
          method: 'PUT',
          url: `${baseUrl}cfppr/attach`,
          data: {
            base64String: base64.split(',')[1],
            fileName: fileName,
            pprId: pprID,
            prevFileName: previousFileName
          },
        })
          .then((resp) => {
            const { status, data: { fileName } } = resp;
            if (status === 200) {
              this.showFileUploadToast(`${file.name} has been successfully uploaded`, true);
              this.setState({ attachmentName: fileName });
            }
          })
          .catch((err) => {
            this.showFileUploadToast(`Failed to attach ${file.name}`, true);
          });
      } else {
        this.showFileUploadToast(`Deal Signed attachment doesn't exist!`, true);
      }
    });
  }

  handleOk = (dealStatus) => {
    this.setState({ showPopUpVisible: false });
    if (dealStatus !== 'Deal Signed') {
      this.actualFileUpload();
    } else {
      this.actualFileReplace();
    }
  }

  handleCancel = () => {    
    this.uploadRef.current.value = '';
    this.setState({ showPopUpVisible: false, file: null, fileName: '' });
  }

  handleUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const fileName = `${event.target.id}_${file.name}`;
    const fileSize = (file.size) / (1024 * 1024);

    if (fileSize > 4) {
      this.showFileUploadToast(`File size must be less than 4MB`, true);
      return;
    }

    if (!isValidFile(file.name)) {
      this.showFileUploadToast(`Invalid File`, true);
      return;
    }
    this.setState({ showPopUpVisible: true, file, fileName });

  };

  toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  fileUploadButton = (id, title, ref) => {
    return (
      <>
        <img src={downloadIcon} alt="Upload" onClick={() => ref.current.click()} />
        <input
          ref={ref}
          id={id}
          type="file"
          style={{ display: 'none' }}
          onChange={this.handleUpload}
        />
      </>
    )
  };

  fileReplaceButton = (id, title, ref) => {
    return (
      <>
        <img src={ReplaceIcon} alt="Replace" onClick={() => ref.current.click()} />
        <input
          ref={ref}
          id={id}
          type="file"
          style={{ display: 'none' }}
          onChange={this.handleUpload}
        />
      </>
    )
  };

  /*Call this function to make a deal fully executable after file upload*/
  callDealFullyExecuted = () => {
    const { dealStatus, pprId, userRole, createdById, userAlias } = this.props;
    const reqData = {
      'AWSAlias': createdById,
      'userRole': roleNames.SALES_REPRESENTATIVE,
      'dealStatus': dealStatus,
      'createdById': createdById,
      'pprId': pprId,
      'loginAlias': userAlias,
      'action': 'upload'
    }

    axios({
      method: "POST",
      url: baseUrl + "cfppr/inactive",
      data: reqData
    }).then(resp => {
      this.sendEmailUpdates();
      this.props.getDashboardData();
    }).catch((err) => {
      console.log('err', err.response);
    });
  }

  sendEmailUpdates = () => {
    const { dealStatus, pprId, userRole, createdById, userAlias, pprType, accountName } = this.props;
    let pprID = Number(pprId.split('-')[1]);
    let emailForm = {
      "dealStatus": "Deal Signed",
      "createdById": createdById,
      "type": pprType,
      "userRole": userRole,
      "accountManager": "",
      "accountName": accountName,
      "ownerId": "",
      "AWSAlias": userAlias,
      "pprId": pprID,
      "isMatChange": "",
      "dashboard": true,
      "RFIflag": "",
      "isMaterialChange": ""
  }
  // axios call to send notification
  let emailUrl = "email/sendMessage"
  emailNotification(emailUrl, emailForm)
  }

  render() {
    const { dealStatus, userRole } = this.props;
    const { attachmentName, showPopUpVisible } = this.state;

    return (
      <div>
        {dealStatus !== undefined && dealStatus === 'Executable Released' ?
          <div className="text-center">
              <div content="Upload" className="cursor-pointer">
                {/* <Tooltip content="Upload"></Tooltip> */}
                <span className="font-size14 white pr-3"></span>
                {this.fileUploadButton('released', 'Attach File', this.uploadRef)}
                {/* <img src={downloadIcon} alt="Upload" /> */}
                {/* </Tooltip> */}
              </div>
          </div>
          :
          <div className="text-center">
            {dealStatus !== undefined && dealStatus === 'Deal Signed' ?
              <div content="Replace" className="cursor-pointer">
                {/* <Tooltip content="Upload"></Tooltip> */}
                <span className="font-size14 white pr-3"></span>
                {this.fileReplaceButton('released', 'Attach File', this.uploadRef)}
                {/* <img src={downloadIcon} alt="Upload" /> */}
                {/* </Tooltip> */}
              </div> : null
            }
          </div>
        }
        <div className='full-width'>
          <AWSModal
            type="renderConfirmationPopUp"
            visible={showPopUpVisible}
            handleOk={() => this.handleOk(dealStatus)}
            handleCancel={() => this.handleCancel()}
            handleClose={() => { }}
            text="Are you sure you want to upload this file?"
          />
        </div>
        {this.state.activateToast && (
          <AWSLoadingToast
            message={this.state.loadingMessage}
            error={this.state.loadingError}
            duration={this.state.loadingMessageDuration}
          />
        )}
      </div>
    );
  }
}
