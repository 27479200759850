import React, { Component } from "react";
import Col from "../common/AWSCol/AWSCol";
import * as Constants from "../../constants/dealStructureConstants";
import AWStextarea from "../common/AWStextarea/AWStextarea";
import Plusicon from "../../images/Pluss_Icon.svg";
import Minusicon from "../../images/minu_icon.svg";
import AWSToggleButton from "../common/AWSToggleButton/AWSToggleButton";
import AWSDropdown from "../common/AWSDropdown/AWSDropdown";
import PropTypes from "prop-types";
import AWSConfig from '../../config/AWSconfig';
import * as roleNames from '../../constants/roleNames.const';
import axios from "axios";
import { Popover, Icon } from "@amzn/awsui-components-react";
import VideoIcon from "../common/Icon/VideoIcon/VideoIcon";
import { getIsCloudFrontShieldAdvancedDeal } from "./helpers/rateCard"
import "./DealDetails.scss";
const config = new AWSConfig();

let questionValue = {
  isVisible: true,
  isMandatory: true,
};

let validation = [
  {
    message: "This is a mandatory field",
    type: "Required"
  }
];

export const getUsageMixValue = (key, values) => {
  const usageMixObj = Array.isArray(values) && values.find(element => element && element.tierKey === key);
  return usageMixObj && parseInt(usageMixObj.value, 10);
}

export const getIsAPSubMixRequired = (subMixValue) => {
  return subMixValue >= 10;
}

export class DealDetails extends Component {

  state = {
    addlListOptions: [],
    errMessage: ''
  }

  componentDidMount() {
    const reqParam = 'getAPT';
    this.getAllAdditionalDealTerms(reqParam);
  }

  componentDidUpdate(prevProps) {
    const { rateCard } = this.props;
    if (prevProps.rateCard.pricingTerms !== rateCard.pricingTerms) {
      this.props.onChangeAdditionalApproval("pricingTerms", rateCard.pricingTerms)
    }
  }

  getAllAdditionalDealTerms = (reqParam) => {
    const adtApi = `${config.userManagementbaseUrl}users/${reqParam}`;
    axios({
      method: "GET",
      url: adtApi
    }).then(resp => {
      this.setState({ addlListOptions: resp.data.Data });
    }).catch((err) => {
      const { response: { data: { Data } } } = err;
      this.setState({ errMessage: Data })
    });
  }

  /**
   * This function used update the local state value of each input element in parent component
   * @param {*} val typed or selected option which need to update in the state
   * @param {*} key in which name of the particular element value to be set
   * @param {*} rowIndex the array row location where the values chaned
   * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
   */
  handleChange = (val, key, rowIndex, cellIndex) => {
    if (key === 'pricingTerms') {
      this.props.onChangeAdditionalApproval("pricingTerms", val)
    }
    this.props.handleInputChange(val, key, rowIndex, cellIndex);
  };

  /**
   * This function will add extra fields for additinal pricing terms
   *
   */
  handleOnAdd = () => {
    if (this.props.userRole && this.props.userRole !== 'Sales Manager') {
      let newObject = {
        cells: [
          {
            type: "text",
            header: "",
            value: ""
          },
          {
            type: "text",
            header: "",
            value: ""
          }
        ],
        corePlusOption: "Select"
      };
      let { formGroup } = this.props.rateCard;
      formGroup.push(newObject);
      this.props.handleInputChange(formGroup, "FORMGROUP");
    }
  };

  /**
   * This function will remove extra fields for additional pricing terms
   * @param {*} rowId rowId to be removed from additional pricing terms
   */
  handleOnDelete = rowId => {
    if (this.props.userRole && this.props.userRole !== 'Sales Manager') {
      let { formGroup } = this.props.rateCard;
      formGroup.splice(rowId, 1);
      this.props.handleInputChange(formGroup, "FORMGROUP");
    }
  };

  /**
   * This function will remove extra fields for additional pricing terms
   * @param {*} value value of the core ++ pricing Drpdown
   * @param {*} index index of the core ++ pricing Drpdown
   * */
  handleMapPricingTerms = (value, index) => {
    this.props.handleInputChange(value, "FormGroupCorePlus", index);
  };

  render() {
    const { addlListOptions } = this.state;
    const { rateCard, canEditPPR, dealInfo } = this.props;
    let isAPSubMixRequired = false;

    if (rateCard && rateCard.regionalUsageMixValues && rateCard.regionalUsageMixValues.length) {
      isAPSubMixRequired = getIsAPSubMixRequired(getUsageMixValue('AP', rateCard.regionalUsageMixValues[0].cells));
    }

    return (
      <div className="border-bottom-grey pb-4 pt-2 full-width">
        <Col span={24}>
          <div className="col-12 float-left p-0 pt-2">
            <h5 className="font-size18 amber-bold pt-4 col-11 p-0 float-left">
              Other Pricing Details
            </h5>
          </div>
        </Col>
        {(this.props.selectedDealType === "Downgrade" || this.props.selectedDealType === "Upgrade" || (this.props.selectedDealType === "Renewal" && this.props.renewalDealInfo === "Early Renewal")) && (
          <div className="col-12 float-left p-0 renewal-wraper">
            <Col span={24} className="mt-4">
              <p className="dark-black font-size14 mb-1 col-8 toggle-txt float-left p-0">
                <span className="float-left">Is the commit and/or term length increasing?</span> <span className="float-left">

                  <Popover content="Please respond with Yes or No"
                    preferredPosition="above" size="small" position="top">
                    <span className="float-left circle-alert">
                      {/* <img src={infoHelpIcon} alt="help" /> */}
                      <Icon name="status-info"></Icon>
                    </span>
                  </Popover>
                </span>
              </p>
              <span className="float-left">
                <AWSToggleButton
                  canEditPPR={canEditPPR}
                  options={Constants.Terms}
                  defaultValue={"No"}
                  userRole={this.props.userRole}
                  value={rateCard.commitOrTerm}
                  isStandalone={true}
                  onChange={value => {
                    this.handleChange(value, "commitOrTerm");
                  }}
                />
              </span>
              <span className="float-right m-0">
                {/* <CommentSection
                  infoDescription={Constants.infoText.approval_price}
                /> */}
              </span>
            </Col>
            {rateCard.commitOrTerm === "Yes" && (
              <Col span={24} className="mt-2">
                <div className="col-8 float-left p-0">
                  <div className="float-left">
                    <p className="dark-black font-size14 mt-2 pr-2 float-left pl-0">
                      <div className="float-left">Please Explain</div>

                      <Popover content={Constants.commitHelpText[0]["pleaseexplain"]}
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">
                          {/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon>
                        </span>
                      </Popover>
                    </p>
                  </div>
                </div>
                <div className="col-11 p-0 float-left">
                  <span className="col-8 float-left p-0">
                    <AWStextarea
                      canEditPPR={canEditPPR}
                      value={rateCard.commitTermText}
                      triggeredNext={this.props.triggeredNext}
                      userRole={this.props.userRole}
                      onChangeFunc={value => {
                        this.handleChange(value, "commitTermText");
                      }}
                      validation={validation}
                      questionValue={questionValue}
                    />
                  </span>
                </div>
                <span className="float-right mt-5">
                  {/* <CommentSection
                    infoDescription={Constants.infoText.Please_explain}
                  /> */}
                </span>
              </Col>
            )}
            {rateCard.TypeOfCFRL === "Bucket" && (
              <Col span={24} className="mt-2">
                <div className="col-8 float-left p-0">
                  <div className="float-left">
                    <p className="dark-black font-size14 mt-2 pr-2 float-left pl-0">
                      <div className="float-left">How much of the bucket commit has the customer used on the current agreement?</div>

                      <Popover content={Constants.commitHelpText[0]["pleaseexplain"]}
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">
                          {/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon>
                        </span>
                      </Popover>
                    </p>
                  </div>
                </div>
                <div className="col-11 p-0 float-left">
                  <span className="col-8 float-left p-0">
                    <AWStextarea
                      canEditPPR={canEditPPR}
                      value={rateCard.existCommitTermText}
                      triggeredNext={this.props.triggeredNext}
                      questionValue={questionValue}
                      userRole={this.props.userRole}
                      validation={validation}
                      onChangeFunc={value => {
                        this.handleChange(value, "existCommitTermText");
                      }}
                    />
                  </span>
                </div>
                <span className="float-right mt-5">
                  {/* <CommentSection
                    infoDescription={Constants.infoText.Please_explain}
                  /> */}
                </span>
              </Col>
            )}
          </div>
        )}
        <Col span={24} className="mt-4 pt-0">
          <p className="dark-black font-size14 mb-1 col-8 toggle-txt float-left p-0 ">
            <div className="float-left">
              If approved at the floor price, will this win the deal?
            </div>

            <Popover content={Constants.commitHelpText[0]["ifapproved"]}
              preferredPosition="above" size="small" position="top">
              <span className="float-left circle-alert">
                {/* <img src={infoHelpIcon} alt="help" /> */}
                <Icon name="status-info"></Icon>
              </span>
            </Popover>
          </p>

          <span className="float-left">
            <AWSToggleButton
              canEditPPR={canEditPPR}
              options={Constants.deal}
              defaultValue={"Yes"}
              value={rateCard.winDeal}
              userRole={this.props.userRole}
              isStandalone={true}
              onChange={value => {
                this.handleChange(value, "winDeal");
              }}
            />
          </span>
          <span className="float-right m-0">
            {/* <CommentSection
              infoDescription={Constants.infoText.approval_price}
            /> */}
          </span>
        </Col>
        {rateCard.winDeal === "No" && (
          <Col span={24} className="mt-2">
            <div className="col-8 float-left p-0">
              <div className="float-left">
                <p className="dark-black font-size14 mt-2 pr-2 float-left pl-0">
                  <div className="float-left">Please Explain</div>

                  <Popover content={Constants.commitHelpText[0]["pleaseexplain"]}
                    preferredPosition="above" size="small" position="top">
                    <span className="float-left circle-alert">
                      {/* <img src={infoHelpIcon} alt="help" /> */}
                      <Icon name="status-info"></Icon>
                    </span>
                  </Popover>
                </p>
              </div>
            </div>
            <div className="col-11 p-0 float-left">
              <span className="col-8 float-left p-0">
                <AWStextarea
                  canEditPPR={canEditPPR}
                  value={rateCard.explainWindeal}
                  userRole={this.props.userRole}
                  onChangeFunc={value => {
                    this.handleChange(value, "explainWindeal");
                  }}
                  triggeredNext={this.props.triggeredNext}
                  validation={validation}
                  questionValue={questionValue}
                />
              </span>
            </div>
            <span className="float-right mt-5">
              {/* <CommentSection
                infoDescription={Constants.infoText.Please_explain}
              /> */}
            </span>
          </Col>
        )}
        <Col span={24} className="mt-4 pt-0">
          <p className="dark-black font-size14 mb-2 col-8 toggle-txt float-left p-0">
            <span className="float-left">
              Is there any projected incremental S3 storage? (Optional)
            </span>

            <Popover content="Please explain"
              preferredPosition="above" size="small" position="top">

              <span className="float-left circle-alert">
                {/* <img src={infoHelpIcon} alt="help" /> */}
                <Icon name="status-info"></Icon>
              </span>
            </Popover>
          </p>
          <div className="col-11 p-0 float-left">
            <span className="col-8 float-left p-0">
              <AWStextarea
                canEditPPR={canEditPPR}
                value={rateCard.projected_storage}
                userRole={this.props.userRole}
                onChangeFunc={value => {
                  this.handleChange(value, "projected_storage");
                }}
              />
            </span>
          </div>
        </Col>
        <Col span={24} className="mt-4 pt-0">
          <p className="dark-black font-size14 mb-2 col-8 toggle-txt float-left p-0">
            What is sub region mix in AP &ndash; SIN, HK, KR, CH, PH, TW, KUL?
              {getIsCloudFrontShieldAdvancedDeal(dealInfo) ? ` (Note: KR distribution must be less than 10% to qualify for the CF+SA Combined RC. Any request with KR usage mix greater than 10% or where we are unable to confirm KR usage will be considered unqualified and separate service-specific PPR's and commitments will be necessary to receive private pricing)` : null }
              {!isAPSubMixRequired ? ` (Optional)` : null}

            <Popover content="Please explain"
              preferredPosition="above" size="small" position="top">

              <span className="float-left circle-alert">
                {/* <img src={infoHelpIcon} alt="help" /> */}
                <Icon name="status-info"></Icon>
              </span>
            </Popover>
          </p>
          <div className="col-11 p-0 float-left">
            <span className="col-8 float-left p-0">
                <AWStextarea
                  canEditPPR={canEditPPR}
                  value={rateCard.sub_region_mix}
                  userRole={this.props.userRole}
                  validation={isAPSubMixRequired ? validation : undefined}
                  questionValue={isAPSubMixRequired ? questionValue : undefined}
                  triggeredNext={this.props.triggeredNext}
                  onChangeFunc={value => {
                    this.handleChange(value, "sub_region_mix");
                  }}
                />
            </span>
          </div>
        </Col>
        <Col span={24} className="mt-4 pt-0">
          <p className="dark-black font-size14 mb-4 col-8 toggle-txt float-left p-0 d-flex">
            <span className="float-left">
              Are there any additional pricing terms?
            </span>

            <Popover content="Please respond with Yes or No"
              preferredPosition="above" size="small" position="top">

              <span className="float-left circle-alert">
                {/* {/* <img src={infoHelpIcon} alt="help" /> */}
                <Icon name="status-info"></Icon>
              </span>
            </Popover>
            {this.props.userRole === roleNames.SALES_REPRESENTATIVE && <span className="pl-2 pr-2 pt-0 mt-0 cursor-pointer other-pricing-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192263", "_blank")}>
              {/* <img src={Video} alt="home" className="pricing-detail deal-detail video-icon ml-2" onClick={() => window.open("https://broadcast.amazon.com/videos/192263", "_blank")} /> */}
              {/* <Icon name="caret-right-filled" ></Icon> */}
              <VideoIcon />
              <span className="other-pricing-tooltip">Additional Deal and Pricing terms</span>
            </span>}

          </p>

          <span className="float-left">
            <AWSToggleButton
              canEditPPR={canEditPPR}
              options={Constants.Terms}
              defaultValue={"No"}
              value={rateCard.pricingTerms}
              userRole={this.props.userRole}
              isStandalone={true}
              onChange={value => {
                this.handleChange(value, "pricingTerms");
              }}
            />
          </span>
          <span className="float-right m-0">
            {/* <CommentSection
              infoDescription={Constants.infoText.approval_price}
            /> */}
          </span>
        </Col>
        {rateCard.pricingTerms === "Yes" && (
          <>
            <Col span={24} className="mt-1 pb-1">
              <p className="grey font-size14 mb-3 full-width">
                <span className="float-left"> Please Specify</span>

                <Popover content="For each request, provide a justification"
                  preferredPosition="above" size="small" position="top">
                  <span className="float-left circle-alert">
                    {/* <img src={infoHelpIcon} alt="help" /> */}
                    <Icon name="status-info"></Icon>
                  </span>
                </Popover>
              </p>
              <p
                className={`grey font-size14 mb-3 p-0 float-left ${this.props.userRole === roleNames.SCE_OWNER ||
                  this.props.userRole === roleNames.IGT_OWNER ||
                  this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                  this.props.userRole === roleNames.PRIVATE_PRICING_CHINA
                  ? "col-3"
                  : "col-4"
                  }`}
              >
                Request
              </p>
              <p className="grey font-size14 mb-3 col-3 pl-4 float-left mr-5">
                Justification
              </p>
              {(this.props.userRole === roleNames.SCE_OWNER ||
                this.props.userRole === roleNames.IGT_OWNER ||
                this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                this.props.userRole === roleNames.PRIVATE_PRICING_CHINA) && (
                  <>
                    <p className="grey font-size14 mb-3 col-3 float-left ml-2 additional-termtext pl-0">
                      <span className="float-left">
                        Map Additional Pricing Terms
                      </span>
                      <span className="float-left">

                        <Popover content={Constants.commitHelpText[0]["Typeofcloudfront"]}
                          preferredPosition="above" size="small" position="top">
                          <span className="float-left circle-alert">
                            {" "}
                            {/* <img src={infoHelpIcon} alt="help" /> */}
                            <Icon name="status-info"></Icon>
                          </span>
                        </Popover>
                      </span>
                    </p>
                  </>
                )}
            </Col>
            <Col span={24} className="pb-1">
              {rateCard.formGroup.map((row, rowIndex) => {
                let validation = [
                  {
                    message: "This is a mandatory field",
                    type: "Required"
                  }
                ];
                let rowLength = rateCard.formGroup.length - 1;
                return (
                  <Col span={24} className=" pb-3" key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => {
                      let questionValue = {
                        isVisible: true,
                        isMandatory: true,
                      };
                      return (
                        <Col
                          span={`${this.props.userRole === roleNames.SCE_OWNER ||
                            this.props.userRole === roleNames.IGT_OWNER ||
                            this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                            this.props.userRole === roleNames.PRIVATE_PRICING_CHINA
                            ? "6"
                            : "8"
                            }`}
                          className="mr-4 pricing-textarea"
                          key={cellIndex}
                        >
                          <AWStextarea
                            questionValue={questionValue}
                            validation={validation}
                            canEditPPR={canEditPPR}
                            userRole={this.props.userRole}
                            value={cell.value}
                            triggeredNext={this.props.triggeredNext}
                            onChangeFunc={value => {
                              this.handleChange(
                                value,
                                "FORMGROUP",
                                rowIndex,
                                cellIndex
                              );
                            }}
                          />
                        </Col>
                      );
                    })}

                    {(this.props.userRole === roleNames.SCE_OWNER ||
                      this.props.userRole === roleNames.IGT_OWNER ||
                      this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                      this.props.userRole === roleNames.PRIVATE_PRICING_CHINA) && (
                        <div className="col-3 float-left p-0 ml-0 pl-1 pt-2 additionterm-dropdown">
                          <AWSDropdown
                            id="additionalTerm"
                            canEditPPR={canEditPPR}
                            options={addlListOptions}
                            value={row.corePlusOption}
                            handleOnSelect={value =>
                              this.handleMapPricingTerms(value, rowIndex)
                            }
                          />
                        </div>
                      )}
                    <div className="minusplus-wraper ml-3 float-left">
                      {rowLength !== 0 ? (
                        <span className="float-left minus-icon mt-0 mr-3 pt-2 pricing-plusminus">
                          <img
                            src={Minusicon}
                            alt="minus"
                            onClick={() => {
                              this.handleOnDelete(rowIndex);
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {rowLength === rowIndex && (
                        <Col className="float-left pt-2" key={rowIndex}>
                          <span
                            className={
                              rowIndex === 0
                                ? "mt-0 float-left pricing-plusminus"
                                : "mt-0 mr-3 float-left pricing-plusminus"
                            }
                          >
                            <img
                              src={Plusicon}
                              alt="plusicon"
                              onClick={() => {
                                canEditPPR === true ?
                                  this.handleOnAdd() : null
                              }}
                            />
                          </span>
                        </Col>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Col>
          </>
        )}
      </div>
    );
  }
}

export default DealDetails;

DealDetails.propTypes = {
  rateCard: PropTypes.any,
  handleInputChange: PropTypes.func,
  triggeredNext: PropTypes.bool,
  selectedDealType: PropTypes.any,
  userRole: PropTypes.any,
  dealInfo: PropTypes.any
};
