import React from "react";
import axios from 'axios';
import { Input, Textarea } from "@amzn/awsui-components-react";
import './EditNotification.scss';
import AWSbutton from "../../common/AWSbutton/AWSbutton";
// import AWSInput from '../../common/AWSInput/AWSinput';
import AWSConfig from '../../../config/AWSconfig';
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import PropTypes from "prop-types";

const config = new AWSConfig();

class EditNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editNotificationData: null,
            activateToast: false,
            loadingMessage: '',
            subject: ''
        }
    }


    componentDidMount() {
        const { emailData } = this.props;
        this.setState({ editNotificationData: emailData });
    }

    handleChangeNotification = ({ detail: { value } }, type) => {
        const { editNotificationData } = this.state;
        if (editNotificationData !== null && editNotificationData !== undefined) {
            if (type === 'subject') {
                editNotificationData.subject = value;
            } else if (type === 'body') {
                editNotificationData.body = value;
            }
        }
        this.setState({ editNotificationData });
    }

    updateNotification = () => {
        this.showUserManagementLoadingToast('Updating Email Notification Template....', true);
        const { editNotificationData } = this.state;
        const updateNotificationUrl = `${config.userManagementbaseUrl}users/updateses`;
        axios({
            method: "POST",
            url: updateNotificationUrl,
            data: editNotificationData
        }).then(resp => {
            if (resp.data.responseCode === 200) {
                this.showUserManagementLoadingToast('Updating Email Notification Template....', false);
                this.showUserManagementLoadingToast('Email Notification Updated Successfully...', true);
                this.props.handleOk('editNotification');
            }
        }).catch(() => {
            this.showUserManagementLoadingToast('Failed to update Email Notification due to Technical Issue...', true);
            // console.log('err', error);
        });
    }

    handleOk = () => {
        this.updateNotification();
    }

    /*
    *show loading toast when new ADT or APT is added
    */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    render() {
        const { editNotificationData, loadingMessage, activateToast } = this.state;
        return (
            <div className="editnotification-wraper">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        Edit Notification
                        <span className="float-right cursor-pointer pr-2 pt-1" id="close" onClick={() => this.props.handleCancel()}>
                            {/* <Icon source={MobileCancelMajorMonotone} /> */}
                        </span></p>
                </div>
                <div className="body-wraper full-width p-4">
                    <div className="full-width">
                        <div className="col-6 float-left">
                            <p className="dark-black mb-1">Subject</p>
                            <div className="full-width">
                                <Input
                                    value={editNotificationData && editNotificationData.subject}
                                    onInput={(event) => this.handleChangeNotification(event, 'subject')}
                                />
                            </div>
                        </div>
                        <div className="col-6 float-left">
                            <p className="dark-black mb-1">User Role</p>
                            <div className="full-width">
                                <Input
                                    value={editNotificationData && editNotificationData.userRole}
                                    disabled={true}
                                    onChangeFunc={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="full-width pt-4">
                        <div className="col-6 float-left">
                            <p className="dark-black mb-1">Deal Status</p>
                            <div className="full-width">
                                <Input
                                    value={editNotificationData && editNotificationData.dealStatus}
                                    disabled={true}
                                    onChangeFunc={() => { }}
                                />
                            </div>
                        </div>
                        <div className="col-6 float-left">
                            <p className="dark-black mb-1">Type</p>
                            <div className="full-width">
                                <Input
                                    value={editNotificationData && editNotificationData.type}
                                    disabled={true}
                                    onChangeFunc={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="full-width pt-4">
                        <div className="col-12 float-left">
                            <p className="dark-black mb-1">Body</p>
                            <Textarea
                                value={editNotificationData && editNotificationData.body}
                                onInput={(event) => this.handleChangeNotification(event, 'body')}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    {/* <span className="float-left pt-4 mt-2 pl-4">Created By <span className="dark-blue"></span></span> */}
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3" id="canceBtn">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Update"
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                        </div>
                        {activateToast && (
                            <AWSLoadingToast
                                message={loadingMessage}
                            // error={this.state.loadingError}
                            // duration={this.state.loadingMessageDuration}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }

}
export default EditNotification;
EditNotification.propTypes = {
    emailData: PropTypes.any,
    handleCancel: PropTypes.func,
    handleOk : PropTypes.func
};