import React from 'react';
import './watchilst.scss';
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSbutton from '../../common/AWSbutton/AWSbutton';
import AWSConfig from '../../../config/AWSconfig';
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import * as additionalTerm from '../../../constants/additionalTerm.const';
import { getFormattedDate } from '../../../utils/dates';
import axios from "axios";
import PropTypes from "prop-types";
const config = new AWSConfig();

class EditNewAdditionalList extends React.Component {
    state = {
        titleName: null,
        adddlList: [],
        additionalTerm: '',
        activateToast: false,
        loadingMessage: ''
    }

    componentDidMount() {
        const { editData } = this.props;
        this.setState({ additionalTerm: editData.cfValue });
    }

    handleChangeAdditionalList = (value) => {
        this.setState({
            additionalTerm: value,
        })
    }

    updateAdditionalTerm = (reqJson) => {
        this.showUserManagementLoadingToast(additionalTerm.UPDATING_MESSAGE, true);
        const updateAdditionalTermUrl = `${config.userManagementbaseUrl}users/updateTerms`;
        axios({
            method: "POST",
            url: updateAdditionalTermUrl,
            data: reqJson
        }).then(resp => {
            if (resp.data.responseCode === 200)
                this.showUserManagementLoadingToast(additionalTerm.UPDATE_MESSAGE, true);
            this.props.handleOk('editNewAdditionalList');
        }).catch((error) => {
           console.log('err', error.response);
        });
    }


    handleOk = () => {
        const { editData } = this.props;
        const { additionalTerm } = this.state;
        const reqJson = {
            'configPartitionKey': editData.configPartitionKey,
            'cfValue': additionalTerm,
            'configSortKey': editData.configSortKey
        }
        this.updateAdditionalTerm(reqJson);
    }

    /*
    *show loading toast when new ADT or APT is added
    */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    render() {
        const { addlistOption, location } = this.props;
        const { additionalTerm, activateToast } = this.state;
        const { state: { firstName, lastName } } = location;

        return (
            <div className="full-width watchlist-popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        Additional List
                    <span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                        </span>
                    </p>
                </div>
                <div className="col-12 float-right p-3 pt-4 pb-5">
                    <div className="col-6 float-left mr-5">
                        <div className="col-10 float-left pl-0 pt-4">
                            <p className="amber-bold mb-1">{addlistOption === 'ADT' ? 'Additional Deal Term' : 'Additional Pricing Term'}</p>
                        </div>
                        <div className="col-10 float-left pl-0 pr-3">
                            <AWSInput
                                value={additionalTerm}
                                onChangeFunc={(value) => { this.handleChangeAdditionalList(value) }}
                            /></div>

                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <span className="float-left pt-4 pl-4 mt-2">Created By <span className="dark-blue">{firstName && `${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-0 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Update"
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                            {activateToast && (
                                <AWSLoadingToast
                                    message={this.state.loadingMessage}
                                    error={this.state.loadingError}
                                    duration={this.state.loadingMessageDuration}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditNewAdditionalList;
EditNewAdditionalList.propTypes = {
    location: PropTypes.any,
    addlistOption: PropTypes.any,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
    editData: PropTypes.any
};