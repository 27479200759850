import React from 'react';
import { Icon } from "@amzn/awsui-components-react";
import axios from 'axios';
import AWSDropdown from "../../common/AWSDropdown/AWSDropdown";
import AWSAdminTable from "../AWSAdminTable/AWSAdminTable";
import AWSConfig from '../../../config/AWSconfig';
import AWSModal from '../AWSModal/AWSModal';
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import * as additionalTerm from '../../../constants/additionalTerm.const';
import './watchilst.scss';
import AWSAdminTableEditIcon from "../../common/AWSAdminTableIcon/AWSAdminTableEditIcon";
import AWSAdminTableDeleteIcon from "../../common/AWSAdminTableIcon/AWSAdminTableDeleteIcon";
import * as ConfigurationConstant from "../AWSAdminTable/AdminTableConstants/ABTConfConstants";
import PropTypes from "prop-types";
const config = new AWSConfig();

const additionalListOption = [
    {
        optionValue: 'Additional Deal Term',
        optionKey: 'ADT',
        defaultValue: null,
    },
    {
        optionValue: 'Additional Pricing Term',
        optionKey: 'APT',
        defaultValue: null,
    },
]

class AdditionalList extends React.Component {
    state = {
        value: "",
        tableData: [],
        modalType: '',
        visible: false,
        dropdownVal: '',
        errMessage: '',
        activateToast: false,
        loadingMessage: '',
        editData: null,
        triggerList: false
    }

    componentDidMount() {
        const reqParam = 'getADT';
        this.getAllAdditionalDealTerms(reqParam);
    }

    /*
    *call ADT api to get all ADT
    */
    getAllAdditionalDealTerms = (reqParam) => {
        const adtApi = `${config.userManagementbaseUrl}users/${reqParam}`;
        axios({
            method: "GET",
            url: adtApi
        }).then(resp => {
            this.setState({ tableData: resp.data.Data });
        }).catch((err) => {
            const Data = err?.response?.data?.Data;
            if (Data) {
                this.setState({errMessage: Data})
            } else {
                console.log(err);
            }
        });
    }

    /*show modal */
    showModal = (modalType) => {
        this.setState({
            visible: true,
            modalType: modalType
        });
    };

    /**
     * show Edit Modal and fill table data
    */
    showEditModal = (e, cellData) => this.setState({ visible: true, modalType: 'editNewAdditionalList', editData: cellData });


    /**
     * This method will delete the ABT or ADT based on selection
    */
    deleteAdditionalList = (e, cellData) => {

        const reqParam = `get${cellData.configPartitionKey}`;
        this.showUserManagementLoadingToast(additionalTerm.DELETING_ADDLTERM_MESSAGE, true);
        const deleteAdditionalTermUrl = `${config.userManagementbaseUrl}users/deleteTerms`;
        axios({
            method: "POST",
            url: deleteAdditionalTermUrl,
            data: cellData
        }).then(resp => {
            if (resp.data.responseCode === 200) {

                this.showUserManagementLoadingToast(additionalTerm.DELETE_MESSAGE, true);
                this.getAllAdditionalDealTerms(reqParam)
            }
        }).catch((error) => {
            console.log('err', error.response);
        });
    }

    /*
    *show loading toast when new ADT or APT is added
    */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    /*hide the modal*/
    handleCancel = () => this.setState({ visible: false });

    handleOk = () => {
        const { dropdownVal } = this.state;
        const reqParam = `get${dropdownVal}`;
        this.setState({ visible: false });
        this.getAllAdditionalDealTerms(reqParam);
    }

    handleAdditionalListChange = (value) => {

        const reqParam = `get${value}`
        this.getAllAdditionalDealTerms(reqParam);
        this.setState({ dropdownVal: value });
    }

    render() {
        const { dropdownVal, tableData, modalType, visible, activateToast, editData } = this.state;
        const { location } = this.props;
        let userColumns = [
            { header: 'List', id: "cfValue", minWidth: "300px", cell: item => <div>{item.cfValue}</div> },
            {
                header: "Actions",
                id: "action",
                minWidth: "80px",
                cell: item => <div className="float-left col-12 p-0">
                    <div className="col-6 float-left p-0" onClick={(e) => this.showEditModal(e, item)}>
                        <AWSAdminTableEditIcon
                        />
                    </div>
                    <div className="col-6 float-left p-0" onClick={(e) => this.deleteAdditionalList(e, item)}>
                        <AWSAdminTableDeleteIcon />
                    </div>
                </div>,
            }
        ];
        return (
            <div>
                <div className="full-width">
                    <div className="col-6 float-left pl-0">
                        <div className="col-6 float-left p-0">
                            {<AWSDropdown
                                id="Dropdown"
                                label="Additional Lists"
                                options={additionalListOption}
                                value={dropdownVal}
                                defaultValue='ADT'
                                handleOnSelect={(value) => this.handleAdditionalListChange(value)}
                            />}
                        </div>

                    </div>

                    <div className="col-6 float-left pr-0">
                        <div className="float-right">
                            <div className="width-auto float-left cursor-pointer" onClick={() => { this.showModal('createNewAdditionalList') }}>
                                <p><span className="plus-icon float-left pr-2 close-icon pt-1">
                                    <Icon name="add-plus"></Icon>
                                </span> Additional list</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width watchlist-table pt-4">
                    <AWSAdminTable
                        columns={userColumns}
                        tableData={tableData}
                        sortableColumnOptions={ConfigurationConstant.cfValue}
                        showFilter={true}
                    />
                </div>
                <div className="full-width">
                    <AWSModal
                        type={modalType}
                        visible={visible}
                        handleCancel={() => this.handleCancel()}
                        handleOk={(modalType) => this.handleOk(modalType)}
                        addlistOption={dropdownVal}
                        editData={editData}
                        location={location}
                    />
                </div>
                {activateToast && (
                    <AWSLoadingToast
                        message={this.state.loadingMessage}
                        error={this.state.loadingError}
                        duration={this.state.loadingMessageDuration}
                    />
                )}
            </div>
        );
    }
}
export default AdditionalList;
AdditionalList.propTypes = {
    location: PropTypes.any
}