import React, { Component } from 'react';
import './AWSModal.scss';
import AddQuestion from './AddQuestion';
import AddNewUser from '../AWSUsers/AddnewUser';
import AddNewRole from '../AWSUsers/AddNewRole';
import EditSection from './EditSection';
import AddNewSection from './AddNewSection';
import DeleteUser from '../AWSUsers/DeleteUser';
import ConfigurationPopup from '../RateCardMangement/ConfigurationPopup';
import EditQuestion from './EditQuestion';
import EditPageHeader from './EditPageHeader';
import AddNewDealStatus from '../DealWorkflow/AddNewDealStatus';
import CreateNewWatchList from '../Configurations/CreateNewWatchlist';
import CreateNewAdditionalList from '../Configurations/CreateNewAdditionalList';
import EditNewAdditionalList from '../Configurations/EditNewAdditionalList';
import EditNotification from '../AWSMngNotification/EditNotification';
import EditSlaNotification from '../AWSMngNotification/EditSlaNotification';
import { Modal } from '@amzn/awsui-components-react';


class AWSModal extends Component {

    /*add question popup for ppr */
    addQuestionPPR = () => {
        return (
            <div className="addques-wraper">
                <AddQuestion
                    {...this.props}
                    sectionIndex={this.props.sectionIndex}
                    dataModel={this.props.dataModel}
                    type={this.props.type}
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(questionJson, id) => this.props.handleOk("addQuestionPPR", questionJson, id)}
                    location={this.props.location} //passing the userinfo binded in location prop
                />
            </div>
        )
    }


    /*edit question */
    editQuestionPPR = () => {
        let { type, selectedStage, sectionIndex, dataModel, dataColumn } = this.props;
        delete dataColumn['templateId'];

        let newEditJsonWithIDs = {
            stageId: dataModel[selectedStage].templateStageId,
            queBlock: [{
                templateId: dataModel[selectedStage].templateStageId + "_" + dataModel[this.props.selectedStage].queBlock[sectionIndex].queBlkId,
                queBlkId: dataModel[this.props.selectedStage].queBlock[sectionIndex].queBlkId,
                questions: [dataColumn]
            }]
        }

        let clonedNewEditJsonWithIDs = JSON.parse(JSON.stringify(newEditJsonWithIDs));

        // this.state.questionJsonNew.stageId = dataModel[selectedStage].templateStageId;
        //     this.state.questionJsonNew.queBlock[0].queBlkId = dataModel[this.props.selectedStage].queBlock[sectionIndex].queBlkId;
        //     this.state.questionJsonNew.queBlock[0].questions[0].rowNum = dataModel[this.props.selectedStage].queBlock[sectionIndex].questions.length + 1;
        return <div className='addques-wraper edit-section-popup'>
            <EditQuestion
                {...this.props}
                dataColumn={clonedNewEditJsonWithIDs}
                dataModel={this.props.dataModel}
                sectionIndex={this.props.sectionIndex}
                type={type}
                handleCancel={() => { this.props.handleCancel() }}
                handleOk={(questionJson, id) => this.props.handleOk("editQuestionPPR", questionJson, id)}
                location={this.props.location}
            />
        </div>
    }

    handleOk = (type) => {
        this.props.handleOk(type);
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    handleOnSelect = (name, value) => {
        this.props.handleOnSelect(name, value);
    }

    onChangeFunc = (name, value) => {
        this.props.onChangeFunc(name, value);
    }

    /*edit title*/
    editSection = () => {
        return (
            <div className="editsection-popup-wraper">
                <EditSection
                    {...this.props}
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(sectionJson) => this.props.handleOk("editSection", sectionJson)}
                    handleDelete={(sectionJson) => this.props.handleDelete("deleteSection", sectionJson)}
                // handleChangeSectionModal={(type, value) => this.props.handleChangeSectionModal(type, value)}
                />
            </div>
        )
    }

    /*add new section*/
    addNewSection = () => {
        return (
            <div className="addsection-popup-wraper">
                <AddNewSection
                    type="addNewSection"
                    handleOk={(jsonValue) => { this.props.handleOk("addNewSection", jsonValue) }}
                    handleCancel={() => { this.handleCancel() }}
                    location={this.props.location}
                />
            </div>
        )
    }

    /*edit page header editPageHeader */
    editPageHeader = () => {
        return (
            <div className="addsection-popup-wraper">
                <EditPageHeader
                    {...this.props}
                    type="editMenu"
                    handleOk={(editHeaderJson) => { this.props.handleOk('editPageHeader', editHeaderJson) }}
                    handleCancel={() => { this.props.handleCancel() }}
                    handleDelete={(editHeaderJson) => this.props.handleDelete('deletePageHeader', editHeaderJson)}
                    dataModel={this.props.dataModel}
                    location={this.props.location}
                />
            </div>
        )
    }
    /*add role popup*/
    addRole = (type) => {
        return (
            <div className="addrole-popup-wraper">
                <AddNewRole
                    type={type}
                    Roleoptions={this.props.Roleoptions}
                    onChangeFunc={(name, value) => { this.onChangeFunc(name, value) }}
                    handleOnSelect={(name, value) => { this.handleOnSelect(name, value) }}
                    handleOk={(type) => { this.handleOk(type) }}
                    handleCancel={() => { this.handleCancel() }}
                    roleData={this.props.roleData}
                />
            </div>
        )
    }

    /* add new User*/
    newUser = (type) => {
        return (
            <div className="new-user popup">
                <AddNewUser
                    type={type}
                    Activeoptions={this.props.Activeoptions}
                    Roleoptions={this.props.Roleoptions}
                    onChangeFunc={(name, value) => { this.onChangeFunc(name, value) }}
                    handleOnSelect={(name, value) => { this.handleOnSelect(name, value) }}
                    handleOk={(type) => { this.handleOk(type) }}
                    handleCancel={() => { this.handleCancel() }}
                    userData={this.props.userData}
                />
            </div>
        )
    }

    /* delete new User*/
    deleteUser = (type) => {
        return (
            <div className="delete-user popup">
                <DeleteUser
                    type={type}
                    deleteObj={this.props.deleteObj}
                    handleOk={(type) => { this.handleOk(type) }}
                    handleCancel={() => { this.handleCancel() }}
                />
            </div>
        )
    }

    /*rate card configuration */
    rateCardConfiguration = () => {
        return (
            <div className="ratecard-config-popup">
                <ConfigurationPopup
                    handleCancel={() => { this.handleCancel() }}
                    showUserManagementLoadingToast={(message, activeState) => this.props.showUserManagementLoadingToast(message, activeState)}
                    setConfigurationChange={this.props.setConfigurationChange}
                />
            </div>
        )
    }
    /*add new deal status */
    newDealStatus = () => {
        return (
            <div className="dealstatus-popup-wraper">
                <AddNewDealStatus
                    handleCancel={() => { this.props.handleCancel() }}
                />
            </div>
        )
    }
    // create new Watchlist

    createNewWatchList = () => {
        return (
            <div className="dealstatus-popup-wraper">
                <CreateNewWatchList
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(type) => this.props.handleOk(type)}
                />
            </div>
        )
    }

    //create new additinal deal terms
    createNewAdditionalList = () => {
        return (
            <div className="dealstatus-popup-wraper">
                <CreateNewAdditionalList
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(type) => this.props.handleOk(type)}
                    addlistOption={this.props.addlistOption}
                    location={this.props.location}
                />
            </div>
        )

    }

    //Edit New Additional Deal terms List
    editNewAdditionalList = () => {
        return (
            <div className="dealstatus-popup-wraper">
                <EditNewAdditionalList
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(type, listKey) => this.props.handleOk(type, listKey)}
                    addlistOption={this.props.addlistOption}
                    editData={this.props.editData}
                    location={this.props.location}
                />
            </div>
        )

    }
    /*edit notification popup*/
    editNotification = () => {
        return (
            <div className="edit-notification-wraper">
                <EditNotification
                    emailData={this.props.emailData}
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(type) => this.props.handleOk(type)}
                />
            </div>
        )
    }

    /*edit notification popup*/
    editSlaNotification = () => {
        return (
            <div className="edit-notification-wraper">
                <EditSlaNotification
                    slaData={this.props.slaData}
                    handleCancel={() => { this.props.handleCancel() }}
                    handleOk={(type) => this.props.handleOk(type)}
                />
            </div>
        )
    }

    renderModal = () => {
        const { type } = this.props;
        switch (type) {
            case "addQuestionPPR": return this.addQuestionPPR("addQuestionPPR")
            case "editQuestionPPR": return this.editQuestionPPR("editQuestionPPR")
            case "editSection": return this.editSection()
            case "newRole": return this.addRole("newRole")
            case "updateRole": return this.addRole("updateRole")
            case "deleteRole": return this.deleteUser("deleteRole")
            case "newUser": return this.newUser("newUser")
            case "deleteUser": return this.deleteUser("deleteUser")
            case "updateUser": return this.newUser("updateUser")
            case "addSection": return this.addNewSection("addSection")
            case "rateCardConfig": return this.rateCardConfiguration("rateCardConfig")
            case "editMenu": return this.editPageHeader("editMenu")
            case "newDealStatus": return this.newDealStatus("newDealStatus")
            case "newWatchList": return this.createNewWatchList("newWatchList")
            case "createNewAdditionalList": return this.createNewAdditionalList("createNewAdditionalList")
            case 'editNewAdditionalList': return this.editNewAdditionalList('editNewAdditionalList');
            case 'editNotification': return this.editNotification("editNotification");
            case 'editSlaNotification': return this.editSlaNotification("editSlaNotification")
            default: return;
        }
    }

    render() {

        return (
            <Modal visible={this.props.visible} size="max" onDismiss={() => { this.props.handleCancel() }}>
                {this.renderModal()}
            </Modal>
        )
    }
}

export default AWSModal;
