import { isArray, isNil } from "lodash"
import { isAfterOrgChange } from "../utils/common"

//Question Label: Customer Type must be repurposed to a new question 
export const QUESTION_LABELS = {
    BusinessUnit: "Business Unit",
    CustomerType: "Customer Type",
    BusinessCustomerType: "Business Customer Type"
}

export const NEW_QUESTION_LABEL = {
    CustomerStatus: "AWS Customer Status",
    CustomerType: "Customer Type"
}

export const OLD_BUSINESS_UNIT_ANSWER_LABELS  = {
    CO: "Commercial Operations",
    PS: "Public Sector",
    China: "China"
}

export const NEW_BUSINESS_UNIT_ANSWER_LABELS  = {
    WWPS: "WW Public Sector",
    AGS: "AWS Global Sector",
    GCR: "Greater China Region"
}

export const NEW_GEO_CHINA = "GCR";
export const OLD_GEO_CHINA = "CHNA";

export const ACCOUNT_TYPE = {
    WWPS: "WWPS",
    GCR: "GCR",
    WWCS: "WWCS"
}

export const BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS = {
    PS: "Public Sector",
    CS: "Commercial Sector"
}

export const UPDATE_LABELS_OBJECT = {
    [QUESTION_LABELS.BusinessUnit]: {
        [OLD_BUSINESS_UNIT_ANSWER_LABELS.CO]: NEW_BUSINESS_UNIT_ANSWER_LABELS.AGS,
        [OLD_BUSINESS_UNIT_ANSWER_LABELS.PS]: NEW_BUSINESS_UNIT_ANSWER_LABELS.WWPS,
        [OLD_BUSINESS_UNIT_ANSWER_LABELS.China]: NEW_BUSINESS_UNIT_ANSWER_LABELS.GCR
    }
}

export const isCOBusinessUnit = (businessUnit) => {
    return businessUnit === OLD_BUSINESS_UNIT_ANSWER_LABELS.CO || businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.AGS;
}

export const isPSBusinessUnit = (businessUnit) => {
    return businessUnit === OLD_BUSINESS_UNIT_ANSWER_LABELS.PS || businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.WWPS;
}

export const isChinaBusinessUnit = (businessUnit) => {
    return businessUnit === OLD_BUSINESS_UNIT_ANSWER_LABELS.China || businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.GCR;
}

export const isChinaGeo = (geo) => {
    return geo === NEW_GEO_CHINA || geo === OLD_GEO_CHINA;
}

export const getBusinessUnitAnswerLabel = (businessUnit) => {
    if (isAfterOrgChange()) {
        return UPDATE_LABELS_OBJECT[QUESTION_LABELS.BusinessUnit][businessUnit] || businessUnit;
    }
    return businessUnit;
}

export const isNewBusinessUnitAnswerLabels = (businessUnit) => {
    return businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.GCR || businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.AGS || businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.WWPS;
}

export const shouldUpdateAGSVertical = (accountType, businessCustomerType) => {
    return isNil(businessCustomerType) ? accountType === ACCOUNT_TYPE.WWCS : accountType === ACCOUNT_TYPE.WWCS && BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.CS === businessCustomerType;
}

export const shouldUpdateGCRVertical = (accountType, businessCustomerType) => {
    return isNil(businessCustomerType) ? accountType === ACCOUNT_TYPE.GCR : accountType === ACCOUNT_TYPE.GCR && BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.CS === businessCustomerType;
}

export const shouldUpdatePSVertical = (accountType, businessCustomerType) => {
    return isNil(businessCustomerType) ? accountType === ACCOUNT_TYPE.WWPS : accountType === ACCOUNT_TYPE.WWPS || BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.PS === businessCustomerType;
}

export const getCustomerType = (accounts) => {
    if (isArray(accounts) && accounts.length > 0) {
        const customerType = accounts[0].customer_type;
        const accountsWithSameCustomerType = accounts.filter((account) => account.customer_type === customerType);
        return accountsWithSameCustomerType.length === accounts.length ? customerType : null;
    }
    return null;
}
