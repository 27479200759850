import React, { Component } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import { Icon } from "@amzn/awsui-components-react";

import AWSButtonRadio from "../../common/AWSButtonRadio/AWSButtonRadio";
import AWSDropdown from "../../common/AWSDropdown/AWSDropdown";
import RequestFees from "./RequestFees"
import GlobalLimits from './GlobalLimits';
import DataTransfer from './DataTransfer';
import saveIcon from '../../../images/save_black.svg';
import './RateCardManagement.scss';
import AWSConfig from '../../../config/AWSconfig';
import AWSModal from "../AWSModal/AWSModal";
import { RateCardKeys, RateCardLabels } from '../../../constants/rateCardConstants';

const config = new AWSConfig();
const TYPE_OF_COMMIT_DROPDOWN_OPTIONS = [
    {
        optionValue: "Volume Commit",
        optionKey: "Volume Commit",
        defaultValue: null,
        displayForCFSA: true
    },
    {
        optionValue: "Commit Fee",
        optionKey: "Commit Fee",
        defaultValue: null,
        displayForCFSA: false
    },
];
const RATE_CARD_TYPES = [
    {
        optionKey: RateCardLabels.RateCard1,
        optionValue: RateCardLabels.RateCard1
    },
    {
        optionKey: RateCardLabels.RateCard2,
        optionValue: RateCardLabels.RateCard2
    },
    {
        optionKey: RateCardLabels.CfsaSmbRateCard,
        optionValue: RateCardLabels.CfsaSmbRateCard,
    },
    {
        optionKey: RateCardLabels.CfsaEnterpriseRateCard,
        optionValue: RateCardLabels.CfsaEnterpriseRateCard,
    }
];
let aosOptions = [];
let termLengthOptions = [];

export const getRateCardKey = (rateCard) => {
    switch(rateCard) {
        case RateCardLabels.RateCard1:
            return RateCardKeys.RateCard1;
        case RateCardLabels.RateCard2:
            return RateCardKeys.RateCard2;
        case RateCardLabels.CfsaSmbRateCard:
            return RateCardKeys.CfsaSmbRateCard;
        case RateCardLabels.CfsaEnterpriseRateCard:
            return RateCardKeys.CfsaEnterpriseRateCard;
        default:
            return RateCardKeys.RateCard1;
    }
}

class RateCardManagement extends Component {

    state = {
        aosSizeSelectedOption: "15",
        termLength: "12",
        rateCardButtonOption: RateCardLabels.RateCard1,
        typeOfCommit: "Volume Commit",
        tableEditedValues: [],
        edited: false,
        editableCellTable: false,
        isModalVisible: false,
        loadingMessage: "",
        modalType: "",
        configurationChange: false,
        currentTypeOfCommitDropdownOptions: TYPE_OF_COMMIT_DROPDOWN_OPTIONS
    }

    /**
    * This lifecycle method is calling API to get the configuration values values of rate card 1
    */
    componentDidMount() {
        this.getRateCardconfigApiCall();
    }

    /**
     * This function call backend api to get the ratecard configuration of in admin module
     */
    getRateCardconfigApiCall = (rateCard = RateCardKeys.RateCard1) => {
        this.showUserManagementLoadingToast("Loading RateCard configuration ...", true);
        let configListApi = `${config.adminrateCardbaseUrl}commitPricingVals?type=${rateCard}`;
        axios({
            method: "GET",
            url: configListApi
        })
            .then(resp => {
                aosOptions = resp.data.AOS.map(obj => ({
                    optionValue: obj,
                    optionKey: obj
                }))

                termLengthOptions = resp.data.TermLength && resp.data.TermLength.map(obj => ({
                    optionValue: obj && obj.toString(),
                    optionKey: obj && obj.toString()
                }))



                this.setState({
                    VolumeCommit: resp.data.VolumeCommit,
                    CommitFee: resp.data.CommitFee,
                    Regions: resp.data.Regions,
                    termLength: termLengthOptions[0].optionValue,
                    aosSizeSelectedOption: aosOptions[0].optionValue
                })
                this.showUserManagementLoadingToast("Loading configuration ...", false);
            })
            .catch(() => {
                this.showUserManagementLoadingToast("Failed Loading configuration ...", false);
            })
    }

    /**
     * This function is used to store the edited values in the rate card table
     * @param {*} data value of the entire cell which is edited
    */
    saveEditedTableValues = (val, keyOfVolCommitData, data) => {

        if (this.state.typeOfCommit === "Volume Commit") {
            let obj = {
                "geo": keyOfVolCommitData,
                "commit": data.MonthlyCommit,
                "editedprice": parseFloat(val)
            }

            if(this.state.tableEditedValues.filter(item=> item.geo === keyOfVolCommitData && item.commit === data.MonthlyCommit).length==0){
                this.state.tableEditedValues.push(obj);
            }
            else{
                this.state.tableEditedValues.filter(item=> item.geo === keyOfVolCommitData && item.commit === data.MonthlyCommit)[0].editedprice = parseFloat(val);
            }

            this.setState({
                edited: true
            })

        } else {
            let obj = {
                "geo": keyOfVolCommitData,
                "commit": data.UpfrontFee,
                "editedprice": parseFloat(val)
            }

            if(this.state.tableEditedValues.filter(item=> item.geo === keyOfVolCommitData && item.commit === data.UpfrontFee).length==0){
                this.state.tableEditedValues.push(obj);
            }
            else{
                this.state.tableEditedValues.filter(item=> item.geo === keyOfVolCommitData && item.commit === data.UpfrontFee)[0].editedprice = parseFloat(val);
            }
            
            //this.state.tableEditedValues.push(obj);
            this.setState({
                edited: true
            })

        }

    }

    /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
     */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    /**
     * This function is used to set the local state for AOS selected
     * @param {*} value value of the particular AOS to set 
    */
    handleAosSize = (value) => {
        this.setState({
            aosSizeSelectedOption: value,
            editableCellTable: false
        })
    }

    /**
     * This function is used to set the local state for term length
     * @param {*} value value of the particular term length to set 
    */
    handleTermLength = (value) => {
        this.setState({
            termLength: value,
            editableCellTable: false
        })
    }

    /**
     * This function is used to set the local state for type of commit
     * @param {*} value value of the particular type of commit to set 
    */
    handleTypeOfCommit = (value) => {
        this.setState({
            typeOfCommit: value,
            editableCellTable: false
        })
    }

    /**
     * This function used to handle rate card button navigation
     * @param {*} value value of the particular rate card type
     */
    handleRateCardButtonMenu = (value) => {
        this.setState({
            rateCardButtonOption: value,
            editableCellTable: false
        });
        const key = getRateCardKey(value);
        this.getRateCardconfigApiCall(key);
        const isCloudFrontShieldAdvancedOption = key === 'cfsa_smb' || key === 'cfsa_enterprise';
        this.resetTypeOfCommitDropdown(isCloudFrontShieldAdvancedOption);
    }

    /**
     * Resets type of commit dropdown options depending on selection
     * @param {boolean} isCloudFrontShieldAdvancedOption boolean describing if option selected is categorized as CloudFront + Shield Advanced
     */
    resetTypeOfCommitDropdown = (isCloudFrontShieldAdvancedOption) => {
        const typeOfCommitOptions = isCloudFrontShieldAdvancedOption ? TYPE_OF_COMMIT_DROPDOWN_OPTIONS.filter((option) => option.displayForCFSA) : TYPE_OF_COMMIT_DROPDOWN_OPTIONS;
        this.setState({
            currentTypeOfCommitDropdownOptions: typeOfCommitOptions,
            typeOfCommit: typeOfCommitOptions[0].optionValue
        });
    }

    /**
     * This function will take all the edited values in rate card table and call API to store the values
     */
    SaveRateCard = () => {
        if (this.state.edited === true) {
            this.setState({
                editableCellTable: false
            })

            let ratecard = getRateCardKey(this.state.rateCardButtonOption);
            let aos = this.state.aosSizeSelectedOption;
            let typeofcommit = (this.state.typeOfCommit === "Volume Commit") ? "vc" : "cf";
            this.showUserManagementLoadingToast("Saving RateCard ...", true);
            let configListApi = `${config.adminrateCardbaseUrl}updateRateCard`;
            let ReqData = {
                "ratecard": ratecard,
                "termlength": this.state.termLength,
                "aos": aos,
                "typeofcommit": typeofcommit,
                "editedvals": this.state.tableEditedValues
            }
            axios({
                method: "PUT",
                url: configListApi,
                data: ReqData
            })
                .then(resp => {
                    this.showUserManagementLoadingToast("RateCard values saved successfully...", true);
                    setTimeout(() => {
                        this.showUserManagementLoadingToast("RateCard values saved successfully", false);
                    }, 600)
                    this.setState({
                        edited: false,
                        tableEditedValues: []
                    })
                })
                .catch(() => {
                    this.showUserManagementLoadingToast("Saving RateCard Failed ...", false);
                })
        } else {
            this.setState({
                editableCellTable: false
            })
        }
    }

    makeCellEditableTrue = () => {
        this.setState({
            editableCellTable: true
        })
    }

    /**
     * This function is used to set the local state for each varaible
     * @param {*} modalType type of the modal to load
     */
    showModal = (modalType) => {
        this.setState({
            isModalVisible: true,
            modalType: modalType,
        });
    };

    closeModal = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    setConfigurationChange = (configurationChange) => {
        this.setState(
        {
            configurationChange: configurationChange,
        },
        () => {
            if (configurationChange) {
            this.getRateCardconfigApiCall();
            this.setState({
                configurationChange: false,
            });
            }
        }
        );
    };

    render() {
        let { sectionIndex } = this.props;
        return (
            <div className="full-width p-0 datatrnasfer-wraper">

                {sectionIndex === 2 &&
                    <GlobalLimits
                        showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                    />
                }
                {sectionIndex !== 2 && (
                    <>
                        {sectionIndex === 0 && (

                            <div className="full-width">
                                <ul className="p-0 float-right sub-controls pt-2 pb-2 mb-0">
                                    <li
                                      className="pr-0"
                                      onClick={() => this.showModal("rateCardConfig")}
                                    >
                                    <span className="mr-2 float-left">
                                      <Icon name="edit"></Icon>
                                    </span>
                                    Edit section
                                      </li>
                                  </ul>
                                <div className="full-width border-bottom">
                                    <div className="col-11 float-left p-0 rate-cardtabs">
                                        <AWSButtonRadio
                                            options={RATE_CARD_TYPES}
                                            canEditPPR={true}
                                            value={this.state.rateCardButtonOption}
                                            handleOnSelect={(value) => this.handleRateCardButtonMenu(value)}
                                            defaultValue={RateCardLabels.RateCard1}
                                        />
                                    </div>

                                    <span className="float-right col-24 cursor-pointer pt-2" onClick={() => this.SaveRateCard()}><span className="mr-2 float-left" >
                                        <img src={saveIcon} alt="save" height="14" />
                                    </span>Save</span>
                                </div>

                                <div className="full-width pt-5">
                                    <div className="col-3 float-left p-0">
                                        <AWSDropdown
                                            id="questionType"
                                            label="Type of Commit"
                                            options={this.state.currentTypeOfCommitDropdownOptions}
                                            value={this.state.typeOfCommit}
                                            defaultValue="Volume Commit"
                                            handleOnSelect={(value) => this.handleTypeOfCommit(value)}
                                        />
                                    </div>

                                </div>
                                <div className="full-width sub-tabs-wraper mt-2">
                                    <div className="col-9 p-0 pt-4 float-left">
                                        <p className="font-size14 mb-1 mt-0 full-width">Average Object Size (AOS) (kb)</p>
                                        <div className="data-transfer float-left">
                                            <AWSButtonRadio
                                                options={aosOptions}
                                                canEditPPR={true}
                                                aosWidthAdminModule={true}
                                                value={this.state.aosSizeSelectedOption}
                                                handleOnSelect={(value) => this.handleAosSize(value)}
                                                defaultValue="15 kb"
                                            />
                                        </div>
                                    </div>


                                    <div className="float-left col-3 data-transfer-dropdown p-0 pt-5">
                                        <AWSDropdown
                                            id="questionType"
                                            label="Term  Length (Months)"
                                            options={termLengthOptions}
                                            value={this.state.termLength}
                                            defaultValue="12"
                                            handleOnSelect={(value) => this.handleTermLength(value)}
                                        />
                                    </div>
                                </div>

                            </div>
                        )}
                        <div className="full-width mt-2">
                            {sectionIndex === 0 &&
                                <DataTransfer
                                    editableCellTable={this.state.editableCellTable}
                                    showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                                    termLength={this.state.termLength}
                                    aosSizeSelectedOption={this.state.aosSizeSelectedOption}
                                    rateCardButtonOption={this.state.rateCardButtonOption}
                                    typeOfCommit={this.state.typeOfCommit}
                                    saveEditedTableValues={this.saveEditedTableValues}
                                    makeCellEditableTrue={() => this.makeCellEditableTrue()}
                                    configurationChange={this.state.configurationChange}
                                />
                            }
                        </div>

                        {sectionIndex === 1 && (
                            <RequestFees
                                showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                            />
                        )}
                    </>
                )}
            <div className="full-width rateCardManagementPopUp">
              <AWSModal
                type={this.state.modalType}
                className="rateCardManagementPopUp"
                visible={this.state.isModalVisible}
                handleCancel={() => {
                    this.closeModal();
                }}
                showUserManagementLoadingToast={(message, activeState) =>
                    this.showUserManagementLoadingToast(message, activeState)
                }
                setConfigurationChange={this.setConfigurationChange}
            />
        </div>
            </div>
        )
    }
}
export default RateCardManagement;

RateCardManagement.propTypes = {
    showUserManagementLoadingToast: PropTypes.func,
    sectionIndex: PropTypes.any,
};
