export const CLOUD_FRONT_SHIELD_ADVANCED_QUESTION_LABEL =
  "CloudFront + Shield Advanced Combined Rate";
export const CLOUD_FRONT_SHIELD_DEAL_INFO_TAG = "cloudFrontShieldAdvancedDeal"; // todo: refactor once constant is available elsewhere
export const CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM =
  "Yes, Small & Medium Business Customer";
export const CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE =
  "Yes, Enterprise Customer";
export const CLOUD_FRONT_SHIELD_ADVANCED_DEALS = [
  CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM,
  CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE,
];
export const CLOUDFRONT_SHIELD_ADVANCED_SMB_MIN_COMMIT = 10; // 10 TB
export const CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_MIN_COMMIT = 150; // 150 TB
export const CLOUD_FRONT_SHIELD_ADVANCED_MIN_AOS = 35; // 35 kB
export const getCloudFrontShieldAdvancedMinCommit = (type) => {
  switch (type) {
    case CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM:
      return CLOUDFRONT_SHIELD_ADVANCED_SMB_MIN_COMMIT;
    case CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE:
      return CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_MIN_COMMIT;
    default:
      return undefined;
  }
};
export const getCloudFrontShieldAdvancedMinCommitError = (minCommit) => {
  return `The minimum monthly commitment volume is ${minCommit} TB for CloudFront + Shield Advanced Combined Rate requests. Either work with the customer to increase the average monthly commitment, or Shield Advanced will need to be separate from the CloudFront ask and a separate PPR will need to be submitted in Genesis.`;
};
export const CLOUDFRONT_SHIELD_ADVANCED_CUSTOM_RATES_ERROR =
  "Lower joint rates are not supported by the CloudFront + Shield Advanced Combined Rate. If additional discounts are required, a Shield Advanced specific commitment will be necessary, and Shield Advanced specific discounts will need to be evaluated separate from CloudFront. Please submit a PPR in Genesis.";

const CLOUDFRONT_SHIELD_ADVANCED_SMB_RATE_CARD_TYPE = "smb";
const CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_RATE_CARD_TYPE = "enterprise";
export const isCloudFrontShieldAdvancedDeal = (answer) => {
  return (
    answer === CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM ||
    answer === CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE
  );
};
export const getCloudFrontShieldAdvancedTypeForRateCard = (
  cloudFrontShieldAdvancedValue
) => {
  switch (cloudFrontShieldAdvancedValue) {
    case CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM:
      return CLOUDFRONT_SHIELD_ADVANCED_SMB_RATE_CARD_TYPE;
    case CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE:
      return CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_RATE_CARD_TYPE;
    default:
      return undefined;
  }
};

export const isCommitAcceptable = (type, commit) => {
  const parsedCommit = parseFloat(commit);
  if (isNaN(parsedCommit)) {
    return false;
  }
  switch (type) {
    case CLOUD_FRONT_SHIELD_ADVANCED_SMALL_MEDIUM:
      return parsedCommit >= CLOUDFRONT_SHIELD_ADVANCED_SMB_MIN_COMMIT;
    case CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE:
      return parsedCommit >= CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_MIN_COMMIT;
    default:
      return false;
  }
};

/**
 * Returns the AOS (Average Object Size) value for CloudFront + Shield Advanced deals
 * @param {boolean} shouldUpdateValue flag identifying whether we should update AOS value
 * @param {number} aosValue current value of Aos_Selected question field
 */
export const getCloudFrontShieldAdvancedAosValue = (shouldUpdateValue, aosValue) => {
 return shouldUpdateValue && aosValue < CLOUD_FRONT_SHIELD_ADVANCED_MIN_AOS ? CLOUD_FRONT_SHIELD_ADVANCED_MIN_AOS : aosValue
};
