import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AWSinput.scss";
import _ from "underscore";
import AWSError from "../AWSError/AWSError";
import infoHelpIcon from '../../../images/awsfalconhelp.png';
import * as roleNames from '../../../constants/roleNames.const';
import { Input, Popover,Icon } from "@amzn/awsui-components-react";

export default class AWSDollaripnut extends Component {
  state = {
    errorLabel: "",
    error: false
  };

  validate(validationArray, val) {
    _.each(validationArray, (validationObject, validationIndex) => {
      switch (validationObject.type) {
        case "Required":
          if (_.isEmpty(val) || _.isNull(val)) {
            this.setState({
              error: true,
              errorLabel: validationObject.message
            });
          } else if (_.isEmpty(val.trim()) || _.isNull(val)) {
            this.setState({
              error: true,
              errorLabel: validationObject.message
            });
          }
          break;
      }
    });
  }

  handleChange = (val, label, validationArray) => {
    this.setState({
      error: false
    });
    this.validate(validationArray, val);
    this.props.onChangeFunc(val);
  };

  handleBlur = val => {
    this.props.onBlurFunc(val);
  };

  checkForDisable = (canEditPPR) => {
    if (canEditPPR === false) {
        return true 
    } else {
        return false
    }
  }
    

  removeError = () => {
    this.setState({
      error: false
    });
  };

  render() {
    const { label, placeholder, value, triggeredNext, labelValue, questionValue, userRole, canEditPPR } = this.props;
    if (this.state.error === true && questionValue.isVisible === false) {
      this.removeError();
    }

    if (
      triggeredNext &&
      !this.state.error &&
      questionValue &&
      questionValue.isVisible
    ) {
      this.validate(this.props.validation, value);
    }

    return (

      <div>
        {labelValue !== '' && (
          <div className="dark-black font-size14 col-8 float-left p-0 mt-3">
            <span className="float-left">{labelValue}</span>
            {labelValue === "Total Expected Revenue" &&
              <Popover content={questionValue && questionValue.helpText}
                size="small" position="top">
                <div className="float-left circle-alert">
                  {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                </div>
              </Popover>}

          </div>

        )}
        <div className="border-input-wraper">
          <span className={`float-left width130 font-size16 amber-bold commit-txt ${labelValue === 'Total Expected Revenue' ? "green-border" : ""}`}>
            <span className="dollar-txt">$</span>
            <div className="AWS-input-div">
              <Input
                placeholder={placeholder}
                value={value}
                error={value === "" ? true : false}
                type={label === "Polaris" ? "number" : "text"}
                onInput={e =>
                  this.handleChange(e.detail.value, label, this.props.validation)
                }
                onBlur={e => this.handleBlur(value)}
                disabled={this.checkForDisable(canEditPPR)}
              />
            </div>
          </span>
          <span className="width-auto mandatory-wraper">
            {this.state.error && <AWSError errorLabel={this.state.errorLabel} />}
          </span>
        </div>


      </div>
    );
  }
}

AWSDollaripnut.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChangeFunc: PropTypes.func,
  onBlurFunc: PropTypes.func
};
