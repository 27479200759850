import React, { Component } from 'react';
import PropTypes from "prop-types";
import { cloneDeep } from 'lodash';
import axios from 'axios';

import '../AWSAdminTable/AWSTable.scss';
import './RateCardTable.scss';
import AWSConfig from '../../../config/AWSconfig';
import AWSAdminTable from '../AWSAdminTable/AWSAdminTable';
import AWSAdminTableInput from '../../common/AWSAdminTableInput/AWSAdminTableInput';
import { getRateCardKey } from './RateCardManagement';

const config = new AWSConfig();

class GlobalLimits extends Component {

    state = {
        columns: [],
        clonedColumnData: []
    }

    /**
    * This lifecycle method is calling API to get all data transfer price rate card values
    */
    componentDidMount() {
        this.dataTransferApiCall()
    }


    updateVolCommitData = (val, keyOfVolCommitData, commitData) => {
        commitData[keyOfVolCommitData] = val;
        let { columns } = this.state;

        this.onEdit(val, keyOfVolCommitData, commitData)
        this.setState({
            columns
        })
    }

    getCommitFeeCols = () => {
        let CommitFeecolumns = [
            {
                header: 'Commit Fee ($)',
                id: 'UpfrontFee',
                minWidth: 120,
                cell: item => <div >
                    {item.UpfrontFee}
                </div>
            },
            {
                header: 'NA ($)',
                id: 'NA',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.NA && item.NA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "NA", item)}
                    />
                </div>
            },
            {
                header: 'EU/IL ($)',
                id: 'EU',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.EU && item.EU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "EU", item)}
                    />
                </div>
            },
            {
                header: 'AP ($)',
                id: 'AP',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.AP && item.AP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AP", item)}
                    />
                </div>
            },
            {
                header: 'JP ($)',
                id: 'JP',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.JP && item.JP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "JP", item)}
                    />
                </div>
            },
            {
                header: 'SA ($)',
                id: 'SA',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.SA && item.SA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "SA", item)}
                    />
                </div>
            },
            {
                header: 'AU ($)',
                id: 'AU',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.AU && item.AU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AU", item)}
                    />
                </div>
            },
            {
                header: 'IN ($)',
                id: 'IN',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.IN && item.IN.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "IN", item)}
                    />
                </div>
            },
            {
                header: 'ZA ($)',
                id: 'ZA',

                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.ZA && item.ZA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ZA", item)}
                    />
                </div>
            },
            {
                header: 'ME ($)',
                id: 'ME',
                cell: item => <div >
                    <AWSAdminTableInput
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}
                        editableCellTable={this.props.editableCellTable}
                        value={item.ME && item.ME.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ME", item)}
                    />
                </div>
            },
        ];
        return CommitFeecolumns;
    }

    getVolumeCommitCols = () => {
        let cols = [
            {
                header: 'Monthly Commit (TB)',
                id: 'MonthlyCommit',
                minWidth: '220px',
                cell: item => <div >{item.MonthlyCommit}</div>

            },
            {
                header: 'NA ($)',
                id: 'NA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        value={item.NA && item.NA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "NA", item)}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                    />
                </div>
            },
            {
                header: 'EU/IL ($)',
                id: 'EU',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.EU && item.EU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "EU", item)}
                    />
                </div>
            },
            {
                header: 'AP ($)',
                id: 'AP',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.AP && item.AP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AP", item)}
                    />
                </div>
            },
            {
                header: 'JP ($)',
                id: 'JP',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.JP && item.JP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "JP", item)}
                    />
                </div>
            },
            {
                header: 'SA ($)',
                id: 'SA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.SA && item.SA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "SA", item)}
                    />
                </div>
            },
            {
                header: 'AU ($)',
                id: 'AU',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.AU && item.AU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AU", item)}
                    />
                </div>
            },
            {
                header: 'IN ($)',
                id: 'IN',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.IN && item.IN.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "IN", item)}
                    />
                </div>
            },
            {
                header: 'ZA ($)',
                id: 'ZA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.ZA && item.ZA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ZA", item)}
                    />
                </div>
            },
            {
                header: 'ME ($)',
                id: 'ME',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.props.editableCellTable}
                        makeCellEditableTrue={() => this.props.makeCellEditableTrue()}

                        value={item.ME && item.ME.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ME", item)}
                    />
                </div>
            },
        ];
        return cols;

    }

    /**
     * This function call backend api to get the datatransfer ratecard of in admin module
     */
    dataTransferApiCall = () => {
        const rateCardKey = getRateCardKey(this.props.rateCardButtonOption);
        let aos = this.props.aosSizeSelectedOption;
        let typeofcommit = (this.props.typeOfCommit === "Volume Commit") ? "vc" : "cf";

        let reqdata = {
            "ratecard": rateCardKey,
            "termlength": this.props.termLength,
            "aos": aos,
            "typeofcommit": typeofcommit
        }

        this.showUserManagementLoadingToast("Loading RateCard ...", true);
        let configListApi = `${config.adminrateCardbaseUrl}ratecardVals`;
        axios({
            method: "POST",
            url: configListApi,
            data: reqdata
        })
            .then(resp => {
                this.setState({
                    columns: resp.data.columns
                }, () => {
                    const cloneColumnData = cloneDeep(this.state.columns);
                    this.setState({
                        clonedColumnData: cloneColumnData
                    })
                })
                this.showUserManagementLoadingToast("Loading RateCard ...", false);
            })
            .catch(() => {
                this.showUserManagementLoadingToast("Failed Loading RateCard ...", false);
            })
    }

    /**
     * This function will be called whenever a cell is edited and store the value in the parent component
     * @param {*} data entire cell values to get the edited value and old value
    */
    onEdit = (val, keyOfVolCommitData, Protocol) => {
        this.props.saveEditedTableValues(val, keyOfVolCommitData, Protocol);
    }

    /**
     * This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
    */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    /**
     * This lifecycle method will be called whenever state change happpens in the parent component
     * @param {*} prevProps previous props value of the parent component
    */
    componentDidUpdate(prevProps) {
        if (prevProps.termLength !== this.props.termLength ||
            prevProps.rateCardButtonOption !== this.props.rateCardButtonOption ||
            prevProps.aosSizeSelectedOption !== this.props.aosSizeSelectedOption ||
            prevProps.typeOfCommit !== this.props.typeOfCommit ||
            this.props.configurationChange
        ) {
            this.dataTransferApiCall();
        }
    }

    render() {

        return (
            <div className="full-width ratecard-table pb-5">
                {
                    this.props.typeOfCommit === 'Volume Commit' &&
                    (
                        <AWSAdminTable
                            tableData={this.state.columns}
                            columns={this.getVolumeCommitCols()}
                            tooltips={false}
                            layout={"fitData"}
                            progressiveRenderSize={10}
                            progressiveRender={true}
                        />
                    )
                }
                {
                    this.props.typeOfCommit === 'Commit Fee' &&
                    (
                        <AWSAdminTable
                            tableData={this.state.columns}
                            columns={this.getCommitFeeCols()}
                            tooltips={false}
                            layout={"fitData"}
                            progressiveRenderSize={10}
                            progressiveRender={true}
                            resizable={false}
                        />
                    )
                }
            </div>
        )
    }
}
export default GlobalLimits;

GlobalLimits.propTypes = {
    editableCellTable: PropTypes.bool,
    showUserManagementLoadingToast: PropTypes.func,
    handleOnSelect: PropTypes.func,
    saveEditedTableValues: PropTypes.func,
    rateCardButtonOption: PropTypes.any,
    aosSizeSelectedOption: PropTypes.any,
    typeOfCommit: PropTypes.any,
    termLength: PropTypes.any,
    makeCellEditableTrue: PropTypes.any,
    configurationChange: PropTypes.any
};