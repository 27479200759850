import React, { Component } from "react";
import printIcon from '../../images/print_icon.svg';
import downloadIcon from '../../images/download_grey.svg';

class PrintDownload extends Component {
    render() {
        return (
            <div className="print-download float-right pt-4">
                <p className="float-left width-auto cursor-pointer"><span className="font-size12 float-left pr-3">Print</span><img src={printIcon} alt="print" /></p>
                <p className="float-left width-auto ml-4 cursor-pointer"><span className="font-size12 float-left pr-2">Download</span><img src={downloadIcon} alt="download" /></p>
            </div>
        )
    }
}
export default PrintDownload;

