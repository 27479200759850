export const customerLegalNameTag = 'cus_legal_name';
export const accountManagerNameTag = 'acc_manager';
export const accountNameTag = 'account_name';
export const cloudFrontSalesManagerTag = "cldf_sm";
export const renewalTypeTag = 'what_is_renewal_type';
export const geoTag = "geo";
export const geoTagPs = "geo_ps";
export const termLengthTag = "term_length";
export const businessUnitTag = "business_unit";
export const pricingQuestionTag = "is_there_pricing";
export const discountTermDateTag = "dis_term_date";
// export const renewalDealInfoTag = "what_is_renewal_type";
export const originAWSTag = "is_the_origin";
export const autoRenewTag = "auto_renew";
export const isYourTag = "is_your";
export const dealTypeTag = "deal_type";
export const additionalDealTermTag = "adi_deal_terms";
export const verticalValueTag = "vertical_value";
export const payerIdDetailsTag = "please_provide_payerid_details";
export const provideBusinessDetailsTag = "please_provide_business";
export const dealDetailsTag = "deal_details";

export const commitTermEffectiveDateCalTag = "commit_term_effective_date";
export const startDateAgreementCalTag = "start_date_agreement";
export const effectiveDetailCalTag = "effective_detail";
export const currentDiscountTermEndCalTag = "current_discount_term_end";
export const terminationDateCalTag = "termination_date";
export const actualDateCalTag = "actual_date";
export const expectedCustomerCalTag = "expected_customer";
export const discountTermEndDateCalTag = "discount_term_end_date";

export const mapAdditionalDealTermTag = "Map_Additional_Deal_Term";

export const otherAWSServiceTag = "other_aws";
export const specifyOriginAWS = "please_specify_five";
export const existingCrossServicePPA = "does_the_cross_service";
export const negotiatingCrossServicePPA = "is_the_cross_service";
export const productAtRisk = "which_other";
export const areWeInCompetetiveSituation = "are_we";
export const competitorOffers = "please_provide_rate_commit"
export const verticalValueTagPs = "vertical_ps";
export const verticalValueTagCh = "vertical_ch";
export const subGeoTag = "please_specify_subgeo";

export const please_provide_justification_for_refrence = "please_provide_justification";
export const commit_begin_discount = "commit_begin_discount";
export const affiliate_payerid_details_table = "please_provide_affiliate_payerid_details";
export const affiliate_payerId_cell = "aws_affiliate_payerId";

export const cloudFrontShieldAdvanced = "cf_sa_deal";
export const businessCustomerType = "business_information";
export const endCustomerLegalEntityName = "end_customer_legal_entity_name";
