
import AWSConfig from '../config/AWSconfig';
import apiCall from './commonApiCall';
const config = new AWSConfig();

export const getPPRForm = (urlWithParams,data,type) => {
    let method,url;
    if (type === "clone") {
        method = "POST";
        url = `${config.amazonFalconbaseUrl}cfppr/cloningppr`
    } else if (type === "audit") {
        method = "POST";
        url = `${config.amazonFalconbaseUrl}${urlWithParams}`
    }else {
        method = "GET";
        url = config.amazonFalconbaseUrl + urlWithParams;
    }
    return apiCall(method,url,data)
}

export const saveOrSubmitPPRForm = (urlWithParams,data) => {
    let method = "POST";
    let url = `${config.amazonFalconbaseUrl}${urlWithParams}`;
    return apiCall(method,url,data)
}

export const emailNotification = (urlWithParams,data) => {
    let method = "POST";
    let url = `${config.amazonFalconbaseUrl}${urlWithParams}`;
    return apiCall(method,url,data)
}

export const getSalesManagerData = (urlWithParams,data) => {
    let method,url;
    method = "POST";
    url = config.userManagementbaseUrl + urlWithParams;
    return apiCall(method,url,data)
}

export const fetchDataFromAccountNameOrAccountId = (data) => {
    let method,url;
    method = "POST";
    url = `${config.awsFalconSearchUrl}search`
    return apiCall(method,url,data)
}

export const submitComment = (data) => {
    let method,url;
    method = "POST";
    url = `${config.commentUrl}`
    return apiCall(method,url,data)
}

export const getPPRCreatedById = (data) => {
    let method = "POST";
    let url = `${config.awsFalconSearchUrl}userid`;
    return apiCall(method,url,data)
}