import React, { Component } from "react";
import { Icon } from "@amzn/awsui-components-react";
import AWSInput from '../common/AWSInput/AWSinput';
import AWSInputDateCommon from '../common/AWSInputDate/AWSInputDateCommon';
import AWSLoadingToast from '../common/AWSLoadingToast/AWSLoadingToast';
import AWSbutton from '../common/AWSbutton/AWSbutton';
import AWSDropDown from '../common/AWSDropdown/AWSDropdown';
import axios from 'axios';
import AWSConfig from '../../config/AWSconfig';
import dealStatusJson from '../../json/dealStatusForAdvanceSearch.json';
import Geo from '../../json/geoForAS.json';
import orgChangeGeo from '../../json/orgChangeGeoForAdvancedSearch.json';
import dealType from '../../json/dealTypeForAS.json'
import channel from '../../json/channelForAS.json'
import openClosedDeal from '../../json/dealOpenClose.json';
import flags from '../../json/flags.json';
import businessUnitOption from '../../json/businessUnitOption.json';
import * as roleNames from '../../constants/roleNames.const';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../../utils/sessionStorageUtils';
import STORAGE_NAMES from '../../constants/storageNames.const';
import { PAYER_ID_LABEL } from '../../constants/dashboardConstants';
import { isAfterOrgChange } from "../../utils/common";
import orgChangeBusinessUnitOptionsAWSDropdown from '../../json/orgChangeBusinessUnitOptionsAWSDropdown.json';
import businessCustomerTypeOptionsAWSDropdown from '../../json/businessCustomerTypeOptionsAWSDropdown.json'

const config = new AWSConfig();
let validation = [{
    message: "This is a mandatory field",
    type: "Required"
}];

let buttonDisable = true

class AdvancedSearch extends Component {
    state = {
        customerName: null,
        accountName: null,
        salesRep: null,
        igtOwner: null,
        payerId: null,
        sceOwner: null,
        geo: null,
        dealType: null,
        status: null,
        openCloseDeal: null,
        channel: null,
        discountTermEffectiveDate: null,
        flags: null,
        activateToast: false,
        salesManager: null,
        businessUnit: null,
        businessCustomerType: null,
        pendingPPOReview: null,
        pendingSCEReview: null,
        loadingMessage: 'Please wait for search results...'
    }

    componentDidMount() {
        const searchFilters = getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS);
        if(searchFilters !== null) {
            const customerName = searchFilters['customerLegalName'];
            delete searchFilters['customerLegalName'];
            this.setState({...searchFilters, customerName })
        }
    }

    /*changing value based on input */
    onChangeFunc = (value, name) => {

        switch (name) {
            case 'customerName':
                this.setState({
                    customerName: value
                })
                break;

            case 'accountName':
                this.setState({
                    accountName: value
                })
                break;

            case 'salesRep':
                this.setState({
                    salesRep: value
                })
                break;

            case 'igtOwner':
                this.setState({
                    igtOwner: value
                })
                break;

            case 'payerId':
                this.setState({
                    payerId: value
                })
                break;

            case 'sceOwner':
                this.setState({
                    sceOwner: value
                })
                break;

            case 'pendingPPOReview':
                this.setState({
                    pendingPPOReview: value
                })
                break;
     
            case 'pendingSCEReview':
                this.setState({
                    pendingSCEReview: value
                })
                break;

            case 'salesManager':
                this.setState({
                    salesManager: value
                })
                break;

            case 'geo':

                if (typeof value === 'object') {
                    this.setState({
                        geo: null
                    })
                } else {
                    this.setState({
                        geo: value
                    })
                }

                break;

            case 'dealType':
                if (typeof value === 'object') {
                    this.setState({
                        dealType: null
                    })
                } else {
                    this.setState({
                        dealType: value
                    })
                }

                break;

            case 'status':
                if (typeof value === 'object') {
                    this.setState({
                        status: null
                    })
                } else {
                    this.setState({
                        status: value
                    })
                }

                break;

            case 'openCloseDeal':
                this.setState({
                    openCloseDeal: value
                })
                break;

            case 'channel':
                if (typeof value === 'object') {
                    this.setState({
                        channel: null
                    })
                } else {
                    this.setState({
                        channel: value
                    })
                }
                break;

            case 'discountTermEffectiveDate':
                if (value === "Invalid date") {
                    this.setState({
                        discountTermEffectiveDate: null
                    })
                } else {
                    this.setState({
                        discountTermEffectiveDate: value
                    })
                }

                break;

            case 'flags':
                if (typeof value === 'object') {
                    this.setState({
                        flags: null
                    })
                } else {
                    this.setState({
                        flags: value
                    })
                }

                break;

            case 'businessUnit':
                if (typeof value === 'object') {
                    this.setState({
                        businessUnit: null
                    })
                } else {
                    this.setState({
                        businessUnit: value
                    })
                }

                break;

            case 'businessCustomerType':
                if (typeof value === 'object') {
                    this.setState({
                        businessCustomerType: null
                    })
                } else {
                    this.setState({
                        businessCustomerType: value
                    })
                }

                break;

        }
    }
    // call api to updatedDashboard
    updatedDashboard = () => {
        let data = {
            "customerLegalName": this.state.customerName,
            "accountName": this.state.accountName,
            "salesManager": this.state.salesManager,
            "salesRep": this.state.salesRep,
            "igtOwner": this.state.igtOwner,
            "sceOwner": this.state.sceOwner,
            "payerId": this.state.payerId,
            "geo": this.state.geo,
            "dealType": this.state.dealType,
            "status": this.state.status,
            "openCloseDeal": this.state.openCloseDeal,
            "channel": this.state.channel,
            "discountTermEffectiveDate": this.state.discountTermEffectiveDate,
            "flags": this.state.flags,
            "userRole": this.props.userRole,
            "businessUnit": this.state.businessUnit,
            "pendingPPOReview": this.state.pendingPPOReview,
            "pendingSCEReview": this.state.pendingSCEReview,
            "businessCustomerType": this.state.businessCustomerType
        }

        this.setState({
            activateToast: true
        })

        const searchListAPI = `${config.awsFalconSearchUrl}dashboardsearch`;
        axios({
            method: "POST",
            url: searchListAPI,
            data: data
        })
            .then(resp => {
                this.setState({
                    activateToast: false,
                    loadingMessage: 'Please wait for search results...'
                });
                /**
                 * Storing the values of the API params
                 */
                setSessionStorage(STORAGE_NAMES.SEARCH_PARAMS, data);
                this.props.handleSearch(resp.data);
            })
            .catch(() => {
                this.setState({
                    loadingMessage: "Server error",
                    loadingError: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            activateToast: false
                        })
                    }, 2500);
                })
            })
            
    }
    /**
     * function to close the modal and reset the states
     */
     clearFilters(){
        removeSessionStorage(STORAGE_NAMES.SEARCH_PARAMS)
        this.clearState();
        this.props.clearFilters();
    }

    closeModal () {
        if(getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) === null) {
            this.clearState();
        } 
        this.props.handleClose();
    }
    /**
     * this function will validate value to enable/disable search button
     */
    checkForButtonEnableOrDisable = () => {
        let count = 0;
        let advanceSearchInput = ["customerName", "salesManager", "salesRep", "igtOwner", "sceOwner", "pendingSCEReview", "pendingPPOReview", "payerId", "geo", "dealType", "status", "channel", "discountTermEffectiveDate", "flags", "businessUnit", "businessCustomerType"];
        advanceSearchInput.forEach((inputKey) => {

            if (this.state[inputKey] !== undefined && this.state[inputKey] !== null && this.state[inputKey] !== "" && this.state[inputKey].trim() !== "") {
                buttonDisable = false;
                count++;
            }

            if (count === 0) {
                buttonDisable = true // true
            }
        })

        return buttonDisable;
    }

    clearState = () => {
        this.setState({
            customerName: null,
            accountName: null,
            salesRep: null,
            igtOwner: null,
            payerId: null,
            sceOwner: null,
            geo: null,
            dealType: null,
            status: null,
            openCloseDeal: null,
            channel: null,
            discountTermEffectiveDate: null,
            flags: null,
            activateToast: false,
            salesManager: null,
            businessUnit: null,
            pendingPPOReview: null,
            pendingSCEReview: null,
            businessCustomerType: null
        })
    };

    render() {

        let { userRole } = this.props;
        let questionValue = {
            isVisible: true,
            isMandatory: true
        };

        return (
            <div className="modal-container full-width popup-bg advanced-search-popup">
                <div className="modal-wraper full-width bg-grey p-3 pl-4 pr-4">
                    <p className="font-size16 dark-black amber-bold col-8 float-left p-0 pt-2 mb-0">
                        Advanced Search</p>
                    <span
                        className="float-right cursor-pointer close-icon dark-black float-left"
                        onClick={() => this.closeModal()}
                    >
                        <Icon name="close"></Icon>
                    </span>
                    {this.state.activateToast && (
                        <AWSLoadingToast
                            message={this.state.loadingMessage}
                            error={this.state.loadingError}
                            duration={this.state.loadingMessageDuration} />
                    )}
                </div>
                <div className="full-width">
                    <div className="body-wraper p-4 full-width">
                        <div className="col-6 float-left pb-4">
                            <p>Customer Legal Name</p>
                            <AWSInput
                                value={this.state.customerName}
                                onChangeFunc={(value) => { this.onChangeFunc(value, "customerName") }}
                            />
                        </div>
                        {/* <div className="col-6 float-left">
                            <p>Account Name</p>
                            <AWSInput
                                value={this.state.accountName}
                                onChangeFunc={(value) => { this.onChangeFunc(value, "accountName") }}
                            />
                        </div> */}
                        <div className="col-6 float-left pt-0 pb-4">
                            <p>Sales Rep Requestor Alias</p>
                            <AWSInput
                                value={this.state.salesRep}
                                onChangeFunc={(value) => { this.onChangeFunc(value, "salesRep") }}
                            />
                        </div>
                        

                        {/* Discount Term Effective Date */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSInputDateCommon
                                label="Discount Term Effective Date"
                                value={this.state.discountTermEffectiveDate}
                                onChangeFunc={(val) => {
                                    this.onChangeFunc(moment(val ? val : "01/01/2019").format("MM/DD/YYYY"), "discountTermEffectiveDate")
                                }}
                            />
                        </div>
                        {/* <div className="col-6 float-left pt-3">
                            <p>Payer ID</p>
                            <AWSInput
                                value={this.state.payerId}
                                onChangeFunc={(value) => { this.onChangeFunc(value, "payerId") }}
                            />
                        </div> */}


                        {/* Status Dropdown */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Deal Status"}
                                options={dealStatusJson}
                                value={this.state.status}
                                questionValue={questionValue}
                                handleOnSelect={value => { this.onChangeFunc(value, "status") }
                                }
                            />
                        </div>

                        {/* Geo Dropdown */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Geo"}
                                options={isAfterOrgChange() ? orgChangeGeo : Geo}
                                value={this.state.geo}
                                questionValue={questionValue}
                                handleOnSelect={value => {
                                    { this.onChangeFunc(value, "geo") }
                                }}
                            />
                        </div>

                        {/* Deal Type Dropdown */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Deal Type"}
                                options={dealType}
                                value={this.state.dealType}
                                questionValue={questionValue}
                                handleOnSelect={value => { this.onChangeFunc(value, "dealType") }
                                }
                            />
                        </div>

                        {/* Open/Close Dropdown */}
                        {/* <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Deal Open/Close"}
                                options={openClosedDeal}
                                value={this.state.openCloseDeal}
                                questionValue={questionValue}
                                handleOnSelect={value => {
                                    { this.onChangeFunc(value, "openCloseDeal") }
                                }}
                            />
                        </div> */}

                        {/* Direct/Reseller Dropdown */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Direct/Reseller/Solution Provider"}
                                options={channel}
                                value={this.state.channel}
                                questionValue={questionValue}
                                handleOnSelect={value => {
                                    { this.onChangeFunc(value, "channel") }
                                }}
                            />
                        </div>

                        {/* Business Unit */}
                        <div className="col-6 float-left pt-3 pb-4">
                            <AWSDropDown
                                label={"Business Unit"}
                                options={isAfterOrgChange() ? orgChangeBusinessUnitOptionsAWSDropdown : businessUnitOption}
                                value={this.state.businessUnit}
                                questionValue={questionValue}
                                handleOnSelect={value => {
                                    { this.onChangeFunc(value, "businessUnit") }
                                }}
                            />
                        </div>

                        {/* Business Customer Type Dropdown */}
                        {isAfterOrgChange() &&
                            <div className="col-6 float-left pt-3 pb-4">
                                <AWSDropDown
                                    label={"Customer Type"}
                                    options={businessCustomerTypeOptionsAWSDropdown}
                                    value={this.state.businessCustomerType}
                                    questionValue={questionValue}
                                    handleOnSelect={value => { this.onChangeFunc(value, "businessCustomerType") }
                                    }
                                />
                            </div>
                        }

                        <div className="col-6 float-left pt-3">
                            <p>{PAYER_ID_LABEL}</p>
                            <AWSInput
                                value={this.state.payerId}
                                onChangeFunc={(value) => { this.onChangeFunc(value, "payerId") }}
                            />
                        </div>
                        {
                            (userRole === roleNames.IGT_OWNER || userRole === roleNames.SCE_OWNER) && (
                                <>
                                    <div className="col-6 float-left pt-3">
                                        <p>PPO Alias</p>
                                        <AWSInput
                                            value={this.state.igtOwner}
                                            onChangeFunc={(value) => { this.onChangeFunc(value, "igtOwner") }}
                                        />
                                    </div>

                                    <div className="col-6 float-left pt-3 pb-3">
                                        <p>SCE Alias</p>
                                        <AWSInput
                                            value={this.state.sceOwner}
                                            onChangeFunc={(value) => { this.onChangeFunc(value, "sceOwner") }}
                                        />
                                    </div>

                                    <div className="col-6 float-left pt-3">
                                        <p>Pending PPO Review</p>
                                        <AWSInput
                                            value={this.state.pendingPPOReview}
                                            onChangeFunc={(value) => { this.onChangeFunc(value, "pendingPPOReview") }}
                                        />
                                    </div>

                                    <div className="col-6 float-left pt-3 pb-3">
                                        <p>Pending SCE Review</p>
                                        <AWSInput
                                            value={this.state.pendingSCEReview}
                                            onChangeFunc={(value) => { this.onChangeFunc(value, "pendingSCEReview") }}
                                        />
                                    </div>
                                </>
                            )
                        }
                        {
                            (userRole === roleNames.IGT_OWNER || userRole === roleNames.SCE_OWNER || userRole === roleNames.SALES_MANAGER) && (
                                <div className="col-6 float-left pt-3">
                                    <p>Sales Manager Alias</p>
                                    <AWSInput
                                        value={this.state.salesManager}
                                        onChangeFunc={(value) => { this.onChangeFunc(value, "salesManager") }}
                                    />
                                </div>
                            )}
                        

                        {
                            (userRole === roleNames.IGT_OWNER || userRole === roleNames.SCE_OWNER || userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR || userRole === roleNames.PRIVATE_PRICING_CHINA) && (
                                <div className="col-6 float-left pt-3 pb-4">
                                    <AWSDropDown
                                        label={"Flags"}
                                        options={flags}
                                        value={this.state.flags}
                                        questionValue={questionValue}
                                        handleOnSelect={value => {
                                            this.onChangeFunc(value, "flags")
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>

                    
                                
                    <div className="full-width button-wraper">
                        <div className="mb-0 mt-3 float-right width-auto pb-4 pt-2">

                            <span className="float-left pr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.closeModal()
                                    }
                                />
                            </span>

                            {getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) !== null && (
                                <span className="float-left pr-4">
                                    <AWSbutton
                                        btnType="btns unfill-button"
                                        disabled={this.checkForButtonEnableOrDisable()}
                                        label="Clear Filter"
                                        isIcon={false}
                                        onClick={() => this.clearFilters()}
                                    />
                                </span>
                            )}

                            <span className="float-right pr-4">
                                <AWSbutton
                                    btnType="fill"
                                    disabled={this.checkForButtonEnableOrDisable()}
                                    label="Search"
                                    isIcon={false}
                                    onClick={() => this.updatedDashboard()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvancedSearch;
