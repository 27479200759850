import React, { Component } from 'react';
import {
    Table, TableContentSelector,
    TablePreferences,
    TableFiltering,
    TableSelection, TablePagination,
    TableSorting, Icon
} from "@amzn/awsui-components-react";
import './AWSTable.scss';
import * as roleNames from '../../../constants/roleNames.const';
const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;


class AWSTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
          filteringText: '',
          pageSize: 10,
          wrapLines: false,
          width:'',
          height:''
        };
        //this.onPaginationChange = this.onPaginationChange.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }
    
    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    // }
    
    updateWindowDimensions() {
        this.setState({
             width: window.innerWidth,
             height: window.innerHeight 
        },() => {
            this.updatepageSize();
        });
    }

    updatepageSize = () =>{
        if(this.state.width === 1280){
            this.setState({
                pageSize : 5
            })
        }
        if(this.state.width === 1366){
            this.setState({
                pageSize : 9
            })
        }
        if(this.state.width === 1536){
            this.setState({
                pageSize : 8
            })
        }
        if(this.state.width === 1600){
            this.setState({
                pageSize : 9
            })
        }
        if(this.state.width === 1680){
            this.setState({
                pageSize : 14
            })
        }
        if(this.state.width === 1980){
            this.setState({
                pageSize : 17
            })
        }
    }

    onFilteringChange({ detail: { filteringText } }) {
        this.setState({
          filteringText
        });
    }
    // componentDidMount() {
    //     this.updateWindowDimensions();
    //     window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    // }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    // }

    // updateWindowDimensions() {
    //     this.setState({
    //          width: window.innerWidth,
    //          height: window.innerHeight 
    //     });
    //    this.updatepageSize()
    // }
    // updatepageSize = () =>{
    //     if(this.state.width === 1280){
    //         this.setState({
    //             pageSize : 5
    //         })
    //     }
    //     if(this.state.width === 1366){
    //         this.setState({
    //             pageSize : 6
    //         })
    //     }
    //     if(this.state.width === 1600){
    //         this.setState({
    //             pageSize : 9
    //         })
    //     }
    // }
    render() {
        const { userRole } = this.props;
        return ( 
            <Table
                loadingText="Loading records"
                columnDefinitions={this.props.columns}
                items={this.props.items}
                stickyHeader={false}
                resizableColumns={true}
                onContentSelectionChange={(e) => this.props.onContentSelectionChange(e)}
                variant="borderless">
                <TablePagination pageSize={this.state.pageSize} className={userRole === roleNames.SALES_REPRESENTATIVE ? "youtube-post":"reset-post"} openEnd={true}></TablePagination>
                <TableSorting sortableColumns={this.props.sortableColumnOptions}/>
                <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel" className={userRole === roleNames.BILLING_TEAM ? "setting-post":" "}>
                    <TableContentSelector 
                        title="Select visible columns" 
                        options={this.props.options} 
                    />
                </TablePreferences>
                <TableFiltering
                    filteringLabel="Filter PPRs"
                    filteringPlaceholder="Search"
                    filteringText={this.state.filteringText}
                    onFilteringChange={this.onFilteringChange.bind(this)}
                    filteringCountTextFunction={getFilterCounterText}
                    className={userRole === roleNames.SALES_REPRESENTATIVE ? "youtube-post":"reset-post"}
                />
            </Table>
        );
    }
}

export default AWSTable;
