import React, { Component } from 'react';
import Col from "../AWSCol/AWSCol";
import AWSinput from "../AWSInput/AWSPricinginput";
import PropTypes from "prop-types";
import AWSError from '../AWSError/AWSError';
import Plusicon from "../../../images/Pluss_Icon.svg";
import Minusicon from '../../../images/minu_icon.svg';

class AWSPolarisTable extends Component {

  state = {
    errorLabel: "",
    error: false
  }


  /**
   * This function used update the local state value of each input element
   * @param {*} value typed or selected option which need to update in the state
   * @param {*} rowIndex the array row location where the values chaned
   * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
  */
  handleChange = (value, rowIndex, cellIndex) => {
    this.setState({
      error: false
    })
    //this.validate();
    this.props.onChangeFunc(value, rowIndex, cellIndex, "Input");
  }

  validate = () => {
    let { rowValues, tableType } = this.props;
    let sumOfInsertedValue = 0;
    rowValues && rowValues.map((cell) => {
      cell.cells.map((rec) => {
        if (rec.value !== "") {
          sumOfInsertedValue = sumOfInsertedValue + parseFloat(rec.value);
        }
      })
    })
    if (sumOfInsertedValue === 0) {
      this.setState({
        error: true,
        errorLabel: "This is a mandatory field"
      })
    } else {
      if (this.state.error === true) {
        this.setState({
          error: false
        })
      }
    }
  }

  /**
   * This function used update the local state value of each input element in parent component
   * @param {*} val typed or selected option which need to update in the state
   * @param {*} key in which name of the particular element value to be set
   * @param {*} rowIndex the array row location where the values chaned
   * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
  */
  handleBlur = (value, rowIndex, cellIndex, key) => {
    this.setState({
      error: false
    })
    this.props.onBlurFunc(value, rowIndex, cellIndex, key);
  }

  /**
   * This function will add extra fields for data transfer price table
   * 
  */
  handleOnAdd = () => {
    let { rowValues, volumeType } = this.props;
    let length = rowValues.length;
    let TBL = (rowValues[length - 1].cells[1] && rowValues[length - 1].cells[1].value !== "") ? (volumeType === 'PB') ? parseFloat(rowValues[length - 1].cells[1].value) + 0.1 : parseInt(rowValues[length - 1].cells[1].value) + 1 : ""
    let tierObject = {
      "cells": [
        {
          "value": TBL, "tierKey": ">TB", "editable": false, "isEdited": false
        },
        {
          "value": "", "tierKey": "<TB", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "NA", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "EU", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "AP", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "JP", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "SA", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "AU", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "IN", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "ZA", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "ME", "editable": true, "isEdited": false
        },
        {
          "value": "", "tierKey": "ALL", "editable": true, "isEdited": false
        }
      ]
    }
    rowValues.push(tierObject);
    this.props.onChangeFunc(rowValues, "dataTransferPriceTiered");
  }

  /**
   * This function will remove extra fields for data transfer price table
   * @param {*} rowId rowId to be removed from data transfer price table
  */
  handleOnDelete = (rowId) => {
    let { rowValues } = this.props;
    rowValues.splice(rowId, 1);
    this.props.onChangeFunc(rowValues, "dataTransferPriceTiered");
  }

  render() {
    let { columns, rowValues, tierType, triggeredNext, tableType, userRole } = this.props;

    if (triggeredNext === true && (!this.state.error) && tableType && (tableType === 'regionalTraficlimit' || tableType === 'regionalTraficMix' || tableType === 'noCommitTable')) {
      this.validate(rowValues, 'Required');
    }

    return (
      <div className="mt-4">
        <div className="input-label font-size14 float-left"></div>
        <Col span={24} className={`mt-2 ${tierType === 'widthregional' ? "widthregional" : ""} `}>
          {
            columns.map((value, key) => {
              return (
                <Col key={value} className={`mr-2 float-left ${tierType === 'tiered' ? "width7p3 table-border" : tierType === 'regionaltype' ? "widthreggioanl width9p3 table-border" : tierType === 'widthregional' ? "widthregionSummary width9p3 table-border" : "width9p3 table-border"}`}>
                  <span className="grey-txt">{value}</span>
                </Col>
              )
            })
          }
        </Col>
        <Col span={24} className="mt-2 ">
          {
            rowValues && rowValues.map((row, rowIndex) => {
              let rowLength = rowValues.length - 1;
              return (
                <Col span={24} key={`tableRow_${rowIndex}`} className={`${tierType === 'widthregional' ? "widthregional" : ""} `}>
                  {
                    row.cells && row.cells.map((cell, cellIndex) => {
                      return (
                        <Col
                          className={`mr-2 mt-0 float-left 
                                  ${tierType === 'tiered' ? "width7p3 tiering-input input-style" : "width9p3 without-tiering-input input-style"}
                                  ${cell.editable === false ? "pointer-events-none" : ""}
                                  ${tierType === "summaryPage" ? "pointer-events-none" : ""}
                                  ${tierType === "widthregional" ? "pointer-events-none" : ""}
                                  ${cell.isEdited && cell.isEdited === true && cell.value !== '' && tierType !== "summaryPage" ? 'orangeborder-input' : ''}`
                          }
                          key={cellIndex}
                        >
                          <AWSinput
                            id={"index"}
                            label={cell.editable === false ? "" : "Polaris"}
                            onChangeFunc={(val) => this.handleChange(val, rowIndex, cellIndex)}
                            onBlurFunc={(val) => this.handleBlur(val, rowIndex, cellIndex, cell.tierKey)}
                            placeholder={""}
                            value={cell.value.toString()}
                            canEditPPR={this.props.canEditPPR}
                            userRole={userRole}
                          />
                        </Col>
                      )
                    })
                  }
                  {(tierType !== "summaryPage" && rowLength === rowIndex && rowLength > 1) && (<Col className="float-left" key={rowIndex}>
                    <span className="mt-2 mr-3 float-left table-icons">
                      <img src={Plusicon} alt="plusicon" onClick={() => { this.handleOnAdd() }} />
                    </span>
                  </Col>)}
                  {tierType !== "summaryPage" && rowIndex > 2 ?
                    <span className="float-left minus-icon mt-2 table-icons">
                      <img src={Minusicon} alt="minus" onClick={() => { this.handleOnDelete(rowIndex) }} />
                    </span>
                    : ''
                  }
                </Col>

              )
            })
          }
          {this.state.error && (
            <AWSError
              errorLabel={this.state.errorLabel}
            />
          )}
        </Col>

      </div>
    )
  }
}

export default AWSPolarisTable;

AWSPolarisTable.propTypes = {
  tierType: PropTypes.string,
  volumeType: PropTypes.any,
  columns: PropTypes.array,
  rowValues: PropTypes.array,
  onChangeFunc: PropTypes.func,
  onBlurFunc: PropTypes.func
};