import React, { Component } from 'react';
import AWSDropdown from '../../common/AWSDropdown/AWSDropdown';
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSCheckBox from "../../common/AWScheckbox/AWSDynamicCheckBox";
import AWSTextArea from '../../common/AWStextarea/AWStextarea';
import Plusicon from "../../../images/Pluss_Icon.svg";
import Minusicon from '../../../images/minu_icon.svg';
import AWSTags from '../../common/AWSTags/AWSTags';
import './Question.scss';

/*
    **React functional component
    @param {number index, object handleChange, array optionJsonValue}
    @return {dynamically create AWSinput tags}
*/
const AWSINPUT = ({ index, handleChange, optionsJsonValue }) => {
    let indexForInput = index

    return (<AWSInput
        placeholder=''
        value={optionsJsonValue && optionsJsonValue.optionValue}
        onChangeFunc={(value) => { handleChange(value, "options", indexForInput) }}
    />)
}

/*
    @param {number index, object handleChange, array optionJsonValue}
    @return {dynamically create AWSCHECKBOX tags}
*/
const AWSCHECKBOX = ({ index, handleChange, defJson }) => {
    const indexForBox = index;
    return (<AWSCheckBox
        label={""}
        index={index}
        value={defJson && defJson.defaultAnswer && defJson.defaultAnswer[index]}
        onChangeFunc={(value) => { handleChange(value, 'isDefault', indexForBox) }}
    />)
}

class Question extends Component {

    state = {
        inputs: [],
        checkboxes: [],
        selectedValidationCheckBox: [],
        validationChecked: [],
        validationListOptionsOnQueType: [],
        selectedQuestion: '',
        count: 1,
    }

    componentDidMount() {
        let { questionJson } = this.props;
        if (questionJson && questionJson.queBlock[0]) {
            this.setState({
                selectedValidationCheckBox: questionJson.queBlock[0].questions[0].validation
            })
            let selectedValidationCheckBoxToValue = questionJson.queBlock[0].questions[0].validation.map((selectedValidationCheckBox) => {
                return selectedValidationCheckBox.label
            })
            this.setState({
                validationChecked: selectedValidationCheckBoxToValue
            })

            if (questionJson.queBlock[0].questions[0].options.length > 1) {
                questionJson.queBlock[0].questions[0].options.map((option, optionIndex) => {
                    this.addMultipleInput();
                })
            } else {
                this.addInput();
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.questionJson !== this.props.questionJson) {
            let { questionJson } = this.props;
            let { validationListOnQueType } = this.props;
            if (questionJson && questionJson.queBlock[0]) {
                let questionValue = questionJson.queBlock[0].questions[0].queType;
                this.setState({
                    selectedValidationCheckBox: questionJson.queBlock[0].questions[0].validation,
                    validationListOptionsOnQueType: validationListOnQueType.filter(({ queType }) => queType === questionValue)
                });
                const { validation } = questionJson.queBlock[0].questions[0];
                let selectedValidationCheckBoxToValue;
                if (validation && validation.length > 0) {
                    selectedValidationCheckBoxToValue = questionJson && questionJson.queBlock[0].questions[0].validation.map((selectedValidationCheckBox) => {
                        return selectedValidationCheckBox.label
                    });
                }
                else selectedValidationCheckBoxToValue = '';

                this.setState({
                    validationChecked: selectedValidationCheckBoxToValue
                })

                if (questionJson.queBlock[0].questions[0].options.length > 1) {
                    questionJson.queBlock[0].questions[0].options.map((option, optionIndex) => {
                        this.addMultipleInput();
                    })
                } else {
                    this.addInput();
                }
            }
        }
    }



    static getDerivedStateFromProps(props, state) {
        const { validationListOptionsOnQueType } = state;
        return { validationListOptionsOnQueType: props.validationListOnQueType }
    }

    /*
    @param {string value, string type, integer index}
    @return {handle the onchange event and add values to state}
    */
    handleQuestionChange = (value, type, index) => {
        const { handleQuestionChange, questionJson, validationListOnQueType } = this.props;
        const { validationListOptionsOnQueType, selectedQuestion } = this.state;
        const optionValues = questionJson && questionJson.queBlock[0].questions[0].options;
        if (type === 'questionType') {
            if (selectedQuestion !== value) {
                this.removeAllInput()
                if (questionJson && questionJson.queBlock[0].questions[0].queType === value) {
                    this.setState({
                        validationListOptionsOnQueType: validationListOnQueType.filter(({ queType }) => queType === value),
                        validationChecked: [],
                        selectedQuestion: value,
                    });
                }
            }
        }
        handleQuestionChange(value, type, index);
    }

    /*
    @param none
    @return {push all input field for edit question}
    */

    addMultipleInput = () => {

        let { inputs, checkboxes } = this.state;

        inputs.push(AWSINPUT);
        checkboxes.push(AWSCHECKBOX);

        this.setState({ inputs, checkboxes });
    }

    /*
    @param {string type of question}
    @return {add an input field in existing node}
    */

    addInput = (type) => {
        let questionType = type;
        if (type === 'BUTTONRADIO')
            questionType = 'TOGGLE';
        const { options } = this.props;
        const { inputs, checkboxes } = this.state;
        if (options && options.length > 0) {
            const option = options.filter((opt) => opt.label === questionType)
            if (option.length > 0) {
                const { maxCount } = option[0];
                if (this.state.count <= maxCount) {
                    const inputs = this.state.inputs.concat(AWSINPUT);
                    const checkboxes = this.state.checkboxes.concat(AWSCHECKBOX);
                    const count = this.state.count + 1;
                    this.setState({ inputs, count, checkboxes });
                }
            } else if (type === undefined) {
                inputs.push(AWSINPUT);
                checkboxes.push(AWSCHECKBOX);
                this.setState({ inputs, checkboxes });
            }
        } else {
            const inputs = this.state.inputs.concat(AWSINPUT);
            const checkboxes = this.state.checkboxes.concat(AWSCHECKBOX);
            this.setState({ inputs, checkboxes });
        }
    }

    /*
    @param {integer index}
    @return {remove input answer particularly}
    */

    removeInput = (index) => {
        const { removeOptions, questionJson } = this.props;
        const { inputs, checkboxes } = this.state;

        let inputLen = this.state.inputs.length - 1;
        if (inputLen === 0) console.log(`can't delete`);
        else {
            const newInputs = inputs.filter((inputVal, ipIndex) => {
                return ipIndex !== index
            });

            const newCheckBox = checkboxes.filter((boxVal, boxIndex) => {
                return boxIndex !== index
            });

            this.setState({ inputs: newInputs, checkboxes: newCheckBox });
            removeOptions(index);
        }
    }

    /*
    @param none
    @return {remove inputs and checkedbox on change of question }
    */

    removeAllInput = () => {
        const { inputs, checkboxes } = this.state;
        let inputLen = inputs.length - 1;
        if (inputLen > 0) {
            this.props.removeOptions();
            for (let i = 0; i < inputLen; i++) {
                inputs.pop();
                checkboxes.pop()
            }
        } else if (inputLen === -1) return;
        else this.props.removeOptions();
    }

    /*
    @param {event}
    @return {validation added to questionjson using callbackfunction handlevalidation}
    */

    handleValidationSelected = (validationOption) => {
        console.log('validationOption', validationOption);

        let { validationListOnQueType } = this.props;
        // console.log('validationListOnQueType', validationListOnQueType);

        // const { validationChecked } = this.state;
        // validationChecked = e
        // this.setState({ validationChecked: e })

        const { handleAddValidation } = this.props;
        // let newArr = [];

        // validationListOnQueType.map((val) => {
        //     validationChecked.map((opt) => {
        //         console.log('opt', opt);

        //         if (val.value === opt) {
        //             newArr.push(val)
        //         }
        //     })
        // });

        handleAddValidation(validationOption);
    }

    /*
     @param {integer:index}
     @return {multiple checkbox}
    */

    renderCheckBox = (inputIndex) => {
        const { options, questionJson, validationListOnQueType } = this.props;

        const { inputs, validationListOptionsOnQueType, selectedValidationCheckBox, checkboxes } = this.state;

        return checkboxes && checkboxes.map((Element, index) => {
            if (index === inputIndex) {
                return (
                    <div className="float-left pt-1">
                        <span className="float-left pt-1 mb-0 checkbox-hover mr-3">
                            <div className="font-size10 dark-black tool-tip">Set default</div>
                            <Element
                                key={index}
                                index={index}
                                defJson={questionJson && questionJson.queBlock[0].questions[0]}
                                handleChange={(value, options, indexForInput) => {
                                    this.handleQuestionChange(value, options, indexForInput)
                                }}
                            />
                        </span>
                    </div>
                )
            }
        })
    }

    render() {
        const { options, questionJson, validationListOnQueType } = this.props;
        const { inputs, validationListOptionsOnQueType, selectedValidationCheckBox, checkboxes } = this.state;
        const questionType = questionJson && questionJson.queBlock[0].questions[0].queType;

        validationListOnQueType && validationListOnQueType.map((val) => {
            val.id = val.label;
            // console.log('val', val);

        });

        const INPUT = inputs && inputs.map((Element, index) => {
            return (
                <>
                    <div className="col-10 float-left p-0">
                        <Element
                            key={index}
                            index={index}
                            optionsJsonValue={questionJson && questionJson.queBlock[0].questions[0].options[index]}
                            handleChange={(value, options, indexForInput) => {
                                this.handleQuestionChange(value, options, indexForInput)
                            }}
                        />
                    </div>
                    <div className="float-left pl-3 pt-1 mt-1">
                        {this.renderCheckBox(index)}
                        {
                            (questionType === 'TEXT' || questionType === 'TEXTUP' || questionType === 'TEXTAREA') ? null
                                : <>
                                    <span className="float-left minus-icon mr-3 mt-0">
                                        <img src={Minusicon} alt="minus" className="mt-2 cursor-pointer"
                                            onClick={() => this.removeInput(index)} />
                                    </span>
                                    {(index === inputs.length - 1) &&
                                        <span className="float-left plus-icon mt-0 pr-0 pt-0">
                                            <img src={Plusicon} alt="plus" className="mt-2 cursor-pointer"
                                                onClick={() => this.addInput(questionType)} />
                                        </span>
                                    }
                                </>
                        }
                    </div>
                </>
            )
        });

        return (
            <div className="full-width body-wraper pt-4 mb-4 pl-5 pr-3 pb-0 mt-4 quest-wraper scroll-width5 moz-scroll">
                <div className="col-6 float-left pr-4 pl-0">
                    <div className="col-6 float-left mt-1 p-0 quest-dropdown">
                        <AWSDropdown
                            disableDropdown={this.props.editQuestion === true ? true : false}
                            id="questionType"
                            options={options}
                            label={"Question Type"}
                            value={questionJson && questionJson.queBlock[0].questions[0].queType}
                            handleOnSelect={(value) => { this.handleQuestionChange(value, "questionType") }}
                        />
                    </div>
                    <div className="col-6 float-left mt-5 pr-0 pt-1">
                        <div className="col-6 float-left pr-0 pl-2 checkbox-isvisble">
                            <AWSCheckBox
                                label={'Is Visible'}
                                value={questionJson && questionJson.queBlock[0].questions[0].isVisible}
                                onChangeFunc={(value) => { this.handleQuestionChange(value, "isVisible") }}
                            />
                        </div>
                        <div className="col-6 float-left p-0 ismandatory-checkbox">
                            <AWSCheckBox
                                label={'Is Mandatory'}
                                value={questionJson && questionJson.queBlock[0].questions[0].isMandatory}
                                onChangeFunc={(value) => { this.handleQuestionChange(value, "isMandatory") }}
                            />
                        </div>
                    </div>
                    <div className="full-width mt-4 p-0 col-10">
                        <p className="font-size14 mb-2">Question Label</p>
                        <AWSTextArea
                            placeholder={''}
                            value={questionJson && questionJson.queBlock[0].questions[0].queLabel}
                            onChangeFunc={(value) => { this.handleQuestionChange(value, "queLabel") }}
                        />
                    </div>
                    {questionType === 'AUTOCOMPLETE' || questionType === 'CALENDAR' || questionType === 'MULTILINELABEL' || questionType === 'TEXT'
                        || questionType === 'TEXTAREA' || questionType === 'FILEINPUT' || questionType === 'RADIOBUTTON' ? null :
                        <div className='full-width pt-4 pl-0'>
                            <p className="dark-black mb-2">{questionType === 'RATETABLE' ? 'Columns' : 'Answer options'}</p>

                            <div className="full-width p-0 mt-0 pt-1">
                                {INPUT}
                            </div>

                        </div>
                    }

                </div>
                <div className="col-6 float-left pr-1 mt-0 ">
                    <p className="dark-black mb-2">Validation</p>
                    <div className="full-width float-left validation-group p-4 scroll-width5 moz-scroll">
                        <div className="full-width tags-container mt-3">
                            <AWSTags
                                deselectedOptions={validationListOnQueType}
                                validationChecked={this.state.validationChecked}
                                handleValidationSelected={this.handleValidationSelected}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 float-right pr-1 mt-3">
                    <p className="mb-2">Help Text</p>
                    <AWSTextArea
                        placeholder={''}
                        value={questionJson && questionJson.queBlock[0].questions[0].helpText}
                        onChangeFunc={(value) => { this.handleQuestionChange(value, "helpText") }}
                    />
                </div>
            </div>
        )

    }
}

export default Question;
