

export let userColumns = [
    {id: "FirstName", comparator: (item1, item2) => {
        const firstField = item1.FirstName;
        const secondField = item2.FirstName;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "LastName", comparator: (item1, item2) => {
        const firstField = item1.LastName;
        const secondField = item2.LastName;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "AWSAlias", comparator: (item1, item2) => {
        const firstField = item1.AWSAlias;
        const secondField = item2.AWSAlias;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "UserRole", comparator: (item1, item2) => {
        const firstField = item1.UserRole;
        const secondField = item2.UserRole;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "UserStatus", comparator: (item1, item2) => {
        const firstField = item1.UserStatus;
        const secondField = item2.UserStatus;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }}
]



export let roleColumns =  [
    {id: "roleName", comparator: (item1, item2) => {
        const firstField = item1.roleName;
        const secondField = item2.roleName;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "parentRole", comparator: (item1, item2) => {
        const firstField = item1.parentRole;
        const secondField = item2.parentRole;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }}
]
