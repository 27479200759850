import React, { Component } from "react";
import downArrowIcon from '../../../images/awsfalcondownArrow.svg';
import axios from "axios";
import AWSConfig from "../../../config/AWSconfig";
import moment from "moment";
import { Spinner } from "@amzn/awsui-components-react";

const config = new AWSConfig();

const baseUrl = config.amazonFalconbaseUrl;

class AuditTrail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            auditTrailData: null,
            dataList: [],
            showAuditTrailData: false
        }
    }



    handleOnClick = () => {
        if (!this.state.showAuditTrailData) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.displayAuditTrailData()
    }

    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (this.node && this.node.contains && this.node.contains(e.target)) {
            return;
        }

        this.handleOnClick();
    }

    displayAuditTrailData = () => {
        if (this.state.showAuditTrailData === true) {
            this.setState({
                showAuditTrailData: false
            })
        } else {
            this.setState({
                showAuditTrailData: true
            })
            this.getAuditTrailData()
        }
    }

    getAuditTrailData = () => {
        const { alias, pprId, createdById } = this.props
        let awsAliasOfCreator = null

        if (createdById === null) {
            awsAliasOfCreator = alias
        } else {
            awsAliasOfCreator = createdById
        }


        this.enableOrDisableLoader(true)
        axios({
            method: "POST",
            url: `${baseUrl}dashboard/auditTrail`,
            data: {
                AWSAlias: awsAliasOfCreator,
                pprId: 'PPR-' + pprId
            }
        })
            .then(resp => {
                let auditTrailDataList = []
                auditTrailDataList = resp.data;
                if (auditTrailDataList.length) {
                    auditTrailDataList.unshift({ data: "New" })
                }

                this.setState({
                    dataList: auditTrailDataList
                })
                this.enableOrDisableLoader(false)

            })
            .catch(() => {
                this.enableOrDisableLoader(false)
            });
    };

    enableOrDisableLoader = showLoader => {
        this.setState({
            loading: showLoader
        })
    }

    callAuditPprApi = (headerData, pprId) => {
        if (headerData.data === "New") {
            this.props.onComponentRendered(null,"refreshOnId")
        } else {
            headerData.pprId = "PPR-" + pprId
            this.props.onComponentRendered(headerData, 'audit')
        }

    }

    render() {
        let { loading, auditTrailData, dataList, showAuditTrailData } = this.state;
        const { alias, pprId, locationState, history } = this.props;

        return (
            <div className="width-auto float-right audit-history">
                <div
                    className="pl-0 version-txt cursor-pointer"
                    onClick={() => this.handleOnClick(pprId)}
                >
                    <span className="font-size14 pr-3">Audit Trail</span> <span className="down-arrow float-right">
                        <img src={downArrowIcon} alt="downArrow" /></span>
                </div>

                <div ref={node => { this.node = node; }} className={this.state.showAuditTrailData === true ? "audit-trail" : ""}>
                    {
                        showAuditTrailData && (loading === false) && dataList && dataList.map((data, index) => {
                            if (data && data.data === "New") {
                                return (
                                    <li
                                        key={index + data.date}
                                        onClick={() => { this.callAuditPprApi(data) }}
                                        className="cursor-pointer audit-history"
                                    >
                                        <div className="txt">
                                            <span className="mr-3 float-left">Return to current status</span>
                                        </div>
                                    </li>
                                )
                            } else {
                                return (
                                    <li
                                        key={index + data.date}
                                        onClick={() => { this.callAuditPprApi(data, pprId) }}
                                        className="cursor-pointer audit-history"
                                    >
                                        <div className="txt">
                                            <span className="mr-3 float-left">{data.data}</span>
                                        </div>
                                        <div className="txt">

                                            <span className="float-right text-right">
                                                {moment(data.date).format("MM-DD-YYYY  hh:mm A")}
                                            </span>
                                        </div>
                                    </li>
                                )
                            }
                        })
                    }
                    {
                        showAuditTrailData && (loading === true) &&
                        <li key={0} >
                            <div>
                                <Spinner variant="disabled" size="normal"></Spinner>
                            </div>
                        </li>
                    }

                    {
                        showAuditTrailData && (loading === false) && dataList.length === 0 &&
                        <li key={0} >
                            <div>
                                No Data Available
                              </div>
                        </li>
                    }
                </div>
            </div>

        )
    }
}

export default AuditTrail