

export let email = [
    {id: "subject", comparator: (item1, item2) => {
        const firstField = item1.subject;
        const secondField = item2.subject;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "userRole", comparator: (item1, item2) => {
        const firstField = item1.userRole;
        const secondField = item2.userRole;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "dealStatus", comparator: (item1, item2) => {
        const firstField = item1.dealStatus;
        const secondField = item2.dealStatus;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "body", comparator: (item1, item2) => {
        const firstField = item1.body;
        const secondField = item2.body;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "type", comparator: (item1, item2) => {
        const firstField = item1.type;
        const secondField = item2.type;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }}
]



export let sla =  [
    {id: "subject", comparator: (item1, item2) => {
        const firstField = item1.subject;
        const secondField = item2.subject;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "dealStatus", comparator: (item1, item2) => {
        const firstField = item1.dealStatus;
        const secondField = item2.dealStatus;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "SLA", comparator: (item1, item2) => {
        const firstField = item1.SLA;
        const secondField = item2.SLA;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "body", comparator: (item1, item2) => {
        const firstField = item1.body;
        const secondField = item2.body;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }},
    {id: "type", comparator: (item1, item2) => {
        const firstField = item1.type;
        const secondField = item2.type;
        return firstField > secondField ? 1 : firstField < secondField ? -1 : 0;
    }}
]
