import React, { Component } from 'react';
import './AWSButtonRadio.scss'
import Col from '../../common/AWSCol/AWSCol';
// import { Icon } from '@shopify/polaris';
import _ from 'underscore';

export default class AWSPricingRadio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      errorLabel: "",
      error: false
    }
  }

  validate(validationArray, val) {
    _.each(validationArray, (validationObject, validationIndex) => {
      //test value against validation rule
      if (validationObject.type === "Required") {
        if ((_.isEmpty(val)) || (_.isNull(val))) {
          this.setState({
            error: true,
            errorLabel: validationObject.message
          })
        }
      }
    });
  }

  toggleTick(value) {
    this.setState({
      value,
      error: false
    });
    this.props.handleOnSelect(value);
  }

  render() {
    const { disabled } = this.props;
    if (this.props.triggeredNext && (!this.state.error)) {
      this.validate(this.props.validation, this.props.value)
    }
    return (
      <div className="tabs-button-wraper">
        <div>
          <ul className="p-0">
            {
              this.props.options && this.props.options.map(({ optionKey, optionValue }) => {
                const selected = this.props.value === optionKey;
                const className = disabled && selected ? "disabled-selected" :
                  disabled && !selected ? "disabled" :
                    !disabled && selected ? "active" :
                      "";
                return (
                  <li className={className} onClick={!disabled ? () => this.toggleTick(optionValue) : undefined} key={optionValue}>
                    {optionValue}
                  </li>
                );
              })
            }
          </ul>
        </div>
        <Col span={24}>
          <span className="error-wraper">
            {this.state.error && (<error className="has-error font-size10 red">
              <span className="error-icon mr-0"></span>
              {`${this.state.errorLabel} ${this.props.label}`}</error>)}
          </span>
        </Col>
      </div>
    )
  }
}
