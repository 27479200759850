import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWSDashboardheader.scss";
import whiteHome from '../../../images/awsfalconhomewhite.svg';
import whiteLogo from "../../../images/aws_logo_white.png";
import Col from "../AWSCol/AWSCol";
import Popover from '../../Dashboard/LogoutPopOver.js';
import { Link } from 'react-router-dom';
import VideoHeader from '../../../images/PlayIconHeaderWhite.svg';
import * as roleNames from '../../../constants/roleNames.const';
import userIcon from '../../../images/awsfalconprofilewhite.svg';
import downArrowIcon from '../../../images/awsfalcondownArrow_white.svg';
import { Icon } from "@amzn/awsui-components-react";
import WisdomPortalIconWhite from '../../../images/WisdomPortalIconWhite.svg';
import externalLink from '../../../images/external_link.svg';
import AWSConfig from '../../../config/AWSconfig';
import { clearSessionStorage } from '../../../utils/sessionStorageUtils';
const config = new AWSConfig();

export default class AWSDashboardheader extends Component {

    state = {
        popoverVisible: false,
    }
    /**
     * This function will call the API to get the Dashboard Records
     * Clear the session storage for Advanced Search
    */
    handleClick = () => {
        clearSessionStorage();
        this.props.getDashboardData()
    }

    navigateToBugLogPage = () => {
        window.open(config.logTicketUrl, "'_blank'")
    };
   
    render() {
        return (
                <header>
                    <div className="col-12 float-left p-0">
                        <div className="pl-4 logo col-6 float-left">
                            <Link to={
                                    { pathname: "/dashboard", state: this.props.locationData }
                                }>
                                <img className="logo-img mt-0 float-left" src={whiteLogo} alt="header" onClick={() => this.handleClick()} />
                                {/* <span className="orange width-auto font-size20 pl-2 cursor-pointer falcon-txt" onClick={() => this.handleClick()}>falcon</span> */}
                                <span className="logo-title pl-3 width-auto" onClick={() => this.handleClick()}>CloudFront Private Pricing Deal Intake Console </span>
                            </Link>
                        </div>
                        <Col span={12} className="notification-user-details float-right pr-4 pt-2">
                            <div className="float-right pt-4 cursor-pointer" onClick={() => this.setState({ popoverVisible: !this.state.popoverVisible })}>
                            <span className="float-left pr-4"><img src={userIcon} className="user-icon" alt="user" /></span>
                                <p className="font-size14 white width-auto float-left user-name-div cursor-pointer pt-0"><span className="pr-4">{this.props.firstName} {this.props.lastName}</span><span className="down-arrow float-right pl-1">
                                    <img src={downArrowIcon} alt="downArrow" /></span></p>
                                <div className="logout-div">
                                    {this.state.popoverVisible &&
                                        <Popover
                                            color={"grey"}
                                            updateUserRole={(secondaryRole, activeRole) => {
                                                this.props.updateUserRole(secondaryRole, activeRole);
                                            }}
                                            updateSPUserRole={(solutionProvider, activeRole) => {
                                                this.props.updateSPUserRole(solutionProvider, activeRole);
                                            }}
                                            updatePPOOrChinaUserRole={(activeRole) => { this.props.updatePPOOrChinaUserRole(activeRole) }}
                                         />
                                    }
                                </div>
                            </div>
                            <div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover">
                                <img src={externalLink} alt="Ticketing" className="ticket-icon" onClick={() => { this.navigateToBugLogPage() }} />
                                <span className="logo-tooltip ticketing-tooltip">SIM Ticketing</span>
                            </div>
                            <div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover">
                                <img src={WisdomPortalIconWhite} alt="Wisdom Portal" className="wisdom-icon" onClick={() =>window.open("https://wisdom.corp.amazon.com/Pages/PP-RC-Cloudfront.aspx","_blank")}/>
                                <span className="logo-tooltip wisdom-tooltip">AWS Wisdom Portal</span>
                            </div>
                            {this.props.location === roleNames.SALES_REPRESENTATIVE && (<div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover header-youtube-icon" onClick={() =>window.open("https://broadcast.amazon.com/channels/14832/playlists/4066","_blank")}>
                                    <img src={VideoHeader} alt="Video" className="video-icon"/>
                                    <span className="logo-tooltip training-tooltip">Training Videos</span>
                            </div>)}

                            <div className="float-right pt-3 pr-4 mt-2 logo-hover">
                                <Link to={
                                    { pathname: "/dashboard", state: this.props.locationData }
                                }>
                                    <img src={whiteHome} alt="home" className="user-icon home-icon" onClick={() => this.handleClick()} />
                                </Link>
                                <span className="logo-tooltip home-tooltip">Home</span>
                            </div>
                            {/* <div className="float-right error-icon pt-4 pr-3 cursor-pointer" onClick={() => { this.navigateBugLogPage() }}>
                                <img src={externalIcon} alt="error" />
                            </div> */}
                        </Col>
                    </div>
                </header>
        );
    }
}

AWSDashboardheader.propTypes = {
    whitelogo: PropTypes.bool,
    whiteUser: PropTypes.bool,
    getDashboardData: PropTypes.func,
    firstName: PropTypes.any,
    lastName: PropTypes.any
};
