import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AWSDashboardCard.scss";
import * as roleNames from '../../../constants/roleNames.const';
const isHovered= false;

export default class AWSDashboardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  handleEnter() {
    this.setState({
      isHovered: true 
    });
  }

  handleLeave() {
    this.setState({
      isHovered: false 
    });
  }

  render() {
    const { cardText, bgColor, count, userRole ,helpText} = this.props;
    return (
      <div
        className={
          this.props.userRole === roleNames.SALES_REPRESENTATIVE ||
          this.props.userRole === roleNames.PRIVATE_PRICING_CHINA ||
          this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
            ? `${bgColor} sales-rep`
            : ( this.props.userRole === roleNames.BILLING_TEAM || this.props.userRole === roleNames.SOLUTION_PROVIDER )
            ? `billing-dashboard ${bgColor} other-logins`
            : `${bgColor} other-logins`
        }
        onMouseEnter={this.handleEnter.bind(this)}
        onMouseLeave={this.handleLeave.bind(this)}
      >
        <p className="float-left white font-size16 col-12 p-0 mt-3">
          {cardText}
        </p>
        <p className="text-right float-left white font-size30 col-12 p-0 mb-0 count-txt">
          {count}
        </p>
        {this.state.isHovered ? <div className="card-helptext">{helpText}</div> : "" }
      </div>
    );
  }
}
AWSDashboardCard.propTypes = {
  cardText: PropTypes.string,
  bgColor: PropTypes.string,
  count: PropTypes.number,
  handelOnClick: PropTypes.func
};
