import React, { Component } from "react";
import moment from 'moment';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { discountEffectiveDateListForMultiSelect } from '../../utils/disscountEffDateList';
import { copyToClipFirefox } from '../../utils/copy';
import { downloadCSV } from './../../utils/downloadCSV';
import './MonthlyActivity.scss';
import AWSbutton from '../common/AWSbutton/AWSbutton';
import downloadIcon from '../../images/download_grey.svg';
import AWSDropdown from '../common/AWSDropdown/AWSDropdown';
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import dealTypeJsonMA from "../../json/monthlyActivityDealType.json";
import AWSconfig from "../../config/AWSconfig";
import geoJson from '../../json/geoForReportMultiSelect.json';
import orgChangeGeoJson from '../../json/orgChangeGeoForReportingMultiselect.json';
import subgeoJson from '../../json/subGeoForReportMultiSelect.json';
import businessCustomerTypeJson from '../../json/businessCustomerTypeMultiSelect.json'

import buJson from '../../json/buForMultiSelect.json';
import commitJson from '../../json/commitTypeForMultiSelect.json';
import cfrcTypeJson from '../../json/cfrcTypeForMultiSelect.json';
import channelJson from '../../json/channelForMultiSelect.json';
import orgChangeBusinessUnitOptionsMultiSelect from '../../json/orgChangeBusinessUnitOptionsMultiSelect.json';

import dateRangeJson from '../../json/dateRange.json';
import pprtypeJson from '../../json/pprType.json';
import dealOpenCloseJson from '../../json/dealOpenClose.json';
import AWSInput from '../common/AWSInput/AWSinput';
import { Multiselect } from "@amzn/awsui-components-react";

import CopyBlackIcon from "../../images/Copyblack.svg";
import PropTypes from "prop-types";
import { isAfterOrgChange } from "../../utils/common";
const isFirefox = typeof InstallTrigger !== 'undefined';
const config = new AWSconfig();
const monthlyMetricsUrl = `${config.reportUrl}monthlyActivityMetrics`;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
axios.defaults.headers.common["Content-Type"] = 'application/json'

class MonthlyActivity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterDate: '',
            salesManagerApprovalDte: '',
            fullyExwcutedDate: '',
            submittedStartDate: null,
            submittedEndDate: null,
            smStartDate: null,
            smEndDate: null,
            fullyExecStartDate: null,
            fullyExecEndDate: null,
            monthlyActivityData: [],
            subgeoOptions: [],
            cfrctype: [],
            committype: [],
            businessUnit: [],
            geo: [],
            dealtype: [],
            channel: [],
            pprType: '',
            activateToast: false,
            loadingMessage: '',
            termLength: '',
            disTermDate: [],
            validation: [{ message: 'Please Enter Valid Character', type: 'Regex', criteria: '^[0-9]*$' }],
            triggeredNext: false,
            questionValue: { isVisible: true, isMandatory: true },
            dealStage: '',
            maxDate: new Date(),
            discountTermSelectedOptions: [],
            geoSelectedOptions: [],
            geo: [],
            subGeo: [],
            subGeoSelectedOptions: [],
            cfrcSelectedOptions: [],
            commitTypeSelectedOptions: [],
            buSelectedOptions: [],
            channelSelectedOptions: [],
            dealStatusSelectedOptions: [],
            dealtypeSelectedOptions: [],
            businessCustomerTypeOptions: []
        }

        this.baseState = this.state;
        this.copyRef = React.createRef();
    }


    componentDidMount() {
        this.generateMonthlyMetric();
    }

    showsalesReportToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    handleSelectedDateRange = (value, id) => {
        if (value === undefined) {
            value = 'Current Month';
        }
        if (id === 'ppr_sub_date' && this.state.filterDate !== value) {
            const result = this.setDateFor(value);
            this.setState({
                submittedStartDate: result['startDate'],
                submittedEndDate: result['endDate'],
                filterDate: value
            });
        } else if (id === 'sales_app_date' && this.state.salesManagerApprovalDte !== value) {
            const result = this.setDateFor(value);
            this.setState({
                smStartDate: result['startDate'],
                smEndDate: result['endDate'],
                salesManagerApprovalDte: value
            })
        } else if (id === 'fully_exec_date' && this.state.fullyExwcutedDate !== value) {
            const result = this.setDateFor(value);
            this.setState({
                fullyExecStartDate: result['startDate'],
                fullyExecEndDate: result['endDate'],
                fullyExwcutedDate: value
            })
        }
        else {
            console.log('No values are allowed here');
        }
    }

    setDateFor = (value) => {
        let startDate, endDate, startOfDate;
        let currentDate = moment();
        let dayStart = currentDate.clone().startOf("day").utc().format();
        startOfDate = new Date(dayStart);
        switch (value) {
            case "Today":
                startDate = new Date(dayStart);
                endDate = new Date(moment(startDate).utc().add(24, 'hours').format());
                break;
            case "Current Week":
                var weekStart = currentDate.clone().startOf("isoWeek").utc().format();
                startDate = new Date(weekStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Current Month":
                var monthStart = currentDate.clone().startOf("month").utc().format();
                startDate = new Date(monthStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Current Quarter":
                var quarterStart = currentDate.clone().startOf("quarter").format();
                startDate = new Date(quarterStart);
                endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                break;
            case "Custom Dates":
                var cusmonthStart = currentDate.clone().startOf("month").utc().format();
                startDate = new Date(cusmonthStart);
                endDate = new Date(moment().utc().format());
                break;
            default:
        }

        return { startDate, endDate };
    }

    handleChange = (val, id) => {
        const valType = typeof (val);
        switch (id) {
            case 'channel_type':
                this.setState({ channel: val.selectedIds, channelSelectedOptions: val.selectedOptions })
                break;
            case 'ppr_type':
                if (valType === "object")
                    this.setState({ pprType: '' });
                else if (val === "Select")
                    this.setState({ pprType: '' });
                else this.setState({ pprType: val });
                break;
            case 'deal_type':
                this.setState({ dealtype: val.selectedIds, dealtypeSelectedOptions: val.selectedOptions })
                break;
            case "bu":
                this.setState({ businessUnit: val.selectedIds, buSelectedOptions: val.selectedOptions })
                break;

            case "commit_type":
                this.setState({ commitType: val.selectedIds, commitTypeSelectedOptions: val.selectedOptions })
                break;

            case 'geo':
                if (val.selectedIds.length === 1 && val.selectedIds.includes("GLBL")) {
                    this.setState({ subGeo: [], subGeoSelectedOptions: [], geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                } else {
                    this.setState({ geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                }
                break;

            case 'subGeo':
                this.setState({ subGeo: val.selectedIds, subGeoSelectedOptions: val.selectedOptions })
                break;
            case 'cfrc_type':
                this.setState({ cfrcType: val.selectedIds, cfrcSelectedOptions: val.selectedOptions })
                break;
            case 'termLength':
                this.setState({ termLength: val });
                break;
            case 'disTerm':
                this.setState({ disTermDate: val.selectedIds, discountTermSelectedOptions: val.selectedOptions })
                break;
            case 'deal_stage':
                if (valType === "object")
                    this.setState({ dealStage: '' });
                if (val === "Select")
                    this.setState({ dealStage: '' });
                else this.setState({ dealStage: val });
                break;
            case "start_pprsubmit_date":
                this.setState({ submittedStartDate: val });
                break;
            case "end_pprsubmit_date":
                this.setState({ submittedEndDate: val });
                break;
            case "sm_start_date":
                this.setState({ smStartDate: val });
                break;
            case "sm_end_date":
                this.setState({ smEndDate: val });
                break;
            case "exec_start_date":
                this.setState({ fullyExecStartDate: val });
                break;
            case "exec_end_date":
                this.setState({ fullyExecEndDate: val });
                break;
            case "business_customer_type":
                this.setState({ businessCustomerType: val.selectedIds, businessCustomerTypeOptions: val.selectedOptions })
                break;
            default:
        }

    }

    generateMonthlyMetric = () => {
        const {
            submittedStartDate,
            submittedEndDate,
            smStartDate,
            smEndDate,
            cfrctype,
            committype,
            businessUnit,
            geo,
            subGeo,
            dealtype,
            channel,
            pprType,
            disTermDate,
            termLength,
            dealStage,
            fullyExecStartDate,
            fullyExecEndDate,
            filterDate,
            businessCustomerType
        } = this.state || {};

        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};

        let _submittedEndDate = submittedEndDate, _smEndDate = smEndDate;

        if (filterDate === 'Custom Dates') {
            if (submittedEndDate !== undefined)
                _submittedEndDate = moment(submittedEndDate).clone().startOf("day").utc().add(24, 'hours').format();
            else if (smEndDate !== undefined)
                _smEndDate = moment(smEndDate).clone().startOf("day").utc().add(24, 'hours').format();
        }

        let disTermDte;
        if (disTermDate === undefined)
            disTermDte = '';
        else disTermDte = disTermDate;

        const reqBodyJson = {
            "submittedStartDate": submittedStartDate,
            "submittedEndDate": _submittedEndDate,
            "salesManagerAppStartDte": smStartDate,
            "salesManagerAppEndDte": _smEndDate,
            "fullyExecStartDate": fullyExecStartDate,
            "fullyExecEndDate": fullyExecEndDate,
            "pprType": pprType,
            "dealType": dealtype,
            "channel": channel,
            "geo": geo,
            "sub_geo": geo.length === 1 && geo.includes("GLBL") ? subGeo : [],
            "bu": businessUnit,
            "cfrcType": cfrctype,
            "commitType": committype,
            "termLength": termLength,
            "disTermDate": disTermDte,
            "dealReady": dealStage,
            "AWSAlias": alias,
            "userRole": userRole,
            "businessCustomerType": businessCustomerType
        }
        this.showsalesReportToast('Please wait while Monthly Report is loading...', true);
        axios({
            method: 'POST',
            url: monthlyMetricsUrl,
            data: reqBodyJson
        })
            .then(resp => {
                if (resp.data.statusCode >= 400)
                    this.props.handleDisplayErr(resp.data.statusCode);
                else
                    this.setState({ monthlyActivityData: resp.data });
            }).catch((err) => {
                if (err.status && (err.status >= 400))
                    this.props.handleDisplayErr(err.status);
                else if (err.response) {
                    const { response: { data: { message }, status } } = err;
                    if (status === 401)
                        this.showsalesReportToast(message, true);
                    else if (status >= 400)
                        this.props.handleDisplayErr(status);
                }

            });
    }

    resetFilter = () => {
        this.setState(this.baseState, () => {
            this.generateMonthlyMetric();
        });
    }

    loadToastForCopy = () => {
        this.showsalesReportToast('Copied....', true);
        setTimeout(() => {
            this.showsalesReportToast('Copied....', false);
        }, 1500)
    }

    callToCopy = () => {
        this.loadToastForCopy();
        isFirefox ? copyToClipFirefox(this.copyRef) : this.copyContent();
    }

    copyContent = () => {
        this.loadToastForCopy();
        window.getSelection().removeAllRanges();
        var range = document.createRange();

        const div = this.copyRef.current;
        const color = div.style.background;
        div.style.margin = "10px";
        div.style.background = "#ffffff";

        document.getElementById("copyContent").style.visibility = "hidden";
        range.selectNode(div);
        window.getSelection().addRange(range);
        try {
            // Now that we've selected the anchor text, execute the copy command
            document.designMode = "on";

            document.execCommand("foreColor", false, "black");
            document.execCommand("copy");
            document.designMode = "off";
        } catch (err) {
            console.warn("Copying warning", err);
        } finally {
            document.getElementById("copyContent").style.visibility = "visible";
            div.style.margin = "0px";
            div.style.background = color;
            document.designMode = "on";
            document.designMode = "off";
            window.getSelection().removeAllRanges();
        }
    };

    downlodCSVFile = () => {
        this.showsalesReportToast('Preparing the CSV FILE to be dowloaded...', true);
        const { monthlyActivityData } = this.state;
        downloadCSV(monthlyActivityData, 'exportmonthlymetrics');
    }


    render() {
        const {
            filterDate,
            salesManagerApprovalDte,
            submittedStartDate,
            submittedEndDate,
            smStartDate,
            smEndDate,
            fullyExwcutedDate,
            fullyExecStartDate,
            fullyExecEndDate,
            monthlyActivityData,
            cfrctype,
            committype,
            businessUnit,
            geo,
            dealtype,
            channel,
            pprType,
            subgeoOptions,
            loadingMessage,
            disTermDate,
            termLength,
            validation,
            triggeredNext,
            questionValue,
            dealStage,
            maxDate,
            discountTermSelectedOptions,
            geoSelectedOptions,
            subGeoSelectedOptions,
            cfrcSelectedOptions,
            commitTypeSelectedOptions,
            buSelectedOptions,
            channelSelectedOptions,
            dealtypeSelectedOptions,
            businessCustomerTypeOptions
        } = this.state;
        return (
            <div className="mothlyactivity-wraper full-width">
                <div className="col-9 float-left">
                    <h1 className="font-size18 amber-bold">Monthly Activity Metrics</h1>
                    {this.state.filterDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={submittedStartDate}
                                    onChange={(value) => this.handleChange(value, "start_pprsubmit_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={submittedEndDate}
                                    onChange={(value) => this.handleChange(value, "end_pprsubmit_date")}
                                    minDate={submittedStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.salesManagerApprovalDte === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={smStartDate}
                                    onChange={(value) => this.handleChange(value, "sm_start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={smEndDate}
                                    onChange={(value) => this.handleChange(value, "sm_end_date")}
                                    minDate={smStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.fullyExwcutedDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="float-left date-wraper col-6 start-date">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={fullyExecStartDate}
                                    onChange={(value) => this.handleChange(value, "exec_start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="float-left totxt">To</span>
                            <span className="float-left date-wraper col-6 end-date pl-5 pr-0">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={fullyExecEndDate}
                                    onChange={(value) => this.handleChange(value, "exec_end_date")}
                                    minDate={fullyExecStartDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    <div className="generate-btn col-12 float-left p-0">
                        <div className="col-8 float-left p-0">
                            <AWSbutton
                                btnType="fill"
                                label="Generate"
                                isIcon={false}
                                onClick={this.generateMonthlyMetric}
                            />
                        </div>

                        <div className="float-right p-0">
                            <p className="float-left mb-0"><div className="float-right font-size12  cursor-pointer btn-copy noselect generate-ptsicon"
                                id="copyContent"
                                onClick={() => this.callToCopy()}
                            >
                                Copy
                                            <img src={CopyBlackIcon} alt="copy" className="ml-2" />
                            </div>
                            </p>
                            <p className="float-left width-auto ml-4 cursor-pointer mb-0 donwload" onClick={this.downlodCSVFile}><span className="font-size12 float-left pr-2">Download</span><img src={downloadIcon} alt="download" /></p>
                        </div>
                    </div>

                    <div ref={this.copyRef} className="full-width monthly-table mt-4">

                        <table className="p-0 m-0" border='1' borderColor="#cecece">
                            <tbody>
                                {monthlyActivityData && monthlyActivityData.map((monthlyreport, index) => {
                                    let metric = Object.keys(monthlyreport.Metrics);
                                    return metric && metric.map((metricVal, metricValIndex) => {
                                        return (
                                            <tr key={metricValIndex}>
                                                <td>{metricVal}</td>
                                                <td>{monthlyreport.Metrics[metricVal]}</td>
                                            </tr>
                                        )
                                    })
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-3 float-left pr-0">
                    <p className="font-size14 amber-bold">Filters</p>
                    <ul className="full-width m-0 p-0 filter-wraper pr-2">
                        <li>
                            <AWSDropdown
                                id="deal_stage"
                                label="Open/Closed"
                                options={dealOpenCloseJson}
                                value={dealStage}
                                handleOnSelect={(value) => this.handleChange(value, "deal_stage")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="date_filter"
                                label="PPR Submitted Date"
                                options={dateRangeJson}
                                value={filterDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "ppr_sub_date")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="sales_date"
                                label="Sales Manager Approval Date"
                                options={dateRangeJson}
                                value={salesManagerApprovalDte}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "sales_app_date")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="fully_exec"
                                label="Fully Executed Date"
                                options={dateRangeJson}
                                value={fullyExwcutedDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value, "fully_exec_date")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Direct/Reseller/Solution Provider
                            </span>
                            <Multiselect
                                id="channel"
                                options={channelJson}
                                selectedOptions={channelSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'channel_type')}
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="ppr_type"
                                label="PPR Type"
                                options={pprtypeJson}
                                value={pprType}
                                handleOnSelect={(value) => this.handleChange(value, 'ppr_type')}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Deal Type
                            </span>
                            <Multiselect
                                id="deal_type"
                                options={dealTypeJsonMA}
                                selectedOptions={dealtypeSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'deal_type')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Geo
                            </span>
                            <Multiselect
                                id="geo"
                                options={isAfterOrgChange() ? orgChangeGeoJson : geoJson}
                                selectedOptions={geoSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'geo')}
                            />
                        </li>

                        {
                            geo.length === 1 && geo.includes("GLBL") && (
                                <li>
                                    <span className='input-label float-left mb-2 label-txt'>
                                        Sub-Geo
                            </span>
                                    <Multiselect
                                        id="subGeo"
                                        options={subgeoJson}
                                        selectedOptions={subGeoSelectedOptions}
                                        placeholder="Select"
                                        checkboxes={true}
                                        selectedLabel="Selected"
                                        onChange={(e) => this.handleChange(e.detail, 'subGeo')}
                                    />
                                </li>
                            )
                        }
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Discount Term Effective Date
                            </span>
                            <Multiselect
                                id="disTerm"
                                label="Select"
                                options={discountEffectiveDateListForMultiSelect()}
                                selectedOptions={discountTermSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'disTerm')}
                            />
                        </li>
                        <li>
                            <AWSInput
                                placeholder=''
                                label="Term Length (Months)"
                                value={termLength}
                                validation={validation}
                                triggeredNext={triggeredNext}
                                questionValue={questionValue}
                                showOptional={false}
                                termEndDate=''
                                onChangeFunc={(value) => this.handleChange(value, "termLength")}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                BU
                                    </span>
                            <Multiselect
                                id="bu"
                                label="Select"
                                options={isAfterOrgChange() ? orgChangeBusinessUnitOptionsMultiSelect : buJson}
                                selectedOptions={buSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'bu')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Commit Type
                                    </span>
                            <Multiselect
                                id="commit_type"
                                label="Select"
                                options={commitJson || []}
                                selectedOptions={commitTypeSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'commit_type')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                CFRC Type
                                    </span>
                            <Multiselect
                                id="cfrc_type"
                                label="Select"
                                options={cfrcTypeJson || []}
                                selectedOptions={cfrcSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'cfrc_type')}
                            />
                        </li>
                        {(isAfterOrgChange() && <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Customer Type
                                    </span>
                            <Multiselect
                                id="business_customer_type"
                                label="Select"
                                options={businessCustomerTypeJson || []}
                                selectedOptions={businessCustomerTypeOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'business_customer_type')}
                            />
                        </li>)}
                    </ul>
                    <div className="pt-4 float-left">
                        <AWSbutton
                            btnType="fill"
                            label="Reset Filter"
                            isIcon={false}
                            onClick={this.resetFilter}
                        />
                    </div>
                    {this.state.activateToast && (
                        <AWSLoadingToast
                            message={loadingMessage}
                        />
                    )}
                </div>
            </div>
        )
    }
}
export default MonthlyActivity;
MonthlyActivity.propTypes = {
    locationState: PropTypes.any,
    handleDisplayErr: PropTypes.func,
};
