import React, { useCallback, useState, useEffect } from 'react';
// import './AWSTags.scss';
import { Select, Multiselect } from '@amzn/awsui-components-react';

export default function AWSTags({ validationChecked, deselectedOptions, handleValidationSelected }) {
    const [selectedOption, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState(deselectedOptions);

    const updateText = useCallback(
        (e) => {
            const { detail: { value } } = e;
            console.log('selec', value);

            // setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option) =>
                option.label.match(filterRegex),
            );
            let endIndex = resultOptions.length - 1;
            if (resultOptions.length === 0) {
                endIndex = 0;
            }
            setOptions(resultOptions);
        },
        [deselectedOptions],
    );

    //   const removeTag = useCallback(
    //     (tag) => () => {
    //       const options = [...validationChecked];
    //       options.splice(options.indexOf(tag), 1);
    //       handleValidationSelected(options);
    //     },
    //     [validationChecked],
    //   );

    //   const tagsMarkup = validationChecked.length && validationChecked.map((option) => {
    //     if (option) {
    //       let tagLabel = '';
    //       tagLabel = option.replace('_', ' ');
    //       tagLabel = titleCase(tagLabel);
    //       return (
    //         <Tag key={`option${option}`} onRemove={removeTag(option)}>
    //           {tagLabel}
    //         </Tag>
    //       );
    //     }

    //   });

    //   const textField = (
    //     <Autocomplete.TextField
    //       onChange={updateText}
    //       label=""
    //       value={inputValue}
    //       placeholder="Select Validation"
    //     />
    //   );
    const handleChange = e => {
        const { detail: { selectedOptions } } = e;
        console.log('hey', selectedOptions);
        setSelectedOptions(selectedOptions);
        handleValidationSelected(selectedOptions);
    }


    return (
        <Multiselect
            options={options}
            selectedOptions={selectedOption}
            filteringType="manual"
            filteringLabel="Filter options"
            placeholder="Choose options"
            checkboxes={true}
            onDelayedFilteringChange={updateText}
            onChange={handleChange}
            selectedLabel="Selected"
        ></Multiselect>
    );
}
