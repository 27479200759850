import React, { Component } from 'react';
// import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
// import { ReactTabulator } from 'react-tabulator'
import './DependentQuest.scss';
import AWSDropdown from '../../common/AWSDropdown/AWSDropdown';
import AWSTextArea from '../../common/AWStextarea/AWStextarea';
import AWSAdminTable from '../AWSAdminTable/AWSAdminTable'
import axios from 'axios';
import AWSConfig from '../../../config/AWSconfig';

const config = new AWSConfig();

const dependentType = [{
    optionValue: 'Questions',
    optionKey: 'questions',
    defaultValue: null
}, {
    optionValue: 'Sections',
    optionKey: 'sections',
    defaultValue: null
}]

const eventType = [{
    optionValue: 'On Select',
    optionKey: 'On Select',
    defaultValue: null
}, {
    optionValue: 'Off Select',
    optionKey: 'Off Select',
    defaultValue: null
}]

const alertMsgs = [{
    optionValue: 'Yes',
    optionKey: 'yes',
    defaultValue: null
}, {
    optionValue: 'No',
    optionKey: 'no',
    defaultValue: null
}]

class DependentQuest extends Component {
    state = {
        dependentTypeVal: '',
        alertMsgVal: '',
        questionId: null,
        questTypes: [],
        sections: [],
        dependentOptions: [],
        selectable: false,
        showDependentSec: false,
        depOn: [],
        dependentCol: [
            {
                header: 'Question',
                id: 'queLabel',
                cell: item => <div onClick={e => this.rowClick(item)} style={{ cursor: 'pointer' }}>{item.queLabel}</div>,
                minWidth: 350,
            },
            {
                header: 'Field type',
                id: 'queType',
                cell: item => <div>{item.queType}</div>,
                minWidth: 140,
            },
            {
                header: 'Page',
                id: 'stageSeq',
                cell: item => <div>{item.stageSeq}</div>,
                minWidth: 80,
            }
        ],
        dependentSect: [
            {
                title: 'Page',
                field: 'stageSeq',
                headerSort: false,
                width: 80
            },
            {
                title: 'Section',
                field: 'queBlkHeader',
                headerSort: false,
                width: 350
            }
        ],
        err: null
    }

    /*
    @param {none}
    @return {call api to for getting all previously created question}
    */
    getAllQuestion = () => {
        const getAllQuestions = `${config.amazonFalconbaseUrl}questions`;
        axios({
            method: 'GET',
            url: getAllQuestions
        }).then(res => {
            const questTypes = res.data;
            const { editQuestion, questionJson } = this.props;
            const { questionId } = questionJson.queBlock[0].questions[0].dependentOn[0];
            if (editQuestion) {
                questTypes.forEach(quest => {
                    if (quest.queId === questionId)
                        this.setState({ dependentOptions: quest.options });
                });
            }
            this.setState({ questTypes });
        }).catch(err => this.setState({ err }));
    }
    /*
    @param {none}
    @return {call api to get all sections created under diff pages}
    */
    getAllSection = () => {
        const getAllSections = `${config.amazonFalconbaseUrl}sections`;
        axios({
            method: 'GET',
            url: getAllSections
        }).then(res => {
            const sections = res.data;
            this.setState({ sections });
        }).catch(err => this.setState({ err }));
    }

    componentDidMount() {
        const { editQuestion, questionJson } = this.props;
        let alerMsgLength;
        if (questionJson.queBlock[0].questions[0].dependentOn.length > 0) {
            if (questionJson.queBlock[0].questions[0].dependentOn[0].alertMessage !== null)
                alerMsgLength = questionJson && questionJson.queBlock[0].questions[0].dependentOn[0].alertMessage.length;
            if (editQuestion && alerMsgLength > 0) {
                this.setState({
                    alertMsgVal: 'yes'
                })
            }
            this.getAllQuestion();
        }
    }

    /*
    @param {object event, object row}
    @return {select a paricular row and set dependentOptions, questionId and depOn}
    */
    rowClick = (item) => {
        const { options, queId } = item || {};
        this.setState({ dependentOptions: options, questionId: queId });
        // let table = this.ref.table;
        // table.selectable = true;
        // table.selectablePersistence = false;
        // row.select()
        // const { _row: { data: { options, queId, dependentOn } } } = row;
        // this.setState({ dependentOptions: options, questionId: queId, depOn: dependentOn });
    };

    /*
    @param {string value, string type}
    @return {set dependentOn attribute on questionJson by calling a callback setDependentOn}
    */
    handleSelection = (value, type) => {
        const { questionId, showDependentSec, depOn } = this.state;
        const { setDependentOn } = this.props;
        switch (type) {
            case 'dependentType':
                if (value === 'questions') {
                    this.setState({ showDependentSec: false })
                    this.getAllQuestion()
                }
                else {
                    this.getAllSection();
                    this.setState({ showDependentSec: true })
                }
                this.setState({ dependentTypeVal: value });
                break;
            case 'alert':
                this.setState({ alertMsgVal: value });
                break;
        }
        setDependentOn(value, type, questionId, depOn);
    }

    /*
    @param {none}
    @return {ReactTabulator based on option choosen dependentType}
    */
    renderTable = () => {
        const { sections, dependentSect, questTypes, dependentCol } = this.state
        return (
            <>
                <div className="depent-quest-table full-width mt-4 pr-4" style={{ display: this.state.showDependentSec === true ? 'block' : 'none' }}>
                    {/* <ReactTabulator
                        data={sections}
                        columns={dependentSect}
                        tooltips={false}
                        layout={"fitData"}
                        resizableRows={false}
                        ref={ref => (this.ref = ref)}
                        rowClick={this.rowClick}
                    /> */}
                </div>
                <div className="depent-quest-table full-width mt-4 pr-4" style={{ display: this.state.showDependentSec === true ? 'none' : 'block' }}>
                    {/* <ReactTabulator
                        data={questTypes}
                        columns={dependentCol}
                        tooltips={false}
                        layout={"fitData"}
                        resizableRows={false}
                        ref={ref => (this.ref = ref)}
                        rowClick={this.rowClick}
                    /> */}
                    <AWSAdminTable
                        columns={dependentCol}
                        tableData={questTypes}
                    />
                </div>
            </>
        )
    }

    render() {
        const {
            dependentTypeVal,
            alertMsgVal,
            dependentOptions,
        } = this.state;
        const { questionJson } = this.props
        return (
            <div className="full-width dependent-quest pt-4 pr-4 pl-5 mb-4">
                <div className="col-8 float-left p-0">
                    <span className="dark-black float-left col-4 p-0 title-txt mb-2">Dependent question</span>
                    <span className="float-right col-5 pr-4">
                        {/* <AWSDropdown
                            id="dependentType"
                            defaultValue='questions'
                            label="Dependent Type"
                            options={dependentType}
                            value={dependentTypeVal}
                            handleOnSelect={(value) => { this.handleSelection(value, 'dependentType') }}
                        /> */}
                    </span>
                    <div className="depent-quest-table full-width mt-4 pr-4">
                        {this.renderTable()}
                    </div>

                </div>
                <div className="col-4 float-left">
                    <div className="full-width">
                        <AWSDropdown
                            id="event"
                            defaultValue="On Select"
                            label="Event"
                            options={eventType}
                            value={questionJson.queBlock[0].questions[0].dependentOn[0] && questionJson.queBlock[0].questions[0].dependentOn[0].event}
                            handleOnSelect={(value) => { this.handleSelection(value, 'event') }}
                        />
                    </div>
                    <div className="full-width mt-4">
                        <AWSDropdown
                            id='option'
                            defaultValue='Select'
                            label='Option'
                            options={dependentOptions}
                            value={questionJson.queBlock[0].questions[0].dependentOn[0] && questionJson.queBlock[0].questions[0].dependentOn[0].option}
                            handleOnSelect={(value) => { this.handleSelection(value, 'option') }}
                        />
                    </div>
                    <div className="full-width mt-4">
                        <AWSDropdown
                            id="alertMsg"
                            defaultValue='no'
                            label="Alert Message"
                            options={alertMsgs}
                            value={alertMsgVal}
                            handleOnSelect={(value) => { this.handleSelection(value, 'alert') }}
                        />
                    </div>
                    {alertMsgVal === 'yes' &&
                        <div className="full-width text-area mt-0">
                            <AWSTextArea
                                value={questionJson.queBlock[0].questions[0].dependentOn[0] && questionJson.queBlock[0].questions[0].dependentOn[0].alertMessage}
                                onChangeFunc={(value) => { this.handleSelection(value, 'alertMessage') }}
                            />
                        </div>
                    }
                </div>

            </div>
        )
    }
}
export default DependentQuest;