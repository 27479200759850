import React, { } from 'react';
import './LogoutPopOver.scss';
import { Link } from 'react-router-dom';
import externalLink from '../../images/external_link.svg';
import AWSConfig from '../../config/AWSconfig';
import Auth from "../../Auth/Auth";
import * as roleNames from '../../constants/roleNames.const';
const config = new AWSConfig();
const auth = new Auth();

class LogoutPopOver extends React.Component {

    state = {
        secondaryRole: localStorage.getItem(roleNames.SECONDARYROLE),
        solutionProvider: localStorage.getItem(roleNames.SOLUTIONPROVIDERROLE),
        activeRole: localStorage.getItem(roleNames.ACTIVEROLE)
    }

    logOutUser = () => {
        auth.authlogout();
    }

    componentDidMount() {
        this.setState({
            secondaryRole: localStorage.getItem(roleNames.SECONDARYROLE),
            solutionProvider: localStorage.getItem(roleNames.SOLUTIONPROVIDERROLE),
            activeRole: localStorage.getItem(roleNames.ACTIVEROLE)
        })
    }

    updateUserRole = (secondaryRole, activeRole) => {
        this.props.updateUserRole(secondaryRole, activeRole);
    }

    updateSPUserRole = (solutionProvider, activeRole) => {
        this.props.updateSPUserRole(solutionProvider, activeRole);
    }

    updatePPOOrChinaUserRole = (activeRole) => {
        this.props.updatePPOOrChinaUserRole(activeRole);
    }

    checkActiveRoleIsPPOOrChina = (activeRole) => {
        return activeRole === roleNames.PRIVATE_PRICING_CHINA || activeRole === roleNames.IGT_OWNER;
    }

    render() {
        const { color } = this.props;
        const { secondaryRole, solutionProvider, activeRole } = this.state;
        return (
            <div className={`logout-wrapper ${color === 'white' ? 'logout-white' : 'logout-grey'}`}>
                <ul className="p-0">
                    {solutionProvider && activeRole && (
                        <li onClick={() => this.updateSPUserRole(solutionProvider, activeRole)} className="grey text-left border-bottom pb-2 cursor-pointer logout-link">{activeRole === 'Solution Provider' ? "Switch to Default" : "Switch to SP"}</li>
                    )}
                    {this.checkActiveRoleIsPPOOrChina(activeRole) && (
                        <li onClick={() => this.updatePPOOrChinaUserRole(activeRole)} className="grey text-left border-bottom pb-2 cursor-pointer logout-link">{activeRole === roleNames.IGT_OWNER ? "Switch to PP China" : "Switch to PPO Owner"}</li>
                    )}
                    {activeRole && secondaryRole && (
                        <li onClick={() => this.updateUserRole(secondaryRole, activeRole)} className="grey text-left border-bottom pb-2 cursor-pointer logout-link">
                            {`Switch to ${roleNames.SHORT_ROLE_NAME[secondaryRole]}`}
                        </li>
                    )}
                    <li onClick={() => this.logOutUser()} className="grey text-left pb-1 cursor-pointer logout-link">Logout</li>
                </ul>

            </div>
        )
    }
}
export default LogoutPopOver;