import React, { Component } from "react";
import PropTypes from "prop-types";
import { Toggle } from "@amzn/awsui-components-react";

import "./AWSToggleButton.scss";
import AWSError from "../AWSError/AWSError";
import * as roleNames from "../../../constants/roleNames.const";
import * as dealStatusConstant from "../../../constants/dealStatusConstant";
import * as tagNameConstant from "../../../constants/tagNameConstant";
import { userRoleCheckForEditableCell } from "../../../utils/common";

export default class AWSToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, value } = this.props;

    if (value === null && defaultValue) {
      this.props.onChange(defaultValue);
    } else if (prevProps.value !== value) {
      this.props.onChange(value);
    }
  }

  componentDidMount() {
    const { defaultValue, value } = this.props;

    if (value !== null || defaultValue !== null) {
      this.props.onChange(value || defaultValue);
    }
  }

  onToggleChange = (event) => {
    const { quePermission, canEditPPR } = this.props;

    if (
      this.isEnabledBasedOnStatus() ||
      (canEditPPR === true &&
        (quePermission === undefined ||
          (quePermission && quePermission.read === false)))
    ) {
      const { checked } = event.detail;
      const value = checked ? "Yes" : "No";
      this.props.onChange(value);
      this.setState({
        error: false,
        errorMessage: "",
      });
    }
  };

  /**
   * this function will verify and return boolean for enabled state of field based on status, userRole and question tagname
   */
  isEnabledBasedOnStatus = () => {
    const { dealStatus, questionValue, locationStateUser, userRole, dealInfo } =
      this.props;
    const listOfQuestionBasedOnTagName = [
      tagNameConstant.autoRenewTag,
      tagNameConstant.isYourTag,
    ];

    if (
      (dealStatus === dealStatusConstant.Open_For_Review &&
        userRoleCheckForEditableCell(
          locationStateUser,
          userRole,
          dealInfo,
          roleNames
        )) ||
      userRole === roleNames.BILLING_TEAM
    ) {
      return (
        questionValue &&
        questionValue.tagName &&
        listOfQuestionBasedOnTagName.includes(questionValue.tagName)
      );
    }

    return false;
  };

  isDisabled = () => {
    const {
      questionValue,
      canEditPPR,
      isDisabledByDependency = false,
      isStandalone = false
    } = this.props;
    
    if (questionValue === undefined && !isStandalone) {
      return true;
    }

    const hasWritePermission = isStandalone ? true :
      questionValue &&
      questionValue.quePermission &&
      questionValue.quePermission.write;

    return (
      canEditPPR === false || isDisabledByDependency || !hasWritePermission
    );
  };

  validate = (validations, value) => {
    validations.forEach((validation) => {
      if (validation.type === "Confirmation" && value !== "Yes") {
        this.setState({
          error: true,
          errorMessage: validation.message,
        });
      }
    });
  };

  render() {
    const { error, errorMessage } = this.state;
    const { defaultValue, questionValue, triggeredNext, validation, value } =
      this.props;

    if (
      triggeredNext &&
      !error &&
      questionValue &&
      questionValue.isVisible &&
      validation.length > 0
    ) {
      this.validate(validation, value);
    }

    const toggleValue = value ? value : defaultValue ? defaultValue : "No";
    const isEnabledBasedOnStatus = this.isEnabledBasedOnStatus();
    const isDisabled = this.isDisabled();

    return (
      <div className="switch-wraper">
        <Toggle
          checked={toggleValue === "Yes"}
          disabled={!isEnabledBasedOnStatus && isDisabled}
          onChange={this.onToggleChange}
        >
          {value}
        </Toggle>
        {questionValue && questionValue.isVisible === true && error && (
          <AWSError errorLabel={errorMessage} />
        )}
      </div>
    );
  }
}

AWSToggleButton.propTypes = {
  canEditPPR: PropTypes.bool,
  dealInfo: PropTypes.object,
  dealStatus: PropTypes.string,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  isDisabledByDependency: PropTypes.bool,
  isStandalone: PropTypes.bool,
  label: PropTypes.string,
  locationStateUser: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAdditionalApproval: PropTypes.func,
  options: PropTypes.array,
  quePermission: PropTypes.object,
  questionValue: PropTypes.object,
  triggeredNext: PropTypes.boolean,
  userRole: PropTypes.string,
  validation: PropTypes.string,
  value: PropTypes.string 
};