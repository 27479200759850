import React, { Component } from 'react';
import Col from '../common/AWSCol/AWSCol';
import * as Constants from "../../constants/dealStructureConstants";
import AWSToggleButton from '../common/AWSToggleButton/AWSToggleButton';
import PropTypes from "prop-types";
import AWSSlider from "../common/AWSSlider/AWSSlider";
import AWSModal from "../common/AWSModal/AWSModal";
import AWSinput from '../common/AWSInput/AWSPricinginput';
import AWSAlert from "../common/AWSAlert/AWSAlert";
import AWSPolarisTable from '../common/AWSEditableTable/AWSPolarisTable';
import { Icon, Popover } from "@amzn/awsui-components-react";
import PolarisSlider from '../common/AWSSlider/PolarisSlider';
import AWSConfig from '../../config/AWSconfig';
import infoHelpIcon from '../../images/awsfalconhelp.png';
import axios from 'axios';
import Video from '../../images/Play.png';
import * as roleNames from '../../constants/roleNames.const';
import VideoIcon from '../common/Icon/VideoIcon/VideoIcon';
const config = new AWSConfig();

class RegionalDetails extends Component {

    state = {
        visible: false,
        globalLimitsData: {}
    }

    /**
     * This function used to display Toast message from parent component
     * @param {*} message message label to be displayed in the toast message
     * @param {*} toast the toast value of true or false
    */
    showDealStructureLoadingToast = (message, activeState) => {
        this.props.showDealStructureLoadingToast(message, activeState);
    }

    /**
     * This function used to show global limits value in pop over 
    */
    showModal = () => {
        this.showDealStructureLoadingToast("Loading Global Limits...", true);
        axios({
            method: "GET",
            url: `${config.pricingIntakeUrl}globallimit`,
        }).then(resp => {
            this.setState({
                globalLimitsData: resp.data,
                visible: true
            });
            this.showDealStructureLoadingToast("Loading Global Limits", false);
        }).catch(err => console.log(err));
    };

    /**
     * This function used to show/hide pop over based on state 
    */
    handleOkOrCancelOfModal = () => {
        this.setState({
            visible: false
        });
    };

    /**
     * This function used update the local state value of each input element
     * and call the commitment Fee calcualtion based on value change 
     * @param {*} value typed or selected option which need to update in the state
     * @param {*} key in which name of the particular element value to be set
     * @param {*} rowIndex the array row location where the values chaned
     * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
    */
    handleChange = (val, key, rowIndex, cellIndex) => {
        this.props.handleInputChange(val, key, rowIndex, cellIndex);
    }

    /**
     * This function used update the local state value of each input element in parent component
     * and call the commitment Fee calcualtion based on value change 
     * @param {*} val typed or selected option which need to update in the state
     * @param {*} key in which name of the particular element value to be set
     * @param {*} rowIndex the array row location where the values chaned
     * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
    */
    handleBlur = (val, key, rowIndex, cellIndex) => {
        this.props.handleInputBlur(val, key, rowIndex, cellIndex);
    }

    getRegionalMixTable = (rateCard, userRole) => {
      return (
        <Col span={19} className="mt-4 regional-mixtable">
          <p className="font-size14 dark-black float-left pt-4">
              <span className="float-left">Regional Usage Mix (in %)</span>
              <Popover content={"Please enter the regional usage mix "}
                  preferredPosition="above" size="small" position="top">
                  <span className="float-left circle-alert">
                      {/* <img src={infoHelpIcon} alt="help" /> */}
                  <Icon name="status-info"></Icon>
                  </span>
              </Popover>

          </p>
          <AWSPolarisTable
              columns={Constants.regionalUsageMixHeaders}
              rowValues={rateCard.regionalUsageMixValues}
              tierType={"notTiered"}
              onChangeFunc={(value, rowIndex, cellIndex) => { this.handleChange(value, "regionalMix", rowIndex, cellIndex) }}
              onBlurFunc={(value, rowIndex, cellIndex, key) => { this.handleBlur(value, "regionalMix", rowIndex, cellIndex, key) }}
              canEditPPR={this.props.canEditPPR}
              tableType={"regionalTraficMix"}
              userRole={userRole}
              triggeredNext={this.props.triggeredNext}
          />
          {rateCard.regionalMixmaximum === true &&
              (
                  <div className="has-error font-size10 red float-left">
                      <span className="error-icon mr-0 float-left"
                      >
                          <Icon name="status-warning"></Icon>
                      </span><span>{Constants.regionalMixVal}</span>
                  </div>
              )
          }
        </Col>
      );
    }

    render() {
        const { rateCard, canEditPPR, userRole } = this.props;
        return (
            <div className="border-bottom-grey pb-4 pt-2 full-width">
                <Col span={24}>
                    <div className="col-12 float-left p-0 pt-2">
                        <h5 className="font-size18 amber-bold pt-4 col-11 p-0 float-left">
                            Regional Limits
                            {userRole === roleNames.SALES_REPRESENTATIVE &&
                                <span className="cursor-pointer regional-limits-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192447", "_blank")}>
                                    {/* <img src={Video} alt="home" className="pricing-detail video-icon ml-2" onClick={() => window.open("https://broadcast.amazon.com/videos/192447", "_blank")} /> */}
                                    <VideoIcon/>
                                    <span className="regional-limits-tooltip">Regional Usage Mix and Regional Traffic Limits</span>
                                </span>
                            }

                        </h5>
                    </div>
                </Col>
                <Col span={24} className="mt-4">
                    <p className="font-size14 dark-black mb-3 full-width">
                        <span className="float-left">
                            Amount of combined traffic for Korea, Taiwan and Philippines in AP (%)
                        </span>
                       {/* <Tooltip
                            content={"Please select the amount of combined traffic in AP for Korea, Taiwan and Philippines"}
                            preferredPosition="above"
                        > */}
                            <span className="float-left circle-alert">
                                {/* <img src={infoHelpIcon} alt="help" /> */}
                                    <Icon name="status-info"></Icon>
                            </span>
                            {/* </Tooltip> */}
                    </p>
                    <span className="col-8 float-left p-0 mr-3 pt-4 max-width69">
                        <AWSSlider
                            options={Constants.COMBINEDTRAFFIC}
                            value={rateCard.combinedTraffic}
                            steps={1}
                            onChangeFunc={(value) => { this.handleChange(value, "combinedTraffic") }}
                        />
                    </span>
                    <span className="col-2 float-left pt-4 small-input">
                        <AWSinput
                            value={rateCard.combinedTraffic.toString()}
                            placeholder={"00"}
                            onChangeFunc={(value) => { this.handleChange(value, "combinedTraffic") }}
                            onBlurFunc={(value) => this.handleBlur(value, "combinedTraffic")}
                            userRole={userRole}
                            canEditPPR={this.props.canEditPPR}
                        />
                    </span>
                    {this.getRegionalMixTable(rateCard, userRole)}
                    {rateCard.combinedTraffic > 30 &&
                        rateCard.regionalUsageMixValues[0] &&
                        rateCard.regionalUsageMixValues[0].cells[2] &&
                        rateCard.regionalUsageMixValues[0].cells[2].value > 10 &&
                        (
                            <div className="col-10 p-0 mt-3 float-left">
                                <AWSAlert
                                    label={"This deal request requires additional approvals"}
                                />
                            </div>
                        )
                    }
                </Col>
                <Col span={24} className="mt-4 float-left pt-5">
                    <p className="font-size14 dark-black col-8 float-left toggle-txt p-0">
                        <span className="float-left">Regional Traffic Limits</span>
                        <Popover content={"Please respond with Yes or No"}
                            preferredPosition="above" size="small" position="top">

                            <span className="float-left circle-alert">
                                {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                            </span>
                        </Popover>

                    </p>
                    <span className="float-left">
                        <AWSToggleButton
                            canEditPPR={canEditPPR}
                            options={Constants.RegionalTrafficType}
                            defaultValue={"No"}
                            value={rateCard.regionalTraffic}
                            userRole={userRole}
                            isStandalone={true}
                            onChange={(value) => { this.handleChange(value, "regionalTraffic") }}
                        />
                    </span>
                </Col>
                {rateCard.regionalTraffic === "Yes" &&
                    (
                        <Col span={19} className="mt-2">
                            <p className="font-size14 dark-black">
                                <span className="float-left">Regional Traffic Limits (as a % of total traffic)</span>
                                <Popover content={"This field is visible only when response to Regional Traffic Limits is Yes"}
                                    preferredPosition="above" size="small" position="top">

                                    <span className="float-left circle-alert">
                                        {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                    </span>
                                </Popover>

                            </p>
                            <div className="regional-table">
                                <AWSPolarisTable
                                    columns={Constants.regionalTrafficLimitHeaders}
                                    rowValues={rateCard.regionalTrafficLimit}
                                    tierType={"regionaltype"}
                                    tableType={"regionalTraficlimit"}
                                    onChangeFunc={(value, rowIndex, cellIndex) => { this.handleChange(value, "regionalTrafficLimit", rowIndex, cellIndex) }}
                                    onBlurFunc={(value, rowIndex, cellIndex, key) => {
                                        this.handleBlur(
                                            value,
                                            "regionalTrafficLimit",
                                            rowIndex,
                                            cellIndex,
                                            key
                                        );
                                    }}
                                    triggeredNext={this.props.triggeredNext}
                                    canEditPPR={this.props.canEditPPR}
                                    userRole={userRole}
                                />
                            </div>

                            {rateCard.regionalTrafficMixmaximum === true &&
                                (
                                    <div className="has-error font-size10 red  float-left">
                                        <span className="error-icon mr-0 float-left"
                                        >
                                            <Icon name="status-warning"></Icon>
                                        </span>{Constants.regionalMixTraffic}
                                    </div>
                                )
                            }
                        </Col>
                    )
                }
                {rateCard.regionalTraffic === "Yes" && rateCard.regionalGlobalPopup === true &&
                    (
                        <>
                            <div className="col-12 p-0 mt-3 float-left">
                                <div className="alert-wraper regional-alert p-0 float-left mt-3 pb-1 pt-1 pb-4">
                                    <span className="float-left border"></span>
                                    <p className="mb-0 p-0 float-left ml-1 mr-0"><span className="float-left alert-icon">
                                        <Icon name="status-warning"></Icon>
                                    </span></p>
                                    <p className="font-size14 drak-black float-left col-11 mb-0 ml-0 p-0 pl-4 pt-3"><span className="font-size14 amber-bold pr-2 floa-left">Alert:</span>Did you know that we have Global Limits rates available? . <span className="blue-link" onClick={this.showModal}>Please click here to learn more.</span></p>
                                    <span className="float-right border right0"></span>
                                </div>
                            </div>

                            <AWSModal
                                type="globalLimits"
                                visible={this.state.visible}
                                data={this.state.globalLimitsData}
                                handleOk={() => { this.handleOkOrCancelOfModal() }}
                                handleCancel={() => { this.handleOkOrCancelOfModal() }}
                                alertMessage={this.state.alertMessage}
                            />
                        </>
                    )
                }
                {rateCard.request_fees === "Waive Request Fees" && rateCard.dealEdited === true &&
                    (
                        <Col span={24} className="mt-5 mb-5">
                            <div className="col-12 float-left p-0">
                                <p className="dark-black font-size14 mb-1 col-11 p-0 float-left">
                                    <span className="float-left">Actual Average Object Size (AOS) (kb)</span>
                                    <Popover content={"Choose the Average Object Size from the slider or enter a value between 1 and 100 in the text box"}
                                        preferredPosition="above" size="small" position="top">

                                        <span className="float-left circle-alert">
                                            {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                        </span>
                                    </Popover>

                                </p>
                            </div>
                            <span className="col-8 max-width69 float-left p-0 mr-3 pt-4">
                                <AWSSlider
                                    value={rateCard.Actual_Aos_Selected}
                                    options={Constants.aos}
                                    steps={1}
                                    onChangeFunc={(value) => { this.handleChange(value, "Actual_Aos_Selected") }}
                                />
                            </span>
                            <span className="col-2 float-left pt-4 small-input">
                                <AWSinput
                                    value={rateCard.Actual_Aos_Selected.toString()}
                                    placeholder={"1"}
                                    onChangeFunc={(value) => { this.handleChange(value, "Actual_Aos_Selected") }}
                                    onBlurFunc={(value) => this.handleBlur(value, "Actual_Aos_Selected")}
                                    userRole={userRole}
                                    canEditPPR={this.props.canEditPPR}
                                />
                            </span>
                        </Col>
                    )
                }
            </div>



        );
    }
}

export default RegionalDetails;

RegionalDetails.propTypes = {
    rateCard: PropTypes.any,
    handleInputChange: PropTypes.func,
    onchangeDealStructure: PropTypes.func,
    showDealStructureLoadingToast: PropTypes.func,
    handleInputBlur: PropTypes.func
};
