import React,{Component} from 'react';
import { Slider } from 'antd';
import 'antd/dist/antd.css';
import './AWSNewslider.scss';

export default class AWSSlider extends React.Component {

    onChange = (value)=> {
        this.props.onChangeFunc(value);
    }
  
    render() {        
        const { options , value, steps } = this.props;
        let min,max,newMarks = {}; 

        options && options.map(({ mark }) => {
            Object.assign(newMarks, { [mark] : mark});
        })
  
        max = (options.slice(-1)[0]) ? options.slice(-1)[0]['mark'] : 0;
        max = (value > max) ? value : max;
        min = (options[0]) ? options[0]['mark'] : 0;
        return (
            <Slider
                marks={newMarks}
                onChange={(value)=>this.onChange(value)}
                value={ value }
                step = {steps}
                min = { min }
                max = { max }
                defaultValue={value}
            />
        );
    }
}
  