import React, { Component } from "react";
import RenderComponent from "./RenderComponent";
import AWSModal from "../common/AWSModal/AWSModal";
import * as tagNameConstant from "../../constants/tagNameConstant";
import * as roleNames from "../../constants/roleNames.const";
import _ from "underscore";
import { QuestionDependencyEvents } from "../../constants/dependencyConstants";
import { isArray, isEqual, isNil, isString } from "lodash";
import {
  handleQuestionBlockRenderingDependency,
  handleQuestionDisablingDependency,
  handleQuestionRenderingDependency,
} from "./utils/dependencyUtils";
import { isChinaBusinessUnit } from "../../constants/questionAnswerLabelConstants";

class DynamicRendercomponent extends Component {
  state = {
    isExpand: false,
    summaryData: {},
    termEndDate: "",
    termLength: "",
    stage: 0,
    alertMessage: "Please attach existing CloudFront PPA",
    payerId: "",
    triggeredNext: false,
    visible: false,
    updateDate: "",
    dataModel: {
      dealIntakeRequestForm: { stage: [] },
    },
    confirmationModalHeader: undefined,
    confirmationModalText: undefined,
    confirmationModalVisible: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  /**
   * this function is used to handle dependent question to make it visible or hidden based on value and question's option
   */
  handleDependencies = (
    value,
    question,
    questionBlockIndex,
    rowId,
    id,
    termEndDate,
    dateValue
  ) => {
    _.each(question.dependentOn, (dep, index) => {
      const { dependentId, event, option, propagate = false, resetValue, dependentValue, render } = dep;
      const depOptionsIncludeValue = !isNil(option) && option.includes(value);
      let clearGeoField = true;

      if (question && question.tagName === tagNameConstant.businessUnitTag && question.answer && isChinaBusinessUnit(question.answer.answerValue)) {
        clearGeoField = false;
      }

      if (
        event === QuestionDependencyEvents.ON_SELECT &&
        depOptionsIncludeValue
      ) {
        if (document.getElementById(`${dependentId}`)) {
          document.getElementById(`${dependentId}`).style.display = "block";
        }
        handleQuestionBlockRenderingDependency(
          this.props.dataModel,
          dependentId,
          true
        );
        handleQuestionRenderingDependency(
          this.props.dataModel[this.props.stage].queBlock[questionBlockIndex]
            .rows,
          dependentId,
          true,
          false,
          false,
          false,
          undefined,
          resetValue,
          dependentValue,
          clearGeoField,
          render
        );
      } else if (
        event === QuestionDependencyEvents.OFF_SELECT &&
        !depOptionsIncludeValue
      ) {
        if (document.getElementById(`${dependentId}`)) {
          document.getElementById(`${dependentId}`).style.display = "none";
        }
        handleQuestionBlockRenderingDependency(
          this.props.dataModel,
          dependentId,
          false
        );
        handleQuestionRenderingDependency(
          this.props.dataModel[this.props.stage].queBlock[questionBlockIndex]
            .rows,
          dependentId,
          false,
          false,
          true,
          false,
          undefined,
          undefined,
          undefined,
          clearGeoField
        );
      } else if (
        event === QuestionDependencyEvents.EQUALS ||
        event === QuestionDependencyEvents.DISABLE
      ) {
        let depIsEquals = false;
        if (isString(dep.option) || isArray(dep.option)) {
          depIsEquals = dep.option.includes(value);
        } else if (!isNil(dep.option)) {
          depIsEquals = dep.option === value;
        }
        const isQuestionVisible = question.isVisible;
        const isDependencyVisibleForEquals = dep.visible;
        const displayDependency =
          isQuestionVisible && depIsEquals && isDependencyVisibleForEquals;

        if (
          event === QuestionDependencyEvents.EQUALS &&
          document.getElementById(`${dependentId}`)
        ) {
          document.getElementById(`${dependentId}`).style.display =
            displayDependency ? "block" : "none";
        }

        const depTemplateStageId = dep.templateStageId;
        const depQueBlockId = dep.queBlockId;

        let depQueStageIndex = this.props.stage;
        let depQueBlockIndex = questionBlockIndex;
        if (depTemplateStageId) {
          this.props.dataModel.forEach((stage, index) => {
            if (isEqual(stage.templateStageId, depTemplateStageId)) {
              depQueStageIndex = index;
            }
          });
        }
        if (depQueBlockId) {
          this.props.dataModel[depQueStageIndex].queBlock.forEach(
            (queBlock, index) => {
              if (queBlock.queBlkId === depQueBlockId) {
                depQueBlockIndex = index;
              }
            }
          );
        }

        const propagateCallback = (value, question) =>
          this.handleDependencies(
            value,
            question,
            questionBlockIndex,
            rowId,
            id,
            termEndDate,
            dateValue
          );

        if (event === QuestionDependencyEvents.EQUALS) {
          handleQuestionBlockRenderingDependency(
            this.props.dataModel,
            dependentId,
            displayDependency
          );
          handleQuestionRenderingDependency(
            this.props.dataModel[depQueStageIndex].queBlock[depQueBlockIndex]
              .rows,
            dependentId,
            displayDependency,
            true,
            false,
            propagate,
            propagateCallback,
            undefined,
            undefined,
            clearGeoField
          );
        } else if (event === QuestionDependencyEvents.DISABLE) {
          handleQuestionDisablingDependency(
            this.props.dataModel[depQueStageIndex].queBlock[depQueBlockIndex]
              .rows,
            dependentId,
            displayDependency,
            dep.disableMessage,
            dep.dependentValue,
            propagate,
            propagateCallback
          );
        }
      } else if (event === QuestionDependencyEvents.MODAL) {
        if (depOptionsIncludeValue && dep.visible) {
          this.setState(
            {
              confirmationModalText: dep.alertMessage,
              confirmationModalHeader: dep.alertHeader,
              confirmationModalVisible: dep.visible,
            },
            () => {
              dep.visible = false;
            }
          );
        } else if (!depOptionsIncludeValue) {
          dep.visible = true;
        }
      }
    });
    this.props.handleOnSelect(
      value,
      question,
      questionBlockIndex,
      rowId,
      id,
      termEndDate,
      dateValue
    );
  };

  /**
   * this function will handle dependent validation based on selection and make alert message to be visible or hidden,
   * It will call dependent question function
   */
  handleDependenValidation = (
    value,
    question,
    questionBlockIndex,
    rowId,
    id,
    termEndDate,
    dateValue
  ) => {
    let count = 0;
    if (question.hasOwnProperty("dependentOn")) {
      _.each(question.dependentOn, (dep, index) => {
        if (dep.event === "checkForAttach") {
          if (value === dep.option) {
            _.each(
              this.props.dataModel[this.props.stage - 1].queBlock,
              (questionBlockElement, questionBlockElementIndex) => {
                questionBlockElement &&
                  questionBlockElement.rows.map(
                    (questionBlock, questionIndex) => {
                      questionBlock &&
                        questionBlock.questions.map(
                          (questionWithAnswer, indexAnswer) => {
                            if (questionWithAnswer !== null) {
                              if (
                                questionWithAnswer.queId === dep.dependentId
                              ) {
                                if (
                                  questionWithAnswer.answer.answerValue === null
                                ) {
                                  count++;
                                  this.setState({
                                    alertMessage: dep.alertMessage,
                                  });
                                }
                              }
                            }
                          }
                        );
                    }
                  );
              }
            );
          }
        }
        if (dep.event === "showpopup") {
          if (dep.option.includes(value)) {
            this.setState({
              alertMessage: dep.alertMessage,
            });
          }
        }
      });

      if (count === 0) {
        this.handleDependencies(
          value,
          question,
          questionBlockIndex,
          rowId,
          id,
          termEndDate,
          dateValue
        );
        this.props.handleOnSelect(
          value,
          question,
          questionBlockIndex,
          rowId,
          id,
          termEndDate,
          dateValue
        );
      }
    } else {
      this.props.handleOnSelect(
        value,
        question,
        questionBlockIndex,
        rowId,
        id,
        termEndDate,
        dateValue
      );
    }
  };

  /**
   * This function will call handle change fucniton in Form file to set value to answer object,
   * It will call function which is used to handle validation dependency
   */
  handleOnSelect = (
    value,
    question,
    questionBlockIndex,
    rowId,
    id,
    term,
    dateValue
  ) => {
    this.overRidePopupModal(value, question);
    let termEndDate = this.state.termEndDate;
    let newdateValue = dateValue;
    this.handleDependenValidation(
      value,
      question,
      questionBlockIndex,
      rowId,
      id,
      termEndDate,
      newdateValue
    );
  };

  /**
   * This function will call handle change fucniton in Form file to set value to answer object,
   * It will call function which is used to handle validation dependency
   */
  handleChange = (value, question, questionBlockIndex, rowId, id) => {
    this.overRidePopupModal(value, question);
    let termEndDate = this.state.termEndDate;
    this.props.handleChange(
      value,
      question,
      questionBlockIndex,
      rowId,
      id,
      termEndDate
    );
    this.handleDependenValidation(
      value,
      question,
      questionBlockIndex,
      rowId,
      id,
      termEndDate
    );
  };

  /**
   * this function will call showModal function to show popup modal based on question tagname,comparing previos,
   * current value change and user role
   * @param {String} value
   * @param {Object} question
   */
  overRidePopupModal = (value, question) => {
    const { userRole } = this.props;
    const userRoleForAlert = roleNames.PRIVATE_PRICING_USERS;
    if (userRoleForAlert.includes(userRole)) {
      const prevQuestionValue = question.answer.answerValue;
      if (question.tagName === tagNameConstant.discountTermDateTag) {
        if (value && prevQuestionValue && value !== prevQuestionValue) {
          this.showModal(true);
        }
      } else if (question.tagName === tagNameConstant.termLengthTag) {
        if (
          value &&
          prevQuestionValue &&
          value.toString() !== prevQuestionValue.toString() &&
          value.toString() !== this.props.termLength.toString()
        ) {
          this.showModal(true);
        }
      }
    }
  };

  /**
   * this function will make modal visible
   */
  showModal = (modalState) => {
    this.setState({
      visible: modalState,
    });
  };

  handleCancel = () => {
    this.showModal(false);
    this.props.onCancelOfOverrideChanges();
  };

  /**
   * this function will handle ok of Modal
   */
  handleOk = () => this.showModal(false);

  handleConfirmationModalOk = () => {
    this.setState({
      confirmationModalHeader: undefined,
      confirmationModalText: undefined,
      confirmationModalVisible: false,
    });
  };

  render() {
    let {
      questionValue,
      index,
      questionBlockIndex,
      triggeredNext,
      duplicateValidation,
      duplicateErrFlag,
      canEditPPR,
      additionalApprovalFlag,
      dealStatus,
      locationStateUser,
    } = this.props;

    return (
      <div className="">
        <AWSModal
          data-id="priving-pricing-edit-modal"
          type="privatePricingEdit"
          visible={this.state.visible}
          handleOk={() => {
            this.handleOk();
          }}
          handleCancel={() => {
            this.handleCancel();
          }}
          titleMessage="This will override current data, are you sure you want to continue?"
        />
        <AWSModal
          data-id="confirmation-modal"
          type="renderConfirmationPopUp"
          visible={this.state.confirmationModalVisible}
          handleOk={this.handleConfirmationModalOk}
          confirmLabel="Ok"
          hideCancel={true}
          text={this.state.confirmationModalText}
          header={this.state.confirmationModalHeader}
          size={"max"}
        />
        <RenderComponent
          dealStatus={dealStatus}
          dealInfo={this.props.dealInfo}
          locationStateUser={locationStateUser}
          additionalApprovalFlag={additionalApprovalFlag}
          questionValue={questionValue}
          index={index}
          pprId={this.props.pprId}
          questionBlockIndex={questionBlockIndex}
          triggeredNext={triggeredNext}
          onChangeAdditionalApproval={(key, value) =>
            this.props.onChangeAdditionalApproval(key, value)
          }
          handleChange={(
            val,
            questionValue,
            questionBlockIndex,
            rowId,
            cellIndex
          ) =>
            this.handleChange(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            )
          }
          handleOnSelect={(
            val,
            questionValue,
            questionBlockIndex,
            rowId,
            cellIndex,
            term,
            dateValue
          ) =>
            this.handleOnSelect(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex,
              term,
              dateValue
            )
          }
          onDropdownSelection={this.props.onDropdownSelection}
          date={this.props.date}
          termLength={this.props.termLength}
          dataModel={this.props.dataModel}
          duplicateValidation={duplicateValidation}
          userRole={this.props.userRole}
          handleDeleteTableRow={(rowId) => {
            this.props.handleDeleteTableRow(rowId);
          }}
          handleBulkDeleteTableRow={(rowId) => {
            this.props.handleBulkDeleteTableRow(rowId);
          }}
          duplicateErrFlag={duplicateErrFlag}
          dynamicSalesManagerOptions={this.props.dynamicSalesManagerOptions}
          canEditPPR={canEditPPR}
          handleAffiliatedPayerIdForSummary={
            this.props.handleAffiliatedPayerIdForSummary
          }
        />
      </div>
    );
  }
}

export default DynamicRendercomponent;
