import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import './AWSAlert.scss';
import PropTypes from "prop-types";
class AWSAlert extends Component {
    // state = {
    //     hidealertFlag: false
    // }
    // hideAlert = () => {
    //     this.setState({
    //         hidealertFlag: true
    //     })
    // }
    render() {
        return (
            <div className="col-12 p-0">
                <div className="col-11 alert-wraper p-0 float-left mt-3 pb-1 pt-1">
                    <div className="float-left alert-icon"><Icon name="status-warning" ></Icon></div>
                    <div className="col-11 p-0 float-left alert-txtwraper pr-0">
                        <p className="font-size14 grey mb-0 p-0 pl-4"><span className="alert-txt font-size14 full-widtht mb-0 amber-bold pr-2">Alert:</span>{this.props.label}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AWSAlert;
AWSAlert.propTypes = {
    label: PropTypes.string,
};