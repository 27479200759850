import React, { Component } from "react";
import "./LeftNavTermiantionSummary.scss";
import "./LeftNavigationSummary.scss";
import AWSTextArea from "../AWStextarea/AWStextarea";
import AWSbutton from "../AWSbutton/AWSbutton";
import CopyIcon from "../../../images/Copy.svg";
import closeIcon from '../../../images/close_light_grey_icon.svg';
import AWSconfig from "../../../config/AWSconfig";
const isFirefox = typeof InstallTrigger !== 'undefined';
const config = new AWSconfig();
let validationArray = [
  {
    cellId: null,
    message: "This is a mandatory field",
    type: "Required",
    criteria: null
  }
];

let validatingQuestion = {
  isVisible: true
};

class LeftNavTerminationDealSummary extends Component {
  /**
   * this function will set value of comments
   */
  handleChange = value => {
    this.props.handleSummaryComment(value);
  };

  closeSummary = () =>{
    this.props.handleOnClick()
  }
  loadToastForCopy = () => {
    this.props.showDealStructureLoadingToast("Summary copied", true);
    setTimeout(() => {
      this.props.showDealStructureLoadingToast("Summary copied", false);
    }, 1500)
  }
  copyRef = React.createRef();
  copyContent = () => {
    this.loadToastForCopy()
    window.getSelection().removeAllRanges();

    var range = document.createRange();

    const div = this.copyRef.current;
    const color = div.style.background;
    div.style.margin = "10px";
    div.style.background = "#ffffff";

    document.getElementById("copyContent").style.visibility = "hidden";
    range.selectNode(div);
    window.getSelection().addRange(range);
    try {
      // Now that we've selected the anchor text, execute the copy command
      document.designMode = "on";
      // if (document.getElementById("summaryContent")) {
      //   document.getElementById("summaryContent").className = "after-copy-table";
      // }

      document.execCommand("foreColor", false, "black");
      // document.execCommand( "fontName", false, "Arial");
      document.execCommand("copy");
      document.designMode = "off";
    } catch (err) {
      console.warn("Copying warning", err);
    } finally {
      document.getElementById("copyContent").style.visibility = "visible";

      // document.getElementById("summaryContent").className = "copy-table data-transfer";
      // if (this.props.leftNavTierPricingTable.length >= 1 || this.props.requestFeesTable.length >= 1 || this.props.regionalLimitsTable.length >= 1 || this.props.regionalTrafficLimitTable.length >= 1) {

      // }

      div.style.margin = "0px";
      div.style.background = color;
      // let body = document.getElementById("leftnavOpen");
      // body.className = "font-change";
      document.designMode = "on";
      document.execCommand("foreColor", false, "#cecece");
      document.designMode = "off";
      window.getSelection().removeAllRanges();
    }
  };

  // *this function for copy text from summary page for Firefox Browser only*/
  copyToClipFirefox = () => {
    this.loadToastForCopy()
    window.getSelection().removeAllRanges();

    var range = document.createRange();

    const div = this.copyRef.current;
    const color = div.style.background;
    const textColor = div.style.color;
    div.style.margin = "10px";
    div.style.background = "#ffffff";
    div.style.color = "black";
    range.selectNode(div);
    window.getSelection().addRange(range);

    document.getElementById("copyContent").style.visibility = "hidden";
    document.execCommand("foreColor", false, "black");
    // document.execCommand( "fontName", false, "Arial");
    document.execCommand("copy");
    //document.designMode = "off";

    document.getElementById("copyContent").style.visibility = "visible";

    div.style.margin = "0px";
    div.style.background = color;
    div.style.color = textColor;
    // let body = document.getElementById("leftnavOpen");
    // body.className = "font-change";
    document.execCommand("foreColor", false, "#cecece");
    window.getSelection().removeAllRanges();
  };
  // funtion to copy URL
  loadToastForCopyURL = () => {
    this.props.showDealStructureLoadingToast("URL copied", true);
    setTimeout(() => {
      this.props.showDealStructureLoadingToast("URL copied", false);
    }, 1500)
  }
  copyURL = () => {

    this.loadToastForCopyURL()
    var copyText = document.getElementById("urlPath");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");

  }
  render() {
    let { summarCommentArrayObject, triggerCommentValidation } = this.props;

    return (
      <div className="full-width termination-leftnav-summary">
        <div className="close-summary">
          <span className="light-white cursor-pointer float-right ml-4" onClick={() => { this.closeSummary() }}>Close <img src={closeIcon} alt="copy" className="ml-2" /></span>
          <span
            className="float-right font-size14 light-white cursor-pointer btn-copy noselect generate-ptsicon"
            id="copyContent"
            onClick={() => {
              isFirefox ? this.copyToClipFirefox() : this.copyContent()
            }}
          >
            Copy  
              <img src={CopyIcon} alt="copy" className="ml-2" />
          </span>
          <span
            className="float-right font-size14 light-white cursor-pointer btn-copy noselect generate-ptsicon mr-4"
            id="copyURL"
            onClick={() => {
              this.copyURL();
            }}
          >
            Copy URL
              <img src={CopyIcon} alt="copy" className="ml-2" />
          </span>
          <input type="text" defaultValue={`${config.baseURl}/Termination/PPR-${this.props.pprId}/view`} id="urlPath" className="urlpath" aria-hidden="true" />
        </div>
        <div className="full-width scroll-content scroll-width10 moz-scroll summary-scroll" ref={this.copyRef} id="summaryContent">
          <div className="full-width">
            <div className="col-6 float-left left-content">
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">Customer Legal Name :</span>{" "}
                {this.props.terminationSummaryData.cus_legal_name}
              </p>
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">Account Name : </span>{" "}
                {this.props.terminationSummaryData.account_name}
              </p>
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">
                Start Date of Current Agreement :
                </span>{" "}
                {this.props.terminationSummaryData.start_date_agreement === "Invalid date"? " ":this.props.terminationSummaryData.start_date_agreement}
              </p>              
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">
                Termination Effective Date :
                </span>{" "}
                {this.props.terminationSummaryData.effective_detail === "Invalid date"?" ":this.props.terminationSummaryData.effective_detail}
              </p>
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">
                  What is the reason for Termination ? :
                </span>{" "}
                {this.props.terminationSummaryData.termination_reason}{" "}
              </p>
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">
                  If bucket commit, how much of the commit has the customer
                  fulfilled ? :
                </span>{" "}
                {this.props.terminationSummaryData.customer_commit_fulfilled}
              </p>
              <p className="lightgrey font-size11 left-txt">
                <span className="amber-bold">
                  If missed commit, please detail by how much ? :
                </span>{" "}
                {this.props.terminationSummaryData.commit_missed_detail}
              </p>
            </div>
          </div>

          
        </div>
        <div className="full-width bottom-content pt-4">
            {/* <div className="col-4 pt-4 float-left p-0 ml-4">
              <p className="font-size10 light-white">
                Sales Rep: {this.props.salesRepName}
              </p>

              {summarCommentArrayObject.commentText &&
                summarCommentArrayObject.commentText.map(
                  (commentObject, commentObjectIndex) => {
                    if (
                      commentObject.commentText !== "" &&
                      commentObject.commentText !== null
                    ) {
                      return (
                        <p className="font-size10 light-white">
                          {commentObject.summaryUserRole !==
                          "Sales Representative"
                            ? `${commentObject.summaryUserRole} Comment :`
                            : "Sales Rep Comment: "}
                          {commentObject.commentText}
                        </p>
                      );
                    }
                  }
                )}
            </div> */}
            <div className="col-6 float-right pr-0 mr-1 comment-box">
              <AWSTextArea
                value={this.props.summaryComment || ""}
                placeholder="Enter Comments"
                // questionValue={validatingQuesstion}
                // validation={validationArray}
                // triggeredNext={triggerCommentValidation}
                onChangeFunc={e => this.handleChange(e)}
              />
            </div>
          </div>
        <div className="padding-summary-from-title">
          <div className="summary-context full-width pb-3">
            <div className="float-right mt-2 mb-1 mr-1 pt-3">
              {this.props.dealAction &&
                this.props.dealAction.map((dealAction, dealActionIndex) => {
                  if (this.props.ownerType === this.props.userRole) {
                    return (
                      <span className="float-left pl-4">
                        <AWSbutton
                          btnType="fill"
                          btnType={
                            this.props.dealAction.length === dealActionIndex + 1
                              ? "fill"
                              : "trasparent-btn"
                          }
                          label={dealAction.sucActionLabel}
                          isIcon={false}
                          disabled={
                            dealAction.isMandatory === true &&
                            (this.props.summaryComment === null ||
                              this.props.summaryComment === "")
                              ? true
                              : false
                          }
                          onClick={() => {
                            this.props.handleSubmit(dealAction);
                          }}
                        />
                      </span>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LeftNavTerminationDealSummary;
