import React, { Component } from 'react';
import AWSButtonRadio from "../../common/AWSButtonRadio/AWSButtonRadio";
import './RateCardTable.scss';
import axios from 'axios';
import saveIcon from '../../../images/save_black.svg';
import PropTypes from "prop-types";
import AWSConfig from '../../../config/AWSconfig';
import AWSAdminTable from '../AWSAdminTable/AWSAdminTable';
import AWSAdminTableInput from '../../common/AWSAdminTableInput/AWSAdminTableInput';


const config = new AWSConfig();
let requestFeesOptions = [
    {
        "sF_Id": "a0R4T0000004lPYUAY",
        "questionId": "a024T000001aeJOQAY",
        "optionValue": "Private Rates",
        "optionOrder": null,
        "optionKey": "Private Rates",
        "IconSource": null,
        "defaultValue": null,
        "cellId": null
    },
    {
        "sF_Id": "a0R4T0000004lPdUAI",
        "questionId": "a024T000001aeJOQAY",
        "optionValue": "Public Rates",
        "optionOrder": null,
        "optionKey": "Public Rates",
        "IconSource": null,
        "defaultValue": null,
        "cellId": null
    }
]

class RequestFees extends Component {

    state = {
        requestFeesSelectedOption: "Private Rates",
        columns: [],
        tableEditedValues: [],
        edited: false,
        editableCellTable: false
    }

    /**
    * This lifecycle method is calling API to get the request fees values to load
     */
    componentDidMount() {
        let rate = "Private Rates";
        this.requestFeesApiCall(rate)
    }

    /**
     * This function call backend api to get the request Fees in admin module
     * @param {*} rate which is a type of rate Card
     */
    requestFeesApiCall = (rate) => {
        this.showUserManagementLoadingToast("Loading Request Fees ...", true);
        let configListApi = (rate === 'Private Rates') ? `${config.adminrateCardbaseUrl}privateRates` : `${config.adminrateCardbaseUrl}publicRates`;
        axios({
            method: "GET",
            url: configListApi
        })
            .then(resp => {
                this.setState({
                    columns: resp.data
                })
                this.showUserManagementLoadingToast("Loading Request Fees ...", false);
            })
            .catch(() => {
                this.showUserManagementLoadingToast("Failed Loading Request Fees ...", false);
            })
    }

    /**
     * This function is set state for selected rate card
     * @param {*} value which is a type of rate Card
     */
    handleRequestFees = (value) => {
        this.setState({
            requestFeesSelectedOption: value,
            editableCellTable: false
        })
        this.requestFeesApiCall(value);
    }

    makeCellEditableTrue = () => {
        this.setState({
            editableCellTable: true
        })
    }

    getRequestFeeColumn = () => {

        const requestFeeColumn = [
            {
                header: '',
                id: 'Protocol',
                cell: item => <div>{item.Protocol}</div>,
            },
            {
                header: 'NA ($)',
                id: 'NA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.NA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "NA", item)}
                    />
                </div>
            },
            {
                header: 'EU/IL ($)',
                id: 'EU',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.EU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "EU", item)}
                    />
                </div>
            },
            {
                header: 'AP ($)',
                id: 'AP',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.AP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AP", item)}
                    />
                </div>
            },
            {
                header: 'JP ($)',
                id: 'JP',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.JP.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "JP", item)}
                    />
                </div>
            },
            {
                header: 'SA ($)',
                id: 'SA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.SA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "SA", item)}
                    />
                </div>
            },
            {
                header: 'AU ($)',
                id: 'AU',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.AU.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "AU", item)}
                    />
                </div>
            },
            {
                header: 'IN ($)',
                id: 'IN',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.IN.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "IN", item)}
                    />
                </div>
            },
            {
                header: 'ZA ($)',
                id: 'ZA',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.ZA.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ZA", item)}
                    />
                </div>
            },
            {
                header: 'ME ($)',
                id: 'ME',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.ME.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ME", item)}
                    />
                </div>
            },
            {
                header: 'ALL ($)',
                id: 'ALL',
                cell: item => <div >
                    <AWSAdminTableInput
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        value={item.ALL && item.ALL.toString()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "ALL", item)}
                    />
                </div>
            }
        ];
        return requestFeeColumn;
    }

    updateVolCommitData = (val, keyOfVolCommitData, commitData) => {
        commitData[keyOfVolCommitData] = val;

        let { columns } = this.state;
        this.onEdit(val, keyOfVolCommitData, commitData.Protocol)
        this.setState({
            columns
        })
    }
    /**
     * This function is called when editing the Public/private rate
     * @param {*} data which is a column of the edited row
     */
    onEdit = (val, keyOfVolCommitData, Protocol) => {
        this.saveRequestFeesEditedTableValues(val, keyOfVolCommitData, Protocol);
    }

    /**
     * This function is called to store the edited Public/private rate values
     * @param {*} data which is a column of the edited row
     */
    saveRequestFeesEditedTableValues = (editedprice, geo, Protocol) => {

        let obj = {
            "geo": geo,
            "request": Protocol,
            "editedprice": parseFloat(editedprice)
        }

        if(this.state.tableEditedValues.filter(item=> item.geo === geo && item.request === Protocol).length==0){
            this.state.tableEditedValues.push(obj);
        }
        else{
            this.state.tableEditedValues.filter(item=> item.geo === geo && item.request === Protocol)[0].editedprice = parseFloat(editedprice);
        }

        //this.state.tableEditedValues.push(obj);
        this.setState({
            edited: true
        })
    }

    /**
     * This function is called to save the edited Public/private rate values in API
     */
    SaveRequestFees = () => {
        this.setState({
            editableCellTable: false
        })
        if (this.state.edited === true) {
            let type = (this.state.requestFeesSelectedOption === "Private Rates") ? "privateRate" : "publicRate";
            this.showUserManagementLoadingToast("Saving Request Fees ...", true);
            let requestFeesSubmitApi = `${config.adminrateCardbaseUrl}updateppRate`;
            let ReqData = {
                "type": type,
                "editedvals": this.state.tableEditedValues
            }

            axios({
                method: "PUT",
                url: requestFeesSubmitApi,
                data: ReqData
            }).then(resp => {
                this.showUserManagementLoadingToast("Request Fees saved Successfully...", true);
                setTimeout(() => {
                    this.showUserManagementLoadingToast("Request Fees saved successfully", false);
                }, 600)
                this.setState({
                    edited: false,
                    tableEditedValues: []
                })
            }).catch(() => {
                this.showUserManagementLoadingToast("Saving Request Fees Failed ...", false);
            })
        }
    }

    /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
     */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    render() {

        return (
            <div className="full-width request-admin-wraper">
                <div className="float-right p-0">
                    <div className="float-right width-auto p-0 cursor-pointer pt-2" onClick={() => this.SaveRequestFees()}><span className="mr-2 float-left">
                        <img src={saveIcon} alt="save" height="14" />
                    </span>Save</div>
                </div>
                <div className="full-width request-tab">
                    <p className="font-size14 mb-1 mt-2">Request fees</p>
                    <AWSButtonRadio
                        options={requestFeesOptions}
                        value={this.state.requestFeesSelectedOption}
                        handleOnSelect={(value) => this.handleRequestFees(value)}
                        defaultValue="Private Rates"
                        canEditPPR={true}
                    />
                </div>
                <div className="full-width ratecard-table pb-5 pt-3">
                    <AWSAdminTable
                        tableData={this.state.columns}
                        columns={this.getRequestFeeColumn()}
                    />
                </div>
            </div>
        );
    }
}

export default RequestFees;

RequestFees.propTypes = {
    showUserManagementLoadingToast: PropTypes.func
};