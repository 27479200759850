import React, { Component } from 'react';
import { getFormattedDate } from '../../../utils/dates'
import AWSInput from "../../common/AWSInput/AWSinput";
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import { Icon } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
class AddNewSection extends Component {

    state = {
        titleName: null
    }

    handleChangeSectionTitle = (value) => {
        this.setState({
            titleName: value
        })
    }

    handleSave = () => {

        let sectionJson = {
            queBlock: [
                {
                    queBlkHeader: this.state.titleName,
                    queBlkId: null,
                    queBlkSeq: null,
                    isQueBlkDelete: true,
                    isVisible: true,
                    infoText: null,
                    questions: []
                }
            ]
        }

        this.props.handleOk(sectionJson)
    }


    render() {
        const { location: { state: { firstName, lastName } } } = this.props
        return (
            <div className="add-section-popup popup full-width">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold close-icon">Add New Section<span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                        <Icon name="close"></Icon>
                    </span></p>
                </div>
                <div className="full-width body-wraper pt-4 mb-5 pl-5 pr-5">
                    <div className="full-width">
                        <p className="dark-black">Section Name</p>
                        <div className="full-width">
                            <AWSInput
                                value={this.state.titleName}
                                onChangeFunc={(value) => { this.handleChangeSectionTitle(value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <span className="float-left pt-4 pl-4">Created By <span className="dark-blue">{`${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Save"
                                    isIcon={false}
                                    onClick={() => this.handleSave()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddNewSection;
AddNewSection.propTypes = {
    location: PropTypes.any,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func
};
