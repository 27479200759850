import React from 'react';
import './watchilst.scss';
import { Icon } from "@amzn/awsui-components-react";
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSbutton from '../../common/AWSbutton/AWSbutton';
import deleteIcon from '../../../images/delete_darkblack.svg';
import AWSConfig from '../../../config/AWSconfig';
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import * as additionalTerm from '../../../constants/additionalTerm.const';
import { getFormattedDate } from '../../../utils/dates';
import PropTypes from "prop-types";
import axios from "axios";

const config = new AWSConfig();

class CreateNewAdditionalList extends React.Component {
    state = {
        titleName: null,
        adddlList: [],
        additionalTerm: '',
        activateToast: false,
        loadingMessage: ''
    }

    handleChangeAdditionalList = (value) => {
        this.setState({
            additionalTerm: value,
        })
    }

    addAdditionalTerm = () => {
        const { additionalTerm } = this.state;
        const adddlList = this.state.adddlList.concat(additionalTerm);
        this.setState({ adddlList });
    }

    removeAddtionalTerm = (index) => {
        const { adddlList } = this.state;
        const newAdditionalTermList = adddlList.slice(0, index).concat(adddlList.slice(index + 1, adddlList.length));
        this.setState({ adddlList: newAdditionalTermList });
    }

    saveAdditionalTerm = (reqJson) => {
        this.showUserManagementLoadingToast(additionalTerm.LOADING_MESSAGE, true);
        const saveAdditionalTermUrl = `${config.userManagementbaseUrl}users/addTerms`;
        axios({
            method: "POST",
            url: saveAdditionalTermUrl,
            data: reqJson
        }).then(resp => {
            if (resp.data.responseCode === 200)
                this.showUserManagementLoadingToast(additionalTerm.SAVE_MESSAGE, true);
            this.props.handleOk('createNewAdditionalList');
        }).catch((error) => {
            console.log('err', error);
        });
    }


    handleOk = () => {
        const { addlistOption } = this.props;
        const { additionalTerm } = this.state;
        const reqJson = {
            'configPartitionKey': addlistOption,
            'cfValue': additionalTerm
        }
        this.saveAdditionalTerm(reqJson);
    }

    /*
    *show loading toast when new ADT or APT is added
    */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        });
    }

    render() {
        const { addlistOption, location } = this.props;
        const { additionalTerm, adddlList, activateToast } = this.state;
        const { state: { firstName, lastName } } = location;
        return (
            <div className="full-width watchlist-popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        Additional List
                    <span className="float-right cursor-pointer pr-2 pt-1 close-icon" onClick={() => this.props.handleCancel()}>
                    <Icon name="close"></Icon>
                        </span>
                    </p>
                </div>
                <div className="col-12 float-right p-3 pt-4 pb-5">
                    <div className="col-6 float-left mr-5">
                        <div className="col-10 float-left pl-0 pt-4">
                            <p className="amber-bold mb-1">{addlistOption === 'ADT' ? 'Additional Deal Term' : 'Additional Pricing Term'}</p>
                        </div>
                        <div className="col-10 float-left pl-0 pr-3">
                            <AWSInput
                                value={additionalTerm}
                                onChangeFunc={(value) => { this.handleChangeAdditionalList(value) }}
                            /></div>
                        <span className="float-left addaccount-plus mt-2 cursor-pointer" onClick={() => this.addAdditionalTerm()}>
                            </span>

                    </div>
                    <div className="full-width p-4">
                        <p className="amber-bold mb-1">Additional list</p>
                        {adddlList && adddlList.map((item, index) => (
                            <ul className="full-width m-0 p-0 additionl-listwraper pt-3" key={index}>
                                <li className="full-width pb-2 mb-3" key={index}>
                                    <div className="width-auto float-left">{item}</div>
                                    <span className="float-right cursor-pointer" onClick={() => this.removeAddtionalTerm(index)}><img src={deleteIcon} alt="delete" /></span>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                <span className="float-left pt-4 pl-4 mt-2">Created By <span className="dark-blue">{firstName && `${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-0 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Add"
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                            {activateToast && (
                                <AWSLoadingToast
                                    message={this.state.loadingMessage}
                                    error={this.state.loadingError}
                                    duration={this.state.loadingMessageDuration}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CreateNewAdditionalList;
CreateNewAdditionalList.propTypes = {
    location: PropTypes.any,
    addlistOption: PropTypes.any,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
    editData: PropTypes.any
};
