import moment from "moment";

export const discountEffectiveDateList = () => {
    let values = [];
    for (let i = 1; i <= 12; i++) {
        const nextMonth = moment([]).startOf('month').add(i, 'M');
        const date = nextMonth.format('MM/DD/YYYY');

        values.push({
            optionKey: date,
            defaultValue: null,
            optionValue: date,
            IconSource: null,
            optionOrder: i,
            cellId: null,
            label: date,
            value: date
        });
    }
    values.unshift({
        optionKey: "Select",
        defaultValue: null,
        optionValue: "Select",
        IconSource: null,
        optionOrder: 0,
        cellId: null,
        label: "Select",
        value: "Select"
    });
    return values;
};

export const discountEffectiveDateListForMultiSelect = () => {
    let values = [];
    for (let i = 1; i <= 12; i++) {
        const nextMonth = moment([]).startOf('month').add(i, 'M');
        const date = nextMonth.format('MM/DD/YYYY');

        values.push({
            id: date,
            defaultValue: null,
            IconSource: null,
            optionOrder: i,
            cellId: null,
            label: date,
            value: date
        });
    }
    return values;
};