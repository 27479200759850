import React, { Component } from 'react';
import { Slider } from 'antd';
import 'antd/dist/antd.css';
import './AWSNewslider.scss';

export default class AWSTextSlider extends React.Component {

    onChange = (value) => {
        this.props.onChangeFunc(value);
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (canEditPPR === true) {
            return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
        } else if (canEditPPR === false) {
            return true // disable true
        } else {
            return false
        }
    }

    render() {
        const { options, value, questionValue,canEditPPR } = this.props;
        let newMarks = {};
        const optionLength = options.length;

        options && options.map((option, optionIndex) => {
            let keyMark = 0;
            if (optionIndex === 0) {
                keyMark = 0
            } else {
                keyMark = 100 / (optionLength - optionIndex)
            }
            Object.assign(newMarks, { [keyMark]: option.optionValue });
        })

        return (
            <div className="textslider-wraper">
                <Slider
                    // disabled={}
                    marks={newMarks}
                    onChange={(value) => this.checkForDisable(questionValue,canEditPPR) === true ? null : this.onChange(value)}
                    value={value}
                    tooltipVisible={false}
                    step={null}
                    defaultValue={value}
                />
            </div>

        );
    }
}
