import { isNil } from "lodash";
/**
  * @description Returns a true/false if the value is present within the DROPDOWN questions optionValues
  * @param  options typed or selected option which need to update in the state
  * @param  value in which name of the particular element value to be set
  * @returns boolean
*/
export const doesValueExistWithinOptions = (options, value) => {
    const optionValue = options.find(
        (item) => item.optionValue === value
    );
    return !isNil(optionValue);
}
