import React, { Component } from 'react';
import AWSinput from "../common/AWSInput/AWSinput";
import AWSDropdown from "../common/AWSDropdown/AWSDropdown";
import Plusicon from "../../images/Pluss_Icon.svg";
import Minusicon from '../../images/minu_icon.svg';
import AWStextarea from "../common/AWStextarea/AWStextarea";
import Col from "../common/AWSCol/AWSCol";
// import { AppProvider, TextField, Tooltip, Icon } from '@shopify/polaris';
import infoHelpIcon from '../../images/awsfalconhelp.png';
import axios from 'axios';
import { CorePlusPlusoptions } from '../../constants/dealStructureConstants';
import AWSConfig from '../../config/AWSconfig';
import * as roleNames from '../../constants/roleNames.const';
import { Popover ,Icon} from "@amzn/awsui-components-react";
import * as tagNameConstant from '../../constants/tagNameConstant';

let conditionValue = "";

const config = new AWSConfig();

class DynamicFormGroup extends Component {

    state = {
        corePlusAdtOptions: [],
        errMessage: ''
    }

    componentDidMount() {
        const reqParam = `getADT`;
        this.getAllAdditionalDealTerms(reqParam);
    }

    getAllAdditionalDealTerms = (reqParam) => {
        const adtApi = `${config.userManagementbaseUrl}users/${reqParam}`;
        axios({
            method: "GET",
            url: adtApi
        }).then(resp => {
            this.setState({ corePlusAdtOptions: resp.data.Data });
        }).catch((err) => {
            const { response: { data: { Data } } } = err;
            this.setState({ errMessage: Data })
        });
    }

    handleOnAddFormGroup = (formgroup, answer, question, rowId) => {
        let cells = [];
        const newRandomId = Math.floor(Math.random() * Math.floor(1000));

        formgroup && formgroup.map((cell, formIndex) => {
            let cellTypeObject = {
                type: cell.type,
                value: null,
                tableRowID: question.sF_Id + newRandomId,
                sequence: cell.sequence,
                questionId: question.sF_Id,
                tagName: cell.tagName,
                permissionSeq: cell.permissionSeq
            }
            cells.push(cellTypeObject);
        })
        let newRowObject = {
            type: null,
            cells,
            newRowId: question.sF_Id + newRandomId,
            tableId: question.sF_Id,
            questionId: question.sF_Id,
            sequence: rowId + 2
        };

        question.formGroup.rows.push(newRowObject)

        this.setState({
            nothing: ""
        })
    }

    handleOnDelete = (formGroup, question, rowId) => {
        question.formGroup.rows.splice(rowId, 1);
        this.setState({
            nothing: ""
        })
    }

    /**
     * this function will check for permission of question visible to loggedin user and return value to set visible state
     */
    makeCellVisibleBasedOnPermission = (cell, userRole) => {
        let updatedUserRole = userRole.replace(/ /g, "_");
        let isCellVisible = false;
        let cellPermission = cell.permissionSeq[0];
        let keyValueOfCellPermission = Object.keys(cellPermission);
        keyValueOfCellPermission.forEach((keyPair, keyPairIndex) => {
            if (keyPair === updatedUserRole) {
                isCellVisible = !cellPermission[keyPair].hidden
            }
        })
        return isCellVisible;
    }

    editQuestionCss = (cell) => {
        return cell.isQuestionEdit === true ? 'editable-color' : ''
    }

    render() {
        const { corePlusAdtOptions } = this.state;
        let { questionValue, index, triggeredNext, questionBlockIndex, userRole, canEditPPR } = this.props;
        const { quePermission } = questionValue;

        return (
            <div key={`formgroup_${index}`} id={questionValue.sF_Id} style={{ display: !questionValue.isVisible ? 'none' : 'block' }} className="mt-5">
                <p className="font-size14 mb-2 full-width ">
                    <div className={`input-label font-size14 float-left `}>{questionValue.isMandatory === true ? questionValue.queLabel : `${questionValue.queLabel} (Optional)`}</div>
                    {
                        questionValue.queLabel && (
                            <Popover content={questionValue && questionValue.helpText}
                                preferredPosition="above" size="small" position="top">
                                <div className="float-left circle-alert">
                                    {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                </div>
                            </Popover>
                        )
                    }
                </p>

                <Col span={24}>
                    <Col span={20} className="mt-0">
                        {
                            questionValue.formGroup && questionValue.formGroup.rows.map((row, i) => {
                                if (row.type === "HEADER") {
                                    return row.cells.map((cell, cellIndex) => {
                                        if (cell.tagName !== tagNameConstant.mapAdditionalDealTermTag) {
                                            return (
                                                <Col span={8} className={`mr-0 ${this.editQuestionCss(cell)}`} key={`form-group-${cellIndex}`}>
                                                    <span className={`grey-txt ${this.editQuestionCss(cell)}`}>{cell.value}</span>
                                                </Col>
                                            )
                                        } else {
                                            if (this.props.userRole === roleNames.SCE_OWNER || this.props.userRole === roleNames.IGT_OWNER || this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                                                this.props.userRole === roleNames.PRIVATE_PRICING_CHINA) {
                                                return (
                                                    <Col span={5} className="mr-0 mapcore-dropdown" key={`form-group-${cellIndex}`}>
                                                        <span className={`grey-txt width-auto float-left ${this.editQuestionCss(cell)}`}>{cell.value}</span>
                                                        {cell && cell.helpText && (
                                                            <span className="float-left">
                                                                <Popover content={cell.helpText}
                                                                    preferredPosition="above" size="small" position="top">
                                                                    <div className="float-right circle-alert">
                                                                        {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                                                    </div>
                                                                </Popover>
                                                            </span>

                                                        )}
                                                    </Col>
                                                )
                                            }
                                        }
                                    })
                                } else {
                                    return;
                                }

                            })
                        }
                    </Col>
                </Col>
                {
                    questionValue.formGroup && questionValue.formGroup.rows.map((formRow, rowId) => {
                        let lastRowElementID = questionValue.formGroup.rows.length - 1;

                        if (questionValue.formGroup && questionValue.formGroup.rows.length === 1) {
                            this.handleOnAddFormGroup(questionValue.formGroup.rows[0] && questionValue.formGroup.rows[0].cells, questionValue.answer, questionValue, rowId)
                        } else {
                            if (formRow.type !== "Empty Row" && formRow.type !== "HEADER") {
                                return (
                                    <Col span={20} key={`formgroupRow_${rowId}`} >
                                        {
                                            formRow.cells.map((cell, cellIndex) => {

                                                switch (cell.type) {
                                                    case "DROPDOWN":
                                                        let isCellVisible = this.makeCellVisibleBasedOnPermission(cell, userRole);
                                                        if (isCellVisible === true) {
                                                            conditionValue = cell.value
                                                            return (
                                                                <Col span={6} className={rowId === 1 ? `pr-3 ${rowId} pr-3 mt-2 pt-2` : `pr-3 mt-2 pt-2 ${rowId}`} key={`dropdown_${cellIndex}`}>
                                                                    <AWSDropdown
                                                                        id={cellIndex}
                                                                        canEditPPR={canEditPPR}
                                                                        validation={this.props.validation}
                                                                        label=""
                                                                        options={cell.tagName === tagNameConstant.mapAdditionalDealTermTag ? corePlusAdtOptions : questionValue.formGroup.rows[0].cells[cellIndex].options}
                                                                        value={cell.value}
                                                                        defaultValue={questionValue.defaultValue}
                                                                        handleOnSelect={
                                                                            (val) => {
                                                                                this.props.handleOnSelect(val, questionValue, questionBlockIndex, rowId, cellIndex)
                                                                            }
                                                                        }
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.validation}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </Col>
                                                            );
                                                        }
                                                        break;

                                                    case "TEXT":
                                                        let isTEXTCellVisible = this.makeCellVisibleBasedOnPermission(cell, userRole);
                                                        if (isTEXTCellVisible === true) {
                                                            conditionValue = cell.value
                                                            return (
                                                                <Col span={6} className="mr-3 mt-2" key={`aws-tabcell-${cellIndex}`}>
                                                                    <AWSinput
                                                                        id={index}
                                                                        canEditPPR={canEditPPR}
                                                                        validation={this.props.validation}
                                                                        label=""
                                                                        placeholder={""}
                                                                        value={cell.value}
                                                                        onChangeFunc={
                                                                            (val) => this.props.handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)
                                                                        }
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.validation}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </Col>
                                                            );
                                                        }
                                                        break;

                                                    case "TEXTAREA":
                                                        let isCellTEXTAREAVisible = this.makeCellVisibleBasedOnPermission(cell, userRole);
                                                        if (isCellTEXTAREAVisible === true) {
                                                            conditionValue = cell.value
                                                            return (
                                                                <Col span={8} className="pr-3 mt-2" key={`aws-tabcell-textarea-${cellIndex}`}>
                                                                    <AWStextarea
                                                                        id={index}
                                                                        canEditPPR={canEditPPR}
                                                                        label=""
                                                                        validation={this.props.validation}
                                                                        placeholder={""}
                                                                        value={cell.value}
                                                                        onChangeFunc={(val) => {
                                                                            this.props.handleChange(val, questionValue, questionBlockIndex, rowId, cellIndex)
                                                                        }}
                                                                        triggeredNext={triggeredNext}
                                                                        validation={questionValue.formGroup.rows[0].cells[cellIndex].validation}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </Col>
                                                            );
                                                        }
                                                }
                                            })
                                        }
                                        {(conditionValue !== "None" && conditionValue !== "Other") && (lastRowElementID === rowId) &&
                                            (
                                                <span className={`float-left minus-icon mr-3  ${questionValue.tagName === "other_aws" ? 'mt-1' : 'pt-0 mt-0'}`}>
                                                    <img src={Plusicon}
                                                        alt="plus" key={`plusIcon_${rowId}`}
                                                        className={(questionValue.tagName === "other_aws" && rowId === 1) ? "cursor-pointer mt-2 pt-3" : questionValue.tagName === "other_aws" ? "mt-3 cursor-pointer pt-2" : " cursor-pointer pt-2 mt-2"}
                                                        onClick={
                                                            () => {
                                                                canEditPPR !== false  && quePermission.read === false ?
                                                                    this.handleOnAddFormGroup(questionValue.formGroup.rows[0].cells, questionValue.answer, questionValue, rowId) :
                                                                    () => { }
                                                            }
                                                        } />
                                                </span>)
                                        }
                                        {lastRowElementID > 1 ?
                                            <span className={`float-left minus-icon mr-3 cursor-pointer  ${(questionValue.tagName === "other_aws" && rowId === 1) ? "mt-0 pt-1" : questionValue.tagName === "other_aws" ? 'mt-2 pt-1' : 'pt-0 mt-0'}`}>
                                                <img src={Minusicon}
                                                    alt="minus"
                                                    className={(questionValue.tagName === "other_aws" && rowId === 1) ? "mt-2 pt-3" : questionValue.tagName === "other_aws" ? "mt-2 cursor-pointer pt-2" : "mt-3 cursor-pointer pt-0"}
                                                    onClick={
                                                        () => {canEditPPR !== false  &&  quePermission.read === false ? this.handleOnDelete(questionValue.formGroup.rows[0].cells, questionValue, rowId) : () => { } }
                                                    } />
                                            </span>
                                            : ''}
                                    </Col>
                                )
                            }
                        }
                    })
                }
            </div>
        )
    }
}

export default DynamicFormGroup;
