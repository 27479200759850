import React, { Component } from "react";
import { Popover, Icon } from "@amzn/awsui-components-react";
import AWScheckbox from "../AWScheckbox/AWScheckbox";
import Col from "../AWSCol/AWSCol";
import AWSinput from "../AWSInput/AWSinput";
import AWSError from '../AWSError/AWSError';
import Plusicon from "../../../images/Pluss_Icon.svg";
import Minusicon from '../../../images/minu_icon.svg';
import { createTableObjRowCell } from "../../../constants/qualifyingQuestionsConstants";
import "../../Dashboard/DealStructure.scss";


class RateTable extends Component {

    state = {
        errorLabel: "",
        error: false
    }

    componentDidMount() {
    }

    handleTieredPricingChange = (value, questionValue) => {
        if (value) {
            // Add tiered pricing for rate table
            questionValue.options = [
                {
                    sF_Id: null,
                    questionId: null,
                    optionKey: "> TB",
                    defaultValue: null,
                    optionValue: "> TB",
                    optionOrder: 1,
                    IconSource: null,
                    cellId: null
                },
                {
                    sF_Id: null,
                    questionId: null,
                    optionKey: "< TB",
                    defaultValue: null,
                    optionValue: "< TB",
                    optionOrder: 2,
                    IconSource: null,
                    cellId: null
                },
                ...questionValue.options.map((option, index) => {
                    return {
                        ...option,
                        optionOrder: index + 3
                    };
                })
            ];
            const rows = questionValue.tableObj.rows;
            const newRows = rows.map((row) => {
                return {
                    ...row,
                    cells: [
                        createTableObjRowCell(1),
                        createTableObjRowCell(2),
                        ...row.cells.map((cell, index) => {
                            return {
                                ...cell,
                                sequence: index + 3
                            };
                        })
                    ]
                };
            });
            if (newRows.length === 1) {
                newRows.push(...newRows.map((row) => {
                    return {
                        sequence: 2,
                        cells: row.cells.map((_, index) => {
                            return createTableObjRowCell(index + 1);
                        })
                    };
                }));
            }
            questionValue.tableObj.rows = newRows;
        } else {
            // Remove tiered pricing for rate table
            questionValue.options = questionValue.options.splice(2).map((option, index) => {
                return {
                    ...option,
                    optionOrder: index + 1
                };
            });
            const firstRow = questionValue.tableObj.rows[0];
            firstRow.cells = firstRow.cells.splice(2).map((cell, index) => {
                return {
                    ...cell,
                    sequence: index + 1
                };
            });
            questionValue.tableObj.rows = [firstRow];
        }
        this.props.handleChange(value, questionValue, undefined, undefined, "tieredPricing");
    };

    /**
     * This function will add extra fields for data transfer price table
     * 
    */
    handleOnAdd = () => {
        const { questionValue } = this.props;
        const rows = questionValue.tableObj.rows;
        const length = rows.length;
        let nextTierValue = (rows[length - 1].cells[1] && rows[length - 1].cells[1].value !== "") ? parseInt(rows[length - 1].cells[1].value, 10) + 1 : "";
        let tierObject = {
            "cells": [
                ...questionValue.options.map((header, index) => {
                    return createTableObjRowCell(index + 1, index === 0 ? nextTierValue : undefined);
                })
            ]
        }
        rows.push(tierObject);
        this.props.handleChange(rows, questionValue, undefined, undefined, "addRow");
    }

    /**
     * This function will remove extra fields for data transfer price table
     * @param {*} rowId rowId to be removed from data transfer price table
    */
    handleOnDelete = (rowId) => {
        let { questionValue } = this.props;
        const rows = questionValue.tableObj.rows;
        rows.splice(rowId, 1);
        this.props.handleChange(rows, questionValue, undefined, undefined, "deleteRow");
    }

    render() {
        let { questionValue, questionBlockIndex, handleChange, canEditPPR, isSummaryPage } = this.props;
        const tieredPricing = questionValue.tableObj.tieredPricing || false;
        const { quePermission } = questionValue;

        return (
            <div className="deal-wraper mt-5" id={questionValue.sF_Id} style={{ display: !questionValue.isVisible ? 'none' : 'block' }}>
                <div className={`input-label font-size14 float-left `}>{questionValue.isMandatory === true ? questionValue.queLabel : `${questionValue.queLabel} ${questionValue.isMandatory && "(Optional)"}`}</div>

                <Popover content={questionValue && questionValue.helpText}
                    preferredPosition="above" size="small" position="top">
                    <div className="float-left circle-alert">
                        <Icon name="status-info"></Icon>
                    </div>
                </Popover>
                <div>
                    <p className="dark-black font-size14 mt-2 pr-2 float-left col-3 pl-0">
                        Data Transfer Price (per GB){" "}
                    </p>
                    <span className="pb-1 ml-5 float-left">
                        <AWScheckbox
                            label={"Use Tiered Pricing"}
                            checked={tieredPricing}
                            onChangeFunc={value => this.handleTieredPricingChange(value, questionValue)}
                            canEditPPR={canEditPPR}
                        />
                    </span>
                </div>
                <div className="mt-4">
                    <div className="input-label font-size14 float-left"></div>
                    <Col span={24} className={"mt-2 rate-table-header-container"}>
                        {
                            questionValue.options && questionValue.options.map((option) => {
                                return (
                                    <Col key={option.optionKey} className={`mr-2 float-left table-border rate-table-header
                                        ${tieredPricing ? "rate-table-col-tiered width7p3 tiering-input input-style" : "rate-table-col width9p3 without-tiering-input input-style"}`}>
                                        <span className="grey-txt">{option.optionValue}</span>
                                    </Col>
                                )
                            })
                        }
                    </Col>
                    <Col span={24} key={"input-rows"} className="mt-2">
                        {
                            questionValue.tableObj && questionValue.tableObj.rows && questionValue.tableObj.rows.map((row, rowIndex) => {
                                let rowsLength = questionValue.tableObj.rows.length - 1;
                                return (
                                    <Col span={24} key={`tableRow_${rowIndex}`}>
                                        {
                                            row.cells && row.cells.map((cell, cellIndex) => {
                                                return (
                                                    <Col key={`tableCell_${cellIndex}`}
                                                        className={`mr-2 mt-0 float-left width9p3 without-tiering-input input-style
                                                        ${tieredPricing ? "rate-table-col-tiered width7p3 tiering-input input-style" : "rate-table-col width9p3 without-tiering-input input-style"}         
                                                        ${cell.editable === false && "pointer-events-none"}
                                                        ${isSummaryPage && "pointer-events-none"}
                                                        ${cell.isEdited && cell.isEdited === true && cell.value !== '' && isSummaryPage && 'orangeborder-input'}`}
                                                    >
                                                        <AWSinput
                                                            id={`input_r${rowIndex}c${cellIndex}`}
                                                            onChangeFunc={(val) => handleChange(val, questionValue, questionBlockIndex, rowIndex, cellIndex)}
                                                            placeholder={""}
                                                            value={cell.value.toString()}
                                                            canEditPPR={canEditPPR}
                                                        />
                                                    </Col>
                                                )
                                            })
                                        }
                                        {(!isSummaryPage && rowsLength === rowIndex && rowsLength > 0) &&
                                            (<Col span={2} key={rowIndex} className="float-left">
                                                <span className="mt-2 mr-3 float-left table-icons">
                                                    <img src={Plusicon} alt="plusicon" onClick={() => { this.handleOnAdd() }} />
                                                </span>
                                                {!isSummaryPage && rowIndex > 1 ?
                                                    <span className="float-left minus-icon mt-2 table-icons">
                                                        <img src={Minusicon} alt="minus" onClick={() => { this.handleOnDelete(rowIndex) }} />
                                                    </span>
                                                    : ''}
                                            </Col>)}
                                    </Col>

                                );
                            })
                        }
                        {this.state.error && (
                            <AWSError
                                errorLabel={this.state.errorLabel}
                            />
                        )}
                    </Col>

                </div>
            </div>
        )
    }
}

export default RateTable;
