import React, { Component } from "react";
import AWSinput from "../common/AWSInput/AWSinput";
import AWScheckbox from "../common/AWScheckbox/AWSDynamicCheckBox";
import AWSAddressInput from "../common/AWSAddressInput/AWSAddressInput";
import AWStextarea from "../common/AWStextarea/AWStextarea";
import AWSAlert from "../common/AWSAlert/AWSAlert";
import AWSDropdown from "../common/AWSDropdown/AWSDropdown";
import AWSDragDropFile from "../common/AWSDragDropFile/AWSDragDropFile";
import AWSSlider from "../common/AWSSlider/AWSSlider";
import AWSTextSlider from "../common/AWSSlider/AWSTextSlider";
import AWSButtonRadio from "../common/AWSButtonRadio/AWSButtonRadio";
import AWSToggleButton from "../common/AWSToggleButton/AWSToggleButton";
import AWSButtonImg from "../common/AWSButtonImg/AWSButtonImg";
import AWSInputDate from "../common/AWSInputDate/AWSinputDate";
import DynamicTextUp from "./DynamicTextUp";
import flagIcon from "../../images/flag.svg";
import _ from "underscore";
import moment from "moment";
import DynamicTable from "./DynamicTable";
import DynamicFormGroup from "./DynamicFormGroup";
import Col from "../common/AWSCol/AWSCol";
// import { Tooltip } from '@shopify/polaris';
import { Popover, Icon } from "@amzn/awsui-components-react";
import infoHelpIcon from "../../images/awsfalconhelp.png";
import AWSAccountNameAC from "../common/AWSAccountNameAC/AWSAccountNameAC";
import * as dealStatusConstant from "../../constants/dealStatusConstant";
import * as tagNameConstant from "../../constants/tagNameConstant";
import * as roleNames from "../../constants/roleNames.const";
import {
  DEFAULT_TEXT_SPAN,
  MAX_SPAN,
  MAX_WIDTH,
  TERM_LENGTH_SPAN
} from "../../constants/questionConstants";
import videoIcon from "../../images/Play.png";
import VideoIcon from "../common/Icon/VideoIcon/VideoIcon";
import RateTable from "../common/RateTable/RateTable";
import { isFinite, toNumber } from "lodash";
import { getOptionsOverride, getQuestionLabelOverride } from "../../utils/common";
import { isQuestionVisible } from "./utils/dependencyUtils";
const TermLengthLabel = "Term Length (Months)";

class RenderComponent extends Component {
  state = {
    isExpand: false,
    summaryData: {},
    termEndDate: "",
    termLength: "",
    stage: 0,
    alertMessage: "Please attach existing CloudFront PPA",
    payerId: "",
    triggeredNext: false,
    visible: false,
    updateDate: "",
    dataModel: {
      dealIntakeRequestForm: { stage: [] },
    },
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.date !== undefined || this.props.date !== undefined) {
      this.getTermEndDate();
    }
  }

  componentDidUpdate(prevProps) {
    let { date, termLength } = this.props;
    if (
      date !== undefined &&
      (prevProps.date !== date || prevProps.termLength !== termLength)
    ) {
      this.getTermEndDate();
    }
  }

  getTermEndDate = () => {
    if (
      this.props.date !== undefined &&
      this.props.date !== "" &&
      this.props.termLength !== ""
    ) {
      let date = new Date(this.props.date);
      let termLengthFromDate = date.setMonth(
        date.getMonth() + parseInt(this.props.termLength, 10)
      );
      let termEndDate = moment(termLengthFromDate)
        .subtract(1, "days")
        .format("MM/DD/YYYY");
      // this.state.termEndDate = termEndDate;
      this.setState({ termEndDate: termEndDate });
    }
  };

  discountEffectiveDateList = (questionValue) => {
    let values = [];
    if (questionValue.options && questionValue.options.length === 0) {
      for (let i = 1; i <= 12; i++) {
        const nextMonth = moment([]).startOf("month").add(i, "M");
        const date = nextMonth.format("MM/DD/YYYY");

        values.push({
          optionKey: date,
          defaultValue: null,
          optionValue: date,
          IconSource: null,
          optionOrder: i,
          cellId: null,
          label: date,
          value: date,
        });
      }
      questionValue.options = values;
      return values;
    } else {
      return questionValue.options;
    }
  };

  /**
   * this function will return proper date format based on condition (tagname) of each question
   */
  calendarValue = (val, questionValue) => {
    if (
      questionValue.tagName === tagNameConstant.startDateAgreementCalTag ||
      questionValue.tagName === tagNameConstant.effectiveDetailCalTag
    ) {
      return moment(val ? val : "Invalid Date").format("MM/DD/YYYY");
    } else if (
      questionValue &&
      questionValue.tagName === tagNameConstant.commitTermEffectiveDateCalTag
    ) {
      let discountTermEffectiveDateInDateFormat = new Date(this.props.date);
      let startDateForCommitTermEffectiveDate = new Date(
        discountTermEffectiveDateInDateFormat.getFullYear(),
        discountTermEffectiveDateInDateFormat.getMonth(),
        2
      );

      return moment(val ? val : startDateForCommitTermEffectiveDate).format(
        "MM/DD/YYYY"
      );
    } else {
      return moment(val ? val : "01/01/2019").format("MM/DD/YYYY");
    }
  };

  editQuestionCss = (questionValue) => {
    return questionValue.isQuestionEdit === true ? "editable-color" : "";
  };

  navigateToAdditionalealterms = () => {
    window.open("https://broadcast.amazon.com/videos/192263", "_blank");
  };
  /**
   * render component based on Json
   * @param {*} questionValue which is a question json
   * @param {*} triggeredNext used to trigger error check on click of next
   */
  renderComponent(
    questionValue,
    index,
    questionBlockIndex,
    triggeredNext,
    duplicateValidation,
    duplicateErrFlag
  ) {
    const { quePermission } = questionValue;
    const {
      dealStatus,
      onDropdownSelection,
      handleChange,
      canEditPPR,
      locationStateUser,
    } = this.props;

    if (
      questionValue &&
      questionValue.tagName === tagNameConstant.accountNameTag
    ) {
      return (
        <Col
          span={questionValue.queLabel === TermLengthLabel ? 6 : 8}
          className="mr-5 mt-5"
          key={`text_${index}`}
          id={questionValue.sF_Id}
          style={{
            display:
              !questionValue.isVisible ||
              (quePermission && quePermission.hidden)
                ? "none"
                : "block",
          }}
        >
          <AWSAccountNameAC
            id={questionValue.sF_Id}
            canEditPPR={canEditPPR}
            type="sfdc_account_name"
            questionValue={questionValue}
            value={questionValue.answer.answerValue}
            payerIdAccountValidation={() => {}}
            showHelpTooltip={true}
            onChangeFunc={(val) =>
              handleChange &&
              handleChange(val, questionValue, questionBlockIndex)
            }
            triggeredNext={triggeredNext}
            onSelectOption={(val) =>
              onDropdownSelection &&
              onDropdownSelection(val, questionValue, questionBlockIndex)
            }
            duplicateValidation={duplicateValidation}
            validation={questionValue.validation}
          />
        </Col>
      );
    }
    switch (questionValue && questionValue.queType) {
      case "AUTOCOMPLETE":
        return (
          <Col
            span={8}
            key={`engine_${index}`}
            className="mt-5 mr-5"
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden === true)
                  ? "none"
                  : "block",
            }}
          >
            <AWSAddressInput
              canEditPPR={canEditPPR}
              label={questionValue.queLabel}
              placeholder={""}
              value={questionValue.answer.answerValue}
              questionValue={questionValue}
              validation={questionValue.validation}
              sequence={questionValue.sequence}
              onChangeFunc={(val) =>
                this.props.handleChange(val, questionValue, questionBlockIndex)
              }
              triggeredNext={triggeredNext}
            />
          </Col>
        );

      case "BUTTONRADIO":
        const questionLabel = getQuestionLabelOverride(questionValue.queLabel);
        const options = getOptionsOverride(questionValue.queLabel, questionValue.options);
        return (
          <Col
            span={24}
            key={`button_radio_${index}`}
            className="mt-5"
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden === true)
                  ? "none"
                  : "block",
            }}
          >
            {questionLabel === null ? (
              ""
            ) : (
              <p
                className={`font-size14 mb-2 full-width ${this.editQuestionCss(
                  questionValue
                )}`}
              >
                <div className="float-left">
                  {questionValue.isMandatory === true
                    ? questionLabel
                    : `${questionLabel} (Optional)`}
                </div>
                <Popover
                  content={questionValue && questionValue.helpText}
                  position="top"
                  size="small"
                >
                  <span className="float-left circle-alert">
                    {/* <img src={infoHelpIcon} alt="help" /> */}
                    <Icon name="status-info"></Icon>
                  </span>
                </Popover>
              </p>
            )}

            <AWSButtonRadio
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              label={questionLabel || ""}
              options={options}
              defaultValue={questionValue.defaultValue}
              validation={questionValue.validation}
              value={questionValue.answer.answerValue}
              handleOnSelect={(val) =>
                this.props.handleOnSelect(
                  val,
                  questionValue,
                  questionBlockIndex
                )
              }
              triggeredNext={triggeredNext}
              helpText={questionValue.helpText}
              quePermission={quePermission}
              isDisabledByDependency={questionValue.isDisabledByDependency}
            />
          </Col>
        );

      case "BUTTONICON":
        return (
          <Col
            span={24}
            className="mt-4"
            key={`button_radio_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <Popover
              content={questionValue && questionValue.helpText}
              position="top"
              size="small"
            >
              <p
                className={`font-size14 mb-1 cursor-pointer ${this.editQuestionCss(
                  questionValue
                )}`}
              >
                {questionValue.isMandatory === true
                  ? questionValue.queLabel
                  : `${questionValue.queLabel} (Optional)`}
              </p>
            </Popover>
            <AWSButtonImg
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              label={questionValue.queLabel || ""}
              options={questionValue.options}
              defaultValue={questionValue.defaultValue}
              validation={questionValue.validation}
              imageLabel="plus"
              value={questionValue.answer.answerValue}
              handleOnSelect={(val) =>
                this.props.handleOnSelect(
                  val,
                  questionValue,
                  questionBlockIndex
                )
              }
              triggeredNext={triggeredNext}
              helpText={questionValue.helpText}
              quePermission={quePermission}
            />
          </Col>
        );

      case "TEXT": {
        if (this.props.date !== "" && this.props.termLength !== "") {
          let jan312009 = new Date(this.props.date);
          let eightMonthsFromJan312009 = jan312009.setMonth(
            jan312009.getMonth() + parseInt(this.props.termLength)
          );
          // this.state.termEndDate = moment(eightMonthsFromJan312009).subtract(1, "days").format("MM/DD/YYYY");
        }
        const { width } = questionValue;
        const widthNumber = toNumber(width);
        const span = questionValue.queLabel === TermLengthLabel
          ? TERM_LENGTH_SPAN
          : isFinite(widthNumber)
            ? widthNumber / MAX_WIDTH * MAX_SPAN
            : DEFAULT_TEXT_SPAN;

        return (
          <Col
            span={span}
            className="mr-5 mt-5"
            key={`text_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <AWSinput
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              questionValue={questionValue}
              termLength={this.props.termLength}
              label={questionValue.queLabel}
              placeholder={""}
              termEndDate={this.state.termEndDate}
              validation={questionValue.validation}
              value={questionValue.answer.answerValue}
              onChangeFunc={(val) =>
                this.props.handleChange(val, questionValue, questionBlockIndex)
              }
              triggeredNext={triggeredNext}
              duplicateValidation={duplicateValidation}
              userRole={this.props.userRole}
              dealStatus={this.props.dealStatus}
              dealInfo={this.props.dealInfo}
              locationStateUser={locationStateUser}
            />
          </Col>
        );
      }
      case "FORMGROUP":
        return (
          <DynamicFormGroup
            canEditPPR={canEditPPR}
            questionValue={questionValue}
            questionBlockIndex={questionBlockIndex}
            index={index}
            validation={questionValue.validation}
            triggeredNext={triggeredNext}
            userRole={this.props.userRole}
            handleOnSelect={(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            ) => {
              this.props.handleOnSelect(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              );
            }}
            handleChange={(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            ) => {
              this.props.handleChange(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              );
            }}
          />
        );

      case "TABLE":
        return (
          <DynamicTable
            canEditPPR={canEditPPR}
            questionValue={questionValue}
            validation={questionValue.validation}
            index={index}
            triggeredNext={triggeredNext}
            handleOnAdd={(cells, answer, questionValue, rowId, rowMax) => {
              this.props.handleOnAdd(
                cells,
                answer,
                questionValue,
                rowId,
                rowMax
              );
            }}
            handleOnSelect={(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            ) => {
              this.props.handleOnSelect(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              );
            }}
            handleChange={(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            ) => {
              this.props.handleChange(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              );
            }}
            handleAffiliatedPayerIdForSummary={
              this.props.handleAffiliatedPayerIdForSummary
            }
            onSelectOption={onDropdownSelection}
            questionBlockIndex={questionBlockIndex}
            duplicateValidation={duplicateValidation}
            handleDeleteTableRow={(rowId) => {
              this.props.handleDeleteTableRow(rowId);
            }}
            handleBulkDeleteTableRow={(rowId) => {
              this.props.handleBulkDeleteTableRow(rowId);
            }}
            duplicateErrFlag={duplicateErrFlag}
          />
        );

      case "RATETABLE":
        return (
          <RateTable
            canEditPPR={canEditPPR}
            questionValue={questionValue}
            validation={questionValue.validation}
            handleChange={(
              val,
              questionValue,
              questionBlockIndex,
              rowId,
              cellIndex
            ) => {
              this.props.handleChange(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              );
            }}
            isSummaryPage={false}
          />
        );

      case "DROPDOWN": {
        const questionLabel = getQuestionLabelOverride(questionValue.queLabel);
        const { width } = questionValue;
        const widthNumber = toNumber(width);
        const span = isFinite(widthNumber) ? widthNumber / 100 * 24 : 6;
        const { dataModel } = this.props;
        questionValue.isVisible = isQuestionVisible(dataModel, questionValue, questionValue.queType);
        return (
          <Col
            span={span}
            className="mr-5 mt-5"
            key={`dropdown_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <AWSDropdown
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              label={questionLabel}
              options={
                questionValue.tagName ===
                tagNameConstant.cloudFrontSalesManagerTag
                  ? this.props.dynamicSalesManagerOptions
                  : questionValue.options
              }
              defaultValue={questionValue.defaultValue}
              value={questionValue.answer.answerValue}
              termEndDate={this.state.termEndDate}
              onChangeAdditionalApproval={(key, value) =>
                this.props.onChangeAdditionalApproval(key, value)
              }
              handleOnSelect={(val) => {
                this.props.handleOnSelect(
                  val,
                  questionValue,
                  questionBlockIndex
                );
              }}
              triggeredNext={triggeredNext}
              validation={questionValue.validation}
              questionValue={questionValue}
              userRole={this.props.userRole}
              dealStatus={this.props.dealStatus}
              dealInfo={this.props.dealInfo}
              locationStateUser={locationStateUser}
              disableDropdown={questionValue.isDisabledByDependency}
            />
          </Col>
        );
      }

      case "DDCALENDAR":
        return (
          <div
            className="mr-5 mt-5 display-flex col-3 float-left pl-0 pr-0"
            key={`dropdown_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <AWSDropdown
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              label={questionValue.queLabel}
              options={
                questionValue.tagName === tagNameConstant.discountTermDateTag
                  ? this.discountEffectiveDateList(questionValue)
                  : questionValue.options
              }
              questionValue={questionValue}
              defaultValue={questionValue.defaultValue}
              value={questionValue.answer.answerValue}
              termEndDate={this.state.termEndDate}
              handleOnSelect={(value, dateValue) => {
                this.props.handleOnSelect(
                  value,
                  questionValue,
                  questionBlockIndex,
                  "row",
                  "id",
                  this.state.termEndDate,
                  value
                );
              }}
              triggeredNext={triggeredNext}
              validation={questionValue.validation}
              questionValue={questionValue}
              onChangeAdditionalApproval={(key, value) =>
                this.props.onChangeAdditionalApproval(key, value)
              }
              userRole={this.props.userRole}
              dealStatus={this.props.dealStatus}
              dealInfo={this.props.dealInfo}
              locationStateUser={locationStateUser}
            />
          </div>
        );

      case "SINGLECHECKBOX":
        return (
          <Col
            span={24}
            key={`check_box_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4"
          >
            <AWScheckbox
              canEditPPR={canEditPPR}
              id={questionValue.sF_Id}
              defaultValue={questionValue.defaultValue}
              value={questionValue.answer.answerValue}
              label={questionValue.queLabel}
              visible={questionValue}
              onChangeFunc={(value) => {
                this.props.handleOnSelect(
                  value,
                  questionValue,
                  questionBlockIndex
                );
              }}
              questionValue={questionValue}
              validation={questionValue.validation}
              triggeredNext={triggeredNext}
            />
          </Col>
        );

      case "FILEINPUT":
        return (
          <div
            key={`fileInput_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-5"
          >
            <AWSDragDropFile
              userRole={this.props.userRole}
              canEditPPR={canEditPPR}
              userRole={this.props.userRole}
              questionValue={questionValue}
              validation={questionValue.validation}
              index={index}
              pprId={this.props.pprId}
              value={questionValue.answer.answerValue}
              triggeredNext={triggeredNext}
              dealId={this.props.dataModel.deal_sF_Id}
              templateId={this.props.dataModel.template_sF_Id}
              onChange={(val) =>
                this.props.handleChange(val, questionValue, questionBlockIndex)
              }
            />
          </div>
        );

      case "RADIOBUTTON":
        const isSalesInputStatus =
          dealStatus === dealStatusConstant.Awaiting_Sales_Input ||
          dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM ||
          dealStatus === dealStatusConstant.PPR_Created;
        return (
          <React.Fragment key={`radio_${index}`}>
            <div
              id={questionValue.sF_Id}
              style={{
                display:
                  !questionValue.isVisible ||
                  (quePermission && quePermission.hidden)
                    ? "none"
                    : "block",
              }}
              className={
                questionValue.queLabel === "Is this an AISPL Account?"
                  ? "aisp-toggle"
                  : "mt-5 pb-2"
              }
            >
              <div
                className={
                  questionValue.queLabel === "Is this an AISPL Account?"
                    ? "font-size14 col-8 mr-1 mt-4 pt-2 p-0 float-left"
                    : "font-size14 col-9 toggle-txt-wraper p-0 pr-3 float-left"
                }
              >
                <span>
                  <div
                    className={`float-left col-11 p-0 toggle-text ${this.editQuestionCss(
                      questionValue
                    )}`}
                  >
                    {questionValue.isMandatory === true
                      ? questionValue.queLabel
                      : `${questionValue.queLabel} (Optional)`}
                    <span className="toggle-circle-wraper">
                      <Popover
                        content={questionValue && questionValue.helpText}
                        position="top"
                        size="small"
                      >
                        <span className="float-left circle-alert">
                          {/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon>
                        </span>
                      </Popover>
                    </span>
                    {this.props.userRole === roleNames.SALES_REPRESENTATIVE &&
                      questionValue.tagName ===
                        tagNameConstant.additionalDealTermTag && (
                        <span
                          className="youtube-icon additional-dealterm other-pricing-youtube"
                          onClick={() => {
                            this.navigateToAdditionalealterms();
                          }}
                        >
                          {/* <img src={videoIcon} alt="video" /> */}
                          <VideoIcon />
                          <span className="other-pricing-tooltip additional-dealterm-tooltip">
                            Additional Deal and Pricing terms
                          </span>
                        </span>
                      )}
                  </div>
                </span>
              </div>

              <span
                className={
                  questionValue.queLabel === "Is this an AISPL Account?"
                    ? "float-left mt-4 pt-1"
                    : "float-left pt-2"
                }
              >
                <AWSToggleButton
                  canEditPPR={canEditPPR}
                  userRole={this.props.userRole}
                  id={questionValue.sF_Id}
                  label={questionValue.queLabel}
                  options={questionValue.options}
                  defaultValue={questionValue.defaultValue}
                  value={questionValue.answer.answerValue}
                  onChange={(value) => {
                    this.props.handleOnSelect(
                      value,
                      questionValue,
                      questionBlockIndex
                    );
                  }}
                  onChangeAdditionalApproval={(key, value) =>
                    this.props.onChangeAdditionalApproval(key, value)
                  }
                  triggeredNext={triggeredNext}
                  quePermission={quePermission}
                  dealStatus={this.props.dealStatus}
                  questionValue={questionValue}
                  dealInfo={this.props.dealInfo}
                  locationStateUser={locationStateUser}
                  validation={questionValue.validation}
                  isDisabledByDependency={questionValue.isDisabledByDependency}
                />
              </span>
            </div>
            <div
              style={{
                display:
                  questionValue.isDisabledByDependency &&
                  questionValue.disableMessage &&
                  isSalesInputStatus
                    ? "block"
                    : "none",
              }}
              className="mt-4 col-11 p-0 pr-3"
            >
              <AWSAlert label={questionValue.disableMessage} />
            </div>
          </React.Fragment>
        );

      case "ALERT":
        return (
          <div
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4 col-11 p-0 pr-3"
          >
            <AWSAlert label={questionValue.queLabel} />
          </div>
        );

      case "SLIDER":
        let marks =
          questionValue &&
          questionValue.options.map((option) => option.optionValue);
        return (
          <Col
            span={18}
            key={`slider_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4"
          >
            <div className="pt-2 pb-5 full-width">
              <div
                className={`font-size14 ${this.editQuestionCss(questionValue)}`}
              >
                {questionValue.isMandatory === true
                  ? questionValue.queLabel
                  : `${questionValue.queLabel} (Optional)`}
              </div>
              <AWSTextSlider
                canEditPPR={canEditPPR}
                id={questionValue.sF_Id}
                questionValue={questionValue}
                options={questionValue.options}
                marks={marks}
                value={questionValue.answer.answerValue}
                label={questionValue.options}
                onChangeFunc={(val) =>
                  this.props.handleChange(
                    val,
                    questionValue,
                    questionBlockIndex
                  )
                }
                triggeredNext={triggeredNext}
                quePermission={quePermission}
              />
            </div>
          </Col>
        );

      case "TEXT SLIDER":
        let marksForTextSlider =
          questionValue &&
          questionValue.options.map((option) => option.optionValue);
        return (
          <Col
            span={18}
            key={`slider_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4"
          >
            <div className="pt-2 pb-5 full-width">
              <Popover
                content={questionValue && questionValue.helpText}
                position="top"
                size="small"
              >
                <p
                  className={`font-size14 cursor-pointer ${this.editQuestionCss(
                    questionValue
                  )}`}
                >
                  {questionValue.isMandatory === true
                    ? questionValue.queLabel
                    : `${questionValue.queLabel} (Optional)`}
                </p>
              </Popover>
              <AWSSlider
                canEditPPR={canEditPPR}
                id={questionValue.sF_Id}
                options={questionValue.options}
                marks={marksForTextSlider}
                value={questionValue.answer.answerValue}
                label={questionValue.options}
                onChangeFunc={(val) =>
                  this.props.handleChange(
                    val,
                    questionValue,
                    questionBlockIndex
                  )
                }
                triggeredNext={triggeredNext}
                quePermission={quePermission}
              />
            </div>
          </Col>
        );

      case "NUMERICAL SLIDER":
        let marksForNumericalSlider =
          questionValue &&
          questionValue.options.map((option) => option.optionValue);
        return (
          <Col
            span={18}
            key={`slider_${index}`}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4"
          >
            <div className="pt-2 pb-5 full-width">
              <Popover
                content={questionValue && questionValue.helpText}
                position="top"
                size="small"
              >
                <p className="font-size14 cursor-pointer">
                  {questionValue.isMandatory === true
                    ? questionValue.queLabel
                    : `${questionValue.queLabel} (Optional)`}
                </p>
              </Popover>
              <AWSSlider
                canEditPPR={canEditPPR}
                id={questionValue.sF_Id}
                options={questionValue.options}
                marks={marksForNumericalSlider}
                value={questionValue.answer.answerValue}
                label={questionValue.options}
                onChangeFunc={(val) =>
                  this.props.handleChange(
                    val,
                    questionValue,
                    questionBlockIndex
                  )
                }
                triggeredNext={triggeredNext}
                quePermission={quePermission}
              />
            </div>
          </Col>
        );

      case "TEXTAREA":
        return (
          <Col
            key={`textarea_${index}`}
            span={16}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-5"
          >
            <AWStextarea
              showOptional={true}
              canEditPPR={canEditPPR}
              label={questionValue.queLabel}
              placeholder={""}
              autosize={{ minRows: 4, maxRows: 10 }}
              onChangeFunc={(val) =>
                this.props.handleChange(val, questionValue, questionBlockIndex)
              }
              value={questionValue.answer.answerValue}
              questionValue={questionValue}
              validation={questionValue.validation}
              triggeredNext={triggeredNext}
              userRole={this.props.userRole}
              dealStatus={this.props.dealStatus}
              dealInfo={this.props.dealInfo}
              locationStateUser={locationStateUser}
            />
          </Col>
        );

      case "TEXTUP":
        return (
          <div
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <DynamicTextUp
              canEditPPR={canEditPPR}
              questionValue={questionValue}
              triggeredNext={triggeredNext}
              handleChange={(
                val,
                questionValue,
                questionBlockIndex,
                rowId,
                cellIndex
              ) =>
                this.props.handleChange(
                  val,
                  questionValue,
                  questionBlockIndex,
                  rowId,
                  cellIndex
                )
              }
              validation={questionValue.validation}
            />
          </div>
        );

      case "LABEL":
        return (
          <Col
            key={`label_${index}`}
            span={3}
            className={
              questionValue.queLabel === "AWS" ? "mt-5 pt-1" : "mt-3 pt-1"
            }
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <span className="grey-txt">
              {questionValue.isMandatory === true
                ? questionValue.queLabel
                : `${questionValue.queLabel} (Optional)`}
            </span>
          </Col>
        );

      case "MULTILINELABEL":
        return (
          <Col
            key={`multiline_${index}`}
            className="mt-4"
            span={18}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
          >
            <span className="grey-txt ">
              {questionValue.isMandatory === true
                ? questionValue.queLabel
                : `${questionValue.queLabel} (Optional)`}
            </span>
            <Popover
              content={questionValue && questionValue.helpText}
              position="top"
              size="small"
            >
              <span className="cursor-pointer circle-alert">
                {/* <img src={infoHelpIcon} alt="help" /> */}
                <Icon name="status-info"></Icon>
              </span>
            </Popover>
          </Col>
        );

      case "FLAG":
        return (
          <Col
            key={`flag_${index}`}
            span={24}
            id={questionValue.sF_Id}
            style={{
              display:
                !questionValue.isVisible ||
                (quePermission && quePermission.hidden)
                  ? "none"
                  : "block",
            }}
            className="mt-4"
          >
            <Col
              key={`flag_image_${index}`}
              span={1}
              className="pt-1 width-auto pr-3"
            >
              <img src={flagIcon} alt="flag" />
            </Col>
            <Col key={`flag_txt_${index}`} span={18}>
              <span className="flag-txt font-size14 red">
                {questionValue.isMandatory === true
                  ? questionValue.queLabel
                  : `${questionValue.queLabel} (Optional)`}
              </span>
            </Col>
          </Col>
        );

      case "CALENDAR":
        if (
          questionValue.tagName ===
            tagNameConstant.currentDiscountTermEndCalTag &&
          this.props.dealStatus === dealStatusConstant.Deal_Signed
        ) {
          return (
            <Col
              key={`calendar_${index}`}
              className="mr-5 mt-5 calendar-width col-3 float-left"
              id={questionValue.sF_Id}
              style={{
                display:
                  !questionValue.isVisible ||
                  (quePermission && quePermission.hidden)
                    ? "none"
                    : "block",
              }}
            >
              <AWSInputDate
                dealInfo={this.props.dealInfo}
                userRole={this.props.userRole}
                dealStatus={this.props.dealStatus}
                additionalApprovalFlag={this.props.additionalApprovalFlag}
                tagName={questionValue && questionValue.tagName}
                canEditPPR={canEditPPR}
                label={questionValue.queLabel}
                placeholder={""}
                value={questionValue.answer.answerValue}
                termEndDate={this.state.termEndDate}
                sequence={questionValue.sequence}
                date={this.props.date}
                onChangeAdditionalApproval={(key, value) =>
                  this.props.onChangeAdditionalApproval(key, value)
                }
                onChangeFunc={(val) => {
                  this.props.handleChange(
                    this.calendarValue(val, questionValue),
                    questionValue,
                    questionBlockIndex
                  );
                }}
                questionValue={questionValue}
                validation={questionValue.validation}
                triggeredNext={triggeredNext}
                locationStateUser={locationStateUser}
              />
            </Col>
          );
        } else if (
          questionValue.tagName ===
            tagNameConstant.currentDiscountTermEndCalTag &&
          this.props.dealStatus !== dealStatusConstant.Deal_Signed
        ) {
          return <div></div>;
        } else {
          return (
            <Col
              key={`calendar_${index}`}
              className="mr-5 mt-5 calendar-width col-3 float-left"
              id={questionValue.sF_Id}
              style={{
                display:
                  !questionValue.isVisible ||
                  (quePermission && quePermission.hidden)
                    ? "none"
                    : "block",
              }}
            >
              <AWSInputDate
                dealStatus={this.props.dealStatus}
                additionalApprovalFlag={this.props.additionalApprovalFlag}
                tagName={questionValue && questionValue.tagName}
                canEditPPR={canEditPPR}
                label={questionValue.queLabel}
                placeholder={""}
                value={questionValue.answer.answerValue}
                termEndDate={this.state.termEndDate}
                sequence={questionValue.sequence}
                date={this.props.date}
                onChangeAdditionalApproval={(key, value) =>
                  this.props.onChangeAdditionalApproval(key, value)
                }
                onChangeFunc={(val) => {
                  this.props.handleChange(
                    this.calendarValue(val, questionValue),
                    questionValue,
                    questionBlockIndex
                  );
                }}
                questionValue={questionValue}
                validation={questionValue.validation}
                triggeredNext={triggeredNext}
                userRole={this.props.userRole}
                dealInfo={this.props.dealInfo}
                locationStateUser={locationStateUser}
              />
            </Col>
          );
        }
    }
  }

  render() {
    let {
      questionValue,
      index,
      questionBlockIndex,
      triggeredNext,
      duplicateValidation,
      duplicateErrFlag,
    } = this.props;
    return (
      // <div>
      //     RenderComponent Method will render here.
      // </div>
      <div className="">
        {this.renderComponent(
          questionValue,
          index,
          questionBlockIndex,
          triggeredNext,
          duplicateValidation,
          duplicateErrFlag
        )}
      </div>
    );
  }
}

export default RenderComponent;
