import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { copyToClipFirefox, copyContent } from '../../utils/copy';
import { downloadCSV } from './../../utils/downloadCSV';
import AWSbutton from '../common/AWSbutton/AWSbutton';
import AWSInputDate from '../common/AWSInputDate/AWSinputDate';
import AWSDropdown from '../common/AWSDropdown/AWSDropdown';
import PrintDownload from './PrintDownload';
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import AWSConfig from '../../config/AWSconfig';
import dateRangeJson from '../../json/dateRange.json';
import dealStatusJson from '../../json/dealStatusForMultiSelect.json';
import dealTypejson from '../../json/dealStatusForMultiSelect.json';
import geoJson from '../../json/geoForReportMultiSelect.json';
import orgChangeGeoJson from '../../json/orgChangeGeoForReportingMultiselect.json';
import buJson from '../../json/buForMultiSelect.json';
import pprtypeJson from '../../json/pprType.json';
import orgChangeBusinessUnitOptionsMultiSelect from '../../json/orgChangeBusinessUnitOptionsMultiSelect.json';
import businessCustomerTypeJson from '../../json/businessCustomerTypeMultiSelect.json';
import './WorkLoadSCE.scss';
import CopyBlackIcon from "../../images/Copyblack.svg";
import downloadIcon from '../../images/download_grey.svg';
import * as roleNames from '../../constants/roleNames.const';
import { Multiselect } from "@amzn/awsui-components-react";

import dealOpenCloseJson from '../../json/dealOpenClose.json';
import { isAfterOrgChange } from "../../utils/common";

const isFirefox = typeof InstallTrigger !== 'undefined';
const config = new AWSConfig();
const currentDate = moment();
const workloadUrl = `${config.reportUrl}workload`;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
axios.defaults.headers.common["Content-Type"] = 'application/json'

let totalAmount = 0;

function sumProperty(arr, type) {
    return arr.reduce((total, obj) => {
        return total + Number(obj[type]);
    }, 0);
}

class WorkLoadSCE extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterDate: '',
            tableData: '',
            workloadData: [],
            loadingMessage: '',
            activateToast: false,
            dealType: [],
            dealStatus: [],
            pprType: '',
            geo: [],
            bu: [],
            startDate: null,
            endDate: null,
            dealStage: '',
            maxDate: new Date(),
            dealtypeSelectedOptions: [],
            buSelectedOptions: [],
            geoSelectedOptions: [],
            dealStatusSelectedOptions: [],
            businessCustomerTypeOptions: []
        },
            this.baseState = this.state;
        this.copyRef = React.createRef();
    }

    componentDidMount() {
        this.genearateWorkLoadReport();
    }

    genearateWorkLoadReport = () => {
        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};
        const { startDate = '', endDate = '', pprType, dealType, dealStatus, geo, bu, dealStage, filterDate, businessCustomerType } = this.state || {};

        let _endDate = endDate;

        if (filterDate === 'Custom Dates') {
            _endDate = moment(endDate).clone().startOf("day").utc().add(24, 'hours').format();
        }

        const reqBodyJson = {
            "dealType": dealType,
            "dealStatus": dealStatus,
            "pprType": pprType,
            "geo": geo,
            "bu": bu,
            'dealReady': dealStage,
            "salesManagerAppStartDte": startDate,
            "salesManagerAppEndDte": endDate,
            "AWSAlias": alias,
            "userRole": userRole,
            "businessCustomerType": businessCustomerType
        }
        this.showsalesReportToast('Please wait while workload Report is loading...', true);
        axios({
            method: 'POST',
            url: workloadUrl,
            data: reqBodyJson
        })
            .then(resp => {
                if (resp.data.statusCode >= 400) {
                    this.props.handleDisplayErr(resp.data.statusCode);

                } else {
                    this.setState({ workloadData: [...resp.data] });
                    this.showsalesReportToast('Please wait while workload Report is loading...', false);
                }
            }).catch((err) => {
                if (err.status && (err.status >= 400)) {
                    this.showsalesReportToast('Please wait while workload Report is loading...', false);
                    this.props.handleDisplayErr(err.status);
                } else if (err.response) {
                    const { response: { data: { message }, status } } = err;
                    if (status === 401)
                        this.showErrToast(message, true);
                    else if (status >= 400) {
                        this.showsalesReportToast('Please wait while workload Report is loading...', false);
                        this.props.handleDisplayErr(status);
                    }
                }
            });
    }

    resetFilter = () => {
        this.setState(this.baseState, () => {
            this.genearateWorkLoadReport()
        });
    }

    showsalesReportToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        })
    }

    showErrToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 3000)
        })
    }

    handleSelectedDateRange = value => {
        let startDate, endDate, startOfDate;
        let currentDate = moment();
        let dayStart = currentDate.clone().startOf("day").utc().format();
        startOfDate = new Date(dayStart);
        if (this.state.filterDate !== value) {
            switch (value) {
                case "Today":
                    startDate = new Date(dayStart);
                    endDate = new Date(moment(startDate).utc().add(24, 'hours').format());
                    break;
                case "Current Week":
                    var weekStart = currentDate.clone().startOf("isoWeek").utc().format();
                    startDate = new Date(weekStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Current Month":
                    var monthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(monthStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Current Quarter":
                    var quarterStart = currentDate.clone().startOf("quarter").format();
                    startDate = new Date(quarterStart);
                    endDate = new Date(moment(startOfDate).utc().add(24, 'hours').format());
                    break;
                case "Custom Dates":
                    var monthStart = currentDate.clone().startOf("month").utc().format();
                    startDate = new Date(monthStart);
                    endDate = new Date(moment().utc().format());
                    this.setState({
                        startDate: startDate,
                        endDate: endDate,
                        filterDate: value
                    });
                    break;
                default:
            }
            if (value && value !== "Custom Dates") {
                this.setState({
                    startDate: startDate,
                    endDate: endDate,
                    filterDate: value
                })
            }
        }
    };

    handleChange = (val, id) => {
        const valType = typeof (val);
        switch (id) {
            case "ppr_type":
                if (valType === "object")
                    this.setState({ pprType: '' });
                else if (val === "Select")
                    this.setState({ pprType: '' });
                else this.setState({ pprType: val });
                break;
            case 'deal_type':
                this.setState({ dealtype: val.selectedIds, dealtypeSelectedOptions: val.selectedOptions })
                break;
            case "bu":
                this.setState({ bu: val.selectedIds, buSelectedOptions: val.selectedOptions })
                break;
            case "deal_status":
                this.setState({ dealStatus: val.selectedIds, dealStatusSelectedOptions: val.selectedOptions })
                break;
            case "geo":
                this.setState({ geo: val.selectedIds, geoSelectedOptions: val.selectedOptions })
                break;
            case "start_date":
                this.setState({ startDate: val });
                break;
            case "end_date":
                this.setState({ endDate: val });
                break;
            case "business_customer_type":
                this.setState({ businessCustomerType: val.selectedIds, businessCustomerTypeOptions: val.selectedOptions })
                break;
            case "deal_stage":
                if (valType === "object")
                    this.setState({ dealStage: '' });
                else if (val === "Select")
                    this.setState({ dealStage: '' });
                else this.setState({ dealStage: val });
                break;
        }
    }

    loadToastForCopy = () => {
        this.showsalesReportToast('Copied....', true);
        setTimeout(() => {
            this.showsalesReportToast('Copied....', false);
        }, 1500)
    }

    callToCopy = () => {
        this.loadToastForCopy();
        isFirefox ? copyToClipFirefox(this.copyRef) : this.copyContent();
    }

    copyContent = () => {
        window.getSelection().removeAllRanges();
        var range = document.createRange();

        const div = this.copyRef.current;
        const color = div.style.background;
        div.style.margin = "10px";
        div.style.background = "#ffffff";

        document.getElementById("copyContent").style.visibility = "hidden";
        range.selectNode(div);
        window.getSelection().addRange(range);
        try {
            document.designMode = "on";

            document.execCommand("foreColor", false, "black");
            document.execCommand("copy");
            document.designMode = "off";
        } catch (err) {
            this.showErrToast('Copying Error', false);
        } finally {
            document.getElementById("copyContent").style.visibility = "visible";
            div.style.margin = "0px";
            div.style.background = color;
            document.designMode = "on";
            document.designMode = "off";
            window.getSelection().removeAllRanges();
        }
    };

    downlodCSVFile = () => {
        this.showErrToast('Preparing the CSV FILE to be dowloaded...', true);
        const { workloadData } = this.state;
        downloadCSV(workloadData, 'exportworkload');
    }

    render() {
        const { filterDate, workloadData, loadingMessage, activateToast, startDate, endDate, pprType, dealStatus, dealType,
            geo, bu, dealStage, maxDate, dealtypeSelectedOptions, geoSelectedOptions, buSelectedOptions, dealStatusSelectedOptions, businessCustomerTypeOptions } = this.state;
        const { locationState } = this.props;
        const { userRole = '', alias = '' } = locationState || {};

        totalAmount = (sumProperty(workloadData, 'total'));

        return (
            <div className="workload-wraper full-width">
                <div className="col-9 float-left">
                    {userRole === roleNames.SCE_OWNER ?
                        <h1 className="font-size18 amber-bold">Workload SCE</h1> :
                        userRole === roleNames.IGT_OWNER ? <h1 className="font-size18 amber-bold">Workload PPO</h1> :
                            userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ?
                                <h1 className="font-size18 amber-bold">Workload Private Pricing Public Sector</h1> :
                                userRole === roleNames.PRIVATE_PRICING_CHINA ? <h1 className="font-size18 amber-bold">Workload Private Pricing China</h1> : null}
                    {this.state.filterDate === "Custom Dates" && (
                        <div className="col-8 float-right timeframe-datepicker p-0 pb-4 pt-1">
                            <span className="date-wraper col-6 start-date float-left">
                                <DatePicker
                                    id={`datepicker_startDate`}
                                    selected={startDate}
                                    onChange={(value) => this.handleChange(value, "start_date")}
                                    maxDate={maxDate}
                                />
                            </span>
                            <span className="totxt float-left">To</span>
                            <span className="date-wraper col-6 end-date pl-5 pr-0 float-left">
                                <DatePicker
                                    id={`datepicker_endDate`}
                                    selected={endDate}
                                    onChange={(value) => this.handleChange(value, "end_date")}
                                    minDate={startDate}
                                    maxDate={maxDate}
                                />
                            </span>
                        </div>
                    )}
                    <div className="generate-btn col-12 float-left p-0">
                        <div className="col-8 float-left p-0">
                            <AWSbutton
                                btnType="fill"
                                label="Generate"
                                isIcon={false}
                                onClick={this.genearateWorkLoadReport}
                            />
                        </div>

                        <div className="float-right p-0">
                            <p className="float-left mb-0"><div className="float-right font-size12  cursor-pointer btn-copy noselect generate-ptsicon"
                                id="copyContent"
                                onClick={() => this.callToCopy()}
                            >
                                Copy
                                            <img src={CopyBlackIcon} alt="copy" className="ml-2" />
                            </div>
                            </p>
                            <p className="float-left width-auto ml-4 cursor-pointer mb-0" onClick={this.downlodCSVFile}><span className="font-size12 float-left pr-2">Download</span><img src={downloadIcon} alt="download" /></p>
                        </div>
                        <div ref={this.copyRef} className="full-width pt-4">
                            <table className="p-0 m-0" border='1' borderColor="#cecece">
                                <thead>
                                    <tr>
                                        <th>First Name </th>
                                        <th>Last Name </th>
                                        <th>PPR Count </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workloadData.map((workloadDataVal, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{workloadDataVal.firstName}</td>
                                                <td>{workloadDataVal.lastName}</td>
                                                <td>{workloadDataVal.pprCount}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td>{totalAmount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-3 float-left pr-0">
                    <p className="font-size14 amber-bold">Filters</p>
                    <ul className="full-width m-0 p-0 filter-wraper pr-2">
                        <li>
                            <AWSDropdown
                                id="deal_stage"
                                label="Open/Closed"
                                options={dealOpenCloseJson}
                                value={dealStage}
                                handleOnSelect={(value) => this.handleChange(value, "deal_stage")}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="date_filter"
                                label="Sales Manager Approved Date"
                                options={dateRangeJson}
                                value={filterDate}
                                handleOnSelect={(value) => this.handleSelectedDateRange(value)}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <AWSDropdown
                                id="ppr_type"
                                label="PPR Type"
                                options={pprtypeJson}
                                value={pprType}
                                handleOnSelect={(value) => this.handleChange(value, 'ppr_type')}
                                defaultValue="Select"
                                filterDD="filter"
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Deal Type
                            </span>
                            <Multiselect
                                id="deal_type"
                                options={dealTypejson}
                                selectedOptions={dealtypeSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'deal_type')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Deal Status
                            </span>
                            <Multiselect
                                id="deal_status"
                                options={dealStatusJson}
                                selectedOptions={dealStatusSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'deal_status')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Geo
                            </span>
                            <Multiselect
                                id="geo"
                                options={isAfterOrgChange() ? orgChangeGeoJson : geoJson}
                                selectedOptions={geoSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'geo')}
                            />
                        </li>
                        <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                BU
                                    </span>
                            <Multiselect
                                id="bu"
                                label="Select"
                                options={isAfterOrgChange() ? orgChangeBusinessUnitOptionsMultiSelect : buJson}
                                selectedOptions={buSelectedOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'bu')}
                            />
                        </li>
                        {(isAfterOrgChange() && <li>
                            <span className='input-label float-left mb-2 label-txt'>
                                Customer Type
                                    </span>
                            <Multiselect
                                id="business_customer_type"
                                label="Select"
                                options={businessCustomerTypeJson || []}
                                selectedOptions={businessCustomerTypeOptions}
                                placeholder="Select"
                                checkboxes={true}
                                selectedLabel="Selected"
                                onChange={(e) => this.handleChange(e.detail, 'business_customer_type')}
                            />
                        </li>)}
                    </ul>
                    <div className="pt-4 float-left">
                        <AWSbutton
                            btnType="fill"
                            label="Reset Filter"
                            isIcon={false}
                            onClick={this.resetFilter}
                        />
                    </div>
                </div>
                {this.state.activateToast && (
                    <AWSLoadingToast
                        message={loadingMessage}
                    />
                )}
            </div>
        )
    }
}
export default WorkLoadSCE;