import React, { Component } from 'react';
import moment from "moment";
import AWStextarea from '../AWStextarea/AWStextarea';
import AWSbutton from '../AWSbutton/AWSbutton';
import closeIcon from '../../../images/close_light_grey_icon.svg';
import { submitComment, emailNotification } from "../../../services/endPoints";
import './LeftNavCollaboration.scss';
import * as roleNames from '../../../constants/roleNames.const';
import * as actionName from '../../../constants/actionButtonConstants';

let validationArray = [
    {
        cellId: null,
        message: "This is a mandatory field",
        type: "Required",
        criteria: null
    }
];

let validatingQuestion = {
    isVisible: true
}

class LeftNavCollaboration extends Component {
    state = {
        commentTxt: "",
        addCommentTxt: "",
        commentArray: [],
        commentButtonDisable: false
    }

    handleChange = (value) => {
        this.setState({
            commentTxt: value
        })
    }

    /**
     * this function will call api to save posted comment from collaboration tab
     */
    callPostCommentApi = (commentObjectArrayFromCollabsTab) => {
        const { pprId, locationStateUser, dealStatus, createdById, userRole } = this.props;
        const { commentTxt } = this.state;
        this.setState({
            commentButtonDisable: true
        })
        const commentApiData = {
            AWSAlias: createdById,
            userRole: roleNames.SALES_REPRESENTATIVE,
            dealStatus: dealStatus,
            pprId: "PPR-" + pprId,
            comments: commentTxt,
            loginAlias: locationStateUser.alias,
            action: "comment",
            loginRole: userRole
        }
        this.showDealStructureLoadingToast("Uploading comments...", true)
        submitComment(commentApiData)
            .then(() => {
                this.showDealStructureLoadingToast("Uploading comments...", false)
                this.showDealStructureLoadingToast("Comments Updated...", true)
                this.props.commentObjectArrayFromCollabsTab(commentObjectArrayFromCollabsTab)
                this.callEmailApi();
                setTimeout(() => {
                    this.showDealStructureLoadingToast("Comments Updated...", false)
                    this.setState({
                        commentButtonDisable: false
                    })
                }, 2000)

                this.setState({
                    commentTxt: "",
                })
            }).catch((err) => {
                this.showDealStructureLoadingToast("Failed to update comments...", true)
                setTimeout(() => {
                    this.showDealStructureLoadingToast("Failed to update comments...", false)
                    this.setState({
                        commentButtonDisable: false
                    })
                }, 3000)
            })
    }

    /** this function will triggered email api call */
    callEmailApi = () => {
        const { pprId, locationStateUser, dealStatus, createdById, dealInfo, accountManager, accountName } = this.props;

        const emailApiBody = {
            dealStatus: dealStatus,
            createdById: createdById,
            comment: true,
            type: dealInfo.pprType,
            userRole: locationStateUser.userRole,
            accountManager: accountManager,
            accountName: accountName,
            ownerId: dealInfo.ownerId,
            AWSAlias: locationStateUser.alias,
            pprId: pprId
        }
        const emailUrl = "email/sendMessage"
        emailNotification(emailUrl, emailApiBody)
    }

    showDealStructureLoadingToast = (message, toast) => {
        this.props.showDealStructureLoadingToast(message, toast);
    };

    /*add comment */
    addComment = () => {
        let { commentTxt } = this.state
        if (commentTxt !== null && commentTxt !== "") {
            const commentObjectArrayFromCollabsTab = {
                addCommentTxt: commentTxt,
                timeStamp: new Date().toISOString()
            }

            this.callPostCommentApi(commentObjectArrayFromCollabsTab)
        }
    }

    closeSummary = () => {
        this.props.handleOnClick()
    }
    render() {
        const { summarCommentArrayObject, triggerCommentValidation, userRole, pprId, summaryCommentTimeStamp, locationStateUser, commentArrayFromCollabTabs } = this.props;
        const { commentTxt, commentButtonDisable } = this.state;

        if (triggerCommentValidation === false) {
            validatingQuestion.isVisible = false
        } else {
            validatingQuestion.isVisible = true
        }
        return (
            <div className="collaboration-wraper full-width pt-3 scroll-width10 summary-scroll moz-scroll">
                <span className="close-summary light-white cursor-pointer float-right ml-4" onClick={() => { this.closeSummary() }}>Close <img src={closeIcon} alt="copy" className="ml-2" /></span>
                <div className="col-8 float-left">
                    <ul className="left-content p-0 float-left mb-0">
                        {
                            summarCommentArrayObject.commentText && summarCommentArrayObject.commentText.map((commentObject, commentObjectIndex) => {
                                if (commentObject.action == "Auto-Approved:") {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Submitted with comments as below:</h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) {commentObject.action}</h4>
                                            }
                                            {commentObject.commentText === "Auto-Approved:" ?
                                                <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p> :
                                                <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')}`}</p>
                                            }
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                }
                                else if (commentObject.commentText !== "" && commentObject.commentText !== null && commentObject.action === actionName.LOST) {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10">  {commentObject.commentedBy} ({commentObject.summaryUserRole}) Deal Lost with comments as below:</h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) {commentObject.action} with comments as below:</h4>
                                            }
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                }
                                else if (commentObject.commentText !== "" && commentObject.commentText !== null && commentObject.action === actionName.INACTIVE) {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10">  {commentObject.commentedBy} ({commentObject.summaryUserRole}) Inactive with comments as below: </h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) {commentObject.action} with comments as below:</h4>
                                            }
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                }
                                else if (commentObject.commentText !== "" && commentObject.commentText !== null && commentObject.action != actionName.COMMENT) {
                                    if (commentObject.action == actionName.SUBMIT) {
                                        commentObject.action = "Submitted";
                                    }
                                    if (commentObject.action == actionName.APPROVE) {
                                        commentObject.action = "Approved"
                                    }
                                    return (
                                        <li>
                                            <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) {commentObject.action} with comments as below:</h4>
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                } else if (commentObject.commentText !== "" && commentObject.commentText !== null && commentObject.action === actionName.COMMENT) {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Commented:</h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Commented:</h4>
                                            }
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                }
                            })
                        }
                        <li>
                            {/* {
                                commentArrayFromCollabTabs.length !== 0 && commentArrayFromCollabTabs.map(({ addCommentTxt, timeStamp }) => {
                                    return (
                                        <>
                                            <h4 className="font-size12 white mb-1 m-t-10"> {locationStateUser.firstName} {locationStateUser.lastName} ({locationStateUser.userRole}) Commented:</h4>
                                            <p className="font-size10 white mb-1">{`${moment(timeStamp).format('MM/DD/YYYY HH:mm')} : ${addCommentTxt}`}</p>
                                        </>
                                    )
                                })
                            } */}
                        </li>
                        {/* <li>
                            <h4 className="font-size12 white mb-1">Sales Manager : Hendry Mendez</h4>
                            <p className="time-txt font-size10 white mb-1">13:14:35, 25 Aug 2019 </p>
                            <p className="font-size10 white mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </li> */}
                    </ul>
                </div>
                <div className="col-4 float-left right-wraper comment-box">
                    {/* <div className="orange m-t-10">Add A Comment</div> */}
                    <AWStextarea
                        labelWhite={true}
                        className="summaryComments commentTextArea"
                        placeholder="Enter Comments"
                        label=""
                        onChangeFunc={(valueTextArea) => this.handleChange(valueTextArea)}
                        value={commentTxt || ""}
                        questionValue={validatingQuestion}
                    // validation={validationArray}
                    // triggeredNext={triggerCommentValidation}
                    />
                    <div className="col-12 float-left pr-0 pt-4 pb-4">
                        {/* {
                            this.props.dealAction && this.props.dealAction.map((dealAction, dealActionIndex) => {
                                if (this.props.ownerType === this.props.userRole) {
                                    return (
                                        <div className="width-auto float-left pt-3 btmbtn-group">
                                            <AWSbutton
                                                btnType={this.props.dealAction.length === (dealActionIndex + 1) ? "fill" : "trasparent-btn"}
                                                label={dealAction.sucActionLabel}
                                                // disabled={false}
                                                disabled={this.props.disableSubmitButton}
                                                onClick={() => { this.props.handleSubmit(dealAction) }}
                                                isIcon={false}
                                            />
                                        </div>
                                    )
                                }
                            })
                        } */}
                        <div className="float-right comment-btn">
                            <AWSbutton
                                btnType="btns unfill-button"
                                label="Comment"
                                isIcon={false}
                                onClick={() => { this.addComment() }}
                                disabled={commentButtonDisable}
                            />
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
export default LeftNavCollaboration;