import React, { Component } from "react";
import "./Leftnavigation.scss";
import PropTypes from "prop-types";
import nextlevel from "../../../images/next_level.svg";
import activeLevel from "../../../images/inprogress_state.svg";
import completeLevel from "../../../images/Finished_State.svg";
import flagIcon from "../../../images/flag_amber.svg";
import _ from "underscore";
import * as roleNames from "../../../constants/roleNames.const";
import videoIcon from "../../../images/Play.png";
import * as tagNameConstant from "../../../constants/tagNameConstant";
import { Icon } from "@amzn/awsui-components-react";
import VideoIcon from "../../common/Icon/VideoIcon/VideoIcon";
import { getIsAPSubMixRequired, getUsageMixValue } from "../../RateCard/DealDetails.js"

let makeTickGreenForRateCard = false;

const propTypesConstant = {
  navMenu: PropTypes.array,
  isExpand: PropTypes.bool,
  stage: PropTypes.number,
  terminationPage: PropTypes.bool,
  neglectCommitAndPricing: PropTypes.bool,
  additionalApprovalFlag: PropTypes.object,
  leftNavRateCardData: PropTypes.object,
  userRole: PropTypes.string,
  dealInfo: PropTypes.object
};

var count = 0;

class LeftNavigationCustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      count: 0,
      downArrowClicked: false,
      activeFlag: false,
    };
  }

  componentDidMount() {
    makeTickGreenForRateCard = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.changeInvalue !== prevProps.changeInvalue) {
      this.setState({
        count,
      });
    }
  }

  /**
   * this function will set state of stage based on stageId to render/open particular stage page
   * @param {*} event
   * @param {*} stageId this will be the id of that page
   */
  handleClick(event, stageId) {
    if (stageId !== 2) {
      this.props.onClick(stageId);
    } else if (
      (this.props.neglectCommitAndPricing === true ||
        this.props.neglectCommitAndPricing === false) &&
      stageId === 2 &&
      this.props.neglectCommitAndPricing === false
    ) {
      this.props.onClick(stageId);
    } else if (this.props.neglectCommitAndPricing !== true) {
      this.props.onClick(stageId);
    }
  }

  /**
   * this function will check for permission of question visible to loggedin user and return value to set visible state
   */
  makeCellVisibleBasedOnPermission = (cell, userRole) => {
    let updatedUserRole = userRole.replace(/ /g, "_");
    let isCellVisible = false;
    let cellPermission = cell.permissionSeq[0];
    let keyValueOfCellPermission = Object.keys(cellPermission);
    keyValueOfCellPermission.forEach((keyPair, keyPairIndex) => {
      if (keyPair === updatedUserRole) {
        isCellVisible = !cellPermission[keyPair].hidden;
      }
    });
    return isCellVisible;
  };

  /**
   * this function will check value and highlight dot-line if it is not empty
   * @param {*} queBlock this will contain list of question
   */
  getClassName(queBlock) {
    let congregate = true;

    if (queBlock && queBlock.isVisible) {
      _.each(queBlock.rows, (r, i) => {
        if (r && r.questions) {
          _.each(r.questions, (q) => {
            if (q) {
              if (
                q.isVisible &&
                q.quePermission &&
                q.quePermission.hidden === false &&
                q.isMandatory === true &&
                q.tagName !== tagNameConstant.currentDiscountTermEndCalTag &&
                q.queType !== "LABEL" &&
                q.queType !== "SLIDER" &&
                q.queType !== "MULTILINELABEL" &&
                q.queType !== "FLAG" &&
                q.queType !== "ALERT"
              ) {
                switch (q.queType) {
                  case "TABLE": {
                    q.tableObj &&
                      q.tableObj.rows.map((row, rowId) => {
                        if (row.type !== "HEADER") {
                          row.cells.map((cell, cellId) => {
                            if (cellId !== 0) {
                              if (cell.value !== null || cell.value !== "") {
                                congregate =
                                  congregate &&
                                  cell.value &&
                                  cell.value.length > 0;
                              } else {
                                congregate = false;
                              }
                            }
                          });
                        }
                        if (
                          q.tableObj.rows.length === 1 &&
                          row.type === "HEADER"
                        ) {
                          congregate = false;
                        }
                      });
                    break;
                  }
                  case "RATETABLE": {
                    q.tableObj &&
                      q.tableObj.rows.map((row) => {
                        row.cells.forEach((cell) => {
                          if (cell.value !== null || cell.value !== "") {
                            congregate =
                              congregate && cell.value && cell.value.length > 0;
                          } else {
                            congregate = false;
                          }
                        });
                      });
                    break;
                  }
                  case "FORMGROUP": {
                    q.formGroup &&
                      q.formGroup.rows &&
                      q.formGroup.rows.map((row, rowId) => {
                        if (rowId !== 0) {
                          row.cells.map((cell, cellId) => {
                            let isVisibleCell =
                              this.makeCellVisibleBasedOnPermission(
                                cell,
                                this.props.userRole
                              );
                            if (isVisibleCell === true) {
                              if (cell.value !== null || cell.value !== "") {
                                congregate =
                                  congregate &&
                                  cell.value &&
                                  cell.value.length > 0;
                              } else {
                                congregate = false;
                              }
                            }
                          });
                        }
                      });
                    break;
                  }
                  case "TEXTUP": {
                    q.textUpRows &&
                      q.textUpRows.map((textUpRow, textUpRowId) => {
                        textUpRow.cells.map((cell, cellIndex) => {
                          if (cell.value != null || cell.value !== "") {
                            congregate =
                              congregate && cell.value && cell.value.length > 0;
                          } else {
                            congregate = false;
                          }
                        });
                      });
                    break;
                  }
                  case "SINGLECHECKBOX": {
                    if (
                      q.answer.answerValue != null &&
                      (q.answer.answerValue === true ||
                        q.answer.answerValue === "true")
                    ) {
                      count++;
                    } else {
                      congregate = false;
                    }
                    break;
                  }
                  case "CALENDAR": {
                    if (
                      q.answer &&
                      q.answer.answerValue != null &&
                      q.answer.answerValue !== "Invalid date"
                    ) {
                      congregate =
                        congregate &&
                        q.answer &&
                        q.answer.answerValue.length > 0;
                      count++;
                    } else {
                      congregate = false;
                    }
                    break;
                  }
                  case "NUMERICAL SLIDER": {
                    const hasValueAndIsNumber =
                      q.answer && typeof q.answer.answerValue === "number";
                    congregate = congregate && hasValueAndIsNumber;
                    break;
                  }
                  case "RADIOBUTTON": {
                    q.validation.forEach((validation) => {
                      if (
                        validation.type === "Confirmation" &&
                        q.answer &&
                        q.answer.answerValue !== "Yes"
                      ) {
                        congregate = false;
                      }
                    });
                    break;
                  }
                  default: {
                    if (q.answer && q.answer.answerValue != null) {
                      if (q.tagName === tagNameConstant.termLengthTag) {
                        let valueNew =
                          q.answer && q.answer.answerValue.toString();
                        congregate =
                          congregate && valueNew && valueNew.length > 0;
                      } else {
                        congregate =
                          congregate &&
                          q.answer &&
                          q.answer.answerValue.length > 0;
                      }

                      count++;
                    } else {
                      congregate = false;
                      if (
                        q.tableObj &&
                        q.tableObj.rows &&
                        q.tableObj.rows.length === 1
                      ) {
                        congregate = false;
                      }
                    }
                  }
                }
              }
            }
          });
        }
      });
    }

    return congregate ? "active-line" : "progress-line";
  }

  /**
   * this function will make tick active state or progress state based on dot line
   * @param {*} stage
   * @param {*} index
   * @param {*} templateStageId
   * @param {*} visibleQuestionCount
   */
  getClassDetails(stage, index, templateStageId, visibleQuestionCount) {
    let active = document.querySelectorAll(
      `#menu_${templateStageId} .dot-lines li.active-line`
    ).length;
    if (visibleQuestionCount === active) {
      return "tick-img active-img all-done";
    } else {
      return stage === index ? "tick-img active-img" : "tick-img";
    }
  }

  /**
   * render green tick based on params
   * @param {*} stage neede for comparing with index to display tick mark
   * @param {*} index neede for comparing with stage to display tick mark
   * @param {*} templateStageId to get particular div
   * @param {*} visibleQuestionCount number of active and visible question
   */
  getImg(stage, index, templateStageId, visibleQuestionCount) {
    let active = document.querySelectorAll(
      `#menu_${templateStageId} .dot-lines li.active-line`
    ).length;
    let newActive = document.querySelectorAll(
      `#menu_${templateStageId} .dot-lines li.progress-line`
    ).length;

    if (visibleQuestionCount >= active) {
      if (visibleQuestionCount === active && newActive === 0) {
        return completeLevel;
      } else {
        return stage === index ? activeLevel : nextlevel;
      }
    } else {
      return stage === index ? activeLevel : completeLevel;
    }
  }

  /**
   * this function will render dot-lines (dashes) for commit and pricing page, and make tick green
   */
  rateCardDash = () => {
    let { leftNavRateCardData, userRole, dealInfo } = this.props;
    let isAPSubMixRequired = false;
    let stage1,
      stage2,
      stage3 = true;
    let sumOfInsertedValue = 0;
    let sumOfdatatTransferValue = 0;
    leftNavRateCardData =
      leftNavRateCardData &&
      leftNavRateCardData.VolumeCommitCheckBox === undefined
        ? JSON.parse(leftNavRateCardData)
        : leftNavRateCardData;

    if (leftNavRateCardData) {
      /* stage 1 */
      if (
        leftNavRateCardData.minimum_commitment_fee !== "" &&
        leftNavRateCardData.total_commitment_fee !== "" &&
        leftNavRateCardData.Aos_Selected !== "" &&
        leftNavRateCardData.TermLength !== ""
      ) {
        stage1 = true;
        leftNavRateCardData.VolumeCommitCheckBox.map((data) => {
          if (data.optionKey === 2 && data.status === true) {
            if (
              leftNavRateCardData.commitment_fee !== "" &&
              leftNavRateCardData.past_price !== "" &&
              leftNavRateCardData.floor_price !== ""
            ) {
              stage1 = true;
            } else {
              stage1 = false;
            }
          }
        });

        if (stage1 !== false) {
          if (
            parseFloat(
              leftNavRateCardData.total_commitment_fee
                .toString()
                .replace(/,/g, "")
            ) <
            parseFloat(
              leftNavRateCardData.minimum_commitment_fee
                .toString()
                .replace(/,/g, "")
            )
          ) {
            stage1 = false;
          } else {
            stage1 = true;
          }
          if (
            leftNavRateCardData.dataTransfertableError === "" &&
            leftNavRateCardData.defaultRateError === ""
          ) {
            stage1 = true;
          } else {
            stage1 = false;
          }
        }
      } else {
        leftNavRateCardData.VolumeCommitCheckBox.map((data) => {
          if (data.optionKey === 4 && data.status === true) {
            if (
              leftNavRateCardData.total_commitment_fee_Nocommit !== "" &&
              leftNavRateCardData.past_price_noCommit !== "" &&
              leftNavRateCardData.floor_price_noCommit !== "" &&
              leftNavRateCardData.total_commitment_fee_Nocommit !== 0
            ) {
              stage1 = true;

              let dataTransferRate =
                leftNavRateCardData.tieredPricing_noCommit === true
                  ? leftNavRateCardData.dataTransferPriceTieredNoCommit
                  : leftNavRateCardData.dataTransferPriceNotTieredNoCommit;
              dataTransferRate.map((cells) => {
                cells.cells.map((data) => {
                  if (data.value !== "") {
                    sumOfdatatTransferValue =
                      sumOfdatatTransferValue + parseFloat(data.value);
                  }
                });
              });
              stage1 =
                sumOfdatatTransferValue > 0 &&
                leftNavRateCardData.dataTransfertableErrorNoCommit === ""
                  ? true
                  : false;
            } else {
              stage1 = false;
            }
          } else {
            stage1 = false;
          }
        });
      }

      /* stage 2*/
      if (this.props.neglectCommitAndPricing === false) {
        leftNavRateCardData.regionalUsageMixValues.map((cells) => {
          cells.cells.map((data) => {
            if (data.value !== "") {
              sumOfInsertedValue = sumOfInsertedValue + parseFloat(data.value);
            }
          });
        });
        stage2 = sumOfInsertedValue === 100 ? true : false;
        if (stage2 === true) {
          if (leftNavRateCardData.regionalTraffic === "Yes") {
            sumOfInsertedValue = 0;
            leftNavRateCardData.regionalTrafficLimit.map((cells) => {
              cells.cells.map((data) => {
                if (data.value !== "") {
                  sumOfInsertedValue =
                    sumOfInsertedValue + parseInt(data.value);
                }
              });
            });
            stage2 = sumOfInsertedValue > 0 ? true : false;
          }
        }
      } else if (leftNavRateCardData.regionalTraffic === "Yes") {
        leftNavRateCardData.regionalTrafficLimit.map((cells) => {
          cells.cells.map((data) => {
            if (data.value !== "") {
              sumOfInsertedValue = sumOfInsertedValue + parseInt(data.value);
            }
          });
        });
        stage2 = sumOfInsertedValue > 0 ? true : false;
      } else {
        stage2 = true;
      }

      /*stage 3 */
      if (dealInfo) {
        isAPSubMixRequired = getIsAPSubMixRequired(getUsageMixValue('AP', leftNavRateCardData.regionalUsageMixValues[0].cells));
      }

      if (
        leftNavRateCardData.winDeal == "Yes" &&
        leftNavRateCardData.pricingTerms == "No"
      ) {
        stage3 = true;
      } else if (
        leftNavRateCardData.winDeal == "No" &&
        leftNavRateCardData.pricingTerms == "No" &&
        leftNavRateCardData.explainWindeal !== ""
      ) {
        stage3 = true;
      } else if (
        leftNavRateCardData.winDeal == "Yes" &&
        leftNavRateCardData.pricingTerms == "Yes"
      ) {
        leftNavRateCardData.formGroup &&
          leftNavRateCardData.formGroup.map((row) => {
            row.cells.map((cell) => {
              if (
                userRole === roleNames.SCE_OWNER ||
                userRole === roleNames.IGT_OWNER ||
                userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                userRole === roleNames.PRIVATE_PRICING_CHINA
              ) {
                row.cells.map((cell) => {
                  if (
                    (cell.value !== null || cell.value !== "") &&
                    row.corePlusOption !== "Select"
                  ) {
                    stage3 = stage3 && cell.value && cell.value.length > 0;
                  } else {
                    stage3 = false;
                  }
                });
              } else {
                row.cells.map((cell) => {
                  if (cell.value != null || cell.value !== "") {
                    stage3 = stage3 && cell.value && cell.value.length > 0;
                  } else {
                    stage3 = false;
                  }
                });
              }
            });
          });
      } else if (
        leftNavRateCardData.winDeal == "No" &&
        leftNavRateCardData.pricingTerms == "Yes" &&
        leftNavRateCardData.explainWindeal !== ""
      ) {
        leftNavRateCardData.formGroup &&
          leftNavRateCardData.formGroup.map((row) => {
            row.cells.map((cell) => {
              if (
                userRole === roleNames.SCE_OWNER ||
                userRole === roleNames.IGT_OWNER ||
                userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                userRole === roleNames.PRIVATE_PRICING_CHINA
              ) {
                row.cells.map((cell) => {
                  if (
                    (cell.value !== null || cell.value !== "") &&
                    row.corePlusOption !== "Select"
                  ) {
                    stage3 = stage3 && cell.value && cell.value.length > 0;
                  } else {
                    stage3 = false;
                  }
                });
              } else {
                row.cells.map((cell) => {
                  if (cell.value != null || cell.value !== "") {
                    stage3 = stage3 && cell.value && cell.value.length > 0;
                  } else {
                    stage3 = false;
                  }
                });
              }
            });
          });
      } else {
        stage3 = false;
      }

      if (
        this.props.selectedDealType === "Downgrade" ||
        this.props.selectedDealType === "Upgrade" ||
        (this.props.selectedDealType === "Renewal" &&
          this.props.renewalDealInfo === "Early Renewal")
      ) {
        if (leftNavRateCardData.TypeOfCFRL === "Bucket") {
          if (leftNavRateCardData.existCommitTermText !== "") {
            stage3 = true;
          } else {
            stage3 = false;
          }
        }
      }


      if (isAPSubMixRequired && (leftNavRateCardData.sub_region_mix === '' || leftNavRateCardData.sub_region_mix === null)) {
        stage3 = false;
      }

      if (stage1 === true && stage2 === true && stage3 === true) {
        makeTickGreenForRateCard = true;
      } else {
        makeTickGreenForRateCard = false;
      }
      return (
        <>
          <li
            key="0"
            className={stage1 ? "active-line" : "progress-line-forCNP"}
          ></li>
          <li
            key="1"
            className={stage2 ? "active-line" : "progress-line-forCNP"}
          ></li>
          <li
            key="2"
            className={stage3 ? "active-line" : "progress-line-forCNP"}
          ></li>
        </>
      );
    } else {
      if (this.props.stage !== 2) {
        return (
          <div style={{ visibility: "hidden" }}>
            <li
              key="0"
              className={stage1 ? "active-line" : "progress-line-forCNP"}
            ></li>
            <li
              key="1"
              className={stage2 ? "active-line" : "progress-line-forCNP"}
            ></li>
            <li
              key="2"
              className={stage3 ? "active-line" : "progress-line-forCNP"}
            ></li>
          </div>
        );
      }
    }
  };

  getCustomPricingTerms = () => {
    let pricingTerms = "";
    if (this.props.additionalPricingTerms) {
      this.props.additionalPricingTerms.map((row, rowIndex) => {
        if (rowIndex === 0) {
          if (
            row.corePlusOption !== "" &&
            row.corePlusOption !== "Custom ABT" &&
            row.corePlusOption !== "Select"
          ) {
            pricingTerms = "Core++ ABT";
          } else if (
            row.corePlusOption !== "" &&
            row.corePlusOption === "Custom ABT"
          ) {
            pricingTerms = "Custom ABT";
          } else {
            pricingTerms = "";
          }
        }
      });
    }
    return pricingTerms;
  };

  getCustomDealTerms = () => {
    let dealTerms = "";
    if (this.props.adiDealTermFormGroup) {
      this.props.adiDealTermFormGroup.map((formGroupRow, rowIndex) => {
        if (rowIndex === 1) {
          if (formGroupRow.cells) {
            formGroupRow.cells.map((formGroupCell, formGroupCellIndex) => {
              if (formGroupCell.sequence && formGroupCell.sequence === 3) {
                if (
                  formGroupCell.value &&
                  formGroupCell.value !== "Custom ABT" &&
                  typeof formGroupCell.value === "string"
                ) {
                  dealTerms = "Core++ ABT";
                } else if (
                  formGroupCell.value &&
                  formGroupCell.value === "Custom ABT" &&
                  typeof formGroupCell.value === "string"
                ) {
                  dealTerms = "Custom ABT";
                } else {
                  dealTerms = "";
                }
              }
            });
          }
        }
      });
    }
    return dealTerms;
  };

  /* scroll up left nav */
  scrollUpLeftnav = () => {
    document
      .getElementsByClassName("customer-info-padding")[0]
      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      downArrowClicked: false,
    });
  };

  /*scroll down left nav */
  scrollDownLeftnav = () => {
    var offsetHeight = document.getElementById("leftnav").offsetHeight;
    document
      .getElementsByClassName("customer-info-padding")[0]
      .scrollTo({ top: offsetHeight, left: 0, behavior: "smooth" });
    this.setState({
      downArrowClicked: true,
    });
  };

  render() {
    let navMenu = this.props.navMenu;
    const { stage, neglectCommitAndPricing } = this.props;
    let additionalApprovalFlag = this.props.additionalApprovalFlag;
    let additonalFlagArray = additionalApprovalFlag
      ? Object.values(additionalApprovalFlag).includes(true)
      : false;

    return (
      <div className="full-width">
        <div
          className="customer-info-padding full-width scroll-width0"
          id="leftnav"
        >
          {this.props.userRole === roleNames.SALES_REPRESENTATIVE && (
            <span
              className="youtube-icon float-right mr-5 mb-2 leftnav-youtube pr-1"
              onClick={() => {
                window.open(
                  "https://broadcast.amazon.com/videos/192257",
                  "_blank"
                );
              }}
            >
              {/* <img src={videoIcon} className="video-icon" alt="youtube"/> */}
              <VideoIcon />
              <span className="leftnav-tooltip tooltip-style">
                Left Navigation Pane
              </span>
            </span>
          )}

          {additonalFlagArray && additonalFlagArray === true && (
            <div className="col-12 flag-wraper mt-4">
              <span className="flag-icon float-left">
                <img src={flagIcon} alt="flag" />
              </span>
              <p className="dark-black text-center font-size12 amber-bold mb-0 p-2 mt-1">
                Requires additional approvals
              </p>
            </div>
          )}
          <div className="full-width pr-4 menu-container">
            <ul
              className={`pr-4 nav-container pt-4 pl-4 pb-3 mb-0 leftsection-wraper  ${
                this.props.isExpand ? "border-nav-wrapper-expand" : ""
              }`}
            >
              {navMenu &&
                navMenu.map((menu, index) => {
                  let countOfVisibleQuestion = 0;

                  menu &&
                    menu.queBlock &&
                    menu.queBlock.map((qb, qbindex) => {
                      if (qb && qb.isVisible === true) {
                        countOfVisibleQuestion = countOfVisibleQuestion + 1;
                      }
                    });

                  return (
                    <li
                      id={`menu_${menu && menu.templateStageId}`}
                      key={`${menu && menu.stageName}_${index}`}
                      style={{ cursor: "Pointer" }}
                      onClick={() => this.handleClick(this, index)}
                      className={
                        index === stage
                          ? `pb-4 ${
                              index === 2 && neglectCommitAndPricing === true
                                ? "neglect-page"
                                : ""
                            }`
                          : `pb-0 ${
                              index === 2 && neglectCommitAndPricing === true
                                ? "neglect-page"
                                : ""
                            }`
                      }
                    >
                      {menu &&
                        menu.stageSeq === "02" &&
                        (this.props.userRole === roleNames.SCE_OWNER ||
                          this.props.userRole === roleNames.IGT_OWNER ||
                          this.props.userRole ===
                            roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                          this.props.userRole ===
                            roleNames.PRIVATE_PRICING_CHINA) && (
                          <span className="flag-txt">
                            {/* {this.props.dealIdentifier && this.props.dealIdentifier.customDealTerms ? */}
                            <div className="txt white font-size10 text-right">
                              {this.getCustomDealTerms()}
                            </div>
                            {/* } */}
                          </span>
                        )}
                      {menu &&
                        menu.stageSeq === "03" &&
                        (this.props.userRole === roleNames.SCE_OWNER ||
                          this.props.userRole === roleNames.IGT_OWNER ||
                          this.props.userRole ===
                            roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                          this.props.userRole ===
                            roleNames.PRIVATE_PRICING_CHINA) && (
                          <span className="flag-txt">
                            {this.props.dealIdentifier &&
                              this.props.dealIdentifier.noCommitDeal && (
                                <div className="txt white font-size10 text-right">
                                  No Commit
                                </div>
                              )}
                            {this.props.dealIdentifier &&
                              this.props.dealIdentifier.customRateCard && (
                                <div className="txt white font-size10 text-right width60">
                                  $ RC2
                                </div>
                              )}
                            {/* {this.props.dealIdentifier && this.props.dealIdentifier.customPricingTerms ? */}
                            <div className="txt white font-size10 text-right">
                              {this.getCustomPricingTerms()}
                            </div>
                            {/* } */}
                          </span>
                        )}
                      <span
                        className={this.getClassDetails(
                          stage,
                          index,
                          menu && menu.templateStageId,
                          countOfVisibleQuestion
                        )}
                      >
                        {index !== 2 ? (
                          <img
                            src={this.getImg(
                              stage,
                              index,
                              menu.templateStageId,
                              countOfVisibleQuestion
                            )}
                          />
                        ) : (
                          <img
                            src={
                              makeTickGreenForRateCard === true
                                ? completeLevel
                                : stage === index
                                ? activeLevel
                                : nextlevel
                            }
                          />
                        )}
                      </span>
                      <span
                        className={
                          stage === index
                            ? "nav-step active-count"
                            : "nav-step active-less-step"
                        }
                      >
                        {menu && menu.stageSeq}
                      </span>
                      <span
                        className={
                          stage === index ? "nav-txt active-nav" : "nav-txt"
                        }
                      >
                        {menu && menu.stageName}
                      </span>

                      {
                        <div
                          className="dot-lines"
                          style={{
                            visibility: stage === index ? "visible" : "hidden",
                          }}
                        >
                          <ul className="p-0 float-right">
                            {menu &&
                              menu.queBlock &&
                              menu.queBlock.map((qb, qbindex) => {
                                if (menu.stageSeq !== "03") {
                                  if (qb && qb.isVisible) {
                                    return (
                                      <li
                                        key={qbindex}
                                        className={this.getClassName(qb, menu)}
                                      ></li>
                                    );
                                  }
                                }
                              })}
                            {menu &&
                              menu.stageSeq === "03" &&
                              this.rateCardDash()}
                          </ul>
                        </div>
                      }
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {navMenu.length > 4 && (
          <div className="col-12 float-left white leftnav-downarrows">
            {/* {this.state.downArrowClicked && <span className="cursor-pointer" onClick={() => this.scrollUpLeftnav()}><Icon source={ChevronUpMinor} /></span>} */}
            {/* {!this.state.downArrowClicked && <span className="cursor-pointer" onClick={() => this.scrollDownLeftnav()}><Icon source={ChevronDownMinor} /></span>} */}
          </div>
        )}
      </div>
    );
  }
}

LeftNavigationCustomerInfo.propTypes = propTypesConstant;

export default LeftNavigationCustomerInfo;
