import axios from 'axios';
import { call, delay, put, take, cancel, fork } from 'redux-saga/effects';
import AWSConfig from '../../src/config/AWSconfig'
import {
  REQUEST_PREDICTION,
  successPrediction,
  failurePrediction,
} from '../actions/actions';

const debounceTime = 200;
const config = new AWSConfig();

const searchUrl = config.awsFalconSearchUrl

export function* loadPredictions({ payload: query }) {
  if (query.value === "" || query.value.length < 4) {
    yield put(successPrediction([]));
    return;
  }

  yield delay(debounceTime);

  let url = `${searchUrl}search`;
  if (query.type === 'Customer Legal Name')
    url = `${searchUrl}custlegalsearch`;

  const { data, error } = yield call(axios.post, url, {
    query: {
      match_phrase_prefix: {
        [query.type]: query.value,
      },
    },
    _source: [query.type],
  });

  if (data && !error) {
    // data = [{sfdc_account_name: "360Networks Inc."}, {sfdc_account_name: "360Networks Inc.1"}, {sfdc_account_name: "360Networks Inc.2"}, {sfdc_account_name: "360Networks Inc.3"}];
    yield put(successPrediction({ data }));
  } else {
    yield put(failurePrediction({ error }));
  }
}

export default function* rootSaga() {
  let task;
  while (true) {
    const action = yield take(REQUEST_PREDICTION);
    if (task) {
      yield cancel(task);
    }
    task = yield fork(loadPredictions, action);
  }
}
