import React from "react";
import axios from 'axios';
import { Icon } from "@amzn/awsui-components-react";
// import { MobilePlusMajorMonotone } from '@shopify/polaris-icons';
import AWSModal from '../AWSModal/AWSModal';
import './AWSUserManagement.scss';
import _ from 'underscore';
import AWSConfig from '../../../config/AWSconfig';
import PropTypes from "prop-types";
import AWSAdminTable from "../AWSAdminTable/AWSAdminTable";
import AWSAdminTableEditIcon from "../../common/AWSAdminTableIcon/AWSAdminTableEditIcon";
import AWSAdminTableDeleteIcon from "../../common/AWSAdminTableIcon/AWSAdminTableDeleteIcon";
import AWSLoadingToast from '../../common/AWSLoadingToast/AWSLoadingToast';
import * as UserManagementConstants from "../AWSAdminTable/AdminTableConstants/UserManagementConstants";
import * as roleNames from '../../../constants/roleNames.const';

const config = new AWSConfig();
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
let activeOptions = [
  { "optionKey": "Active", "optionValue": "Active" },
  { "optionKey": "Inactive", "optionValue": "Inactive" }
];

class AWSUserManagement extends React.Component {

  state = {
    tableData: [],
    visible: false,
    modalType: "",
    deleteObj: "",
    roleOptions: [],
    userData:
    {
      "FirstName": "",
      "LastName": "",
      "UserRole": "",
      "AddedBy": "Admin",
      "AWSAlias": "",
      "UserStatus": "",
      "ReportingManager": "",
      "SecondaryRole": "",
      "SalesRepresentative": "",
      "isSPUser": false
    },
    activateToast: false,
    loadingMessage: ''
  };

  /**
  * This lifecycle method is calling API to get user management details
  */
  componentDidMount() {
    this.showUserManagementLoadingToast("Loading User List...", true);
    let UsersApi = `${config.userManagementbaseUrl}users/getUsers`;
    axios({
      method: "GET",
      url: UsersApi,
      headers: {
        'Authorization': localStorage.getItem('cfat'),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(resp => {
        this.setState({
          tableData: resp?.data?.Data
        });
        this.showUserManagementLoadingToast("Loading User List", false);
      }).catch(err => console.log(err));
  }

  /**
   *  This function is called to show toast message based on the selection
   * @param {*} message which is a message to display 
   * @param {*} toast which is a boolean to show hide the message
  */
  showUserManagementLoadingToast = (message, toast) => {
    this.props.showUserManagementLoadingToast(message, toast);
  }

  /**
 * This function is used to set the local state for each varaible
 * @param {*} modalType type of the modal to load  
 * @param {*} data value of all the role data 
 */
  showModal = (modalType, data) => {
    this.setState({
      roleOptions: []
    });
    if (modalType !== 'deleteUser') {
      this.showUserManagementLoadingToast("Loading...", true);
      let RolesApi = `${config.userManagementbaseUrl}users/Roles`;
      axios({
        method: "GET",
        url: RolesApi,
        headers: {
          'Authorization': localStorage.getItem('cfat'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
        .then(resp => {
          _.each(resp?.data?.Data?.Items, (Values) => {
            let roleOptions = { "optionKey": Values.roleName, "optionValue": Values.roleName };
            this.state.roleOptions.push(roleOptions);
          })
        }).catch(err => console.log(err));

          this.showUserManagementLoadingToast("Loading..", false);

          this.setState({
            visible: true,
            modalType: modalType,
            userData: data
          });
    } else {
      this.setState({
        visible: true,
        modalType: modalType,
        deleteObj: data
      });
    }
  };

  /**
   * This function is used to set the local state for each varaible
   * @param {*} value value of the particular varaible to set 
   * @param {*} name name of the particular varaible to set 
   */
  onChangeUserData = (name, value) => {
    this.setState(prevState => {
      let newUserData = { ...prevState.userData };
      if (name === roleNames.IS_SOLUTION_PROVIDER && value === false) {
        delete newUserData[name];
      } else {
        newUserData[name] = value;
      }
      return {
        userData: newUserData
      };
    });
  }

  /**
 * This function is used to handle the button selection in the modal
 */
  handleOkOrCancelOfModal = () => {
    this.setState({
      visible: false,
      userData:
      {
        "FirstName": "",
        "LastName": "",
        "UserRole": "",
        "AddedBy": "Admin",
        "AWSAlias": "",
        "UserStatus": "",
        "ReportingManager": "",
        "SecondaryRole": "",
        "SalesRepresentative": ""
      }
    });
  };

  showErrToast = (message, activeState) => {
    this.setState({
      activateToast: activeState,
      loadingMessage: message
    }, () => {
      setTimeout(() => {
        this.setState({
          activateToast: false,
        })
      }, 3000)
    })
  }

  /**
  * This function is used to call when the role is added or edited
  * @param {*} type type of action to be taken when the function called
  * @param {*} name name of the particular varaible to set 
  */
  handleSubmit = (type) => {
    const { location: { state: { alias } } } = this.props;

    if (type !== 'deleteUser') {
      let userData = this.state.userData;
      if (userData.FirstName.trim() !== "" && userData.LastName.trim() !== "" && userData.UserRole.trim() !== ""
        && userData.AWSAlias.trim() !== "" && userData.UserStatus.trim() !== "" && userData.ReportingManager.trim() !== "") {
        let message = (type === 'newUser') ? 'Adding New User...' : 'Updating User...';
        this.showUserManagementLoadingToast(message, true);
        let UsersApi = (type === 'newUser') ? `${config.userManagementbaseUrl}users/AddUser` : `${config.userManagementbaseUrl}users/updateUser`;
        axios({
          method: "POST",
          data: this.state.userData,
          url: UsersApi
        })
          .then(resp => {
            if (resp?.data?.responseCode === 400) {
              this.showUserManagementLoadingToast(resp?.data?.Data, true)
            } else {
              if (alias === userData.AWSAlias) {
                // Update active and secondary role if updating yourself
                localStorage.setItem(roleNames.ACTIVEROLE, userData.UserRole);
                if (userData.SecondaryRole) {
                  localStorage.setItem(roleNames.SECONDARYROLE, userData.SecondaryRole);
                  localStorage.setItem(roleNames.ORIGINALSECONDARYROLE, userData.SecondaryRole);
                } else {
                  localStorage.removeItem(roleNames.SECONDARYROLE);
                  localStorage.removeItem(roleNames.ORIGINALSECONDARYROLE);
                }
              }

              this.setState({
                tableData: resp?.data?.Data
              });
              this.setState(prevState => ({
                userData: {
                  ...prevState.userData,
                  "FirstName": "",
                  "LastName": "",
                  "UserRole": "",
                  "AddedBy": "Admin",
                  "AWSAlias": "",
                  "UserStatus": "",
                  "ReportingManager": "",
                  "SecondaryRole": "",
                  "SalesRepresentative": ""
                }
              }));
              this.setState({
                visible: false
              });
              this.showUserManagementLoadingToast("Adding New User", false);
            }
          }).catch(err => console.log(err));
      }
    } else {
      console.log('alias', alias);

      let cell = this.state.deleteObj;
      this.showUserManagementLoadingToast("Deleting user...", true);
      let reqdata = {
        "AWSAlias": cell.AWSAlias,
        "Role": cell.UserRole,
        "loginAlias": alias
      }
      let DeleteApi = `${config.userManagementbaseUrl}users/DeleteUser`;
      axios({
        method: "POST",
        url: DeleteApi,
        data: reqdata
      }).then(resp => {
        if (resp?.data?.responseCode === 400) {
          this.showUserManagementLoadingToast("Deleting user...", false);
          this.showErrToast(resp?.data?.Data, true);
        } else {
          let removeIndex = this.state?.tableData?.map(function (item) { return item.AWSAlias; }).indexOf(cell.AWSAlias);
          this.state?.tableData?.splice(removeIndex, 1);
          this.showUserManagementLoadingToast("User successfully removed from the application", true);
          setTimeout(() => {
            this.showUserManagementLoadingToast("User successfully removed from the application", false);
          }, 1000)
        }
      }).catch(err => console.log(err));
      this.setState({
        visible: false
      });
    }
  };

  /**
  * This function is used to set the local state for each varaible based on selection
  * @param {*} value value of the particular varaible to set 
  * @param {*} name name of the particular varaible to set 
  */
  handleOnSelect = (name, value) => {
    this.setState(prevState => ({
      userData: {
        ...prevState.userData,
        [name]: value
      }
    }));
  }

  /**
 * This function is used to edit role values in the modal
 * @param {*} cell cell value 
 * */
  onEdit = (cell) => {
    this.showModal("updateUser", cell)
  }

  /**
 * This function is used to show the delete popup in modal     
 * @param {*} cell cell value to be deleted
 * */
  onDelete = (cell) => {
    this.showModal("deleteUser", cell);
  }

  render() {
    const { loadingMessage } = this.state;
    let userColumns = [
      { header: "First Name", id: "FirstName", minWidth: "60px", cell: item => <div>{item.FirstName}</div> },
      { header: "Last Name", id: "LastName", minWidth: "60px", cell: item => <div>{item.LastName}</div> },
      { header: "AWS Alias", id: "AWSAlias", minWidth: "80px", cell: item => <div>{item.AWSAlias}</div> },
      { header: "Role", id: "UserRole", minWidth: "80px", cell: item => <div>{item.UserRole}</div> },
      { header: "Status", id: "UserStatus", minWidth: "80px", align: "center", cell: item => <div>{item.UserStatus}</div> },
      {
        header: "Actions",
        id: "action",
        minWidth: "80px",
        cell: item => <div className="float-left col-12 p-0">
          <div className="col-6 float-left p-0" onClick={(e) => this.onEdit(
            item
          )}>
            <AWSAdminTableEditIcon
            // onEdit = {() => this.onEdit(item)}
            />
          </div>
          <div className="col-6 float-left p-0" onClick={(e, index) => this.onDelete(
            item
          )}>
            <AWSAdminTableDeleteIcon />
          </div>
        </div>,
      }
    ];

    return (
      <div className="user-management full-width">
        <div className="full-width">
          <ul className="p-0 float-right sub-controls pt-2 pb-2 mb-0">
            <li className="pr-0" onClick={() => this.showModal("newUser", this.state.userData)}>
              <span className="mr-2 float-left close-icon plus-icon">
                <Icon name="add-plus"></Icon>
              </span><span className="float-left">New User</span>
            </li>
            {/* <li className="pr-0" ><span className="mr-2 float-left"><Icon source={SaveMinor} /></span> Save</li> */}
          </ul>
        </div>
        <div className="full-width user-managenent-table user-infor-table">
          <AWSAdminTable
            sortableColumnOptions={UserManagementConstants.userColumns}
            columns={userColumns}
            tableData={this.state.tableData}
            showFilter={true}
          />
        </div>
        <div className="full-width newUserPopup">
          <AWSModal
            type={this.state.modalType}
            className="newUserPopup"
            visible={this.state.visible}
            Activeoptions={activeOptions}
            Roleoptions={this.state.roleOptions}
            userData={this.state.userData}
            deleteObj={this.state.deleteObj}
            onChangeFunc={(name, value) => { this.onChangeUserData(name, value) }}
            handleOnSelect={(name, value) => { this.handleOnSelect(name, value) }}
            handleOk={(type) => { this.handleSubmit(type) }}
            handleCancel={() => { this.handleOkOrCancelOfModal() }}
          />
        </div>
        {this.state.activateToast && (
          <AWSLoadingToast
            message={loadingMessage}
          />
        )}
      </div>
    );
  }
}

export default AWSUserManagement;

AWSUserManagement.propTypes = {
  showUserManagementLoadingToast: PropTypes.func,
  handleOnSelect: PropTypes.func,
  onChangeFunc: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
};