import React, { Component } from 'react';
// import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import './DependentQuest.scss';
import './AddQuestion.scss';
import './PermissionAndSeq.scss';
import AWSRadioButton from '../../common/AWSRadioButton/AWSRadioButton';
import AWSLoadingToast from '../../common/AWSLoadingToast/AWSLoadingToast'
import Plusicon from "../../../images/Pluss_Icon.svg";
import Minusicon from '../../../images/minu_icon.svg';
import AWSConfig from '../../../config/AWSconfig'
import axios from 'axios';
import _ from 'underscore'

const config = new AWSConfig();

const baseUrlRole = `${config.userManagementbaseUrl}users/Roles`;
const baseSequenceRole = `${config.amazonFalconbaseUrl}sequence?seq=`;

class PermissionAndSeq extends Component {
    state = {
        value: '',
        roleList: [],
        selectedPermission: [],
        rowMatrix: [],
    }

    componentDidMount() {

        let { questionJson } = this.props;

        const { permissionSeq } = questionJson.queBlock[0].questions[0];
        let selectedPermission = [];
        if (questionJson && permissionSeq) {
            permissionSeq.map((item, index) => {
                selectedPermission.push(item);
            })
        }

        this.setState({ selectedPermission: [...selectedPermission] })
        this.rolesApiCall();
        this.addSequence();
        this.sequenceApiCall();
    }

    /**
     * this function will add empty row for sequencing
     */
    addSequence = () => {
        const { rowMatrix } = this.state;
        rowMatrix.push({ col: [{ value: false, answerVal: null }, { value: false, answerVal: null }, { value: false, answerVal: null }], newAddedRow: true })
        this.setState({ rowMatrix });
    }

    /**
     * this function will remove row of sequence and call handleSequencing to update rowNum and colNum
     */
    removeSequence = (index) => {
        let { rowMatrix } = this.state;
        const { handleSequencing } = this.props;
        handleSequencing(index + 1, 1, "removeSequence");
        rowMatrix.splice(index, 1);
        this.setState({
            rowMatrix
        })
    }

    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        })
    }

    /**
     * This function call backend api to get the question of roles in permission and Sequence
     */
    rolesApiCall = () => {
        this.showUserManagementLoadingToast("Loading permission .....", true);
        axios({
            method: "GET",
            url: baseUrlRole
        }).then(resp => {
            this.setState({ roleList: resp.data.Data.Items })
            this.showUserManagementLoadingToast("Loading permission", false);
        }).catch(err => console.log(err));
    }

    /**
     * This function call backend api to get the sequence of all question in section
     */
    sequenceApiCall = () => {
        let { oldDataModel, selectedStage, sectionIndex, questionJson, updateQuestion } = this.props;

        let questionIndex = oldDataModel[selectedStage].queBlock[sectionIndex].questions.length;

        let templateId = "";

        if (questionIndex > 0) {
            templateId = oldDataModel[selectedStage].templateStageId + "_" + oldDataModel[selectedStage].queBlock[sectionIndex].queBlkId;
        }


        this.showUserManagementLoadingToast("Loading Sequence .....", true);
        axios({
            method: "GET",
            url: baseSequenceRole + templateId
        }).then(resp => {
            this.setState({ rowMatrix: resp.data.rowSeq }, () => {
                if (updateQuestion === false) {
                    this.updateRowNumAndColumnNum(questionIndex)
                }
            })
            this.showUserManagementLoadingToast("Loading Sequence .....", false);
        }).catch(err => console.log(err));
    }

    updateRowNumAndColumnNum = (questionIndex) => {
        const { handleSequencing } = this.props;
        handleSequencing(questionIndex + 1, 1);
    }
    /**
     * This function will call callback function for setting permission of different role's in parent component
    */
    handlePermissionChange = (value, userRole) => {
        const { handlePermission } = this.props;
        handlePermission(value, userRole);
    }

    handleSequenceChange = (rowIndex, colIndex, newQuestionIndex) => {
        let { rowMatrix } = this.state;

        if (rowMatrix[rowIndex].col[colIndex].value === false) {
            rowMatrix && rowMatrix.map((row, rowMatIndex) => {
                row && row.col && row.col.map((col, colMatIndex) => {
                    if (col.answerVal === newQuestionIndex) {
                        col.answerVal = null
                        col.value = false
                    }
                })
            })
            rowMatrix[rowIndex].col[colIndex].value = true;
            rowMatrix[rowIndex].col[colIndex].answerVal = newQuestionIndex;
            const { handleSequencing } = this.props;
            handleSequencing(rowIndex + 1, colIndex + 1);
            this.setState({
                rowMatrix
            })
        }
    }

    /**
     * This function will render three radio buttion for read, read/write and hidden button.
    */
    renderAWSRadioButton = (userRoleName) => {
        let _userRole = userRoleName.split(' ').join('_');
        let selected = '';
        if (this.state.selectedPermission.length > 0) {
            let obj = this.state.selectedPermission[0][_userRole];
            let keys = [];
            if (obj) {
                keys = Object.keys(obj);
                for (let i = 0; i < keys.length; i++) {
                    if (obj[keys[i]]) {
                        selected = keys[i]
                    }
                }
            }
        }
        return (
            <AWSRadioButton
                selectedPermission={selected}
                onChangeFunc={(value) => this.handlePermissionChange(value, userRoleName)}
            />
        )
    }

    render() {
        const { rowMatrix } = this.state;

        return (
            <div className="full-width dependent-quest pt-5 mt-2 pr-4 pl-5 mb-4">
                {this.state.activateToast && (
                    <AWSLoadingToast
                        message={this.state.loadingMessage}
                        error={this.state.loadingError}
                        duration={this.state.loadingMessageDuration}
                    />
                )}
                <div className="col-6 float-left p-0">
                    <p className="dark-black mb-2">Permissions</p>
                    <div className="full-width radio-btnwrpaer">
                        <ul className="col-12 p-0">
                            <li className="dark-black permission-wraper header-container">
                                <div className="table-content dark-black ">Roles</div>
                                <div className="table-content dark-black text-center">Read Only</div>
                                <div className="table-content dark-black text-center">Read/Write</div>
                                <div className="table-content dark-black text-center">Hidden</div>
                            </li>
                            <div className="full-width body-wraper">
                                {
                                    this.state.roleList.map((roleList, index) => {
                                        return (
                                            <li className="dark-black permission-wraper " key={index}>
                                                <div className="table-content pt-2 grey">{roleList.roleName}</div>
                                                <div className="table-content text-center pt-1 radio-group">
                                                    {this.renderAWSRadioButton(roleList.roleName)}
                                                </div>
                                            </li>
                                        )
                                    }
                                    )
                                }
                            </div>

                        </ul>
                    </div>

                </div>
                {/* <div className="col-6 float-left pl-5">
                    <p className="dark-black mb-2">Question Sequence</p>                    
                    {rowMatrix.map((row, i) => (
                        <ul className="full-width sequence-wraper p-0">
                            <li key={i}>
                                <div className="width-auto sequnce-number float-left sequence-box">
                                    {++i}
                                </div>
                                {row && row.col && row.col.map((item, j) => (
                                    <div className={`col-3 float-left ${item.value === true ? 'filled-bg sequence-box' : 'empty-bg sequence-box cursor-pointer'}`} key={j} onClick={() => { this.handleSequenceChange(i - 1, j, 1) }}>{item.value}</div>
                                ))}
                            </li>
                            <div className="plusminus-wraper float-left ml-2">
                                {(i === rowMatrix.length) &&
                                    <>
                                        <span className="float-left mr-3 cursor-pointer"><img src={Plusicon} alt="plus" onClick={() => this.addSequence()} /></span>

                                        {(i - 1 > 0 && row && row.newAddedRow === true) && <span className="float-left cursor-pointer"><img src={Minusicon} onClick={() => this.removeSequence(i - 1)} alt="minus" /></span>}
                                    </>
                                }
                            </div>
                        </ul>
                    )
                    )}
                </div> */}
            </div>
        )
    }
}
export default PermissionAndSeq;