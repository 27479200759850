import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import AWSInput from '../../common/AWSInput/AWSinput';
import AWSDropDown from '../../common/AWSDropdown/AWSDropdown';
import AWScheckbox from "../../common/AWScheckbox/AWScheckbox";
import PropTypes from "prop-types";
import * as roleNames from '../../../constants/roleNames.const';
import salesManagerSecondaryRoles from '../../../json/salesManagerSecondaryRoles.json';
import ppoOwnerSecondaryRoles from '../../../json/ppoOwnerSecondaryRoles.json';

let validation = [{
    message: "This is a mandatory field",
    type: "Required"
}];

let questionValue = {
    isVisible: true,
    isMandatory: true
};

const rolesWithSecondaryRoles = [
    roleNames.SALES_MANAGER,
    roleNames.ADMIN,
    roleNames.IGT_OWNER,
    roleNames.PRIVATE_PRICING_CHINA
];

class AddNewUser extends Component {

    state = {
        triggeredNext: false,
        isVisible: true
    }

    /**
     * This function is used to set the local state for each varaible based on selection
     * @param {*} value value of the particular varaible to set 
     * @param {*} name name of the particular varaible to set 
     */
    handleOnSelect = (value, name) => {
        this.props.handleOnSelect(name, value);
    }

    /**
     * This function is used to set the local state for each varaible
     * @param {*} value value of the particular varaible to set 
     * @param {*} name name of the particular varaible to set 
     */
    onChangeFunc = (value, name) => {
        this.props.onChangeFunc(name, value);
    }

    /**
     * This function is called on click of the configuration changes and check for validation errors
     */
    handleOk = (type) => {
        this.setState({
            triggeredNext: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    triggeredNext: false
                })
                this.props.handleOk(type)
            }, 100)
        })
    }

    getSecondaryRoles = (userRole) => {
        switch (userRole) {
            case roleNames.SALES_MANAGER:
                return salesManagerSecondaryRoles;
            case roleNames.ADMIN: {
                const adminSecondaryRoles = [
                    {
                        "optionKey": "Select",
                        "optionValue": "Select"
                    },
                    ...this.props.Roleoptions.filter(roleOption => {
                        // Remove "Requestor" as an option since backend will change your role to
                        // "Sales Representative" if your UserRole is "Requestor".
                        return roleOption.optionKey !== roleNames.ADMIN && roleOption.optionKey !== roleNames.REQUESTOR
                    })
                ];
                return adminSecondaryRoles;
            }
            // PPO Owner (IGT_OWNER) and China fallthrough as they have same role options
            case roleNames.IGT_OWNER:
            case roleNames.PRIVATE_PRICING_CHINA: {
                /**
                 * We are only showing Public Private Sector role for PPO Owner for now
                 * props.Roleoptions is an array of all roles, so we can use filter in the future 
                 * to explicitly display allowed secondary roles for PPO Owner
                 */
                return ppoOwnerSecondaryRoles;
            }
            default:
                return [];
        }
    }

    render() {
        const { userData, type } = this.props;
        return (
            <div className="full-width addnewuser-popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        {
                            type === 'newUser' ? "New User Information" : "Update User Information"
                        }
                        <span className="float-right cursor-pointer pr-2 pt-1 close-icon" onClick={() => this.props.handleCancel()}>
                            <Icon name="close"></Icon>
                        </span></p>
                </div>
                <div className="full-width body-wraper pt-4 mb-5 pl-5 pr-5 pb-4">
                    <div className="full-width pt-3">
                        <div className="input-width float-left p-0 mr-5">
                            <p className="dark-black">First Name</p>
                            <div className="full-width">
                                <AWSInput
                                    placeholder="First Name"
                                    value={userData.FirstName}
                                    onChangeFunc={(value) => { this.onChangeFunc(value, "FirstName") }}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                />
                            </div>
                        </div>
                        <div className="input-width float-left p-0 mr-5">
                            <p className="dark-black">Last Name</p>
                            <div className="full-width">
                                <AWSInput
                                    placeholder="Last Name"
                                    value={userData.LastName}
                                    onChangeFunc={(value) => { this.onChangeFunc(value, "LastName") }}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                />
                            </div>
                        </div>
                        <div className={`input-width float-left p-0 ${type === "updateUser" ? "pointer-events-none" : ""}`}>
                            <p className="dark-black">AWS Alias</p>
                            <div className={`full-width ${type === "updateUser" ? "pointer-events-none" : ""}`}>
                                <AWSInput
                                    placeholder="AWS Alias"
                                    value={userData.AWSAlias}
                                    onChangeFunc={(value) => { (type !== "updateUser") ? this.onChangeFunc(value, "AWSAlias") : ""; }}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="full-width mt-5">
                        <div className="input-width float-left p-0 mr-5">
                            <p className="dark-black">Status</p>
                            <div className="full-width">
                                <AWSDropDown
                                    placeholder="Salesmanager@aws.com"
                                    options={this.props.Activeoptions}
                                    value={userData.UserStatus}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                    handleOnSelect={
                                        (value) => {
                                            this.handleOnSelect(value, "UserStatus")
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className="input-width float-left p-0 mr-5">
                            <p className="dark-black">Reporting Manager</p>
                            <div className="full-width">
                                <AWSInput
                                    placeholder="Reporting Manager"
                                    value={userData.ReportingManager}
                                    onChangeFunc={(value) => { this.onChangeFunc(value, "ReportingManager") }}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                />
                            </div>
                        </div>
                        <div className="input-width float-left p-0 mr-5">
                            <div>&nbsp;</div>
                            <div className="full-width">
                                <AWScheckbox
                                    label="Is Solution Provider"
                                    onChangeFunc={(value) => { this.onChangeFunc(value, roleNames.IS_SOLUTION_PROVIDER)} }
                                    checked={userData.isSPUser || false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="full-width mt-5">
                        <div className="input-width float-left p-0 mr-5">
                            <p className="dark-black">Assign Role</p>
                            <div className="full-width">
                                <AWSDropDown
                                    placeholder="Salesmanager@aws.com"
                                    options={this.props.Roleoptions}
                                    value={userData.UserRole}
                                    validation={validation}
                                    triggeredNext={this.state.triggeredNext}
                                    questionValue={questionValue}
                                    handleOnSelect={
                                        (value) => {
                                            this.handleOnSelect(value, "UserRole")
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {userData.UserRole && rolesWithSecondaryRoles.includes(userData.UserRole) &&
                            (
                                <div className="input-width float-left p-0 mr-5">
                                    <p className="dark-black">Assign Secondary Role (Optional)</p>
                                    <div className="full-width">
                                        <AWSDropDown
                                            placeholder="Salesmanager@aws.com"
                                            options={this.getSecondaryRoles(userData.UserRole)}
                                            value={userData.SecondaryRole}
                                            questionValue={questionValue}
                                            handleOnSelect={
                                                (value) => {
                                                    this.handleOnSelect(value === "Select" ? null : value, "SecondaryRole")
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        {userData.UserRole && (userData.UserRole === roleNames.REQUESTOR || userData.SecondaryRole === roleNames.REQUESTOR) &&
                            (
                                <div className="input-width float-left p-0 mr-5">
                                    <p className="dark-black">Sales Representative Alias</p>
                                    <div className="full-width">
                                        <AWSInput
                                            placeholder="Sales Representative"
                                            value={userData.SalesRepresentative}
                                            onChangeFunc={(value) => { this.onChangeFunc(value, "SalesRepresentative") }}
                                            validation={validation}
                                            triggeredNext={this.state.triggeredNext}
                                            questionValue={questionValue}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={
                                        type === 'newUser' ? "Add" : "Update"
                                    }
                                    isIcon={false}
                                    onClick={() => this.handleOk(type)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddNewUser;

AddNewUser.propTypes = {
    showUserManagementLoadingToast: PropTypes.func,
    handleOnSelect: PropTypes.func,
    onChangeFunc: PropTypes.func,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
    Roleoptions: PropTypes.any,
    Activeoptions: PropTypes.any,
    userData: PropTypes.any,
    type: PropTypes.any,
};