import axios from "axios";

export class ApiRequest {
  static get(url, params) {
    return axios({
      method: "GET",
      url,
      params
    });
  }

  static post(url, params, data) {
    return axios({
      method: "POST",
      url,
      params,
      data
    });
  }
}