import React, { Component } from 'react';
import {
    Table, TableContentSelector,
    TablePreferences,
    TableSelection, TablePagination,
    TableFiltering,
    TableSorting, Icon
} from "@amzn/awsui-components-react";
import './AWSTable.scss';

const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;
class AWSAdminTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filteringText: '',
            pageSize: 10,
        }
    }

    onFilteringChange({ detail: { filteringText } }) {
        this.setState({ filteringText });
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }
    
    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    // }
    
    updateWindowDimensions() {
        this.setState({
             width: window.innerWidth,
             height: window.innerHeight 
        },() => {
            this.updatepageSize();
        });
    }

    updatepageSize = () =>{
        if(this.state.width === 1280){
            this.setState({
                pageSize : 7
            })
        }
        if(this.state.width === 1366){
            this.setState({
                pageSize : 12
            })
        }
        if(this.state.width === 1600){
            this.setState({
                pageSize : 10
            })
        }
        if(this.state.width === 1680){
            this.setState({
                pageSize : 16
            })
        }
        if(this.state.width === 1980){
            this.setState({
                pageSize : 18
            })
        }
    }
    render() {
        const { showFilter } = this.props;
        return (
            <div>
                <div className={`col-12 float-left p-0 created-table`} style={{ visibility: "visible" }}>
                    <div className="admin-table">
                        <Table
                            loadingText="Loading records"
                            columnDefinitions={this.props.columns}
                            items={this.props.tableData}
                            stickyHeader={true}
                            resizableColumns={true}
                            variant="borderless">
                            <TableSorting sortableColumns={this.props.sortableColumnOptions} />
                            {showFilter && <>
                                <TablePagination pageSize={this.state.pageSize} className="reset-post" openEnd={true}></TablePagination>
                                <TableFiltering
                                    filteringLabel="Filter PPRs"
                                    filteringPlaceholder="Search"
                                    filteringText={this.state.filteringText}
                                    onFilteringChange={(event) => this.onFilteringChange(event)}
                                    filteringCountTextFunction={getFilterCounterText}
                                    className="awsui-table-filtering-container"
                                />
                            </>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default AWSAdminTable;