import React, { Component } from 'react';

class AWSCol extends Component {

    render() {
        let newSpanValue = (this.props.span) / 2;
        return (
            <div className={`${(this.props.span && `col-${Math.round(newSpanValue)} `) || ""}${this.props.className ? this.props.className + " " : ""}p-0 float-left`} style={this.props.style || undefined}>
                {this.props.children}
            </div>
        );
    }
}

export default AWSCol;
