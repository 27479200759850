import React from "react";
import PropTypes from "prop-types";
import * as roleNames from '../../../constants/roleNames.const';
import "../../Dashboard/AWSDashboard.scss";
import { Icon } from "@amzn/awsui-components-react";
export default class AWSTableCloseIcon extends React.Component {
  render() {
    let { dealStatus, userRole } = this.props;
    if (
      userRole &&
      dealStatus &&
      dealStatus !== "Inactive" &&
      dealStatus !== "Deal Lost" &&
      dealStatus !== "Deal Signed" &&
      dealStatus !== "PPR Created" &&
      userRole === roleNames.SALES_REPRESENTATIVE
    ) {
      return (
        <div className="text-center table-close-icon mt-0 pl-2 ml-1 float-left cursor-pointer">
            <span><Icon name="close" className="cursor-pointer"></Icon></span>
        </div>
      );
    } else {
      return (
        <div className="text-center table-grey-close-icon mt-0 pl-2 ml-1 float-left">
          <Icon name="close"></Icon>
        </div>
      );
    }
  }
}

AWSTableCloseIcon.propTypes = {
  label: PropTypes.string,
  onChangeFunc: PropTypes.func
};
