import React, { Component } from 'react';
import Col from '../../common/AWSCol/AWSCol';
import saveIcon from '../../../images/save_black.svg';

import AWSAdminTable from '../AWSAdminTable/AWSAdminTable';

import AWSButtonRadio from "../../common/AWSButtonRadio/AWSButtonRadio";
import axios from 'axios';
import PropTypes from "prop-types";
import AWSConfig from '../../../config/AWSconfig';
import AWSAdminTableInput from '../../common/AWSAdminTableInput/AWSAdminTableInput';

const config = new AWSConfig();

const RateCardType = [
    {
        optionKey: "Rate Card 1",
        optionValue: "Rate Card 1"
    },
    {
        optionKey: "Rate Card 2",
        optionValue: "Rate Card 2"
    }
];

class GlobalLimits extends Component {

    state = {
        columns: [],
        rateCardButtonOption: "Rate Card 1",
        tableEditedValues: [],
        edited: false,
        editableCellTable: false
    }

    /**
    * This lifecycle method is calling API to get the Global Limits values of rate card 1
    */
    componentDidMount() {
        let value = 'Rate Card 1';
        this.globalLimitsApiCall(value);
    }

    /**
     * This function call backend api to get the ZGlobal Limits ratecard of in admin module
     * @param {*} value which is a type of rate Card
     */
    globalLimitsApiCall = (value) => {
        let ratecard = (value === "Rate Card 2") ? "ratecard2" : "ratecard1";
        let reqdata = {
            "ratecard": ratecard,
        }
        this.showUserManagementLoadingToast("Loading Global Limits ...", true);
        let globalLimitsListApi = `${config.adminrateCardbaseUrl}globalLimitVals`;

        axios({
            method: "POST",
            url: globalLimitsListApi,
            data: reqdata
        })
            .then(resp => {
                this.setState({
                    columns: resp.data
                })
                this.showUserManagementLoadingToast("Loading Global Limits ...", false);
            })
            .catch(() => {
                this.showUserManagementLoadingToast("Failed Loading Global Limits ...", false);
            })
    }

    makeCellEditableTrue = () => {
        this.setState({
            editableCellTable: true
        })
    }

    getGlobalValue = () => {
        const columns = [
            {
                header: 'Monthly Commit (TB)',
                id: 'commit',
                minWidth: "100px",
                cell: item => <div>{item.commit}</div>,

            },
            {
                header: 'Global',
                id: 'price',
                minWidth: "40px",
                cell: item => <div >
                    <AWSAdminTableInput
                        edited={this.state.edited}
                        value={item.price.toString()}
                        editableCellTable={this.state.editableCellTable}
                        makeCellEditableTrue={() => this.makeCellEditableTrue()}
                        onChangeFunc={(val) => this.updateVolCommitData(val, "price", item)}
                        onBlurFunc={(val) => this.updateVolCommitDataonBlur(val, "price", item)}
                    />
                </div>
            },
        ];
        return columns;
    }

    updateVolCommitData = (val, keyOfVolCommitData, commitData) => {
        commitData[keyOfVolCommitData] = val;
        let { columns } = this.state;
        //this.onEdit(val, commitData)
        this.setState({
            columns
        })
    }

    updateVolCommitDataonBlur = (val, keyOfVolCommitData, commitData) => {
        commitData[keyOfVolCommitData] = val;
        this.onEdit(val, commitData)
        
    }

    /**
     * This function is called when editing the Global Limts Value
     * @param {*} data which is a column value of the edited row
     */
    onEdit = (val, commitData) => {
        this.saveGlobalLimitsEditedTableValues(val, commitData);
    }

    /**
     * This function is called to store the edited Global Limits values
     * @param {*} data which is a column of the edited row
     */
    saveGlobalLimitsEditedTableValues = (val, commitData) => {
        if (val !== '') {
            let obj = {
                "termlength": commitData.termLength,
                "aos": commitData.aos,
                "editedvals": [{
                    "limit": commitData.limit,
                    "commit": commitData.volCommit,
                    "editedprice": parseFloat(val)
                }]
            }

            // if(this.state.tableEditedValues.filter(item=> item.termlength === commitData.termLength && 
            //     item.aos === commitData.aos && 
            //     item.editedvals[0] &&
            //     item.editedvals[0].limit === commitData.limit &&
            //     item.editedvals[0].commit === commitData.volCommit)
            //     .length==0){
            //     this.state.tableEditedValues.push(obj);
            // }
            // else{
            //     this.state.tableEditedValues.filter(item=> item.termlength === commitData.termLength && 
            //         item.aos === commitData.aos && 
            //         item.editedvals[0] &&
            //         item.editedvals[0].limit === commitData.limit &&
            //         item.editedvals[0].commit === commitData.volCommit)[0].editedprice = parseFloat(val);
            // }

            this.state.tableEditedValues.push(obj);
            this.setState({
                edited: true
            })
        }
    }

    /**
     * This function is called to save the Global Limits values in API
    */
    SaveGlobalLimits = () => {
        this.setState({
            editableCellTable: false
        })
        if (this.state.edited === true) {
            this.showUserManagementLoadingToast("Saving Global Limits ...", true);
            let ratecard = (this.state.rateCardButtonOption === "Rate Card 2") ? "ratecard2" : "ratecard1";
            let globalLimitsSaveApi = `${config.adminrateCardbaseUrl}updateGloballimits`;
            let ReqData = {
                "ratecard": ratecard,
                "globalData": this.state.tableEditedValues
            }
            axios({
                method: "PUT",
                url: globalLimitsSaveApi,
                data: ReqData
            }).then(resp => {
                this.showUserManagementLoadingToast("Global Limits saved successfully...", true);
                setTimeout(() => {
                    this.showUserManagementLoadingToast("Global Limits saved successfully", false);
                }, 600)
                this.setState({
                    edited: false,
                    tableEditedValues: []
                })
            }).catch(() => {
                this.showUserManagementLoadingToast("Saving Global Limits Failed ...", false);
            })
        }
    }



    /**
     * This function used to handle rate card button navigation
     */
    handleRateCardButtonMenu = (value) => {
        this.setState({
            rateCardButtonOption: value,
            editableCellTable: false
        })
        this.globalLimitsApiCall(value);
    }

    /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
     */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    render() {
        return (
            <div className="modal-container global-limit-popup full-width">
                <div className="modal-wraper">
                    <div className="full-width">
                        <div className="col-11 float-left p-0 rate-cardtabs">
                            <AWSButtonRadio
                                options={RateCardType}
                                canEditPPR={true}
                                value={this.state.rateCardButtonOption}
                                handleOnSelect={(value) => this.handleRateCardButtonMenu(value)}
                                defaultValue="Rate Card 1"
                            />
                        </div>
                        <div className="float-right p-0">
                            <div className="float-right width-auto p-0 cursor-pointer pt-2" onClick={() => this.SaveGlobalLimits()}><span className="mr-2 float-left">
                                <img src={saveIcon} alt="save" height="14" />
                            </span>Save</div>
                        </div>
                        <div className="full-width">
                            {
                                this.state.columns.map((globalLimits, Index) => {
                                    return (
                                        <Col key={Index} span={6} className="pr-2 pt-3 float-left global-limit-table">
                                            <p className="font-size12 dark-black">{globalLimits.Name}</p>
                                            {
                                                <AWSAdminTable
                                                    tableData={globalLimits.values}
                                                    columns={this.getGlobalValue()}
                                                    tooltips={false}
                                                    layout={"fitData"}
                                                />
                                            }
                                        </Col>
                                    )
                                })
                            }
                        </div>
                        <p className="font-size12 grey mt-5 float-left">*Request fees waived for request >=35kb</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default GlobalLimits;

GlobalLimits.propTypes = {
    showUserManagementLoadingToast: PropTypes.func
};