import * as KatalMetrics from '@katal/metrics';
import KatalMetricsDriverConsoleLogJson from '@katal/metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverSushi from '@katal/metricsDriverSushi';
import * as config from './config';

function _setMetricsDriver(metricsDriver, metricsErrorHandler) {
    if (config.default.envConfig === config.default.local) {
        metricsDriver = new KatalMetricsDriverConsoleLogJson();
    } else {
        metricsDriver = new KatalMetricsDriverSushi.Builder()
            .withDomainRealm(config.default.domain, 'USAmazon')
            .withErrorHandler(metricsErrorHandler)
            .build();
    }
    return metricsDriver;
}

function _setMetricsContext() {
    let initialMetricsContext;
    initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite(config.default.envConfig)
        .withServiceName('AWSFalcon')
        .build();
    return initialMetricsContext;
}

const makePublisher = () => {
    const metricsErrorHandler = err => {
        throw new Error(err);
    };
    let metricsDriver = {};
    metricsDriver = _setMetricsDriver(metricsDriver, metricsErrorHandler);
    const initialMetricsContext = _setMetricsContext();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsErrorHandler,
        initialMetricsContext,
    );
};

const initialMetricsPublisher = makePublisher();
export default initialMetricsPublisher;
