import { SALES_MANAGER, IGT_OWNER, SCE_OWNER, PRIVATE_PRICING_CHINA, PRIVATE_PRICING_PUBLIC_SECTOR, SOLUTION_PROVIDER, BILLING_TEAM } from '../constants/roleNames.const'
export const isSalesManager = userRole => userRole === SALES_MANAGER;

export const isPPOOwner = userRole => userRole === IGT_OWNER;

export const isSceOwner = userRole => userRole === SCE_OWNER;

export const isChinaPPO = userRole => userRole === PRIVATE_PRICING_CHINA;

export const isPublicSector = userRole => userRole === PRIVATE_PRICING_PUBLIC_SECTOR;

export const isOnBoardingTeam = userRole => userRole === BILLING_TEAM;

export const isSPUser = userRole => userRole === SOLUTION_PROVIDER;

export const isPPOOrChina = userRole => isPPOOwner(userRole) || isChinaPPO(userRole);