import { isArray, isNil } from "lodash";
import * as tagNameConstant from "../../../constants/tagNameConstant";
import { BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS, NEW_BUSINESS_UNIT_ANSWER_LABELS, isNewBusinessUnitAnswerLabels } from "../../../constants/questionAnswerLabelConstants";

/**
 * this function will take question block element and make value of question (selected or written) empty when question is hidden of block
 * @params {*} questionBlockelement
 */
export const emptyHiddenFieldOfBlockElement = (questionBlockElement) => {
  questionBlockElement &&
    isArray(questionBlockElement.rows) &&
    questionBlockElement.rows.forEach((row) => {
      row &&
        isArray(row.questions) &&
        row.questions.forEach((question) => {
          if (
            question &&
            (question.questionType === "TABLE" ||
              question.questionType === "RATETABLE")
          ) {
            const { tableObj } = question;
            if (
              tableObj &&
              isArray(tableObj.rows) &&
              tableObj.rows.length > 1
            ) {
              tableObj.rows.splice(1, question.tableObj.rows.length);
            }
          }
        });
    });
};

/**
 * this function will empty the question answer when it is hidden
 * @params question
 */
export const emptyHiddenFieldOfQuestion = (question, useDefaultValue) => {
  if (useDefaultValue && question.answer) {
    question.answer.answerValue = question.defaultValue;
  } else if (
    question.queType !== "FILEINPUT" &&
    question.queType !== "RADIOBUTTON" &&
    question.queType !== "BUTTONRADIO" &&
    question.answer
  ) {
    question.answer.answerValue = null;
  }
};

/**
 * This function returns the answer value of the tagName specified
 * @param data - All the PPR intake form data/questions
 * @param tagName - Unique tagName associated with a question
 * @returns The answer of the question
 */
export const returnQuestionAnswerValue = (data, tagName) => {
  if (isNil(data) || !isArray(data)) {
    return undefined;
  }

  for (const pprFormPage of data) {
    if (pprFormPage.queBlock && isArray(pprFormPage.queBlock)) {
      for (const queBlock of pprFormPage.queBlock) {
        if (queBlock.rows && isArray(queBlock.rows)) {
          for (const questionsRow of queBlock.rows) {
            if (questionsRow.questions && isArray(questionsRow.questions)){
              for (const question of questionsRow.questions) {
                if (question && question.tagName && question.answer && question.tagName === tagName) {
                  return question.answer.answerValue;
                }
              }
            }
          }
        }
      }
    }
  }
};

/**
 * This function determines if the GCR/AGS vertical should be visible based on the Business Customer Type value
 * @param question - The question e.g. Business Unit question
 * @param verticalTagName - Tag name of the Vertical question
 * @returns boolean whether the question should be visible
 */
export const isCommercialSectorVerticalVisible = (question, businessUnit, businessCustomerType) => {
  switch (question.tagName) {
    case tagNameConstant.verticalValueTag:
      return businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.AGS && businessCustomerType !== BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.PS;
    case tagNameConstant.verticalValueTagCh:
      return businessUnit === NEW_BUSINESS_UNIT_ANSWER_LABELS.GCR && businessCustomerType !== BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.PS;
    default:
      return undefined;
  }
};

/**
 * This function determines if a question should be visible
 * @param data - All the PPR intake form data/questions
 * @param question - overrides the defaultVisibility 
 * @param questionType - Type of question that it will render e.g. DROPDOWN
 * @returns boolean whether the question should be visible
 */
export const isQuestionVisible = (data, question, questionType) => {

  switch (questionType) {
    case 'DROPDOWN':
      //Specialized rendering for AGS/GCR Vertical question
      let isCommercialCustomerTypeVerticalVisible;
      const businessUnit = returnQuestionAnswerValue(data, tagNameConstant.businessUnitTag);

      if (isNewBusinessUnitAnswerLabels(businessUnit)) {
        if (question.tagName && (question.tagName === tagNameConstant.verticalValueTag || question.tagName === tagNameConstant.verticalValueTagCh)) {
          const businessCustomerType = returnQuestionAnswerValue(data, tagNameConstant.businessCustomerType);
          isCommercialCustomerTypeVerticalVisible = isCommercialSectorVerticalVisible(question, businessUnit, businessCustomerType);
        }
      }
      return !isNil(isCommercialCustomerTypeVerticalVisible) ?  isCommercialCustomerTypeVerticalVisible : question.isVisible;
    default:
      return question.isVisible;
    }
};

export const handleQuestionBlockRenderingDependency = (
  data,
  dependentId,
  isVisible
) => {
  isArray(data) &&
    data.forEach((stage) => {
      isArray(stage.queBlock) &&
        stage.queBlock.forEach((questionBlock) => {
          if (
            questionBlock !== null &&
            dependentId === questionBlock.queBlkId
          ) {
            questionBlock.isVisible = isVisible;
            !isVisible && emptyHiddenFieldOfBlockElement(questionBlock);
          }
        });
    });
};

export const handleQuestionRenderingDependency = (
  data,
  dependentId,
  isVisible,
  useDefaultValue = false,
  checkCurrentDiscountTermEndCalTag, // for backwards compatability
  propagate,
  propagateCallback,
  resetValue,
  dependentValue,
  clearGeoField,
  render
) => {
  isArray(data) &&
    data.forEach((row) => {
      row &&
        isArray(row.questions) &&
        row.questions.forEach((question) => {
          // for backwards compatability
          const currentDiscountTermEndTagCondition =
            checkCurrentDiscountTermEndCalTag === true
              ? question.tagName !==
                tagNameConstant.currentDiscountTermEndCalTag
              : true;
          if (
            question !== null &&
            dependentId === question.queId &&
            currentDiscountTermEndTagCondition
          ) {
            question.isVisible = isNil(render) ? isVisible : render;
            (!question.isVisible && clearGeoField) && emptyHiddenFieldOfQuestion(question, useDefaultValue);
            propagate &&
              propagateCallback(question.answer.answerValue, question);
          }
          if (resetValue && dependentId === question.queId && question.answer) {
            question.answer.answerValue = question.defaultValue || null;
          }
          if (dependentValue && dependentId === question.queId && question.answer) {
            question.answer.answerValue = dependentValue;
          }
        });
    });
};

export const handleQuestionDisablingDependency = (
  data,
  dependentId,
  disable,
  disableMessage,
  dependentValue,
  propagate,
  propagateCallback
) => {
  isArray(data) &&
    data.forEach((row) => {
      row &&
        isArray(row.questions) &&
        row.questions.forEach((question) => {
          if (
            question !== null &&
            dependentId === question.queId &&
            question.answer
          ) {
            if (disable) {
              question.answer.answerValue =
                dependentValue !== undefined
                  ? dependentValue
                  : question.defaultValue;
            }
            question.isDisabledByDependency = disable;
            question.disableMessage = disableMessage;
            propagate &&
              propagateCallback(question.answer.answerValue, question);
          }
        });
    });
};