import React, { Component } from 'react';

class AWSRow extends Component {
    render() {
        return (
            <div className={`float-left ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}

export default AWSRow;
