import React, { Component } from 'react';
import AWSFlashBar from './AWSFlashBar';
import AWSconfig from '../../../config/AWSconfig';
import axios from 'axios';

const config = new AWSconfig();

class AWSFlashBarContainer extends Component {
  state = {
    level: 0,
    message: ""
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: config.isItDownUrl
    }).then((response) => {
      const { level, message } = response.data;
      this.setState({
        level: level,
        message: message
      });
    }).catch((error) => {
        console.error('AWSFlashBarContainer error:', error);
    });
  }

  render() {
    return (
      <AWSFlashBar
        level={this.state.level}
        message={this.state.message}
      />
    );
  }
}

export default AWSFlashBarContainer;
