import React, { Component } from 'react';
import { Flashbar } from '@amzn/awsui-components-react';

class AWSFlashBar extends Component {
  state = {
    items: []
  };

  getFlashbarType = (level) => {
    switch (level) {
      case 1:
        return 'info';
      case 2:
        return 'warning';
      case 3:
        return 'error';
      default:
        return 'info';
    }
  };

  getItems = (level, message) => {
    return [
      {
        type: this.getFlashbarType(level),
        dismissible: true,
        onDismiss: () => {
          this.setState({ items: [] })
        },
        content: message
      }
    ];
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.level !== this.props.level ||
      prevProps.message !== this.props.message
    ) {
      this.setState({
        items: this.getItems(this.props.level, this.props.message)
      });
    }
  }

  render() {
    if (this.props.level > 0 && this.props.message) {
      return (
        <Flashbar id='site-status-flashbar' items={this.state.items} />
      );
    } else {
      return null;
    }
  }
}

export default AWSFlashBar;
