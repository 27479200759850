import React, { Component } from 'react';
import './AWSButtonImg.scss'
// import { Icon } from '@shopify/polaris';
// import {
//     PlusMinor, RefreshMinor, NoteMajorMonotone, ArrowUpMinor, ArrowDownMinor
// } from '@shopify/polaris-icons';

// const IconMatch = [
//     {
//         key: "PlusMinor",
//         value: PlusMinor
//     },
//     {
//         key: "RefreshMinor",
//         value: RefreshMinor
//     },
//     ,
//     {
//         key: "NoteMajorMonotone",
//         value: NoteMajorMonotone
//     },
//     ,
//     {
//         key: "ArrowUpMinor",
//         value: ArrowUpMinor
//     },
//     ,
//     {
//         key: "ArrowDownMinor",
//         value: ArrowDownMinor
//     }
// ]

export default class AWSButtonImg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errorLabel: "",
            error: false
        }

    }

    componentDidMount() {
        if (this.props.value !== null) {
            this.props.handleOnSelect(this.props.value);
        } else {
            this.props.handleOnSelect(this.props.defaultValue);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.props.handleOnSelect(this.props.value)
        }
    }

    toggleTick(value) {
        const { quePermission, canEditPPR } = this.props;

        if (canEditPPR === true) {
            if (quePermission && quePermission.read === false) {
                this.setState({
                    value,
                    error: false
                });
                this.props.handleOnSelect(value);
            } else if (quePermission === undefined) {
                this.setState({ value, error: false });
                this.props.handleOnSelect(value);
            }
        }
    }
    
    checkForDisable = () => {
        const { quePermission, canEditPPR } = this.props;

        if (canEditPPR === true) {
            return quePermission && quePermission.read ? quePermission.read : false
        } else if (canEditPPR === false) {
            return true // disable true
        } else {
            return false
        }
    }

    render() {
        return (
            <div className="button-img-wraper">
                <ul className={`p-0 ${this.checkForDisable() === true ? 'disable-state':''}`}>
                    {
                        this.props.options && this.props.options.map(({ optionKey, optionValue, IconSource }) => {

                            return (
                                <li className={this.props.value === optionKey ? "active" : ""} onClick={() => this.toggleTick(optionValue)} key={optionKey}>
                                    {/* <span className={this.props.value === optionKey ? "white mr-2" : "dark-black mr-2"}>
                                        {
                                            IconMatch.map((icon, index) => {
                                                return icon.key === IconSource ? null : <></>
                                            })
                                        }
                                    </span> */}
                                    <span>{optionValue}</span>

                                </li>
                            );
                        })
                    }

                </ul>
            </div>
        )
    }

}
