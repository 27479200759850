import React, { Component, PureComponent } from "react";
import './PageNotFound.scss';
import clousdIllustrate from '../../images/cloud_illustration.svg';
export default class ServerError extends PureComponent {
    render() {
        return (
            <div className="col-12 text-center page-notfound pt-4">
                <h1 className="amber-bold pb-4 orange font-size30 pt-5">{this.props.statusCode ? this.props.statusCode : "500"} </h1>
                <p className="font-size16 pb-4 dark-black pt-3">Something went wrong</p>
                <img src={clousdIllustrate} alt="cloud"/>
                {/* <p className="font-size18">The page which you are looking for is not available</p> */}
                <p className="link-txt cursor-pointer font-size16 pt-5 pb-2" onClick={() => this.props.onRefreshClick()}>Please Click To Refresh </p>
                <p className="link-txt cursor-pointer font-size16 pt-3" onClick={() => this.props.onDashboardClick()}>Click here to navigate to Dashboard</p>
            </div>
        )
    }
}
