import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWSbutton.scss";
import arrowWhite from '../../../images/arrow_white.svg';
import arrowOrange from '../../../images/arrow.svg';
import { Button } from "@amzn/awsui-components-react";

export default class AWSbutton extends Component {
    render() {
        const { label, btnType, isIcon } = this.props;
        return (
            <div className={btnType === "fill" ? "btns fill-btn" : "btns unfill-btn"}>
                <Button
                    ariaExpanded={false}
                    ariaPressed={false}
                    destructive={false}
                    type="button"
                    disabled={this.props.disabled ? this.props.disabled: false}
                    size="slim"
                    onClick={this.props.onClick}>
                    {label}
                    {
                        isIcon &&
                        <img
                            className={btnType === "fill" ? "rightarrow" : "leftarrow"}
                            src={btnType === "fill" ? arrowWhite : arrowOrange} alt="arrow" />
                    }
                </Button>
            </div>
        );
    }
}

AWSbutton.propTypes = {
    btnType: PropTypes.string,
    label: PropTypes.any,
    onClick: PropTypes.func,
    isIcon: PropTypes.bool,
    disabled: PropTypes.bool
};
