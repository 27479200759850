import React, { Component } from "react";
import AWSheader from "../common/AWSheader/AWSheader";
import Leftnavigation from "../common/LeftNav/Leftnavigation";
import Controls from "./Controls";
// import CommentSection from "./CommentSection";
import AWSbutton from "../common/AWSbutton/AWSbutton";
import _ from 'underscore';
import axios from 'axios';
import DynamicRenderComponent from "./DynamicRendercomponent";
import Col from '../common/AWSCol/AWSCol';
import './Termination.scss';
import AWSConfig from '../../config/AWSconfig';
import { getTokenBasedOnRole } from '../../utils/common';
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import * as roleNames from '../../constants/roleNames.const';
import * as dealStatusConstant from '../../constants/dealStatusConstant';
import { emailNotification, getPPRCreatedById } from "../../services/endPoints";
import * as tagNameConstant from '../../constants/tagNameConstant';
import { cloneDeep } from 'lodash';
import VideoIcon from '../common/Icon/VideoIcon/VideoIcon';
let prevAutoPopulateAccountData = []
const config = new AWSConfig();
let baseUrl;
let baseUrlForUser = `${config.userManagementbaseUrl}`;
export default class Termination extends Component {
    state = {
        isExpand: false,
        commentCollabState: [],
        summaryData: {},
        termEndDate: "",
        termLength: "",
        nothing: "",
        stage: 0,
        date: "",
        visible: false,
        payerId: "",
        triggeredNext: false,
        triggerCommentValidation: false,
        terminationPage: true,
        dataModel: [],
        sMDataModel: [],
        disableSubmitButton: true,
        pprName: null,
        dealAction: [],
        dealInfo: [],
        loadingError: false,
        activateToast: false,
        cloudFrontSalesManager: null,
        loadingMessageDuration: undefined,
        loadingMessage: "",
        summaryComment: null,
        accountManagerName: null,
        accountName: null,
        dynamicSalesManagerOptions: [],
        autoPopulateAccountData: [],
        summaryDataUpdated: false,
        updatedCustomerLegalName: '',
        terminationSummaryData: {
            cus_legal_name: null,
            account_name: null,
            cldf_sm: null,
            effective_detail: null,
            termination_reason: null,
            start_date_agreement: null,
            customer_commit_fulfilled: null,
            commit_missed: null,
            actual_: null,
            commit_missed_detail: null
        }
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        baseUrl = config.amazonFalconbaseUrl
    }

    componentDidMount() {
        this.onComponentRendered()
    }

    onComponentRendered = (headerData, type) => {
        let url;
        let cloneData = null;
        let userLoginRole = this.props.location.state && this.props.location.state.userRole;
        let loginAlias = this.props.location.state && this.props.location.state.alias

        if (this.props.location.state && this.props.location.state.userRole) {
            if (type === "audit") {
                url = config.amazonFalconbaseUrl + "cfppr/audit";
                // pprFormType = type;
                cloneData = headerData
                this.setState({
                    // stage: 0
                })
            } else {
                if (this.props.match.params.pprId) {
                    let userRoleAlias = this.props.location.state && this.props.location.state.userRoleAlias;
                    if(userRoleAlias){
                        url = config.amazonFalconbaseUrl + "cfppr/" + "ppr" + "?pprId=" + this.props.match.params.pprId + "&AWSAlias=" + userRoleAlias + "&userRole=" + roleNames.SALES_REPRESENTATIVE + "&loginRole=" + userLoginRole + "&loginAlias=" + loginAlias;   
                    }else{
                        url = "";
                        this.getPprCreatedId(this.props.match.params.pprId);
                    }
                } else {
                    let userAlias = this.props.location.state && this.props.location.state.alias;
                    url = `${config.amazonFalconbaseUrl}cfppr/terminate` + "?AWSAlias=" + userAlias
                }
            }
            if(url !== ''){
                axios({
                    method: (type === 'audit') ? "POST" : "GET",
                    url: url,
                    data: cloneData,
                    headers: {
                        'Authorization': localStorage.getItem('cfat'),
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                })
                    .then(resp => {
                        // call api to get sales manager option dynamically
                        this.setState({
                            dataModel: resp.data.pageStages,
                            sMDataModel: resp.data.pageStages,
                            pprName: resp.data.dealInfo.pprName,
                            ownerType: resp.data.dealInfo.ownerType,
                            dealAction: resp.data.dealInfo.dealAction,
                            dealInfo: resp.data.dealInfo,
                            userRoleInfo: resp.data.userRole,
                            firstname: this.props.location.state && this.props.location.state.firstName,
                            lastname: this.props.location.state && this.props.location.state.lastName,
                            canEditPPR: resp.data.canEditPPR
                        }, () => {
                            let sMDataModelClone = JSON.parse(JSON.stringify(this.state.dataModel));
                            this.setState({
                                sMDataModel: sMDataModelClone
                            })
                            this.salesManagerOptions(resp.data.dealInfo);
                        })
                    })
                    .catch(error => {
                        this.setState({
                            activateToast:true,
                            loadingMessage: "Server error",
                            loadingError: true
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    activateToast: false,
                                })
                            }, 6000)
                        });
                    })
            }
        } else {
            localStorage.clear();
            this.props.history.push("/");
        }
    }

    getPprCreatedId = (pprId) => {
        const pprID = Number(pprId.split('-')[1]);
        const postData = { pprId: pprID };
        getPPRCreatedById(postData)
            .then(resp => {
                if (resp.data.userId && resp.data.userId !== '') {
                    let locationState = this.props.location && this.props.location.state;
                    locationState.userRoleAlias = resp.data.userId;
                    this.onComponentRendered();
                } else {
                    this.callDashboardApi(this.props.location && this.props.location.state);
                }
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status === 404)) {
                    this.callDashboardApi(this.props.location && this.props.location.state);
                } else {
                    this.setState({
                        activateToast: true,
                        loadingMessage: "Server error",
                        loadingError: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                activateToast: false,
                            })
                        }, 6000)
                    });
                }
            });
    }

    handleOnSelect = (value, question, questionBlockIndex, rowId, id) => {

        this.updateSummaryData(value, question)

        if (question.queType === "TABLE") {
            question.tableObj.rows[rowId].cells[id].value = value;
            question.tableObj.rows[rowId].sequence = rowId;
        } else if (question.queType === "FORMGROUP") {
            question.formGroup.rows[rowId].cells[id].value = value;
        } else {
            question.value = value;
            question.answer.answerValue = value;
            question.answer.questionId = question.sF_Id
        }

        if (question.questionLabel === "Term Length") {
            this.setState({
                termLength: value
            })
        }

        if (question && question.tagName === tagNameConstant.cloudFrontSalesManagerTag) {
            this.setState({
                cloudFrontSalesManager: value
            })
        }
        this.setState({
            nothing: ""
        });

    }

    handleChange = (value, question, questionBlockIndex, rowId, id) => {
        this.setState({
            triggeredNext: false
        })
        // if (question && question.tagName === "account_name") {
        //     this.state.terminationSummaryData["cus_legal_name"] = question.answer.answerValue
        // }
        this.updateSummaryData(value, question)

        if (question.tagName === tagNameConstant.accountManagerNameTag) {
            this.setState({
                accountManagerName: value
            })
        }

        if (question.tagName === tagNameConstant.accountNameTag) {
            this.setState({
                accountName: value
            })
        }

        if (question && question.tagName === tagNameConstant.cloudFrontSalesManagerTag) {
            this.setState({
                cloudFrontSalesManager: value
            })
        }

        if (question.queType === "FORMGROUP") {
            question.formGroup.rows[rowId].cells[id].value = value;
        } else if (question.queType === "TABLE") {
            question.tableObj.rows[rowId].cells[id].value = value;
        } else {


            if (question.questionLabel === "Discount Term Effective Date") {
                let jan312009 = new Date(value);
                this.setState({
                    date: jan312009
                })
            }
            question.answer.answerValue = value;
            question.answer.questionId = question.sF_Id
        }

        this.setState({
            nothing: ""
        });
    }

    updateSummaryData = (value, question) => {
        let { terminationSummaryData } = this.state;
        let newPayerKey = Object.keys(this.state.terminationSummaryData);
        newPayerKey.forEach((keyPair) => {
            if (question && question.tagName) {
                if (keyPair === question.tagName) {
                    terminationSummaryData[keyPair] = value && value.toString()
                }
                this.setState({
                    terminationSummaryData
                }, () => {

                })

            }

        })


    }

    /**
     * this function will stop navigating on next/submit click if form has mandatory field empty
     */
    validation = (stageLength, stage, data, handleSubmitFromTerminationForm) => {

        if (document.getElementsByClassName('has-error').length > 0) {
            setTimeout(() => {
                document.getElementsByClassName("main-container")[0].scrollTo(0, 0)
            }, 0)
            this.setState({
                triggeredNext: false,
                disableSubmitButton: true,
                isExpand: false
            })
        } else {
            if (this.state.isExpand === true) {
                this.handleSubmitButtonFromSummary(handleSubmitFromTerminationForm)
            } else {
                if (stageLength === (stage + 1)) {
                    this.setState({
                        isExpand: true,
                        disableSubmitButton: false
                    })
                }
            }

        }
    }

    /**
     * this function will check for empty field of form and redirect
     */
    handleSubmitFromTerminationForm = (handleSubmitFromTerminationForm) => {
        this.setState({
            triggeredNext: true
        }, () => {
            setTimeout(() => {
                this.validation(this.state.dataModel.length, this.state.stage, this.state.dataModel, handleSubmitFromTerminationForm);
            }, 10)
        })
    }

    /**
     * this function will set json for termination page and call api to submit termination ppr
     */
    handleSubmitButtonFromSummary = (submitClickDealAction) => {
        let { dataModel, dealInfo, accountName, accountManagerName } = this.state;

        if (this.state.dealInfo.salesRepName === undefined || this.state.dealInfo.salesRepName === null) {
            this.state.dealInfo.salesRepName = this.props.location.state.firstName + '  ' + this.props.location.state.lastName;
        }

        this.state.dealInfo.lastModifiedDate = null;
        this.state.dealInfo.dealStatus = submitClickDealAction.sucStatus;
        this.state.dealInfo.customerLegalName = this.state.customerLegalName;

        if (submitClickDealAction.sucCheckOut === roleNames.SALES_REPRESENTATIVE) {
            this.state.dealInfo.ownerId = this.state.dealInfo.createdById;
        } else if (submitClickDealAction.sucCheckOut === roleNames.SALES_MANAGER) {
            this.state.dealInfo.ownerId = this.state.cloudFrontSalesManager;
        } else {
            this.state.dealInfo.ownerId = submitClickDealAction.sucCheckOut;
        }
        this.state.dealInfo.ownerType = submitClickDealAction.sucCheckOut;
        this.state.dealInfo.lastModifiedDate = new Date().toISOString();
        this.state.dealInfo.dealIdentifier = this.state.dealIdentifier;

        if (this.state.dealInfo && this.state.dealInfo.createdDate === null) {
            this.state.dealInfo.createdDate = new Date().toISOString();
        }

        if (this.state.dealInfo.createdById === null) {
            this.state.dealInfo.createdById = this.props.location.state.alias;
        }

        let updatedUserRoleInfo;

        if (this.state.userRoleInfo === null || this.state.userRoleInfo === undefined) {
            updatedUserRoleInfo = this.props.location.state.userRole;
        } else {
            updatedUserRoleInfo = this.state.userRoleInfo;
        }

        let summaryCommentArrayObject = {
            summaryUserRole: this.props.location.state && this.props.location.state.userRole,
            commentText: this.state.summaryComment,
            timestamp: new Date().toISOString(),
            commentedBy: this.props.location.state.firstName + '  ' + this.props.location.state.lastName,
            action: submitClickDealAction.sucActionLabel
        }

        if (this.state.summaryComment !== "" && this.state.summaryComment !== null) {
            if (this.state.dealInfo.summaryComment.commentText === null) {
                this.state.dealInfo.summaryComment.commentText = [summaryCommentArrayObject]
            } else if (this.state.dealInfo.summaryComment.commentText.length) {
                this.state.dealInfo.summaryComment.commentText.push(summaryCommentArrayObject)
            }
        } else {
            if (submitClickDealAction.isMandatory === true) {
                this.setState({
                    triggerCommentValidation: true
                })
                return;
            }
        }

        if (submitClickDealAction.isMandatory !== true) {

            if (this.state.summaryComment === "" || this.state.summaryComment === null) {
                let summaryCommentArrayObjectForEmpty = {
                    summaryUserRole: this.props.location.state && this.props.location.state.userRole,
                    commentText: "No Comments Added",
                    timestamp: new Date().toISOString(),
                    commentedBy: this.props.location.state.firstName + '  ' + this.props.location.state.lastName,
                    action: submitClickDealAction.sucActionLabel
                }

                if (dealInfo.summaryComment.commentText === null) {
                    dealInfo.summaryComment.commentText = [summaryCommentArrayObjectForEmpty]
                } else if (dealInfo.summaryComment.commentText.length) {
                    dealInfo.summaryComment.commentText.push(summaryCommentArrayObjectForEmpty)
                }
            }
        }

        let terminatePprForm = {
            AWSAlias: this.state.dealInfo.createdById,
            userRole: updatedUserRoleInfo,
            loginAlias: this.props.location.state && this.props.location.state.alias,
            loginRole: this.props.location.state && this.props.location.state.userRole,
            pageStages: dataModel,
            dealInfo: this.state.dealInfo
        }
        this.setState({
            activateToast: true,
            loadingMessage: "Please wait while we process your request...",
            loadingError: false
        })
        axios({
            method: "POST",
            url: `${baseUrl}cfppr/ppr`,
            data: terminatePprForm
        })
            .then(resp => {

                const emailForm = {
                    dealStatus: resp.data.data.dealInfo.dealStatus,
                    createdById: dealInfo.createdById,
                    type: resp.data.data.dealInfo.pprType,
                    userRole: this.props.location.state && this.props.location.state.userRole,
                    accountManager: accountManagerName,
                    accountName: accountName,
                    ownerId: dealInfo.ownerId,
                    AWSAlias: this.props.location.state && this.props.location.state.alias,
                    pprId: dealInfo.pprName
                }

                // axios call to send notification
                const emailUrl = "email/sendMessage"
                emailNotification(emailUrl, emailForm).catch(err => console.log(err));

                this.setState({
                    activateToast: true,
                    loadingMessage: "PPR Saved successfully",
                    loadingError: false,
                    loadingMessageDuration: 3000
                })
                let location = {
                    pathname: "/dashboard",
                    state: this.props.location.state
                }
                this.props.history.push(location);
            }).catch(error => {
                // this.state.dealInfo.summaryComment.commentText.pop()
                this.setState({
                    activateToast: true,
                    loadingMessage: "Failed to create PPR",
                    loadingError: true,
                    loadingMessageDuration: 3000
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            activateToast: false
                        })
                    }, 3000)
                });
            });
    }

    /**
     * this function will post data to backend as an autosave feature with loader and navigate to dashboard
     */
    handlePPRCreatedForAutoSaveOnClick = (locationState) => {
        let { dealInfo } = this.state;
        if (dealInfo.dealStatus === dealStatusConstant.PPR_Created || dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input || dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM) {
            let { dataModel } = this.state;

            let newDataModel = dataModel;

            this.state.dealInfo.dealStatus = dealStatusConstant.PPR_Created;
            this.state.dealInfo.customerLegalName = this.state.customerLegalName;
            this.state.dealInfo.dealType = "Termination";
            this.state.dealInfo.lastModifiedDate = new Date().toISOString();

            if (this.state.dealInfo && this.state.dealInfo.createdDate === null) {
                this.state.dealInfo.createdDate = new Date().toISOString();
            }

            if (this.state.dealInfo.createdById === null || this.state.dealInfo.createdById === undefined) {
                this.state.dealInfo.createdById = this.props.location.state.alias;
            }

            this.state.dealInfo.ownerId = this.props.location.state.alias;

            let terminatePprForm = {
                loginAlias: this.props.location.state && this.props.location.state.alias,
                loginRole: this.props.location.state && this.props.location.state.userRole,
                AWSAlias: this.props.location.state.alias,
                userRole: this.props.location.state.userRole,
                pageStages: newDataModel,
                dealInfo: this.state.dealInfo,
            }

            this.setState({
                activateToast: true,
                loadingMessage: "Please wait while we process your request...",
                loadingError: false
            })
            axios({
                method: "POST",
                url: `${baseUrl}cfppr/ppr`,
                data: terminatePprForm
            })
                .then(resp => {
                    this.setState({
                        activateToast: true,
                        loadingMessage: "PPR Saved successfully",
                        loadingError: false,
                        loadingMessageDuration: 3000
                    })
                    this.callDashboardApi(locationState)
                }).catch(() => {
                    this.setState({
                        activateToast: true,
                        loadingMessage: "Failed to save PPR",
                        loadingError: true,
                        loadingMessageDuration: 3000
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                activateToast: false
                            })
                        }, 100)
                    }
                    )
                })
        } else {
            this.callDashboardApi(locationState)
        }
    }

    /**
     * This function will call dashboard api
     */
    callDashboardApi = (locationState) => {
        let location = {
            pathname: "/dashboard",
            state: locationState
        }
        this.props.history.push(location);
    }

    disableSubmitButtonFlag = () => {
        if (this.state.isExpand === false) {
            this.setState({
                disableSubmitButton: true
            })
        }
    }

    /**
     * this function will call api to get sales manager alias dropdown options
     */
    salesManagerOptions = (dealInfo) => {
        let value = [];
        let userRoleUrl = baseUrlForUser + "users/getuserRoles";
        const awsAlias = (dealInfo && dealInfo.createdById ) ? dealInfo.createdById : this.props.location.state && this.props.location.state.alias;

        let requestData = {
            userRole: roleNames.SALES_MANAGER,
            AWSAlias: awsAlias,
            action: "dealIntakeForm"
        };

        axios({
            method: "POST",
            data: requestData,
            url: userRoleUrl
        }).then(resp => {
            resp.data.Data.forEach((salesManagerData) => {
                value.push({
                    optionKey: salesManagerData.AWSAlias,
                    optionValue: salesManagerData.FirstName + ' ' + salesManagerData.LastName + ' (' + salesManagerData.AWSAlias + ')',
                    defaultValue: null,
                });
            })

            this.setState({
                dynamicSalesManagerOptions: value
            })
        }).catch(err => console.log(err));
    }

    /**
     * this function will hide/show summar page based on isExpand state
     */
    handleExpand = () => {
        this.setState({
            isExpand: !this.state.isExpand
        }, () => {
            this.disableSubmitButtonFlag()
        })
    }

    updateUserRole = (secondaryRole, activeRole) =>  getTokenBasedOnRole(secondaryRole, activeRole, this.props.location.state, this.props.history);

    /**
     * this function will set value of comment with empty value validation
     */
    handleSummaryComment = (value) => {
        if (value === "") {
            this.setState({
                summaryComment: null,
                triggerCommentValidation: false
            })
        } else {
            this.setState({
                summaryComment: value,
                triggerCommentValidation: false
            })
        }
    }

    /**
     * this function will redirect to dashboard on clicking of cancel button
     */
    handleCancelButtonClick = () => {
        let location = {
            pathname: "/dashboard",
            state: this.props.location.state
        }
        this.props.history.push(location);
    }

    /**
     * This function is common to set state of loader and loading message
     */
    showDealStructureLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        })
    }
    /**
  * This function used update data for particular selection of auto populate dropdown of question
  * based on tag name it update content dynamically dynamically
  * @param {*} value typed or selected option which need to update in answer object of question
  * @param {*} question in which answer object need to update
  * @param {*} rowId of table when any table data is inserted to make change in particular row
  * @param {*} id cell id of table when any table data is inserted to make change in particular cell
  */
    onDropdownSelection = (value, question, queBlockIndex, rowId, cellIndex) => {
        if (question.tagName === tagNameConstant.accountNameTag) {
            this.handleChange(value, question, queBlockIndex, rowId, cellIndex);
            this.getDataFromAccountNameOrAccountId(value, 'sfdc_account_name');
        }
    };

    getDataFromAccountNameOrAccountId = (val, type) => {
        const url = `${config.awsFalconSearchUrl}search`;
        const postData = {
            query: {
                match_phrase_prefix: {
                    [type]: val,
                },
            }
        };
        axios({
            method: 'POST',
            url: url,
            data: postData
        }).then(resp => {
            this.setState({
                autoPopulateAccountData: resp.data
            }, () => {
                if (resp.data && resp.data.length) {
                    this.setAccountData(resp.data)
                }
            })
        }).catch((e) => {
            console.warn('Auto completed selection warning', e);
        });
    }

    /**
     * this function will set data for payer ID's from the response
     */
    setAccountData = (data) => {
        if (data.length) {
            prevAutoPopulateAccountData = data
        }

        const modal = cloneDeep(this.state.dataModel);
        let abort = false;
        for (const level1 of modal) {
            for (const level2 of level1.queBlock) {
                if (level2) {
                    for (const level3 of level2.rows) {
                        for (const level4 of level3.questions) {
                            if (level4.tagName === tagNameConstant.accountNameTag) {
                                level4.value = data[0].sfdc_account_name;
                                level4.answer.answerValue = data[0].sfdc_account_name;
                            } else if (level4.tagName === tagNameConstant.customerLegalNameTag) {
                                level4.value = data[0].legalname;
                                level4.answer.answerValue = data[0].legalname;
                                this.updateSummaryData(data[0].legalname, level4)
                            }
                        }
                        if (abort) { break; }
                    }
                }
                if (abort) { break; }
            }
            if (abort) { break; }
        }
        this.setState({ ...this.state, dataModel: modal });
    };

    commentObjectArrayFromCollabsTab = (commentObject) => {
        let { commentCollabState,dealInfo } = this.state;
        let newcommentArray = [...commentCollabState, ...[commentObject]]
        this.setState({
            commentCollabState: newcommentArray
        })
        const dealInfoCommentObj = {
            summaryUserRole: this.props.location.state && this.props.location.state.userRole,
            commentText: commentObject.addCommentTxt,
            timestamp: new Date().toISOString(),
            commentedBy: this.props.location.state.firstName + '  ' + this.props.location.state.lastName,
            action: "comment"  
        }
        if (dealInfo.summaryComment.commentText === null) {
            dealInfo.summaryComment.commentText = [dealInfoCommentObj]
        } else if (dealInfo.summaryComment.commentText.length) {
            dealInfo.summaryComment.commentText.push(dealInfoCommentObj)
        }
    }

    renderLeftNavigation = () => {
        let navMenu = this.state.dataModel;
        let { dealInfo, canEditPPR } = this.state;
        let salesRepName = null;
        if (this.props.location.state && this.props.location.state.userRole) {
            if (dealInfo.salesRepName && dealInfo.salesRepName !== null) {
                salesRepName = dealInfo.salesRepName;
            } else {
                salesRepName = this.props.location.state.firstName + '  ' + this.props.location.state.lastName;
            }
            return (
                <Leftnavigation
                    navMenu={navMenu}
                    canEditPPR={canEditPPR}
                    isForm={false}
                    stage={this.state.stage}
                    salesRepName={salesRepName}
                    ownerType={this.state.ownerType}
                    userRole={this.props.location.state && this.props.location.state.userRole}
                    terminationSummaryData={this.state.terminationSummaryData}
                    summarCommentArrayObject={dealInfo && dealInfo.summaryComment}
                    disableSubmitButton={this.state.disableSubmitButton}
                    isExpand={this.state.isExpand}
                    dealAction={this.state.dealAction}
                    summaryData={this.state.summaryData}
                    terminationPage={true}
                    summaryComment={this.state.summaryComment}
                    triggerCommentValidation={this.state.triggerCommentValidation}
                    handleOnClick={() => this.handleExpand()}
                    handleSummaryComment={(value) => this.handleSummaryComment(value)}
                    handleSubmit={(dealActionValue) => { this.handleSubmitFromTerminationForm(dealActionValue) }}
                    showDealStructureLoadingToast={(message, activeState) => this.showDealStructureLoadingToast(message, activeState)}
                    pprId={this.state.pprName}
                    dealStatus={dealInfo.dealStatus}
                    createdById={dealInfo && dealInfo.createdById}
                    commentObjectArrayFromCollabsTab={(commentObj) => {this.commentObjectArrayFromCollabsTab(commentObj)}}
                    commentArrayFromCollabTabs={this.state.commentCollabState}
                    locationStateUser={this.props.location.state}
                />
            )
        } else {
            localStorage.clear();
            this.props.history.push("/");
        }
    }
    showVidePopup = () => {
        let pageheadeName = this.state.dataModel[this.state.stage].stageName
        
        if(pageheadeName === "Terminate PPR"){
            window.open("https://broadcast.amazon.com/videos/192407", '_blank')
        }
    
    }
    render() {
        let locationState = this.props.location && this.props.location.state;
        let { dealInfo } = this.state;
        return (
            <div className="termination-wraper">
                {this.state.activateToast && (
                    <AWSLoadingToast
                        message={this.state.loadingMessage}
                        error={this.state.loadingError}
                        duration={this.state.loadingMessageDuration}
                    />
                )}
                <AWSheader
                    handlePPRCreatedForAutoSave={(locationState) => this.handlePPRCreatedForAutoSaveOnClick(locationState)}
                    locationState={locationState}
                    firstName={this.state.firstname}
                    lastName={this.state.lastname}
                    updateUserRole={(secondaryRole, activeRole) => {this.updateUserRole(secondaryRole, activeRole)}}
                    goHomeConfirmationPopUp={(this.props.location.state.userRole === roleNames.SALES_REPRESENTATIVE && this.state.canEditPPR) ? true : false}
                    history={this.props.history}
                />
                <div className={`${this.state.isExpand ? 'overflow-hidden-y' : 'overflow-auto-y'} main-container bg-grey pt-3 scroll-width5 moz-scroll`} ref={this.myRef}>
                    <div className={this.state.isExpand ? "overlay-dashboard display-block" : "overlay-dashboard display-none"}></div>
                    <Controls
                        createdById={dealInfo && dealInfo.createdById}

                        locationState={locationState}
                        pprId={this.state.pprName}
                        termination={true}
                        onComponentRendered={(headerData, type) => {console.log("need to work on")}}
                    />
                    <div className="pt-1">
                        <Col span={6}>
                            {this.renderLeftNavigation()}
                        </Col>

                        <Col span={18}>

                            {this.state.dataModel.length > 0 &&
                                <div className="cust-info-wraper pl-5 pr-5 pt-0">
                                    <Col span={24}>
                                        {
                                            this.state.dataModel[this.state.stage].queBlock.map((dataValue, questionBlockIndex) => {

                                                if (dataValue.isVisible) {
                                                    return (
                                                        <div className="content-wraper pt-0 pb-4 full-width" key={`question_block_${questionBlockIndex}`}>
                                                            <span className="float-right col-1 p-0 mt-4">
                                                                {/* <CommentSection infoDescription={dataValue.infoText} /> */}
                                                            </span>
                                                            {dataValue.queBlkHeader &&
                                                                <h6 className="mt-4 col-11 p-0 float-left pb-4">
                                                                    {dataValue.queBlkHeader} <span className="youtube-icon termination-youtube" onClick={() => { this.showVidePopup() }}>
                                                                        {/* <img src={videoIcon} alt="video" /> */}
                                                                        <VideoIcon/>
                                                                        <span className="termination-tooltip">Terminate PPR</span></span>
                                                                    
                                                                </h6>}

                                                            {/* <p className="font-size14 float-left">
                                                                    {dataValue.description}
                                                                </p> */}

                                                            {dataValue.rows && dataValue.rows.map((rowValue, index) => {
                                                                return (
                                                                    <div className="full-width" key={`rows_${index}`}>

                                                                        {rowValue.questions && rowValue.questions.map((questionValue, index) => {
                                                                            return (
                                                                                <DynamicRenderComponent
                                                                                    canEditPPR={this.state.canEditPPR}
                                                                                    userRole={this.props.location.state && this.props.location.state.userRole}
                                                                                    questionValue={questionValue}
                                                                                    dynamicSalesManagerOptions={this.state.dynamicSalesManagerOptions}
                                                                                    key={index}
                                                                                    index={index}
                                                                                    questionBlockIndex={questionBlockIndex}
                                                                                    triggeredNext={this.state.triggeredNext}
                                                                                    dataModel={this.state.dataModel}
                                                                                    stage={this.state.stage}
                                                                                    onChangeAdditionalApproval={() => { }}
                                                                                    handleDeleteTableRow={() => { }}
                                                                                    handleBulkDeleteTableRow={() => { }}
                                                                                    duplicateValidation={false}
                                                                                    pprId={this.state.pprName}
                                                                                    onDropdownSelection={this.onDropdownSelection}
                                                                                    handleChange={
                                                                                        (value, question, questionBlockIndex, rowId, id) => {
                                                                                            this.handleChange(value, question, questionBlockIndex, rowId, id)
                                                                                        }
                                                                                    }
                                                                                    handleOnSelect={
                                                                                        (value, question, questionBlockIndex, rowId, id) => {
                                                                                            this.handleOnSelect(value, question, questionBlockIndex, rowId, id)
                                                                                        }
                                                                                    }
                                                                                />)
                                                                        })}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }

                                            })
                                        }

                                        <div className="mb-3 mt-4 full-width">
                                            <Col span={3} className="float-left width-auto">
                                                <AWSbutton
                                                    btnType="btns unfill-button"
                                                    label="Cancel"
                                                    isIcon={false}
                                                    onClick={this.handleCancelButtonClick}
                                                />
                                            </Col>
                                            <div className="float-right width-auto">
                                                <AWSbutton
                                                    btnType="fill"
                                                    label="Next"
                                                    isIcon={false}
                                                    onClick={this.handleSubmitFromTerminationForm}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                </div>
                            }
                        </Col>
                    </div>
                </div>
            </div>
        );
    }
}
