import React from "react";
import PropTypes from "prop-types";
import * as dashboardConstant from '../../../constants/dashboardConstants';
import "../../Dashboard/AWSDashboard.scss";
import { Icon } from "@amzn/awsui-components-react";
export default class AWSTableOpenReviewIcon extends React.Component {
  render() {
    let { dealStatus, dealType, createdById, userAlias } = this.props;
    if (
      dealStatus &&
      dealStatus !== dashboardConstant.OPEN_FOR_REVIEW &&
      dealType !== dashboardConstant.TERMINATION &&
      createdById === userAlias
    ) {
      return (
        <div className="text-center table-close-icon mt-0 pl-2 ml-1 float-left cursor-pointer">
            <span><Icon name="unlocked" className="cursor-pointer"></Icon></span>
        </div>
      );
    } else {
      return (
        <div className="text-center table-grey-close-icon mt-0 pl-2 ml-1 float-left">
          <Icon name="unlocked"></Icon>
        </div>
      );
    }
  }
}

AWSTableOpenReviewIcon.propTypes = {
  dealStatus: PropTypes.string
};
