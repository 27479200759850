/**
 * State Mapper for Dashboard Data
 * createStateForDashboardData(): dashboardData
    dashboardData: tileData
    tablepopOver: columnsData
    dashboardColumnOptions: allColumns
    createStateForTableDataForDashboard: tableData
        submittedList: submitPPr,
        pendingSMList: thirdTab,
        pendingExecList: pendingExecTab,
        pendingCusTermsList: cusTermsTab,
        openForReviewList: openReviewTab,
        pendingLegalList: pendingLegalTab 
 * pprcreatedList: pprList,
 * submittedPPRLabel: pprSubmittedLabel, 
 * activateToast: false,
 * submittedPPRtab,
 * allPPRtab,
 * salesManagertab: false, 
 * sceOwnertab: false,
 * customerTermstab: false,
 * openForReviewtab: false,
 * pendingLegaltab: false,
 * allPPRTabLabel
 * @returns Object for Dashboard related state
 */
export const createStateForDashboardPage = ({ dashboardData, pprList = [], pprSubmittedLabel, submittedPPRtab, allPPRtab, allPPRTabLabel }) => {
    return {
        ...createStateForDashboardData(dashboardData),
        pprcreatedList: pprList,
        submittedPPRLabel: pprSubmittedLabel,
        submittedPPRtab,
        allPPRtab,
        allPPRTabLabel,
        activateToast: false,
        salesManagertab: false,
        sceOwnertab: false,
        customerTermstab: false,
        openForReviewtab: false,
        pendingLegaltab: false,
    }
};

export const createStateForDashboardData = ({ tileData = [], columnsData = [], allColumns = [], tableData = {} }) => {
    return {
        dashboardData: tileData,
        tablepopOver: columnsData,
        dashboardColumnOptions: allColumns,
        ...createStateForTableDataForDashboard(tableData)
    }
}

export const createStateForTableDataForDashboard = ({ submitPPr = [], thirdTab = [], pendingExecTab = [], cusTermsTab = [], openReviewTab = [], pendingLegalTab = [] }) => {
    return {
        submittedList: submitPPr,
        pendingSMList: thirdTab,
        pendingExecList: pendingExecTab,
        pendingCusTermsList: cusTermsTab,
        openForReviewList: openReviewTab,
        pendingLegalList: pendingLegalTab 
    }
}