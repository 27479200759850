import React, { Component } from 'react';
import './PPRCollapasableData.scss';
import { Icon } from "@amzn/awsui-components-react";
import AWSAdminTableDeleteIcon from "../../common/AWSAdminTableIcon/AWSAdminTableDeleteIcon";
import AWSAdminTableEditIcon from "../../common/AWSAdminTableIcon/AWSAdminTableEditIcon"
import PPRTableConstant from "../AWSAdminTable/AdminTableConstants/PPRTableConstants";
import "../AWSAdminTable/AWSTable.scss"
import AWSModal from '../AWSModal/AWSModal';
import AWSAdminTable from "../AWSAdminTable/AWSAdminTable";
import SampleJson from "../AWSAdminTable/sampleJson";
import Modal from "../../common/AWSModal/AWSModal";

class PPRCollapasableData extends Component {

    state = {
        visible: false,
        titleName: "",
        isVisibleSection: true,
        tableDataFromApi: [],
        valueChange: true,
        modalType: "",
        dataColumn: {},
        deleteModalVisible: false
    }

    componentDidMount() {
        if (this.props.questionBlock.queBlock[this.props.sectionIndex].queBlkHeader !== undefined) {
            this.setState({
                titleName: this.props.questionBlock && this.props.questionBlock.queBlock[this.props.sectionIndex].queBlkHeader,
                tableDataFromApi: this.props.questionBlock && this.props.questionBlock.queBlock[this.props.sectionIndex].questions,
                sectionBlock: this.props.questionBlock && this.props.questionBlock.queBlock[this.props.sectionIndex]
            })
        } else {
            this.setState(prevState => {
                return {
                    titleName: prevState.titleName,
                    tableDataFromApi: prevState.tableDataFromApi,
                    sectionBlock: prevState.sectionBlock
                }
            })
        }
    }

    componentWillReceiveProps() {
        let { questionBlock, sectionIndex } = this.props;
        let { tableDataFromApi, titleName } = this.state;
        let queBlock = questionBlock &&
            questionBlock.queBlock &&
            questionBlock.queBlock[sectionIndex] &&
            questionBlock.queBlock[sectionIndex]
        let questions = queBlock && queBlock.questions;
        if (queBlock && queBlock.queBlkHeader && queBlock.queBlkHeader !== titleName || this.props.sectionIndex !== sectionIndex || questions && questions.length !== tableDataFromApi.length) {
            this.setState({
                sectionBlock: queBlock,
                titleName: queBlock.queBlkHeader,
                tableDataFromApi: JSON.parse(JSON.stringify(questions)),
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.valueChange !== prevProps.valueChange || this.props.sectionIndex !== prevProps.sectionIndex || this.props.selectedStage !== prevProps.selectedStage || this.props.dataModel !== prevProps.dataModel) {
            if (this.props.questionBlock &&
                this.props.questionBlock.queBlock &&
                this.props.questionBlock.queBlock[this.props.sectionIndex] &&
                this.props.questionBlock.queBlock[this.props.sectionIndex].questions !== undefined) {
                const tableDataFromApi = JSON.parse(JSON.stringify(this.props.questionBlock.queBlock[this.props.sectionIndex].questions))
                this.setState({
                    tableDataFromApi,
                    valueChange: this.props.valueChange
                })
            } else {
                this.setState({ tableDataFromApi: [], valueChange: this.props.valueChange, visible: false });
            }
        }
    }

    showModal = (typeOfModal, dataColumn) => {
        this.setState({
            visible: true,
            modalType: typeOfModal,
            dataColumn: dataColumn
        });
    };

    handleSave = (typeOfModal, questionJson) => {
        let { modalType, titleName } = this.state;
        this.setState({
            visible: false
        });

        this.props.handleSectionChange(typeOfModal, questionJson)
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            titleName: this.props.questionBlock.queBlock[this.props.sectionIndex].queBlkHeader
        });
    };

    onEdit = (e, cell) => {

        this.showModal("editQuestionPPR", cell)
    }

    onDelete = (e, cell, index) => {
        this.setState({ ...this.state, deleteModalVisible: true, dataColumn: cell })
    }

    handleDelete = () => {
        this.setState({ ...this.state, deleteModalVisible: false });
        this.props.handleSectionChange("deleteQuestionPPR", this.state.dataColumn);
    };

    handleChangeSectionModal = (type, value) => {
        this.setState({
            [type]: value
        })
    }

    handleSectionDelete = (typeOfModal, deleteJson) => {
        this.setState({
            visible: false
        });
        this.props.handleSectionChange(typeOfModal, deleteJson)
    }

    render() {
        let { tableDataFromApi, titleName } = this.state;
        let pprHeader = [
            {
                header: "Questions",
                id: "queLabel",
                cell: item => <div>{item.queLabel}</div>,
                minWidth: '290px',
            },
            {
                header: "Field type",
                id: "queType",
                cell: item => <div>{item.queType}</div>,
                minWidth: '210px',
            },
            {
                header: "Row",
                id: "rowNum", align: "center",
                cell: item => <div>{item.rowNum}</div>,
                minWidth: '100px',
            }
            ,
            {
                header: "Column",
                id: "columnNum",
                cell: item => <div>{item.columnNum}</div>,
                minWidth: '100px',
            },
            {
                header: "Actions",
                id: "action",
                minWidth: '80px',
                cell: item => <div className="float-left col-12 p-0">
                    <div className="col-6 float-left" onClick={(e) => this.onEdit(
                        e,
                        item
                    )}>
                        <AWSAdminTableEditIcon />
                    </div>
                    <div className="col-6 float-left" onClick={(e, index) => this.onDelete(
                        e,
                        item,
                        index
                    )}>
                        <AWSAdminTableDeleteIcon />
                    </div>
                </div>,

            }
        ];


        {/* //     title: "Action", id: "action", align: "center",
        //     columns: [
        //         {
        //             title: "", align: "right",
        //             minWidth: 20,
        //             formatter: (e, cell) => {
        //                 return `<div class='text-center'><span class='mr-3 edit-icon icons-styles'></div>`
        //             },
        //             cell: (e, cell, index) => {
        //                 this.onEdit(e, cell._cell.row.data)
        //             }
        //         },
        //         {
        //             title: "", field: "", align: "", minWidth: 20,
        //             formatter: (e, cell) => {
        //                 return `<div class='text-center'><span class='delete-icon icons-styles'></div>`
        //             },
        //             cell: (e, cell, index) => {
        //                 this.onDelete(e, cell._cell.row.data, index)
        //             }
        //         },
        //     ],
        // } */}
        return (
            <div className="ppr-wraper full-width">
                <ul className="p-0 float-right sub-controls pb-4 pt-2">
                    <li className="pr-4"
                        onClick={() => this.showModal("editSection")}
                    >
                        <span className="mr-2 float-left">
                            <Icon name="edit"></Icon>
                        </span>
                        Edit section
                    </li>
                    <li className="pr-0" onClick={() => { this.showModal("addQuestionPPR") }}>
                        <span className="mr-2 float-left">
                            <Icon name="add-plus"></Icon>
                        </span>
                        New Question
                    </li>
                    {/* <li className="float-right"><span className="mr-2 float-left"><Icon source={SaveMinor} /></span>Save</li> */}
                </ul>
                {this.state.visible &&
                    <AWSModal
                        type={this.state.modalType}
                        dataModel={this.props.dataModel}
                        sectionIndex={this.props.sectionIndex}
                        selectedStage={this.props.selectedStage}
                        label="isVisible"
                        sectionBlock={this.state.sectionBlock}
                        dataColumn={this.state.dataColumn}
                        titleName={this.state.titleName}
                        isVisibleSection={this.state.isVisibleSection}
                        visible={this.state.visible}
                        handleChangeSectionModal={(type, value) => this.handleChangeSectionModal(type, value)}
                        handleOk={(typeOfModal, questionJson) => { this.handleSave(typeOfModal, questionJson) }}
                        handleDelete={(typeOfModal, questionJson) => { this.handleSectionDelete(typeOfModal, questionJson) }}
                        handleCancel={() => { this.handleCancel() }}
                        location={this.props.location}
                    />
                }
                <div className="full-width pb-2 ppr-table">
                    <AWSAdminTable
                        columns={pprHeader}
                        tableData={tableDataFromApi}
                        sortableColumnOptions={PPRTableConstant.sortableColumnOptions}
                    />
                </div>
                <Modal
                    type="deletePopup"
                    visible={this.state.deleteModalVisible}
                    handleOk={() => { this.handleDelete() }}
                    handleCancel={() => { this.setState({ ...this.state, deleteModalVisible: false }) }}
                    titleMessage="Do you want to delete the question?"
                />
            </div>
        )
    }
}
export default PPRCollapasableData;