import React, { Component } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import { Icon } from "@amzn/awsui-components-react";

import AWSbutton from "../../common/AWSbutton/AWSbutton";
import AWSInput from '../../common/AWSInput/AWSinput';
import Plusicon from "../../../images/Pluss_Icon.svg";
import AWSButtonRadio from "../../common/AWSButtonRadio/AWSButtonRadio";
import AWSConfig from '../../../config/AWSconfig';
import { getRateCardKey } from './RateCardManagement';

const config = new AWSConfig();

let validation = [{
    message: "This is a mandatory field",
    type: "Required"
}];

let questionValue = {
    isVisible: false,
    isMandatory: true
};

const RateCardType = [
    {
        optionKey: "Rate Card 1",
        optionValue: "Rate Card 1"
    },
    {
        optionKey: "Rate Card 2",
        optionValue: "Rate Card 2"
    },
    {
        optionKey: "CF + SA SMB Rate Card",
        optionValue: "CF + SA SMB Rate Card",
    },
    {
        optionKey: "CF + SA Enterprise Rate Card",
        optionValue: "CF + SA Enterprise Rate Card",
    }
];

class ConfigurationPopup extends Component {

    state = {
        triggeredNext: false,
        tmCloseIcon: false,
        rgCloseIcon: false,
        vmCloseIcon: false,
        cfCloseIcon: false,
        aosCloseIcon: false,
        TermLength: "",
        AOS: "",
        Regions: "",
        VolumeCommit: "",
        CommitFee: "",
        rateCardButtonOption: "Rate Card 1",
    }

    /**
    * This lifecycle method is calling API to get rate card configuration details
    */
    componentDidMount() {
        this.configurationApiCall("Rate Card 1")
    }

    /**
     * This function call backend api to get the ratecard configuration of in admin module
    */
    configurationApiCall = (type) => {
        const rateCardKey = getRateCardKey(type);
        let req = {
            "typeOfRC": rateCardKey
        }
        this.showUserManagementLoadingToast("Loading configuration ...", true);
        let configListApi = `${config.adminrateCardbaseUrl}getConfigRateCard`;
        axios({
            method: "POST",
            url: configListApi,
            data: req
        })
            .then(resp => {
                this.setState({
                    TermLength: resp.data.TermLength,
                    VolumeCommit: resp.data.VolumeCommit,
                    CommitFee: resp.data.CommitFee,
                    Regions: resp.data.Regions,
                    AOS: resp.data.AOS,
                    tmCloseIcon: false,
                    rgCloseIcon: false,
                    vmCloseIcon: false,
                    cfCloseIcon: false,
                    aosCloseIcon: false
                })
                this.props.setConfigurationChange(true);
                this.showUserManagementLoadingToast("Loading configuration ...", false);
            })
            .catch(() => {
                this.showUserManagementLoadingToast("Failed Loading configuration ...", false);
            })
    }

    /**
     * This function used to handle rate card button navigation
    */
    handleRateCardButtonMenu = (value) => {
        this.setState({
            rateCardButtonOption: value
        })
        this.configurationApiCall(value);
    }

    /**
     *  This function is called to show toast message based on the selection
     * @param {*} message which is a message to display 
     * @param {*} toast which is a boolean to show hide the message
    */
    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }

    /**
     * This function is used to set the local state for each varaible
     * @param {*} value value of the particular varaible to set 
     * @param {*} id element id to identify the location of the element
     * @param {*} id element name of the particular variable to set
    */
    onChangeFunc = (value, id, name) => {
        switch (name) {
            case 'TermLength':
                this.setState(prevState => ({
                    TermLength: {
                        ...prevState.TermLength,
                        values: prevState.TermLength.values.map(
                            el => (el.id === id && el.isAdded) ? { ...el, updatedValue: value, isEdited: false, isDeleted: false } : el.id === id ? { ...el, updatedValue: value, isEdited: true } : el
                        )
                    }
                }));
                break;
            case 'AOS':
                this.setState(prevState => ({
                    AOS: {
                        ...prevState.AOS,
                        values: prevState.AOS.values.map(
                            el => (el.id === id && el.isAdded) ? { ...el, updatedValue: value, isEdited: false, isDeleted: false } : el.id === id ? { ...el, updatedValue: value, isEdited: true } : el
                        )
                    }
                }));
                break;
            case 'CommitFee':
                this.setState(prevState => ({
                    CommitFee: {
                        ...prevState.CommitFee,
                        values: prevState.CommitFee.values.map(
                            el => (el.id === id && el.isAdded) ? { ...el, updatedValue: value, isEdited: false, isDeleted: false } : el.id === id ? { ...el, updatedValue: value, isEdited: true } : el
                        )
                    }
                }));
                break;
            case 'Regions':
                this.setState(prevState => ({
                    Regions: {
                        ...prevState.Regions,
                        values: prevState.Regions.values.map(
                            el => (el.id === id && el.isAdded) ? { ...el, updatedValue: value, isEdited: false, isDeleted: false } : el.id === id ? { ...el, updatedValue: value, isEdited: true } : el
                        )
                    }
                }));
                break;
            case 'VolumeCommit':
                this.setState(prevState => ({
                    VolumeCommit: {
                        ...prevState.VolumeCommit,
                        values: prevState.VolumeCommit.values.map(
                            el => (el.id === id && el.isAdded) ? { ...el, updatedValue: value, isEdited: false, isDeleted: false } : el.id === id ? { ...el, updatedValue: value, isEdited: true } : el
                        )
                    }
                }));
                break;
            default:
        }
    }

    /**
     * This function is used to set the local state for each varaible
     * @param {*} value value of the particular varaible to set 
     * @param {*} name name of the particular varaible to set 
     */
    handleOnSelect = (value, name) => {
        this.props.handleOnSelect(name, value);
    }

    /**
     * This function is called on click of the configuration changes and check for validation errors
     */
    handleOk = () => {
        this.setState({
            triggeredNext: true
        }, () => {
            this.setState({
                triggeredNext: false
            })
            setTimeout(() => {
                this.submitConfig();
            }, 100)
        })
    }

    /**
     * This function is called on submit of the configuration changes 
    */
    submitConfig = () => {
        const rateCardKey = getRateCardKey(this.state.rateCardButtonOption);
        let Submitdata = {
            "typeOfRC": rateCardKey,
            "VolumeCommit": this.state.VolumeCommit,
            "Regions": this.state.Regions,
            "CommitFee": this.state.CommitFee,
            "TermLength": this.state.TermLength,
            "AOS": this.state.AOS
        }
        this.showUserManagementLoadingToast("Saving configuration ...", true);
        let configSubmitApi = `${config.adminrateCardbaseUrl}alterConfigRateCard`;
        axios({
            method: "PUT",
            data: Submitdata,
            url: configSubmitApi
        })
            .then(resp => {
                setTimeout(() => {
                    this.showUserManagementLoadingToast("Added configuration has been saved ", true);

                }, 100)
                this.configurationApiCall(this.state.rateCardButtonOption);
            });
    }

    /**
     * This function is used enable delete icon to each configuration options
     * @param {*} type configuration type to show the close icon
    */
    showClose = (type) => {
        switch (type) {
            case "TermLength":
                var tmCloseIcon = this.state.tmCloseIcon;
                this.setState({
                    tmCloseIcon: !tmCloseIcon
                })
                break;
            case "AOS":
                var aosCloseIcon = this.state.aosCloseIcon;
                this.setState({
                    aosCloseIcon: !aosCloseIcon
                })
                break;
            case "VolumeCommit":
                var vmCloseIcon = this.state.vmCloseIcon;
                this.setState({
                    vmCloseIcon: !vmCloseIcon
                })
                break;
            case "CommitFee":
                var cfCloseIcon = this.state.cfCloseIcon;
                this.setState({
                    cfCloseIcon: !cfCloseIcon
                })
                break;
            case "Regions":
                var rgCloseIcon = this.state.rgCloseIcon;
                this.setState({
                    rgCloseIcon: !rgCloseIcon
                })
                break;
            default:
        }
    }

    /**
     * This function is used delete the configurations options
     * @param {*} name configuration name to identity the type of configuration
     * @param {*} id to identity the position of the configuration type that is deleted
    */
    hideInput = (name, id) => {
        switch (name) {
            case 'TermLength':
                this.setState(prevState => ({
                    TermLength: {
                        ...prevState.TermLength,
                        values: prevState.TermLength.values.map(
                            el => el.id === id ? { ...el, isDeleted: true } : el
                        )
                    }
                }));
                break;
            case 'AOS':
                this.setState(prevState => ({
                    AOS: {
                        ...prevState.AOS,
                        values: prevState.AOS.values.map(
                            el => el.id === id ? { ...el, isDeleted: true } : el
                        )
                    }
                }));
                break;
            case 'CommitFee':
                this.setState(prevState => ({
                    CommitFee: {
                        ...prevState.CommitFee,
                        values: prevState.CommitFee.values.map(
                            el => el.id === id ? { ...el, isDeleted: true } : el
                        )
                    }
                }));
                break;
            case 'Regions':
                this.setState(prevState => ({
                    Regions: {
                        ...prevState.Regions,
                        values: prevState.Regions.values.map(
                            el => el.id === id ? { ...el, isDeleted: true } : el
                        )
                    }
                }));
                break;
            case 'VolumeCommit':
                this.setState(prevState => ({
                    VolumeCommit: {
                        ...prevState.VolumeCommit,
                        values: prevState.VolumeCommit.values.map(
                            el => el.id === id ? { ...el, isDeleted: true } : el
                        )
                    }
                }));
                break;
            default:
        }
    }

    /**
     * This function is to add new termlength value to the existing term length values
    */
    TermLengthLogic() {
        let max = (this.state.TermLength.values.length > 0) ?
            this.state.TermLength.values.reduce((prev, current) => (prev.id > current.id) ? prev : current) : "";
        let newObject = {
            updatedValue: '',
            id: (max) ? parseInt(max.id) + 1 : 100,
            isAdded: true
        }
        let { values } = this.state.TermLength;
        values.push(newObject);
    }

    /**
     * This function is to add new average object size (AOS) value to the existing average object size (AOS) values
    */
    AOSLogic() {
        let max = (this.state.AOS.values.length > 0) ?
            this.state.AOS.values.reduce((prev, current) => (prev.id > current.id) ? prev : current) : "";
        let newObject = {
            updatedValue: '',
            id: (max) ? parseInt(max.id) + 1 : 100,
            isAdded: true
        }
        let { values } = this.state.AOS;
        values.push(newObject);
    }

    /**
     * This function is to add new volume commit value to the existing volume commit values
    */
    VolumeCommitLogic() {
        let max = (this.state.VolumeCommit.values.length > 0) ?
            this.state.VolumeCommit.values.reduce((prev, current) => (prev.id > current.id) ? prev : current) : "";
        let newObject = {
            updatedValue: '',
            id: (max) ? parseInt(max.id) + 1 : 100,
            isAdded: true
        }
        let { values } = this.state.VolumeCommit;
        values.push(newObject);
    }

    /**
     * This function is to add new commitment fee value to the existing commitment fee values
    */
    CommitFeeLogic() {
        let max = (this.state.CommitFee.values.length > 0) ?
            this.state.CommitFee.values.reduce((prev, current) => (prev.id > current.id) ? prev : current) : "";
        let newObject = {
            updatedValue: '',
            id: (max) ? parseInt(max.id) + 1 : 100,
            isAdded: true
        }
        let { values } = this.state.CommitFee;
        values.push(newObject);
    }

    /**
     * This function is to add new Region value to the existing Region values
    */
    RegionsLogic() {
        let max = (this.state.Regions.values.length > 0) ?
            this.state.Regions.values.reduce((prev, current) => (prev.id > current.id) ? prev : current) : "";
        let newObject = {
            updatedValue: '',
            id: (max) ? parseInt(max.id) + 1 : 100,
            isAdded: true
        }
        let { values } = this.state.Regions;
        values.push(newObject);
    }

    /**
     * This function is to identify the type of configuration value to be added
     * @param {*} name to identity the configuration type to add a new value
    */
    addNewValue = (name) => {
        switch (name) {
            case 'TermLength':
                this.TermLengthLogic()
                break;
            case "AOS":
                this.AOSLogic()
                break;
            case 'VolumeCommit':
                this.VolumeCommitLogic()
                break;
            case 'CommitFee':
                this.CommitFeeLogic()
                break;
            case 'Regions':
                this.RegionsLogic()
                break;
            default:
        }
    }

    render() {
        return (
            <div className="full-width configuration-popup">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        Configurations
                        <span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                        {<Icon name="close" />}
                        </span>
                    </p>
                </div>
                <div className="full-width body-wraper pt-4 mb-0 pl-5 pr-5 pb-4">
                    <div className="full-width pt-3">
                        <div className="col-11 float-left p-0 rate-cardtabs">
                            <AWSButtonRadio
                                options={RateCardType}
                                value={this.state.rateCardButtonOption}
                                handleOnSelect={(value) => this.handleRateCardButtonMenu(value)}
                                defaultValue="Rate Card 1"
                                canEditPPR={true}
                            />
                        </div>
                        <div className="small-input float-left p-0 mr-5">
                            {/* {this.state.TermLength !== '' &&
                                (
                                    <div className="col-8 float-left p-0 add-wraper">
                                        <p className="dark-black mb-1 ">Term Length in Months</p>
                                        {
                                            this.state.TermLength.values.map((termlength, index) => {
                                                return (
                                                    <div key={"termlength_" + index} className="col-3 float-left pl-0 pr-2 input-container">
                                                        {termlength.isDeleted !== true &&
                                                            <div>
                                                                <span className="float-left config-input">
                                                                    <AWSInput
                                                                        placeholder=""
                                                                        value={termlength.updatedValue && termlength.updatedValue.toString()}
                                                                        onChangeFunc={(value) => { this.onChangeFunc(value, termlength.id, "TermLength") }}
                                                                        validation={validation}
                                                                        triggeredNext={this.state.triggeredNext}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </span>
                                                                {
                                                                    this.state.tmCloseIcon &&
                                                                    <span className="close-icon cursor-pointer" onClick={() => { this.hideInput("TermLength", termlength.id) }}>
                                                                        {<Icon name="close" />}
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="plus-minus width-auto float-left pl-3 pt-3">
                                            <span className="mr-3 float-left cursor-pointer" onClick={() => { this.addNewValue("TermLength") }}><img src={Plusicon} alt="plus" /></span>
                                            <span className="mr-0 float-left cursor-pointer" onClick={() => { this.showClose("TermLength") }}>
                                            {<Icon name="close" />}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            {this.state.AOS !== '' &&
                                (
                                    <div className="col-8 float-left p-0 pt-4 add-wraper">
                                        <p className="dark-black mb-1">Average Object Size (AOS) in KB</p>
                                        {
                                            this.state.AOS.values.map((aos, index) => {
                                                return (
                                                    <div key={"aos_" + index} className="col-3 float-left pl-0 pr-2 input-container">
                                                        {aos.isDeleted !== true &&
                                                            <div>
                                                                <span className="float-left config-input" id={"config_aos" + index}>
                                                                    <AWSInput
                                                                        placeholder=""
                                                                        value={aos.updatedValue && aos.updatedValue.toString()}
                                                                        onChangeFunc={(value) => { this.onChangeFunc(value, aos.id, "AOS") }}
                                                                        validation={validation}
                                                                        triggeredNext={this.state.triggeredNext}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </span>
                                                                {
                                                                    this.state.aosCloseIcon &&
                                                                    <span className="close-icon cursor-pointer" onClick={() => { this.hideInput("AOS", aos.id) }}>
                                                                        {<Icon name="close" />}
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }

                                            )
                                        }
                                        <div className="plus-minus width-auto float-left pl-3 pt-3">
                                            <span className="mr-3 float-left cursor-pointer" onClick={() => { this.addNewValue("AOS") }}><img src={Plusicon} alt="plus" /></span>
                                            <span className="mr-0 float-left cursor-pointer" onClick={() => { this.showClose("AOS") }}>
                                            {<Icon name="close" />}
                                            </span>
                                        </div>
                                    </div>
                                )} */}
                            {this.state.VolumeCommit !== '' &&
                                (
                                    <div className="col-8 float-left p-0 add-wraper pt-4">
                                        <p className="dark-black mb-1 ">Volume Commit in TB</p>
                                        {
                                            this.state.VolumeCommit.values.map((volumeCommit, index) => {
                                                return (
                                                    <div key={index} className="col-3 float-left pl-0 pr-2 input-container">
                                                        {volumeCommit.isDeleted !== true &&
                                                            <div>
                                                                <span className="float-left config-input" id={"config_VolumeCommit" + index}>
                                                                    <AWSInput
                                                                        placeholder=""
                                                                        value={volumeCommit.updatedValue && volumeCommit.updatedValue.toString()}
                                                                        onChangeFunc={(value) => { this.onChangeFunc(value, volumeCommit.id, "VolumeCommit") }}
                                                                        validation={validation}
                                                                        triggeredNext={this.state.triggeredNext}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </span>
                                                                {
                                                                    this.state.vmCloseIcon &&
                                                                    <span className="close-icon cursor-pointer" onClick={() => { this.hideInput("VolumeCommit", volumeCommit.id) }}>
                                                                        {<Icon name="close" />}
                                                                    </span>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            }

                                            )
                                        }
                                        <div className="plus-minus width-auto float-left pl-3 pt-3">
                                            <span className="mr-3 float-left cursor-pointer" onClick={() => { this.addNewValue("VolumeCommit") }}><img src={Plusicon} alt="plus" /></span>
                                            <span className="mr-0 float-left cursor-pointer" onClick={() => { this.showClose("VolumeCommit") }}>
                                            {<Icon name="close" />}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            {/* {this.state.CommitFee !== '' &&
                                (
                                    <div className="col-8 float-left p-0 add-wraper pt-4">
                                        <p className="dark-black mb-1 ">Commitment Fee in $</p>
                                        {
                                            this.state.CommitFee.values.map((commitfee, index) => {
                                                return (
                                                    <div key={index} className="col-3 float-left pl-0 pr-2 input-container">
                                                        {commitfee.isDeleted !== true &&
                                                            <div>
                                                                <span className="float-left config-input" id={"config_CommitFee" + index}>
                                                                    <AWSInput
                                                                        placeholder=""
                                                                        value={commitfee.updatedValue && commitfee.updatedValue.toString()}
                                                                        onChangeFunc={(value) => { this.onChangeFunc(value, commitfee.id, "CommitFee") }}
                                                                        validation={validation}
                                                                        triggeredNext={this.state.triggeredNext}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </span>
                                                                {
                                                                    this.state.cfCloseIcon && <span className="close-icon cursor-pointer" onClick={() => { this.hideInput("CommitFee", commitfee.id) }}>
                                                                        {<Icon name="close" />}
                                                                    </span>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            }

                                            )
                                        }
                                        <div className="plus-minus width-auto float-left pl-3 pt-3">
                                            <span className="mr-3 float-left cursor-pointer" onClick={() => { this.addNewValue("CommitFee") }}><img src={Plusicon} alt="plus" /></span>
                                            <span className="mr-0 float-left cursor-pointer" onClick={() => { this.showClose("CommitFee") }}>
                                            {<Icon name="close" />}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            {this.state.Regions !== '' &&
                                (
                                    <div className="col-8 float-left p-0 pt-4">
                                        <p className="dark-black mb-1">Add new Regions</p>
                                        {
                                            this.state.Regions.values.map((newRegion, index) => {
                                                return (
                                                    <div key={index} className="col-3 float-left pl-0 pr-2 input-container">
                                                        {newRegion.isDeleted !== true &&
                                                            <div>
                                                                <span className="float-left config-input" id={"config_TermLength" + index}>
                                                                    <AWSInput
                                                                        placeholder=""
                                                                        value={newRegion.updatedValue && newRegion.updatedValue.toString()}
                                                                        onChangeFunc={(value) => { this.onChangeFunc(value, newRegion.id, "Regions") }}
                                                                        validation={validation}
                                                                        triggeredNext={this.state.triggeredNext}
                                                                        questionValue={questionValue}
                                                                    />
                                                                </span>
                                                                {
                                                                    this.state.rgCloseIcon && <span className="close-icon cursor-pointer" onClick={() => { this.hideInput("Regions", newRegion.id) }}>
                                                                        {<Icon name="close" />}
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }

                                            )
                                        }
                                        <div className="plus-minus width-auto float-left pl-3 pt-3">
                                            <span className="mr-3 float-left cursor-pointer" onClick={() => { this.addNewValue("Regions") }}><img src={Plusicon} alt="plus" /></span>
                                            <span className="mr-0 float-left cursor-pointer" onClick={() => { this.showClose("Regions") }}>
                                            {<Icon name="close" />}
                                            </span>
                                        </div>
                                    </div>
                                )} */}
                        </div>
                    </div>
                </div>
                <div className="footer-wraper full-width pr-4">
                    <p className="col-6 float-left pl-3 pt-3">Adding or Editing value in the configurations will reflect the change across the application.</p>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={"Save"}
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfigurationPopup;

ConfigurationPopup.propTypes = {
    showUserManagementLoadingToast: PropTypes.func,
    handleOnSelect: PropTypes.func,
    handleCancel: PropTypes.func,
};
