import React, { Component } from 'react';
import "./AWSheader.scss";
import homeIcon from '../../../images/awsfalconhomegrey.svg';
import saveIcon from '../../../images/save.svg';
import Logo from "../../../images/aws_logoblack.png";
import Col from "../AWSCol/AWSCol";
import Popover from '../../Dashboard/LogoutPopOver.js';
import userIcon from '../../../images/awsfalconprofilegrey.svg';
import downArrowIcon from '../../../images/awsfalcondownArrow.svg';
import VideoHeaderWhite from '../../../images/PlayIconHeadergrey.svg';
import WisdomPortalIconGrey from '../../../images/WisdomPortalIconGrey.svg';
import external_linkBlack from '../../../images/external_linkBlack.svg';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as roleNames from '../../../constants/roleNames.const';
import { BACK_TO_SEARCH_RESULT } from '../../../constants/routes.const';
import AWSConfig from '../../../config/AWSconfig';
import PropTypes from "prop-types";
import { clearSessionStorage, getSessionStorage } from '../../../utils/sessionStorageUtils';
import STORAGE_NAMES from '../../../constants/storageNames.const';
import AWSModal from "../AWSModal/AWSModal";

const config = new AWSConfig();
export default class AWSheader extends Component {

    state = {
        popoverVisible: false,
        modalVisible: false,
        modalType: "renderConfirmationPopUp",
    }
    navigateToBugLogPage = () => {
        window.open(config.logTicketUrl, "'_blank'")
    };
 
    goHome = () => {
        clearSessionStorage();
        if (this.props.goHomeConfirmationPopUp) {
            this.setState({ modalVisible: true });
        } else {
            this.props.handlePPRCreatedForAutoSave(this.props.locationState);
        }
    }

    handleCancelOrCloseOfModal = () => {
        this.setState({ modalVisible: false });
    }; 

    handleOkOfModal = () => {
        this.setState({ modalVisible: false });
        const location = {
            pathname: "/dashboard",
            state: this.props.state
        };
        this.props.history.push(location);
    }

    checkForSaveIcon = () => {
        let { dealStatus, dealInfo, canEditPPR } = this.props;

        let userRole = this.props.locationState && this.props.locationState.userRole;
        if (userRole === roleNames.SALES_REPRESENTATIVE && canEditPPR === true) {
            if (dealStatus === dealStatusConstant.PPR_Submitted || dealStatus === dealStatusConstant.PPR_Created || dealStatus === dealStatusConstant.Awaiting_Sales_Input || dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM || (dealStatus === dealStatusConstant.Deal_Signed)) {
                return true
            }
        } else if (userRole === roleNames.SALES_REPRESENTATIVE && canEditPPR === false) {
            if ((dealStatus === dealStatusConstant.Deal_Signed)) {
                return true
            } else {
                return false
            }
        } else if (userRole === roleNames.BILLING_TEAM && dealStatus === dealStatusConstant.Deal_Signed) {
            return true
        }
        else {
            return false
        }
    }

    render() {
        return (
            <div className="header-wraper">
                <header>
                    <div className="col-12 p-0 float-left">
                        <Col className="logo" span={12} data-testid="falconLogo">
                            <div className="logo-wraper float-left col-12 pl-4">
                                <img className="cursor-pointer logo-img mt-0 float-left" src={Logo} alt="header" onClick={() => this.goHome()} />
                                <span onClick={() => this.goHome()} className=" cursor-pointer logo-title pl-3 width-auto">{(this.props.type === 'admin') ? "CloudFront Private Pricing Admin Console" : "CloudFront Private Pricing Deal Intake Console"} </span>
                            </div>
                        </Col>
                        <Col span={12} className="notification-user-details float-right pr-4 pt-2">
                            <div className="float-right pt-4" onClick={() => this.setState({ popoverVisible: !this.state.popoverVisible })}>
                                <span className="float-left pr-4"><img src={userIcon} className="user-icon" alt="user" /></span>
                                <p className="font-size14 black width-auto float-left user-name-div cursor-pointer pt-0"><span className="pr-4">{this.props.firstName} {this.props.lastName}</span> <span className="down-arrow float-right pl-1">
                                    <img src={downArrowIcon} alt="downArrow" /></span></p>
                                <div className="logout-div">
                                    {this.state.popoverVisible &&
                                        <Popover
                                            color={"white"}
                                            updateUserRole={(secondaryRole, activeRole) => { this.props.updateUserRole(secondaryRole, activeRole) }}
                                            updateSPUserRole={(solutionProvider, activeRole) => { this.props.updateSPUserRole(solutionProvider, activeRole) }}
                                            updatePPOOrChinaUserRole={(activeRole) => { this.props.updatePPOOrChinaUserRole(activeRole) }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover">
                                <img src={external_linkBlack} alt="Ticketing" className="ticket-icon" onClick={() => { this.navigateToBugLogPage() }} />
                                <span className={this.props.type === 'admin' ? "logo-tooltip admin-ticketing-tooltip" : "logo-tooltip ticketing-tooltip"}>SIM Ticketing</span>
                            </div>

                            <div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover">
                                <img src={WisdomPortalIconGrey} alt="Wisdom Portal" className="wisdom-icon" onClick={() => window.open("https://wisdom.corp.amazon.com/Pages/PP-RC-Cloudfront.aspx", "_blank")} />
                                <span className={this.props.type === 'admin' ? "logo-tooltip admin-wisdom-tooltip" : "logo-tooltip wisdom-tooltip"}>AWS Wisdom Portal</span>
                            </div>

                            {this.props.locationState && this.props.locationState.userRole === roleNames.SALES_REPRESENTATIVE && (<div className="float-right pt-3 pr-4 mt-2 cursor-pointer logo-hover header-youtube-icon light-header" onClick={() => window.open("https://broadcast.amazon.com/channels/14832/playlists/4066", "_blank")}>
                                <img src={VideoHeaderWhite} alt="Video" className="video-icon" />
                                <span className="logo-tooltip training-tooltip">Training Videos</span>
                            </div>)}

                            {
                                this.checkForSaveIcon() === true && (
                                    <div className="float-right pt-3 pr-4 home-icon cursor-pointer mt-2 save-icon logo-hover">
                                        <img src={saveIcon} alt="save" onClick={() => this.props.handlePPRCreatedWhileSaveButtonClick(this.props.locationState)} />
                                        <span className="logo-tooltip save-white-tooltip">Save</span>
                                    </div>
                                )
                            }
                            {this.props.disableHomeIcon !== true && (
                                <div className="float-right pt-3 pr-4 home-icon cursor-pointer mt-2 logo-hover" data-testid="homeIcon">
                                    <img src={homeIcon} alt="home" className="user-icon home-icon" onClick={() => this.goHome()}  />
                                    <span className="logo-tooltip home-white-tooltip">Home</span>
                                </div>
                            )}

                            {getSessionStorage(STORAGE_NAMES.SEARCH_PARAMS) !== null && (
                                <div className="float-right pt-3 pr-4 home-icon cursor-pointer mt-2 logo-hover">
                                    <span className="user-icon home-icon" onClick={() => this.props.handlePPRCreatedForAutoSave(this.props.locationState)}>{BACK_TO_SEARCH_RESULT}</span>
                                </div>
                            )}
                        </Col>
                    </div>
                </header>

                {this.props.goHomeConfirmationPopUp &&
                    < AWSModal
                        type={this.state.modalType}
                        visible={this.state.modalVisible}
                        handleOk={() => {this.handleOkOfModal()}}
                        handleCancel={() => {this.handleCancelOrCloseOfModal()}}
                        handleClose={() => {this.handleCancelOrCloseOfModal()}}
                        text="Are you sure you want to go home? Your work will not be saved."
                        cancelLabel="Cancel"
                        confirmLabel="OK"
                    />
                }
            
            </div>
        );
    }

}
AWSheader.propTypes = {
    handlePPRCreatedForAutoSave: PropTypes.func,
    disableHomeIcon: PropTypes.bool,
    handlePPRCreatedWhileSaveButtonClick: PropTypes.func,
    locationState: PropTypes.any,
    type: PropTypes.any,
    dealInfo: PropTypes.any,
    auto_new: PropTypes.any,
    dealStatus: PropTypes.any,
    canEditPPR: PropTypes.any,
    firstName: PropTypes.any,
    lastName: PropTypes.any,
    goHomeConfirmationPopUp: PropTypes.bool,
};