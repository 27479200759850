import React, { Component } from "react";
import Col from "../common/AWSCol/AWSCol";
import { Icon, Button, Popover } from "@amzn/awsui-components-react";
// import { UndoMajorMonotone, RiskMinor } from "@shopify/polaris-icons";
// import CommentSection from "../Dashboard/CommentSection";
import * as Constants from "../../constants/dealStructureConstants";
import AWScheckbox from "../common/AWScheckbox/AWScheckbox";
import AWSinput from "../common/AWSInput/AWSPricinginput";
import AWSDollarinput from "../common/AWSInput/AWSDollarInput";
import AWStextarea from "../common/AWStextarea/AWStextarea";
import AWSError from '../common/AWSError/AWSError';
// import PolarisSlider from "../common/AWSSlider/PolarisSlider";
import AWSSlider from "../common/AWSSlider/AWSSlider";
import AWSPolarisTable from "../common/AWSEditableTable/AWSPolarisTable";
import AWSButtonRadio from "../common/AWSButtonRadio/AWSPricingRadio";
import AWSAlert from "../common/AWSAlert/AWSAlert";
import PropTypes from "prop-types";
import infoHelpIcon from '../../images/awsfalconhelp.png';
import Video from '../../images/Play.png';
import * as roleNames from '../../constants/roleNames.const';
import "./DealDetails.scss";
import VideoIcon from '../common/Icon/VideoIcon/VideoIcon';
import { SaveEnteredRatesComponent } from "./components/saveEnteredRatesComponent";
import { getIsCloudFrontShieldAdvancedDeal } from "./helpers/rateCard"
import { getCloudFrontShieldAdvancedMinCommit, getCloudFrontShieldAdvancedMinCommitError } from "../../constants/cloudFrontShieldAdvancedConstants";
import { showVolumeCommitAlert } from "./helpers/alerts";

let validation = [
  {
    message: "This is a mandatory field",
    type: "Required"
  }
];

let questionValue = {
  isVisible: true,
  isMandatory: true
};

class PricingDetails extends Component {
  /**
   * This function used update the local state value of each input element in parent component
   * and call the commitment Fee calcualtion based on value change
   * @param {*} val typed or selected option which need to update in the state
   * @param {*} key in which name of the particular element value to be set
   * @param {*} rowIndex the array row location where the values chaned
   * @param {*} cellIndex cell id of table when any table data is inserted to make change in particular cell
   */
  handleChange = (val, key, rowIndex, cellIndex, type) => {
    this.props.handleInputChange(val, key, rowIndex, cellIndex, type);
  };

  /**
   * This function used update the local state value of each input element in parent component
   * and call the commitment Fee calcualtion based on value change
   * @param {*} value typed or selected option which need to update in the state
   * @param {*} name in which name of the particular element value to be set
   * @param {*} rowId the array row location where the values chaned
   * @param {*} cellId cell id of table when any table data is inserted to make change in particular cell
   * @param {*} key key name of the particular cell
   */
  handleBlur = (val, name, rowIndex, cellIndex, key) => {
    this.props.handleInputBlur(val, name, rowIndex, cellIndex, key);
  };

  /**
   * This function used to load the standard rates after user Edited the rate card values
   */
  loadStandardDeal = () => {
    this.props.loadStandardDeal();
  };

  /**
   * This function used to load the standard private rates after user Edited the rate card values
   */
  loadStandardPrivateRates = () => {
    this.props.loadStandardPrivateRates();
  };

  render() {
    const { rateCard, userRole, dealInfo } = this.props;
    const isCloudFrontShieldAdvancedDeal = getIsCloudFrontShieldAdvancedDeal(dealInfo);
    
    const disabledForSavedRates = !this.props.canEditPPR || rateCard.saveEnteredRates;
    return (
      <div className="border-bottom-grey pb-4 full-width">
        <Col span={24}>
          <div className="col-12 float-left p-0 pt-2">
            <h5 className="font-size18 amber-bold pt-4 col-11 p-0 float-left">
              Pricing
            </h5>
          </div>
        </Col>
        <Col span={24} className="mt-5">
          <div className="col-12 float-left p-0">
            <div className="float-left">

              <p className="dark-black font-size14 mb-3 col-12 p-0 float-left">
                <span className="float-left">Type of CloudFront Rate Card</span>

                <Popover content={Constants.infoText.typeofcommit}
                  preferredPosition="above" size="small" position="top">

                  <span className="float-left circle-alert">
                    {/* <img src={infoHelpIcon} alt="help" /> */}
                    <Icon name="status-info"></Icon>
                  </span>
                </Popover>
              </p>
            </div>
            {/* <CommentSection infoDescription={Constants.infoText.typeofcommit} /> */}
          </div>
          {rateCard.VolumeCommitCheckBox.map(volumevalue => {
            return (
              <span key={volumevalue.optionKey}>
                {volumevalue.optionType === "Checkbox" && (
                  <>
                    <Col span={5} key={volumevalue.optionKey} className="d-flex">
                      <AWScheckbox
                        checked={volumevalue.status}
                        label={volumevalue.optionValue}
                        value={volumevalue.optionValue}
                        onChangeFunc={value => {
                          this.handleChange(value, volumevalue.optionValue);
                        }}
                        userRole={userRole}
                        canEditPPR={this.props.canEditPPR}
                        disabled={(volumevalue.optionValue === "Commitment Fee" || volumevalue.optionValue === "No Commit") && isCloudFrontShieldAdvancedDeal}
                      />
                      {userRole === roleNames.SALES_REPRESENTATIVE && volumevalue.optionValue === "Volume Commit" ?
                        <div className="float-right pl-3 pr-2 pt-0 cursor-pointer commit-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192418", "_blank")}>
                          {/* <img src={Video} alt="Video" className="video-icon pricing-detail" onClick={() => window.open("https://broadcast.amazon.com/videos/192418", "_blank")} /> */}
                          <VideoIcon />
                          <span className="commit-tooltip">Commit & Pricing - Volume Commit</span>
                        </div> : ""
                      }
                      {userRole === roleNames.SALES_REPRESENTATIVE && volumevalue.optionValue === "Commitment Fee" ?
                        <div className="float-right pl-3 pr-2 pt-0 cursor-pointer commit-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192429", "_blank")} >
                          {/* <img src={Video} alt="Video" className="video-icon pricing-detail" onClick={() => window.open("https://broadcast.amazon.com/videos/192429", "_blank")} /> */}
                          <VideoIcon />
                          <span className="commit-tooltip">Commit & Pricing - Commitment Fee</span>
                        </div> : ""
                      }
                      {userRole === roleNames.SALES_REPRESENTATIVE && volumevalue.optionValue === "No Commit" ?
                        <div className="float-right pl-3 pr-2 pt-0 cursor-pointer commit-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192433", "_blank")}>
                          {/* <img src={Video} alt="Video" className="video-icon pricing-detail" onClick={() => window.open("https://broadcast.amazon.com/videos/192433", "_blank")} /> */}
                          <VideoIcon />
                          <span className="commit-tooltip no-commit-tooltip">Commit & Pricing - No Commit</span>
                        </div> : ""
                      }
                    </Col>
                  </>
                )}

                {volumevalue.optionType === "Input" &&
                  rateCard.VolumeCommitCheckBox[1].status === true && (
                    <div className="float-left col-2 pl-2 mr-2 commitfee-dollar-input" key={volumevalue.optionKey}>
                      <AWSDollarinput
                        placeholder={""}
                        value={rateCard.commitment_fee}
                        labelValue={""}
                        onChangeFunc={value => {
                          this.handleChange(value, "commitment_fee", "", "", "Input");
                        }}
                        onBlurFunc={value =>
                          this.handleBlur(value, "commitment_fee")
                        }
                        validation={validation}
                        triggeredNext={this.props.triggeredNext}
                        questionValue={questionValue}
                        userRole={userRole}
                        canEditPPR={this.props.canEditPPR}
                      ></AWSDollarinput>
                    </div>
                  )}
              </span>
            );
          })}
          {rateCard.TermLength < 24 &&
            rateCard.commitment_fee !== 0 &&
            rateCard.commitment_fee !== "" &&
            parseInt(rateCard.commitment_fee.toString().replace(/,/g, "")) <
            10000 && (
              <div className="col-10 p-0 mt-3 float-left">
                <AWSAlert label={Constants.TermCommitfor12} />
              </div>
            )}
          {rateCard.TermLength >= 24 &&
            rateCard.commitment_fee !== 0 &&
            rateCard.commitment_fee !== "" &&
            parseInt(rateCard.commitment_fee.toString().replace(/,/g, "")) <
            15000 && (
              <div className="col-10 p-0 mt-3 float-left">
                <AWSAlert label={Constants.TermCommitfor24} />
              </div>
            )}
          {rateCard.VolumeCommitCheckBox[3].status === true && (
            <div className="col-10 p-0 mt-3 float-left">
              <AWSAlert
                label={"The deal request requires additional approvals"}
              />
            </div>
          )}
          {isCloudFrontShieldAdvancedDeal && (
            <div className="col-10 p-0 mt-3 float-left">
              <AWSAlert
                label={"Commitment Fee and No Commit options are not allowed for CF+SA deals at this time"}
              />
            </div>
          )}
        </Col>
        {rateCard.TypeOfCommit.length > 0 && (
          <>
            {rateCard.VolumeCommitCheckBox[1].status === true && (
              <Col span={18} className="mt-4">
                <div className="mb-4">
                  <AWStextarea
                    label={
                      "How much CloudFront usage has the customer had over the past 12 months?"
                    }
                    value={rateCard.past_price}
                    onChangeFunc={value => {
                      this.handleChange(value, "past_price");
                    }}
                    validation={validation}
                    triggeredNext={this.props.triggeredNext}
                    questionValue={questionValue}
                    canEditPPR={this.props.canEditPPR}
                    userRole={userRole}
                  />
                </div>
                <div className="mb-0 full-width pt-4">
                  <AWStextarea
                    label={
                      "How much CloudFront usage does the customer expect to have per year over the term of the agreement?"
                    }
                    value={rateCard.floor_price}
                    onChangeFunc={value => {
                      this.handleChange(value, "floor_price");
                    }}
                    validation={validation}
                    triggeredNext={this.props.triggeredNext}
                    questionValue={questionValue}
                    canEditPPR={this.props.canEditPPR}
                    userRole={userRole}
                  />
                </div>
              </Col>
            )}
            {rateCard.VolumeCommitCheckBox[0].status === true && (
              <Col span={24} className="mt-5">
                <div className=" col-12 float-left p-0">
                  <div className="float-left">

                    <p className="dark-black font-size14 mb-2 col-12 float-left p-0">
                      <span className="float-left">Type of Commit</span>

                      <Popover content={Constants.commitHelpText[0]["Typeofcommit"]}
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon></span>
                      </Popover>
                    </p>
                  </div>
                </div>

                {/* <CommentSection
                  infoDescription={Constants.infoText.Type_of_CFRC}
                /> */}
                <AWSButtonRadio
                  options={Constants.CFRC}
                  defaultValue={"Monthly"}
                  value={rateCard.TypeOfCFRL}
                  handleOnSelect={value => {
                    this.handleChange(value, "TypeOfCFRL");
                  }}
                />
              </Col>
            )}
            <Col span={24} className="mt-5 pt-2">
              <div className="col-12 float-left p-0">
                <div className=" float-left">
                  <p className="dark-black font-size14 mb-2 col-12 float-left p-0 d-flex requestfeeee">
                    <span className="float-left">Request Fees</span>
                    <span>
                      <Popover content={Constants.commitHelpText[0]["Requestfees"]}
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon></span>
                      </Popover>
                    </span>

                    {this.props.userRole === roleNames.SALES_REPRESENTATIVE && <span className="float-right ml-3 video-icon cursor-pointer request-fees-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192444", "_blank")}>
                      <VideoIcon />
                      <span className="request-fees-tooltip">Commit & Pricing - Request Fees</span>
                    </span>}

                  </p>
                </div>
              </div>
              <span className="float-right">
                {/* <CommentSection
                  infoDescription={Constants.infoText.request_fees}
                /> */}
              </span>
              <AWSButtonRadio
                options={Constants.commitfee}
                value={rateCard.request_fees}
                handleOnSelect={value => {
                  this.handleChange(value, "request_fees");
                }}
              />

              {rateCard.request_fees === "Apply Private Rates" && (
                <>
                  <p className="grey font-size14 mb-1 mt-4">
                    <span className="float-left">Request rates are in $/10k requests</span>
                    <Popover content={Constants.commitHelpText[0]["CommitFeePrivateRates"]}
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>

                  </p>
                  <div className="width78 float-left p-0 requestfee-table">
                    <AWSPolarisTable
                      columns={Constants.tableRequestHeaders}
                      rowValues={rateCard.dataprivateRates}
                      tierType={"tiered"}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      onChangeFunc={(value, rowIndex, cellIndex) => {
                        this.handleChange(
                          value,
                          "PrivateRates",
                          rowIndex,
                          cellIndex
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "PrivateRates",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  </div>
                  <div className={"width78 float-left load-btnwraper p-0 mt-4 pr-3"}>
                    <div className="float-right">
                      <span className="mr-0">
                        <Button
                          onClick={() => {
                            this.loadStandardPrivateRates();
                          }}
                        >
                          <Icon name="undo"></Icon>
                          Restore Rates
                        </Button>
                      </span>
                    </div>
                  </div>
                  {rateCard.privaterateEdited === true && (
                    <div className="col-11 float-left p-0 pr-3 request-alert">
                      <div className="col-12 p-0 mt-3 float-left">
                        <AWSAlert
                          label={
                            "This deal request requires additional approvals"
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>
            {rateCard.request_fees === "Waive Request Fees" && (
              <Col span={24} className="mt-5">
                <div className="col-12 float-left p-0">
                  <div className=" float-left">

                    <p className="dark-black font-size14 mb-1 col-12 p-0 float-left">
                      <span className="float-left">Minimum Average Object Size (AOS) (kb)</span>

                      <Popover content={Constants.commitHelpText[0]["AOS"]}
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon></span>
                      </Popover>
                    </p>
                  </div>
                  {/* <CommentSection
                    infoDescription={Constants.infoText.average_obj_size}
                  /> */}
                </div>
                <span className="col-8 max-width69 float-left p-0 mr-3 pt-4">
                  <AWSSlider
                    value={rateCard.Aos_Selected}
                    options={Constants.aos}
                    steps={1}
                    onChangeFunc={value => {
                      this.handleChange(value, "Aos_Selected", "", "", "Slider");
                    }}
                  />
                </span>
                <span className="col-2 float-left pt-4 small-input">
                  <AWSinput
                    value={rateCard.Aos_Selected.toString()}
                    placeholder={"1"}
                    onChangeFunc={value => {
                      this.handleChange(value, "Aos_Selected", "", "", "Input");
                    }}
                    onBlurFunc={value => this.handleBlur(value, "Aos_Selected")}
                    userRole={userRole}
                    canEditPPR={this.props.canEditPPR}
                  />
                </span>
                {!isCloudFrontShieldAdvancedDeal &&
                  rateCard.VolumeCommitCheckBox[0].status === false &&
                  rateCard.VolumeCommitCheckBox[1].status === true &&
                  rateCard.Aos_Selected < 35 && (
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert label={Constants.CommitFeeVal} />
                    </div>
                  )}
                {!isCloudFrontShieldAdvancedDeal &&
                  rateCard.VolumeCommitCheckBox[0].status === true &&
                  rateCard.VolumeCommitCheckBox[1].status === false &&
                  rateCard.Aos_Selected < 15 && (
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert label={Constants.VolumeCommitVal} />
                    </div>
                  )}
                {!isCloudFrontShieldAdvancedDeal &&
                  rateCard.VolumeCommitCheckBox[0].status === true &&
                  rateCard.VolumeCommitCheckBox[1].status === true &&
                  rateCard.Aos_Selected < 15 && (
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert label={Constants.VolumeandCommitVal} />
                    </div>
                  )}
                {isCloudFrontShieldAdvancedDeal &&
                  rateCard.VolumeCommitCheckBox[0].status === true &&
                  rateCard.Aos_Selected < 35 && (
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert label={Constants.cloudFrontShieldAdvancedAOSVal} />
                    </div>
                  )}
              </Col>
            )}
            <Col span={24} className="mt-5 pt-2">
              <div className="col-12 float-left p-0">
                <div className="float-left">

                  <p className="dark-black font-size14 mb-1 col-12 p-0 float-left">
                    <span className="float-left">Term Length (Months)</span>

                    <Popover content={Constants.commitHelpText[0]["TermlengthMonths"]}
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>
                  </p>
                </div>
                {/* <CommentSection
                  infoDescription={Constants.infoText.terms_month}
                /> */}
              </div>

              <span className="col-8 float-left max-width69 p-0 mr-3 pt-4">
                <AWSSlider
                  options={Constants.terms}
                  value={rateCard.TermLength}
                  steps={1}
                  onChangeFunc={value => {
                    this.handleChange(value, "TermLength", "", "", "Slider");
                  }}
                />
              </span>
              <span className="col-2 float-left pt-4 small-input">
                <AWSinput
                  value={rateCard.TermLength.toString()}
                  placeholder={"1"}
                  onChangeFunc={value => {
                    this.handleChange(value, "TermLength", "", "", "Input");
                  }}
                  onBlurFunc={value => this.handleBlur(value, "TermLength")}
                  userRole={userRole}
                  canEditPPR={this.props.canEditPPR}
                />
              </span>
              {rateCard.TermLength < 12 && (
                <div className="col-10 p-0 mt-3 float-left">
                  <AWSAlert label={Constants.termLengthMinError} />
                </div>
              )}
              {rateCard.TermLength > 36 && (
                <div className="col-10 p-0 mt-3 float-left">
                  <AWSAlert label={Constants.termLengthMaxError} />
                </div>
              )}
            </Col>
            {rateCard.VolumeCommitCheckBox[0].status === true && (
              <>
                <Col span={24} className="mt-5 pt-2">
                  <div
                    className={`col-12 float-left p-0  ${rateCard.TypeOfCFRL === "No Commit"
                      ? "pointer-events-none"
                      : ""
                      }`}
                  >
                    <p className="dark-black font-size14 mb-2 col-11 p-0 float-left">
                      <span className="float-left">Volume Commit Unit</span>

                      <Popover content="Choose one of the options TB or PB for the Volume Commit"
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon></span>
                      </Popover>
                    </p>

                    <span className="float-right">
                      {" "}
                      {/* <CommentSection
                        infoDescription={Constants.infoText.volume_commit}
                      /> */}
                    </span>
                    <span className="ml-0">
                      <AWSButtonRadio
                        options={Constants.volume}
                        value={rateCard.volumeUnit}
                        handleOnSelect={value => {
                          this.handleChange(value, "volumeUnit");
                        }}
                        disabled={disabledForSavedRates && rateCard.tieredPricing}
                      />
                    </span>
                  </div>
                </Col>
                <Col span={24} className="mt-5">
                  <div className={`col-12 float-left p-0`}>
                    <p className="dark-black font-size14 mb-2 col-11 p-0 float-left">
                      <span className="float-left">Data Transfer Commit Regions</span>

                      <Popover content="Choose one of the options All or By Geo"
                        preferredPosition="above" size="small" position="top">
                        <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                          <Icon name="status-info"></Icon></span>
                      </Popover>
                    </p>
                    <span className="float-right">
                      {/* <CommentSection
                        infoDescription={
                          Constants.infoText.data_transfet_commit
                        }
                      /> */}
                    </span>
                    <span className="ml-0">
                      <AWSButtonRadio
                        options={Constants.regions}
                        value={rateCard.geo}
                        handleOnSelect={value => {
                          this.handleChange(value, "geo");
                        }}
                      />
                    </span>
                  </div>
                </Col>
              </>
            )}
            {rateCard.geo === "All" &&
              rateCard.VolumeCommitCheckBox[0].status === true && (
                <Col span={24} className="mt-5">
                  <p className="dark-black font-size14 mb-1 full-width">
                    <span className="float-left">All Regions Volume Commit</span>

                    <Popover content="Choose the All Regions Volume Commit from the slider or enter a value in the text box"
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>
                  </p>
                  {rateCard.volumeUnit === "PB" ? (
                    <span className="col-8 max-width69 float-left p-0 mr-3 pt-4">
                      <AWSSlider
                        options={Constants.transferPBAll}
                        value={rateCard.requested_commit}
                        steps={0.1}
                        onChangeFunc={value => {
                          this.handleChange(value, "requested_commit", "All", "", "Slider");
                        }}
                      />
                    </span>
                  ) : (
                    <span className="col-8 max-width69 float-left p-0 mr-3 pt-4">
                      <AWSSlider
                        options={Constants.transferTBAll}
                        value={rateCard.requested_commit}
                        steps={1}
                        onChangeFunc={value => {
                          this.handleChange(value, "requested_commit", "All", "", "Slider");
                        }}
                      />
                    </span>
                  )}
                  <span className="col-2 float-left pt-4 small-input">
                    <AWSinput
                      placeholder={rateCard.volumeUnit === "PB" ? "0.1" : "10"}
                      value={rateCard.requested_commit.toString()}
                      onChangeFunc={value => {
                        this.handleChange(value, "requested_commit", "All", "", "Input");
                      }}
                      canEditPPR={this.props.canEditPPR}
                      onBlurFunc={value =>
                        this.handleBlur(value, "requested_commit")
                      }
                      userRole={userRole}
                    />
                  </span>
                  {rateCard.VolumeCommitError && (
                    <div className="has-error font-size10 red  float-left mt-1">
                      <span className="error-icon mr-0 float-left">
                        <Icon name="status-warning"></Icon>
                      </span>
                      {rateCard.VolumeCommitError}
                    </div>
                  )}
                </Col>
              )}
            {rateCard.geo === "By Geo" &&
              rateCard.VolumeCommitCheckBox[0].status === true &&
              rateCard.geolocations.map((location, Index) => {
                return (
                  <Col
                    span={24}
                    key={Index}
                    className={`mt-4 pb-4 ${rateCard.TypeOfCFRL === "No Commit"
                      ? "pointer-events-none"
                      : ""
                      }`}
                  >
                    <p className="dark-black font-size14 mb-1">
                      {location.optionKey === "EU"
                        ? "EU/IL Volume Commit"
                        : location.optionKey + " " + "Volume Commit"}
                    </p>
                    {rateCard.volumeUnit === "PB" ? (
                      <span className="col-8 float-left p-0 mr-3 max-width69 pt-4">
                        <AWSSlider
                          options={Constants.transferPBbyGeo}
                          value={location.optionValue}
                          steps={0.1}
                          onChangeFunc={value => {
                            this.handleChange(
                              value,
                              "requested_commit",
                              location.optionKey,
                              "",
                              "Slider"
                            );
                          }}
                        />
                      </span>
                    ) : (
                      <span className="col-8 float-left p-0 mr-3 max-width69 pt-4">
                        <AWSSlider
                          options={Constants.transferTBbyGeo}
                          value={location.optionValue}
                          steps={1}
                          markerCount={7}
                          pointerCount={0}
                          onChangeFunc={value => {
                            this.handleChange(
                              value,
                              "requested_commit",
                              location.optionKey,
                              "",
                              "Slider"
                            );
                          }}
                        />
                      </span>
                    )}
                    <span className="col-2 float-left pt-4 small-input">
                      <AWSinput
                        placeholder={"0000"}
                        value={location.optionValue.toString()}
                        onChangeFunc={value => {
                          this.handleChange(
                            value,
                            "requested_commit",
                            location.optionKey,
                            "",
                            "Input"
                          );
                        }}
                        canEditPPR={this.props.canEditPPR}
                        onBlurFunc={value =>
                          this.handleBlur(
                            value,
                            "requested_commit",
                            location.optionKey
                          )
                        }
                        userRole={userRole}
                      />
                    </span>
                    {rateCard.geoVolumeCommitErrors[location.optionKey] && (
                      <AWSError
                        errorLabel={rateCard.geoVolumeCommitErrors[location.optionKey]}
                      />
                    )}
                  </Col>
                );
              })}
              {
                showVolumeCommitAlert(
                  isCloudFrontShieldAdvancedDeal,
                  rateCard.geo,
                  rateCard.VolumeCommitError,
                  rateCard.geoVolumeCommitErrors
                ) &&
                  <div className="col-10 p-0 mt-3 float-left">
                    <AWSAlert
                      label={getCloudFrontShieldAdvancedMinCommitError(getCloudFrontShieldAdvancedMinCommit(dealInfo.cloudFrontShieldAdvancedDeal))}
                    />
                </div>
              }
            <Col span={24} className="mt-5 pt-0">
              <p className="dark-black font-size14 mt-3 pr-2 float-left">
                <span className="float-left">Data Transfer Price (per GB){" "}</span>

                <Popover content="The Data Transfer Price (per GB) is populated here from the rate card. Edit manually if you are providing a different rate"
                  preferredPosition="above" size="small" position="top">
                  <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                    <Icon name="status-info"></Icon></span>
                </Popover>
                {this.props.userRole === roleNames.SALES_REPRESENTATIVE &&
                  <div className="float-right ml-3 vide-icon cursor-pointer pricing-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192449", "_blank")} >
                    <VideoIcon />
                    <span className="pricing-tooltip">Rate Card Automation, Override and Restore</span>
                  </div>}
              </p>
              <div className="d-flex">
                <span className="pb-1 ml-5 float-left pt-3">
                  <AWScheckbox
                    label={"Use Tiered Pricing"}
                    checked={rateCard.tieredPricing}
                    onChangeFunc={value => {
                      this.handleChange(value, "tieredPricing");
                    }}
                    userRole={userRole}
                    canEditPPR={!disabledForSavedRates}
                  />
                </span>
                <span>
                  <Popover content="Please select the checkbox to use tiered pricing"
                    preferredPosition="above" size="small" position="top">
                    <span className="float-right circle-alert pt-3 mr-3">{/* <img src={infoHelpIcon} alt="help" /> */}
                      <Icon name="status-info"></Icon></span>
                  </Popover>
                </span>

                {this.props.userRole === roleNames.SALES_REPRESENTATIVE &&
                  <span className="float-right vide-icon cursor-pointer pt-3 mr-3 pricing-youtube youtube-icon" onClick={() => window.open("https://broadcast.amazon.com/videos/192451", "_blank")} >
                    <VideoIcon />
                    <span className="pricing-tooltip">Tiered Pricing</span>
                  </span>}

                <SaveEnteredRatesComponent
                  value={this.props.rateCard.saveEnteredRates}
                  userRole={userRole}
                  onChangeFunc={value => {
                    this.handleChange(value, "saveEnteredRates", undefined, undefined, "Input");
                  }}
                  canEditPPR={this.props.canEditPPR}
                />
              </div>
              <div className="width97 float-left p-0 mt-0">
                <div className="col-10 float-left p-0 tiering-table">
                  {rateCard.tieredPricing === true ? (
                    <AWSPolarisTable
                      columns={Constants.getTableHeaderTier(rateCard.volumeUnit)}
                      rowValues={rateCard.dataTransferPriceTiered}
                      tierType={"tiered"}
                      VolumeType={rateCard.volumeUnit}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      onChangeFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleChange(
                          value,
                          "dataTransferInput",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "dataTransferInput",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  ) : (
                    <AWSPolarisTable
                      columns={Constants.tableHeaderAll}
                      rowValues={rateCard.dataTransferPriceNotTiered}
                      tierType={"noTiered"}
                      VolumeType={rateCard.volumeUnit}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      onChangeFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleChange(
                          value,
                          "dataTransferInput",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "dataTransferInput",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  )}
                  {rateCard.dataTransfertableError && (
                    <div className="has-error font-size10 red  float-left mt-3">
                      <span className="error-icon mr-0 float-left">
                        <Icon name="status-warning"></Icon>
                      </span>
                      {rateCard.dataTransfertableError}
                    </div>
                  )}
                  {rateCard.defaultRateError && (
                    <div className="has-error font-size10 red  float-left mt-3">
                      <span className="error-icon mr-0 float-left">
                        <Icon name="status-warning"></Icon>
                      </span>
                      {rateCard.defaultRateError}
                    </div>
                  )}
                  <div className={`col-6 float-right load-btnwraper p-0 mt-4 ${disabledForSavedRates ? "disable-undo" : ""}`}>
                    <div className="float-right">
                      <span className="mr-0">
                        <Button
                          disabled={disabledForSavedRates}
                          onClick={() => {
                            this.loadStandardDeal();
                          }}
                        >
                          <Icon name="undo"></Icon>
                          Restore Rates
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
                {
                  rateCard.cloudFrontShieldAdvancedCustomRatesError &&
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert
                        label={rateCard.cloudFrontShieldAdvancedCustomRatesError}
                      />
                    </div>
                }
                {rateCard.dealEdited === true &&
                  (
                    <div className="col-10 p-0 mt-3 float-left">
                      <AWSAlert
                        label={
                          "This deal request requires additional approvals"
                        }
                      />
                    </div>
                  )}
              </div>
            </Col>
            <Col span={20} className="float-left revenue-txt ml-3"></Col>
            <Col span={24} className="mt-4 pointer-events-none">
              <AWSDollarinput
                placeholder={""}
                value={rateCard.minimum_commitment_fee}
                labelValue={"Minimum Committed Revenue"}
                canEditPPR={this.props.canEditPPR}
                onChangeFunc={value => {
                  this.handleChange(value, "minimum_commitment_fee");
                }}
                onBlurFunc={value =>
                  this.handleBlur(value, "minimum_commitment_fee")
                }
                userRole={userRole}
              ></AWSDollarinput>
            </Col>
            <Col span={24} className="mt-4 total-wraper">
              <AWSDollarinput
                placeholder={""}
                value={rateCard.total_commitment_fee}
                labelValue={"Total Expected Revenue"}
                canEditPPR={this.props.canEditPPR}
                onChangeFunc={value => {
                  this.handleChange(value, "total_commitment_fee");
                }}
                onBlurFunc={value =>
                  this.handleBlur(value, "total_commitment_fee")
                }
                validation={validation}
                triggeredNext={this.props.triggeredNext}
                questionValue={questionValue}
                userRole={userRole}
              ></AWSDollarinput>
              <span className="float-right">
                {/* <CommentSection
                  infoDescription={Constants.infoText.Total_Expected_Revenue}
                /> */}
              </span>
            </Col>
            <Col span={20} className="float-left revenue-txt ml-3">
              {rateCard.totalExpError && (
                <div className="has-error font-size10 red float-right mt-3">
                  <span className="error-icon mr-0 float-left">
                    <Icon name="status-warning"></Icon>
                  </span>
                  {rateCard.totalExpError}
                </div>
              )}
            </Col>
          </>
        )}
        {rateCard.TypeOfCommit.length === 0 && (
          <>
            <Col span={18} className="mt-4">
              <div className="mb-4">
                <AWStextarea
                  label={
                    "How much CloudFront usage has the customer had over the past 12 months?"
                  }
                  value={rateCard.past_price_noCommit}
                  onChangeFunc={value => {
                    this.handleChange(value, "past_price_noCommit");
                  }}
                  validation={validation}
                  triggeredNext={this.props.triggeredNext}
                  questionValue={questionValue}
                  userRole={userRole}
                  canEditPPR={this.props.canEditPPR}
                />
              </div>
              <div className="mb-0 full-width pt-4">
                <AWStextarea
                  label={
                    "How much CloudFront usage does the customer expect to have per year over the term of the agreement?"
                  }
                  value={rateCard.floor_price_noCommit}
                  onChangeFunc={value => {
                    this.handleChange(value, "floor_price_noCommit");
                  }}
                  validation={validation}
                  triggeredNext={this.props.triggeredNext}
                  questionValue={questionValue}
                  userRole={userRole}
                  canEditPPR={this.props.canEditPPR}
                />
              </div>
            </Col>
            <Col span={24} className="mt-5 pt-2">
              <div className="col-12 float-left p-0">
                <div className=" float-left">
                  <p className="dark-black font-size14 mb-2 col-12 float-left p-0">
                    <span className="float-left">Request Fees</span>

                    <Popover content={Constants.commitHelpText[0]["Requestfees"]}
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>
                  </p>
                </div>
              </div>
              <span className="float-right">
                {/* <CommentSection
                  infoDescription={Constants.infoText.request_fees}
                /> */}
              </span>
              <AWSButtonRadio
                options={Constants.commitfee}
                value={rateCard.request_fees}
                handleOnSelect={value => {
                  this.handleChange(value, "request_fees");
                }}
                userRole={userRole}
                canEditPPR={this.props.canEditPPR}
              />

              {rateCard.request_fees === "Apply Private Rates" && (
                <>
                  <p className="grey font-size14 mb-1 mt-4">
                    <span className="float-left">Request rates are in $/10k requests</span>


                    <Popover content={Constants.commitHelpText[0]["Requestfees"]}
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>
                  </p>
                  <div className="width78 float-left p-0 requestfee-table">
                    <AWSPolarisTable
                      columns={Constants.tableRequestHeaders}
                      rowValues={rateCard.dataprivateRates}
                      tierType={"tiered"}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      onChangeFunc={(value, rowIndex, cellIndex) => {
                        this.handleChange(
                          value,
                          "PrivateRates",
                          rowIndex,
                          cellIndex
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "PrivateRates",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  </div>
                  <div className={`width78 float-left load-btnwraper p-0 mt-4 pr-3 ${disabledForSavedRates ? "disable-undo" : ""}`}>
                    <div className="float-right">
                      <span className="mr-0">
                        <Button
                          disabled={disabledForSavedRates}
                          onClick={() => {
                            this.loadStandardPrivateRates();
                          }}
                          userRole={userRole}
                          canEditPPR={this.props.canEditPPR}
                        >
                          <Icon name="undo"></Icon>
                          Restore Rates
                        </Button>
                      </span>
                    </div>
                  </div>
                  {rateCard.privaterateEdited === true && (
                    <div className="col-11 float-left p-0 pr-3 request-alert">
                      <div className="col-12 p-0 mt-3 float-left">
                        <AWSAlert
                          label={
                            "This deal request requires additional approvals"
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>
            {rateCard.request_fees === "Waive Request Fees" && (
              <Col span={24} className="mt-4">
                <div className="col-12 float-left p-0">
                  <p className="dark-black font-size14 mb-1 col-11 p-0 float-left">
                    <span className="float-left">Minimum Average Object Size (AOS) (kb)</span>


                    <Popover content="Choose the Term from the slider or enter a value between 1 and 100 in the text box"
                      preferredPosition="above" size="small" position="top">
                      <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                        <Icon name="status-info"></Icon></span>
                    </Popover>
                  </p>
                  {/* <CommentSection
                    infoDescription={Constants.infoText.average_obj_size}
                  /> */}
                </div>
                <span className="col-8 float-left max-width69 p-0 mr-3 pt-4">
                  <AWSSlider
                    value={rateCard.Aos_Selected_Nocommit}
                    options={Constants.aos}
                    steps={1}
                    onChangeFunc={value => {
                      this.handleChange(value, "Aos_Selected_Nocommit");
                    }}
                    userRole={userRole}
                    canEditPPR={this.props.canEditPPR}
                  />
                </span>
                <span className="col-2 float-left pt-4 small-input">
                  <AWSinput
                    value={rateCard.Aos_Selected_Nocommit.toString()}
                    placeholder={"1"}
                    onChangeFunc={value => {
                      this.handleChange(value, "Aos_Selected_Nocommit");
                    }}
                    canEditPPR={this.props.canEditPPR}
                    userRole={userRole}
                    onBlurFunc={value =>
                      this.handleBlur(value, "Aos_Selected_Nocommit")
                    }
                  />
                </span>
              </Col>
            )}
            <Col span={24} className="mt-5">
              <div className="col-12 float-left p-0">
                <p className="dark-black font-size14 mb-1 col-11 p-0 float-left">
                  <span className="float-left">Term Length (Months)</span>

                  <Popover content="Choose the Term from the slider or enter a value between 1 and 48 in the text box"
                    preferredPosition="above" size="small" position="top">
                    <span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
                      <Icon name="status-info"></Icon></span>
                  </Popover>
                </p>
                {/* <CommentSection
                  infoDescription={Constants.infoText.terms_month}
                /> */}
              </div>
              <span className="col-8 float-left max-width69 p-0 mr-3 pt-4">
                <AWSSlider
                  options={Constants.terms}
                  value={rateCard.TermLength_Nocommit.toString()}
                  steps={1}
                  onChangeFunc={value => {
                    this.handleChange(value, "TermLength_Nocommit");
                  }}
                />
              </span>
              <span className="col-2 float-left pt-4 small-input">
                <AWSinput
                  value={rateCard.TermLength_Nocommit.toString()}
                  placeholder={"1"}
                  canEditPPR={this.props.canEditPPR}

                  onChangeFunc={value => {
                    this.handleChange(value, "TermLength_Nocommit");
                  }}
                  onBlurFunc={value =>
                    this.handleBlur(value, "TermLength_Nocommit")
                  }
                  userRole={userRole}
                />
              </span>
            </Col>
            <Col span={24} className="mt-4 pt-4">
              <p className="dark-black font-size14 mt-2 pr-2 float-left col-3 pl-0">
                Data Transfer Price (per GB){" "}
              </p>
              <span className="pb-1 ml-5 float-left">
                <AWScheckbox
                  label={"Use Tiered Pricing"}
                  checked={rateCard.tieredPricing_noCommit}
                  value={rateCard.tieredPricing_noCommit}
                  onChangeFunc={value => {
                    this.handleChange(value, "tieredPricing_noCommit");
                  }}
                  userRole={userRole}
                  canEditPPR={this.props.canEditPPR}
                />
              </span>

              <div className="width97 float-left p-0 mt-0">
                <div className="col-10 float-left p-0 tiering-table">
                  {rateCard.tieredPricing_noCommit === true ? (
                    <AWSPolarisTable
                      columns={Constants.tableHeaderTier}
                      rowValues={rateCard.dataTransferPriceTieredNoCommit}
                      tierType={"tiered"}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      tableType={"noCommitTable"}
                      triggeredNext={this.props.triggeredNext}
                      onChangeFunc={(value, rowIndex, cellIndex) => {
                        this.handleChange(
                          value,
                          "dataTransferPricenoCommit",
                          rowIndex,
                          cellIndex
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "dataTransferPricenoCommit",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  ) : (
                    <AWSPolarisTable
                      columns={Constants.tableHeaderAll}
                      rowValues={rateCard.dataTransferPriceNotTieredNoCommit}
                      tierType={"noTiered"}
                      canEditPPR={this.props.canEditPPR}
                      userRole={userRole}
                      tableType={"noCommitTable"}
                      triggeredNext={this.props.triggeredNext}
                      onChangeFunc={(value, rowIndex, cellIndex) => {
                        this.handleChange(
                          value,
                          "dataTransferPricenoCommit",
                          rowIndex,
                          cellIndex
                        );
                      }}
                      onBlurFunc={(value, rowIndex, cellIndex, key) => {
                        this.handleBlur(
                          value,
                          "dataTransferPricenoCommit",
                          rowIndex,
                          cellIndex,
                          key
                        );
                      }}
                    />
                  )}
                  {rateCard.dataTransfertableErrorNoCommit && (
                    <div className="has-error font-size10 red  float-left mt-3">
                      <span className="error-icon mr-0 float-left">
                        <Icon name="status-warning"></Icon>
                      </span>
                      {rateCard.dataTransfertableErrorNoCommit}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col span={24} className="mt-4 total-wraper">
              <AWSDollarinput
                placeholder={""}
                value={rateCard.total_commitment_fee_Nocommit}
                labelValue={"Total Expected Revenue"}
                canEditPPR={this.props.canEditPPR}
                onChangeFunc={value => {
                  this.handleChange(value, "total_commitment_fee_Nocommit");
                }}
                onBlurFunc={value =>
                  this.handleBlur(value, "total_commitment_fee_Nocommit")
                }
                validation={validation}
                triggeredNext={this.props.triggeredNext}
                questionValue={questionValue}
                userRole={userRole}
              ></AWSDollarinput>
              <span className="float-right">
                {/* <CommentSection
                  infoDescription={Constants.infoText.Total_Expected_Revenue}
                /> */}
              </span>
            </Col>
          </>
        )}
      </div>
    );
  }
}

export default PricingDetails;

PricingDetails.propTypes = {
  canEditPPR: PropTypes.bool,
  rateCard: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleInputBlur: PropTypes.func,
  loadStandardDeal: PropTypes.func,
  triggeredNext: PropTypes.bool,
  userRole: PropTypes.any,
  dealInfo: PropTypes.any
};