import React, { Component } from 'react';
import Col from "../../common/AWSCol/AWSCol";
import "./NavBar.scss";
import {Input} from  "@amzn/awsui-components-react";

class NavBar extends Component {

    state = {
        selected: 0,
        stage: ["Account Informaation", "Deal structute", "Commit and pricing", "Customer signature"]
    }

    handleSelectTab = (navIndex, stageId,stageName) => {
        this.props.navMenuOption !== "editMenu" && this.props.handleSelectTab(navIndex,stageId,stageName)
    }

    handleChange = (e, navIndex) => {
        this.props.handleChange(e, navIndex)
    }
    render() {

        return (
            <div className="navigation full-width">
                    <Col span={24}>
                        <div className="col-12 p-0 float-left tabs-contanier">
                            <ul className="col-12 float-left p-0 mb-0" id="nav-container">
                                {this.props.dataModel && this.props.dataModel.map((stage, navIndex) => {
                                    return (
                                        <li key = {navIndex} className={this.props.selectedStage === navIndex ? "font-size14 dark-black mr-4 active-tab pb-1 amber-bold" : "font-size14 dark-black mr-4  pb-1 amber-bold"}
                                            onClick={() => { this.handleSelectTab(navIndex,stage.templateStageId,stage.stageName) }} >
                                            {this.props.enableEditHeader === true && this.props.selectedStage === navIndex ?
                                                <Input
                                                    value={stage.stageName}
                                                    type={"text"}
                                                    onChange={(e) => this.handleChange(e.detail.value, navIndex)}
                                                />
                                                :
                                                stage.stageName
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
            </div>
        );
    }
}

export default NavBar;