export const copyToClipFirefox = (copyRef) => {
    window.getSelection().removeAllRanges();

    var range = document.createRange();

    const div = copyRef.current;
    const color = div.style.background;
    const textColor = div.style.color;
    div.style.margin = "10px";
    div.style.background = "#ffffff";
    div.style.color = "black";
    range.selectNode(div);
    window.getSelection().addRange(range);

    document.getElementById("copyContent").style.visibility = "hidden";
    document.execCommand("foreColor", false, "black");
    document.execCommand("copy");

    document.getElementById("copyContent").style.visibility = "visible";

    div.style.margin = "0px";
    div.style.background = color;
    div.style.color = textColor;
    
    document.execCommand("foreColor", false, "#cecece");
    window.getSelection().removeAllRanges();
};

export const copyContent = (copyRef) => {
    window.getSelection().removeAllRanges();
    var range = document.createRange();

    const div = copyRef.current;
    const color = div.style.background;
    div.style.margin = "10px";
    div.style.background = "#ffffff";

    document.getElementById("copyContent").style.visibility = "hidden";
    range.selectNode(div);
    window.getSelection().addRange(range);
    try {
        
        document.designMode = "on";

        if (document.getElementById("dealTypeTable")) {
            document.getElementById("dealTypeTable").className = "after-copy-table";
        }
        if (document.getElementById("channelTable")) {
            document.getElementById("channelTable").className = "after-copy-table";
        }
        if (document.getElementById("dealStatusTable")) {
            document.getElementById("dealStatusTable").className = "after-copy-table";
        }
        if (document.getElementById("GeoTable")) {
            document.getElementById("GeoTable").className = "after-copy-table";
        }

        document.execCommand("foreColor", false, "black");
        // document.execCommand( "fontName", false, "Arial");
        document.execCommand("copy");
        document.designMode = "off";
    } catch (err) {
        console.warn("Copying warning", err);
    } finally {
        document.getElementById("copyContent").style.visibility = "visible";
        if (document.getElementById("dealTypeTable")) {
            document.getElementById("dealTypeTable").className = " ";
        }
        if (document.getElementById("channelTable")) {
            document.getElementById("channelTable").className = " ";
        }
        if (document.getElementById("dealStatusTable")) {
            document.getElementById("dealStatusTable").className = " ";
        }
        if (document.getElementById("GeoTable")) {
            document.getElementById("GeoTable").className = " ";
        }
        document.getElementById("dealTypeTable").className = "dealTypeTable";
        div.style.margin = "0px";
        div.style.background = color;
        // let body = document.getElementById("leftnavOpen");
        // body.className = "font-change";
        document.designMode = "on";
        // document.execCommand("foreColor", false, "#cecece");
        document.designMode = "off";
        window.getSelection().removeAllRanges();
    }
};