import React, { Component } from 'react';
import AWSTable from "../common/AWSTable/AWSTable";
import AWSTableConstants from "../common/AWSTable/AWSTableConstants";
import AWSTableCloneIcon from "../common/AWSTableCloneIcon/AWSTableCloneIcon";
import AWSTableDeleteIcon from "../common/AWSTableDeleteIcon/AWSTableDeleteIcon";
import AWSTableSLAIcon from "../common/AWSTableSLAIcon/AWSTableSLAIcon";
import AWSTableCloseIcon from "../common/AWSTableCloseIcon/AWSTableCloseIcon";
import AWSTableDownloadIcon from "../common/AWSTableDownloadIcon/AWSTableDownloadIcon";
import AWSTableUpload from '../common/AWSTableUpload/AWSTableUpload';
import AWSTableOpenReviewIcon from '../common/AWSTableOpenReviewIcon/AWSTableOpenReviewIcon';
import * as dashboardConstant from '../../constants/dashboardConstants';
import * as roleNames from '../../constants/roleNames.const';
import userDashboard from '../../json/userDashboardOptions.json';
import "./AWSDashboard.scss";
import { getBusinessUnitAnswerLabel } from '../../constants/questionAnswerLabelConstants';

class AWSDashboardData extends Component {

    onView = (pprId, dealType, createdById) => {
        this.props.onView(
        "view",
        pprId,
        dealType,
        createdById
        );
    };

    /**
     * this function will be called to assign the ppr to other user roles
     * @param {*} pprId id of the particular ppr
     * @param {*} dealtype type of the deal created
     * @param {*} userRoleAlias role of the logged in user
     */
    onAssign = (event,pprId,dealtype,accountName,userRoleAlias,SalsRep,SalesManager,roleType) => {
        event.stopPropagation();
        if (this.props.userRole && roleType === this.props.userRole) {
            this.props.onAssign(
                pprId,
                dealtype,
                accountName,
                userRoleAlias,
                SalsRep,
                SalesManager
            );
        }
    };

    /** show popup for clone
     * @param {*} event value of the ppr created
     * @param {*} id id of the particular ppr
     */
    showDeletePPRPopup = (event, id, dealStatus) => {
        event.stopPropagation();
        if(dealStatus === 'PPR Created'){
            this.props.showDeletePPRPopup(id);
        }
    };
  
    /*** this function will be called to enable the clone popup confirmation
    * @param {*} dealStatus dealStatus of the ppr
    */
    enableClonePopup = (dealStatus) => {
        const cloneIconStatus = ['PPR Created', 'Inactive','Deal Signed', 'Deal Lost'];
        return cloneIconStatus.includes(dealStatus);
    }

    /** show popup for clone
     * @param {*} type value of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealstatus status of the deal created
     * @param {*} createdById created by date of deal
     */
    showInAcitvePopup = (e, pprId, dealstatus, createdById, pprType, accountName) => {
        e.stopPropagation();
        let userRole =
        this.props.location.state && this.props.location.state.userRole;
        if (userRole === roleNames.SALES_REPRESENTATIVE && !this.enableClonePopup(dealstatus)) {
        this.props.showInAcitvePopup(e, pprId, dealstatus, createdById, pprType, accountName);
        }
    };

    /** show popup for clone
     * @param {*} type value of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealtype type of the deal created
     * @param {*} userRoleAlias role of the logged in user
     */
    showClonePopup = (event,type,pprId,dealtype,userRoleAlias,dealStatus) => {
        event.stopPropagation();
        let userRole =
        this.props.location.state && this.props.location.state.userRole;
        if (dealtype !== "Termination" && userRole === roleNames.SALES_REPRESENTATIVE &&
        this.enableClonePopup(dealStatus)
        ) {
        this.props.showClonePopup(type, pprId, dealtype, userRoleAlias,dealStatus);
        }
    };

    /**
     * this function will be called when clicking the download
     * @param {*} event click event
     * @param {*} id id of the particular ppr
     */
    onDownload = (event, id, type) => {
        event.stopPropagation();
        this.props.onDownload(event, id, type);
    };

    /** show popup for open for review
     * @param {*} type value of the ppr created
     * @param {*} pprId id of the particular ppr
     * @param {*} dealstatus status of the deal created
     * @param {*} createdById created by date of deal
     */
    showOpenReviewPopup = (e, pprId, dealstatus, dealtype, createdById, pprType, accountName) => {
        e.stopPropagation();
        const userAlias = this.props.location.state && this.props.location.state.alias;
        if (dealstatus && dealstatus !== dashboardConstant.OPEN_FOR_REVIEW && dealtype !== dashboardConstant.TERMINATION && createdById === userAlias) {
            this.props.showOpenReviewPopup(e, pprId, dealstatus, createdById, pprType, accountName);
        }
    };

    getColumnDefinitions(){
        const columnDefinitions = [
            {
                header: "PPR ID",
                id: "pprId",
                cell: item => <div><a className="anchortag" onClick = {() => this.onView(item.pprId,item.dealType,item.createdById)}>{item.pprId}</a></div>,
                minWidth: '160px'
            },
            {
                header: "Customer Legal Name",
                id: "customerLegalName",
                cell: item => <div><a className="anchortag" onClick = {() => this.onView(item.pprId,item.dealType,item.createdById)}>{item.customerLegalName}</a></div>,
                minWidth: '200px'
            },
            {
                header: "Created Date",
                id: "createdDate",
                cell: item => <div>{(item.createdDate) ? moment(item.createdDate).format('MM/DD/YYYY') : ""}</div>,
                minWidth: '160px'
            },
            {
                header: "Effective Date",
                id: "discountTermEffectiveDate",
                cell: item => <div>{item.discountTermEffectiveDate}</div>,
                minWidth: '160px'
            },
            {
                header: "Deal Type",
                id: "dealType",
                cell: item => <div>{item.dealType}</div>,
                minWidth: '160px'
            },
            {
                header: "Deal Status",
                id: "dealStatus",
                cell: item => <div>{item.dealStatus}</div>,
                minWidth: '160px'
            },
            {
                header: "SLA Status",
                id: "SLA",
                cell: item => <AWSTableSLAIcon
                            userRole={
                              this.props.location.state && this.props.location.state.userRole
                            }
                            sla = {item.SLA}
                            pprType={item.pprType}
                            lastModifiedDate = {item.lastModifiedDate}
                            slaBreachmsg = {item.SLABreachmsg}
                          />,
                minWidth: '160px'
            },
            {
                header: "Sales Rep (Requestor)",
                id: "Sales_Representative",
                cell: item => 
                <div className="cursor-pointer anchortag" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    "Sales Representative"
                  )}>{item.Sales_Representative}</div>,
                minWidth: '200px'
            },
            {
                header: "PPR Type",
                id: "pprType", 
                cell: item => <div>{item.pprType}</div>,
                minWidth: '160px'
            },
            {
                header: "Payer ID",
                id: "payerId",
                cell: item => <div>{ (item.payerId && item.payerId.length > 15) ? String(item.payerId).substr(0, 15-1) + '...' : item.payerId }</div>,
                minWidth: '160px'
            },
            {
                header: "Sales Rep (Other)",
                id: "Sales_Representative_Requestor",
                cell: item => <div>{item.Sales_Representative_Requestor}</div>,
                minWidth: '160px'
            },
            {
                header: "Sales Manager",
                id: "Sales_Manager",
                cell: item => <div className="cursor-pointer" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    roleNames.SALES_MANAGER
                  )}>{item.Sales_Manager}</div>,
                minWidth: '160px'
            },
            {
                header: "SCE Owner",
                id: "SCE_Owner",
                cell: item => <div className="cursor-pointer" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    roleNames.SCE_OWNER
                  )}>{item.SCE_Owner}</div>,
                minWidth: '160px'
            },
            {
                header: "PPO Owner",
                id: "PPO_Owner", 
                cell: item => <div className="cursor-pointer" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    roleNames.IGT_OWNER
                  )}>{item.PPO_Owner}</div>,
                minWidth: '160px'
            },
            {
                header: "China Owner",
                id: "Private_Pricing_China",
                cell: item => <div className="cursor-pointer" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    roleNames.PRIVATE_PRICING_CHINA
                  )}>{item.Private_Pricing_China}</div>,
                minWidth: '160px'
            },
            {
                header: "Public Sector Owner",
                id: "Private_Pricing_Public_Sector",
                cell: item => <div className="cursor-pointer" onClick = { (e) => this.onAssign(
                    e,
                    item.pprId,
                    item.dealType,
                    item.accountName,
                    item.createdById,
                    item.Sales_Representative,
                    item.Sales_Manager,
                    roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
                  )}>{item.Private_Pricing_Public_Sector}</div>,
                minWidth: '220px'
            },
            {
                header: "Flags",
                id: "flag",
                cell: item => <div>{item.flag}</div>,
                minWidth: '160px'
            },
            {
                header: "Business Unit",
                id: "businessUnit",
                cell: item => <div>{getBusinessUnitAnswerLabel(item.businessUnit)}</div>,
                minWidth: '160px'
            },
            {
                header: "Vertical",
                id: "vertical",
                cell: item => <div>{item.vertical}</div>,
                minWidth: '160px'
            },
            {
                header: "Expected Customer Signature Date",
                id: "expectedCustomerSignatureDate",
                cell: item => <div>{(item.expectedCustomerSignatureDate) ? moment(item.expectedCustomerSignatureDate).format('MM/DD/YYYY') : ""}</div>,
                minWidth: '280px'
            },
            {
                header: "Last Status Updated",
                id: "lastStatusUpdated",
                cell: item => <div>{(item.lastStatusUpdated) ? moment(item.lastStatusUpdated).format('MM/DD/YYYY') : ""}</div>,
                minWidth: '200px'
            },
            {
                header: "Geo",
                id: "geo",
                cell: item => <div>{item.geo}</div>,
                minWidth: '160px'
            },
            {
                header: "Minimum Committed Revenue ($)",
                id: "minimumCommittedRevenue",
                cell: item => <div>{item.minimumCommittedRevenue}</div>,
                minWidth: '250px'
            },
            {
                header: "Term Length",
                id: "termLength",
                cell: item => <div>{item.termLength}</div>,
                minWidth: '160px'
            },
            {
                header: "CFRC Type",
                id: "CFRCType",
                cell: item => <div>{item.CFRCType}</div>,
                minWidth: '160px'
            },
            {
                header: "Commit Type",
                id: "commitType",
                cell: item => <div>{item.commitType}</div>,
                minWidth: '160px'
            },
            {
                header: "Expected Revenue ($)",
                id: "expectedRevenue",
                cell: item => <div>{item.expectedRevenue}</div>,
                minWidth: '180px'
            },
            {
                header: "Account Name",
                id: "accountName", 
                cell: item => <div>{item.accountName}</div>,
                minWidth: '160px'
            },
            {
                header: "Direct/Reseller/Solution Provider",
                id: "channel", 
                cell: item => <div>{item.channel}</div>,
                minWidth: '260px'
            },
            {
                header: "References",
                id: "references",
                cell: item => <div>{item.references}</div>,
                minWidth: '160px'
            },
            {
                header: "Executable",
                id: "executable",
                cell: item => <div onClick = { (e) => this.onDownload(
                                    e,
                                    item.pprId,
                                    "executable"
                                )}>
                                <AWSTableDownloadIcon
                                userRole={ this.props.location.state && this.props.location.state.userRole}
                                dealStatus={item.dealStatus}
                                type={"executable"}
                                />
                             </div>,
                minWidth: '150px'
            },
            {
                header: "Final Contract",
                id: "finalContract",
                cell: item => <div onClick = { (e) => this.onDownload(
                                    e,
                                    item.pprId,
                                    "finalContract"
                                )}>
                                <AWSTableDownloadIcon
                                userRole={ this.props.location.state && this.props.location.state.userRole}
                                dealStatus={item.dealStatus}
                                type={"finalContract"}
                                />
                             </div>,
                minWidth: '150px'
            },
            {
                header: "Upload/Replace Contract",
                id: "uploadContract", 
                cell: item => <div>
                            <AWSTableUpload
                                userRole={this.props.location.state && this.props.location.state.userRole}
                                userAlias={this.props.location.state && this.props.location.state.alias}
                                dealStatus={item.dealStatus}
                                pprId={item.pprId}
                                createdById={item.createdById}
                                pprType={item.pprType}
                                accountName={item.accountName}
                                getDashboardData={() => this.props.getDashboardData()}
                            />
                                </div>,
                minWidth: '210px'
            },
            {
                header: "Actions",
                id: "Actions",
                minWidth: '120px',
                cell: item =>   <div>
                                    <div id="DashboardClonePopUp" onClick = { (e) => this.showClonePopup(
                                            e,
                                            "clone",
                                            item.pprId,
                                            item.dealType,
                                            item.createdById,
                                            item.dealStatus
                                        )}>
                                        <AWSTableCloneIcon
                                            createdById = {item.createdById}
                                            userAlias = {this.props.location.state && this.props.location.state.alias}
                                            userRole={this.props.location.state &&this.props.location.state.userRole}
                                            dealStatus={item.dealStatus}
                                            dealType={item.dealType}
                                        />
                                    </div>
                                    <div id="DashboardDeletePopUp" onClick = { (e) => this.showDeletePPRPopup(
                                            e,
                                            item.pprId,
                                            item.dealStatus
                                        )}>
                                        <AWSTableDeleteIcon
                                            createdById = {item.createdById}
                                            userAlias = {this.props.location.state && this.props.location.state.alias}
                                            userRole={this.props.location.state &&this.props.location.state.userRole}
                                            dealStatus={item.dealStatus}
                                        />
                                    </div>
                                    <div id="DashboardInactivePopUp" onClick = { (e) => this.showInAcitvePopup(
                                            e,
                                            item.pprId,
                                            item.dealStatus,
                                            item.createdById,
                                            item.pprType,
                                            item.accountName
                                        )}>
                                        <AWSTableCloseIcon
                                            userRole={this.props.location.state &&this.props.location.state.userRole}
                                            dealStatus={item.dealStatus}
                                        />
                                    </div>
                                    <div id="DashboardInactivePopUp" onClick = { (e) => this.showOpenReviewPopup(
                                            e,
                                            item.pprId,
                                            item.dealStatus,
                                            item.dealType,
                                            item.createdById,
                                            item.pprType,
                                            item.accountName
                                        )}>
                                        <AWSTableOpenReviewIcon
                                            createdById = {item.createdById}
                                            userAlias = {this.props.location.state && this.props.location.state.alias}
                                            userRole={this.props.location.state &&this.props.location.state.userRole}
                                            dealStatus={item.dealStatus}
                                            dealType={item.dealType}
                                        />
                                    </div>
                                </div>,
            },
            {
                header: "Customer Type",
                id: "businessCustomerType",
                cell: item => <div>{item.businessCustomerType}</div>,
                minWidth: '160px'
            }
        ];
        return columnDefinitions;
    }

    getColumnOptions(dashboardColumnOptions){
        let contentOptions = [
            {
                label: "Properties",
                options: dashboardColumnOptions
            }
        ];
        return contentOptions;
    }

    render() {
        const { dashboardColumnOptions, userRole,location } = this.props;
        return ( 
            <div className="full-width">
                {this.props.allPPRtab === true && dashboardColumnOptions &&
                    (<div className={`col-12 float-left p-0 created-table1`}>
                        <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.pprcreatedList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                        </AWSTable>
                        </div>
                    </div>)
                }
                {this.props.submittedPPRtab === true && dashboardColumnOptions &&
                (
                    <div className={`col-12 float-left p-0 created-table`}>
                        <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.submittedList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                        </AWSTable>
                        </div>
                    </div>
                )}
                {this.props.salesManagertab === true && dashboardColumnOptions &&
                (
                    <div className={`col-12 float-left p-0 created-table`}>
                        <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.pendingSMList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                        </AWSTable>
                        </div>
                    </div>
                )}
                {this.props.customerTermstab === true && dashboardColumnOptions && (
                <div className={`col-12 float-left p-0 created-table`}>
                    <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.pendingCusTermsList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                    </AWSTable>
                    </div>
                </div>
                )}
                {this.props.openForReviewtab === true && dashboardColumnOptions && (
                <div className={`col-12 float-left p-0 created-table`}>
                    <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.openForReviewList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                    </AWSTable>
                    </div>
                </div>
                )}
                {this.props.sceOwnertab === true && dashboardColumnOptions && (
                <div className={`col-12 float-left p-0 created-table`}>
                    <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.pendingExecList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                    </AWSTable>
                    </div>
                </div>
                )}
                {this.props.pendingLegaltab === true && dashboardColumnOptions && (
                <div className={`col-12 float-left p-0 created-table`}>
                    <div className="table-wraper dashboard-table">
                        <AWSTable
                            columns={this.getColumnDefinitions()}
                            options={this.getColumnOptions(dashboardColumnOptions)}
                            items={this.props.pendingLegalList}
                            sortableColumnOptions={AWSTableConstants.sortableColumnOptions}
                            onContentSelectionChange={(e) =>this.props.onContentSelectionChange(e)}
                            userRole={this.props.location.state &&this.props.location.state.userRole}
                        >
                    </AWSTable>
                    </div>
                </div>
                )}
            </div> 
        );
    }
}

export default AWSDashboardData;