import React, { Component } from "react";
import "./AWSLogin.scss";
import AWSButton from "../common/AWSbutton/AWSbutton";
// import Logo from "../../images/AWS_logo.svg";
import Logo from "../../images/loginlogo.png";
import { getToken } from "../../services/Oauth/Oauth";
import bgImg from "../Login/woman-writing-on-a-notebook-beside-teacup-and-tablet-733856.jpg";
import accessLock from "../../images/accessLock.svg";
import accessLockHover from "../../images/accessLockHover.svg";
// import { AppProvider, TextField, Icon } from "@shopify/polaris";
// import enTranslations from "@shopify/polaris/locales/en.json";
// import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
// import {
//   CustomersMajorMonotone,
//   LockMajorMonotone
// } from "@shopify/polaris-icons";
import { Input } from "@amzn/awsui-components-react";

import axios from "axios";
import AWSconfig from "../../config/AWSconfig";
import Auth from "../../Auth/Auth";

const auth = new Auth();
const config = new AWSconfig();
export default class AWSLogin extends Component {
  state = {
    username: "",
    password: "",
    error: null,
    activateToast: false,
    loadingMessage: "Logging in ...",
    loadingError: false,
    welcomeMessage: false,
    loginMethod: "sso"
  };
  mouseOver = () => this.setState({ hover: true });
  mouseOut = () => this.setState({ hover: false });
  componentDidMount() {

    if (/code/.test(this.props.location.search)) {
      this.setState({
        welcomeMessage: true
      });
    } else {
      this.setState({
        welcomeMessage: false
      });
    }
  }
  oktaLogin = () => {
    auth.authLogin();
  };

  parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  getSAMLError = () => {
    const samlError = this.parseQuery(this.props.location.search)["error_description"];
    if (samlError) {
      return samlError.replaceAll("+", " ");
    }
  }

  isSAMLError = () => {
    const samlErrorRegex = /Error\+in\+SAML/;
    return samlErrorRegex.test(this.props.location.search);
  }

  renderLoginContent = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.unAuthorized === true) {
      return (
        <div className="full-width">
          <div className="midway pt-4">
              <div className="full-width pt-4 font-size14 grey welcome-msg">
                Sorry, you don’t have access. Please click on ‘Request Access’, and submit a SIM Ticket
              </div>
          </div>
          <div className="midway pt-4">
              <div className="col-12 text-center pt-4"
                  onMouseOver={this.mouseOver}
                  onMouseOut={this.mouseOut}
                >
                  <a href={config.ticketUrl} className="pl-0">Request Access<img src={this.state.hover ? accessLockHover : accessLock} className="ml-2 " alt="accessLock" /></a>
                </div>
          </div>
        </div>
      );
    } else if (this.state.loginMethod === "classic") {
      return (
        <div className="full-width">
          <div className="clasic">
          </div>
        </div>
      );
    } else {
      return (
        <div className="full-width">
          <div className="midway pt-4">
          {this.state.welcomeMessage === true ?
            <div className="full-width pt-4 font-size14 grey welcome-msg">
              Validating the details and loading your personalised dashboard...
            </div>
            :
            <div className="full-width">
              <div className="col-12 text-center">
              {this.isSAMLError() && (
                <>
                  <div className="col-12 text-center red">
                    A SAML error has occurred.
                    <br />
                    {this.getSAMLError()}
                  </div>
                  <div className="pt-4 text-center">
                    <a href={config.logTicketUrl} className="pl-0" target="_blank" rel="noopener noreferrer">SIM Ticketing</a>
                  </div>
                  <br />
                </>
              )}
                <AWSButton
                  label={"AWS Single Sign-On"}
                  isIcon={false}
                  btnType={"unfill"}
                  onClick={this.oktaLogin}
                />
                {/* <div onClick={()=>this.setState({loginMethod: "classic"})} className="mt-3 another-login">Log In with different method</div> */}
              </div>
              {/* <div className="pt-4 pl-5">
                {/* To request access please create a ticket
                  <a href={config.ticketUrl} className="pl-2">here</a> */}
              {/* <a href={config.ticketUrl} className="pl-2">Request Access<img src={this.state.hover ? accessLockHover : accessLock} className="ml-2 " alt="accessLock" /></a> */}

              {/* </div> */}
              <div className="pt-4 text-center"
                onMouseOver={this.mouseOver}
                onMouseOut={this.mouseOut}
              >
                {/*  To request access please create a ticket
                  <a href={config.ticketUrl} className="pl-2">here</a>
                  */}
                <a href={config.ticketUrl} className="pl-0">Request Access<img src={this.state.hover ? accessLockHover : accessLock} className="ml-2 " alt="accessLock" /></a>
              </div>
            </div>
          }
        </div>
      </div>
      );
    }
  }

  render() {
    return (
      <div
        className="bg-img container-fluid"
        style={{ padding: 0, width: "100%" }}
      >
        {/* <AppProvider i18n={enTranslations}> */}
        <div
          className="col-lg-6"
          style={{
            backgroundImage: `url(${bgImg})`,
            height: "100vh",
            width: "50%",
            float: "left",
            backgroundSize: "cover"
          }}
        >
        </div>
        <div className="col-lg-6" style={{ width: "50%", float: "left" }}>
          <div className="login-wraper">
            <div className="logo mb-0">
              <div className="logo-container">
                <span>
                  <img src={Logo} alt="logo" className="img-logo" />
                </span>
              </div>
            </div>
            {this.renderLoginContent()}
          {/* {this.state.activateToast && (
              <AWSLoadingToast
                message={this.state.loadingMessage}
                error={this.state.loadingError}
                duration={this.state.loadingMessageDuration}
              />
            )} */}
          </div>
        </div>
        {/* </AppProvider> */}
      </div>
    );
  }
}