import React  from 'react';
import axios from 'axios';
import AWSDropdown from "../../common/AWSDropdown/AWSDropdown";
import AWSTable from "../AWSAdminTable/AWSAdminTable";
import AWSConfig from '../../../config/AWSconfig';
import AWSModal from '../AWSModal/AWSModal';
import './watchilst.scss'

const config = new AWSConfig();

const EagleWatchlist = [
    {
        optionValue: "Eagle Watchlist",
        optionKey: "Eagle Watchlist",
        defaultValue: null,
    },
    {
        optionValue: "Option 2",
        optionKey: "Option 2",
        defaultValue: null,
    },
]
class Watchlist extends React.Component {
    state = {
        value: "",
        tableData: [],
        modalType: '',
        visible: false,
        EagleWatchlist: "Dropdown",
    }
    componentDidMount() {
        this.loadCustomDealAPi()
    }
    /* load custom Deal API */
    loadCustomDealAPi = () => {
        let standardDeal = `${config.amazonFalconbaseUrl}deal/getDeal?dealType=custom`;
        axios({
            method: "GET",
            url: standardDeal
        })
            .then(resp => {
                this.setState({
                    tableData: resp.data.Items
                });

            }).catch(err => console.log(err));
    }
    /*show modal */
    showModal = (modalType) => {
        this.setState({
            visible: true,
            modalType: modalType
        })
    }
    /*hide the modal*/
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleWatchlistChange = (value) => {
        this.setState({
            EagleWatchlist: value
        })
    }

    render() {
        let userColumns = [
            { title: "Customer Legal Name", field: "customerlegalname", sorter: "string" },
            {
                title: "", field: "custom", align: "center",
                columns: [
                    {
                        title: "", align: "center",
                        width: 30,
                        formatter: () => {
                            return `<div class='text-center'><span class='mr-3 delete-icon icons-styles'></div>`
                        },
                    }
                ],
            }
        ];
        return (
            <div>

                <div className="full-width">
                    <div className="col-6 float-left pl-0">
                        <div className="col-6 float-left p-0">
                            {<AWSDropdown
                                id="Dropdown"
                                label="Type of watchlist"
                                options={EagleWatchlist}
                                value={this.state.EagleWatchlist}
                                defaultValue="Eagle Watchlist"
                                handleOnSelect={(value) => this.handleWatchlistChange(value)}
                            />}
                        </div>

                    </div>

                    <div className="col-6 float-left pr-0">
                        <div className="float-right">
                            <div className="width-auto float-left pr-4 cursor-pointer">
                                <p><span className="edit-icon float-left pr-2">
                                    </span> Edit Watchlist</p>
                            </div>
                            <div className="width-auto float-left cursor-pointer" onClick={() => { this.showModal("newWatchlist") }}>
                                <p><span className="plus-icon float-left pr-2 ">
                                    </span> New Watchlist</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="full-width watchlist-table pt-4">
                    <AWSTable
                        columns={userColumns}
                        tableData={this.state.tableData}
                    />
                </div>
                <div className="full-width">
                    <AWSModal
                        type={"newWatchList"}
                        visible={this.state.visible}
                        handleCancel={() => { this.handleCancel() }}
                    />

                </div>
            </div>


        );
    }
}
export default Watchlist;
