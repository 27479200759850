import axios from 'axios';

let apiCall = (method,url,data) => {
    return axios({
        method,
        url,
        data
    })
       
}


export default apiCall;