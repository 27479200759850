import React from "react";
import AdditionalList from "../Configurations/AdditionalList";
import Watchlist from "../Configurations/Watchlist";
import ABT from "../Configurations/Abt";
class Configurations extends React.Component {

    showUserManagementLoadingToast = (message, toast) => {
        this.props.showUserManagementLoadingToast(message, toast);
    }
    render() {
        const { selectedStage, location } = this.props;
        return (
            <div className="full-width">
                {/* {console.log(selectedStage, "==selectedStage")} */}
                {/* {selectedStage === 0 &&
                    <Watchlist
                        showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                    />
                } */}
                {selectedStage === 0 &&
                    <AdditionalList
                        location={location}
                        showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                    />
                }
                {/* {selectedStage === 2 &&
                    <ABT
                        showUserManagementLoadingToast={(message, activeState) => this.showUserManagementLoadingToast(message, activeState)}
                    />
                } */}


            </div>

        );
    }
}

export default Configurations;
