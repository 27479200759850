import React, { Component } from 'react';
import './Leftnavigation.scss';
import arrow from '../../../images/arrow.svg';
import LeftNavigationCustomerInfo from './LeftNavigationCustomerInfo';
import LeftNavigationCommon from './LeftNavigationCommon';
import LeftNavTerminationSummary from './LeftNavTermiantionSummary'
import LeftNavTab from './LeftNavTab';
import PropTypes from 'prop-types';

// const pt = {
//     navMenu: PropTypes.array,
//     isExpand: PropTypes.bool,
//     isForm: PropTypes.bool,
//     stage: PropTypes.number
// }

class Leftnavigation extends Component {

    constructor(props) {
        super(props)
    }

    /**
     * this function will display summary/close label in left navigation
     */
    displaySummaryLabel = () => {
        let { isExpand } = this.props;
        return (
            <>
                {isExpand && (
                    <div className="close-arrow-dot-margin">
                    </div>
                )}
                {isExpand ?
                    (""
                    ) : <div className="summary-txt padding-right20" onClick={() => this.props.handleOnClick()}>Summary
                        <span className="arrow-img"><img src={arrow} alt="arrow" /></span></div>
                }
            </>
        )
    }

    render() {
        let { isExpand, navMenu, stage, isForm, rateCardData, terminationPage, changeInvalue, leftNavIndex, dealIdentifier, terminationSummaryData, dealAction } = this.props;

        return (
            <div className="full-width">
                <div className={isExpand ? 'nav-wrapper  pb-3 pr-5 pl-0 pt-3 nav-wrapper-expand' : "nav-wrapper pt-3 pb-3 pr-0 pl-0"}>
                    <div className="full-width full-height">
                        <div className={isExpand ? "col-3 float-left border-right-grey p-0 full-height" : "col-12 float-left p-0"}>
                            {isForm === false ?
                                <LeftNavigationCommon
                                    stage={stage}
                                    heading={this.props.heading}
                                    navMenu={navMenu}
                                    leftNavIndex={leftNavIndex}
                                    terminationPage={terminationPage}
                                    leftNavRateCardData={this.props.leftNavRateCardData}
                                    handleLefNavClick={(val) => this.props.handleLefNavClick(val)}
                                /> : <LeftNavigationCustomerInfo
                                    isExpand={isExpand}
                                    neglectCommitAndPricing={this.props.neglectCommitAndPricing}
                                    changeInvalue={changeInvalue}
                                    stage={stage}
                                    dealInfo={this.props.dealInfo}
                                    navMenu={navMenu}
                                    leftNavRateCardData={this.props.leftNavRateCardData}
                                    onClick={(val) => this.props.handleNavigation(val)}
                                    dealIdentifier={this.props.dealIdentifier}
                                    additionalApprovalFlag={this.props.additionalApprovalFlag}
                                    userRole={this.props.userRole}
                                    additionalPricingTerms={this.props.additionalPricingTerms}
                                    adiDealTermFormGroup={this.props.adiDealTermFormGroup}
                                    renewalDealInfo={this.props.renewalDealInfo}
                                    selectedDealType={this.props.selectedDealType}
                                />}

                        </div>
                        {isExpand && (
                            <div className="col-9 float-left p-0 pl-2">
                                {
                                    <LeftNavTab
                                        accountManager={this.props.accountManager}
                                        accountName={this.props.accountName}
                                        dealInfo={this.props.dealInfo}
                                        pprType={this.props.pprType}
                                        neglectCommitAndPricing={this.props.neglectCommitAndPricing}
                                        dealIdentifier={this.props.dealIdentifier}
                                        tempArray={this.props.tempArray}
                                        customerLegalName={this.props.customerLegalName}
                                        canEditPPR={this.props.canEditPPR}
                                        triggerCommentValidation={this.props.triggerCommentValidation}
                                        pprId={this.props.pprId}
                                        showAdiDealTermInSummary={this.props.showAdiDealTermInSummary}
                                        terminationSummaryData={terminationSummaryData}
                                        terminationPage={terminationPage}
                                        summarCommentArrayObject={this.props.summarCommentArrayObject}
                                        salesRepName={this.props.salesRepName}
                                        locationStateUser={this.props.locationStateUser}
                                        dealStatus={this.props.dealStatus}
                                        userRole={this.props.userRole}
                                        ownerType={this.props.ownerType}
                                        dealAction={this.props.dealAction}
                                        summaryData={this.props.summaryData}
                                        rateCardData={rateCardData}
                                        pprData={navMenu}
                                        leftNavTierPricing={this.props.leftNavTierPricing}
                                        leftNavTierPricingTable={this.props.leftNavTierPricingTable}
                                        regionalLimitsTable={this.props.regionalLimitsTable}
                                        regionalTrafficLimitTable={this.props.regionalTrafficLimitTable}
                                        requestFeesTable={this.props.requestFeesTable}
                                        summaryComment={this.props.summaryComment}
                                        summaryCommentTimeStamp={this.props.summaryCommentTimeStamp}
                                        disableSubmitButton={this.props.disableSubmitButton}
                                        handleSummaryComment={(value) => this.props.handleSummaryComment(value)}
                                        handleSubmit={this.props.handleSubmit}
                                        showDealStructureLoadingToast={this.props.showDealStructureLoadingToast}
                                        terminationPage={terminationPage}
                                        additionalPricingTerms={this.props.additionalPricingTerms}
                                        adiDealTermFormGroup={this.props.adiDealTermFormGroup}
                                        enableCommentBox={this.props.enableCommentBox}
                                        combinedTraffic={this.props.combinedTraffic}
                                        createdById={this.props.createdById}
                                        shouldShowPTS={this.props.shouldShowPTS}
                                        selectedDealType={this.props.selectedDealType}
                                        renewalDealInfo={this.props.renewalDealInfo}
                                        handleOnClick={this.props.handleOnClick}
                                        commentObjectArrayFromCollabsTab={this.props.commentObjectArrayFromCollabsTab}
                                        commentArrayFromCollabTabs={this.props.commentArrayFromCollabTabs}
                                        affiliatedIdArray={this.props.affiliatedIdArray}
                                        qualifyingQuestionIds={this.props.qualifyingQuestionIds}
                                        originShieldQuestionIds={this.props.originShieldQuestionIds}
                                    />

                                }
                            </div>)}
                    </div>
                    <div className="close-arrow-dot-margin float-right">
                        {this.props.showSummary === false ? "" : this.displaySummaryLabel()}
                    </div>
                </div>
            </div>
        );
    }
}

// Leftnavigation.propTypes = pt;

export default Leftnavigation;
