import React from "react";
import AWSEmailNotification from "./AWSEmailNotification";
import AWSSLANotification from "./AWSSLANotification";
import PropTypes from "prop-types";

class AWSMngNotification extends React.Component {

    /**
       *  This function is called to show toast message based on the selection
       * @param {*} message which is a message to display 
       * @param {*} toast which is a boolean to show hide the message
      */
    showUserManagementLoadingToast = (message,toast) => {
        this.props.showUserManagementLoadingToast(message,toast);
    }

    render(){
        const { selectedStage } = this.props;
        return (
          <div>
            {
              selectedStage === 1 ? 
              <AWSSLANotification 
                  showUserManagementLoadingToast={(message,activeState) => this.showUserManagementLoadingToast(message,activeState)}
              /> : 
              <AWSEmailNotification 
                  showUserManagementLoadingToast={(message,activeState) => this.showUserManagementLoadingToast(message,activeState)}
              />
            }
          </div>
        );
    }
}

export default AWSMngNotification;

AWSMngNotification.propTypes = {
  selectedStage: PropTypes.any,
  showUserManagementLoadingToast: PropTypes.func,
};
