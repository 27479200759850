import React from "react";
import { Icon, Popover } from "@amzn/awsui-components-react";
import AWScheckbox from "../../common/AWScheckbox/AWScheckbox";
import * as Constants from "../../../constants/dealStructureConstants";

export class SaveEnteredRatesComponent extends React.Component {
  render() {
    return (
      <div>
        <span className="pb-1 ml-5 float-left pt-3" >
          <AWScheckbox
            label={"Save Entered Rates"}
            checked={this.props.value}
            onChangeFunc={this.props.onChangeFunc}
            userRole={this.props.userRole}
            canEditPPR={this.props.canEditPPR}
          />
        </span>
        <span>
          <Popover content={Constants.infoText.saveEnteredRates}
            preferredPosition="above" size="small" position="top">
            <span className="float-right circle-alert pt-3 mr-3">
              <Icon name="status-info" />
            </span>
          </Popover>
        </span>
      </div>
    );
  }
}