import { isNil } from "lodash";

export const getRateCardApiBody = (
  requestedCommit,
  cfrlType,
  commitType,
  aosSelected,
  termLength,
  commitFee,
  volumeUnit,
  geoIsAll,
  geoLocations,
  isCloudFrontShieldAdvanced,
  cloudFrontShieldAdvancedType
) => {
  const _aosSelected = parseInt(aosSelected, 10);
  const _termLength = parseInt(termLength, 10);
  const _commitFee = parseInt(parseCommitFee(commitFee), 10);
  const data = [];

  if (geoIsAll) {
    const _requestedCommit = calculateRequestedCommit(
      requestedCommit,
      volumeUnit
    );
    if (requestedCommit > 0) {
      data.push(
        constructRateCardApiObject(
          commitType,
          cfrlType,
          _aosSelected,
          _termLength,
          "ALL",
          _requestedCommit,
          _commitFee,
          isCloudFrontShieldAdvanced,
          cloudFrontShieldAdvancedType
        )
      );
    }
  } else {
    geoLocations.forEach((geoLocation) => {
      const geoCommit = geoLocation.optionValue;
      if (geoCommit > 0) {
        const _requestedCommit = calculateRequestedCommit(
          geoLocation.optionValue,
          volumeUnit
        );
        if (requestedCommit > 0) {
          data.push(
            constructRateCardApiObject(
              commitType,
              cfrlType,
              _aosSelected,
              _termLength,
              geoLocation.optionKey,
              _requestedCommit,
              _commitFee,
              isCloudFrontShieldAdvanced,
              cloudFrontShieldAdvancedType
            )
          );
        }
      }
    });
  }

  return data;
};

export const getUpdatedStateFromRateCardApi = (
  rateCardData,
  standardRateCard,
  saveEnteredRates,
  minimumCommitmentRevenue,
  dealEdited,
  defaultRateCardRates,
  dataTransferPriceNotTiered,
  dataTransferPriceTiered,
  isCloudFrontShieldAdvanced
) => {
  const updatedRateCardState = {
    dealEdited,
    defaultRateCardRates,
    minimum_commitment_fee: minimumCommitmentRevenue,
    standardMinimumRevenue: minimumCommitmentRevenue,
    total_commitment_fee: minimumCommitmentRevenue,
    totalExpError: "",
    dataTransfertableError: "",
  };

  if (!saveEnteredRates) {
    const _dataTransferPriceNotTiered = dataTransferPriceNotTiered.map(
      (tier) => {
        return {
          cells: tier.cells.map((cell) => ({
            ...cell,
            value: standardRateCard[cell.tierKey] || "",
            isEdited: false,
          })),
        };
      }
    );
    const _dataTransferPriceTiered = dataTransferPriceTiered.map((tier) => {
      return {
        cells: tier.cells.map((cell) => ({
          ...cell,
          value: "",
          isEdited: false,
        })),
      };
    });
    let defaultRateError = "";
    if (isCloudFrontShieldAdvanced && rateCardData && rateCardData.length === 0) {
      defaultRateError = "Pre-approved rates not available.";
    } else if (rateCardData && rateCardData.length === 0) {
      defaultRateError = "Pre-approved rates not available. Please enter the rates manually";
    }
    Object.assign(updatedRateCardState, {
      dataTransferPriceNotTiered: _dataTransferPriceNotTiered,
      dataTransferPriceTiered: _dataTransferPriceTiered,
      defaultRateError,
      tieredPricing: false,
    });
  }

  return updatedRateCardState;
};

export const isDealEdited = (
  saveEnteredRates,
  tieredPricing,
  dataTransferPriceNotTiered,
  standardRateCard
) => {
  let dealEdited = false;
  if (saveEnteredRates && tieredPricing !== true) {
    dealEdited = dataTransferPriceNotTiered.reduce((prev, cells) => {
      const val = cells.cells.reduce((prev, current) => {
        const standard = standardRateCard[current.tierKey];
        if (standard === undefined) {
          return prev || false;
        }
        const condition =
          parseFloat(current.value) < standardRateCard[current.tierKey];
        return prev || condition;
      }, false);
      return prev || val;
    }, false);
  }
  return dealEdited;
};

export const parseCommitFee = (commitFee) => {
  return commitFee.toString().replace(/,/g, "");
};

export const calculateRequestedCommit = (requestedCommit, volumeUnit) => {
  const isUnitPB = volumeUnit === "PB";
  return isNil(requestedCommit)
    ? 0
    : parseFloat(requestedCommit) * (isUnitPB ? 1024 : 1);
};

export const constructRateCardApiObject = (
  commitType,
  cfrlType,
  aosSelected,
  termLength,
  geo,
  requestedCommit,
  commitFee,
  isCloudFrontShieldAdvanced,
  cloudFrontShieldAdvancedType
) => {
  return {
    TypeOfCommit: commitType,
    TypeOfCFRL: cfrlType,
    Aos_Selected: aosSelected,
    TermLength: termLength,
    requested_commit: requestedCommit,
    geo: geo,
    commitPrice: commitFee,
    Istier: false,
    isCloudFrontShieldAdvanced,
    cloudFrontShieldAdvancedType,
  };
};

export const parseRevenueFromApi = (
  isVolumeCommit,
  minimumCommitmentRevenueFromApi,
  commitmentFeeFromState
) => {
  if (!isNil(minimumCommitmentRevenueFromApi) && isVolumeCommit) {
    return Math.round(parseFloat(minimumCommitmentRevenueFromApi))
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  } else {
    return commitmentFeeFromState;
  }
};
