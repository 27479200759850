import React, { Component } from "react";
import AWSheader from "../common/AWSheader/AWSheader";
import Leftnavigation from "../common/LeftNav/Leftnavigation";
import Controls from "./Controls";
// import CommentSection from "./CommentSection";
import AWSbutton from "../common/AWSbutton/AWSbutton";
import summaryData from "../../json/summaryData";
import DealStructure from "./DealStructure";
import _ from "underscore";
import axios from "axios";
import { Icon } from "@amzn/awsui-components-react";
import summaryTable from "../../json/summaryTable.json";
import DynamicRenderComponent from "../Dashboard/DynamicRendercomponent";
import {
  getPPRForm,
  getSalesManagerData,
  fetchDataFromAccountNameOrAccountId,
  saveOrSubmitPPRForm,
  emailNotification,
  getPPRCreatedById,
} from "../../services/endPoints";
import AWSLoadingToast from "../common/AWSLoadingToast/AWSLoadingToast";
import AWSConfig from "../../config/AWSconfig";
import { cloneDeep, isNil } from "lodash";
import { isAfterOrgChange, isValidJson } from "../../utils/common";
import AWSModal from "../common/AWSModal/AWSModal";
import RateCardDataJson from "../../json/ratecard.json";
import { deal } from "../../constants/dealStructureConstants";
import * as roleNames from "../../constants/roleNames.const";
import * as actionName from "../../constants/actionButtonConstants";
import * as dealStatusConstant from "../../constants/dealStatusConstant";
import * as qualifyingQuestionsConstants from "../../constants/qualifyingQuestionsConstants";
import * as tagNameConstant from "../../constants/tagNameConstant";
import {
  CLOUD_FRONT_SHIELD_ADVANCED_DEALS,
  CLOUD_FRONT_SHIELD_ADVANCED_QUESTION_LABEL,
  CLOUD_FRONT_SHIELD_DEAL_INFO_TAG,
  isCloudFrontShieldAdvancedDeal,
  getCloudFrontShieldAdvancedAosValue
} from "../../constants/cloudFrontShieldAdvancedConstants";
import videoIcon from "../../images/Play.png";
import ErrorPage from "../PageNotFound/ServerError";
import VideoIcon from "../common/Icon/VideoIcon/VideoIcon";
import { clickMetrics } from "../../metrics/metrics.common";
import { getTokenBasedOnRole } from "../../utils/common";
import { 
  OLD_BUSINESS_UNIT_ANSWER_LABELS, 
  isCOBusinessUnit, 
  isChinaBusinessUnit, 
  isPSBusinessUnit, 
  OLD_GEO_CHINA,
  NEW_GEO_CHINA,
  ACCOUNT_TYPE, 
  shouldUpdateAGSVertical,
  shouldUpdateGCRVertical,
  shouldUpdatePSVertical,
  getCustomerType,
  BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS
} from "../../constants/questionAnswerLabelConstants";
import orgChangeBusinessUnitOptionsAWSDropdown from "../../json/orgChangeBusinessUnitOptionsAWSDropdown.json"
import businessUnitOption from "../../json/businessUnitOption.json";
import { doesValueExistWithinOptions } from "./utils/formUtils";
const config = new AWSConfig();
const DiscountTermEffectiveDateLabel = "Discount Term Effective Date";
const payerIdType = "aws_account_id";
const accountNameType = "sfdc_account_name";
const scrollToClassName = "main-container";
const commitAndPricingPageHeader = "Commit & Pricing";
let commitAndPricingStageTriggered = false;
let baseUrl;
let baseUrlForUser = `${config.userManagementbaseUrl}`;

let prevAutoPopulateAccountData = [];
export default class Form extends Component {
  state = {
    displayErrorPage: false,
    commentCollabState: [],
    ModalUnLinkedIdType: false,
    unLinkedAlertType: "",
    isExpand: false,
    displayPtsButton: true,
    disableSubmitButton: true,
    dynamicSalesManagerOptions: [],
    auditVersionId: null,
    summaryData: {},
    summaryTable: {},
    sMDataModel: null,
    summaryComment: null,
    termEndDate: "",
    termLength: "",
    currentDiscountTermDate: "",
    userRoleInfo: null,
    duplicateErrFlag: false,
    renewalDealInfo: "",
    stage: 0,
    date: "",
    dealInfo: {
      rateCard: null,
    },
    summaryDataUpdated: false,
    visible: false,
    changeInvalue: true,
    payerId: "",
    pprName: null,
    combinedTraffic: 0,
    neglectCommitAndPricing: false,
    summaryCommentTimeStamp: "",
    tempArray: [],
    dashboardPayerId: [],
    triggeredNext: false,
    allowSubmit: true,
    leftNavTierPricing: "",
    leftNavTierPricingTable: "",
    regionalLimitsTable: "",
    regionalTrafficLimitTable: "",
    requestFeesTable: "",
    leftNavRateCardData: undefined,
    accountManagerName: null,
    accountName: null,
    loadingError: false,
    activateToast: false,
    loadingMessageDuration: undefined,
    loadingMessage: "",
    newAwsData: [],
    dataModel: [],
    dealAction: [],
    dealIdentifier: [],
    customerLegalName: null,
    dealStatus: null,
    cloudFrontSalesManager: null,
    iGtOwner: null,
    triggerCommentValidation: false,
    selectedDealType: "",
    duplicateValidation: false,
    userRole: "",
    additionalPricingTerms: "",
    showAdiDealTermInSummary: false,
    adiDealTermFormGroup: [],
    enableCommentBox: true,
    autoPopulateAccountData: [],
    canEditPPR: true,
    showPTS: false,
    geo: "",
    businessUnit: "",
    buisnessJustification: null,
    autoPopulateType: null,
    videoPopup: false,
    affiliatedIdArray: [],
    qualifyingQuestionIds: [],
    originShieldQuestionIds: [],
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    baseUrl = config.amazonFalconbaseUrl;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.pprId !== this.props.match.params.pprId) {
      this.onComponentRendered();
    }
  }

  /**
   * Api call to load ppr and set json to local state
   */
  componentDidMount() {
    commitAndPricingStageTriggered = false;
    if (
      this.props.location.state &&
      this.props.location.state.userRole !== "Admin"
    ) {
      this.onComponentRendered();
    } else {
      localStorage.clear();
      this.props.history.push("/");
    }
  }

  onComponentRendered = (headerData, type) => {
    let cloneData = null;
    let pprFormType = null;
    let url;
    const isSameCustomer =
      this.props.match.params.isSameCustomer === "yes" ? true : false;
    const dealStatus = this.props.match.params.dealStatus;
    let userLoginRole =
      this.props.location.state && this.props.location.state.userRole;
    let loginAlias =
      this.props.location.state && this.props.location.state.alias;
    if (type === "audit") {
      url = "cfppr/audit";
      pprFormType = type;
      cloneData = headerData;
      this.setState({
        // stage: 0
      });
    } else if (type === "refreshOnId") {
      let userRoleAlias =
        this.props.location.state && this.props.location.state.userRoleAlias;
      if (userRoleAlias) {
        url =
          "cfppr/" +
          "ppr" +
          "?pprId=" +
          "PPR-" +
          this.state.pprName +
          "&AWSAlias=" +
          userRoleAlias +
          "&userRole=" +
          roleNames.SALES_REPRESENTATIVE +
          "&loginRole=" +
          userLoginRole +
          "&loginAlias=" +
          loginAlias;
      } else {
        url = "";
        this.getPprCreatedId(this.state.pprName);
      }
    } else {
      if (this.props.match.params.pprId) {
        let userRoleAlias =
          this.props.location.state && this.props.location.state.userRoleAlias;
        if (this.props.match.params.type === "clone") {
          pprFormType = "clone";
          cloneData = {
            role:
              this.props.location.state && this.props.location.state.userRole,
            user: this.props.location.state && this.props.location.state.alias,
            pprID: this.props.match.params.pprId,
            status: dealStatus,
            isSameCustomer: isSameCustomer,
          };
        } else {
          if (userRoleAlias) {
            pprFormType = "edit";
            url =
              "cfppr/" +
              "ppr" +
              "?pprId=" +
              this.props.match.params.pprId +
              "&AWSAlias=" +
              userRoleAlias +
              "&userRole=" +
              roleNames.SALES_REPRESENTATIVE +
              "&loginRole=" +
              userLoginRole +
              "&loginAlias=" +
              loginAlias;
          } else {
            url = "";
            this.getPprCreatedId(this.props.match.params.pprId);
          }
        }
      } else {
        pprFormType = "new";
        let userAlias =
          this.props.location.state && this.props.location.state.alias;
        url = "cfppr/" + "userppr" + "?AWSAlias=" + userAlias;
      }
    }

    let SummaryJsonData = cloneDeep(summaryData);
    let rateCardData = this.state.rateCardData;
    this.setState({
      summaryData: {
        ...SummaryJsonData,
        "Payer Id": null,
      },
      // rateCardData: null,
      summaryTable: summaryTable,
      activateToast: true,
      loadingMessage: "Loading...",
      loadingError: false,
      userRole: this.props.location.state && this.props.location.state.userRole,
      firstname:
        this.props.location.state && this.props.location.state.firstName,
      lastname: this.props.location.state && this.props.location.state.lastName,
      displayErrorPage: false,
      isExpand: false,
    });

    // call api to get form data
    // axios({
    //     method: (this.props.match.params.type === "clone" || type === 'audit') ? "POST" : "GET",
    //     url: url,
    //     data: cloneData
    // })
    if (type === "refreshOnId" && this.props.match.params.pprId && url !== "") {
      getPPRForm(url, cloneData, pprFormType)
        .then((resp) => {
          this.setState(
            {
              dataModel: resp.data.pageStages,
              pprName: resp.data.dealInfo.pprName,
              sMDataModel: resp.data.pageStages,
              rateCardData: resp.data.dealInfo.rateCard,
              dealInfo: resp.data.dealInfo,
              dealAction: resp.data.dealInfo.dealAction,
              summaryCommentTimeStamp:
                resp.data.dealInfo.summaryComment &&
                resp.data.dealInfo.summaryComment.timestamp,
              activateToast: false,
              dealStatus: resp.data.dealInfo.dealStatus,
              ownerType: resp.data.dealInfo.ownerType,
              userRoleInfo: resp.data.userRole,
              leftNavRateCardData: resp.data.dealInfo.rateCard,
              dealIdentifier: resp.data.dealInfo.dealIdentifier,
              additionalApprovalFlag: resp.data.dealInfo.additionalApprovalFlag,
              auditVersionId: resp.data.dealInfo.userId,
              canEditPPR: resp.data.canEditPPR,
              showPTS: resp.data.showPTS,
              neglectCommitAndPricing:
                resp.data.dealInfo.neglectCommitAndPricing,
            },
            () => {
              // Find additional qualifying question section
              const dealDetailsStage = this.state.dataModel.find(
                (stage) => stage.stageName === "Deal Description"
              );
              const qualifyingQuestionsSection = dealDetailsStage
                ? dealDetailsStage.queBlock.find(
                    (section) =>
                      section.queBlkHeader === "Additional Qualifying Questions"
                  )
                : undefined;
              const qualifyingQuestionsRows = qualifyingQuestionsSection
                ? qualifyingQuestionsSection.rows
                : undefined;
              if (qualifyingQuestionsRows) {
                const qualifyingQuestionIds = [];
                const originShieldQuestionIds = [];
                qualifyingQuestionsRows.forEach((row) => {
                  row.questions.forEach((question) => {
                    if (
                      question.queLabel ===
                      qualifyingQuestionsConstants.originShieldQuestionLabel
                    ) {
                      qualifyingQuestionIds.push(question.queId);
                      question.dependentOn.forEach((originShieldQuestion) => {
                        !originShieldQuestionIds.includes(
                          originShieldQuestion.dependentId
                        ) &&
                          originShieldQuestionIds.push(
                            originShieldQuestion.dependentId
                          );
                      });
                    } else if (
                      !qualifyingQuestionIds.includes(question.queId)
                    ) {
                      qualifyingQuestionIds.push(question.queId);
                    }
                  });
                });
                // Set ids for qualifying and origin shield questions for lookup later
                const filteredQualifyingQuestionIds =
                  qualifyingQuestionIds.filter(
                    (id) => !originShieldQuestionIds.includes(id)
                  );
                this.setState(
                  {
                    qualifyingQuestionIds: filteredQualifyingQuestionIds,
                    originShieldQuestionIds: originShieldQuestionIds,
                  },
                  () => {
                    this.setQualifyingQuestionSavedValues(
                      qualifyingQuestionsRows,
                      filteredQualifyingQuestionIds,
                      originShieldQuestionIds
                    );
                  }
                );
              }

              // call api to get sales manager option dynamically
              this.salesManagerOptions(resp.data.dealInfo);

              let sMDataModelClone = JSON.parse(
                JSON.stringify(this.state.dataModel)
              );

              this.setState({
                sMDataModel: sMDataModelClone,
              });

              let rateCardCloneVal = JSON.parse(
                JSON.stringify(this.state.rateCardData)
              );
              this.setState({
                rateCardClone: rateCardCloneVal,
                dealIdentifierClone: resp.data.dealInfo.dealIdentifier,
                additionalApprovalFlagClone:
                  resp.data.dealInfo.additionalApprovalFlag,
              });

              const userRoleForSummaryExpand = [
                roleNames.SALES_MANAGER,
                roleNames.BILLING_TEAM,
                roleNames.SCE_OWNER,
                roleNames.IGT_OWNER,
                roleNames.PRIVATE_PRICING_CHINA,
                roleNames.PRIVATE_PRICING_PUBLIC_SECTOR,
              ];
              userRoleForSummaryExpand.includes(userLoginRole) === true &&
                this.expandSummaryByDefault(userLoginRole, type);
              this.checkForAdditionalDealTerm();
              this.setRateCardSavedValues();
              this.callApiPPRCreatedForAutoSave();
              this.callApiOnTimeInterVal();
              this.makeByDefaultMaterialChangeAsFalse();
            }
          );
        })
        .catch((error) => {
          this.setToastState("Server error", 6000, true);
        });
    } else if (type !== "refreshOnId" && url !== "") {
      getPPRForm(url, cloneData, pprFormType)
        .then((resp) => {
          this.setState(
            {
              dataModel: resp.data.pageStages,
              pprName: resp.data.dealInfo.pprName,
              sMDataModel: resp.data.pageStages,
              rateCardData: resp.data.dealInfo.rateCard,
              dealInfo: resp.data.dealInfo,
              dealAction: resp.data.dealInfo.dealAction,
              summaryCommentTimeStamp:
                resp.data.dealInfo.summaryComment &&
                resp.data.dealInfo.summaryComment.timestamp,
              // activateToast: false,
              dealStatus: resp.data.dealInfo.dealStatus,
              ownerType: resp.data.dealInfo.ownerType,
              userRoleInfo: resp.data.userRole,
              leftNavRateCardData: resp.data.dealInfo.rateCard,
              dealIdentifier: resp.data.dealInfo.dealIdentifier,
              additionalApprovalFlag: resp.data.dealInfo.additionalApprovalFlag,
              auditVersionId: resp.data.dealInfo.userId,
              canEditPPR: resp.data.canEditPPR,
              showPTS: resp.data.showPTS,
              neglectCommitAndPricing:
                resp.data.dealInfo.neglectCommitAndPricing,
            },
            () => {
              // Find additional qualifying question section
              const dealDetailsStage = this.state.dataModel.find(
                (stage) => stage.stageName === "Deal Description"
              );
              const qualifyingQuestionsSection = dealDetailsStage
                ? dealDetailsStage.queBlock.find(
                    (section) =>
                      section.queBlkHeader === "Additional Qualifying Questions"
                  )
                : undefined;
              const qualifyingQuestionsRows = qualifyingQuestionsSection
                ? qualifyingQuestionsSection.rows
                : undefined;
              if (qualifyingQuestionsRows) {
                const qualifyingQuestionIds = [];
                const originShieldQuestionIds = [];
                qualifyingQuestionsRows.forEach((row) => {
                  row.questions.forEach((question) => {
                    if (
                      question.queLabel ===
                      qualifyingQuestionsConstants.originShieldQuestionLabel
                    ) {
                      qualifyingQuestionIds.push(question.queId);
                      question.dependentOn.forEach((originShieldQuestion) => {
                        !originShieldQuestionIds.includes(
                          originShieldQuestion.dependentId
                        ) &&
                          originShieldQuestionIds.push(
                            originShieldQuestion.dependentId
                          );
                      });
                    } else if (
                      !qualifyingQuestionIds.includes(question.queId)
                    ) {
                      qualifyingQuestionIds.push(question.queId);
                    }
                  });
                });
                // Set ids for qualifying and origin shield questions for lookup later
                const filteredQualifyingQuestionIds =
                  qualifyingQuestionIds.filter(
                    (id) => !originShieldQuestionIds.includes(id)
                  );
                this.setState(
                  {
                    qualifyingQuestionIds: filteredQualifyingQuestionIds,
                    originShieldQuestionIds: originShieldQuestionIds,
                  },
                  () => {
                    if (pprFormType !== "new") {
                      this.setQualifyingQuestionSavedValues(
                        qualifyingQuestionsRows,
                        filteredQualifyingQuestionIds,
                        originShieldQuestionIds
                      );
                    }
                  }
                );
              }

              let sMDataModelClone = JSON.parse(
                JSON.stringify(this.state.dataModel)
              );
              if (type === "audit") {
                setTimeout(() => {
                  this.setState({
                    activateToast: false,
                  });
                }, 4000);
              } else {
                this.setState({
                  activateToast: false,
                });
              }
              this.setState({
                sMDataModel: sMDataModelClone,
              });

              let rateCardCloneVal = JSON.parse(
                JSON.stringify(this.state.rateCardData)
              );
              this.setState({
                rateCardClone: rateCardCloneVal,
                dealIdentifierClone: resp.data.dealInfo.dealIdentifier,
                additionalApprovalFlagClone:
                  resp.data.dealInfo.additionalApprovalFlag,
              });

              // call api to get sales manager option dynamically
              this.salesManagerOptions(resp.data.dealInfo);

              const userRoleForSummaryExpand = [
                roleNames.SALES_MANAGER,
                roleNames.BILLING_TEAM,
                roleNames.SCE_OWNER,
                roleNames.IGT_OWNER,
                roleNames.PRIVATE_PRICING_CHINA,
                roleNames.PRIVATE_PRICING_PUBLIC_SECTOR,
              ];
              userRoleForSummaryExpand.includes(userLoginRole) === true &&
                this.expandSummaryByDefault(userLoginRole, type);
              this.checkForAdditionalDealTerm();
              this.setRateCardSavedValues();
              this.callApiPPRCreatedForAutoSave();
              this.callApiOnTimeInterVal();
              this.makeByDefaultMaterialChangeAsFalse();
            }
          );
        })
        .catch((error) => {
          this.setToastState("Server error", 6000, true);
        });
    } else {
      this.setState({
        activateToast: false,
      });
    }
    this.handleSummaryData(this.props.match.params.pprId, "", "PprId");
  };

  getPprCreatedId = (pprId) => {
    const pprID = Number(pprId.split("-")[1]);
    const postData = { pprId: pprID };
    getPPRCreatedById(postData)
      .then((resp) => {
        if (resp.data.userId && resp.data.userId !== "") {
          let locationState = this.props.location && this.props.location.state;
          locationState.userRoleAlias = resp.data.userId;
          this.onComponentRendered();
        } else {
          this.callDashboardApi(this.props.location && this.props.location.state);
        }
      })
      .catch((error) => {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
          this.callDashboardApi(this.props.location && this.props.location.state);
        } else {
          this.setState(
            {
              activateToast: true,
              loadingMessage: "Server error",
              loadingError: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  activateToast: false,
                });
              }, 6000);
            }
          );
        }
      });
  };

  /**
   * Set the state to display a toast.
   * @param {string} toastMessage message to be displayed
   * @param {number} duration duration of the toast in milliseconds
   * @param {boolean} isError true if error
   */

  setToastState = (toastMessage, duration, isError) => {
    this.setState(
      {
        activateToast: true,
        loadingMessage: toastMessage,
        loadingError: isError,
        loadingMessageDuration: duration,
      }, 
      () => {
        setTimeout(() => {
          this.setState({
            activateToast: false,
            displayErrorPage: isError,
          });
        }, duration);
      }
    );
  };

  makeByDefaultMaterialChangeAsFalse = () => {
    let { dealInfo, userRole } = this.state;

    if (
      (dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input ||
        dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM) &&
      userRole === roleNames.SALES_REPRESENTATIVE
    ) {
      dealInfo.isMatChange = true;
    } else {
      if (
        dealInfo.dealStatus !== dealStatusConstant.PPR_Created &&
        userRole === roleNames.SALES_REPRESENTATIVE
      ) {
        if (dealInfo.isMatChange) {
          dealInfo.isMatChange = false;
        }
      }
    }
  };

  expandSummaryByDefault = (userLoginRole, type) => {
    if (type !== "audit") {
      let { dealInfo } = this.state;
      if (
        userLoginRole === roleNames.SALES_MANAGER &&
        dealInfo &&
        dealInfo.pprType === "Core"
      ) {
        this.setState({
          isExpand: true,
          disableSubmitButton: false,
        });
      } else if (
        userLoginRole === roleNames.SCE_OWNER &&
        dealInfo &&
        dealInfo.pprType === "Core"
      ) {
        this.setState({
          isExpand: true,
        });
      } else if (
        userLoginRole === roleNames.IGT_OWNER &&
        dealInfo &&
        dealInfo.pprType === "Core"
      ) {
        this.setState({
          isExpand: true,
        });
      } else if (
        userLoginRole === roleNames.PRIVATE_PRICING_CHINA &&
        dealInfo &&
        dealInfo.pprType === "Core"
      ) {
        this.setState({
          isExpand: true,
        });
      } else if (
        userLoginRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR &&
        dealInfo &&
        dealInfo.pprType === "Core"
      ) {
        this.setState({
          isExpand: true,
        });
      } else {
        this.setState({
          isExpand: false,
        });
      }
    }
  };

  /**
   * this function will call api to get sales manager alias dropdown options
   */

  salesManagerOptions = (dealInfo) => {
    let value = [];
    // let userRoleUrl = baseUrlForUser + "users/getuserRoles";
    const userRoleUrl = "users/getuserRoles";
    const awsAlias =
      dealInfo && dealInfo.createdById
        ? dealInfo.createdById
        : this.props.location.state && this.props.location.state.alias;

    const requestData = {
      userRole: roleNames.SALES_MANAGER,
      AWSAlias: awsAlias,
      action: "dealIntakeForm",
    };
    getSalesManagerData(userRoleUrl, requestData).then((resp) => {
      resp.data.Data.forEach((salesManagerData) => {
        value.push({
          optionKey: salesManagerData.AWSAlias,
          optionValue:
            salesManagerData.FirstName +
            " " +
            salesManagerData.LastName +
            " (" +
            salesManagerData.AWSAlias +
            ")",
          defaultValue: null,
        });
      });

      this.setState({
        dynamicSalesManagerOptions: value,
      });
    });
  };

  setQualifyingQuestionSavedValues = (
    qualifyingQuestionsRows,
    qualifyingQuestionIds,
    originShieldQuestionIds
  ) => {
    const { summaryData } = this.state;
    qualifyingQuestionsRows.forEach((row) => {
      const question = row.questions[0];
      if (question.queType === "RATETABLE") {
        summaryData[question.queId] = question;
      } else if (question.value) {
        if (qualifyingQuestionIds.includes(question.queId)) {
          summaryData.qualifyingQuestions[question.queId] = question;
        } else if (originShieldQuestionIds.includes(question.queId)) {
          summaryData.originShieldQuestions[question.queId] = question;
        }
      }
    });
    this.setState({
      summaryData,
    });
  };

  // this function will set Rate card values based on API
  setRateCardSavedValues = () => {
    let { dealInfo, userRole } = this.state;

    if (this.state.rateCardData) {
      let parsedData = JSON.parse(this.state.rateCardData);
      if (
        dealInfo.dealStatus !== dealStatusConstant.PPR_Created &&
        userRole === roleNames.SALES_REPRESENTATIVE
      ) {
        if (parsedData.isMaterialChange) {
          parsedData.isMaterialChange = false;
        }
      }
      dealInfo.rateCard = JSON.stringify(parsedData);
      this.setState({
        dealInfo,
      });

      let { summaryData } = this.state;
      this.state.summaryData["pricingTermsFlag"] = parsedData.pricingTerms;
      if (parsedData.VolumeCommitCheckBox[3].status === true) {
        if (parsedData.tieredPricing_noCommit === false) {
          let sumOfInsertedValue = 0;
          parsedData.dataTransferPriceNotTieredNoCommit.map((cells) => {
            cells.cells.map((data) => {
              if (data.value !== "") {
                sumOfInsertedValue =
                  sumOfInsertedValue + parseFloat(data.value);
              }
            });
          });
          if (sumOfInsertedValue > 0) {
            this.setState({
              leftNavTierPricingTable:
                parsedData.dataTransferPriceNotTieredNoCommit,
              leftNavTierPricing: "Nottiered",
            });
          } else {
            this.setState({
              leftNavTierPricing: "",
            });
          }
        } else if (
          parsedData.tieredPricing_noCommit &&
          parsedData.tieredPricing_noCommit === true
        ) {
          let sumOfInsertedValue = 0;
          parsedData.dataTransferPriceTieredNoCommit.map((cells) => {
            cells.cells.map((data) => {
              if (data.value !== "") {
                sumOfInsertedValue =
                  sumOfInsertedValue + parseFloat(data.value);
              }
            });
          });
          if (sumOfInsertedValue > 0) {
            this.setState({
              leftNavTierPricingTable:
                parsedData.dataTransferPriceTieredNoCommit,
              leftNavTierPricing: "tiered",
            });
          } else {
            this.setState({
              leftNavTierPricing: "",
            });
          }
        } else {
          this.setState({
            leftNavTierPricing: "",
          });
        }
      } else {
        if (parsedData.tieredPricing === false) {
          this.setState({
            leftNavTierPricingTable: parsedData.dataTransferPriceNotTiered,
            leftNavTierPricing: "Nottiered",
          });
        } else if (
          parsedData.tieredPricing &&
          parsedData.tieredPricing === true
        ) {
          this.setState({
            leftNavTierPricingTable: parsedData.dataTransferPriceTiered,
            leftNavTierPricing: "tiered",
          });
        } else {
          this.setState({
            leftNavTierPricingTable: parsedData.dataTransferPriceNotTiered,
            leftNavTierPricing: "Nottiered",
          });
        }
      }

      if (parsedData.regionalTraffic === "Yes") {
        this.setState({
          regionalTrafficLimitTable: parsedData.regionalTrafficLimit,
        });
      }

      if (
        parsedData.pricingTermsFlag === "Yes" ||
        parsedData.pricingTerms === "Yes"
      ) {
        summaryData.pricingTermsFlag = parsedData.pricingTerms;
        this.setState({
          additionalPricingTerms: parsedData.formGroup,
          summaryData,
        });
      }

      this.setState({
        regionalLimitsTable: parsedData.regionalUsageMixValues,
      });

      if (parsedData.request_fees === "Apply Private Rates") {
        this.setState({
          requestFeesTable: parsedData.dataprivateRates,
        });
      }
    }
  };

  /**
   * This function will call api for autosave on specific time interval
   */
  callApiOnTimeInterVal = () => {
    this.timeout = setInterval(() => {
      this.callApiPPRCreatedForAutoSave();
    }, 120000);
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  /**
   * This function used update answer value of question based on question type
   * and call handle summary function to update summary page dynamically
   * @param {text} value typed or selected option which need to update in answer object of question
   * @param {object} question in which answer object need to update
   * @param {string} rowId of table when any table data is inserted to make change in particular row
   * @param {string} id cell id of table when any table data is inserted to make chang in particular cell
   */
  handleChange = (value, question, queBlockIndex, rowId, id, termEndDate) => {
    let { dealInfo } = this.state;
    let summaryValue = value;

    this.setState({
      termEndDate: termEndDate,
    });
    if (
      question.tagName === tagNameConstant.currentDiscountTermEndCalTag &&
      this.state.dealStatus === dealStatusConstant.Deal_Signed
    ) {
      this.setState({
        currentDiscountTermDate: value,
      });
    } else if (question.tagName === tagNameConstant.customerLegalNameTag) {
      this.setState({
        customerLegalName: value,
      });
    } else if (question.tagName === tagNameConstant.accountManagerNameTag) {
      this.setState({
        accountManagerName: value,
      });
    } else if (question.tagName === tagNameConstant.renewalTypeTag) {
      this.setState({
        renewalDealInfo: value,
      });
    } else if (question.tagName === tagNameConstant.accountNameTag) {
      this.setState({
        accountName: value,
      });
    } else if (question.tagName === tagNameConstant.cloudFrontSalesManagerTag) {
      this.setState({
        cloudFrontSalesManager: value,
      });
    }

    if (question.queLabel === roleNames.IGT_OWNER) {
      this.setState({
        iGtOwner: value,
      });
    }

    // if(question.tagName === tagNameConstant.geoTag){
    //     this.setState({
    //         geo : value
    //     })
    // }
    if (question.queType === "TEXTUP") {
      question.textUpRows[rowId].cells[id].value = value;
    } else if (question.queType === "FORMGROUP") {
      question.formGroup.rows[rowId].cells[id].value = value;
      this.checkForAdiTermFormGroup(question);
    } else if (question.queType === "TABLE") {
      if (id === 1) {
        if (question.tableObj.rows[0].cells[1].value === "AWS Payer ID") {
          this.state.tempArray[rowId - 1] = value;
          if (this.state.duplicateValidation) {
            this.setState({
              duplicateErrFlag: true,
              duplicateValidation: false,
            });
          } else this.setState({ duplicateErrFlag: false });

          this.handleSummaryData(value, question, "payerIds");
        }
      }
      question.tableObj.rows[rowId].cells[id].value = value;
    } else if (question.queType === "RATETABLE") {
      if (id === "tieredPricing") {
        question.tableObj.tieredPricing = value;
      } else if (id !== "addRow" && id !== "deleteRow") {
        question.tableObj.rows[rowId].cells[id].value = value;
      }
      summaryValue = question;
    } else {
      if (question.tagName === tagNameConstant.termLengthTag) {
        if (
          value &&
          question.answer.answerValue &&
          value.toString() !== question.answer.answerValue.toString()
        ) {
          this.handleMaterialChange(dealInfo);
        }
        question.answer.answerValue = value.toString();
        question.answer.questionId = question.sF_Id;
        this.setState(
          {
            termLength: value.toString(),
          },
          () => {
            this.handleSummaryData(value, question);
          }
        );
      } else {
        question.answer.answerValue = value;
        question.answer.questionId = question.sF_Id;
      }
    }

    this.setState({
      triggeredNext: false,
      changeInvalue: !this.state.changeInvalue,
    });
    this.handleSummaryData(summaryValue, question);
    this.handleQuestionEdit(dealInfo, question, value, rowId, id);
  };

  /**
   * This function used update answer value of question on selection of availalble option of question
   * based on question type and call handle summary function to update summary page dynamically
   * @param {*} value typed or selected option which need to update in answer object of question
   * @param {*} question in which answer object need to update
   * @param {*} rowId of table when any table data is inserted to make change in particular row
   * @param {*} id cell id of table when any table data is inserted to make change in particular cell
   */
  handleOnSelect = (
    value,
    question,
    queBlockIndex,
    rowId,
    id,
    termEndDate,
    newDateValue
  ) => {
    if (question.queType === "RATETABLE") return;
    let { dealInfo } = this.state;
    if (
      question.queLabel === "Business Unit" &&
      isChinaBusinessUnit(value) &&
      question.tagName === tagNameConstant.businessUnitTag
    ) {
      this.state.summaryData["Geo"] = OLD_BUSINESS_UNIT_ANSWER_LABELS.China === value ? OLD_GEO_CHINA : NEW_GEO_CHINA;
    } else if (isPSBusinessUnit(value) || isCOBusinessUnit(value)) {
      this.state.summaryData["Geo"] = "";
    }

    if (question.tagName === tagNameConstant.pricingQuestionTag) {
      if (value === "No") {
        this.setState({
          neglectCommitAndPricing: true,
          rateCardData: null,
        });
        this.makeFlagFalseWhenneglectCommitAndPricing();
      } else {
        this.setState({
          neglectCommitAndPricing: false,
        });
      }
    }

    if (question.tagName === tagNameConstant.discountTermDateTag) {
      if (
        value &&
        question.answer.answerValue &&
        value !== question.answer.answerValue
      ) {
        this.handleMaterialChange(dealInfo);
      }
    }

    if (question.tagName === tagNameConstant.termLengthTag) {
      if (
        value &&
        question.answer.answerValue &&
        value.toString() !== question.answer.answerValue.toString()
      ) {
        this.handleMaterialChange(dealInfo);
      }
    }

    if (question.tagName === tagNameConstant.renewalTypeTag) {
      this.setState((prevState) => ({
        dealInfo: {
          ...prevState.dealInfo,
          renewalDealInfo: value,
        },
      }));
    }

    if (question.tagName === tagNameConstant.originAWSTag) {
      if (
        value &&
        question.answer.answerValue &&
        value !== question.answer.answerValue
      ) {
        this.handleMaterialChange(dealInfo);
      }
      if (value === "No") {
        this.onChangeAdditionalApproval("origin_aws", true);
      } else {
        this.onChangeAdditionalApproval("origin_aws", false);
      }
    }

    if (question.tagName === tagNameConstant.autoRenewTag) {
      if (
        value &&
        question.answer.answerValue &&
        value !== question.answer.answerValue
      ) {
        this.handleMaterialChange(dealInfo);
      }

      dealInfo.autoRenew = value;
      if (value === "No") {
        this.onChangeAdditionalApproval("auto_renew", true);
      } else {
        this.onChangeAdditionalApproval("auto_renew", false);
      }
    }

    if (question.tagName === tagNameConstant.isYourTag) {
      if (value === "No") {
        this.onChangeAdditionalApproval("reference", true);
      } else {
        this.onChangeAdditionalApproval("reference", false);
      }
    }
    if (question.tagName === tagNameConstant.dealTypeTag) {
      if (
        value &&
        question.answer.answerValue &&
        value !== question.answer.answerValue
      ) {
        this.handleMaterialChange(dealInfo);
      }
      dealInfo.dealType = value;
      this.setState({
        dealInfo,
      });
      if (value === "Downgrade") {
        this.onChangeAdditionalApproval("downgrade", true);
        this.onChangeCustomDeal("downgrade", true);
      } else {
        this.onChangeAdditionalApproval("downgrade", false);
        this.onChangeCustomDeal("downgrade", false);
      }

      if (value !== "Amendment") {
        this.setState({
          neglectCommitAndPricing: false,
        });
      }
    }
    if (question.tagName === tagNameConstant.businessUnitTag) {
      this.setState({
        businessUnit: value,
      });
    }
    if (question.tagName === tagNameConstant.additionalDealTermTag) {
      this.onChangeCustomDeal(question.tagName, value);
      this.onChangeAdditionalApproval(question.tagName, value);
      this.setAdiDealTermStateOfSummary(value);
    }

    if (question.tagName === tagNameConstant.cloudFrontSalesManagerTag) {
      this.setState({
        cloudFrontSalesManager: value,
      });
    }

    if (question.queType === "TABLE") {
      question.tableObj.rows[rowId].cells[id].value = value;
      question.tableObj.rows[rowId].sequence = rowId;
    } else if (question.queType === "FORMGROUP") {
      question.formGroup.rows[rowId].cells[id].value = value;
      let clonedFormGroup = Object.assign({}, question);
      this.checkForAdiTermFormGroup(clonedFormGroup);
    } else {
      if (question.queLabel === DiscountTermEffectiveDateLabel) {
        this.setState({
          date: value,
        });
        this.handleSummaryData(newDateValue, question);
      }

      question.answer.answerValue = value;
      question.answer.questionId = question.sF_Id;
    }

    if (question.tagName === tagNameConstant.termLengthTag) {
      question.answer.answerValue = value.toString();
      this.setState(
        {
          termLength: value.toString(),
        },
        () => {
          this.handleSummaryData(value, question);
        }
      );
    }

    if (question.tagName === tagNameConstant.cloudFrontShieldAdvanced) {
      let updatedRateCardData;

      // Update CloudFront Rate Card checkboxes if values already exist
      if (this.state.rateCardData && CLOUD_FRONT_SHIELD_ADVANCED_DEALS.includes(value)) {
        updatedRateCardData = JSON.parse(this.state.rateCardData);
        const prevValue = this.state.dealInfo[CLOUD_FRONT_SHIELD_DEAL_INFO_TAG];
        updatedRateCardData = {
          ...updatedRateCardData,
          Aos_Selected: getCloudFrontShieldAdvancedAosValue(!isCloudFrontShieldAdvancedDeal(prevValue), updatedRateCardData.Aos_Selected),
          VolumeCommitCheckBox:
            updatedRateCardData.VolumeCommitCheckBox.map((volumeCommit) => ({
              ...volumeCommit,
              status: this.getCfSaCheckboxStatus(volumeCommit)
            }))
        }
        updatedRateCardData = JSON.stringify(updatedRateCardData);
      }

      this.setState((prevState) => ({
        dealInfo: {
          ...prevState.dealInfo,
          [CLOUD_FRONT_SHIELD_DEAL_INFO_TAG]: value,
        },
        rateCardData: updatedRateCardData
          ? updatedRateCardData
          : prevState.rateCardData
      }));
    }

    this.setState({
      triggeredNext: false,
      changeInvalue: !this.state.changeInvalue,
    });
    this.handleQuestionEdit(dealInfo, question, value, rowId, id);
    this.handleSummaryData(value, question, "new", newDateValue);
  };

  getCfSaCheckboxStatus = (checkbox) => {
    return ((checkbox.optionValue === 'Commitment Fee' ||
             checkbox.optionValue === 'Commitment' ||
             checkbox.optionValue === 'No Commit') && checkbox.status === true) ? false : checkbox.status;
  }

  handleQuestionEdit = (dealInfo, question, value, rowId, id) => {
    const { userRole } = this.state;

    if (question.queType === "TABLE" || question.queType === "RATETABLE") {
      if (
        (dealInfo.dealStatus === dealStatusConstant.PPR_Created ||
          dealInfo.dealStatus === dealStatusConstant.PPR_Submitted) &&
        userRole === roleNames.SALES_REPRESENTATIVE
      ) {
        question.tableObj.rows[rowId].cells[id].prevValue = value;
      } else if (
        rowId > 0 &&
        value !== question.tableObj.rows[rowId].cells[id].prevValue
      ) {
        question.tableObj.rows[0].cells[id].isQuestionEdit = true;
      } else if (
        rowId > 0 &&
        value === question.tableObj.rows[rowId].cells[id].prevValue
      ) {
        question.tableObj.rows[0].cells[id].isQuestionEdit = false;
      }
    } else if (question.queType === "FORMGROUP") {
      if (
        (dealInfo.dealStatus === dealStatusConstant.PPR_Created ||
          dealInfo.dealStatus === dealStatusConstant.PPR_Submitted) &&
        userRole === roleNames.SALES_REPRESENTATIVE
      ) {
        question.formGroup.rows[rowId].cells[id].prevValue = value;
      } else if (value !== question.formGroup.rows[rowId].cells[id].prevValue) {
        question.formGroup.rows[0].cells[id].isQuestionEdit = true;
      } else if (value === question.formGroup.rows[rowId].cells[id].prevValue) {
        question.formGroup.rows[0].cells[id].isQuestionEdit = false;
      }
    } else if (
      (dealInfo.dealStatus === dealStatusConstant.PPR_Created ||
        dealInfo.dealStatus === dealStatusConstant.PPR_Submitted) &&
      userRole === roleNames.SALES_REPRESENTATIVE
    ) {
      question.value = value;
    } else if (value !== question.value) {
      if (question.tagName === tagNameConstant.termLengthTag) {
        if (value.toString() !== question.value.toString()) {
          question.isQuestionEdit = true;
        } else {
          question.isQuestionEdit = false;
        }
      } else {
        question.isQuestionEdit = true;
      }
    } else if (value === question.value) {
      question.isQuestionEdit = false;
    }
  };

  /**
   * this function will set material change flag as true or false based on deal status and user role
   */
  handleMaterialChange = (dealInfo) => {
    let { userRole } = this.state;
    if (
      dealInfo.dealStatus !== dealStatusConstant.PPR_Created &&
      userRole === roleNames.SALES_REPRESENTATIVE
    ) {
      dealInfo.isMatChange = true;
    }
  };

  /**
   * this function will display set state for displaying additional deal term in summary page
   */
  setAdiDealTermStateOfSummary = (value) => {
    if (value === "Yes") {
      this.setState({
        showAdiDealTermInSummary: true,
      });
    } else {
      this.setState({
        showAdiDealTermInSummary: false,
      });
    }
  };

  /**
   * check for additional deal term formgroup value
   */
  checkForAdiTermFormGroup = (question) => {
    if (question.queLabel === null) {
      this.setState({
        adiDealTermFormGroup: question.formGroup.rows,
      });
    }
  };

  checkForAdditionalDealTerm = () => {
    let { dataModel } = this.state;
    dataModel.forEach(({ queBlock }) => {
      queBlock &&
        queBlock.forEach((queBlockRow) => {
          queBlockRow &&
            queBlockRow.rows &&
            queBlockRow.rows.forEach((questionValue) => {
              if (questionValue) {
                questionValue.questions &&
                  questionValue.questions.forEach((question) => {
                    if (
                      question.tagName &&
                      question.tagName === tagNameConstant.additionalDealTermTag
                    ) {
                      this.onChangeCustomDeal(
                        question.tagName,
                        question.answer.answerValue
                      );
                      this.setAdiDealTermStateOfSummary(
                        question.answer.answerValue
                      );
                    }
                    if (question.queType === "FORMGROUP") {
                      let clonedFormGroup = Object.assign({}, question);
                      this.checkForAdiTermFormGroup(clonedFormGroup);
                    }
                  });
              }
            });
        });
    });
  };

  closeunLinkedModal = () => {
    this.setState({
      ModalUnLinkedIdType: false,
    });
  };

  checkForAutofilledData = (data, type) => {
    let abort = false;
    const modal = cloneDeep(this.state.dataModel);

    let returnValue = false;
    for (const level1 of modal) {
      for (const level2 of level1.queBlock) {
        if (level2) {
          for (const level3 of level2.rows) {
            if (level3) {
              for (const level4 of level3.questions) {
                if (level4) {
                  if (
                    level4 &&
                    level4.tagName === tagNameConstant.customerLegalNameTag
                  ) {
                    if (level4 && level4.answer.answerValue === null) {
                      this.setState(
                        {
                          visible: false,
                        },
                        () => {
                          this.setAccountData(data);
                        }
                      );
                    } else {
                      if (
                        level4 &&
                        data &&
                        data.length > 0 &&
                        data[0].sfdc_account_name ===
                          level4.answer.answerValue &&
                        type === accountNameType
                      ) {
                        this.setState({
                          visible: false,
                          autoPopulateType: type,
                        });
                      } else {
                        this.setState({
                          visible: true,
                          autoPopulateType: type,
                        });
                      }
                    }
                  } else if (
                    level4 &&
                    level4.tagName === tagNameConstant.accountNameTag
                  ) {
                    this.setState({
                      visible: false,
                    });
                  }
                }
              }
            }
            if (abort) {
              break;
            }
          }
        }
        if (abort) {
          break;
        }
      }
      if (abort) {
        break;
      }
    }

    // return returnValue
  };

  handleOk = (e) => {
    let { autoPopulateAccountData } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.setAccountData(autoPopulateAccountData);
      }
    );
  };

  handleCancel = (e) => {
    let { autoPopulateType } = this.state;

    this.setState(
      {
        visible: false,
      },
      () => {
        if (prevAutoPopulateAccountData.length) {
          this.setAccountDataOnCancelForAccountNameAndPayerId(
            prevAutoPopulateAccountData,
            autoPopulateType
          );
        }
      }
    );
  };

  /**
   * this function will reset data for Payer ID's and Account Name when overidden is cancelled
   */
  setAccountDataOnCancelForAccountNameAndPayerId = (data, typeOfInput) => {
    if (data.length) {
      prevAutoPopulateAccountData = data;
    }

    let { tempArray } = this.state;
    let payerIdTempArray = [...tempArray];
    const modal = cloneDeep(this.state.dataModel);
    let abort = false;
    for (const level1 of modal) {
      for (const level2 of level1.queBlock) {
        if (level2) {
          for (const level3 of level2.rows) {
            if (level3) {
              for (const level4 of level3.questions) {
                if (
                  level4 &&
                  level4.tagName === tagNameConstant.accountNameTag &&
                  typeOfInput === accountNameType
                ) {
                  level4.value = data[0].sfdc_account_name;
                  level4.answer.answerValue = data[0].sfdc_account_name;
                }
                if (
                  level4.tagName === tagNameConstant.payerIdDetailsTag &&
                  level4.tableObj &&
                  typeOfInput === payerIdType
                ) {
                  if (level4.tableObj.rows && level4.tableObj.rows.length) {
                    level4.tableObj.rows.pop();
                    payerIdTempArray.pop();
                  }
                }
              }
              if (abort) {
                break;
              }
            }
          }
        }
        if (abort) {
          break;
        }
      }
      if (abort) {
        break;
      }
    }

    this.setState(
      { ...this.state, dataModel: modal, tempArray: payerIdTempArray },
      () => {
        this.handleSummaryData(null, null, "payerIds");
      }
    );
  };

  /**
   * this function will set data for payer ID's from the response
   */
  setAccountData = (data) => {
    if (data.length) {
      prevAutoPopulateAccountData = data;
    }

    let { tempArray } = this.state;
    let payerIdTempArray = [...tempArray];
    const modal = cloneDeep(this.state.dataModel);
    let abort = false;
    let buObj;
    let verticalFlag = false;
    const filteredData = data.filter((items) => {
      return items.sfdc_account_name === items.legalname;
    });
    if (filteredData && filteredData.length > 0) {
      const businessUnitObj = filteredData.filter((filterObj) => {
        return filterObj.payer_rolename === "Payer";
      });
      if (businessUnitObj && businessUnitObj.length > 0) {
        buObj = businessUnitObj[0];
      } else buObj = filteredData[0];
    } else {
      const _buObj = data.filter((dataObj) => {
        return dataObj.payer_rolename === "Payer";
      });
      if (_buObj && _buObj.length > 0) buObj = _buObj[0];
      else buObj = data[0];
    }
    const customerType = getCustomerType(data);
    const businessCustomerType = buObj.accounttype !== ACCOUNT_TYPE.WWPS ? customerType : BUSINESS_CUSTOMER_TYPE_ANSWER_LABELS.PS;
    
    for (const level1 of modal) {
      for (const level2 of level1.queBlock) {
        let verticalDepData;
        let geoDepData;
        if (level2) {
          for (const level3 of level2.rows) {
            if (level3) {
              for (const level4 of level3.questions) {
                if (level4 && data && data.length) {
                  if (level4.tagName === tagNameConstant.businessCustomerType) {
                    level4.value = businessCustomerType;
                    level4.answer.answerValue = businessCustomerType;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (level4.tagName === tagNameConstant.customerLegalNameTag) {
                    this.handleSummaryData(data[0].legalname, level4);
                    level4.value = data[0].sfdc_account_name;
                    level4.answer.answerValue = data[0].sfdc_account_name;
                  } else if (
                    level4.tagName === tagNameConstant.accountNameTag
                  ) {
                    level4.value = data[0].sfdc_account_name;
                    level4.answer.answerValue = data[0].sfdc_account_name;
                  } else if (
                    level4.tagName === tagNameConstant.businessUnitTag
                  ) {
                    let value;
                    const businessUnitDefaultValue = level4.defaultValue;
                    const businessUnitValues = (businessUnitDefaultValue === OLD_BUSINESS_UNIT_ANSWER_LABELS.CO) ? businessUnitOption : orgChangeBusinessUnitOptionsAWSDropdown;
                    if (buObj.accounttype === ACCOUNT_TYPE.GCR) {
                      value = businessUnitValues[2].optionValue;
                    } else if (buObj.accounttype === ACCOUNT_TYPE.WWPS) {
                      value = businessUnitValues[1].optionValue;
                    } else {
                      value = businessUnitValues[0].optionValue;
                    }

                    level4.value = value;
                    level4.answer.answerValue = value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (
                    level4.tagName === tagNameConstant.verticalValueTag && shouldUpdateAGSVertical(buObj.accounttype, businessCustomerType)
                  ) {
                    const isIndustryInOptions = doesValueExistWithinOptions(level4.options, buObj.industry) && buObj.industry !== "Other";
                    const value = !isIndustryInOptions ? "Other" : buObj.industry;
                    if (!isIndustryInOptions) {
                      verticalDepData = {
                        depId:
                          level4.dependentOn[0] &&
                          level4.dependentOn[0].dependentId,
                        value: buObj.industry,
                      };
                    }
                    level4.value = value;
                    level4.answer.answerValue = value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (
                    level4.tagName === tagNameConstant.verticalValueTagPs && shouldUpdatePSVertical(buObj.accounttype, businessCustomerType)
                  ) {
                    const isIndustryInOptions = doesValueExistWithinOptions(level4.options, buObj.industry) && buObj.industry !== "Other";
                    const value = !isIndustryInOptions ? "Other" : buObj.industry;
                    if (!isIndustryInOptions) {
                      verticalDepData = {
                        depId:
                          level4.dependentOn[0] &&
                          level4.dependentOn[0].dependentId,
                        value: buObj.industry,
                      };
                    }
                    level4.value = value;
                    level4.answer.answerValue = value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (
                    level4.tagName === tagNameConstant.verticalValueTagCh && shouldUpdateGCRVertical(buObj.accounttype, businessCustomerType)
                  ) {
                    const isIndustryInOptions = doesValueExistWithinOptions(level4.options, buObj.industry) && buObj.industry !== "Other";
                    const value = !isIndustryInOptions ? "Other" : buObj.industry;
                    if (!isIndustryInOptions) {
                      verticalDepData = {
                        depId:
                          level4.dependentOn[0] &&
                          level4.dependentOn[0].dependentId,
                        value: buObj.industry,
                      };
                    }
                    level4.value = value;
                    level4.answer.answerValue = value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (geoDepData && level4.queId === geoDepData.depId) {
                    level4.value = geoDepData.value;
                    level4.answer.answerValue = geoDepData.value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (
                    verticalDepData &&
                    level4.queId === verticalDepData.depId
                  ) {
                    level4.value = verticalDepData.value;
                    level4.answer.answerValue = verticalDepData.value;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (level4.tagName === tagNameConstant.geoTagPs && buObj.accounttype === ACCOUNT_TYPE.WWPS) {
                    if (doesValueExistWithinOptions(level4.options, buObj.geo)) {
                      level4.value = buObj.geo;
                      level4.answer.answerValue = buObj.geo;
                      level4.answer.questionId = level4.sF_Id;
                    }
                  } else if (level4.tagName === tagNameConstant.geoTag && buObj.accounttype !== ACCOUNT_TYPE.WWPS) {
                    // geoDepData = buObj.geo === 'GLBL' && { depId: level4.dependentOn[2] && level4.dependentOn[2].dependentId, value: 'GLOBAL' };
                    geoDepData = buObj.geo === "STRAT" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "STRATEGIC",
                    };
                    geoDepData = buObj.geo === "GFS" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "GFS",
                    };
                    geoDepData = buObj.geo === "TELCO" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "TELCO",
                    };
                    geoDepData = buObj.geo === "GLOBAL" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "GLOBAL",
                    };
                    geoDepData = buObj.geo === "GSI" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "GSI",
                    };
                    geoDepData = buObj.geo === "HOUSE" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: "HOUSE",
                    };
                    geoDepData = buObj.geo === "GLBL" && {
                      depId:
                        level4.dependentOn[2] &&
                        level4.dependentOn[2].dependentId,
                      value: buObj && buObj.region ? buObj.region : "GLOBAL",
                    };
                    if (
                      buObj.geo === "STRAT" ||
                      buObj.geo === "GFS" ||
                      buObj.geo === "TELCO" ||
                      buObj.geo === "GLOBAL" ||
                      buObj.geo === "GSI" ||
                      buObj.geo === "HOUSE"
                    ) {
                      level4.value = "GLBL";
                      level4.answer.answerValue = "GLBL";
                    } else {
                      if (doesValueExistWithinOptions(level4.options, buObj.geo)) {
                        level4.value = buObj.geo;
                        level4.answer.answerValue = buObj.geo;
                      }
                    }
                    // level4.value = buObj.geo;
                    // level4.answer.answerValue = buObj.geo;
                    level4.answer.questionId = level4.sF_Id;
                  } else if (level4.tagName === tagNameConstant.subGeoTag) {
                    if (
                      buObj.geo === "STRAT" ||
                      buObj.geo === "GFS" ||
                      buObj.geo === "TELCO" ||
                      buObj.geo === "GLOBAL" ||
                      buObj.geo === "GSI" ||
                      buObj.geo === "HOUSE"
                    ) {
                      // geoDepData = buObj.geo === 'GLBL' && { depId: level4.dependentOn[2] && level4.dependentOn[2].dependentId, value: 'GLOBAL' };
                      level4.value =
                        buObj.geo === "STRAT" ? "STRATEGIC" : buObj.geo;
                      level4.answer.answerValue =
                        buObj.geo === "STRAT" ? "STRATEGIC" : buObj.geo;
                      level4.answer.questionId = level4.sF_Id;
                    } else if (buObj.geo === "GLBL") {
                      level4.value =
                        buObj && buObj.region ? buObj.region : "GLOBAL";
                      level4.answer.answerValue =
                        buObj && buObj.region ? buObj.region : "GLOBAL";
                      level4.answer.questionId = level4.sF_Id;
                    }
                  } else if (
                    level4.tagName === tagNameConstant.payerIdDetailsTag &&
                    level4.tableObj
                  ) {
                    let rows = level4.tableObj.rows;
                    const tempData = cloneDeep(rows[1]);
                    const tempRows = cloneDeep(rows).splice(0, 1);
                    payerIdTempArray = [];
                    data.map((payerDetail, index) => {
                      payerIdTempArray.push(payerDetail.aws_account_id);
                      const row = cloneDeep(tempData);
                      row.cells[0].value = index + 1;

                      row.cells[1].value = payerDetail.aws_account_id;
                      row.cells[2].value = payerDetail.legalname;
                      row.cells[3].value = payerDetail.payer_rolename;
                      row.cells[4].value = payerDetail.payerid_mode_ofpayment;

                      row.cells[1].prevValue = payerDetail.aws_account_id;
                      row.cells[2].prevValue = payerDetail.legalname;
                      row.cells[3].prevValue = payerDetail.payer_rolename;
                      row.cells[4].prevValue =
                        payerDetail.payerid_mode_ofpayment;

                      row.cells[0].autopopulate = true;
                      row.cells[1].autopopulate = true;
                      row.cells[2].autopopulate = true;
                      row.cells[3].autopopulate = true;
                      row.cells[4].autopopulate = true;
                      tempRows.push(row);
                    });
                    level4.tableObj.rows = [...tempRows];
                  }
                }
              }
            }

            if (abort) {
              break;
            }
          }
        }
        if (abort) {
          break;
        }
      }
      if (abort) {
        break;
      }
    }

    this.setState(
      { ...this.state, dataModel: modal, tempArray: payerIdTempArray },
      () => {
        this.handleSummaryData(null, null, "payerIds");
      }
    );
  };

  /**
   * This function used update data for particular selection of auto populate dropdown of question
   * based on tag name it update content dynamically dynamically
   * @param {*} value typed or selected option which need to update in answer object of question
   * @param {*} question in which answer object need to update
   * @param {*} rowId of table when any table data is inserted to make change in particular row
   * @param {*} id cell id of table when any table data is inserted to make change in particular cell
   */
  onDropdownSelection = (value, question, queBlockIndex, rowId, cellIndex) => {
    if (question.tagName === tagNameConstant.accountNameTag) {
      this.handleChange(value, question, queBlockIndex, rowId, cellIndex);
      this.getDataFromAccountNameOrAccountId(value, accountNameType);
    } else {
      this.getDataFromAccountNameOrAccountId(value, payerIdType);
    }
  };

  getDataFromAccountNameOrAccountId = (val, type) => {
    const postData = {
      query: {
        match_phrase_prefix: {
          [type]: val,
        },
      },
    };

    fetchDataFromAccountNameOrAccountId(postData)
      .then((resp) => {
        if (resp.data && resp.data.statusCode && resp.data.statusCode == 400) {
          this.setState({
            ModalUnLinkedIdType: true,
            unLinkedAlertType: resp.data.body,
          });
        } else {
          this.setState(
            {
              autoPopulateAccountData: resp.data,
            },
            () => {
              if (resp.data && resp.data.length) {
                this.checkForAutofilledData(resp.data, type);
              }
            }
          );
        }
      })
      .catch((e) => {
        console.warn("Auto completed selection warning", e);
      });
  };

  /**
   * This function used to update summary page dynamically based on type
   * @param {*} value text or table value which neede to update
   * @param {*} type label type in summary page
   * @param {*} question need to pass in function to update value based on json constant
   */
  handleSummaryData = (value, question, type, newDateValue) => {
    if (
      question &&
      question.queLabel ===
        "Is your customer agreeable that AWS may use their name and logo to identify them as a customer?"
    ) {
      this.state.summaryData["References"] = value;
    }
    if (type === "payerIds") {
      this.state.summaryData["Payer Id"] = this.state.tempArray.join();
    } else if (type === "PprId") {
      this.state.summaryData["PprId"] = value;
    } else if (question.queType === "RATETABLE") {
      const summaryData = this.state.summaryData;
      this.setState({
        summaryData: {
          ...summaryData,
          [question.queId]: value,
        },
      });
    } else if (this.state.qualifyingQuestionIds.includes(question.queId)) {
      const summaryData = this.state.summaryData;
      this.setState({
        summaryData: {
          ...summaryData,
          qualifyingQuestions: {
            ...summaryData.qualifyingQuestions,
            [question.queId]: question,
          },
        },
      });
    } else if (this.state.originShieldQuestionIds.includes(question.queId)) {
      const summaryData = this.state.summaryData;
      this.setState({
        summaryData: {
          ...summaryData,
          originShieldQuestions: {
            ...summaryData.originShieldQuestions,
            [question.queId]: question,
          },
        },
      });
    } else if (question.tagName === tagNameConstant.cloudFrontShieldAdvanced) {
      const summaryData = this.state.summaryData;
      this.setState({
        summaryData: {
          ...summaryData,
          [CLOUD_FRONT_SHIELD_ADVANCED_QUESTION_LABEL]: question,
        },
      });
    } else {
      this.updateSummaryDataFromJsonKeyConstant(value, question, newDateValue);
    }
    this.setState({
      summaryDataUpdated: true,
    });
    // console.log("handleSummaryData question:", question.queLabel, "summaryData:", this.state.summaryData);
  };

  /**
   * This function will update summary page value based on key of constatnt json and label of question
   * @param {*} value need to be updated
   * @param {*} question useful to compare lable with key json constant
   * @param {*} newDateValue to update discount term effective date.
   */
  updateSummaryDataFromJsonKeyConstant = (value, question, newDateValue) => {
    let newPayerKey = Object.keys(summaryData);

    newPayerKey.forEach((keyPair) => {
      if (question.tagName === tagNameConstant.termLengthTag) {
        this.state.summaryData["Term Length (Months)"] = this.state.termLength;
        this.state.summaryData["Discount Term End Date"] =
          this.state.termEndDate && this.state.termEndDate.toString();
      } else if (
        question.tagName === tagNameConstant.currentDiscountTermEndCalTag
      ) {
        this.state.summaryData["Current Discount Term End Date"] =
          this.state.currentDiscountTermDate &&
          this.state.currentDiscountTermDate.toString();
      } else if (question.queLabel === DiscountTermEffectiveDateLabel) {
        this.state.summaryData["Discount Term Effective Date"] = newDateValue;
      } else if (question.queType === "RATETABLE") {
        this.state.summaryData[question.queId] = value;
      } else if (this.state.qualifyingQuestionIds.includes(question.queId)) {
        const summaryData = this.state.summaryData;
        this.setState({
          summaryData: {
            ...summaryData,
            qualifyingQuestions: {
              ...summaryData.qualifyingQuestions,
              [question.queId]: question,
            },
          },
        });
      } else if (this.state.originShieldQuestionIds.includes(question.queId)) {
        const summaryData = this.state.summaryData;
        this.setState({
          summaryData: {
            ...summaryData,
            originShieldQuestions: {
              ...summaryData.originShieldQuestions,
              [question.queId]: question,
            },
          },
        });
      } else {
        if (
          question.tagName === tagNameConstant.dealTypeTag &&
          keyPair === "Deal Type"
        ) {
          this.state.summaryData[keyPair] = value && value.toString();
        } else if (
          question.tagName === tagNameConstant.provideBusinessDetailsTag &&
          keyPair === "Business Justification"
        ) {
          this.state.summaryData[keyPair] = value && value.toString();
        } else if (question.queLabel !== DiscountTermEffectiveDateLabel) {
          if (this.state.summaryData["Geo"] === OLD_GEO_CHINA) {
            this.state.summaryData["Geo"] = OLD_GEO_CHINA;
          }
          if (
            keyPair === question.queLabel &&
            keyPair === "Geo" &&
            question.isVisible === false
          ) {
            this.state.summaryData[keyPair] = isAfterOrgChange() ? NEW_GEO_CHINA : OLD_GEO_CHINA;
          } else if (keyPair === question.queLabel) {
            this.state.summaryData[keyPair] = value && value.toString();
          }
        }
      }
    });
    this.setState({
      summaryDataUpdated: true,
    });
  };

  /**
   * This function used to navigate between stages of ppr based on stage Id
   * @param {*} stageId selected stage id on clicking of left nav
   */
  handleNavigation = (stageId) => {
    let { dealInfo } = this.state;

    if (commitAndPricingStageTriggered === true) {
      let data = this.rateCardData && this.rateCardData.setRateCardData();

      if (data) {
        this.state.rateCardData = data;
        dealInfo.rateCard = data;
        commitAndPricingStageTriggered = false;
      }
    }
    if (stageId === 2) {
      commitAndPricingStageTriggered = true;
    }
    this.setState({
      stage: stageId,
      triggeredNext: false,
      isExpand: false,
      enableCommentBox: true,
    });
    document.getElementsByClassName(scrollToClassName)[0].scrollTo(0, 0);
  };

  /**
   * this function will receive values from child component(form) and
   * set the values in left navigation summary page
   */
  onchangeDealStructure = (key, value, type, geoValues, volumeUnit) => {
    let defaultTrafficload = false;
    let geo = [];
    this.setState({
      changeInvalue: !this.state.changeInvalue,
    });
    if (key === "Dataload") {
      this.setState((prevState) => ({
        summaryData: {
          ...prevState.summaryData,
          defaultLoad: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        summaryData: {
          ...prevState.summaryData,
          defaultLoad: false,
        },
      }));
    }
    if (key === "Commit Volume") {
      if (type !== "All") {
        geo = geoValues
          .map((el) => {
            if (el.optionValue !== 0) {
              return ` ${el.optionKey === "EU" ? "EU/IL" : el.optionKey} ${
                el.optionValue
              } ${volumeUnit}`;
            }
          })
          .filter((ele) => ele !== undefined);
        geo.length > 0 ? (value = geo.join(";")) : value;
      } else {
        if (value !== "") {
          value = `${value} ${volumeUnit}`;
        } else {
          value = "  ";
        }
      }
    }
    if (key === "TermLength") {
      this.setState(
        {
          termLength: value.toString(),
        },
        () => {
          this.state.summaryData["Term Length (Months)"] =
            this.state.termLength;
          key = "Term Length (Months)";
          this.setState({
            summaryDataUpdated: true,
          });
        }
      );
    }
    if (key === "Traffic Limit") {
      if (value !== "") {
        this.setState((prevState) => ({
          summaryData: {
            ...prevState.summaryData,
            regionalTraffic: value,
          },
        }));
      } else {
        defaultTrafficload = true;
      }
    }
    if (key === "NoCommit") {
      this.setState({
        leftNavTierPricing: "",
      });
    }
    if (key === "LefNav") {
      this.setState({
        leftNavRateCardData: value,
      });
    } else {
      if (key === "datasource") {
        if (type === "tiered") {
          this.setState({
            leftNavTierPricing: "tiered",
            leftNavTierPricingTable: value,
          });
        } else {
          this.setState({
            leftNavTierPricing: "Nottiered",
            leftNavTierPricingTable: value,
          });
        }
      } else if (key === "regionalMix") {
        this.setState((prevState) => ({
          regionalLimitsTable: value,
        }));
      } else if (key === "regionalTrafficLimit") {
        this.setState((prevState) => ({
          summaryData: {
            ...prevState.summaryData,
            regionalTraffic: type,
          },
          regionalTrafficLimitTable: value,
        }));
      } else if (key === "PrivateRates") {
        this.setState((prevState) => ({
          requestFeesTable: value,
        }));
      } else if (key === "combinedTraffic") {
        this.setState((prevState) => ({
          combinedTraffic: value,
        }));
      } else if (key === "AdditionalPricingTerms") {
        this.setState((prevState) => ({
          summaryData: {
            ...prevState.summaryData,
            pricingTermsFlag: type,
          },
          additionalPricingTerms: value,
        }));
      } else {
        if (
          key !== "Dataload" &&
          key !== "AdditionalPricingTerms" &&
          defaultTrafficload === false
        ) {
          this.state.summaryData[key] = value;
        }
      }
    }
  };

  makeFlagFalseWhenneglectCommitAndPricing = () => {
    let { neglectCommitAndPricing, additionalApprovalFlag, dealIdentifier } =
      this.state;
    this.setState((prevState) => ({
      additionalApprovalFlag: {
        ...prevState.additionalApprovalFlag,
        adi_price_terms: false,
        aosOverride: false,
        aosVolumeOverride: false,
        commitFeeOverride: false,
        customDealIdentifier: false,
        minCommittedFlag: false,
        noCommitDeal: false,
        regionalLimitOverride: false,
        regionalUsage: false,
      },
    }));

    this.setState((prevState) => ({
      dealIdentifier: {
        ...prevState.dealIdentifier,
        customPricingTerms: false,
        customRateCard: false,
        noCommitDeal: false,
      },
    }));
  };
  /**
   * This function will set custom flag as true when deal is custom
   */
  onChangeCustomDeal = (key, value) => {
    if (
      this.state.userRole === roleNames.SALES_REPRESENTATIVE ||
      this.state.userRole === roleNames.SALES_MANAGER ||
      this.state.userRole === roleNames.SCE_OWNER ||
      this.state.userRole === roleNames.PRIVATE_PRICING_CHINA ||
      this.state.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR
    ) {
      if (key === "downgrade") {
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            downgrade: value,
          },
        }));
      }
      if (key === "reference") {
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            reference: value,
          },
        }));
      }
      if (key === "auto_renew") {
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            auto_renew: value,
          },
        }));
      }
      if (key === "adi_deal_terms") {
        let additionalDealTermFlag = value === "Yes" ? true : false;
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            customDealTerms: additionalDealTermFlag,
          },
        }));
      }
      if (key === "customRateCard") {
        let customDealFlag = value === "Custom" ? true : false;
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            customRateCard: customDealFlag,
          },
        }));
      }
      if (key === "PricingTerms") {
        let customDealFlag = value === "Yes" ? true : false;
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            customPricingTerms: customDealFlag,
          },
        }));
      }
      if (key === "No Commit") {
        this.setState((prevState) => ({
          dealIdentifier: {
            ...prevState.dealIdentifier,
            noCommitDeal: value,
          },
        }));
      }
    }
  };

  /**
   * This function will set aditional approval flag as true when aditional Approval is enabled
   */
  onChangeAdditionalApproval = (key, value) => {
    if (this.state.userRole === roleNames.SALES_REPRESENTATIVE) {
      if (key === "downgrade") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            downgrade: value,
          },
        }));
      }
      if (key === "pricingTerms") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            adi_price_terms: value === "Yes" ? true : false,
          },
        }));
      }
      // if (key === "reference") {
      //     this.setState(prevState => ({
      //         additionalApprovalFlag: {
      //             ...prevState.additionalApprovalFlag,
      //             reference: value
      //         }
      //     }));
      // }
      if (key === "minCommittedFlag") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            minCommittedFlag: value,
          },
        }));
      }
      // if (key === "origin_aws") {
      //     this.setState(prevState => ({
      //         additionalApprovalFlag: {
      //             ...prevState.additionalApprovalFlag,
      //             origin_aws: value
      //         }
      //     }));
      // }
      // if (key === "auto_renew") {
      //     this.setState(prevState => ({
      //         additionalApprovalFlag: {
      //             ...prevState.additionalApprovalFlag,
      //             auto_renew: value
      //         }
      //     }));
      // }
      if (key === "dis_term_date") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            effectiveDateOveride: value,
          },
        }));
      }
      if (key === "Expected_Customer_Signature_Date") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            signatureDateOveride: value,
          },
        }));
      }
      if (key === "adi_deal_terms") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            adi_deal_terms: value === "Yes" ? true : false,
          },
        }));
      }
      if (key === "customRateCard") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            customDealIdentifier: value,
          },
        }));
      }
      if (key === "termLength") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            termLength: value,
          },
        }));
      }
      if (key === "regionalLimitOverride") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            regionalLimitOverride: value,
          },
        }));
      }
      if (key === "commitFeeOverride") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            commitFeeOverride: value,
          },
        }));
      }
      if (key === "aosVolumeOverride") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            aosVolumeOverride: value,
          },
        }));
      }
      if (key === "noCommitDeal") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            noCommitDeal: value,
          },
        }));
      }
      if (key === "privateRatesFlag") {
        this.setState((prevState) => ({
          additionalApprovalFlag: {
            ...prevState.additionalApprovalFlag,
            privateRatesFlag: value,
          },
        }));
      }
    }
  };

  /**
   * This function is common to set state of loader and loading message
   */
  showDealStructureLoadingToast = (message, activeState) => {
    this.setState({
      activateToast: activeState,
      loadingMessage: message,
    });
  };

  /**
   * This function used to handle next and back button click , it will make submit button enable-disable summary page
   * based on progress of ppr form, will set rate card data in question object,
   * will call validation on click of every next click
   */
  handleButtonClick = (stageName, stageLength, menu, data) => {
    let { tempArray, dealInfo, neglectCommitAndPricing } = this.state;
    if (neglectCommitAndPricing === undefined) {
      neglectCommitAndPricing = false;
    }
    if (
      document.getElementsByClassName("progress-line").length === 0 &&
      neglectCommitAndPricing === false &&
      document.getElementsByClassName("progress-line-forCNP").length === 0
    ) {
      this.setState({
        disableSubmitButton: false,
      });
    } else if (
      document.getElementsByClassName("progress-line").length === 0 &&
      neglectCommitAndPricing === true
    ) {
      this.setState({
        disableSubmitButton: false,
      });
    } else {
      this.setState({
        disableSubmitButton: true,
      });
    }

    let { stage } = this.state;
    if (this.state.stage === 1) {
      commitAndPricingStageTriggered = true;
    } else {
      commitAndPricingStageTriggered = false;
    }

    if (this.state.stage === 2) {
      let data = this.rateCardData.setRateCardData();
      dealInfo.rateCard = data;
      this.state.rateCardData = data;
    }

    if (stageName === "back" && stage) {
      if (neglectCommitAndPricing === true && stage === 3) {
        this.setState({
          stage: stage - 2,
          triggeredNext: false,
        });
      } else {
        this.setState({
          stage: stage - 1,
          triggeredNext: false,
        });
      }
    } else if (stageName === "back" && stage === 0) {
      this.callDashboardApi(this.props.location && this.props.location.state);
    } else if (stageName === "next") {
      let result = this.checkDuplicateAwsPayerId(tempArray);
      if (result === true) {
        this.setState(
          {
            triggeredNext: true,
            duplicateValidation: true,
            duplicateErrFlag: false,
          },
          () => {
            setTimeout(() => {
              this.validation(stage, stageName, stageLength, data);
            }, 100);
          }
        );
      } else {
        this.setState(
          {
            triggeredNext: true,
            duplicateValidation: false,
          },
          () => {
            setTimeout(() => {
              this.validation(stage, stageName, stageLength, data);
            }, 100);
          }
        );
      }
    }
    this.callApiPPRCreatedForAutoSave();
  };

  /**
   * this function handle payerId update on delete of table row
   */
  handleDeleteTableRow = (rowId) => {
    let { tempArray } = this.state;
    tempArray.splice(rowId - 1, 1);
    this.setFlagsAndCallSummaryDataFunction(tempArray, rowId);
  };

  /**
   * this function will set flag duplicate error flag and call handle summary data function
   */
  setFlagsAndCallSummaryDataFunction = (tempArray, rowId) => {
    this.setState(
      {
        tempArray,
        triggeredNext: false,
      },
      () => {
        this.handleSummaryData(rowId, tempArray, "payerIds");
        const result = this.checkDuplicateAwsPayerId(tempArray);
        const resultOfnonEmpty = this.checkForEmptyData(tempArray);

        if (result || result === undefined) {
          this.setState({
            duplicateValidation: false,
            duplicateErrFlag: false,
          });
        } else {
          if (resultOfnonEmpty) {
            this.setState({
              duplicateValidation: false,
              duplicateErrFlag: true,
            });
          }
        }
      }
    );
  };

  /**
   *
   * @param {*} affiliatedPayerId
   * this function will set affiliatedIdArray state which will be used to
   * display Affiliated Payer Ad in summary page
   */
  handleAffiliatedPayerIdForSummary = (affiliatedPayerId) => {
    this.setState({
      affiliatedIdArray: affiliatedPayerId,
    });
  };

  /**
   * this function handle payerId update on delete of multiple table row
   */
  handleBulkDeleteTableRow = (rowIds) => {
    let { tempArray } = this.state;

    tempArray = tempArray.filter(function (value, index) {
      return rowIds.indexOf(index + 1) == -1;
    });

    this.setFlagsAndCallSummaryDataFunction(tempArray, rowIds);
  };

  /**
   * This function will check for empty data if any
   */
  checkForEmptyData = (awsPayerIdArr) => {
    let nonEmptyCount = true;
    for (var i = 0; i < awsPayerIdArr.length; i++) {
      if (awsPayerIdArr[i] === null || awsPayerIdArr[i] === "") {
        nonEmptyCount = false;
      }
      if (i === awsPayerIdArr.length - 1) {
        return nonEmptyCount;
      }
    }
  };

  /**
   * This function will check for Duplicate Aws Payer Id
   */
  checkDuplicateAwsPayerId = (awsPayerIdArr) => {
    let resultOfnonEmpty = this.checkForEmptyData(awsPayerIdArr);

    if (resultOfnonEmpty) {
      let payerIdCount = [];
      for (var i = 0; i < awsPayerIdArr.length; i++) {
        if (payerIdCount[awsPayerIdArr[i]] === undefined) {
          payerIdCount[awsPayerIdArr[i]] = 1;
        } else {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  /**
   * this function check for error and based on that allows to submit or to navigate to next page
   */
  validation = (stage, stageName, stageLength, data) => {
    let { neglectCommitAndPricing } = this.state;
    if (document.getElementsByClassName("has-error").length > 0) {
      setTimeout(() => {
        document.getElementsByClassName(scrollToClassName)[0] &&
          document.getElementsByClassName(scrollToClassName)[0].scrollTo(0, 0);
      }, 0);
    } else {
      if (stage !== stageLength - 1) {
        if (neglectCommitAndPricing === true && stage === 1) {
          this.setState({
            stage: stage + 2,
            triggeredNext: false,
          });
        } else {
          this.setState({
            stage: stage + 1,
            triggeredNext: false,
          });
        }
        setTimeout(() => {
          document.getElementsByClassName(scrollToClassName)[0] &&
            document
              .getElementsByClassName(scrollToClassName)[0]
              .scrollTo(0, 0);
        }, 10);
      } else {
        this.setState(
          {
            isExpand: true,
            allowSubmit: true,
          },
          () => {
            clearInterval(this.timeout);
          }
        );
      }
    }
  };

  /**
   * this function will set value of comment with empty value validation
   */
  handleSummaryComment = (value) => {
    if (value === "" || value === null) {
      this.setState({
        summaryComment: null,
        triggerCommentValidation: false,
        // disableSubmitButton: true
      });
    } else {
      this.setState({
        summaryComment: value,
        triggerCommentValidation: false,
        // disableSubmitButton: false
      });
    }
  };

  /**
   * Scroll down left nav down when mandtory field is not filled
   */
  scrollDownLeftnav = () => {
    var offsetHeight = document.getElementById("leftnavOpen").offsetHeight + 10;
    setTimeout(() => {
      document
        .getElementsByClassName("summary-content")[0]
        .scrollTo({ top: offsetHeight, left: 0, behavior: "smooth" });
    }, 0);
  };

  displayMandatoryCommentBox = (submitClickDealAction) => {
    let { summaryComment } = this.state;
    if (submitClickDealAction.isMandatory === true) {
      this.scrollDownLeftnav();
      this.setState({
        enableCommentBox: true,
        disableSubmitButton: true,
      });
    } else {
      this.setState({
        enableCommentBox: true,
      });
    }

    if (
      submitClickDealAction.isMandatory === true &&
      (summaryComment === null || summaryComment === "")
    ) {
      this.setState({
        disableSubmitButton: true,
      });
    } else {
      this.setState({
        enableCommentBox: true,
        disableSubmitButton: false,
      });
    }
  };

  checkForMetricsDealStatus = (dealStatus) => {
    if (dealStatus !== "Reject") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * this function will post data to backend
   */
  handleSubmitButtonFromSummary = (submitClickDealAction) => {
    // this.displayMandatoryCommentBox(submitClickDealAction)
    // if (document.getElementsByClassName('progress-line').length === 0) {
    let { dataModel, sMDataModel, dealInfo } = this.state;
    let newDataModel = dataModel;

    // For SCE/IGT to transfer to IGT,SCE vice versa
    if (submitClickDealAction.isDealTypeOverride) {
      dealInfo.isDealTypeOverride =
        submitClickDealAction.isDealTypeOverride || null;
    }

    if (
      submitClickDealAction.sucActionLabel === "Request For Information" &&
      submitClickDealAction.isRFIflag
    ) {
      dealInfo.RFIflag = true;
      // dealInfo.isMatChange = false
    }

    let summaryCommentArrayObject = {
      summaryUserRole:
        this.props.location.state && this.props.location.state.userRole,
      commentText: this.state.summaryComment,
      timestamp: new Date().toISOString(),
      commentedBy:
        this.props.location.state.firstName +
        "  " +
        this.props.location.state.lastName,
      action: submitClickDealAction.sucActionLabel,
    };

    if (
      this.state.summaryComment !== "" &&
      this.state.summaryComment !== null
    ) {
      if (dealInfo.summaryComment.commentText === null) {
        dealInfo.summaryComment.commentText = [summaryCommentArrayObject];
      } else if (dealInfo.summaryComment.commentText.length) {
        dealInfo.summaryComment.commentText.push(summaryCommentArrayObject);
      }
    } else if (
      this.state.summaryComment !== "" &&
      this.state.summaryComment !== null
    ) {
      if (dealInfo.summaryComment.commentText === null) {
        dealInfo.summaryComment.commentText = [summaryCommentArrayObject];
      } else if (dealInfo.summaryComment.commentText.length) {
        dealInfo.summaryComment.commentText.push(summaryCommentArrayObject);
      }
    } else {
      if (submitClickDealAction.isMandatory === true) {
        this.setState({
          triggerCommentValidation: true,
        });
        return;
      } else this.setState({ triggerCommentValidation: false });
    }
    if (dealInfo.salesRepName === undefined || dealInfo.salesRepName === null) {
      dealInfo.salesRepName =
        this.props.location.state.firstName +
        "  " +
        this.props.location.state.lastName;
    }

    dealInfo.lastModifiedDate = null;
    dealInfo.dealStatus = submitClickDealAction.sucStatus;
    dealInfo.customerLegalName = this.state.customerLegalName;
    dealInfo.additionalApprovalFlag = this.state.additionalApprovalFlag;
    dealInfo.neglectCommitAndPricing = this.state.neglectCommitAndPricing;

    if (this.state.neglectCommitAndPricing === true) {
      dealInfo.rateCard = null;
    }

    if (this.state.neglectCommitAndPricing === true) {
      dealInfo.rateCard = null;
    }

    if (submitClickDealAction.sucCheckOut === roleNames.SALES_REPRESENTATIVE) {
      dealInfo.ownerId = dealInfo.createdById;
    } else if (submitClickDealAction.sucCheckOut === roleNames.SALES_MANAGER) {
      dealInfo.ownerId = this.state.cloudFrontSalesManager;
    } else {
      dealInfo.ownerId = submitClickDealAction.sucCheckOut;
    }

    dealInfo.ownerType = submitClickDealAction.sucCheckOut;
    //dealInfo.dealType = "New";
    dealInfo.lastModifiedDate = new Date().toISOString();
    dealInfo.dealIdentifier = this.state.dealIdentifier;

    if (dealInfo && dealInfo.dashboard) {
      if (this.state.tempArray.length > 1) {
        dealInfo.dashboard.payerId = this.state.tempArray.join(";  ");
      } else {
        dealInfo.dashboard.payerId = this.state.tempArray;
      }
    }

    if (dealInfo.createdById === null) {
      dealInfo.createdById = this.props.location.state.alias;
    }

    let updatedUserRoleInfo;

    if (
      this.state.userRoleInfo === null ||
      this.state.userRoleInfo === undefined
    ) {
      updatedUserRoleInfo = this.props.location.state.userRole;
    } else {
      updatedUserRoleInfo = this.state.userRoleInfo;
    }
    if (
      this.state.userRole === roleNames.SALES_REPRESENTATIVE &&
      dealInfo.dealStatus === dealStatusConstant.PPR_Submitted
    ) {
      dealInfo.submittedRatecard = dealInfo.rateCard;
    }

    if (submitClickDealAction.isMandatory !== true) {
      if (
        this.state.summaryComment === "" ||
        this.state.summaryComment === null
      ) {
        let summaryCommentArrayObjectForEmpty = {
          summaryUserRole:
            this.props.location.state && this.props.location.state.userRole,
          commentText: "No Comments Added",
          timestamp: new Date().toISOString(),
          commentedBy:
            this.props.location.state.firstName +
            "  " +
            this.props.location.state.lastName,
          action: submitClickDealAction.sucActionLabel,
        };

        if (dealInfo.summaryComment.commentText === null) {
          dealInfo.summaryComment.commentText = [
            summaryCommentArrayObjectForEmpty,
          ];
        } else if (dealInfo.summaryComment.commentText.length) {
          dealInfo.summaryComment.commentText.push(
            summaryCommentArrayObjectForEmpty
          );
        }
      }
    }

    let pprForm = {
      AWSAlias: dealInfo.createdById,
      userRole: updatedUserRoleInfo,
      loginAlias: this.props.location.state && this.props.location.state.alias,
      loginRole:
        this.props.location.state && this.props.location.state.userRole,
      pageStages: newDataModel,
      dealInfo: dealInfo,
    };

    this.setState({
      disableSubmitButton: false,
    });
    if (isValidJson(pprForm)) {
      if (this.state.allowSubmit === true) {
        this.setState({
          activateToast: true,
          loadingMessage: "Please wait while we process your request...",
          loadingError: false,
          displayPtsButton: false,
        });

        // axios call to submit/reject i.e action button call
        // axios({
        //     method: "POST",
        //     url: `${baseUrl}cfppr/ppr`,
        //     data: pprForm
        // })
        let url = "cfppr/ppr";

        saveOrSubmitPPRForm(url, pprForm)
          .then((resp) => {
            const { data } = resp;

            if (resp.status === 200) {
              if (
                this.props.location.state &&
                this.props.location.state.userRole &&
                submitClickDealAction &&
                submitClickDealAction.sucActionLabel &&
                this.checkForMetricsDealStatus(
                  submitClickDealAction.sucActionLabel
                )
              ) {
                const userRole = this.props.location.state.userRole;
                let metricsAction =
                  "DealIntakeForm-" +
                  userRole.replace(/\s+/g, "-") +
                  "-" +
                  submitClickDealAction.sucActionLabel.replace(/\s+/g, "-") +
                  "-Clicked";
                clickMetrics(metricsAction);
              }

              let rateCardJson = JSON.parse(dealInfo.rateCard);
              let emailForm = {
                dealStatus: resp.data.data.dealInfo.dealStatus,
                createdById: dealInfo.createdById,
                type: resp.data.data.dealInfo.pprType,
                userRole:
                  this.props.location.state &&
                  this.props.location.state.userRole,
                accountManager: this.state.accountManagerName,
                accountName: this.state.accountName,
                ownerId: dealInfo.ownerId,
                AWSAlias:
                  this.props.location.state && this.props.location.state.alias,
                pprId: dealInfo.pprName,
                isMatChange: dealInfo.isMatChange,
                RFIflag: dealInfo.RFIflag,
                isMaterialChange: rateCardJson && rateCardJson.isMaterialChange,
              };
              // axios call to send notification
              let emailUrl = "email/sendMessage";
              emailNotification(emailUrl, emailForm);

              const redirectToDashboard =
                submitClickDealAction.sucActionLabel === "Service Team Approved"
                  ? false
                  : true;

              this.setState(
                {
                  activateToast: true,
                  loadingMessage: "PPR Saved successfully",
                  loadingError: false,
                  loadingMessageDuration: 3000,
                },
                () => {
                  if (!redirectToDashboard) {
                    setTimeout(() => {
                      this.setState({
                        activateToast: false,
                      });
                    }, 3000);
                  }
                }
              );

              if (!redirectToDashboard) {
                if (data && data.data && data.data.dealInfo) {
                  // Update deal action buttons since we're staying on summary tab
                  this.setState({
                    dealAction: resp.data.data.dealInfo.dealAction,
                  });
                }
              } else {
                let location = {
                  pathname: "/dashboard",
                  state: this.props.location.state,
                };
                this.props.history.push(location);
              }
            } else if (data.statusCode && data.statusCode === 400) {
              this.setToastState(data.errorMessage, 3000, false);
            } else {
              this.setToastState("Failed to create PPR", 3000, true);
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data.statusCode &&
              error.data.statusCode === 400
            ) {
              this.setToastState(error.data.errorMessage, 3000, false);
            } else {
              this.setToastState("Error: " + error.data.error, 3000, true);
            }
          });
      }
    }
    // }
    // else {
    //     this.setState({
    //         disableSubmitButton: true
    //     })
    // }
  };

  /**
   * this function will post data to backend as an autosave feature with loader and navigate to dashboard on click
   */
  handlePPRCreatedForAutoSaveOnClick = (locationState) => {
    let { dealInfo, neglectCommitAndPricing } = this.state;
    if (dealInfo.dealStatus === dealStatusConstant.PPR_Created) {
      let { dataModel } = this.state;

      if (this.rateCardData && this.rateCardData.setRateCardData()) {
        let rateCardDataFromCommitAndPricingPage =
          this.rateCardData.setRateCardData();

        if (rateCardDataFromCommitAndPricingPage) {
          dealInfo.rateCard = rateCardDataFromCommitAndPricingPage;
          this.setState({
            rateCardData: rateCardDataFromCommitAndPricingPage,
          });
        }
      }

      let newDataModel = dataModel;

      dealInfo.dealStatus = dealStatusConstant.PPR_Created;
      dealInfo.customerLegalName = this.state.customerLegalName;
      //dealInfo.dealType = "New";
      dealInfo.dealIdentifier = this.state.dealIdentifier;
      dealInfo.neglectCommitAndPricing = neglectCommitAndPricing;

      if (this.state.neglectCommitAndPricing === true) {
        dealInfo.rateCard = null;
      }

      if (dealInfo.createdById === null || dealInfo.createdById === undefined) {
        dealInfo.createdById = this.props.location.state.alias;
      } else if (dealInfo.createdById !== this.props.location.state.alias) {
        this.callDashboardApi(locationState);
        return;
      }

      dealInfo.ownerId = this.props.location.state.alias;
      dealInfo.additionalApprovalFlag = this.state.additionalApprovalFlag;

      if (dealInfo && dealInfo.dashboard) {
        if (this.state.tempArray.length > 1) {
          dealInfo.dashboard.payerId = this.state.tempArray.join(";  ");
        } else {
          dealInfo.dashboard.payerId = this.state.tempArray;
        }
      }

      let pprForm = {
        loginAlias:
          this.props.location.state && this.props.location.state.alias,
        loginRole:
          this.props.location.state && this.props.location.state.userRole,
        AWSAlias: this.props.location.state.alias,
        userRole: this.props.location.state.userRole,
        pageStages: newDataModel,
        dealInfo: dealInfo,
      };

      if (isValidJson(pprForm)) {
        this.setState({
          activateToast: true,
          loadingMessage: "Please wait while we process your request...",
          loadingError: false,
        });
        // axios({
        //     method: "POST",
        //     url: `${baseUrl}cfppr/ppr`,
        //     data: pprForm
        // })
        let url = "cfppr/ppr";

        saveOrSubmitPPRForm(url, pprForm)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({
                activateToast: true,
                loadingMessage: "PPR Saved successfully",
                loadingError: false,
                loadingMessageDuration: 3000,
              });
              this.callDashboardApi(locationState);
            } else {
              this.setState(
                {
                  activateToast: true,
                  loadingMessage: "Failed to save PPR",
                  loadingError: true,
                  loadingMessageDuration: 3000,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      activateToast: false,
                    });
                    this.callDashboardApi(locationState);
                  }, 100);
                }
              );
            }
          })
          .catch(() => {
            this.setState(
              {
                activateToast: true,
                loadingMessage: "Failed to save PPR",
                loadingError: true,
                loadingMessageDuration: 3000,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    activateToast: false,
                  });
                  this.callDashboardApi(locationState);
                }, 100);
              }
            );
          });
      }
    } else {
      this.callDashboardApi(locationState);
    }
  };

  /**
   * this function will post data to backend as an autosave feature with loader and navigate to dashboard on click
   */
  handlePPRCreatedWhileSaveButtonClick = (locationState) => {
    let { dealInfo, neglectCommitAndPricing } = this.state;
    let user_role =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userRole;
    if (
      dealInfo.dealStatus === dealStatusConstant.PPR_Created ||
      dealInfo.dealStatus === dealStatusConstant.PPR_Submitted ||
      dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input ||
      dealInfo.dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM ||
      dealInfo.dealStatus === dealStatusConstant.Deal_Signed
    ) {
      let { dataModel } = this.state;

      if (this.rateCardData && this.rateCardData.setRateCardData()) {
        let rateCardDataFromCommitAndPricingPage =
          this.rateCardData.setRateCardData();

        if (rateCardDataFromCommitAndPricingPage) {
          dealInfo.rateCard = rateCardDataFromCommitAndPricingPage;
          this.setState({
            rateCardData: rateCardDataFromCommitAndPricingPage,
          });
        }
      }

      let newDataModel = dataModel;
      let aws_alias =
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.alias;

      if (user_role !== roleNames.BILLING_TEAM) {
        if (
          dealInfo.createdById === null ||
          dealInfo.createdById === undefined
        ) {
          dealInfo.createdById = this.props.location.state.alias;
        } else if (dealInfo.createdById !== this.props.location.state.alias) {
          return;
        }
      }

      // if (dealInfo.dealStatus === dealStatusConstant.PPR_Created) {
      //     dealInfo.dealType = "New";
      // }
      if (dealInfo.dealStatus === dealStatusConstant.PPR_Created) {
        aws_alias =
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.alias;

        // if (dealInfo.dealStatus === dealStatusConstant.Deal_Signed) {
        //     user_role = dealStatusConstant.Sales_rep;
        //     dealInfo.prevDealStatus = dealStatusConstant.Deal_Signed;
        //     aws_alias = dealInfo.createdById;
        // }
      } else {
        if (dealInfo.dealStatus === dealStatusConstant.Deal_Signed) {
          user_role = dealStatusConstant.Sales_rep;
          dealInfo.prevDealStatus = dealStatusConstant.Deal_Signed;
          aws_alias = dealInfo.createdById;
        } else {
          aws_alias = dealInfo.createdById;
        }
      }

      // dealInfo.dealStatus = dealStatusConstant.PPR_Created;
      dealInfo.customerLegalName = this.state.customerLegalName;
      dealInfo.dealIdentifier = this.state.dealIdentifier;
      dealInfo.neglectCommitAndPricing = neglectCommitAndPricing;

      if (this.state.neglectCommitAndPricing === true) {
        dealInfo.rateCard = null;
      }

      dealInfo.ownerId = this.props.location.state.alias;
      dealInfo.additionalApprovalFlag = this.state.additionalApprovalFlag;

      if (dealInfo && dealInfo.dashboard) {
        if (this.state.tempArray.length > 1) {
          dealInfo.dashboard.payerId = this.state.tempArray.join(";  ");
        } else {
          dealInfo.dashboard.payerId = this.state.tempArray;
        }
      }

      let pprForm = {
        loginAlias:
          this.props.location.state && this.props.location.state.alias,
        loginRole:
          this.props.location.state && this.props.location.state.userRole,
        AWSAlias: aws_alias,
        userRole: user_role,
        pageStages: newDataModel,
        dealInfo: dealInfo,
      };

      if (isValidJson(pprForm)) {
        this.setState({
          activateToast: true,
          loadingMessage: "Please wait while we save your PPR...",
          loadingError: false,
        });

        let url = "cfppr/ppr";

        saveOrSubmitPPRForm(url, pprForm)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState(
                {
                  activateToast: true,
                  loadingMessage: "PPR Saved successfully",
                  loadingError: false,
                  loadingMessageDuration: 3000,
                  dealInfo: {
                    ...this.state.dealInfo,
                    dashboard: {
                      ...this.state.dealInfo.dashboard,
                      versionId: resp.data.data.dealInfo.dashboard.versionId,
                    },
                  },
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      activateToast: false,
                    });
                  }, 100);
                }
              );
            } else {
              this.setState(
                {
                  activateToast: true,
                  loadingMessage: "Failed to save PPR",
                  loadingError: true,
                  loadingMessageDuration: 3000,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      activateToast: false,
                    });
                  }, 100);
                }
              );
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data.statusCode &&
              error.data.statusCode === 400
            ) {
              this.setState(
                {
                  activateToast: true,
                  loadingMessage: error.data.errorMessage,
                  loadingError: false,
                  loadingMessageDuration: 3000,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      activateToast: false,
                    });
                  }, 6000);
                }
              );
            } else {
              this.setToastState("Failed to create PPR", 3000, true);
            }
          });
      }
    }
  };

  /**
   * This function will call save api with PPR Created Status on certain time interval or on click on next
   */
  callApiPPRCreatedForAutoSave = (summaryClick) => {
    let { dealInfo, neglectCommitAndPricing } = this.state;
    if (
      document.visibilityState === "visible" &&
      (this.state.isExpand === false || summaryClick) &&
      dealInfo.dealStatus === dealStatusConstant.PPR_Created
    ) {
      let { dataModel } = this.state;

      let newDataModel = dataModel;

      if (this.rateCardData && this.rateCardData.setRateCardData()) {
        let rateCardDataFromCommitAndPricingPage =
          this.rateCardData.setRateCardData();

        if (rateCardDataFromCommitAndPricingPage) {
          dealInfo.rateCard = rateCardDataFromCommitAndPricingPage;
          this.setState({
            rateCardData: rateCardDataFromCommitAndPricingPage,
          });
        }
      }

      dealInfo.dealStatus = dealStatusConstant.PPR_Created;
      dealInfo.customerLegalName = this.state.customerLegalName;
      //dealInfo.dealType = "New";
      dealInfo.dealIdentifier = this.state.dealIdentifier;
      dealInfo.neglectCommitAndPricing = neglectCommitAndPricing;

      if (this.state.neglectCommitAndPricing === true) {
        dealInfo.rateCard = null;
      }

      if (dealInfo.createdById === null || dealInfo.createdById === undefined) {
        dealInfo.createdById = this.props.location.state.alias;
      } else if (dealInfo.createdById !== this.props.location.state.alias) {
        return;
      }

      dealInfo.ownerId = this.props.location.state.alias;
      dealInfo.additionalApprovalFlag = this.state.additionalApprovalFlag;

      if (dealInfo && dealInfo.dashboard) {
        if (this.state.tempArray.length > 1) {
          dealInfo.dashboard.payerId = this.state.tempArray.join(";  ");
        } else {
          dealInfo.dashboard.payerId = this.state.tempArray;
        }
      }

      let pprForm = {
        loginAlias:
          this.props.location.state && this.props.location.state.alias,
        loginRole:
          this.props.location.state && this.props.location.state.userRole,
        AWSAlias: this.props.location.state.alias,
        userRole: this.props.location.state.userRole,
        pageStages: newDataModel,
        dealInfo: dealInfo,
        isAutoSave: true,
      };
      // axios({
      //     method: "POST",
      //     url: `${baseUrl}cfppr/ppr`,
      //     data: pprForm
      // })
      let url = "cfppr/ppr";

      saveOrSubmitPPRForm(url, pprForm)
        .then((resp) => {
          if (resp.data && resp.data.data) {
            this.setState({
              dealAction: resp.data.data.dealInfo.dealAction,
              dealInfo: {
                ...this.state.dealInfo,
                dashboard: {
                  ...this.state.dealInfo.dashboard,
                  versionId: resp.data.data.dealInfo.dashboard.versionId,
                },
              },
            });
          }
        })
        .catch((error) => {
          if (error && error.data) {
            if (error.data.statusCode === 400) {
              this.setToastState(error.data.errorMessage, 3000, false);
            }
            if (error.data.statusCode === 401) {
              this.setToastState("Session expired", 1000, false);
              setTimeout(() => {
                let location = {
                  pathname: "/",
                }
                this.props.history.push(location);
              }, 1000);
            }
            clearInterval(this.timeout);
          }
        });
    }
  };

  /**
   * This function will call dashboard api
   */
  callDashboardApi = (locationState) => {
    let location = {
      pathname: "/dashboard",
      state: locationState,
    };
    this.props.history.push(location);
  };

  /**
   * This function used to open summary page and will make function call to insert data to summary page
   */
  handleExpand = (navMenu) => {
    let { dealInfo, neglectCommitAndPricing } = this.state;
    if (neglectCommitAndPricing === undefined) {
      neglectCommitAndPricing = false;
    }
    if (this.state.stage === 2) {
      let data = this.rateCardData.setRateCardData();
      dealInfo.rateCard = data;
      this.state.rateCardData = data;
    }
    this.handleSummaryData("TABLE", "Payer Id", navMenu);

    if (
      document.getElementsByClassName("progress-line").length === 0 &&
      neglectCommitAndPricing === false &&
      document.getElementsByClassName("progress-line-forCNP").length === 0
    ) {
      this.setState({
        disableSubmitButton: false,
      });
    } else if (
      document.getElementsByClassName("progress-line").length === 0 &&
      neglectCommitAndPricing === true
    ) {
      this.setState({
        disableSubmitButton: false,
      });
    } else {
      this.setState({
        disableSubmitButton: true,
      });
    }
    this.setState(
      {
        isExpand: !this.state.isExpand,
      },
      () => {
        if (this.state.isExpand === false) {
          this.callApiOnTimeInterVal();
          this.setState({
            enableCommentBox: true,
          });
        } else {
          this.callApiPPRCreatedForAutoSave(true);
        }
      }
    );
  };

  /**
   * this function will update comment history in dealInfo object when comment is posted from Collabaration tab in summary
   */
  commentObjectArrayFromCollabsTab = (commentObject) => {
    let { commentCollabState, dealInfo } = this.state;
    const newcommentArray = [...commentCollabState, ...[commentObject]];
    this.setState({
      commentCollabState: newcommentArray,
    });
    const dealInfoCommentObj = {
      summaryUserRole:
        this.props.location.state && this.props.location.state.userRole,
      commentText: commentObject.addCommentTxt,
      timestamp: new Date().toISOString(),
      commentedBy:
        this.props.location.state.firstName +
        "  " +
        this.props.location.state.lastName,
      action: actionName.COMMENT,
    };
    if (dealInfo.summaryComment.commentText === null) {
      dealInfo.summaryComment.commentText = [dealInfoCommentObj];
    } else if (dealInfo.summaryComment.commentText.length) {
      dealInfo.summaryComment.commentText.push(dealInfoCommentObj);
    }
  };

  /**
   * this function will render left navigation, it will open summary page based on state change
   */
  renderLeftNavigation = () => {
    let navMenu = this.state.dataModel;
    let { dealInfo, neglectCommitAndPricing } = this.state;
    let locationState = this.props.location && this.props.location.state;
    const shouldShowPTS =
      locationState &&
      locationState.userRole === roleNames.SALES_REPRESENTATIVE &&
      this.isPTSValidStatusFlow(dealInfo);

    let salesRepName = null;
    if (dealInfo.salesRepName && dealInfo.salesRepName !== null) {
      salesRepName = dealInfo.salesRepName;
    } else {
      if (this.props.location.state && this.props.location.state.firstName) {
        salesRepName =
          this.props.location.state.firstName +
          "  " +
          this.props.location.state.lastName;
      } else {
        localStorage.clear();
        this.props.history.push("/");
      }
    }

    return (
      <Leftnavigation
        accountManager={this.state.accountManagerName}
        accountName={this.state.accountName}
        dealInfo={dealInfo}
        pprType={dealInfo.pprType}
        navMenu={navMenu}
        customerLegalName={this.state.customerLegalName}
        tempArray={this.state.tempArray}
        canEditPPR={this.state.canEditPPR}
        salesRepName={salesRepName}
        pprId={this.state.pprName}
        adiDealTermFormGroup={this.state.adiDealTermFormGroup}
        locationStateUser={this.props.location.state}
        ownerType={this.state.ownerType}
        userRole={
          this.props.location.state && this.props.location.state.userRole
        }
        dealStatus={this.state.dealStatus}
        rateCardData={this.state.rateCardData}
        handleNavigation={(stageId) => this.handleNavigation(stageId)}
        isExpand={this.state.isExpand}
        changeInvalue={this.state.changeInvalue}
        stage={this.state.stage}
        dealAction={this.state.dealAction}
        isForm={true}
        disableSubmitButton={this.state.disableSubmitButton}
        terminationPage={false}
        leftNavTierPricing={this.state.leftNavTierPricing}
        leftNavTierPricingTable={this.state.leftNavTierPricingTable}
        handleOnClick={() => this.handleExpand(navMenu)}
        summaryData={this.state.summaryData}
        leftNavRateCardData={this.state.leftNavRateCardData}
        summaryComment={this.state.summaryComment}
        regionalLimitsTable={this.state.regionalLimitsTable}
        regionalTrafficLimitTable={this.state.regionalTrafficLimitTable}
        requestFeesTable={this.state.requestFeesTable}
        summarCommentArrayObject={dealInfo && dealInfo.summaryComment}
        summaryCommentTimeStamp={this.state.summaryCommentTimeStamp}
        triggerCommentValidation={this.state.triggerCommentValidation}
        handleSummaryComment={(value) => this.handleSummaryComment(value)}
        handleSubmit={(submitClickDealAction) => {
          this.handleSubmitButtonFromSummary(submitClickDealAction);
        }}
        showDealStructureLoadingToast={(message, activeState) =>
          this.showDealStructureLoadingToast(message, activeState)
        }
        dealIdentifier={this.state.dealIdentifier}
        additionalApprovalFlag={this.state.additionalApprovalFlag}
        additionalPricingTerms={this.state.additionalPricingTerms}
        showAdiDealTermInSummary={this.state.showAdiDealTermInSummary}
        enableCommentBox={this.state.enableCommentBox}
        combinedTraffic={this.state.combinedTraffic}
        createdById={dealInfo.createdById}
        shouldShowPTS={shouldShowPTS}
        selectedDealType={dealInfo.dealType}
        neglectCommitAndPricing={neglectCommitAndPricing}
        renewalDealInfo={dealInfo.renewalDealInfo}
        commentObjectArrayFromCollabsTab={(commentObj) => {
          this.commentObjectArrayFromCollabsTab(commentObj);
        }}
        commentArrayFromCollabTabs={this.state.commentCollabState}
        affiliatedIdArray={this.state.affiliatedIdArray}
        qualifyingQuestionIds={this.state.qualifyingQuestionIds}
        originShieldQuestionIds={this.state.originShieldQuestionIds}
      />
    );
  };

  /**
   * this function will print the page
   */
  print() {
    var printContents = document.getElementById("print_area").innerHTML;
    var originalContents = document?.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setTimeout(() => {
      document.getElementById("printBtn").addEventListener("click", this.print);
    }, 100);
  }

  /**
   * this function will print the form on click of ctrl + P
   */
  handleKeyPress = (event) => {
    if (event.ctrlKey && event.keyCode == 80) {
      event.preventDefault();
      this.print();
    }
  };

  isPTSValidStatusFlow = (dealInfo) => {
    if (this.state.displayPtsButton === true && this.state.showPTS === true) {
      return true;
    } else {
      return false;
    }
  };

  isJsonValid = (validatejsontext) => {
    try {
      JSON.parse(JSON.stringify(validatejsontext));
      return true;
    } catch (e) {
      return false;
    }
  };
  showVidePopup = () => {
    let pageheadeName = this.state.dataModel[this.state.stage].stageName;

    if (pageheadeName === "Account Information") {
      window.open("https://broadcast.amazon.com/videos/192243", "_blank");
    }
    if (pageheadeName === "Deal Description") {
      window.open("https://broadcast.amazon.com/videos/192247", "_blank");
    }
  };
  navigatetoDealType = () => {
    window.open("https://broadcast.amazon.com/videos/192239", "_blank");
  };

  updateUserRole = (secondaryRole, activeRole) =>
    getTokenBasedOnRole(
      secondaryRole,
      activeRole,
      this.props.location.state,
      this.props.history
    );

  updateSPUserRole = (solutionProvider, activeRole) =>
    getTokenBasedOnRole(
      solutionProvider,
      activeRole,
      this.props.location.state,
      this.props.history,
      false,
      true
    );

  updatePPOOrChinaUserRole = (activeRole) => {
    if (activeRole === roleNames.IGT_OWNER) {
      // PPO Owner
      return getTokenBasedOnRole(
        roleNames.PRIVATE_PRICING_CHINA,
        activeRole,
        this.props.location.state,
        this.props.history
      );
    } else if (activeRole === roleNames.PRIVATE_PRICING_CHINA) {
      // Private Pricing China
      return getTokenBasedOnRole(
        roleNames.IGT_OWNER,
        activeRole,
        this.props.location.state,
        this.props.history
      );
    }
  };

  onCancelRateCardChanges = () => {
    const additionalApprovalFlagClone = {
      ...this.state.additionalApprovalFlagClone,
    };
    const dealIdentifierClone = { ...this.state.dealIdentifierClone };

    this.setState((prevState) => ({
      additionalApprovalFlag: {
        ...prevState.additionalApprovalFlag,
        adi_price_terms: additionalApprovalFlagClone.adi_price_terms,
        aosOverride: additionalApprovalFlagClone.aosOverride,
        aosVolumeOverride: additionalApprovalFlagClone.aosVolumeOverride,
        commitFeeOverride: additionalApprovalFlagClone.commitFeeOverride,
        customDealIdentifier: additionalApprovalFlagClone.customDealIdentifier,
        minCommittedFlag: additionalApprovalFlagClone.minCommittedFlag,
        noCommitDeal: additionalApprovalFlagClone.noCommitDeal,
        regionalLimitOverride:
          additionalApprovalFlagClone.regionalLimitOverride,
        regionalUsage: additionalApprovalFlagClone.regionalUsage,
      },
    }));

    this.setState((prevState) => ({
      dealIdentifier: {
        ...prevState.dealIdentifier,
        customPricingTerms: dealIdentifierClone.customPricingTerms,
        customRateCard: dealIdentifierClone.customRateCard,
        noCommitDeal: dealIdentifierClone.noCommitDeal,
      },
    }));
  };
  onCancelOfOverrideChanges = () => {
    const { sMDataModel } = this.state;
    const newsMDataModel = JSON.parse(JSON.stringify(sMDataModel));
    this.setState({
      dataModel: newsMDataModel,
    });
  };

  render() {
    let { dealInfo, isExpand, neglectCommitAndPricing, displayErrorPage } =
      this.state;
    const versionHistoryVisibiltyRole = [
      roleNames.SALES_REPRESENTATIVE,
      roleNames.SCE_OWNER,
      roleNames.PRIVATE_PRICING_CHINA,
      roleNames.PRIVATE_PRICING_PUBLIC_SECTOR,
      roleNames.IGT_OWNER,
    ];

    if (this.state.isExpand === true) {
      clearInterval(this.timeout);
    }

    let locationState = this.props.location && this.props.location.state;
    const shouldShowPTS =
      locationState &&
      locationState.userRole === roleNames.SALES_REPRESENTATIVE &&
      this.isPTSValidStatusFlow(dealInfo);

    if (displayErrorPage === true) {
      return (
        <div>
          <AWSheader
            dealInfo={dealInfo}
            canEditPPR={this.state.canEditPPR}
            additionalApprovalFlag={this.state.additionalApprovalFlag}
            firstName={this.state.firstname}
            lastName={this.state.lastname}
            termination={false}
            handlePPRCreatedForAutoSave={(locationState) =>
              this.handlePPRCreatedForAutoSaveOnClick(locationState)
            }
            handlePPRCreatedWhileSaveButtonClick={(locationState) =>
              console.log("save button")
            }
            locationState={locationState}
            dealStatus={this.state.dealStatus}
            updateUserRole={(secondaryRole, activeRole) => {
              this.updateUserRole(secondaryRole, activeRole);
            }}
            updateSPUserRole={(solutionProvider, activeRole) => {
              this.updateSPUserRole(solutionProvider, activeRole);
            }}
            updatePPOOrChinaUserRole={(activeRole) => {
              this.updatePPOOrChinaUserRole(activeRole);
            }}
            goHomeConfirmationPopUp={false}
            history={this.props.history}
          />
          <div className="errorPagePadding">
            <ErrorPage
              onRefreshClick={() =>
                this.onComponentRendered(null, "refreshOnId")
              }
              onDashboardClick={() => this.callDashboardApi(locationState)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <AWSheader
            canEditPPR={this.state.canEditPPR}
            dealInfo={dealInfo}
            additionalApprovalFlag={this.state.additionalApprovalFlag}
            firstName={this.state.firstname}
            lastName={this.state.lastname}
            termination={false}
            handlePPRCreatedForAutoSave={(locationState) =>
              this.handlePPRCreatedForAutoSaveOnClick(locationState)
            }
            handlePPRCreatedWhileSaveButtonClick={(locationState) =>
              this.handlePPRCreatedWhileSaveButtonClick(locationState)
            }
            locationState={locationState}
            dealStatus={this.state.dealStatus}
            updateUserRole={(secondaryRole, activeRole) => {
              this.updateUserRole(secondaryRole, activeRole);
            }}
            updateSPUserRole={(solutionProvider, activeRole) => {
              this.updateSPUserRole(solutionProvider, activeRole);
            }}
            updatePPOOrChinaUserRole={(activeRole) => {
              this.updatePPOOrChinaUserRole(activeRole);
            }}
            goHomeConfirmationPopUp={
              this.props.location.state.userRole ===
              roleNames.SALES_REPRESENTATIVE
                ? true
                : false
            }
            history={this.props.history}
          />

          <div
            className={
              isExpand
                ? "main-container bg-grey pt-0 overflow-hidden-y"
                : "main-container bg-grey pt-0 overflow-auto-y moz-scroll scroll-width5"
            }
            ref={this.myRef}
            id="print_area"
            onKeyDown={this.handleKeyPress}
          >
            <div
              className={
                isExpand
                  ? "overlay-dashboard display-block"
                  : "overlay-dashboard display-none"
              }
            ></div>
            <Controls
              pprId={this.state.pprName}
              history={this.props.history}
              locationState={locationState}
              shouldShowVersioning={
                locationState &&
                versionHistoryVisibiltyRole.includes(locationState.userRole)
                  ? true
                  : false
              }
              shouldShowPTS={shouldShowPTS}
              createdById={dealInfo.createdById}
              onComponentRendered={(headerData, type) => {
                this.onComponentRendered(headerData, type);
              }}
              showToast={(message, activeState) =>
                this.showDealStructureLoadingToast(message, activeState)
              }
            />

            <div className="pt-1">
              <div
                className={
                  isExpand ? "col-6 float-left p-0" : "col-3 float-left p-0"
                }
              >
                {this.renderLeftNavigation()}
              </div>
              {isExpand && <div className="col-8 float-left"></div>}
              {this.state.activateToast && (
                <AWSLoadingToast
                  message={this.state.loadingMessage}
                  error={this.state.loadingError}
                  duration={this.state.loadingMessageDuration}
                />
              )}

              <AWSModal
                type="Secondary"
                visible={this.state.visible}
                handleOk={() => {
                  this.handleOk();
                }}
                handleCancel={() => {
                  this.handleCancel();
                }}
                titleMessage="This will override current data, are you sure you want to continue?"
              />
              <AWSModal
                type="unLinkedIdType"
                visible={this.state.ModalUnLinkedIdType}
                handleCancel={() => {
                  this.closeunLinkedModal();
                }}
                titleMessage={this.state.unLinkedAlertType}
              />

              <div className="col-9 float-left">
                {this.state.dataModel.length > 0 && (
                  <div className="cust-info-wraper pl-5 pr-5 pt-1">
                    <div className="col-12 p-0">
                      <h5 className="font-size24 amber-bold">
                        {this.state.dataModel[this.state.stage].formHeader}
                        {(this.state.dataModel[this.state.stage].stageName ===
                          "Account Information" ||
                          this.state.dataModel[this.state.stage].stageName ===
                            "Deal Description") &&
                          this.props.location.state &&
                          this.props.location.state.userRole ===
                            roleNames.SALES_REPRESENTATIVE && (
                            <span
                              className="youtube-icon ppr-stages-youtube"
                              onClick={() => {
                                this.showVidePopup();
                              }}
                            >
                              {/* <img src={videoIcon} alt="video" /> */}
                              {/* <Icon name="caret-right-filled"></Icon> */}
                              <VideoIcon />
                              <span
                                className={
                                  this.state.dataModel[this.state.stage]
                                    .stageName === "Account Information"
                                    ? "ppr-stages-tooltip"
                                    : "ppr-stages-tooltip ppr-deal-desc-tooltip"
                                }
                              >
                                {this.state.dataModel[this.state.stage]
                                  .stageName === "Account Information"
                                  ? "Account Information"
                                  : "Deal Description"}
                              </span>
                            </span>
                          )}
                      </h5>
                      {this.state.dataModel[this.state.stage].stageName ===
                      commitAndPricingPageHeader ? (
                        <DealStructure
                          canEditPPR={this.state.canEditPPR}
                          dataModel={this.state.dataModel}
                          pprId={this.state.pprName}
                          auditVersionId={this.state.auditVersionId}
                          dealInfo={dealInfo}
                          rateCardData={
                            neglectCommitAndPricing === true
                              ? null
                              : this.state.rateCardData
                          }
                          onRef={(instance) => (this.rateCardData = instance)}
                          termLength={this.state.termLength}
                          triggeredNext={this.state.triggeredNext}
                          renewalDealInfo={dealInfo.renewalDealInfo}
                          onchangeDealStructure={(
                            key,
                            value,
                            type,
                            geoValues,
                            volumeUnit
                          ) =>
                            this.onchangeDealStructure(
                              key,
                              value,
                              type,
                              geoValues,
                              volumeUnit
                            )
                          }
                          onChangeCustomDeal={(key, value) =>
                            this.onChangeCustomDeal(key, value)
                          }
                          onChangeAdditionalApproval={(key, value) =>
                            this.onChangeAdditionalApproval(key, value)
                          }
                          showDealStructureLoadingToast={(
                            message,
                            activeState
                          ) =>
                            this.showDealStructureLoadingToast(
                              message,
                              activeState
                            )
                          }
                          selectedDealType={dealInfo.dealType}
                          userRole={this.state.userRole}
                          dealStatus={this.state.dealStatus}
                          rateCardClone={this.state.rateCardClone}
                          onCancelRateCardChanges={() =>
                            this.onCancelRateCardChanges()
                          }
                        />
                      ) : (
                        this.state.dataModel[this.state.stage].queBlock.map(
                          (dataValue, queBlockIndex) => {
                            if (dataValue && dataValue.isVisible) {
                              this.state.newAwsData &&
                                this.state.newAwsData.map(
                                  (newQuestionValue) => {
                                    if (
                                      dataValue.queBlockHeader ===
                                        newQuestionValue.queBlockHeader &&
                                      newQuestionValue.questions.length
                                    ) {
                                      dataValue.rows.map(
                                        (rowValue, rowValueIndex) => {
                                          rowValue &&
                                            rowValue.questions.map(
                                              (question) => {
                                                newQuestionValue.questions.map(
                                                  (
                                                    newquestion,
                                                    newquestionIndex
                                                  ) => {
                                                    if (
                                                      question.questionLabel ===
                                                      newquestion.questionLabel
                                                    ) {
                                                      question.questionLabel =
                                                        newquestion.bankLabel;
                                                    }
                                                  }
                                                );
                                              }
                                            );
                                        }
                                      );
                                    }
                                  }
                                );

                              return (
                                <div
                                  className="content-wraper full-width pt-2 pb-5"
                                  key={`question_block_${queBlockIndex}`}
                                >
                                  <div className="full-width">
                                    {dataValue.queBlkHeader && (
                                      <h6 className="mt-5 col-11 p-0 float-left pb-2 dealtype-youtube">
                                        {dataValue.queBlkHeader}
                                        {dataValue.queBlkId ===
                                          "cf955e79-aae2-419f-a426-c2dbf1c9eff1" &&
                                          this.props.location.state &&
                                          this.props.location.state.userRole ===
                                            roleNames.SALES_REPRESENTATIVE && (
                                            <span
                                              className="youtube-icon ppr-stages-youtube"
                                              onClick={() => {
                                                this.navigatetoDealType();
                                              }}
                                            >
                                              {/* <img src={videoIcon} alt="video" /> */}
                                              {/* <Icon name="caret-right-filled"></Icon> */}
                                              <VideoIcon />
                                              <span className="ppr-stages-tooltip select-deal-type-tooltip">
                                                Select Deal Type
                                              </span>
                                            </span>
                                          )}
                                      </h6>
                                    )}
                                    <span className="float-right col-1 p-0 mt-4 comment-info">
                                      {/* <CommentSection infoDescription={dataValue.infoText} /> */}
                                    </span>
                                  </div>
                                  {dataValue.description !== null && (
                                    <p className="font-size14 float-left col-12 p-0">
                                      {dataValue.description}
                                    </p>
                                  )}
                                  {dataValue &&
                                    dataValue.rows.map((rowValue, index) => {
                                      return (
                                        <div
                                          className={
                                            dataValue.tagName ===
                                              tagNameConstant.dealDetailsTag &&
                                            index === 0
                                              ? "full-width display-content"
                                              : "full-width"
                                          }
                                          key={`rows_${index}`}
                                        >
                                          {rowValue &&
                                            rowValue.questions.map(
                                              (
                                                questionValue,
                                                questionIndex
                                              ) => {
                                                return (
                                                  <DynamicRenderComponent
                                                    locationStateUser={
                                                      this.props.location.state
                                                    }
                                                    dealInfo={dealInfo}
                                                    additionalApprovalFlag={
                                                      this.state
                                                        .additionalApprovalFlag
                                                    }
                                                    dealStatus={
                                                      this.state.dealStatus
                                                    }
                                                    key={questionIndex}
                                                    duplicateErrFlag={
                                                      this.state
                                                        .duplicateErrFlag
                                                    }
                                                    canEditPPR={
                                                      this.state.canEditPPR
                                                    }
                                                    dynamicSalesManagerOptions={
                                                      this.state
                                                        .dynamicSalesManagerOptions
                                                    }
                                                    questionValue={
                                                      questionValue
                                                    }
                                                    index={questionIndex}
                                                    userRole={
                                                      this.state.userRole
                                                    }
                                                    pprId={this.state.pprName}
                                                    termLength={
                                                      this.state.termLength
                                                    }
                                                    date={this.state.date}
                                                    questionBlockIndex={
                                                      queBlockIndex
                                                    }
                                                    triggeredNext={
                                                      this.state.triggeredNext
                                                    }
                                                    dataModel={
                                                      this.state.dataModel
                                                    }
                                                    stage={this.state.stage}
                                                    onChangeAdditionalApproval={(
                                                      key,
                                                      value
                                                    ) =>
                                                      this.onChangeAdditionalApproval(
                                                        key,
                                                        value
                                                      )
                                                    }
                                                    checkForMadatoryForFormGroup={() => {
                                                      this.checkForMadatoryForFormGroup(
                                                        null
                                                      );
                                                    }}
                                                    onDropdownSelection={
                                                      this.onDropdownSelection
                                                    }
                                                    handleChange={(
                                                      value,
                                                      question,
                                                      queBlockIndex,
                                                      rowId,
                                                      id,
                                                      termEndDate
                                                    ) => {
                                                      this.handleChange(
                                                        value,
                                                        question,
                                                        queBlockIndex,
                                                        rowId,
                                                        id,
                                                        termEndDate
                                                      );
                                                    }}
                                                    handleOnSelect={(
                                                      value,
                                                      question,
                                                      queBlockIndex,
                                                      rowId,
                                                      id,
                                                      termEndDate,
                                                      dateValue
                                                    ) => {
                                                      this.handleOnSelect(
                                                        value,
                                                        question,
                                                        queBlockIndex,
                                                        rowId,
                                                        id,
                                                        termEndDate,
                                                        dateValue
                                                      );
                                                    }}
                                                    duplicateValidation={
                                                      this.state
                                                        .duplicateValidation
                                                    }
                                                    handleDeleteTableRow={(
                                                      rowId
                                                    ) =>
                                                      this.handleDeleteTableRow(
                                                        rowId
                                                      )
                                                    }
                                                    handleBulkDeleteTableRow={(
                                                      rowId
                                                    ) =>
                                                      this.handleBulkDeleteTableRow(
                                                        rowId
                                                      )
                                                    }
                                                    onCancelOfOverrideChanges={() =>
                                                      this.onCancelOfOverrideChanges()
                                                    }
                                                    handleAffiliatedPayerIdForSummary={(
                                                      selectedAffiliatedPayerIds
                                                    ) =>
                                                      this.handleAffiliatedPayerIdForSummary(
                                                        selectedAffiliatedPayerIds
                                                      )
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            }
                            return;
                          }
                        )
                      )}

                      <div className="pb-4 pt-4 full-width">
                        <div className="float-left col-3 p-0">
                          <AWSbutton
                            btnType="btns unfill-button"
                            label="Back"
                            isIcon={true}
                            onClick={() =>
                              this.handleButtonClick(
                                "back",
                                this.state.dataModel.length
                              )
                            }
                          />
                        </div>
                        <div className="float-right width-auto">
                          <AWSbutton
                            btnType="fill"
                            label="Next"
                            isIcon={true}
                            onClick={() => {
                              this.handleButtonClick(
                                "next",
                                this.state.dataModel.length,
                                this.state.stage,
                                this.state.dataModel
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
