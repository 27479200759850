import React, { Component } from 'react';
import { Icon } from "@amzn/awsui-components-react";
import { getFormattedDate } from '../../../utils/dates'
import AWSInput from "../../common/AWSInput/AWSinput";
import AWSbutton from "../../common/AWSbutton/AWSbutton";

class EditPageHeader extends Component {
    state = {
        stageName: "",
        formHeader: "",
        stageId: '',
        triggeredNext: false,
        questionValue: [{ isVisible: true }],
        validation: [{ message: 'This is a mandatory field', type: 'Required' }],
        charValidation: [{ message: 'Please Enter Valid Character', type: 'Regex', criteria: '^[a-zA-Z0-9 ]*$' }]
    }
    componentDidMount() {
        let { selectedStage, dataModel } = this.props;

        this.setState({
            stageName: dataModel[selectedStage].stageName,
            formHeader: dataModel[selectedStage].formHeader
        })
    }
    /*function to change the title text in input */
    handleChangeSectionTitle = (value) => {
        this.setState({
            stageName: value
        })
    }
    /*function to change the header text in input */
    handleChangeHeader = (value) => {
        this.setState({
            formHeader: value
        })
    }
    /*function to save header */
    handleSave = () => {
        let { dataModel, selectedStage } = this.props;
        let { stageName, formHeader, stageId } = this.state;

        let editHeaderJson = {
            stageId: dataModel[selectedStage].templateStageId, //replace stageId to templateStageId
            stageName: stageName,
            isStageDelete: true,
            formHeader: formHeader
        }
        this.props.handleOk(editHeaderJson)
    }
    /*function to delete header */
    deleteHeader = () => {
        let { dataModel, selectedStage } = this.props;
        let { stageId, stageName } = this.state
        let deleteHeaderJson = {
            stageId: dataModel[selectedStage].templateStageId,
            stageName: stageName,
            isStageDelete: true,
            formHeader: dataModel[selectedStage].formHeader
        }
        this.props.handleDelete(deleteHeaderJson)
    }
    render() {
        let { type } = this.props;
        const { triggeredNext, questionValue, validation, charValidation } = this.state;
        const { location: { state: { firstName, lastName } } } = this.props;

        return (
            <div className="add-section-popup popup full-width editpage-header">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold close-icon">Edit Page Header<span className="float-right cursor-pointer pr-2 pt-1" onClick={() => this.props.handleCancel()}>
                    <Icon name="close"></Icon>
                        </span></p>
                </div>
                <div className="full-width body-wraper pt-4 mb-5 pl-5 pr-5">
                    <div className="full-width mt-3">
                        <p className="dark-black mb-0">Page Header</p>
                        <div className="full-width">
                            <AWSInput
                                value={this.state.formHeader}
                                showOptional={false}
                                onChangeFunc={(value) => { this.handleChangeHeader(value) }}
                                validation={charValidation}
                                triggeredNext={triggeredNext}
                                questionValue={questionValue}
                            />
                        </div>
                    </div>
                    <div className="full-width mt-4">
                        <p className="dark-black mb-0">Page Name</p>
                        <div className="full-width">
                            <AWSInput
                                value={this.state.stageName}
                                showOptional={false}
                                onChangeFunc={(value) => { this.handleChangeSectionTitle(value) }}
                                validation={validation}
                                triggeredNext={triggeredNext}
                                questionValue={questionValue}
                            />
                        </div>
                    </div>

                </div>
                <div className="footer-wraper full-width pr-4">
                    <span className="float-left pt-4 pl-4 mt-2">Created By <span className="dark-blue">{`${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Delete"
                                    isIcon={false}
                                    onClick={() => this.deleteHeader()}
                                />
                            </span>
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label="Save"
                                    disabled={!this.state.stageName}
                                    isIcon={false}
                                    onClick={() => this.handleSave()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditPageHeader;