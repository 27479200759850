import React, { Component } from 'react';
import moment from "moment";
import AWStextarea from '../AWStextarea/AWStextarea';
import AWSbutton from '../AWSbutton/AWSbutton';
import closeIcon from '../../../images/close_light_grey_icon.svg';
import * as roleNames from '../../../constants/roleNames.const';
import { submitComment } from "../../../services/endPoints";
import * as actionName from '../../../constants/actionButtonConstants';

import './LeftNavCollaboration.scss';

let validationArray = [
    {
        cellId: null,
        message: "This is a mandatory field",
        type: "Required",
        criteria: null
    }
];

let validatingQuestion = {
    isVisible: true
}

class LeftNavTerminationCommuntication extends Component {
    state = {
        commentTxt: "",
        addCommentTxt: "",
        commentButtonDisable: false
    }
    handleChange = (value) => {
        this.setState({
            commentTxt: value
        })
    }

    callPostCommentApi = (commentObjectArrayFromCollabsTab) => {
        const { pprId, locationStateUser, dealStatus, createdById,userRole } = this.props;
        const { commentTxt } = this.state;
        this.setState({
            commentButtonDisable: true
        })
        const commentApiData = {
            AWSAlias: createdById ? createdById : locationStateUser.alias,
            userRole: roleNames.SALES_REPRESENTATIVE,
            dealStatus: dealStatus,
            pprId: "PPR-" + pprId,
            comments: commentTxt,
            loginAlias: locationStateUser.alias,
            action: actionName.COMMENT,
            loginRole: userRole
        }
        this.showDealStructureLoadingToast("Uploading comments...", true)
        submitComment(commentApiData)
            .then(resp => {
                this.showDealStructureLoadingToast("Uploading comments...", false)
                this.showDealStructureLoadingToast("Comments Updated...", true)
                this.props.commentObjectArrayFromCollabsTab(commentObjectArrayFromCollabsTab)

                setTimeout(() => {
                    this.showDealStructureLoadingToast("Comments Updated...", false)
                    this.setState({
                        commentButtonDisable: false
                    })
                }, 2000)

                this.setState({
                    commentTxt: "",
                })
            }).catch((err) => {                
                this.showDealStructureLoadingToast("Failed to update comments...", true)
                setTimeout(() => {
                    this.showDealStructureLoadingToast("Failed to update comments...", false)
                    this.setState({
                        commentButtonDisable: false
                    })
                }, 3000)
            })
    }

    /*add comment */
    addComment = () => {
        let { commentTxt } = this.state
        if (commentTxt !== null && commentTxt !== "") {
            const commentObjectArrayFromCollabsTab = {
                addCommentTxt: commentTxt,
                timeStamp: new Date().toISOString()
            }

            this.callPostCommentApi(commentObjectArrayFromCollabsTab)
        }
    }

    showDealStructureLoadingToast = (message, toast) => {
        this.props.showDealStructureLoadingToast(message, toast);
    };

    closeSummary = () => {
        this.props.handleOnClick()
    }

    render() {
        const { summarCommentArrayObject, triggerCommentValidation, userRole, pprId, summaryCommentTimeStamp, locationStateUser, commentArrayFromCollabTabs } = this.props;
        const { commentTxt,commentButtonDisable } = this.state;

        if (triggerCommentValidation === false) {
            validatingQuestion.isVisible = false
        } else {
            validatingQuestion.isVisible = true
        }
        return (
            <div className="collaboration-wraper full-width pt-3 scroll-width10 summary-scroll moz-scroll">
                <span className="close-summary light-white cursor-pointer float-right ml-4" onClick={() => { this.closeSummary() }}>Close <img src={closeIcon} alt="copy" className="ml-2" /></span>
                <div className="col-8 float-left">
                    <ul className="left-content p-0 float-left mb-0">
                        {
                            summarCommentArrayObject.commentText && summarCommentArrayObject.commentText.map((commentObject, commentObjectIndex) => {
                                if (commentObject.commentText !== "" && commentObject.commentText !== null && commentObject.action === actionName.COMMENT) {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Commented:</h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Commented:</h4>
                                            }
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                } else if (commentObject.commentText !== "" && commentObject.commentText !== null) {
                                    return (
                                        <li>
                                            {commentObject.summaryUserRole === roleNames.SALES_REPRESENTATIVE ?
                                                <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) Submitted with comments as below:</h4>
                                                : <h4 className="font-size12 white mb-1 m-t-10"> {commentObject.commentedBy} ({commentObject.summaryUserRole}) {commentObject.action} with comments as below:</h4>
                                            }
                                            <p className="font-size10 white mb-1">{`${moment(commentObject.timestamp).format('MM/DD/YYYY HH:mm')} : ${commentObject.commentText}`}</p>
                                            {/* // {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText} */}
                                        </li>
                                    )
                                }
                            })
                        }
                         <li>
                            {/* <h4 className="font-size12 white mb-1">Sales Manager : Hendry Mendez</h4>
                            <p className="time-txt font-size10 white mb-1">{summaryCommentTimeStamp} </p>
                            {this.state.commentCliked && (<p className="font-size12 white mb-1">{this.state.addCommentTxt}</p>)} */}
                        </li>
                        {/* <li>
                            <h4 className="font-size12 white mb-1">Sales Manager : Hendry Mendez</h4>
                            <p className="time-txt font-size10 white mb-1">13:14:35, 25 Aug 2019 </p>
                            <p className="font-size10 white mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </li> */}
                    </ul>
                </div>
                <div className="col-4 float-left right-wraper comment-box">
                    {/* <p className="orange m-t-10">Leave a comment</p> */}
                    <AWStextarea
                        className="summaryComments commentTextArea"
                        placeholder="Enter Comments"
                        onChangeFunc={(e) => this.handleChange(e)}
                        value={commentTxt || ""}
                        questionValue={validatingQuestion}
                        label=""
                        // validation={validationArray}
                        // triggeredNext={triggerCommentValidation}
                    />
                    <div className="col-12 float-left pr-0 pt-4 pb-4">
                        {/* {
                            this.props.dealAction && this.props.dealAction.map((dealAction, dealActionIndex) => {
                                if (this.props.ownerType === this.props.userRole) {
                                    return (
                                        <div className="width-auto float-left pt-3 btmbtn-group">
                                            <AWSbutton
                                                btnType={this.props.dealAction.length === (dealActionIndex + 1) ? "fill" : "trasparent-btn"}
                                                label={dealAction.sucActionLabel}
                                                // disabled={false}
                                                disabled={this.props.disableSubmitButton}
                                                onClick={() => { this.props.handleSubmit(dealAction) }}
                                                isIcon={false}
                                            />
                                        </div>
                                    )
                                }
                            })
                        } */}
                        <div className="float-right comment-btn">
                            <AWSbutton
                                btnType="btns unfill-button"
                                label="Comment"
                                isIcon={false}
                                onClick={() => { this.addComment() }}
                                disabled={commentButtonDisable}
                            />
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
export default LeftNavTerminationCommuntication;