import React from 'react';
import { Icon } from "@amzn/awsui-components-react";
function VideoIcon() {
    return (
        <Icon name="caret-right-filled" ></Icon>
    );
}
VideoIcon.defaultProps = {
    size: 12
}
export default VideoIcon;