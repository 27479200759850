import React, { Component } from 'react';
import Col from "../common/AWSCol/AWSCol";
import _ from 'underscore';
import { Input } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import AWSError from '../common/AWSError/AWSError';

export default class DynamicTextUp extends Component {

    state = {
        errorLabel: [[null, null], [null, null], [null, null]],
        error: [[false, false], [false, false], [false, false]],
        value: "",
        isError: false
    }

    /**
     * this function will handle change in value by storing in json and calling validation function to validate value
     */
    handleChange = (val, textUpRowIndex, textUpCellIndex) => {
        let { error } = this.state;
        let { questionValue } = this.props;
        this.state.error[textUpRowIndex][textUpCellIndex] = false;
        // ObjectTextUP.rows[textUpRowIndex].cells[textUpCellIndex].value = (val);
        this.setState({
            value: val,
            error,
            isError: false
        })
        this.props.handleChange(val, questionValue, "", textUpRowIndex, textUpCellIndex)
        this.validateForPercentageSummation(textUpRowIndex, textUpCellIndex, val)
    }

    /**
     * this function will check for regex and update state for error
     */
    checkRegexOrRangeAndSetErrorMessage = (validationObject, val, textUpRowIndex, textUpCellIndex) => {
        let regex = new RegExp(validationObject.criteria);
        if ((!_.isEmpty(val)) && (!_.isNull(val))) {
            if (!regex.test((val))) {
                let { error, errorLabel } = this.state;
                this.state.error[textUpRowIndex][textUpCellIndex] = true;
                this.state.errorLabel[textUpRowIndex][textUpCellIndex] = validationObject.message;
                this.setState({
                    error,
                    errorLabel,
                    isError: true
                })
            }
        }
    }

    /**
     * this function will validate based on validation array and display error
     * @param {Array} validationArray 
     * @param {String} val 
     * @param {Integer} textUpRowIndex 
     * @param {Integer} textUpCellIndex 
     */
    validate(validationArray, val, textUpRowIndex, textUpCellIndex) {
        if (textUpRowIndex !== undefined && textUpCellIndex !== undefined) {
            _.each(validationArray, (validationObject, validationIndex) => {
                //test value against validation rule
                if (this.state.error[textUpRowIndex][textUpCellIndex] === false) {
                    switch (validationObject.type) {
                        case "Regex":
                            this.checkRegexOrRangeAndSetErrorMessage(validationObject, val, textUpRowIndex, textUpCellIndex)
                            break;

                        case "Range":
                            this.checkRegexOrRangeAndSetErrorMessage(validationObject, val, textUpRowIndex, textUpCellIndex)
                            break;

                        case "Required":
                            if ((_.isNull(val)) || (_.isEmpty(val)) || (_.isEmpty(val && val.trim()))) {
                                let { error, errorLabel } = this.state;
                                this.state.error[textUpRowIndex][textUpCellIndex] = true;
                                this.state.errorLabel[textUpRowIndex][textUpCellIndex] = validationObject.message;
                                this.setState({
                                    error,
                                    errorLabel,
                                    isError: true
                                })
                            }
                            break;
                    }
                }

            });
        } else {
            this.checkForMandatoryOnNextClick()
        }

    }

    /**
     * this function will validate empty cell on next click
     */
    checkForMandatoryOnNextClick = () => {
        let { questionValue } = this.props;
        questionValue && questionValue.textUpRows.forEach((textUpRow, textUpLoopRowIndex) => {
            textUpRow.cells.map((textUpCell, textUpCellIndex) => {
                let val = textUpCell.value;
                if ((_.isNull(val)) || (_.isEmpty(val)) || (_.isEmpty(val && val.trim()))) {
                    let { error, errorLabel } = this.state;
                    this.state.error[textUpLoopRowIndex][textUpCellIndex] = true;
                    this.state.errorLabel[textUpLoopRowIndex][textUpCellIndex] = "This is a mandatory field";
                    this.setState({
                        error,
                        errorLabel,
                        isError: true
                    })
                }
            })
        });
    }

    /**
     * this function will validate summation of field and show error if exceeds 100%
     */
    validateForPercentageSummation = (textUpRowIndex, textUpCellIndex, val) => {
        let percentageValue = 0;
        let { error, errorLabel } = this.state;
        let { questionValue, validation } = this.props;

        if (val) {
            questionValue && questionValue.textUpRows.forEach((textUpRow, textUpLoopRowIndex) => {
                this.state.error[textUpLoopRowIndex][textUpCellIndex] = false;
                this.setState({
                    error
                })

                if (textUpRow.cells[textUpCellIndex].value) {
                    percentageValue = parseInt(percentageValue) + parseInt(textUpRow.cells[textUpCellIndex].value);
                }
            });
        }

        if (percentageValue > 100) {
            this.state.error[textUpRowIndex][textUpCellIndex] = true;
            this.state.errorLabel[textUpRowIndex][textUpCellIndex] = "Sum cannot exceed 100%";

            this.setState({
                error,
                errorLabel,
                isError: true
            })
        } else if (percentageValue !== 100) {
            this.state.error[textUpRowIndex][textUpCellIndex] = true;
            this.state.errorLabel[textUpRowIndex][textUpCellIndex] = "Sum should be 100%";
            this.setState({
                error,
                errorLabel,
                isError: true
            })
        } else {
            if (textUpRowIndex) {
                this.state.error[textUpRowIndex][textUpCellIndex] = false;
            }
            this.setState({
                error,
                isError: false
            },
                () => {
                    this.validate(validation, val, textUpRowIndex, textUpCellIndex)
                })
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (canEditPPR === true) {
            return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
        } else if (canEditPPR === false) {
            return true // disable true
        }
    }

    render() {
        let { questionValue, triggeredNext, canEditPPR } = this.props;

        let flattenedErrorArray = [].concat(...this.state.error);

        // function will call empty field validation on next click
        if (triggeredNext && (!flattenedErrorArray.includes(true)) && questionValue && questionValue.isVisible) {
            this.checkForMandatoryOnNextClick()
        }
        return (
            <div>
                <div className="AWS-input-div">
                    {
                        questionValue && questionValue.textUpRows.map((textUpRow, textUpRowIndex) => {
                            return (
                                <div className="pb-3 pt-4 col-12 float-left pl-0" key={textUpRowIndex}>
                                    {
                                        textUpRow.cells.map((textUpCell, textUpCellIndex) => {
                                            if (textUpCell.type === "TEXT") {
                                                return (
                                                    <>
                                                        <div className="col-3 float-left pl-0">
                                                            {textUpRowIndex === 0 && (
                                                                <div className={this.props.labelType === "TEXTUP" ? `${this.props.className}` : "input-label"}>
                                                                    <p className="text-center col-10 float-left p-0 mb-1" key={textUpCellIndex}>{textUpCell.textUpLabel}</p>
                                                                </div>
                                                            )}
                                                            <div className="float-left col-10 p-0">
                                                                <input
                                                                    className="awsui-input"
                                                                    placeholder=""
                                                                    value={textUpCell.value}
                                                                    onChange={(e) => this.handleChange(e.target.value, textUpRowIndex, textUpCellIndex)}
                                                                    disabled={this.checkForDisable(questionValue, canEditPPR)}
                                                                    key={textUpCellIndex}
                                                                />
                                                                
                                                            </div>

                                                            <div className="percentage-sign width-auto float-left pl-2 pt-2 mt-0">%</div>
                                                            {(questionValue.isVisible === true) && this.state.error[textUpRowIndex][textUpCellIndex] === true && (
                                                                <div className="col-12 p-0 float-left">
                                                                    <AWSError
                                                                        errorLabel={this.state.errorLabel[textUpRowIndex][textUpCellIndex]}
                                                                        key={textUpCellIndex}
                                                                    />
                                                                </div>

                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            }
                                            if (textUpCell.type === "LABEL") {
                                                return (
                                                    <Col key={`label_${textUpCellIndex}`}
                                                        span={4}
                                                        className={`${textUpRowIndex === 0 ? 'pt-4 mt-3' : 'pt-2'}`}
                                                    >
                                                        <span className={`${textUpRowIndex === 0 ? 'grey-txt pt-1 float-left' : 'grey-txt'}`}>{textUpCell.value}</span>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

