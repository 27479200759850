export const originShieldQuestionLabel = "Origin Shield Pricing: Is the customer seeking approval to use CloudFront as an origin to third-party CDNs?";
export const rateTableHeaders = ["US West (Oregon)", "US West (N. California)", "US East (Ohio)", "US East (N. Virginia)", "EU (London)", "EU (Ireland)", "EU (Frankfurt)", "EU (Paris)", "Asia Pacific (Tokyo)", "Asia Pacific (Singapore)", "Asia Pacific (Sydney)", "Asia Pacific (Seoul)", "Asia Pacific (Mumbai)", "South America (Sao Paulo)"];
export const getRateTableObj = (columnHeaders) => {
    return {
        answerId: null,
        maxRows: 30,
        questionId: null,
        rows: [{
            sequence: 1,
            cells: columnHeaders.map((_, index) => {
                return {
                    answerId: null,
                    questionId: null,
                    type: null,
                    defaulValue: null,
                    isQuestionEdit: false,
                    prevValue: null,
                    sequence: index + 1,
                    cellType: null,
                    options: [],
                    placeholder: null,
                    tableRowId: null,
                    value: "",
                    validation: []
                };
            })
        }]
    }
}

export const createTableObjRowCell = (sequence, value) => {
    return {
        answerId: null,
        questionId: null,
        type: null,
        defaulValue: null,
        isQuestionEdit: false,
        prevValue: null,
        sequence: sequence,
        cellType: null,
        options: [],
        placeholder: null,
        tableRowId: null,
        value: value === undefined || value === null ? "" : value,
        validation: []
    };
}