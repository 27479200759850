import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWStextarea.scss";
import _ from 'underscore';
import AWSError from '../AWSError/AWSError';
import { Textarea, Popover,Icon } from "@amzn/awsui-components-react";
import * as tagNameConstant from '../../../constants/tagNameConstant';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as roleNames from '../../../constants/roleNames.const';
import { userRoleCheckForEditableCell } from "../../../utils/common";

export default class AWStextarea extends Component {

    state = {
        errorLabel: "",
        error: false,
        valueChanged: true,
        textAreaValue: null
    }

    componentDidMount() {
        const {value} = this.props;
        if (value) {
            this.props.onChangeFunc(value);
            this.setState({
                textAreaValue:value
            })
        }
    }

    componentDidUpdate(prevProps){
        let {valueChanged} = this.state;

        if (prevProps.value !== this.props.value) {
            this.setState({
                valueChanged: !valueChanged,
                textAreaValue: this.props.value
            })
            if (this.props.value) {
                this.props.onChangeFunc(this.props.value);
            }
        }
    }

    checkRegexOrRangeAndSetErrorMessage = (validationObject, val) => {
        let regex = new RegExp(validationObject.criteria);
        if ((!_.isEmpty(val)) && (!_.isNull(val))) {
            if (!regex.test((val))) {
                this.setState({
                    error: true,
                    errorLabel: validationObject.message
                })
            }
        }
    }

    validate(validationArray, val) {
        _.each(validationArray, (validationObject, validationIndex) => {
            //test value against validation rule
            switch (validationObject.type) {
                case "Regex":
                    this.checkRegexOrRangeAndSetErrorMessage(validationObject, val)
                    break;

                case "Range":
                    this.checkRegexOrRangeAndSetErrorMessage(validationObject, val)
                    break;

                case "Required":
                    if ((_.isNull(val)) || (_.isEmpty(val)) || (_.isEmpty(val && val.trim()))) {
                        this.setState({
                            error: true,
                            errorLabel: validationObject.message
                        })
                    }
                    break;
            }
        });
    }

    handleChange = (value) => {
        this.setState({
            error: false,
            textAreaValue: value
        })
        // this.validate(this.props.validation, value);
        // this.props.onChangeFunc(value);
    }

    onBlur(){
        this.props.onChangeFunc(this.state.textAreaValue);
    }

    removeError = () => {
        this.setState({
            error: false
        })
        this.props.onChangeFunc(null);
    }

    editQuestionCss = (questionValue) => {
        return questionValue.isQuestionEdit === true ? 'editable-color' : ''
    }

    /**
     * this function will verify and return boolean for disable state of field based on status, userRole and question tagnam
     */
    verifyDisablecondtionBasedOnstatus = () => {
        const { dealStatus, userRole, questionValue, locationStateUser,dealInfo } = this.props;
        const listOfQuestionBasedOnTagName = [tagNameConstant.please_provide_justification_for_refrence]
        if ((dealStatus === dealStatusConstant.Open_For_Review && userRoleCheckForEditableCell(locationStateUser, userRole, dealInfo, roleNames)) || userRole === roleNames.BILLING_TEAM) {
            if (questionValue && questionValue.tagName && listOfQuestionBasedOnTagName.includes(questionValue.tagName)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    checkForDisable = (questionValue, canEditPPR) => {
        if (this.verifyDisablecondtionBasedOnstatus()) {
            return false
        } else {
            if (canEditPPR === true) {
                    return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
            } else if (canEditPPR === false) {
                return true // disable true
            } else {
                return false
            }
        }
    }

    render() {
        const { label, placeholder, value, triggeredNext, questionValue, canEditPPR, showHelpTooltip = true, showOptional,userRole } = this.props;

        if ((triggeredNext === true) && (!this.state.error) && questionValue && questionValue.isVisible) {
            this.validate(this.props.validation, this.state.textAreaValue)
        }

        if (this.state.error === true && questionValue && questionValue.isVisible === false) {
            this.removeError()
        }

        this.checkForDisable(questionValue, canEditPPR)

        return (
            <>
                <div className="AWStextarea-div">
                    {questionValue !== undefined && label !== null ?
                        <div className={` ${this.props.labelWhite === true ? 'white' : ''} pb-2 full-width font-size14`}>
                            <span className={`float-left ${this.editQuestionCss(questionValue)}`}>{questionValue.isMandatory && questionValue.isMandatory === true ? label : `${label} ${showOptional === true ? "(Optional)" : ""}`}</span>
                            {showHelpTooltip && questionValue && questionValue.helpText && (
                                <Popover content={questionValue && questionValue.helpText} size="small" position="top">
                                    <span className="float-left circle-alert">
                                        {/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon>
                                    </span>
                                </Popover>
                            )}
                        </div>
                        : <span>{label}</span>
                    }
                    <div className="full-width">
                        <Textarea
                            placeholder={placeholder}
                            onInput={(e) => this.handleChange(e.detail.value)}
                            value={this.state.textAreaValue}
                            onBlur={this.onBlur.bind(this)}
                            // disabled={questionValue && questionValue.quePermission ? questionValue.quePermission.read : false}
                            disabled={this.checkForDisable(questionValue, canEditPPR)}
                        />
                    </div>

                    {(questionValue && questionValue.isVisible === true) && this.state.error && (
                        <AWSError
                            errorLabel={this.state.errorLabel}
                        />
                    )}
                </div>
            </>

        );
    }
}

AWStextarea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeFunc: PropTypes.func,
    value: PropTypes.string
};
